const PropTypes = require('prop-types');
// @ vendors
const React = require('react');
const { connect } = require('react-redux');
const throttle = require('lodash/function/throttle');

// @ commons
const { OKButton } = require('commonsComponents/OKButton');

// @actions
const printerActions = require('actions/contactCenter/printer');

// @ Utils
const { isPrintOfficeMode } = require('utilities/contactCenter/printerHelper');

class PrinterPreviewManagerButton extends React.Component {
    constructor(props) {
        super(props);
        // Call click event only once during the period of one second. This prevents to accidentally trigger multiple calls of the same operation.
        this.handleClick = throttle(this.handleClick.bind(this), 1000, {trailing: false});
    }

    handleClick() {
        const { immPrinter, dispatch, officeSignature } = this.props;
        if(officeSignature && isPrintOfficeMode()){
            this.props.onClick();   
        }

        if (isPrintOfficeMode()) {
            const isShowingPreview = immPrinter.get('isShowingPreview');
            const documentsPrintedAndSigned = immPrinter.get('documentsPrintedAndSigned');

            if (!isShowingPreview) {
                dispatch(printerActions.showPrintRequired());
            } else if (isShowingPreview && documentsPrintedAndSigned !== 'YES') {
                dispatch(printerActions.showPrintedAndSignedStatusRequired());
            } else {
                dispatch(printerActions.printedAndSignedSuccess());
                this.props.onClick();
            }
        } else {
            this.props.onClick();
        }
    }

    render () {
        const { children, modifier, loading, size, rightIcon, disabled } = this.props;

        return (
            <OKButton
                rightIcon={rightIcon}
                modifier={modifier}
                loading={loading}
                size={size}
                onClick={this.handleClick}
                disabled={disabled}>
                {children}
            </OKButton>
        );
    }
}

PrinterPreviewManagerButton.propTypes = {
    immPrinter: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.any,
    modifier: PropTypes.string,
    size: PropTypes.string,
    rightIcon: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    officeSignature: PropTypes.bool
}

PrinterPreviewManagerButton.defaultProps = {
    officeSignature: false
}

module.exports = connect(state => ({
    immPrinter: state.printer,
}))(PrinterPreviewManagerButton);
