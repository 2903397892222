// @vendors
const React = require('react');

// @components
const StocksContractDropdownOption = require('./stocksContractDropdownContractOption');

const StocksContractDropdownGroupedOption = props => {
    return <StocksContractDropdownOption {...props} grouped />;
};

module.exports = StocksContractDropdownGroupedOption;