// @ vendor
const React = require('react');
const PropTypes = require('prop-types');
const { Component } = require('react');

// @utilities
const { FormattedText, formatText, hasTranslation } = require('core/i18n').i18n;

// @commons
const OKCommonTooltip = require('commonsComponents/OKTooltip');

class OKTooltip extends Component {

    render() {
        let { content, title, injectedStrings } = this.props;
        let tooltipProps = Object.assign({}, this.props);

        if (!!title && hasTranslation(title)) {
            title = formatText(title);
            delete tooltipProps.title;
            
        }

        if (typeof content === 'string' && hasTranslation(content)) {
            content = <FormattedText value={content} injectedStrings={injectedStrings} />;
            delete tooltipProps.content;
        }
        
        return (
            <OKCommonTooltip
                {...tooltipProps}
                title={title}
                content={content}
            />
        );
    }
}
OKTooltip.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]).isRequired,
    injectedStrings: PropTypes.array,
    title: PropTypes.string,
};

module.exports = OKTooltip;