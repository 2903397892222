//@utils
const {
    getEventCategory,
} = require("../../../utilities/taggingCommonCatalogue");

export const analyticsId = {
    CLICK_TOGGLE: "CLICK_TOGGLE",
};

export const catalogue = {
    [analyticsId.CLICK_TOGGLE]: (isExpanded) => ({
        ...getEventCategory(),
        accionEvento: "chevron",
        etiquetaEvento: isExpanded ? "abrir" : "cerrar",
    }),
};
