// Security Kits codes
const KITS = {
    KIT_001: '001', // Code for sms
    KIT_002: '002', // Code for sms + signature
    KIT_CUSTOM: 'CUSTOM' // Code for custom
}

// All Security Kits codes
const AVAILABLE_KITS = [
    KITS.KIT_001,
    KITS.KIT_002,
    KITS.KIT_CUSTOM
];

// Neruda Security Set codes
const NERUDA_SET = [
	'010', // Keys and passwords
	'007', // Hire products
	'011', // Cards
	'023'  // Paired devices
];

module.exports = Object.assign(
    { AVAILABLE_KITS },
    { KITS },
    { NERUDA_SET }
);