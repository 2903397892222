module.exports = {
    ERROR_INVALID_PASSWORD: 'FACSEG_50201001',
    ERROR_USER_BLOCKED: 'FACSEG_50201003',
    ERROR_DOCUMENT_NOT_FOUND: 'PS_4126',
    ERROR_INVALID_DOCUMENT: 'PS_4499 incorrect document',
    ERROR_REGISTER_BIZUM_SUCCESSFUL: '0',
    ERROR_USER_REGISTERED_IN_OTHER_ENTITY: 'PAINOP_CD00016',
    ERROR_BAD_REQUEST: 'Bad Request',
    ERROR_INVALID_PORTABILITY_CODE: 'PAINOP_CD00018',
    ERROR_CARD_REQUEST_ADDITIONAL_NO_INTERVENERS: 'NO_INTERVENERS',
    ERROR_CARD_REQUEST_ADDITIONAL_RELATIONSHIP_ALREADY_EXISTS: 'ALTA NO EFECTUADA. YA EXISTE ESTA RELACI',
    ERROR_INVALID_INTERACTION_ID_CODE: '400',
    ERROR_PENSION_PLAN_NO_EXTRAORDINARY_CONTRIBUTIONS_TO_CANCEL: 'PK0162'
}
