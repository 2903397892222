module.exports = {
    SEND_ORDER: 'Yes',
    PRODUCT_TYPE: 'Fondo',
    TRANSFER_ORDER_TYPE: 'Traspaso',
    BUY_ORDER_TYPE: 'Compra',
    SELL_ORDER_TYPE: 'Venta',
    BUY_SELL_ORDER_TYPE: 'Compra/Venta',
    CONNECTION_CHANNEL_CONTACT_CENTER: 'cc',
    SAVE_DOCUMENTATION_NO: 'no'
};
