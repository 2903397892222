require('./warningMessage.scss');
// @ vendor
const React = require('react');
const PropTypes = require('prop-types');
const { Link } = require('react-router-dom');

class WarningMessage extends React.Component {

    buildText() {
        const { isLink, text, to, typeOfBackground } = this.props;
        if (isLink) {
            return (
                <Link
                    to={to}
                    className={`warning-message__text warning-message__text--${typeOfBackground}`}
                >
                    <i className={`warning-message__icon warning-message__icon--${typeOfBackground} icon-error`}/>
                    {text}
                </Link>
            );
        } else {
            return (
                <div className={`warning-message-container--${typeOfBackground}`}>
                    <i className={`warning-message__icon warning-message__icon--${typeOfBackground} icon-error`}/>
                    {text}
                </div>
            );
        }
    }

    render() {
        const { visible, typeOfBackground } = this.props;

        return visible ? (
            <span className="warning-message">
                <div className={`warning-message__text warning-message__text--${typeOfBackground}`}>{this.buildText()}</div>
            </span>
        ) : null;
    }
}

WarningMessage.propTypes = {
    text: PropTypes.string.required,
    visible: PropTypes.boolean,
    isLink: PropTypes.boolean,
    typeOfBackground: PropTypes.oneOf(['dark', 'light', 'orange']),
    to: PropTypes.string,
};

WarningMessage.defaultProps = {
    typeOfBackground: 'dark',
    isLink: false
}

module.exports = WarningMessage;
