const TYPEOFROADSLIST = {
    '1': 'Calle',
    '2': 'Plaza',
    '3': 'Paseo',
    '4': 'Avenida',
    '5': 'Ronda',
    '6': 'Carretera',
    '7': 'Camino',
    '8': 'Cuesta',
    '9': 'Edificio',
    '10': 'Urbanización',
    '11': 'Carrera',
    '12': 'Callejón',
    '13': 'Polígono',
    '14': 'Travesía',
    '15': 'Glorieta',
    '16': 'Colonia',
    '17': 'Chalet',
    '18': 'Agrupación',
    '19': 'Alameda',
    '20': 'Apartado',
    '21': 'Bajada',
    '22': 'Barranco',
    '23': 'Barriada',
    '24': 'Barrio',
    '25': 'Bloque',
    '26': 'Bulevar',
    '27': 'Caserío',
    '28': 'Diseminado',
    '29': 'Grupo',
    '30': 'Lugar',
    '31': 'Mercado',
    '32': 'Parque',
    '33': 'Partida',
    '34': 'Pasaje',
    '35': 'Poblado',
    '36': 'Rambla',
    '37': 'Residencial',
    '38': 'Rúa',
    '39': 'Sector',
    '40': 'Senda',
    '41': 'Subida',
    '42': 'Torrente',
    '43': 'Travesera',
    '44': 'Vía',
    '45': 'Carrer',
    '47': 'Arroyo',
}
module.exports = TYPEOFROADSLIST;
