const { ADOBE_OFFER_CODE_GLOBAL_POSITION_TAB, ADOBE_OFFER_CODE_ACCOUNTS_TAB, ADOBE_OFFER_CODE_CARDS_TAB } = require('constants/adobe')
const { RETAIL_LOAN_AD_ID } = require('constants/index');

const COMMERCIAL_ADS_TYPES = {
    global: [
        {
            id: 'B.2.1',
            label: 'BannerA',
            type: 'banner-a',
            offerSpaceName: ADOBE_OFFER_CODE_GLOBAL_POSITION_TAB
        },
        {
            id: 'B.2.2',
            label: 'Wafer',
            type: 'wafer',
            description: 'productsEmpty'
        },
        {
            id: ['B.2.3.1', 'B.2.3.2', 'B.2.3.3', 'B.2.3.4'],
            label: 'CrossSellingGlobal',
            type: 'cross-selling'
        }
    ],
    'my-finances': [
        {
            id: 'B.2.1',
            label: 'BannerA',
            type: 'banner-a',
            offerSpaceName: ADOBE_OFFER_CODE_GLOBAL_POSITION_TAB
        }
    ],
    accounts: [
        {
            id: 'B.3.1',
            label: 'BannerB',
            type: 'banner-b',
            offerSpaceName: ADOBE_OFFER_CODE_ACCOUNTS_TAB
        },
        {
            id: ['B.3.2.1', 'B.3.2.2', 'B.3.2.3', 'B.3.2.4'],
            label: 'CrossSellingAccount',
            type: 'cross-selling'
        }
    ],
    'accounts/:accountId/details': {
        id: 'B.4.1',
        label: 'BannerA',
        type: 'banner-a'
    },
    cards: [
        {
            id: 'B.5.1',
            label: 'BannerB',
            type: 'banner-b',
            offerSpaceName: ADOBE_OFFER_CODE_CARDS_TAB
        },
        {
            id: ['B.5.2.1', 'B.5.2.2', 'B.5.2.3', 'B.5.2.4'],
            label: 'CrossSellingCards',
            type: 'cross-selling'
        },
        {
            id: '',
            label: 'Cards',
            type: 'cards',

        }
    ],
    'global/entities/manage': [
        {
            id: 'B.2.1',
            label: 'BannerA',
            type: 'banner-a',
            offerSpaceName: ADOBE_OFFER_CODE_GLOBAL_POSITION_TAB
        }
    ],
    'global/entities': [
        {
            id: 'B.5.2.2',
            label: 'BannerA',
            type: 'banner-a'
        }
    ],
    'cards/:cardId/details': {
        id: 'B.6.1',
        label: 'BannerA',
        type: 'banner-a'
    },
    transfers: [
        {
            id: 'B.7.1',
            label: 'BannerB',
            type: 'banner-b'
        },
        {
            id: ['B.7.2.1', 'B.7.2.2', 'B.7.2.3', 'B.7.2.4'],
            label: 'CrossSellingTransfers',
            type: 'cross-selling'
        }
    ],
    'loans-mortgages': [
        {
            id: 'B.8.1',
            label: 'BannerB',
            type: 'banner-b'
        },
        {
            id: ['B.8.2.1', 'B.8.2.2', 'B.8.2.3', 'B.8.2.4'],
            label: 'CrossSellingMortgages',
            type: 'cross-selling'
        },
        {
            id: 'B.8.6',
            label: 'Wafer',
            type: 'wafer',
            section: 'mortgagesInProgress'
        },
        {
            id: RETAIL_LOAN_AD_ID,
            label: 'wafferCompras',
            type: 'waffer-compras'
        }
    ],
    'insurances': [
        {
            id: 'B.8.1',
            label: 'BannerB',
            type: 'banner-b'
        },
        {
            id: ['B.8.2.1', 'B.8.2.2', 'B.8.2.3', 'B.8.2.4'],
            label: 'CrossSellingMortgages',
            type: 'cross-selling'
        }
    ],
    'loans-mortgages/preapproved-loans/:loanId': {
        id: 'B.8.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'loans-mortgages/preapproved-loans/:loanId/movements': {
        id: 'B.8.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'loans-mortgages/preapproved-loans/:loanId/repayment-plan': {
        id: 'B.8.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'loans-mortgages/credit/investment-credit/:creditId': {
        id: 'B.8.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'loans-mortgages/credit/pregranted-credit/:creditId': {
        id: 'B.8.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'loans-mortgages/loans/:loanId': {
        id: 'B.9.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'loans-mortgages/loans/:loanId/movements': {
        id: 'B.9.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'loans-mortgages/loans/:loanId/repayment-plan': {
        id: 'B.9.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'loans-mortgages/loans/:loanId/settlements': {
        id: 'B.9.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'loans-mortgages/loans/:loanId/unpaid-fees': {
        id: 'B.9.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'loans-mortgages/loans/:loanId/unpaid-fees/detail/:feeIndex': {
        id: 'B.9.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'operative-general-data': {
        id: 'B.9.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    documentation: {
        id: 'B.9.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'documentation/contracts/mortgages/:contractId/documents': {
        id: 'B.2.2',
        label: 'Wafer',
        type: 'wafer'
    },
    'amortization-plan': {
        id: 'B.9.1',
        label: 'BannerB',
        type: 'banner-b'
    },
    'purchase/:contractIndex/:redirect?': {
        id: ['B.2.3.1', 'B.2.3.2', 'B.2.3.3', 'B.2.3.4'],
        label: 'CrossSellingGlobal',
        type: 'cross-selling'
    },
    deposits: [
        {
            id: 'B.10.1',
            label: 'BannerB',
            type: 'banner-b'
        },
        {
            id: ['B.10.2.1', 'B.10.2.2', 'B.10.2.3', 'B.10.2.4'],
            label: 'CrossSellingDeposits',
            type: 'cross-selling'
        }
    ],
    'deposits/closed': [
        {
            id: 'B.10.1',
            label: 'BannerB',
            type: 'banner-b'
        },
        {
            id: ['B.10.2.1', 'B.10.2.2', 'B.10.2.3', 'B.10.2.4'],
            label: 'CrossSellingDeposits',
            type: 'cross-selling'
        }
    ],
    'deposits/:depositId': [
        {
            id: 'B.10.1',
            label: 'BannerB',
            type: 'banner-b'
        },
        {
            id: ['B.10.2.1', 'B.10.2.2', 'B.10.2.3', 'B.10.2.4'],
            label: 'CrossSellingDeposits',
            type: 'cross-selling'
        }
    ],
    'deposits/:depositId/imposition/:impositionId': {
        id: 'B.11.1',
        label: 'BannerA',
        type: 'banner-a'
    },
    'broker/general': [
        {
            id: 'B.12.1',
            label: 'BannerC',
            type: 'banner-c'
        },
        {
            id: 'B.12.4',
            label: 'BannerA',
            type: 'banner-a'
        },
        {
            id: ['B.12.2.1', 'B.12.2.2', 'B.12.2.3', 'B.12.2.4'],
            label: 'CrossSellingBroker',
            type: 'cross-selling'
        }
    ],
    'broker/general/robo': [
        {
            id: 'B.12.6',
            label: 'BannerC',
            type: 'banner-c'
        }
    ],
    'broker/markets': [
        {
            id: 'B.12.3',
            label: 'BannerA',
            type: 'banner-a'
        },
        {
            id: ['B.12.3.1', 'B.12.3.2', 'B.12.3.3', 'B.12.3.4'],
            label: 'CrossSellingBroker',
            type: 'cross-selling'
        }
    ],
    'broker/funds': {
        id: 'B.12.5',
        label: 'BannerA',
        type: 'banner-a'
    },
    'broker/explore': [
        {
            id: 'B.12.9',
            label: 'BannerD',
            type: 'banner-d'
        }
    ],
    edit: {
        id: 'B.13.0',
        label: 'BannerA',
        type: 'banner-a'
    },
    settings: {
        id: 'B.14.0',
        label: 'BannerA',
        type: 'banner-a'
    },
    taxAndRents: {
        id: 'B.15.0',
        label: 'BannerA',
        type: 'banner-a'
    },
    'insurances/home/:policyNumber': {
        id: 'B.16.1',
        label: 'BannerB',
        type: 'banner-b',
    },
    'insurances/mobile/:policyNumber': {
        id: 'B.16.1',
        label: 'BannerB',
        type: 'banner-b',
    },
    'edit/shipping-address/remove': {
        id: 'B.13.0',
        label: 'BannerA',
        type: 'banner-a'
    },
    'edit/shipping-address/:operation': {
        id: 'B.13.0',
        label: 'BannerA',
        type: 'banner-a'
    },
    'broker/watchlist':  [{
        id: 'B.12.7',
        label: 'BannerA',
        type: 'banner-a',
        section: 'watchlistBannerFirst'
    },{
        id: 'B.12.8',
        label: 'BannerA',
        type: 'banner-a',
        section: 'watchlistBannerSecond'
    }]
};


//Default Data if no banner data comes from the service

const CROSS_SELLING_PRODUCTS = [
    //Position Global
    {
        id: 'B.2.3.1',
        title: 'commercialAdsCrossSellingProducts-accountsTitle',
        description: 'commercialAdsCrossSellingProducts-accountsDescription',
        uri: 'commercialAdsCrossSellingProducts-accountsURI',
        modifier: 'accounts',
        label: 'CrossSellingAccount'
    },
    {
        id: 'B.2.3.2',
        title: 'commercialAdsCrossSellingProducts-cardsTitle',
        description: 'commercialAdsCrossSellingProducts-cardsDescription',
        uri: 'commercialAdsCrossSellingProducts-cardsURI',
        modifier: 'cards',
        label: 'CrossSellingCards'
    },
    {
        id: 'B.2.3.3',
        title: 'commercialAdsCrossSellingProducts-loansTitle',
        description: 'commercialAdsCrossSellingProducts-loansDescription',
        uri: 'commercialAdsCrossSellingProducts-loansURI',
        modifier: 'mortgages',
        label: 'CrossSellingMortgages'
    },
    {
        id: 'B.2.3.4',
        title: 'commercialAdsCrossSellingProducts-savingsTitle',
        description: 'commercialAdsCrossSellingProducts-savingsDescription',
        uri: 'commercialAdsCrossSellingProducts-savingsURI',
        modifier: 'deposits',
        label: 'CrossSellingDeposits'
    },
    //Accounts
    {
        id: 'B.3.2.1',
        title: 'commercialAdsCrossSellingProducts-cardsTitle',
        description: 'commercialAdsCrossSellingProducts-cardsDescription',
        uri: 'commercialAdsCrossSellingProducts-cardsURI',
        modifier: 'cards',
        label: 'CrossSellingCards'
    },
    {
        id: 'B.3.2.2',
        title: 'commercialAdsCrossSellingProducts-loansTitle',
        description: 'commercialAdsCrossSellingProducts-loansDescription',
        uri: 'commercialAdsCrossSellingProducts-loansURI',
        modifier: 'mortgages',
        label: 'CrossSellingMortgages'
    },
    {
        id: 'B.3.2.3',
        title: 'commercialAdsCrossSellingProducts-savingsTitle',
        description: 'commercialAdsCrossSellingProducts-savingsDescription',
        uri: 'commercialAdsCrossSellingProducts-savingsURI',
        modifier: 'deposits',
        label: 'CrossSellingDeposits'
    },
    {
        id: 'B.3.2.4',
        title: 'commercialAdsCrossSellingProducts-investmentsTitle',
        description: 'commercialAdsCrossSellingProducts-investmentsDescription',
        uri: 'commercialAdsCrossSellingProducts-investmentsURI',
        modifier: 'investments',
        label: 'CrossSellingInvestments'
    },
    // Cards
    {
        id: 'B.5.2.1',
        title: 'commercialAdsCrossSellingProducts-accountsTitle',
        description: 'commercialAdsCrossSellingProducts-accountsDescription',
        uri: 'commercialAdsCrossSellingProducts-accountsURI',
        modifier: 'accounts',
        label: 'CrossSellingAccount'
    },
    {
        id: 'B.5.2.2',
        title: 'commercialAdsCrossSellingProducts-loansTitle',
        description: 'commercialAdsCrossSellingProducts-loansDescription',
        uri: 'commercialAdsCrossSellingProducts-loansURI',
        modifier: 'mortgages',
        label: 'CrossSellingMortgages'
    },
    {
        id: 'B.5.2.3',
        title: 'commercialAdsCrossSellingProducts-savingsTitle',
        description: 'commercialAdsCrossSellingProducts-savingsDescription',
        uri: 'commercialAdsCrossSellingProducts-savingsURI',
        modifier: 'deposits',
        label: 'CrossSellingDeposits'
    },
    {
        id: 'B.5.2.4',
        title: 'commercialAdsCrossSellingProducts-investmentsTitle',
        description: 'commercialAdsCrossSellingProducts-investmentsDescription',
        uri: 'commercialAdsCrossSellingProducts-investmentsURI',
        modifier: 'investments',
        label: 'CrossSellingInvestments'
    },
    //Transfers
    {
        id: 'B.7.2.1',
        title: 'commercialAdsCrossSellingProducts-accountsTitle',
        description: 'commercialAdsCrossSellingProducts-accountsDescription',
        uri: 'commercialAdsCrossSellingProducts-accountsURI',
        modifier: 'accounts',
        label: 'CrossSellingAccount'
    },
    {
        id: 'B.7.2.2',
        title: 'commercialAdsCrossSellingProducts-cardsTitle',
        description: 'commercialAdsCrossSellingProducts-cardsDescription',
        uri: 'commercialAdsCrossSellingProducts-cardsURI',
        modifier: 'cards',
        label: 'CrossSellingCards'
    },
    {
        id: 'B.7.2.3',
        title: 'commercialAdsCrossSellingProducts-loansTitle',
        description: 'commercialAdsCrossSellingProducts-loansDescription',
        uri: 'commercialAdsCrossSellingProducts-loansURI',
        modifier: 'mortgages',
        label: 'CrossSellingMortgages'
    },
    {
        id: 'B.7.2.4',
        title: 'commercialAdsCrossSellingProducts-savingsTitle',
        description: 'commercialAdsCrossSellingProducts-savingsDescription',
        uri: 'commercialAdsCrossSellingProducts-savingsURI',
        modifier: 'deposits',
        label: 'CrossSellingDeposits'
    },
    //Loans
    {
        id: 'B.8.2.1',
        title: 'commercialAdsCrossSellingProducts-accountsTitle',
        description: 'commercialAdsCrossSellingProducts-accountsDescription',
        uri: 'commercialAdsCrossSellingProducts-accountsURI',
        modifier: 'accounts',
        label: 'CrossSellingAccount'
    },
    {
        id: 'B.8.2.2',
        title: 'commercialAdsCrossSellingProducts-cardsTitle',
        description: 'commercialAdsCrossSellingProducts-cardsDescription',
        uri: 'commercialAdsCrossSellingProducts-cardsURI',
        modifier: 'cards',
        label: 'CrossSellingCards'
    },
    {
        id: 'B.8.2.3',
        title: 'commercialAdsCrossSellingProducts-loansTitle',
        description: 'commercialAdsCrossSellingProducts-loansDescription',
        uri: 'commercialAdsCrossSellingProducts-loansURI',
        modifier: 'mortgages',
        label: 'CrossSellingMortgages'
    },
    {
        id: 'B.8.2.4',
        title: 'commercialAdsCrossSellingProducts-investmentsTitle',
        description: 'commercialAdsCrossSellingProducts-investmentsDescription',
        uri: 'commercialAdsCrossSellingProducts-investmentsURI',
        modifier: 'investments',
        label: 'CrossSellingInvestments'
    },
    //Deposits
    {
        id: 'B.10.2.1',
        title: 'commercialAdsCrossSellingProducts-accountsTitle',
        description: 'commercialAdsCrossSellingProducts-accountsDescription',
        uri: 'commercialAdsCrossSellingProducts-accountsURI',
        modifier: 'accounts',
        label: 'CrossSellingAccount'
    },
    {
        id: 'B.10.2.2',
        title: 'commercialAdsCrossSellingProducts-cardsTitle',
        description: 'commercialAdsCrossSellingProducts-cardsDescription',
        uri: 'commercialAdsCrossSellingProducts-cardsURI',
        modifier: 'cards',
        label: 'CrossSellingCards'
    },
    {
        id: 'B.10.2.3',
        title: 'commercialAdsCrossSellingProducts-loansTitle',
        description: 'commercialAdsCrossSellingProducts-loansDescription',
        uri: 'commercialAdsCrossSellingProducts-loansURI',
        modifier: 'mortgages',
        label: 'CrossSellingMortgages'
    },
    {
        id: 'B.10.2.4',
        title: 'commercialAdsCrossSellingProducts-savingsTitle',
        description: 'commercialAdsCrossSellingProducts-savingsDescription',
        uri: 'commercialAdsCrossSellingProducts-savingsURI',
        modifier: 'deposits',
        label: 'CrossSellingDeposits'
    },
    //Investments
    {
        id: 'B.12.2.1',
        title: 'commercialAdsCrossSellingProducts-accountsTitle',
        description: 'commercialAdsCrossSellingProducts-accountsDescription',
        uri: 'commercialAdsCrossSellingProducts-accountsURI',
        modifier: 'accounts',
        label: 'CrossSellingAccount'
    },
    {
        id: 'B.12.2.2',
        title: 'commercialAdsCrossSellingProducts-cardsTitle',
        description: 'commercialAdsCrossSellingProducts-cardsDescription',
        uri: 'commercialAdsCrossSellingProducts-cardsURI',
        modifier: 'cards',
        label: 'CrossSellingCards'
    },
    {
        id: 'B.12.2.3',
        title: 'commercialAdsCrossSellingProducts-loansTitle',
        description: 'commercialAdsCrossSellingProducts-loansDescription',
        uri: 'commercialAdsCrossSellingProducts-loansURI',
        modifier: 'mortgages',
        label: 'CrossSellingMortgages'
    },
    {
        id: 'B.12.2.4',
        title: 'commercialAdsCrossSellingProducts-savingsTitle',
        description: 'commercialAdsCrossSellingProducts-savingsDescription',
        uri: 'commercialAdsCrossSellingProducts-savingsURI',
        modifier: 'deposits',
        label: 'CrossSellingDeposits'
    },
    {
        id: 'B.12.3.1',
        title: 'commercialAdsCrossSellingProducts-accountsTitle',
        description: 'commercialAdsCrossSellingProducts-accountsDescription',
        uri: 'commercialAdsCrossSellingProducts-accountsURI',
        modifier: 'accounts',
        label: 'CrossSellingAccount'
    },
    {
        id: 'B.12.3.2',
        title: 'commercialAdsCrossSellingProducts-cardsTitle',
        description: 'commercialAdsCrossSellingProducts-cardsDescription',
        uri: 'commercialAdsCrossSellingProducts-cardsURI',
        modifier: 'cards',
        label: 'CrossSellingCards'
    },
    {
        id: 'B.12.3.3',
        title: 'commercialAdsCrossSellingProducts-loansTitle',
        description: 'commercialAdsCrossSellingProducts-loansDescription',
        uri: 'commercialAdsCrossSellingProducts-loansURI',
        modifier: 'mortgages',
        label: 'CrossSellingMortgages'
    },
    {
        id: 'B.12.3.4',
        title: 'commercialAdsCrossSellingProducts-savingsTitle',
        description: 'commercialAdsCrossSellingProducts-savingsDescription',
        uri: 'commercialAdsCrossSellingProducts-savingsURI',
        modifier: 'deposits',
        label: 'CrossSellingDeposits'
    }
];

module.exports = {
    COMMERCIAL_ADS_TYPES,
    CROSS_SELLING_PRODUCTS
};
