require('./LastDaysDate.scss');

// @ vendor
const React = require('react');
const PropTypes = require('prop-types');

class LastDaysDate extends React.Component {

	render() {
        const {
            dateEnd,
            enabled,
            iconEnabled,
            iconFont,
            size,
            text,
        } = this.props;
        let content = null;

        if (enabled) {
            let icon;

            if (!!iconEnabled) {
                icon = <i className={`last-days-date__icon last-days-date__icon--${size} ${iconFont}`} />;
            }

            content = (
                <div className={`last-days-date last-days-date--${size}`}>
                    <div className={`last-days-date__text last-days-date__text--${size}`}>
                        {icon} {text} {dateEnd}
                    </div>
                </div>
            );
        }

        return content;
    }
}

LastDaysDate.propTypes = {
    dateEnd: PropTypes.string,
    enabled: PropTypes.bool,
    fontIcon: PropTypes.string,
    iconEnabled: PropTypes.bool,
    iconFont: PropTypes.string,
    size: PropTypes.string.isRequired,
    text: PropTypes.string,
};

LastDaysDate.defaultProps = {
    enabled: false,
    fontEnabled: false,
    iconEnabled: false,
    iconFont: '',
    text: '',
};

module.exports = LastDaysDate;
