module.exports = {

    BLOCKED_ACCOUNTS_CONTRACT: 'BNK-010',
    BLOCKED_ACCOUNTS_SIMPLE_NOTE: 'BNK-001',
    BLOCKED_ACCOUNTS_APPRAISAL: 'BNK-002',
    BLOCKED_ACCOUNTS_MORTGAGE_ASSOCIATION: 'BNK-003',
    BLOCKED_ACCOUNTS_INSURANCES_HOME: 'BNK-020',
    BLOCKED_ACCOUNTS_INSURANCES_HOME_RELATED: 'BNK-021',
    BLOCKED_ACCOUNTS_RETAIL_LOAN: 'BNK-014',
    BLOCKED_ACCOUNTS_PREGRANTED_CREDIT: 'BNK-018',
    BLOCKED_ACCOUNTS_CONTRACT_PAYROLL_LOAN: 'BNK-006',

    ETFS_SELL_CODE: 'WTH-016',
    ETFS_BUY_CODE: 'WTH-015',

    HIRE_STOCKS_CONTRACT: 'WTH-001',
    HIRE_PENSION_PLAN: 'WTH-023',
    HIRE_FUNDS: 'WTH-019',
    HIRE_CM: 'WTH-042',

    HIRE_E_ACCOUNT: 'BAK-029',
    HIRE_JUNIOR_ACCOUNT: 'BAK-030',

    HIRE_CARD_SOLIDARITY_DEBIT: 'BAK-032',
    HIRE_CARD_DIAMOND_PACK: 'BAK-033',
    HIRE_CARD_PREMIUM_PACK: 'BAK-034',
    HIRE_CARD_OPEN_CREDIT: 'BAK-035',
    HIRE_CARD_VIA_T: 'BAK-036',
    HIRE_CARD_PREPAY: 'BAK-037',
    HIRE_CARD_MINICARD: 'BAK-038',
    HIRE_CARD_E_CARD: 'BAK-039',
    HIRE_CARD_OPEN_DEBIT: 'BAK-040',
    HIRE_CARD_VIRTUAL_CREDIT: 'BAK-041',
    HIRE_CARD_VIRTUAL_DEBIT: 'BAK-042',
    HIRE_CARD_AT_HOME_ABROAD: 'BAK-043',
    HIRE_CARD_REVOLVING: 'BAK-044',
    HIRE_CARD_OPEN_YOUNG_PACK: 'BAK-045',

    HIRE_WELCOME_ACCOUNT_CODE: 'BAK-046',
    HIRE_WELCOME_PAYROLL_ACCOUNT_CODE: 'BAK-047',

    DEPOSITS_CODE: 'BAK-031',
    DEPOSITS_EXCEPTIONS_CODE: 'BAK-048',
    DEPOSITS_RENEWAL_CODE: 'BAK-049',

    STOCKS_ADVANCED_SELL_CODE: 'WTH-014',
    STOCKS_SELL_CODE: 'WTH-013',
    STOCKS_ADVANCED_BUY_CODE: 'WTH-012',
    STOCKS_BUY_CODE: 'WTH-011',

    HIRE_ROBO:'WTH-035',

    WARRANTS_SELL_CODE: 'WTH-018',
    WARRANTS_BUY_CODE: 'WTH-017',
}
