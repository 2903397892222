// @ vendors
const React = require('react');
const { has } = require('lodash');

// @ commons
const { getSplittedNumber, formatCurrency } = require('utilities/currencyHelper');
const { getLanguage } = require('core/i18n').i18n;

// @ constants
const { GENERAL_DATA } = require('constants/mortgageRequestMortgageDataTypes.js');

const MortgageRequestCurrencyHelp = customParams => {
    const params = {
        currency: has(customParams, 'currency') ? customParams.currency : '0',
        symbolCurrency: has(customParams, 'symbolCurrency') ? customParams.symbolCurrency : GENERAL_DATA.CURRENCY,
        periodicy: has(customParams, 'periodicy') ? customParams.periodicy : '',
        isFormated: has(customParams, 'isFormated') ? customParams.isFormated : false,
        classes: has(customParams, 'classes') ? customParams.classes : {},
        withDecimals: has(customParams, 'withDecimals') ? customParams.withDecimals : true
    };
    const cuotaCurrentSplitted = getSplittedNumber(params.currency);
    const getCurrentLanguage = getLanguage();
    const cuotaCurrentWhole = cuotaCurrentSplitted.whole;
    const cuotaCurrentDecimalMark = params.withDecimals ? cuotaCurrentSplitted.decimalMark : '';
    const cuotaCurrentDecimals = params.withDecimals ? cuotaCurrentSplitted.decimals : '';
    const setCurrencySymbol = formatCurrency(params.symbolCurrency);

    let periodicyPosition;
    
    if (params.periodicy){
        if (getCurrentLanguage === 'es'){
            periodicyPosition = ` ${setCurrencySymbol}/${params.periodicy}`
        } else {
            periodicyPosition = ` /${params.periodicy}`
        }
    }

    if (params.isFormated){
        const esCurrentValue = (
            <span>
                <span className={params.classes.int ? params.classes.int : ''}>{ cuotaCurrentWhole }</span>
                <span className={params.classes.decimal ? params.classes.decimal : ''}>{ cuotaCurrentDecimalMark }{ cuotaCurrentDecimals } </span>
                <span className={params.classes.symbol ? params.classes.symbol: ''}> { periodicyPosition === undefined ? setCurrencySymbol : periodicyPosition }</span>
            </span>
        );
        const enCurrentValue = (
            <span>
                <span className={params.classes.symbol ? params.classes.symbol : ''}>{ setCurrencySymbol }</span>
                <span className={params.classes.int ? params.classes.int : ''}>{ `${cuotaCurrentWhole}` }</span>
                <span className={params.classes.decimal ? params.classes.decimal: ''}>
                    { cuotaCurrentDecimalMark }{ cuotaCurrentDecimals }{ periodicyPosition }
                </span>
            </span>
        );
        return getCurrentLanguage === 'es' ? esCurrentValue : enCurrentValue;
    } else {
        if (getCurrentLanguage === 'es' && params.symbolCurrency) {
            return `${cuotaCurrentWhole}${cuotaCurrentDecimalMark}${cuotaCurrentDecimals} ${setCurrencySymbol}`;
        } else if (getCurrentLanguage === 'en' && params.symbolCurrency && !customParams.addCurrencySymbol) {
            return `${setCurrencySymbol} ${cuotaCurrentWhole}${cuotaCurrentDecimalMark}${cuotaCurrentDecimals}`;
        } else {
            return `${cuotaCurrentWhole}${cuotaCurrentDecimalMark}${cuotaCurrentDecimals}`;
        }
    }
};

module.exports = MortgageRequestCurrencyHelp;
