require('./commercialAds.scss');

// @ vendors
const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const { withRouter } = require('react-router-dom');
const { connect } = require('react-redux');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const get = require('lodash/object/get');
// @ actions
const commercialAdsAction = require('actions/commercialInterstitialsAds');
// @ components
const { LoadingSection } = require('commonsComponents/loadingSection');
const Card = require('components/cards/card/card.js');
const LastDaysLabel = require('components/commons/LastDaysLabel/LastDaysLabel');
const LastDaysDate = require('components/commons/LastDaysDate/LastDaysDate');
// @ commons
const { FormattedText, formatText, hasTranslation } = require('core/i18n').i18n;
const CrossSellingProduct = require('components/commons/commercialAds/crossSellingProduct/crossSellingProduct');
const { EmptyState } = require('commonsComponents/emptyState');
const { OKButton } = require('components/commons/OKButton/OKButton');
const { OKLink } = require('commonsComponents/OKLink');
const PromotionBanner = require('components/commons/commercialAds/promotionBanner/promotionBanner');
const { HTMLSafeInjector } = require('commonsComponents/htmlSafeInjector');
// @ helper
const {
    formatRedirectUrl,
    getCardIdAd,
    getCommercialDataFromPath,
    getFilteredCrossSellProduct,
    getIdsFromPath,
    hasPrivateUrl,
    hasPublicUrl,
    immAdIsNotEmpty,
    isAdobeFinalStatus,
    doTaggingForCommercialAdsClick,
} = require('utilities/commercialAdsHelper');
const { decodeHTMLEntities } = require('utilities/stringHelper');

// @ constants
const {
    COMMERCIAL_ADS_EMPTY_BANNER_URI,
    COMMERCIAL_ADS_CHANNEL,
    COMMERCIAL_ADS_EMPTY_WAFER_URI,
    CARD_SIZE_BIG,
    CARD_SIZE_MEDIUM
} = require('constants/index');
const { ADOBE_PRESENTED_STATUS_CODE, ADOBE_INTERESTED_STATUS_CODE } = require('constants/adobe');
const { LAST_DAYS_CMS_TAG, ADOBE } = require('constants/flagBasedFeatures');

// @ assets
const masterCardDefault = require('assets/images/Mastercard-Default.svg');
const visaDefault = require('assets/images/Visa-Default.svg');
const CROSS_SELLING_INTERACTION = 'interaccion-cross-selling-privada';
// @ hoc
const withContactCenterValidation = require('utilities/withContactCenterValidation');

class CommercialAds extends React.Component {
    constructor(props) {
        super(props);

        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    componentDidMount() {
        const {
            excludeType,
            immCard,
            immFlagBasedFeatures,
            path,
            requestAds,
            section,
            type,
        } = this.props;
        let ids = getIdsFromPath(path, excludeType);

        if (type === 'cards') {
            let commercialData = getCommercialDataFromPath(path, type, section);

            commercialData = getCardIdAd(immCard, commercialData);
            ids = [commercialData.id];
        }

        if (immFlagBasedFeatures.get(ADOBE) && type !== 'cards' && !__CONTACT_CENTER__) {
            requestAds(ids, type, immFlagBasedFeatures.get(ADOBE));
        } else {
            requestAds(ids, type);
        }
    }

    componentWillUnmount() {
        const { requestAdsReset, path } = this.props;
        if (path !== 'broker/general') {
            this.setContentAsNotInterestedIfApplies();
        }
        requestAdsReset();
    }

    setContentAsNotInterestedIfApplies = () => {
        const { path, type, section, setRemainingAdsAsClear } = this.props;

        const commercialData = getCommercialDataFromPath(path, type, section);
        const commercialDataId = commercialData.id;

        if (Array.isArray(commercialDataId)) {
            setRemainingAdsAsClear(commercialDataId)
        } else {
            setRemainingAdsAsClear([commercialDataId])
        }
    }

    updateCommercialAdsStatus = (immAd, newStatus, callback = () => { }) => {
        const { updateAdsStatusAndCallback } = this.props;
        const propId = !!immAd && immAd.get('propositionId');

        if (!propId) {
            callback();
            return;
        }

        const locationId = immAd.get('adId');
        const offerId = immAd.get('offerId');
        const offerCode = immAd.get('offerCode');

        const adobeStatus = immAd.get('adobeStatus');

        /**  We only update status if:
         *      - There's no adobeStatus (it's the first time it's being rendered)
         *      - There's a status, but it's not a final one (interes/not interested)
         *      - There's a status and it's different from the current one
         * */
        if ((!adobeStatus || (!isAdobeFinalStatus(adobeStatus) && adobeStatus !== newStatus)) && offerId) {
            const proposition = [{ propId, status: newStatus, locationId, offerId, offerCode }];
            updateAdsStatusAndCallback(proposition, callback);
        } else {
            callback();
        }

    }

    getBannerAds(commercialData) {
        const {
            immCommercialAds,
            immFlagBasedFeatures,
        } = this.props;
        const commercialDataId = commercialData.id;
        const commercialDataType = commercialData.type;
        const productAssociation = this.getComponentProductAssociation(
            commercialDataType,
            commercialDataId
        );

        const immAd = immCommercialAds.getIn(['byId', commercialDataId]);
        if (immAdIsNotEmpty(immAd)) {
            this.updateCommercialAdsStatus(immAd, ADOBE_PRESENTED_STATUS_CODE);
        }

        return (
            <PromotionBanner
                productAssociation={productAssociation}
                immCommercialAds={immCommercialAds}
                immFlagBasedFeatures={immFlagBasedFeatures}
                commercialData={commercialData}
                updateCommercialAdsStatus={this.updateCommercialAdsStatus}
            />
        );
    }

    getBannerBodyBlock(id) {
        const { immCommercialAds } = this.props;
        const immAd = immCommercialAds.getIn(['byId', id]);
        return immAd.getIn(['body', 'value']);
    }

    getCardAds(commercialData) {
        const { immCommercialAds, immCard, size } = this.props;
        commercialData = getCardIdAd(immCard, commercialData);
        const commercialDataId = commercialData.id;
        const immAd = immCommercialAds.getIn(['byId', commercialDataId]);
        const altValueDefault = formatText('card-default');
        const cardBrand = immCard.get('brand');
        const cardClass = classNames({ 'commercial-ads__figure-container--card-medium': size === CARD_SIZE_MEDIUM });

        let cardImage;
        let altValue;
        let urlValue;
        let cardImageDefault;
        let urlValueDefault;

        if (immAdIsNotEmpty(immAd)) {
            altValue = immAd.get('fieldAltText');

            if (size === CARD_SIZE_MEDIUM) {
                urlValue = immAd.getIn(['fieldImageMedium', 'url']);
            } else {
                urlValue = immAd.getIn(['fieldImageLarge', 'url']);
            }

            if (!immCommercialAds.get('isLoadingCardImages')) {
                cardImage = (
                    <Card immCommercialAds={immCommercialAds} immCard={immCard} size={size} />
                );
            }
        } else {
            if (cardBrand === 'master') {
                urlValueDefault = masterCardDefault;
            } else {
                urlValueDefault = visaDefault;
            }

            cardImageDefault = <img alt={altValueDefault} src={urlValueDefault} />;
        }

        return (
            <div className="commercial-ads__figure-container">
                <div className={cardClass}>
                    {!!urlValue && <img className="commercial-ads__figure-container-img" alt={altValue} src={urlValue} />}
                    {cardImageDefault}
                    {!urlValue &&
                        <Card
                            immCard={immCard}
                            immCommercialAds={immCommercialAds}
                            showOnlyNumber={true}
                            size={CARD_SIZE_BIG}
                        />
                    }
                    {cardImage}
                </div>
            </div>
        );
    }

    getComponentProductAssociation(adType, adId, channel = COMMERCIAL_ADS_CHANNEL) {
        return `ad-${channel}-${adType}-${adId}`;
    }

    getCrossSellingAds(commercialData) {
        const title = 'footer-title';
        const crossSellingProducts = this.getCrossSellingProducts(commercialData);

        return (
            <div className="commercial-ads__cross-selling container-fluid"
                data-gtns-component-name={CROSS_SELLING_INTERACTION}
            >
                <h2 className="commercial-ads__cross-selling--title">
                    <FormattedText value={title} />
                </h2>
                <div className="row">
                    {crossSellingProducts}
                </div>
            </div>
        );
    }

    getCrossSellingProducts(commercialData) {
        const {
            immCommercialAds,
            immFlagBasedFeatures,
        } = this.props;
        const products = get(commercialData, 'id', []);
        const commercialDataType = get(commercialData, 'type');

        const crossSellingProduct = products.map((productId, index) => {
            const immAd = immCommercialAds.getIn(['byId', productId]);
            const productAssociation = this.getComponentProductAssociation(
                commercialDataType,
                productId
            );
            let bannerUri = COMMERCIAL_ADS_EMPTY_BANNER_URI;
            let dataToRender;

            if (immAdIsNotEmpty(immAd)) {
                const altValue = immAd.get('fieldAltText');
                const urlValue = immAd.getIn(['fieldImageLarge', 'url']);
                const bodyBlock = this.getBannerBodyBlock(productId);
                const bannerUriTargetBlank = immAd.getIn(['fieldLink', 'targetBlank']);
                bannerUri = immAd.getIn(['fieldLink', 'uri']);
                // We need the content between h3 tags. https://jira.globant.com/browse/OPE099-7843
                const title = immAd.get('title');
                const handleCrossSellRedirect = () => {
                    doTaggingForCommercialAdsClick(immAd, immFlagBasedFeatures.get(ADOBE));
                    this.handleRedirectClick(bannerUri, bannerUriTargetBlank, immAd);
                };

                this.updateCommercialAdsStatus(immAd, ADOBE_PRESENTED_STATUS_CODE);

                // TODO: Check a proper implementation for the body block and
                // different type of banners.
                const isBannerC = commercialDataType === 'banner-c';
                let lastDaysLabel;
                let lastDaysDateTop;
                let lastDaysDateMiddle;
                let lastDaysDateBottom;
                let lastDaysDate;
                let innerBody;
                let labelNotBannerC;

                if (immFlagBasedFeatures.get(LAST_DAYS_CMS_TAG)) {
                    const immLastDays = immAd.get('fieldLastDays');
                    const immValidPeriod = immAd.get('fieldValidPeriod');
                    if (!!immLastDays) {
                        lastDaysLabel = (
                            <LastDaysLabel
                                color={immLastDays.get('color')}
                                date={immLastDays.getIn(['date', 'value'])}
                                dateEnd={immLastDays.getIn(['date', 'end_value'])}
                                enabled={immLastDays.get('enable')}
                                iconColor={immLastDays.getIn(['icon', 'color'])}
                                iconEnabled={immLastDays.getIn(['icon', 'enable'])}
                                iconFont={immLastDays.getIn(['icon', 'font_icon'])}
                                isBannerC={isBannerC}
                                size={immLastDays.get('size')}
                                text={immLastDays.get('text')}
                                textColor={immLastDays.get('text_color')}
                            />
                        );
                    }

                    if (!!immValidPeriod) {
                        lastDaysDate = (
                            <LastDaysDate
                                color={immValidPeriod.get('color')}
                                date={immValidPeriod.getIn(['date', 'value'])}
                                dateEnd={immValidPeriod.getIn(['date', 'end_value'])}
                                enabled={immValidPeriod.get('enable')}
                                iconEnabled={immValidPeriod.get('font_icon_enable')}
                                iconFont={immValidPeriod.get('font_icon')}
                                size={immValidPeriod.get('size')}
                                text={immValidPeriod.get('text')}
                            />
                        );
                        if (immValidPeriod.get('position') === 'top') {
                            lastDaysDateTop = lastDaysDate;
                        } else if (immValidPeriod.get('position') === 'middle') {
                            lastDaysDateMiddle = lastDaysDate;
                        } else if (immValidPeriod.get('position') === 'bottom') {
                            lastDaysDateBottom = lastDaysDate;
                        }
                    }
                }

                if (isBannerC) {
                    innerBody = (
                        <div className="commercial-ads__figure-cross-sell">
                            {lastDaysLabel}
                            {lastDaysDate}
                            <HTMLSafeInjector message={title} />
                            <HTMLSafeInjector message={bodyBlock} />
                        </div>
                    );
                } else {
                    labelNotBannerC = lastDaysLabel;
                    innerBody = (
                        <div className="commercial-ads__figure-cross-sell">
                            {lastDaysDateTop}
                            <HTMLSafeInjector message={title} />
                            {lastDaysDateMiddle}
                            <HTMLSafeInjector message={bodyBlock} />
                            {lastDaysDateBottom}
                        </div>
                    );
                }

                dataToRender = (
                    <div
                        id={productAssociation}
                        key={index}
                        className="commercial-ads__figure-body col-xs-12 col-sm-6 col-md-3"
                        onClick={handleCrossSellRedirect}
                        title={decodeHTMLEntities(title)}
                    >
                        <div
                            alt={altValue}
                            className="commercial-ads__content-width-background"
                            style={{ backgroundImage: `url(${urlValue})` }}>
                            {labelNotBannerC}
                            {innerBody}
                        </div>
                    </div>
                );
            } else {
                dataToRender = this.getEmptyCrossSelling(index, productAssociation, productId);
            }

            return dataToRender;
        });

        return crossSellingProduct;
    }

    getEmptyCrossSelling(index, productAssociation, productId) {
        let { description, modifier, title, uri } = getFilteredCrossSellProduct(productId);
        const handleEmptyCrossSellRedirect = () => {
            this.handleRedirectClick(formatText(uri), false);
        };
        title = hasTranslation(title) ? formatText(title) : title;
        description = hasTranslation(description) ? formatText(description) : description;

        return (
            <div
                id={productAssociation}
                key={index}
                className="col-xs-12 col-sm-6 col-md-3"
                title={title}>
                <CrossSellingProduct
                    description={description}
                    modifier={modifier}
                    title={title}
                    onClick={handleEmptyCrossSellRedirect}
                />
            </div>
        );
    }

    getWaferAds(commercialData) {
        const { immCommercialAds, bgTransparent, customWaferTexts } = this.props;
        const commercialDataId = commercialData.id;
        const commercialDataType = commercialData.type;
        const commercialDataLabel = commercialData.label;
        const immAd = immCommercialAds.getIn(['byId', commercialDataId]);
        const translationPrefix = `commercialAds-empty${commercialDataLabel}`;
        const productAssociation = this.getComponentProductAssociation(
            commercialDataType,
            commercialDataId
        );
        const dataGtnscomponentName = productAssociation.replace(/-/g, ' ');
        let waferPrimaryText = formatText(`${translationPrefix}PrimaryText`);
        let waferButtonText = `${translationPrefix}ButtonText`;
        let waferButtonContent = <FormattedText value={waferButtonText} />;
        let waferUri = COMMERCIAL_ADS_EMPTY_WAFER_URI;
        let waferUriTargetBlank = false;

        if (get(customWaferTexts, 'waferPrimaryText', false)) {
            waferPrimaryText = get(customWaferTexts, 'waferPrimaryText', '');
            waferButtonContent = get(customWaferTexts, 'waferButtonContent', '');
            waferUri = get(customWaferTexts, 'waferUri', '');
            waferUriTargetBlank = get(customWaferTexts, 'waferUriTargetBlank', false);

        } else if (immAd && !immAd.isEmpty() && immAd.get('body') && immAd.get('fieldLink') && immAd.get('body').size && immAd.get('fieldLink').size) {
            waferPrimaryText = immAd.getIn(['body', 'value']);
            waferButtonContent = immAd.getIn(['fieldLink', 'title']);
            waferUri = immAd.getIn(['fieldLink', 'uri']);
            waferUriTargetBlank = immAd.getIn(['fieldLink', 'targetBlank']);

            this.updateCommercialAdsStatus(immAd, ADOBE_PRESENTED_STATUS_CODE);
        }

        const waferStateButtonBlock = __CONTACT_CENTER__ && hasPublicUrl(waferUri) ? null : (
            <OKLink
                extraClass="buttons-base buttons-base--small buttons-base--secondary link-to-button"
                to={waferUri}
            >
                <span>
                    {waferButtonContent}
                </span>
            </OKLink>
        );
        const emptyWaferClasses = classNames('commercial-ads__empty-wafer', {
            'commercial-ads__empty-wafer--bg-color': !bgTransparent
        });

        return (
            <section
                id={productAssociation}
                data-gtns-component-name={dataGtnscomponentName}
                className={emptyWaferClasses}
            >
                <EmptyState primaryText={waferPrimaryText} button={waferStateButtonBlock} iconType="financiation-banking" />
            </section>
        );
    }

    buildCommercialAds() {
        const { path, type, section } = this.props;

        const commercialData = getCommercialDataFromPath(path, type, section);
        const adType = this.isAdLoading(commercialData);
        let dataToRender = null;

        // TODO: Create different components for ad types
        switch (adType || type) {
            case 'banner-a':
            case 'banner-b':
            case 'banner-c':
            case 'banner-d':
                dataToRender = this.getBannerAds(commercialData);
                break;
            case 'cards':
                dataToRender = this.getCardAds(commercialData);
                break;
            case 'cross-selling':
                dataToRender = this.getCrossSellingAds(commercialData);
                break;
            case 'wafer':
                dataToRender = this.getWaferAds(commercialData);
                break;
            case 'loading':
                dataToRender = <LoadingSection />;
        }

        return dataToRender;
    }

    handleRedirectClick(uri = COMMERCIAL_ADS_EMPTY_BANNER_URI, targetBlank = true, immAd) {

        // This will be called after ad status is updated
        const callback = () => {
            if (targetBlank) {
                window.open(uri, '_blank');
            } else {
                if (hasPublicUrl(uri)) {
                    uri = formatRedirectUrl(uri);
                    window.location.href = uri;
                } else if (hasPrivateUrl(uri)) {
                    this.props.history.push(uri);
                } else {
                    window.location.href = uri;
                }
            }
        }

        this.updateCommercialAdsStatus(immAd, ADOBE_INTERESTED_STATUS_CODE, callback);
    }

    isAdLoading(commercialData) {
        const { immCommercialAds } = this.props;
        let adType = null;

        if (commercialData) {
            adType = commercialData.type;

            if ((immCommercialAds.get('isFetching') && adType !== 'interstitial') ||
                (immCommercialAds.get('isLoadingCardImages') && adType === 'cards')) {
                adType = 'loading';
            }
        }

        return adType;
    }

    render() {
        const { extraClass } = this.props;
        const className = classNames('commercial-ads', extraClass);
        const commercialAds = this.buildCommercialAds();

        return (
            <div className={className}>
                {commercialAds}
            </div>
        );
    }
}

CommercialAds.propTypes = {
    path: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        'banner-a',
        'banner-b',
        'banner-c',
        'baner-d',
        'cards',
        'cross-selling',
        'wafer'
    ]).isRequired,
    extraClass: PropTypes.string,
    excludeType: PropTypes.string,
    history: PropTypes.object.isRequired,
    immCommercialAds: PropTypes.object.isRequired,
    immFlagBasedFeatures: PropTypes.object.isRequired,
    requestAds: PropTypes.func.isRequired,
    immCard: PropTypes.object,
    section: PropTypes.string,
    size: PropTypes.string,
    bgTransparent: PropTypes.bool,
    updateAdsStatus: PropTypes.func,
    updateAdsStatusAndCallback: PropTypes.func,
    setRemainingAdsAsClear: PropTypes.func,
    requestAdsReset: PropTypes.func,
    customWaferTexts: PropTypes.object,
};

const connectedCommercialAds = connect(
    state => ({
        immCommercialAds: state.commercialAds,
        immFlagBasedFeatures: state.flagBasedFeatures,
    }),
    {
        requestAds: commercialAdsAction.requestAds,
        requestAdsReset: commercialAdsAction.requestAdsReset,
        updateAdsStatus: commercialAdsAction.updateAdsStatus,
        updateAdsStatusAndCallback: commercialAdsAction.updateAdsStatusAndCallback,
        setRemainingAdsAsClear: commercialAdsAction.setRemainingAdsAsClear
    }
)(withRouter(CommercialAds));

module.exports.constructor = connectedCommercialAds;
module.exports.CommercialAds = connectedCommercialAds;
module.exports.CommercialAdsCCValidated = withContactCenterValidation(connectedCommercialAds)

