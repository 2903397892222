require('./signaturePatternCodeInput.scss');

// @ vendor
const PropTypes = require('prop-types');
const React = require('react');
const classnames = require('classnames');
const PureRenderMixin = require('react-addons-pure-render-mixin');

// @ core
const { FormattedText } = require('core/i18n').i18n;

// @ components
const { OKButton } = require('components/commons/OKButton/OKButton');
const SignaturePasscode = require('components/signaturePattern/signaturePatternCodeInput/signaturePasscode/signaturePasscode');

// @ utilities
const { createDigitsEnumeration } = require('utilities/signaturePatternHelper');

// @ constants
const { SENDING_SIGNATURE_KEY } = require('constants/flagBasedFeatures.js');

class SignaturePatternCodeInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnInputDigit = this.handleOnInputDigit.bind(this);
        this.buildErrorMessage = this.buildErrorMessage.bind(this);
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    buildErrorMessage() {
        const {immSignaturePattern} = this.props;
        const error = immSignaturePattern.get('isFetchingSignaturePassCodeError');
        if ( !error && !immSignaturePattern.get('showRequiredSignatureCode') ) return null;
        return (
            <p id="msg_signaturePatternCodeInput_inputRequired" className="signature-pattern-code-input__error">
                <FormattedText value={`${error ? 'signaturePattern-confirmOldSignature' : 'signaturePattern-incompleteOldSignature'}`} />
            </p>
        );
    }

    handleOnInputDigit(newDigit, position) {
        const {validateDigitInput} = this.props;
        validateDigitInput({newDigit, position});
    }

    buildTitle() {
        const { isInVerticalWizard } = this.props;
        if (__CONTACT_CENTER__ && isInVerticalWizard) return null;

        return (
            <div className="signature-pattern-code-input__title">
                <FormattedText value="signaturePattern-codeTitle" />
            </div>
        );
    }

    buildSendSignatureKeyInfoText() {
        const { immFlagBasedFeatures } = this.props;
        let sendSignatureKeyInfoText = '';
        let sendSignatureKeyInfoContainer;

        if (!__CONTACT_CENTER__) {
            sendSignatureKeyInfoText = immFlagBasedFeatures.get(SENDING_SIGNATURE_KEY)
            ? 'sendSignatureKey-signaturePatternInfoTextWithLink'
            : 'sendSignatureKey-signaturePatternContactInfoText';
            sendSignatureKeyInfoText = (sendSignatureKeyInfoText || '').trim();
            sendSignatureKeyInfoContainer = (
                sendSignatureKeyInfoText && (
                    <div className="signature-pattern-code-input__send-key-info">
                        <FormattedText value={sendSignatureKeyInfoText} avoidTargetBlank />
                    </div>
                )
            );
        }
        return sendSignatureKeyInfoContainer;
    }

    render() {
        const { immSignaturePattern, disabled, withValidateButton, isResetSignature } = this.props;
        const isFetchingConfigSuccess = immSignaturePattern.get('isFetchingConfigSuccess');
        const isFetchingSignaturePassCode = immSignaturePattern.get('isFetchingSignaturePassCode');
        const isFetchingSignaturePassCodeSuccess = immSignaturePattern.get('isFetchingSignaturePassCodeSuccess');
        const isFetchingSignaturePassCodeFailure = immSignaturePattern.get('isFetchingSignaturePassCodeFailure');
        const consumerFinishInputDigits = immSignaturePattern.get('consumerFinishInputDigits');
        // translate human positions >> [1,2,3] to arrays valid positions [0, 1, 2].
        const immRequiredDigits = immSignaturePattern.get('requiredDigits').map(digit => digit - 1);
        const immDigitsValues = immSignaturePattern.get('consumerInputDigits');
        const showTitle = this.props.displayTitle ? this.buildTitle() : null;
        const classNameSignatureButton = classnames(
            'signature-pattern-code-input__signature-button',
            {
                'signature-pattern-code-input__signature-button--hidden': !isFetchingConfigSuccess
            }
        );

        let buttonSignaturePatternEl;
        if (consumerFinishInputDigits && !isFetchingSignaturePassCodeSuccess) {
            if (withValidateButton || (!immSignaturePattern.get('isFetchingSignaturePassCode') && immSignaturePattern.get('requiredSMS'))) {
                //show OK button only if otp validation is needed next.
                const loading = (
                    immSignaturePattern.get('isFetchingConfig') ||
                    immSignaturePattern.get('isFetchingSMSCode') ||
                    immSignaturePattern.get('isFetchingSignaturePassCode') ||
                    immSignaturePattern.get('callbackCalled')
                );
                buttonSignaturePatternEl = (
                    <div className="signature-pattern-code-input__signature-ok-container">
                        <OKButton
                            loading={loading}
                            onClick={this.props.handleProcessSignaturePassCodeWithoutCallback}
                            modifier="secondary"
                            disabled={disabled}
                        >
                            <FormattedText value="signaturePasscode-ok"/>
                        </OKButton>
                    </div>
                );
            }
        } else if (isFetchingSignaturePassCodeSuccess) {
            buttonSignaturePatternEl = (
                <div className="signature-pattern-code-input__signature-ok-container">
                    <div className="signature-pattern-code-input__icon-container">
                        <i className="signature-pattern-code-input__icon icon icon-check"/>
                    </div>
                </div>
            );
        } else if (isFetchingSignaturePassCodeFailure) {
            buttonSignaturePatternEl = (
                <div className="signature-pattern-code-input__signature-ok-container">
                    <OKButton className={classNameSignatureButton} modifier="secondary" disabled={disabled}>
                        ERROR
                    </OKButton>
                </div>
            );
        }
        
        const errorMessage = this.buildErrorMessage();
        const sendSignatureKeyInfo = this.buildSendSignatureKeyInfoText();
        const requiredDigitsJS = immSignaturePattern.get('requiredDigits').toJS();
        const injectedStrings = (__CONTACT_CENTER__) ? [createDigitsEnumeration(requiredDigitsJS)] : requiredDigitsJS;
        const signaturePositionsValue = !!isResetSignature
            ? "signaturePattern-resetSignaturePositionsInfoText"
            : "signaturePattern-signaturePositionsInfoText"
        ;

        return (
            <div className="signature-pattern-code-input">
                {showTitle}
                <div className="signature-pattern-code-input__description">
                    <FormattedText
                        value={signaturePositionsValue}
                        injectedStrings={injectedStrings}
                    />
                </div>
                {sendSignatureKeyInfo}
                <SignaturePasscode
                    disabled={isFetchingSignaturePassCodeSuccess || isFetchingSignaturePassCode || disabled}
                    immRequiredDigits={immRequiredDigits}
                    immDigitsValues={immDigitsValues}
                    onChange={this.handleOnInputDigit}
                    onKeyDown={this.handleOnInputDigit}
                />
                {buttonSignaturePatternEl}
                <div className="clearfix"></div>
                {errorMessage}
            </div>
        );
    }
}

SignaturePatternCodeInput.propTypes = {
    displayTitle: PropTypes.bool,
    immFlagBasedFeatures: PropTypes.object,
    immSignaturePattern: PropTypes.object.isRequired,
    validateSignaturePassCode: PropTypes.func,
    handleProcessSignaturePassCodeWithoutCallback: PropTypes.func,
    validateDigitInput: PropTypes.func,
    isInVerticalWizard: PropTypes.bool,
    withValidateButton: PropTypes.bool,
    disabled: PropTypes.bool,
    isResetSignature: PropTypes.bool

};

SignaturePatternCodeInput.defaultProps = {
    isInVerticalWizard: false,
    disabled: false,
    displayTitle: true,
    isResetSignature: false,
};

module.exports = SignaturePatternCodeInput;