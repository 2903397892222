const EXTENSIONS = {
    'image/gif': 'gif',
    'image/jpeg': 'jpg',
    'image/pjpeg': 'jpg',
    'image/png': 'png',
    'image/x-png': 'png',
    'text/plain': 'txt',
    'application/pdf': 'pdf',
    'application/force-download': 'pdf',
    'application/x-download': 'pdf',
    'binary/octet-stream': 'pdf'
};

module.exports = EXTENSIONS;
