require('./dropdownAccounts.scss');
// @vendors
const React = require('react');
const PropTypes = require('prop-types');
const OKCustomSelect = require('./OKCustomSelect');
const { formatText, FormattedText } = require('core/i18n').i18n;
const classnames = require('classnames');
const AccountItemSelected = require('components/dropdownAccounts/accountItemSelected');
const AccountItemOptions = require('components/dropdownAccounts/accountItemOptions');
const AccountItemNoBalanceSelected = require('components/dropdownAccounts/accountItemNoBalanceSelected');
const AccountItemNoBalanceOptions = require('components/dropdownAccounts/accountItemNoBalanceOptions');
const { toCamelCase } = require('utilities/stringHelper');
const PureRenderMixin = require('react-addons-pure-render-mixin');
// @commons
const { OKMessage } = require('commonsComponents/OKMessage');
const { LoadingSection } = require('commonsComponents/loadingSection');
// @utilities
const { getBlockedAccountsFlags } = require('utilities/blockedAccountsHelper');

class DropdownAccounts extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    componentDidMount() {
        const {
            accountsIndexes,
            blockedAccountsAll,
            currentAccountId,
            initialAccountId,
            onAccountChange,
        } = this.props;
        const idAccount = accountsIndexes.length === 1 ? accountsIndexes[0] : initialAccountId;

        if (idAccount && !currentAccountId && !blockedAccountsAll) {
            onAccountChange(idAccount, true);
        }
    }

    handleBlur() {
        if (!this.props.currentAccountId) {
            this.props.onAccountChange();
        }
    }

    handleChange(item) {
        if (!!item.value && !item.isInactiveAccount && !item.isNegativeBalance) {
            this.props.onAccountChange(item.value);
        }
    }

    buildOptions() {
        const {
            immAccounts,
            accountsIndexes,
            blockedAccountsIds,
            filterId,
            id,
            showAvailableBalance,
            showNegativeBalanceAccounts,
            disableOptionCriteria
        } = this.props;

        let options = accountsIndexes.filter(opt => !!opt);

        if (!!filterId) {
            options = options.filter(value => { return value !== filterId });
        }

        return options.map(accountId => {
            const immAccount = immAccounts.get(accountId);
            const alias = immAccount.get('aliasTentative') || immAccount.get('alias') || toCamelCase(immAccount.get('accountType'));
            let option = {
                id,
                iban: immAccount.get('iban'),
                alias,
                type: immAccount.get('catalogData').get('product'),
                balance: showAvailableBalance ? immAccount.getIn(['balances', 'availableBalance']) : immAccount.get('balance'),
                isInactiveAccount: false,
                isNegativeBalance: false
            }

            if (disableOptionCriteria(immAccount)) {
                option.disabled = true;
            }
            else {
                option.value = accountId
            }

            if (blockedAccountsIds.includes(accountId)) {
                option.disabled = true;
                option.isInactiveAccount = true;
            }

            if (showNegativeBalanceAccounts && immAccount.get('isNegativeBalance')) {
                option.disabled = true;
                option.isNegativeBalance = true;
            }

            return option;
        });
    }

    buildDisclaimer() {
        const {
            blockedAccountsIds,
            accountsIndexes,
            disclaimer,
            savingsAccounts,
            showDisclaimer
        } = this.props;
        const blockedAccountFlags = getBlockedAccountsFlags(accountsIndexes,blockedAccountsIds);
        let content = null;
        const savingsAccountsDisclaimer = (
            <div className="dropdown-accounts__disclaimer">
                <OKMessage
                    type={'informative'}
                >
                    <FormattedText
                        value={"dropdownAccounts-dropdownAccounts_disclaimerSavings"}
                    />
                </OKMessage>
            </div> 
        )
        if (blockedAccountFlags.atLeastOneBlocked && showDisclaimer) {
            const messageType = blockedAccountFlags.allBlocked ? 'warning' : 'important';
            const disclaimerValue = disclaimer[messageType];
            content = (
                <React.Fragment>
                    <div className="dropdown-accounts__disclaimer">
                        <OKMessage
                            type={messageType}
                        >
                            <FormattedText
                                value={disclaimerValue}
                            />
                        </OKMessage>
                    </div>
                    {savingsAccounts.length > 0 && savingsAccountsDisclaimer}
                </React.Fragment>
            )
        } else if (savingsAccounts.length) {
            content = savingsAccountsDisclaimer;
        }
        return content;
    }

    render() {
        const {
            blockedAccountsIds,
            currentAccountId,
            detailsLabel,
            enabled,
            id,
            loading,
            error,
            errorMsg,
            label,
            onOpen,
            optionalElement,
            subLabelElement,
            tooltipMsg,
            showBalance,
            contextPlaceholder
        } = this.props;
        const searchable = false;
        const clearable = false;
        const placeholder = !!contextPlaceholder ? contextPlaceholder : formatText('transfers-selectAccount');
        const options = this.buildOptions();
        const disclaimer = this.buildDisclaimer();

        let accountItemOptions = !showBalance ? AccountItemNoBalanceOptions : AccountItemOptions;
        let accountItemSelected = !showBalance ? AccountItemNoBalanceSelected : AccountItemSelected;
        // Don't allow the currentAccountId be selected when is blocked
        const currentValue = !blockedAccountsIds.includes(currentAccountId) ? currentAccountId : null;
        const accountDetails = detailsLabel && !error ? (
            <span className="dropdown-accounts__associated-cc-legend">
                {detailsLabel}
            </span>
        ) : null;
        const content = loading ? <LoadingSection /> : (
            <OKCustomSelect
                id={id}
                label={label}
                optionalElement={optionalElement}
                name="form-field-name"
                className={classnames('dropdown-accounts', { 'dropdown-accounts--error': error })}
                disabled={!enabled}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                optionComponent={accountItemOptions}
                options={options}
                value={currentValue}
                valueComponent={accountItemSelected}
                searchable={searchable}
                clearable={clearable}
                placeholder={placeholder}
                errorState={error}
                errorMessage={errorMsg}
                subLabelElement={subLabelElement}
                tooltipMsg={tooltipMsg}
                tooltipOffSet={[0, -15]}
                onOpen={onOpen}
            />
        );

        return (
            <React.Fragment>
                {content}
                {accountDetails}
                {disclaimer}
            </React.Fragment>
        );
    }
}

DropdownAccounts.propTypes = {
    accountsIndexes: PropTypes.array,
    blockedAccountsAll: PropTypes.bool,
    blockedAccountsIds: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    contextPlaceholder: PropTypes.string,
    currentAccountId: PropTypes.string,
    detailsLabel: PropTypes.string,
    disableOptionCriteria: PropTypes.func,
    disclaimer: PropTypes.shape({
        important: PropTypes.string,
        warning: PropTypes.string,
    }),
    enabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    filterId: PropTypes.string,
    id: PropTypes.string,
    immAccounts: PropTypes.object,
    initialAccountId: PropTypes.string,
    label: PropTypes.string,
    loading: PropTypes.bool,
    onAccountChange: PropTypes.func,
    onOpen: PropTypes.func,
    optionalElement: PropTypes.element,
    showAvailableBalance: PropTypes.bool,
    showBalance: PropTypes.bool,
    showNegativeBalanceAccounts: PropTypes.bool,
    subLabelElement: PropTypes.element,
    tooltipMsg: PropTypes.any,
    savingsAccounts:PropTypes.array,
    showDisclaimer: PropTypes.bool
};

DropdownAccounts.defaultProps = {
    blockedAccountsIds: [],
    blockedAccountsAll: false,
    detailsLabel: null,
    disclaimer: {
        important: 'dropdownAccounts-dropdownAccounts_disclaimerError',
        warning: 'dropdownAccounts-dropdownAccounts_disclaimerWarning'
    },    
    filterId: '',
    enabled: true,
    errorMsg: '',
    label: '',
    loading: false,
    optionalElement: null,
    showAvailableBalance: false,
    subLabelElement: null,
    tooltipMsg: '',
    showBalance: true,
    showInactiveAccountsAsBlocked: false,
    showNegativeBalanceAccounts: false,
    disableOptionCriteria: () => { return false },
    savingsAccounts: [],
    showDisclaimer: true,
}
module.exports = DropdownAccounts;
