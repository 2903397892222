const PropTypes = require('prop-types');
// @vendors
const React = require('react');

// @helpers
const { formatText, getLanguage } = require('core/i18n').i18n;
const { getLastNChars } = require('utilities/stringHelper');
const { formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');

// @components
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');

class StocksContractDropdownFuturesOption extends React.Component {
    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    handleMouseDown (event) {
        this.props.onSelect(this.props.option, event);
    }

    handleMouseMove (event) {
        if (!this.props.isFocused) {
            this.props.onFocus(this.props.option, event);
        }
    }

    render() {
        const { option, className } = this.props;
        const { nombretitular, iban, balance } = option;
        const optionClassName = `stocks-contract-dropdown__option ${className}`;
        const ibanLastChars = getLastNChars(iban);
        const plainAmountObject = getPlainAmountObject(balance);

        return (
            <div
                className={optionClassName}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
            >
                <span className="stocks-contract-dropdown__value-label stocks-contract-dropdown__option--highlighted">
                    {nombretitular}
                </span>
                <span className="stocks-contract-dropdown__value--medium">
                    {ibanLastChars}
                </span>

                <span className="stocks-contract-dropdown__option--right">
                    <span>
                        {formatText('broker-stocksContractDropdownDerivativesTransferBalance')}
                    </span>
                    <span className="stocks-contract-dropdown__value-price stocks-contract-dropdown__option--highlighted">
                        <OneLineSymbolAmount
                            amountObject={plainAmountObject}
                            className="stocks-contract-dropdown__option-balance"
                            language={getLanguage()}
                            amountObjectFormatted={formatAmountObject(plainAmountObject, true)}
                        />
                    </span>
                </span>
            </div>
        );
    }
}

StocksContractDropdownFuturesOption.propTypes = {
    className: PropTypes.string,
    option: PropTypes.object.isRequired,
    isFocused: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired
};

module.exports = StocksContractDropdownFuturesOption;