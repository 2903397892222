module.exports = {
    DEPOSIT_13_MONTHS_LEAK_RETENTION_PRODUCT_TYPE: '302',
    DEPOSIT_13_MONTHS_LEAK_RETENTION_PRODUCT_SUBTYPE: '868',
    DEPOSIT_13_MONTHS_LEAK_RETENTION_PRODUCT_STANDARD: '0000003',
    DEPOSIT_13_MONTHS_DUE_DATES_PRODUCT_TYPE: '302',
    DEPOSIT_13_MONTHS_DUE_DATES_PRODUCT_SUBTYPE: '868',
    DEPOSIT_13_MONTHS_DUE_DATES_PRODUCT_STANDARD: '0000005',
    DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_TYPE: '302',
    DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_SUBTYPE: '868',
    DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_STANDARD: '0000002',
};
