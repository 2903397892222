const { formatText } = require('core/i18n').i18n;
const { sortByOrder } = require('lodash/collection');

const categoriesList = [
    'Casa y hogar',
    'Transporte y automoción',
    'Educación, salud, belleza y bienestar',
    'Comercio y tiendas',
    'Ocio y viajes',
    'Restaurantes',
    'Seguros, organismos y ONG’s',
    'Otros gastos',
    'Cajeros',
    'Transferencias, gastos bancarios y préstamos',
    'Ingresos',
    'Inversiones y ahorro',
    'Movimientos excluidos de la contabilidad',
];

const CATEGORY_KEYS = {
    INCOME_CATEGORY: 'Ingresos'
}

const getDataByCategory = (category, data) => {
    let categoryData = {
        icon: '',
        subcategories: []
    };
    category = category.toLowerCase();
    switch (category) {
        case 'casa y hogar':
            categoryData.icon = 'icon-casa-hogar';
            categoryData.subcategories = [
                'Agua',
                'Alimentación y supermercados',
                'Alquiler',
                'Comunidad',
                'Decoración, mobiliario y mantenimiento del hogar',
                'Electricidad',
                'Gas',
                'Otros (Casa y hogar)',
                'Servicio doméstico, limpieza y tintorería',
                'Telefonía, Internet y televisión'
            ];
            break;
        case 'transporte y automoción':
            categoryData.icon = 'icon-transporte-automacion';
            categoryData.subcategories = [
                'Compra, mantenimiento y reparación de vehículo',
                'Gasolineras',
                'Transporte público, taxis, autopistas y aparcamientos',
                'Otros (Transporte y automoción)',
            ];
            break;
        case 'educación, salud, belleza y bienestar':
            categoryData.icon = 'icon-eduacion-salud-belleza';
            categoryData.subcategories = [
                'Clínicas y atención médica',
                'Colegios, universidades, academias y cursos',
                'Farmacia y óptica',
                'Gimnasio y clubes deportivos',
                'Peluquería, estética y productos de belleza',
                'Otros (Colegios, universidades, academias y cursos)',
            ];
            break;
        case 'comercio y tiendas':
            categoryData.icon = 'icon-comercio-tiendas';
            categoryData.subcategories = [
                'Electrónica, libros, prensa, música y Apps',
                'Juguetes, regalos y pequeños comercios',
                'Otros (Comercio y tiendas)',
                'Ropa, zapatos, accesorios y deportes',
                'Envío a domicilio y otros comercios',
            ];
            break;
        case 'ocio y viajes':
            categoryData.icon = 'icon-viajes';
            categoryData.subcategories = [
                'Actividades de ocio y tiempo libre',
                'Espectáculos y museos',
                'Hoteles y alojamiento',
                'Vuelos, trenes y alquiler de automóvil',
                'Loterías y juegos de azar',
                'Música y TV online',
                'Otros (Ocio y viajes)',
            ];
            break;
        case 'restaurantes':
            categoryData.icon = 'icon-restaurantes';
            categoryData.subcategories = ['Restaurantes, cafeterías y bares'];
            break;
        case 'seguros, organismos y ongs':
        case 'seguros, organismos y ong’s':
            categoryData.icon = 'icon-banco-ong-seguros';
            categoryData.subcategories = [
                'Asociaciones, donaciones y ONGs',
                'Impuestos y multas',
                'Otros (Seguros, organismos y ONGs)',
                'Seguros'
            ];
            break;
        case 'otros gastos':
            categoryData.icon = 'icon-otros-gastos';
            categoryData.subcategories = ['Otros gastos'];
            break;
        case 'cajeros':
            categoryData.icon = 'icon-cajero';
            categoryData.subcategories = ['Cajeros'];
            break;
        case 'transferencias, gastos bancarios y préstamos':
            categoryData.icon = 'icon-transferir';
            categoryData.subcategories = [
                'Efectivo y cheques',
                'Gastos y comisiones bancarias',
                'Préstamos, hipoteca y renting',
                'Transferencias',
                'Tarjetas',
                'Tarjetas Prepago',
                'Otros (Transferencias, gastos bancarios y préstamos)',
            ];
            break;
        case 'ingresos':
            categoryData.icon = 'icon-ingresos';
            categoryData.subcategories = [
                'Bonificaciones cuenta',
                'Ingresos por nómina',
                'Ingresos por transferencias',
                'Intereses, dividendos y rendimientos',
                'Ingresos en efectivo',
                'Ingresos profesionales',
                'Otros (Ingresos)',
            ];
            break;
        case 'inversiones y ahorro':
            categoryData.icon = 'icon-inversiones-ahorros';
            categoryData.subcategories = [
                'Ahorro',
                'Depósitos, valores, fondos y planes de pensiones',
                'Otros (Inversiones y ahorro)',
            ];
            break;
        case 'movimientos excluidos de la contabilidad':
            categoryData.icon = 'icon-mov-excluidos';
            categoryData.subcategories = [
                'Liquidaciones de tarjeta de crédito',
                'Otros (Movimientos excluidos de la contabilidad)',
                'Transferencias entre cuentas propias'
            ];
            break;
    }

    return categoryData[data];
};

const getCategoryIcon = category => {
    if (!category) {
        return '';
    }
    return getDataByCategory(category, 'icon');
};

const categoryOptions = function() {
    const categoriesUnsorted = categoriesList.map(key => {
        return {
            value: key,
            label: formatText('categories-' + key)
        }
    });
    return sortByOrder(categoriesUnsorted, ['label'], ['asc']);
};

const subcategoryOptions = function(subcategoriesList, category) {
    const subcategoriesUnsorted = subcategoriesList.map( key => {
        return {
            value: key,
            label: formatText(category + '-' + key)
        }
    });

    return sortByOrder(subcategoriesUnsorted, ['label'], ['asc']);
};

const getSubcategoryOptions = category => {
    if (!category) {
        return [];
    }

    const subcategoriesList = getDataByCategory(category, 'subcategories')
    return subcategoryOptions(subcategoriesList, category);
}

const onlyExpensesCategoriesList = categoriesList.filter(
    category => category !== 'Ingresos' && category !== 'Movimientos excluidos de la contabilidad'
);

const hideCategoryListForOpenYoung = [
    'Ingresos',
    'Movimientos excluidos de la contabilidad',
    'Seguros, organismos y ONG’s',
    'Transferencias, gastos bancarios y préstamos',
    'Inversiones y ahorro',
];
const onlyOpenYoungExpensesCategoriesList = categoriesList.filter(
    category => !hideCategoryListForOpenYoung.includes(category)
).sort();

const includesCategory = searchCategory => {
    return categoriesList.some(category => searchCategory.toLowerCase() === category.toLowerCase());
};

module.exports = {
    CATEGORY_KEYS,
    onlyExpensesCategoriesList,
    onlyOpenYoungExpensesCategoriesList,
    categoriesList,
    includesCategory,
    getCategoryIcon,
    getSubcategoryOptions,
    categoryOptions
};
