const React = require('react');
const { FormattedText } = require('core/i18n').i18n;

const { COUNTRIES_CODES: { PT } } = require('constants/countries');

module.exports = {
    AMOUNT_FILTER_LABEL: <FormattedText value={'myMoneyTransactions-amount'}/>,
    AMOUNT_FILTER_GREATER_THAN: <FormattedText value={'myMoneyTransactions-greaterThan'}/>,
    AMOUNT_FILTER_LOWER_THAN: <FormattedText value={'myMoneyTransactions-lowerThan'}/>,

    CLEAR_ALL_FILTERS_BUTTON_LABEL: <FormattedText value="filters-clean" format="camelCase"/>,

    DATE_PICKER_CUSTOM_PLACEHOLDER: 'datePickerCustom-placeholder',
    DATE_PICKER_CUSTOM_LOCALE: 'datePickerCustom-locale',
    DATE_PICKER_CUSTOM_DATE_FORMAT: 'datePickerCustom-dateFormat',
    DATE_PICKER_LOWERCASE_MONTH_COUNTRIES: [PT],

    DRAG_N_DROP_UPLOAD_AMOUNT_FILES_LIMIT: 1,
    DRAG_N_DROP_UPLOAD_IS_MULTIPLE_DEFAULT_VALUE: false,
    DRAG_N_DROP_UPLOAD_MAX_LENGTH_FILE_NAME: 255,
    DRAG_N_DROP_UPLOAD_MAX_SIZE: 5120,
    DRAG_N_DROP_UPLOAD_VALID_TYPES_FILES: ['pdf','jpg','jpeg','png'],
    DRAG_N_DROP_UPLOAD_TITLE: <FormattedText value="dragNDropUpload-deleteFile" />,
    DRAG_N_DROP_UPLOAD_FILE_TEXT: <FormattedText value="dragNDropUpload-file" />,
    DRAG_N_DROP_UPLOAD_ERROR_MESSAGE: <FormattedText value="dragNDropUpload-defaultErrorMessage" />,

    LABEL_DATE_TODAY: <FormattedText value="labelDate-today" />,
    LABEL_DATE_YESTERDAY: <FormattedText value="labelDate-yesterday" />,

    LAST_MOVEMENTS_TABLE_CURRENT_MONTH_KEY: <FormattedText value="myMoneyTransactions-thisMonth"/>
};
