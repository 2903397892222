const NERUDA_CARD_BONIFICATION_LEVELS = {
    '0000001': 0.5,
    '0000002': 1,
    '0000003': 1.5,
    '0000004': 0.5,
    '0000005': 0.75,
    '0000006': 1,
    '0000007': 0.5,
    '0000008': 1,
    '0000009': 1.5,
    '0000010': 0.5,
    '0000011': 0.75,
    '0000012': 1,
    '0000013': 0,   // No rewards for this standard
    '0000014': 0,   // No rewards for this standard
};

const NO_PRIME = 'noPrime';
const PRIME = 'prime';

const NERUDA_CARD_BONIFICATION_STATUS = {
    '0000001': PRIME,
    '0000002': PRIME,
    '0000003': PRIME,
    '0000004': NO_PRIME,
    '0000005': NO_PRIME,
    '0000006': NO_PRIME,
    '0000007': PRIME,
    '0000008': PRIME,
    '0000009': PRIME,
    '0000010': NO_PRIME,
    '0000011': NO_PRIME,
    '0000012': NO_PRIME,
    '0000013': '',  // No rewards for this standard
    '0000014': '',  // No rewards for this standard
};

module.exports = {
    NERUDA_CARD_BONIFICATION_LEVELS,
    NERUDA_CARD_BONIFICATION_STATUS,
    NO_PRIME,
    PRIME
};