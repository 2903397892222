const WARNING_FISCAL_DATES = {
    providers: [
        {
            provider: ["Vida Caixa Se. y Re."],
            //28 Dic 2018, 12:50PM Madrid
            initialDate: new Date(Date.UTC(2018, 11, 28, 11, 50, 0)),
            //02 Ene 2019, 00:00AM Madrid
            closeDate: new Date(Date.UTC(2019, 0, 1, 23, 0, 0))
        },
        {
            provider: ["Caser Pensiones EGFP"],
            //27 Dic 2018, 12:50PM Madrid
            initialDate: new Date(Date.UTC(2018, 11, 27, 11, 50, 0)),
            //02 Ene 2019, 00:00AM Madrid
            closeDate: new Date(Date.UTC(2019, 0, 1, 23, 0, 0))
        },
        {
            provider: ["Santander Pensiones EGFP", "BBVA Pensiones EGFP", "AXA Pensiones EGFP"],
            //31 Dic 2018, 12:50PM Madrid
            initialDate: new Date(Date.UTC(2018, 11, 31, 11, 50, 0)),
            //02 Ene 2019, 00:00AM Madrid
            closeDate: new Date(Date.UTC(2019, 0, 1, 23, 0, 0))
        },
    ],
    defaultProviderDates: {
        //31 Dic 2018, 12:50PM Madrid
        initDate: new Date(Date.UTC(2018, 11, 31, 11, 50, 0)),
        //02 Ene 2019, 00:00AM Madrid
        closeDate: new Date(Date.UTC(2019, 0, 1, 23, 0, 0))
    },
    //1 Dic 2018, 12:50PM Madrid
    generalInitDate: new Date(Date.UTC(2018, 11, 1, 11, 50, 0)),
    //02 Ene 2019, 00:00AM Madrid
    generalCloseDate: new Date(Date.UTC(2019, 0, 1, 23, 0, 0)),
    //1 Dic 2018, 12:50PM Madrid default
    getGeneralInitDate: ({ year = 2018, month = 11, day = 1 }) => new Date(Date.UTC(year, month, day, 11, 50, 0)),
    //02 Ene 2019, 00:00AM Madrid default
    getGeneralCloseDate: ({ year = 2019, month = 0, day = 1 }) => new Date(Date.UTC(year, month, day, 23, 0, 0))
}

module.exports = WARNING_FISCAL_DATES;