module.exports = {
    DEFAULT_TRANSLATION_TEXT: '####',
    DEFAULT_TRANSLATION_TEXT_MISSING_VALUE: '########',
    CC_TRANSLATION_PREFIX: 'cc_',
    TRANSLATION_PLATFORM_PRI: 'PRI',
    TRANSLATION_PLATFORM_CC: 'CC',
    TRANSLATION_VERSION_STORAGE_LABEL: 'translationVersion',
    TRANSLATIONS_STORAGE_LABEL: 'translationsContent',
    TRANSLATION_PLATFORM_SHARED: 'SHARED',
};
