
// @vendor
const React = require('react');
const PropTypes = require('prop-types');
const { connect } = require('react-redux');

// @commons
const OKModal = require('components/commons/OKModal/OKModal');
const { LoadingSection } = require('commonsComponents/loadingSection');

// actions
const BrokerUsPersonBlockActions = require('actions/brokerUsPersonBlock')

class ModalUsPersonBlock extends React.Component {

    componentDidMount() {
        const { immBrokerUsPersonBlock, getIsUsPerson } = this.props;
        if (!immBrokerUsPersonBlock.get('fetchSuccess') && !immBrokerUsPersonBlock.get('isFetching')) {
            getIsUsPerson();
        }
    }

    buildModal() {
        const {
            description,
            titleKey,
            buttonClick,
            buttonText
        } = this.props;

        return (
            <OKModal
                description={description}
                descriptionAlignment="center"
                footerType="action" //navigation
                isOpen={true}
                onCloseCallback={buttonClick}
                onPrimaryButtonClick={buttonClick}
                primaryButtonText={buttonText}
                title={titleKey}
                titleAlignment="center"
            />
        );
    }

    render() {
        const { children, immBrokerUsPersonBlock, featureFlag } = this.props;
        const isFetching = immBrokerUsPersonBlock.get('isFetching');
        const isUsPerson = immBrokerUsPersonBlock.get('isUsPerson');
        let content;
        if (!isFetching) {
            content = isUsPerson && featureFlag ? this.buildModal() : children;
        } else {
            content = <LoadingSection />
        }

        return (
            <div>
                {content}
            </div>
        )
    }
}

ModalUsPersonBlock.propTypes = {
    titleKey: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    buttonClick: PropTypes.func,
    children: PropTypes.any,
    featureFlag: PropTypes.string,
    immBrokerUsPersonBlock: PropTypes.object,
    getIsUsPerson: PropTypes.func,
}

ModalUsPersonBlock.defaultProps = {
    titleKey: 'us_person_popup-title',
    description: 'us_person_popup-message',
    buttonText: 'us_person_popup-confirm_button_title',
};

module.exports.constructor = ModalUsPersonBlock;
module.exports.ModalUsPersonBlock = connect(
    state => ({
        immBrokerUsPersonBlock: state.brokerIsUsPerson
    }),
    {
        getIsUsPerson: BrokerUsPersonBlockActions.getIsUsPersonInfo,
    }
)(ModalUsPersonBlock);
