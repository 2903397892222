const ES = 'Es';
const ES_LANG = 'es';

const EN = 'En';
const EN_LANG = 'en';

const NL = 'Nl';
const NL_LANG = 'nl';

const PT = 'Pt';
const PT_LANG = 'pt';

const DE = 'De';
const DE_LANG = 'de';

const FR = 'Fr';
const FR_LANG = 'fr';


const languageCorrelation = {
    [ES_LANG]: ES,
    [EN_LANG]: EN,
    [NL_LANG]: NL,
    [PT_LANG]: PT,
    [DE_LANG]: DE,
    [FR_LANG]: FR,
}

module.exports = {
    ES, ES_LANG,
    EN, EN_LANG,
    NL, NL_LANG,
    PT, PT_LANG,
    DE, DE_LANG,
    FR, FR_LANG,
    languageCorrelation,
}
