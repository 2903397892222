require('./dropdownAccounts.scss');

// @ vendors
const React = require('react');
const PropTypes = require('prop-types');
// @ commons
const { HYPHEN } = require('constants/index');
const { getLastNChars } = require('utilities/stringHelper');

class AccountItemOptions extends React.Component {

    constructor() {
        super();
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    handleMouseDown(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    }

    handleMouseMove(event) {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    }

    render() {
        const { option, className } = this.props;
        const account = option;
        const id = `${account.id}_Options_${account.value}`;
        const accountClass = `${className} dropdown-accounts__account`;
        const accountIban = getLastNChars(account.iban);
        let showInactiveIcon;

        if (account.isInactiveAccount) {
            showInactiveIcon = <span className="dropdown-accounts__account-description-inactive-icon"><i className="icon-candado"></i></span>
        } else if (account.isNegativeBalance) {
            showInactiveIcon = (<span className="dropdown-accounts__account-description-negative-balance">
                <i className="dropdown-accounts__account-icon icon-advertencia"></i>
            </span>);
        }

        return (
            <div
                id={id}
                className={accountClass}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
            >
                <div className="dropdown-accounts__account-description dropdown-accounts__account-description--height">
                    {showInactiveIcon}
                    <span className="dropdown-accounts__account-description-alias">{account.alias}</span>
                    <span className="dropdown-accounts__account-description-iban" > {accountIban}</span>
                    <span className="dropdown-accounts__account-description-separator"> {HYPHEN} </span>
                    <span className="dropdown-accounts__account-description-type">{account.type} </span>
                </div>
            </div>
        );
    }
}

AccountItemOptions.propTypes = {
    className: PropTypes.string,
    onSelect: PropTypes.func,
    option: PropTypes.object,
    onFocus: PropTypes.func,
    isFocused: PropTypes.bool
};

module.exports = AccountItemOptions;
