require('./wizardHeaderMenu.scss');

const PropTypes = require('prop-types');

// @ vendors
const React = require('react');

// @commons
const { OKLink } = require('commonsComponents/OKLink');
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');

const WizardHeaderMenu = props => {
    const { headerMenuData } = props;
    const linksData = headerMenuData.linksData;
    let linkItems;
    let menu;

    linkItems = linksData.map(function(linkData, index) {
        const target = linkData.target || '';
        let tooltipBlock;

        if (linkData.hasTooltip) {
            tooltipBlock = (
                <OKTooltip
                    alternativeIcon={true}
                    content={linkData.tooltipText}
                    offset={[0, -15]}
                    type="descriptive"
                />
            );
        }

        return (
            <li key={index} className="wizard-header-menu__link-list-item">
                <OKLink
                    modifier="complementary"
                    target={target}
                    to={linkData.href}
                >
                    {linkData.label}
                </OKLink>
                {tooltipBlock}
            </li>
        );
    });

    menu = (
        <section className="wizard-header-menu">
            <h2 className="wizard-header-menu__title">{headerMenuData.title}</h2>
            <div className="wizard-header-menu__right-content">
                <ul className="wizard-header-menu__link-list">
                    {linkItems}
                </ul>
                <div className="wizard-header-menu__extra-content">
                    {headerMenuData.extraContent}
                </div>
            </div>
        </section>
    );

    return menu;
};

WizardHeaderMenu.propTypes = {
    headerMenuData: PropTypes.object.isRequired
};

module.exports = WizardHeaderMenu;
