module.exports = {
    SIMULACION: 'SIMULACION',
    RESULTADO_SIMULACION: 'RESULTADO_SIMULACION',
    PRE_APROBACION: 'PRE_APROBACION',
    PRE_APROBADO: 'PRE_APROBADO',
    SOLIC_PRESTAMO: 'SOLIC_PRESTAMO',
    APORTAR_DOC: 'APORTAR_DOC',
    AUT_CIRBE: 'AUT_CIRBE',
    AUT_CIRBE_CO: 'AUT_CIRBE_CO',
    WAIT_CIRBE_COT: 'WAIT_CIRBE_COT',
    DATOS_VIV: 'DATOS_VIV',
    TRAMITAR_NS: 'TRAMITAR_NS',
    WAIT_NS: 'WAIT_NS',
    PROBLEMA_NS: 'PROBLEMA_NS',
    PEND_TASACION: 'PEND_TASACION',
    TRAMITAR_TAS: 'TRAMITAR_TAS',
    WAIT_DICTAMEN: 'WAIT_DICTAMEN',
    ANALISIS_TAS: 'ANALISIS_TAS',
    ESPERA_AUTORIZACION_SEGURO_HOGAR: 'ESPERA_AUTORIZACION_SEGURO_HOGAR',
    APROBADO_RIESGOS: 'APROBADO_RIESGOS',
    GENERANDO_OV: 'GENERANDO_OV',
    PROV_FONDOS: 'PROV_FONDOS',
    PEND_MMPP: 'PEND_MMPP',
    PEND_SALDO: 'PEND_SALDO',
    PEND_FIRMA: 'PEND_FIRMA',
    FIRMADO: 'FIRMADO',
    ERROR_BUREAUS: 'ERROR_BUREAUS',
    MODEL_CORRECT: 'MODEL_CORRECT',
    TASA_ESF_KO: 'TASA_ESF_KO',
    ANULADO: 'ANULADO',
    OFRECER_FINANCIACION_GASTOS: 'OFRECER_FINANCIACION_GASTOS',
    CONFIRMA_DATOS_T2: 'CONFIRMA_DATOS_T2',
    APERTURA_CUENTA: 'APERTURA_CUENTA',
    UNKNOWN: 'UNKNOWN',
    SIN_EXPEDIENTE: 'SIN_EXPEDIENTE',
    ESPERA_DOC_FIRMA: 'ESPERA_DOC_FIRMA',
    ESPERA_ACTA_NOTARIO: 'ESPERA_ACTA_NOTARIO',
    GESTION_FECHA_FIRMA: 'GESTION_FECHA_FIRMA'

};
