const PropTypes = require('prop-types');
const React = require('react');
const constants = require('constants/index');
const get = require('lodash/object/get');
const { InputSafe } = require('commonsComponents/inputSafe');
const classNames = require('classnames');

class SignaturePasscodeInput extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            formattedValue: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
        this.setNode = this.setNode.bind(this);
    }

    componentDidMount() {
       this.checkInputValue();
    }

    componentWillReceiveProps(nextProps) {
        this.clearFieldsOnError(nextProps);
    }

    componentDidUpdate(){
        if (this.props.focus){
            this.nodeReference.focus();
        }
    }

    isValidInputValue = (value) => {
        const { isNumeric } = this.props;
        const regex = isNumeric ? /[0-9]/ : /[A-Za-z0-9]/;
        return regex.test(value);
    }

    checkInputValue = () => {
        const { initialValue} = this.props
        if (initialValue) {
            this.setState({
                formattedValue: '*',
                currentInputValue: initialValue
            });
        }
    }

    clearFieldsOnError(nextProps){
        if (!this.props.clearOnError) return false;

        if (nextProps.error && (this.props.error !== nextProps.error)) {
            this.setState({
                formattedValue: '',
                currentInputValue: ''
            });
        }
    }

    setNode(ref){
        this.nodeReference = get(ref, 'el');
    }

    handleChange(e){
        const enteredInputValue = e.target.value;
        // Another check to avoid input accent characters or other characters don't allowed that can't be controlled in the onKeyDown and onKeyPress events
        if(this.isValidInputValue(enteredInputValue)){ 
            const currentInputValue = enteredInputValue === '*' ? this.state.currentInputValue : enteredInputValue;
            if (!enteredInputValue || enteredInputValue === this.state.currentInputValue) return false;

            if (this.state.timeout ) {
                window.clearTimeout(this.state.timeout);
            }

            const timeout = window.setTimeout(function() {
                this.setState({
                    formattedValue: enteredInputValue ? '*' : ''
                });
            }.bind(this), 500);

            this.setState({
                currentInputValue: currentInputValue,
                formattedValue: enteredInputValue,
                timeout: timeout
            });

            this.props.onChange(currentInputValue, this.props.index);
        }
    }

    handleOnKeyDown(e) {
        const { onEnter } = this.props
        if (e.keyCode === constants.BACKSPACE_KEYCODE) {
            if (this.state.timeout) {
                window.clearTimeout(this.state.timeout);
            }
            this.setState({
                currentInputValue: '',
                formattedValue: '',
            });
            this.props.onKeyDown('', this.props.index);

        } else if (e.keyCode === constants.ENTER_KEY_CODE) {
            onEnter && onEnter(this.props.index);

        } else {
            const enteredInputValue = e.target.value;
            const currentInputValue = enteredInputValue === '*' ? this.state.currentInputValue : enteredInputValue;
            if (e.keyCode === constants.TAB_KEYCODE || !enteredInputValue || enteredInputValue === this.state.currentInputValue) {
                return false;
            }
            if (this.state.timeout) {
                window.clearTimeout(this.state.timeout);
            }
            this.props.onKeyDown(currentInputValue, this.props.index);
        }
    }

    handleOnKeyPress(e){
        if (!this.isValidInputValue(e.key)) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

    }

    handleOnPaste(e){
        if (!this.isValidInputValue(e.clipboardData.getData('Text'))) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }

    render() {
        const { index, disabled, error, displayErrorStyles } = this.props;
        const id = 'inp_SignaturePasscodeInput_Value_' + index ;
        const signaturePasscodeBoxClass = classNames({
            'signature-passcode__box': true,
            'signature-passcode__box--error': error && displayErrorStyles
        });
        return (
            <div className={signaturePasscodeBoxClass}>
                <InputSafe
                    ref={this.setNode}
                    id={id}
                    type="text"
                    disabled={disabled}
                    maxLength="1"
                    onChange={this.handleChange}
                    onKeyDown={this.handleOnKeyDown}
                    onKeyPress={this.handleOnKeyPress}
                    onPaste={this.handleOnPaste}
                    value={this.state.formattedValue}
                    placeholder="#"
                    autoComplete="off"
                    className="signature-passcode__input"
                />
            </div>
        );
    }
}

SignaturePasscodeInput.propTypes = {
    index: PropTypes.number,
    disabled: PropTypes.bool,
    displayErrorStyles: PropTypes.bool,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    focus: PropTypes.bool,
    reset: PropTypes.bool,
    isNumeric: PropTypes.bool,
    onEnter: PropTypes.func,
    error: PropTypes.bool,
    clearOnError: PropTypes.bool,
    initialValue: PropTypes.string
};

SignaturePasscodeInput.defaultProps = {
    isNumeric: false,
    clearOnError:false,
    displayErrorStyles: false
}

module.exports = SignaturePasscodeInput;