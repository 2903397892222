require('./callMeBack.scss');
require('./callMeBack.override.scss');

// @vendors
const React = require('react');
const PropTypes = require('prop-types');
const { FormattedText, formatText } = require('core/i18n').i18n;
const { connect } = require('react-redux');
const specialistsActions = require('actions/investmentSpecialists');
const classNames = require('classnames');
const moment = require('moment');

// @components
const { ContentBox } = require('commonsComponents/contentBox');
const { LoadingSection } = require('commonsComponents/loadingSection');
const { InputSafe } = require('commonsComponents/inputSafe');
const { OKLink } = require('commonsComponents/OKLink');
const { OKButton } = require('components/commons/OKButton/OKButton');
const OKSelect = require('components/commons/OKSelect/OKSelect');
const { Accordion } = require('commonsComponents/accordion');
const { AccordionHeader } = require('commonsComponents/accordionHeader');
const { AccordionBody } = require('commonsComponents/accordionBody');
const PhoneInput = require('components/commons/phoneInput/phoneInput');

// @helpers
const { toCamelCase, toFirstUppercase } = require('utilities/stringHelper');
const PersonalDataHelper = require('utilities/personalDataHelper');
const { sendTextToClipBoard } = require('commonsUtilities/clipBoardHelper');
const { formatInternationalPhone, formatMultiplePhoneNumber } = require('utilities/phoneHelper');
const withContactCenterValidation = require('utilities/withContactCenterValidation');

// @commons
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');

// @constants
const {
    CALL_TIME_OPTIONS_RIGHT_AWAY_ES,
    CALL_TIME_OPTIONS_STATUS_OK,
    BROKER_CALLMEBACK_VALID_TEL_CODE_REG_EX,
    PHONE_AND_MOBILE_NUMBER_REGEX,
    OFFICE_TYPE_DIAMOND_PLUS,
    OFFICE_TYPE_DIAMOND,
    OFFICE_TYPE_PREMIUM,
    OFFICE_TYPE_OFICINA_NACIONAL
} = require('constants/index');
const {
    CALLMEBACK_GENESYS,
    CALLMEBACK
} = require('constants/flagBasedFeatures');
const { DEFAULT_ORIGIN_COUNTRY } = require('constants/countries')
const { CALL_ME_BACK_BEHAVIOUR } = require('constants/phoneInput');
const RIGHT_AWAY = toCamelCase(CALL_TIME_OPTIONS_RIGHT_AWAY_ES);
const CALL_ME_BACK_SECTION = "call-me-back-section";
const PROC_CALL_ME_BACK = "te llamamos";

const { recordProcess, PROC_START, PROC_CANCEL, PROC_END } = require('utilities/tagging');

class CallMeBack extends React.Component {
    constructor(props) {
        super(props);

        const telephone = PersonalDataHelper.getPhoneOnly(props.immProfile);
        const telCode = PersonalDataHelper.getPhoneCodeOnly(props.immProfile);

        this.state = {
            showProfiles: false,
            showCallMeBack: false,
            telephone,
            telCode,
            reasonText: '',
            callTime: '',
            telephoneError: false,
            telephoneCodeError: false
        };
        this.handleAvatarsOnClick = this.handleAvatarsOnClick.bind(this);
        this.handleAccordionOnClick = this.handleAccordionOnClick.bind(this);
        this.handleRequestCall = this.handleRequestCall.bind(this);
        this.onChangeTelephoneInput = this.onChangeTelephoneInput.bind(this);
        this.buildGeneralView = this.buildGeneralView.bind(this);
        this.handleCallMeBackOnClick = this.handleCallMeBackOnClick.bind(this);
        this.handleOnChangeReasonTextInput = this.handleOnChangeReasonTextInput.bind(this);
        this.handleCancelCallMeBackOnClick = this.handleCancelCallMeBackOnClick.bind(this);
        this.handleCallTimeChange = this.handleCallTimeChange.bind(this);
        this.onTelCodeChange = this.onTelCodeChange.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }

    componentDidMount() {
        const { getSpecialists, resetCallTimeOptions, onRequestBrokerCallTimeOptions, originCountry } = this.props;
        this.accordeonRef = React.createRef();
        resetCallTimeOptions();
        getSpecialists('generalSpecialists');
        onRequestBrokerCallTimeOptions(originCountry)
    }

    componentWillReceiveProps(nextProps) {
        const telephone = PersonalDataHelper.getPhoneOnly(this.props.immProfile);
        const nextTelephone = PersonalDataHelper.getPhoneOnly(nextProps.immProfile);
        const nextTelCode = PersonalDataHelper.getPhoneCodeOnly(nextProps.immProfile);

        if (!telephone && nextTelephone) {
            this.setState({
                telephone: nextTelephone,
                telCode: nextTelCode
            });
        }
    }

    onChangeTelephoneInput(value) {
        this.setState({ telephone: value });
    }

    onTelCodeChange(value) {
        this.setState({ telCode: value });
        this.props.onTelCodeChange(value);
    }

    buildAssignedSpecialistProfile() {
        const { immInvestmentSpecialists, isSmall } = this.props;
        const assignedSpecialist = immInvestmentSpecialists.get('assignedSpecialist');
        const imageUrl = assignedSpecialist.get('imageUrl');
        const name = toCamelCase(assignedSpecialist.get('name'));
        let manager = formatText('callmeback-yourManager');
        const officeName = immInvestmentSpecialists.getIn(['office', 'name']);
        let title = `${manager} ${officeName}`;
        if (officeName === OFFICE_TYPE_DIAMOND_PLUS && this.hasSpecialist()) {
            manager = formatText('callmeback-diamondPlusYourManager');
            title = `${manager} ${officeName}`;
        }
        const exclusiveSpecialist = ` | ${formatText('callmeback-assignedSpecialistExclusive')}`;

        return (
            <div className="row no-gutter">
                <div className="col-xs-3">
                    <img
                        className="callmeback__img callmeback__img--assigned-specialist"
                        alt="investment specialist"
                        src={imageUrl}
                    />
                </div>
                <div className="col-xs-9">
                    <div
                        className={classNames('callmeback__profile-info', {
                            'callmeback__profile-info--small': isSmall
                        })}
                    >
                        <div className="callmeback__profile-title">
                            {title}
                        </div>
                        <span className="callmeback__profile-name">
                            {name}
                        </span>
                        <span className="callmeback__profile-exclusive">
                            {exclusiveSpecialist}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    buildAssignedSpecialistWillBeAvailable() {
        const { immInvestmentSpecialists } = this.props;
        const officeName = immInvestmentSpecialists.getIn(['office', 'name']);

        const mondayThursday = immInvestmentSpecialists.getIn([
            'assignedSpecialist',
            'timetable',
            'lunesJueves'
        ]);

        const friday = immInvestmentSpecialists.getIn([
            'assignedSpecialist',
            'timetable',
            'viernes'
        ]);

        let mondayThursdayFrom = mondayThursday.get('desde');
        let mondayThursdayTo = mondayThursday.get('hasta');

        let fridayFrom = friday.get('desde');
        let fridayTo = friday.get('hasta');

        // WATCH OUT!! We assume that we get full hours between 0 and 24 ('HH')
        // from the server because the format is number.

        let injectedStrings = [
            moment(mondayThursdayFrom, 'HH').format('HH:mm'),
            moment(mondayThursdayTo, 'HH').format('HH:mm')
        ];

        let formattedTextValue = 'callmeback-assignedSpecialistWillBeAvailable';

        if (officeName === OFFICE_TYPE_DIAMOND_PLUS && this.hasSpecialist()) {
            formattedTextValue = 'callmeback-diamondPlusAssignedSpecialistWillBeAvailable';
        }

        if (mondayThursdayFrom !== fridayFrom || mondayThursdayTo !== fridayTo) {
            injectedStrings.push(
                moment(fridayFrom, 'HH').format('HH:mm'),
                moment(fridayTo, 'HH').format('HH:mm')
            );

            formattedTextValue = 'callmeback-assignedSpecialistWillBeAvailableDifferentTimeFriday';
            if (officeName === OFFICE_TYPE_DIAMOND_PLUS && this.hasSpecialist()) {
                formattedTextValue = 'callmeback-diamondPlusAssignedSpecialistWillBeAvailableDifferentTimeFriday';
            }
        }

        return (
            <FormattedText
                injectedStrings={injectedStrings}
                value={formattedTextValue}
            />
        );
    }

    buildBesidesWillBeAvailable(phone, isMobile = false) {
        const { immInvestmentSpecialists, immProfile } = this.props;
        const officeType = immInvestmentSpecialists.getIn(['office', 'name']);
        let besidesText;
        const phoneCountryCode = PersonalDataHelper.getPhoneCodeFromOriginCountry(immProfile.get('originCountry'))
        const isMultiplePhoneNumbers = phone && phone.includes("/");
        let officeNumber = isMultiplePhoneNumbers ? formatMultiplePhoneNumber(phoneCountryCode, phone, isMobile) : formatInternationalPhone(phoneCountryCode, phone, isMobile);
        let officeDetails = [officeType, officeNumber];

        switch (officeType) {
            case OFFICE_TYPE_PREMIUM:
                besidesText = 'callmeback-assignedSpecialistBesidesWillBeAvailablePremium';
                break;
            case OFFICE_TYPE_DIAMOND:
                besidesText = 'callmeback-assignedSpecialistBesidesWillBeAvailableDiamond';
                break;
            case OFFICE_TYPE_DIAMOND_PLUS:
                besidesText = 'callmeback-assignedSpecialistBesidesWillBeAvailableDiamondPlus';
                break;
            case OFFICE_TYPE_OFICINA_NACIONAL:
                besidesText = 'callmeback-helpAdvice';
                break;
            default:
                besidesText = 'callmeback-helpAdvice';
                officeDetails = [officeNumber];
                break;
        }

        return (
            <div className="col-xs-12 callmeback__besides-will-be-available">
                <hr />
                <FormattedText injectedStrings={officeDetails} value={besidesText} />
            </div>
        );
    }

    buildCallMeBackEntrance() {
        const { 
            hasEntrance, 
            immFlagBasedFeatures, 
            immInvestmentSpecialists 
        } = this.props;
        const officeName = immInvestmentSpecialists.getIn(['office', 'name']);
        let dataToRender = null;

        if (hasEntrance && immFlagBasedFeatures.get(CALLMEBACK)) {
            let callMeBackEntrance = formatText('callmeback-entrance');
            let callMeBackHere = formatText('callmeback-here');
            if (officeName === OFFICE_TYPE_DIAMOND_PLUS) {
                callMeBackEntrance = formatText('callmeback-diamondPlusEntrance');
                callMeBackHere = formatText('callmeback-diamondPlusHere');
            }

            dataToRender = (
                <div className="callmeback__entrance-link">
                    <span className="callmeback__entrance-link-text">
                        {callMeBackEntrance}{' '}
                    </span>
                    <OKLink
                        inverse={true}
                        onClick={this.handleCallMeBackOnClick}
                    >
                        {callMeBackHere}
                    </OKLink>
                </div>
            );
        }

        return dataToRender;
    }

    buildCallMeBackView() {
        const { reasonText, telephone, telCode, telephoneError, telephoneCodeError, callTime } = this.state;
        const { immProfile, immInvestmentSpecialists } = this.props;
        const callTimeOptions = immInvestmentSpecialists.get('callTimeOptions');
        const status = immInvestmentSpecialists.get('status');
        const requestingCall = immInvestmentSpecialists.get('requestingCall');
        const invalidCallTime = callTime === RIGHT_AWAY && status !== CALL_TIME_OPTIONS_STATUS_OK;
        const clientName = toCamelCase(immProfile.get('nombreCliente').trim());
        const selectErrorMessage = formatText('callmeback-callTimeTimezoneOutOfRange');
        const behaviour = {
            value: CALL_ME_BACK_BEHAVIOUR,
            profileOfuscatedPhone: PersonalDataHelper.getPhoneOnly(immProfile),
            profileCountryCode: PersonalDataHelper.getPhoneCodeOnly(immProfile)
        }

        return (
            <ContentBox>
                <div className="row callmeback__entrance">
                    <div className="row callmeback__entrance-label">
                        <div className="col-xs-12">
                            <FormattedText
                                injectedStrings={[clientName]}
                                value="callMeBackEntrance-title"
                            />
                        </div>
                    </div>
                    <div className="row callmeback__entrance-input-section">
                        <div className="col-xs-12 callmeback__entrance-label">
                            <FormattedText value="callMeBackEntrance-phoneLabel" />
                        </div>
                        <div className="col-xs-12">
                            <PhoneInput
                                id="brokerCallMeBackPhone"
                                behaviour={behaviour}
                                phonePrefix={telCode}
                                phoneNumber={telephone}
                                prefixCallback={this.onTelCodeChange}
                                numberCallback={this.onChangeTelephoneInput}
                                numberError={telephoneError}
                                prefixError={telephoneCodeError}
                                numberErrorMsg={formatText('brokerInvestmentSpecialistModal-invalidTel')}
                                prefixErrorMsg={formatText('brokerInvestmentSpecialistModal-invalidTelCode')}
                            />
                        </div>
                    </div>
                    <div className="row callmeback__entrance-input-section">
                        <div className="col-xs-12 callmeback__entrance-label">
                            <FormattedText value="callMeBackEntrance-callTimeOptionsLabel" />
                        </div>
                        <div className="col-xs-12">
                            <OKSelect
                                className="callmeback__options"
                                onChange={this.handleCallTimeChange}
                                options={callTimeOptions}
                                value={callTime}
                                searchable={false}
                                errorState={invalidCallTime}
                                errorMessage={selectErrorMessage}
                            />
                        </div>
                    </div>
                    <div className="row callmeback__entrance-input-section">
                        <div className="col-xs-12 callmeback__entrance-label">
                            <FormattedText value="callMeBackEntrance-reasonLabel" />
                        </div>
                        <div className="col-xs-12">
                            <InputSafe
                                value={reasonText}
                                maxLength={100}
                                onChange={this.handleOnChangeReasonTextInput}
                                isTextArea={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-5">
                            <OKButton
                                modifier="link-ghost"
                                onClick={this.handleCancelCallMeBackOnClick}
                            >
                                <FormattedText value="callMeBackEntrance-cancelButtonLabel" />
                            </OKButton>
                        </div>
                        <div className="col-xs-7">
                            <OKButton
                                loading={requestingCall}
                                modifier="secondary"
                                onClick={this.handleRequestCall}
                            >
                                <FormattedText
                                    value="callMeBackEntrance-submitButtonLabel"
                                    format="uppercase"
                                />
                            </OKButton>
                        </div>
                    </div>
                </div>
            </ContentBox>
        );
    }

    buildConditionalView() {
        const { immInvestmentSpecialists } = this.props;
        const requestingCall = immInvestmentSpecialists.get('requestingCall');
        const successRequestCall = immInvestmentSpecialists.get('successRequestCall');
        const requestCallSent = immInvestmentSpecialists.get('requestCallSent');
        const status = immInvestmentSpecialists.get('status') === CALL_TIME_OPTIONS_STATUS_OK;
        let component = this.buildCallMeBackView();

        if (!requestingCall) {
            if (status && requestCallSent && successRequestCall) {
                component = this.buildSuccessScreen();
            } else if (requestCallSent && !successRequestCall) {
                component = this.buildErrorScreen();
            }
        }

        return component;
    }

    //This function builds  email and  phone number section with tooltip.
    buildContactOptionSection = (phone, email, isMobile = false, isError = false) => {
        const { immProfile } = this.props;
        // Email custom tooltip data.
        const emailContent = (
            <p className="callmeback__contact-options__e-mail">
                {email}
            </p>
        );
        const toolTipContent = (
            <span>
                {this.state.copied || email}
                <button
                    className="callmeback__tooltip-copy-button icon__tooltip icon-copiar"
                    onClick={() => sendTextToClipBoard(email)}
                />
            </span>
        );

        const isMultiplePhoneNumbers = phone && phone.includes("/");
        const phoneCountryCode = PersonalDataHelper.getPhoneCodeFromOriginCountry(immProfile.get('originCountry'))
        let phoneNumber = isMultiplePhoneNumbers ? formatMultiplePhoneNumber(phoneCountryCode, phone, isMobile) : formatInternationalPhone(phoneCountryCode, phone, isMobile);
        let phoneSection;
        if (!isError) {
            phoneSection = (
                <span className="callmeback__contact-options__phone-number">
                    <label>{phoneNumber}</label>
                </span>
            );
        }

        return (
            <div className="col-xs-12 callmeback__contact-options">
                {phoneSection}
                <OKTooltip
                    content={toolTipContent}
                    offset={[0, -15]}
                    target={emailContent}
                />
            </div>
        );
    }

    buildErrorScreen() {
        return (
            <ContentBox id="brokerInvestmentSpecialistError" smallTitle={true}>
                <div className="callmeback__icon-error">
                    <i className="callmeback__icon-error-icon icon-error" />
                </div>
                <p className="callmeback__icon-success-label">
                    <FormattedText value="callmeback-errorMessageTitle" />
                </p>
                <p className="callmeback__error-message">
                    <FormattedText value="callmeback-errorMessageSubtitle" />
                </p>
            </ContentBox>
        );
    }

    buildExtraInfo(messageKey) {
        return (
            <div className="broker-investment-specialist__extra-info">
                <FormattedText value={messageKey} />
            </div>
        );
    }

    buildGeneralErrorView = () => {
        const { immGlobalVariables } = this.props;
        const title = formatText('callmeback-CapitalTitle');
        const subtitle = formatText('callmeback-errorSubtitle');
        const willBeAvailableErrorTitle = formatText('callmeback-errorWillBeAvailableTitle');
        const ErrorPhone = immGlobalVariables.getIn(['variables', 'parameters', 'telefono', 'cliente']);
        const email = formatText('callmeback-eMail');
        const callMeBackEntrance = this.buildCallMeBackEntrance();
        const isMobile = false;
        const isError = true;
        const contactOptionSection = this.buildContactOptionSection(ErrorPhone, email, isMobile, isError);
        return (
            <ContentBox
                id="callmeback"
                title={title}
                customClassName="callmeback"
                footerContent={callMeBackEntrance}
            >
                <div className="row">
                    <div className="col-xs-12 callmeback__subtitle">
                        {subtitle}
                    </div>
                    <div className="col-xs-12 callmeback__error-title">
                        {willBeAvailableErrorTitle}
                    </div>
                    <div className="col-xs-12">
                        <FormattedText value="callmeback-errorWillBeAvailableBody" />
                    </div>
                    {contactOptionSection}
                    <div className="col-xs-12 callmeback__error-section">
                        <hr />
                    </div>
                </div>
            </ContentBox>
        )
    }

    buildGeneralView() {
        const { immInvestmentSpecialists } = this.props;
        const callMeBackEntrance = this.buildCallMeBackEntrance();
        let accordionSection = this.buildOpenAccordion();
        let title = formatText('callmeback-title');
        let subtitle = formatText('callmeback-subtitle');
        let willBeAvailable = formatText('callmeback-willBeAvailable');
        let phone = this.removeWhiteSpaces(immInvestmentSpecialists.getIn(['office', 'phone']));
        let email = immInvestmentSpecialists.getIn(['office', 'email']);
        let specialistAccordion;
        let besidesWillBeAvailable = this.buildBesidesWillBeAvailable(phone);
        let contactOptionSection = this.buildContactOptionSection(phone, email);
        const officeType = immInvestmentSpecialists.getIn(['office', 'name']);

        switch (officeType) {
            case OFFICE_TYPE_PREMIUM:
                title = formatText('callmeback-title')
                subtitle = formatText('callmeback-subtitle')
                break;
            case OFFICE_TYPE_DIAMOND:
                title = formatText('callmeback-title')
                subtitle = formatText('callmeback-subtitle')
                break;
            case OFFICE_TYPE_DIAMOND_PLUS:
                title = formatText('callmeback-diamondPlusTitle');
                subtitle = formatText('callmeback-diamondPlusSubtitle');
                willBeAvailable = formatText('callmeback-diamondPlusWillBeAvailable');
                break;
            case OFFICE_TYPE_OFICINA_NACIONAL:
                title = formatText('callmeback-CapitalTitle');
                subtitle = formatText('callmeback-OficinaNacionalSubtitle');
                contactOptionSection = this.buildContactOptionSection(phone, email);
                willBeAvailable = formatText('callmeback-willBeAvailableFor101To107Offices');
                break;
        }

        let contentBoxText;

        if (officeType === OFFICE_TYPE_OFICINA_NACIONAL) {
            contentBoxText = (
                <div>
                    {accordionSection}
                    <div className="col-xs-12 callmeback__consultant-will-be-available">
                        {willBeAvailable}
                    </div>
                    <div className="col-xs-12">
                        <FormattedText
                            className="callmeback__call-time-zone"
                            value="callmeback-OficinaNacionalCallTimeZone"
                        />
                    </div>
                    {contactOptionSection}
                    {besidesWillBeAvailable}
                </div>
            )
        } else {
            contentBoxText = (
                <div>
                    {contactOptionSection}
                    <div className="col-xs-12 callmeback__consultant-will-be-available">
                        {willBeAvailable}
                    </div>
                    <div className="col-xs-12">
                        <FormattedText
                            className="callmeback__call-time-zone"
                            value="callmeback-callTimeZone"
                        />
                    </div>
                    {besidesWillBeAvailable}
                    {accordionSection}
                </div>
            );
        }

        if (this.hasSpecialist()) {
            const inlineProfilePics = this.buildAssignedSpecialistProfile();
            title = formatText('callmeback-assignedSpecialistTitle');
            subtitle = formatText('callmeback-assignedSpecialistSubtitle');
            if (officeType === OFFICE_TYPE_DIAMOND_PLUS) {
                title = formatText('callmeback-diamondPlusAssignedSpecialistTitle');
                subtitle = formatText('callmeback-diamondPlusAssignedSpecialistSubTitle');
            }
            accordionSection = (
                <div className="col-xs-12 callmeback__assigned-specialist-profile">
                    {inlineProfilePics}
                </div>
            );
            willBeAvailable = this.buildAssignedSpecialistWillBeAvailable();
            phone = this.removeWhiteSpaces(immInvestmentSpecialists.getIn(['assignedSpecialist', 'phone']));
            email = immInvestmentSpecialists.getIn(['assignedSpecialist', 'email']);
            contactOptionSection = this.buildContactOptionSection(phone, email);
            specialistAccordion = this.buildSpecialistAccordion();
            contentBoxText = (
                <div>
                    {accordionSection}
                    <div className="col-xs-12 callmeback__specialist-will-be-available">
                        {willBeAvailable}
                    </div>
                    <div className="col-xs-12">
                        <FormattedText
                            className="callmeback__call-time-zone"
                            value="callmeback-callTimeZone"
                        />
                    </div>
                    {contactOptionSection}
                    {besidesWillBeAvailable}
                    {specialistAccordion}
                </div>
            );

        }
        return (
            <ContentBox
                id="callmeback"
                title={title}
                smallTitle={true}
                customClassName="callmeback"
                data-gtns-component-name={CALL_ME_BACK_SECTION}
                footerContent={callMeBackEntrance}
            >
                <div className="row">
                    <div className="col-xs-12 callmeback__subtitle">
                        {subtitle}
                    </div>
                    {contentBoxText}
                </div>
            </ContentBox>
        );
    }

    buildHeaderPics(profilePics) {
        const { showProfiles } = this.state;
        let headerPics = null;

        if (!showProfiles) {
            headerPics = (
                <div className="col-xs-12 callmeback__pics-header">
                    {profilePics}
                </div>
            );
        }

        return headerPics;
    }

    buildInlineProfilePics() {
        const { immInvestmentSpecialists, isSmall } = this.props;
        const specialistsLength = isSmall ? 4 : 5;
        const specialistPics = immInvestmentSpecialists.getIn(['office', 'specialists']);
        let dataToRender = null;

        let specialistsFiltered = null;

        if (immInvestmentSpecialists.get('assignedSpecialist')) {
            specialistsFiltered = specialistPics.filter(specialist => {
                return (
                    specialist.get('email') !==
                    immInvestmentSpecialists.getIn(['assignedSpecialist', 'email'])
                );
            });
        } else {
            specialistsFiltered = specialistPics;
        }

        if (specialistsFiltered && !specialistsFiltered.isEmpty()) {
            dataToRender = specialistsFiltered.map((specialistFiltered, index) => {
                if (index < specialistsLength) {
                    return (
                        <img
                            key={index}
                            className="callmeback__inlineimg"
                            alt="investment specialist"
                            src={specialistFiltered.get("imageUrl")}
                            onClick={this.handleAvatarsOnClick}
                        />
                    );
                }
            });
        }

        return dataToRender;
    }

    buildOpenAccordion() {
        const { showProfiles } = this.state;
        const { immInvestmentSpecialists, immProfile } = this.props;
        let officeName = immInvestmentSpecialists.getIn(['office', 'name']);
        const officeAlias = immInvestmentSpecialists.getIn(['office', 'alias']);
        const country = immProfile.get('originCountry');

        if (officeName === OFFICE_TYPE_OFICINA_NACIONAL) {
            officeName = formatText('callmeback-virtual') + officeAlias;
        }
        let callCenterText = formatText('callmeback-callcenter');
        if (officeName === OFFICE_TYPE_DIAMOND_PLUS) {
            callCenterText = formatText('callmeback-diamondPlusCallcenter');
        }
        const callCenter = `${callCenterText} ${country === DEFAULT_ORIGIN_COUNTRY ? officeName : ''}`;
        const profiles = this.buildProfiles();
        const inlineProfilePics = this.buildInlineProfilePics();
        const headerPics = this.buildHeaderPics(inlineProfilePics);
        const headerClass = classNames('col-xs-12 callmeback__accordion-header-title', {
            'callmeback__accordion-header-title--expanded': showProfiles
        });
        return (
            <div className="col-xs-12 callmeback__accordion-wrapper callmeback__accordion-wrapper--public">
                <Accordion
                    ref={this.accordeonRef}
                    customClassName="callmeback__accordion"
                    onClick={this.handleAccordionOnClick}
                >
                    <AccordionHeader
                        name="AccordionHeader"
                        canBeExpanded
                        avoidOnClickOnChildren
                    >
                        <div className="row">
                            <div className={headerClass}>{callCenter}</div>
                            {headerPics}
                        </div>
                    </AccordionHeader>
                    <AccordionBody name="AccordionBody">
                        <div className="callmeback__accordion-body">
                            {profiles}
                        </div>
                    </AccordionBody>
                </Accordion>
            </div>
        );
    }

    buildProfiles() {
        const { immInvestmentSpecialists, isSmall } = this.props;
        const specialists = immInvestmentSpecialists.getIn(['office', 'specialists']);
        let dataToRender = null;

        let specialistsFiltered = null;

        if (immInvestmentSpecialists.get('assignedSpecialist')) {
            specialistsFiltered = specialists.filter(specialist => {
                return (
                    specialist.get('email') !==
                    immInvestmentSpecialists.getIn(['assignedSpecialist', 'email'])
                );
            });
        } else {
            specialistsFiltered = specialists;
        }

        if (specialists && !specialists.isEmpty()) {
            dataToRender = specialistsFiltered.map((specialist, index) => {
                const imageUrl = specialist.get('imageUrl');
                const name = toCamelCase(specialist.get('name'));
                const nameClass = classNames('callmeback__accordion-name', {
                    'callmeback__accordion-name--small': isSmall
                });

                let profileInfo = (
                    <div className={nameClass}>
                        {name}
                    </div>
                );

                return (
                    <div className="row no-gutter" key={index}>
                        <div className="col-xs-3">
                            <img
                                key={index}
                                className="callmeback__img callmeback__accordion-img"
                                alt="investment specialist"
                                src={imageUrl}
                                onClick={this.handleAvatarsOnClick}
                            />
                        </div>
                        <div className="col-xs-9">{profileInfo}</div>
                    </div>
                );
            });
        }

        return dataToRender;
    }

    buildSpecialistAccordion() {
        const { immInvestmentSpecialists } = this.props;
        const officeName = immInvestmentSpecialists.getIn(['office', 'name']);
        let callCenterText = formatText('callmeback-callcenterAssigned');
        if (officeName === OFFICE_TYPE_DIAMOND_PLUS) {
            callCenterText = formatText('callmeback-diamondPlusCallcenter');
        }
        const callCenter = `${callCenterText} ${officeName}`;
        const profiles = this.buildProfiles();
        const inlineProfilePics = this.buildInlineProfilePics();
        const headerPics = this.buildHeaderPics(inlineProfilePics);
        const { showProfiles } = this.state;
        const headerClass = classNames('col-xs-12 callmeback__accordion-header-title', {
            'callmeback__accordion-header-title--expanded': showProfiles
        });
   
        return (
            <div className="col-xs-12 callmeback__accordion-wrapper callmeback__accordion-wrapper--public">
                <Accordion
                    ref={this.accordeonRef}
                    isExpanded={false}
                    customClassName="callmeback__accordion"
                    onClick={this.handleAccordionOnClick}
                >
                    <AccordionHeader
                        name="AccordionHeader"
                        avoidOnClickOnChildren
                        canBeExpanded
                    >
                        <div className="row">
                            <div className={headerClass}>{callCenter}</div>
                            {headerPics}
                        </div>
                    </AccordionHeader>
                    <AccordionBody name="AccordionBody">
                        <div className="callmeback__accordion-body">
                            {profiles}
                        </div>
                    </AccordionBody>
                </Accordion>
            </div>
        );
    }

    buildSuccessScreen() {
        const { immProfile, immInvestmentSpecialists } = this.props;
        const callTime = immInvestmentSpecialists.get('callTime');
        let contactText = <FormattedText value="callmeback-weWillCallYou" />;

        if (callTime !== RIGHT_AWAY) {
            contactText = (
                <div className="callmeback__contact-message">
                    <FormattedText injectedStrings={[toFirstUppercase(callTime)]}
                        value="callmeback-callTimeContactMessage"
                    />
                    <FormattedText className="callmeback__call-time-zone" value="callmeback-callTimeZone" />
                </div>
            );
        }

        return (
            <ContentBox
                id="brokerInvestmentSpecialistSuccess"
                smallTitle={true}
                customClassName="callmeback"
            >
                <p className="callmeback__icon-success">
                    <i className="wizard-success-step__success-icon" />
                </p>
                <p className="callmeback__icon-success-label">
                    <FormattedText
                        injectedStrings={[toCamelCase(immProfile.get('nombreCliente').trim())]}
                        value="callmeback-thankYouForContact"
                    />
                </p>
                <div className="callmeback__success-message">
                    {contactText}
                </div>
            </ContentBox>
        );
    }

    buildView() {
        return this.specialistHasError() ? this.buildGeneralErrorView() : this.buildGeneralView();
    }

    handleCallMeBackOnClick() {
        recordProcess(PROC_CALL_ME_BACK, PROC_START);
        this.setState({
            showCallMeBack: true,
            showProfiles: false
        });
    }

    handleCallTimeChange(option) {
        this.setState({ callTime: option.value });
        this.props.onCallTimeChange(option.label);
    }

    handleCancelCallMeBackOnClick() {
        const telephone = PersonalDataHelper.getPhoneOnly(this.props.immProfile);
        const telCode = PersonalDataHelper.getPhoneCodeOnly(this.props.immProfile);
        const resetState = {
            showProfiles: false,
            showCallMeBack: false,
            telephone,
            telCode,
            callTime: '',
            reasonText: '',
            telephoneError: false,
            telephoneCodeError: false
        };

        recordProcess(PROC_CALL_ME_BACK, PROC_CANCEL);
        this.setState(resetState);
    }

    handleOnChangeReasonTextInput(event) {
        this.setState({ reasonText: event.target.value });
    }

    handleAvatarsOnClick() {
        this.accordeonRef.current.handlerToggle();
    }

    handleAccordionOnClick() {
        this.setState({ showProfiles: !this.state.showProfiles });
    }

    handleRequestCall() {
        const {
            currentPath,
            immFlagBasedFeatures,
            immInvestmentSpecialists,
            immProfile,
            pageTitle,
            requestCallMeBack,
            resetCallMeBack
        } = this.props;
        const { reasonText, telephone, telCode } = this.state;
        const phoneToSend = (telCode + telephone).replace(/\s/g, '');
        const isCallMeBackGenesysEnabled = immFlagBasedFeatures.get(CALLMEBACK_GENESYS);
        const resetCallMeBackCallback = () => {
            setTimeout(() => {
                resetCallMeBack();

                this.setState({
                    showProfiles: false,
                    showCallMeBack: false,
                    telephone,
                    telCode,
                    callTime: '',
                    reasonText: '',
                    telephoneError: false,
                    telephoneCodeError: false
                });
            }, 15000);
        };
        const cmbObj = {
            timeForContact: immInvestmentSpecialists.get('callTime'),
            message: reasonText,
            pagePath: currentPath,
            pageTitle: document.title,
            phone: phoneToSend,
            name: immProfile.get('nombreCliente').trim(),
            lastName1: immProfile.getIn(['apellidoUno', 'apellido']).trim(),
            lastName2: immProfile.getIn(['apellidoDos', 'apellido']).trim()
        };

        if (this.validateFields(telCode)) {
            requestCallMeBack(cmbObj, resetCallMeBackCallback, isCallMeBackGenesysEnabled, pageTitle);
            recordProcess(PROC_CALL_ME_BACK, PROC_END);
        }
    }

    hasSpecialist() {
        const { immInvestmentSpecialists } = this.props;
        const assignedSpecialist = immInvestmentSpecialists.get('assignedSpecialist');

        return assignedSpecialist && !assignedSpecialist.isEmpty();
    }

    removeWhiteSpaces(value) {
        return value ? value.replace(/ /g, '') : '';
    }

    specialistHasError() {
        const { immInvestmentSpecialists } = this.props;

        return immInvestmentSpecialists.get('getSpecialistsError') !== '';
    }

    validateFields(telcode) {
        const { telephone } = this.state;
        const profileTelephone = PersonalDataHelper.getPhoneOnly(this.props.immProfile);
        const phoneDirty = !(profileTelephone === telephone);
        const validTelCode = BROKER_CALLMEBACK_VALID_TEL_CODE_REG_EX.test(telcode);
        let validTelNumber = telephone && telephone.length;
        const countryCode = PersonalDataHelper.getPhoneCodeFromOriginCountry(this.props.immProfile.get('originCountry'))
        //If we modify the contrycode, the phonenumber value will be erased, so we can asume the that the phoneNumber is dirty
        if (validTelNumber && telcode === `+${countryCode}` && phoneDirty) {
            validTelNumber = PHONE_AND_MOBILE_NUMBER_REGEX.test(telephone);
        }

        this.setState({ telephoneError: !validTelNumber, telephoneCodeError: !validTelCode });

        return validTelNumber && validTelCode;
    }

    render() {
        const { immInvestmentSpecialists } = this.props;
        const { showCallMeBack } = this.state;

        let component = null;

        if (!showCallMeBack) {
            if (immInvestmentSpecialists.get('isFetching')) {
                component = (
                    <ContentBox customClassName="callmeback">
                        <LoadingSection />
                    </ContentBox>
                );
            } else {
                component = this.buildView();
            }
        } else {
            component = this.buildConditionalView();
        }

        return component;
    }
}

CallMeBack.propTypes = {
    context: PropTypes.string,
    currentPath: PropTypes.string,
    getSpecialists: PropTypes.func,
    hasEntrance: PropTypes.bool,
    immFlagBasedFeatures: PropTypes.object,
    immGlobalVariables: PropTypes.object.isRequired,
    immInvestmentSpecialists: PropTypes.object,
    immProfile: PropTypes.object,
    isSmall: PropTypes.bool,
    modifier: PropTypes.string,
    onCallTimeChange: PropTypes.func.isRequired,
    onRequestPrivateCallTimeOptions: PropTypes.func.isRequired,
    onTelCodeChange: PropTypes.func.isRequired,
    requestCallMeBack: PropTypes.func,
    resetCallMeBack: PropTypes.func.isRequired,
    resetCallTimeOptions: PropTypes.func.isRequired,
    pageTitle: PropTypes.string,
    onRequestBrokerCallTimeOptions: PropTypes.func,
    originCountry: PropTypes.string
};

CallMeBack.defaultProps = {
    isSmall: false,
    hasEntrance: true
};

const container = connect(
    state => ({
        currentPath: state.routing.locationBeforeTransitions.pathname,
        immFlagBasedFeatures: state.flagBasedFeatures,
        immGlobalVariables: state.globalVariables,
        immInvestmentSpecialists: state.brokerInvestmentSpecialists,
        immProfile: state.profile,
        originCountry: state.profile.get('originCountry'),
    }),
    {
        getSpecialists: specialistsActions.getSpecialists,
        onCallTimeChange: specialistsActions.changeCallTime,
        onRequestPrivateCallTimeOptions: specialistsActions.requestPrivateCallTimeOptions,
        resetCallTimeOptions: specialistsActions.reset,
        onTelCodeChange: specialistsActions.changeTelCode,
        requestCallMeBack: specialistsActions.requestCallMeBack,
        resetCallMeBack: specialistsActions.resetCallMeBack,
        onRequestBrokerCallTimeOptions: specialistsActions.requestBrokerCallTimeOptions,
    }
)(CallMeBack);

module.exports.CallMeBack = withContactCenterValidation(container);
