import {
    AGGREGATOR,
    ASNEF,
    BIZUM,
    CHEQUES,
    CREDIT,
    EURIBOR,
    MANAGEMENT_FEES,
    MIFID,
    OPEN_YOUNG,
    PERSONETICS,
    ROBO,
    SECURITIES,
    SETBACKS,
    SOLIDARITY,
    TAXATION,
    TYPE_FUNDS,
} from "constants/flagBasedFeatures";

const DOCUMENTS_GROUPS = [
    {
        codGrupo: '0000',
        nombreGrupo: ''
    },
    {
        codGrupo: 'Agregador',
        nombreGrupo: 'Agregador',
        featureTogglingCheck: AGGREGATOR
    },
    {
        codGrupo: 'MIFID2',
        nombreGrupo: 'MIFID2',
        paginateLocally: true,
        pageSize: 10,
        featureTogglingCheck: MIFID
    },
    {
        codGrupo: 'MyFinanceNews',
        nombreGrupo: 'MyFinanceNews',
        featureTogglingCheck: PERSONETICS
    },
    {
        codGrupo: 'Bizum',
        nombreGrupo: 'Bizum',
        featureTogglingCheck: BIZUM
    },
    {
        codGrupo: 'testmifid',
        nombreGrupo: 'testmifid',
        featureTogglingCheck: MIFID
    },
    {
        codGrupo: 'ROBO',
        nombreGrupo: 'ROBO',
        featureTogglingCheck: ROBO
    },
    {
        codGrupo: 'accountdsc',
        nombreGrupo: 'accountdsc',
    },
    {
        codGrupo: 'GDPR',
        nombreGrupo: 'GDPR'
    },
    {
        codGrupo: 'BUREAUX',
        nombreGrupo: 'BUREAUX',
        featureTogglingCheck: ASNEF
    },
    {
        codGrupo: '0005',
        nombreGrupo: 'COBE',
        featureTogglingCheck: TAXATION
    },
    {
        codGrupo: '0006',
        nombreGrupo: 'EXTRACTOS'
    },
    {
        codGrupo: '0007',
        nombreGrupo: 'EMBARGOS',
        featureTogglingCheck: CREDIT
    },
    {
        codGrupo: '0008',
        nombreGrupo: 'DOMICILIACIONES',
    },
    {
        codGrupo: '0009',
        nombreGrupo: 'TRANSF. NAC.'
    },
    {
        codGrupo: '0010',
        nombreGrupo: 'TRASPASOS',
        featureTogglingCheck: TAXATION
    },
    {
        codGrupo: '0011',
        nombreGrupo: 'RIESGOS',
        featureTogglingCheck: CREDIT
    },
    {
        codGrupo: '0013',
        nombreGrupo: 'EXTRANJERO',
    },
    {
        codGrupo: '0014',
        nombreGrupo: 'PRESTAMOS',
        featureTogglingCheck: CREDIT
    },
    {
        codGrupo: '0020',
        nombreGrupo: 'FONDO INVERSION',
        featureTogglingCheck: TYPE_FUNDS
    },
    {
        codGrupo: '0022',
        nombreGrupo: 'SEGUROS',
        featureTogglingCheck: TAXATION
    },
    {
        codGrupo: '0033',
        nombreGrupo: 'INFORM. FISCAL'
    },
    {
        codGrupo: '0037',
        nombreGrupo: 'COMPENSACION',
        featureTogglingCheck: CHEQUES
    },
    {
        codGrupo: '0039',
        nombreGrupo: 'APERTURA CTAS.'
    },
    {
        codGrupo: '0042',
        nombreGrupo: 'TARJETAS'
    },
    {
        codGrupo: '0044',
        nombreGrupo: 'EURIBOR',
        featureTogglingCheck: EURIBOR
    },
    {
        codGrupo: '0045',
        nombreGrupo: 'PLAN PENSIONES',
        featureTogglingCheck: TAXATION
    },
    {
        codGrupo: '0046',
        nombreGrupo: 'PERSONAS',
        featureTogglingCheck: OPEN_YOUNG
    },
    {
        codGrupo: '0047',
        nombreGrupo: 'VALORES RA',
    },
    {
        codGrupo: '0049',
        nombreGrupo: 'RETROCESIONES',
        featureTogglingCheck: SETBACKS
    },
    {
        codGrupo: '0051',
        nombreGrupo: 'DESCUBIERTOS',
    },
    {
        codGrupo: '0101',
        nombreGrupo: 'VALORES',
        featureTogglingCheck: SECURITIES, 
    },
    {
        codGrupo: '0102',
        nombreGrupo: 'CUENTAS'
    },
    {
        codGrupo: '0105',
        nombreGrupo: 'FUTUROS',
        featureTogglingCheck: CREDIT
    },
    {
        codGrupo: '0107',
        nombreGrupo: 'EXTRACTOS',
        featureTogglingCheck: CREDIT
    },
    {
        codGrupo: '1518',
        nombreGrupo: 'COMISIONGCD',
        featureTogglingCheck: MANAGEMENT_FEES,
    },
    {
        codGrupo: '0109',
        nombreGrupo: 'POLIZAS',
        featureTogglingCheck: CREDIT
    },
    {
        codGrupo: '0116',
        nombreGrupo: 'DEPÓSITOS',
        featureTogglingCheck: CREDIT
    },
    {
        codGrupo: '0118',
        nombreGrupo: 'EFECTOS',
        featureTogglingCheck: CREDIT,
    },
    {
        codGrupo: 'Solidarios',
        nombreGrupo: 'Solidarios',
        featureTogglingCheck: SOLIDARITY
    },
    {
        codGrupo: 'DeprecationWarning',
        nombreGrupo: 'RoboAdvisor',
    },
    {
        codGrupo: 'DATABANK',
        nombreGrupo: 'DATABANK'
    },
    {
        codGrupo: '0119',
        nombreGrupo: 'LIQUIDACION CTAS'
    } ,{
        codGrupo: "1133",
        nombreGrupo: "DOCUMENTOS SOLICITADOS",
    },
];

const HASHED_DOC_GROUP = DOCUMENTS_GROUPS.reduce(function(hash, docGroup) {
    hash[docGroup.codGrupo] = docGroup;
    return hash;
}, {});

module.exports = {
    DOCUMENTS_GROUPS,
    HASHED_DOC_GROUP
};
