module.exports = {
    CREDIT_CARD_CREDIT_LIMIT_EXCEEDS_MAX_CODE: '410046',
    CREDIT_CARD_MAX_CARDS_EXCEEDED_CODE: '410038',
    CREDIT_CARD_REQUEST_IN_PROGRESS_CODE: '410044',
    CREDIT_CARD_REQUEST_IN_PROGRESS_CODE_CC: '410168',
    CREDIT_CARD_WRONG_CREDIT_LIMIT_CODE: '410045',
    CREDIT_CARD_WRONG_EMAIL_FORMAT_CODE: '410017',
    DEBIT_CARD_MAX_CARDS_EXCEEDED_CODE: '410038',
    DEBIT_CARD_MAX_CARDS_EXCEEDED_CODE_CC: '410168',
    DEBIT_CARD_SOLIDARITY_NO_FINISH_RECORD: '410176',
    CREDIT_CARD_LOW_CREDIT_SCORE: '9',
    CREDIT_CARD_NEXT_DAY_ERROR: [
        'ADMIOP_AR0002',
        'ADMIOP_AR0022',
        'ADMIOP_AR0024',
        'ADMIOP_AR0053',
        'ADMIOP_AR0054',
        'ADMIOP_AR0131',
        'ADMIOP_AR0280',
        'ADMIOP_AR0354',
        'ADMIOP_AR1099',
        'ADMIOP_AR3001',
        'ADMIOP_AR4002',
        'ADMIOP_AR5000',
        'ADMIOP_AR6666',
        'ADMIOP_AR8888',
        'ADMIOP_AR9999',
        'M4_1579',
        'MP_1579',
        'MP_4007'
    ]
};
