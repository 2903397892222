require('./brokerEditAlias.scss');

const PropTypes = require('prop-types');

const React = require('react');
const BrokerEditField = require('./brokerEditField/brokerEditField');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const { getFirstNChars } = require('utilities/stringHelper');
const classnames = require('classnames');

class BrokerEditAlias extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            aliasEditing: false
        };

        this.handleToggleEdit = this.handleToggleEdit.bind(this);
        this.editAliasCallback = this.editAliasCallback.bind(this);
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    editAliasCallback(result) {
        const { postAlias, allowEmptyAlias } = this.props;
        if (result.hasChanged && result.value.length > 0 || allowEmptyAlias) {
            postAlias(result.value);
        }
        this.handleToggleEdit();
    }

    handleToggleEdit() {
        this.setState( previousState => {
            return { aliasEditing: !previousState.aliasEditing}
        })
    }

    render() {
        const {
            alias,
            aliasPlaceHolder,
            error,
            errorMessage,
            limit,
            fontSize
        } = this.props;
        const { aliasEditing } = this.state;
        let aliasContent;

        if (aliasEditing) {
            aliasContent = (
                <BrokerEditField
                    allowMaxLength={this.props.allowMaxLength}
                    blurCallback={this.editAliasCallback}
                    initialValue={alias}
                    limit={limit}
                    placeholder={aliasPlaceHolder}
                    widthOffset={45}
                    font={fontSize}
                />
            );
        }
        else {
            const aliasClass = classnames("broker-edit-alias__value", {
                "broker-edit-alias__value--error" : error
            });
            const errorMessageContent = error ? <div className="broker-edit-alias__error-message">{errorMessage}</div> : null;
            aliasContent = (
                <div className="broker-edit-alias__container">
                    <span className={aliasClass} onClick={this.handleToggleEdit}>
                        {getFirstNChars(alias, 20)}
                    </span>
                    {errorMessageContent}
                </div>
            );
        }

        return (
            <div className="broker-edit-alias">
                {aliasContent}
            </div>
        );
    }
}

BrokerEditAlias.propTypes = {
    alias: PropTypes.string,
    aliasPlaceHolder: PropTypes.string,
    allowEmptyAlias: PropTypes.bool,
    allowMaxLength: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    postAlias: PropTypes.func,
    limit: PropTypes.number,
    fontSize: PropTypes.string
}

BrokerEditAlias.defaultProps = {
    alias: '',
    allowEmptyAlias: false,
    allowMaxLength: false,
    error: false,
    errorMessage: '',
    limit: 20
}

module.exports = BrokerEditAlias;
