module.exports = {
    ACCOUNT_CHOICE_ACTION_EVENT: 'selecciona una cuenta asociada',
    ACTION_EVENT_CLOSE_MODAL: 'cerrar modal',
    ACTION_EVENT_VIEW: 'vista',
    AGREEMENT_ACTION: "aceptacion",
    AMAZON_CODIGOS_FILTER_MODAL: "filtro modal",
    AMAZON_CODIGOS: "cuenta/cheque-regalo-codigos",
    AMAZON_CONSENT_MODAL_ACCEPT: 'acepto',
    AMAZON_CONSENT_MODAL_BACK: 'volver',
    AMAZON_CONSENT_MODAL_CONTINUE: "continuar",
    AMAZON_CONSENT_MODAL_ERROR: 'modal-cesion-de-datos/error',
    AMAZON_CONSENT_MODAL_OK : "entendido",
    AMAZON_CONSENT_MODAL_POLICIES: 'politica',
    AMAZON_CONSENT_MODAL_SHOW: 'modal-cesion-de-datos/gift',
    AMAZON_CONSENT_MODAL: "accounts/amazon-gift-check",
    AMAZON_GIFT_CARD_AMOUNT_ERROR: "error",
    AMAZON_GIFT_CARD_COUPEN_CODE_RETRY: "reintentar",
    AMAZON_GIFT_CARD_HIRING_ANTERIOR: 'anterior',
    AMAZON_GIFT_CARD_HIRING_CANCEL: 'cancelar',
    AMAZON_GIFT_CARD_HIRING_COMPRAR: 'comprar',
    AMAZON_GIFT_CARD_HIRING_IMPORTE: "importe",
    AMAZON_GIFT_CARD_HIRING_REINTENTAR: 'reintentar',
    AMAZON_GIFT_CARD_HIRING_SIGUIENTE: 'siguiente',
    AMAZON_GIFT_CARD_HIRING_VOLVER: 'volver a cuentas',
    AMAZON_GIFT_CARD_RETURN_TIPO_PROCESS: "proc-accounts-amazon-gift-check-return",
    AMAZON_GIFT_CARD_TIPO_PROCESS: "proc-cuenta-cheque-regalo-de-amazon",
    AMAZON_PROMOCIONES_CHECKBOX_CHECKED: "checked",
    AMAZON_PROMOCIONES_CHECKBOX_UNCHECKED: "unchecked",
    AMAZON_PROMOCIONES_COMAPRE_BUTTON: "comprarButton",
    AMAZON_PROMOCIONES_CONDITIONS_LINK: "condicionesLink",
    AMAZON_PROMOCIONES_STEP_ONE: "cuenta/cheque-regalo-promocion/paso 1",
    AMAZON_PROMOCIONES_STEP_THREE_SUCCESS: "cuenta/cheque-regalo-promocion/paso 3/exito",
    AMAZON_PROMOCIONES_STEP_THREE: "cuenta/cheque-regalo-promocion/paso 3/error",
    AMAZON_PROMOCIONES_STEP_TWO: "cuenta/cheque-regalo-promocion/paso 2",
    AMAZON_PROMOCIONES: "cuenta/cheque-regalo-promocion",
    AMAZON_RETURN_GIFT_VOUCHER_CANCEL: "cancelar",
    AMAZON_RETURN_GIFT_VOUCHER_CONFIRM: "confirmar",
    AMAZON_RETURN_GIFT_VOUCHER_REINTENTAR: 'reintentar',
    BACK_TO_ACCOUNTS_ACTION_EVENT: 'volver a cuentas',
    BACK_TO_CARDS_ACTION_EVENT: 'volver a tarjetas',
    BUTTON_CLICK: 'click en boton',
    CANCEL_ACTION_EVENT: 'cancelar',
    CARD_SELECTION_ACTION_EVENT: "seleccion tarjeta",
    CHECKED_ACTION_EVENT: 'checked',
    MAESTRO_CARD_EVENT:'mensaje warning/mastercard',
    MAESTRO_BLOCK_CARD_LINK:'tarjeta/bloqueo',
    MAESTRO_NEW_CARD_LINK:'tarjeta/reemplazo',
    CC_DEPOSIT_EXCLUSIVE_OFFER: 'oferta exclusiva CC',
    CHEVRON_CLOSE: "cerrar",
    CHEVRON_OPEN: "abrir",
    CLICK_LINK_ACTION: "clickLink",
    CLICK_BUTTON_ACTION: "clickButton",
    COMMERCIAL_ADS_ACTION_EVENT: 'click',
    COMMERCIAL_ADS_AI_ID: 'IA',
    COMMERCIAL_ADS_CLICK_TEALIUM_EVENT: 'promo_click',
    COMMERCIAL_ADS_EVENT_CATEGORY: 'espacio comercial ',
    COMMERCIAL_ADS_TEALIUM_EVENT: 'promotion_impression',
    COMMERCIAL_INTERSTITIAL_CLOSE_AND_NEVER_SEE: 'cerrar y no volver a ver',
    COMMERCIAL_INTERSTITIAL_EVENT_CATEGORY: 'interstitial comercial ',
    COMMERCIAL_WAFER_ADS_EVENT_CATEGORY: 'espacio obleas ',
    CONSENTS_ACTION_EVENT: 'consentimientos',
    CONTRACT_BUTTON: 'contratar',
    CONTRATOS_DETAIL_PROCESS: 'contratos',
    CONVERT_TO_OPEN_PAYROLL_ACCOUNT_PASO_PROCESS: 'inicio',
    CONVERT_TO_OPEN_PAYROLL_ACCOUNT_TIPO_PROCESS: 'convertir a cuenta nomina open',
    DEFERRAL_DEPOSIT_OFFER_BUTTON_ACTION_EVENT: 'contratar ahora',
    DEFERRAL_DEPOSIT_OFFERS_BUTTON_ACTION_EVENT: 'ver ofertas',
    DEFERRAL_DEPOSIT_OFFER_ACTION_EVENT: 'vista mensaje cta',
    DEFERRAL_DEPOSIT_MULTI_OFFER_ACTION_EVENT: 'vista mensaje telefono',
    DEFERRAL_DEPOSIT_OFFER_CATEGORY_EVENT: 'deposito proximo vencimiento',
    DEFERRAL_DEPOSIT_BLOCKED_ACCOUNT_ACTION_EVENT: 'vista mensaje cuenta bloqueada',
    DEFERRAL_DEPOSIT_INPUT_TOOLTIP_ACTION_EVENT: 'importe del deposito',
    DEFERRAL_DEPOSIT_INPUT_TOOLTIP_TAG_EVENT: 'tooltip',
    DEFERRAL_DEPOSIT_INPUT_ERROR_CATEGORY: 'importe mayor que el contratado',
    DEFERRAL_DEPOSIT_PRECONTRACTUAL_LINK_ACTION: 'click en informacion',
    OPT_ACTION_EVENT: 'codigo de confirmacion',
    DEFERRAL_PAYMENT_ACCOUNT_ACTION_EVENT: 'aplaza tu pago',
    DEFERRAL_PAYMENT_CANCEL: 'cancelar',
    DEFERRAL_PAYMENT_CARD_ACTION_EVENT: 'aplaza tu compra',
    DEFERRAL_PAYMENT_CLICK_BUTTON_ACTION_EVENT: 'clickButton',
    DEFERRAL_PAYMENT_CLICK_TAG_ACTION_EVENT: 'clickTag',
    DEFERRAL_PAYMENT_FINISH: 'aplazar',
    DEFERRAL_PAYMENT_NEXT: 'siguiente',
    DEFERRAL_PAYMENT_OPTION_ACTION_EVENT: 'opcion de aplazamiento',
    DEFERRAL_PAYMENT_PREVIOUS: 'anterior',
    DEFERRAL_PAYMENT_QUOTA_INPUT_ACTION_EVENT: 'clickTextBox',
    DEFERRAL_PAYMENT_QUOTA_SLIDER_ACTION_EVENT: 'slider',
    DEFERRAL_DEPOSIT_MODAL_CONDITIONS_ACTION_EVENT: 'consultar condiciones',
    DEFERRAL_DEPOSIT_MODAL_CONDITIONS_CATEGORY_EVENT: 'interaccion en depositos',
    DEFERRAL_DEPOSIT_MODAL_CONDITIONS_CLOSE_TAG_EVENT: 'cerrar',
    DEFERRAL_DEPOSIT_MODAL_CONDITIONS_TAG_EVENT: 'vista',
    DEFERRAL_DEPOSIT_MODAL_CONDITIONS_READ_TAG_EVENT: 'entendido',
    DEFERRAL_DEPOSIT_MODAL_CONDITIONS_SHOW_MORE_TAG_EVENT: 'ver mas',
    DEFERRAL_DEPOSIT_MODAL_CONDITIONS_SHOW_LESS_TAG_EVENT: 'ocultar detalles',
    DEFERRAL_DEPOSIT_REQUEST_RENEW_CATEGORY_EVENT: "deposito proximo vencimiento",
    DEFERRAL_DEPOSIT_REQUEST_RENEW_ACTION_EVENT: "vista mensaje condiciones",
    DEFERRAL_DEPOSIT_REQUEST_CONDITIONS_ACTION_EVENT: "consultar condiciones",
    DEFERRAL_DEPOSIT_MODAL_CONDITIONS_FETCH_FAILED_TAG_EVENT: 'error cargando informacion',
    DEFERRED_PAYMENT_INSUFFICIENT_BALANCE: 'saldo no disponible',
    DEFERRED_PAYMENT_LIMIT: 'maximo limite de credito tarjeta',
    DELETE_ACTION_EVENT: 'eliminar',
    DEPOSIT_BACK: 'volver',
    DEPOSIT_BIGGER_THAN_GLOBAL_POSITION_INCREMENT: 'modal importe mayor que incremento en posicion global',
    DEPOSIT_BIGGER_THAN_BALANCE: 'modal importe mayor que en la cuenta',
    DEPOSIT_CANCEL_EVENT_TAG: 'ver cancelados/vencidos',
    DEPOSIT_EMPTY_STATE_CLOSED_DEPOSITS_CLICK: 'click ver cancelados o vencidos',
    DEPOSIT_EMPTY_STATE_SEE_CLOSED_DEPOSITS: 'ver cancelados o vencidos',
    DEPOSIT_EMPTY_STATE_CLOSED_DEPOSITS: 'cancelados o vencidos',
    DEPOSIT_EMPTY_STATE_NO_HIRED: 'no contratados',
    DEPOSIT_EMPTY_STATE_LANDING: 'landing depositos',
    DEPOSIT_EMPTY_STATE_NO_HIRED_VIEW: 'vista mensaje sin depositos contratados',
    DEPOSIT_CHOICE: 'elige el plazo de tu deposito',
    DEPOSIT_SINGLE_CHOICE: 'deposito elegido',
    DEPOSIT_CONTRACT_BUTTON: 'mas informacion',
    DEPOSIT_ERROR_EVENT_TAG: 'textBox - error',
    DEPOSIT_IMPORT: 'importe del deposito',
    DEPOSIT_NEW_MONEY: 'con dinero nuevo',
    DEPOSIT_WITHOUT_NEW_MONEY: 'sin dinero nuevo',
    DEPOSIT_RENOVATION: 'renovacion del deposito al vencimiento',
    DEPOSIT_TEXTBOX: 'textBox',
    DEPOSIT_VIEW: 'vista',
    DEPOSIT_CLOSE: 'cerrar',
    DEPOSIT_CONTINUE: 'continuar',
    DEPOSIT_ACCEPT: 'aceptar',
    IA_INVESTMENT_HOME_INTERACTIONS: 'IAInvestmentHomeInteractions',
    INCREASE_NEW_MONEY_MODAL_CLOSE: 'salir',
    INCREASE_NEW_MONEY_MODAL_CONTINUE: 'ver otros depositos',
    DEPOSIT_MORE_INFO: 'modal importe maximo',
    DEPOSIT_NO_MONEY: 'modal incrementa el saldo de posicion global',
    DEVICES_BLOCK: "dispositivos/bloquear",
    DEVICES_CANCEL: "cancelar",
    DEVICES_CHEVRON: "dispositivos/chevron",
    DEVICES_CLOSE: "dispositivos/cerrar-sesion",
    DEVICES_EDIT_NAME: "dispositivos/editar-nombre",
    DEVICES_ERROR: "error",
    DEVICES_HIDE: "dispositivos/ocultar",
    DEVICES_INIT: "inicio",
    DEVICES_MANAGEMENT: "gestionar dispositivos",
    DEVICES_SHOW: "dispositivos/mostrar",
    DEVICES_SUCCESS: "exito",
    DEVICES_TOOLTIP: "gestionar dispositivos/tooltip",
    DOWNLOAD_ACTION_EVENT: 'descargar',
    ETF_LEGAL_INFORMATION: "informacion legal etps etcs",
    HIDDEN_DEVICES: "gestionar dispositivos/ocultos",
    KEYS_SIGNATURE_CATEGORY_ERROR: 'otp/error/claves/firma/cambio',
    KEYS_SIGNATURE_EVENT_TAG: 'claves/firma/cambio',
    LINK_CLICK_ACTION_EVENT: 'click en enlace',
    LOGOUT_MODAL_EVENT: 'modal timeout/sesion-caducada',
    LOGOUT_MODAL_REENTER: 'volver a entrar',
    MANAGE_SECURITY: "gestionar",
    MODIFY_SECURITY: "cambiar",
    MORE_MOVEMENTS_ACTION_EVENT: "ver mas movimientos",
    MORE_MOVEMENTS_EVENT_TAG: "clickLink",
    MY_PROFILE_KEY_SIGNATURE_STEP1_ERROR_ACTION_EVENT: "error clave de firma",
    MY_PROFILE_KEY_SIGNATURE_STEP1_ERROR_EVENT: "error/clave de firma",
    MY_PROFILE_KEY_SIGNATURE_STEP1_ERROR_DESCRIPTION_EVENT: "la clave no coincide",
    MY_PROFILE_KEY_SIGNATURE_STEP1_ONCHANGE_ACTION_EVENT: "text box",
    MY_PROFILE_KEY_SIGNATURE_FINISH_TAG_EVENT: "confirmar",
    MY_PROFILE_KEY_SIGNATURE_SMS_ACTION_EVENT: "text box",
    MY_PROFILE_KEY_SIGNATURE_SMS_TAG_EVENT: "codigo de confirmacion",
    MY_PROFILE_KEY_SIGNATURE_SMS_RESEND_ACTION_EVENT: "error codigo de confirmacion",
    MY_PROFILE_KEY_SIGNATURE_SMS_RESEND_CATEGORY_EVENT: "error/codigo de confirmacion",
    MY_PROFILE_KEY_SIGNATURE_SMS_RESEND_DESCRIPTION_EVENT: "codigo incorrecto",
    MY_PROFILE_KEY_SIGNATURE_MODAL_OPEN_TAG_EVENT: "solicitar nueva clave de firma",
    MY_PROFILE_KEY_SIGNATURE_MODAL_VIEW_ACTION_EVENT: "modal envio clave de firma",
    MY_PROFILE_KEY_SIGNATURE_MODAL_CLOSE_ACTION_EVENT: "modal envio clave de firma",
    MY_PROFILE_KEY_SIGNATURE_MODAL_CLOSE_TAG_EVENT: "cerrar modal",
    MY_PROFILE_KEY_SIGNATURE_MODAL_SECONDARY_BUTTON_ACTION_EVENT: "modal envio clave de firma",
    MY_PROFILE_KEY_SIGNATURE_MODAL_SECONDARY_BUTTON_TAG_EVENT: "cancelar",
    MY_PROFILE_KEY_SIGNATURE_VIEW_STEP_EVENT: "paso_1",
    PAGE_MORTGAGE_PANEL: "/mortgage/panel/",
    MORTGAGE_INSURANCE_BONUS_CLICK: "click en bonificaciones",
    NEXT_ACTION_EVENT: 'siguiente',
    NOTIFICATIONS_FILTER: "notificaciones filtro",
    NOTIFICATIONS_INIT: "inicio",
    OPEN_ACTION_EVENT: 'abrir',
    OPEN_MARKET_BANK_ACCOUNT_EVENT_TAG: "cuenta de banco seleccionada",
    OPEN_MARKET_DOCUMENT_DOWNLOAD_EVENT_TAG: "descargar documentacion",
    OPEN_MARKET_DOCUMENT_PRINT_EVENT_TAG: "imprimir documentacion",
    OPEN_MARKET_PRE_LEAVE_MODAL_EVENT_TAG: "modal cancelacion operacion",
    OPEN_MARKET_PRODUCT_MODAL_EVENT_TAG: "modal detalle de producto",
    OPEN_MARKET_SEE_ACTION_DETAILS: "ver detalles",
    PASSWORD: "clave de acceso",
    PERSONETICS_EVENT_CATEGORY: "personetics",
    POSITION_GLOBAL_EVENT_CATEGORY:"posicion-global",
    PRINT_ACTION_EVENT: 'imprimir',
    PREVIOUS_ACTION_EVENT: 'anterior',
    PERSONETICS_INBOX_EVENT_CATEGORY:"personetics-inbox",
    REGISTRY_SECURITY: "registrar",
    REQUESTED_DOCUMENTS: 'documentos/solicitados',
    SCROLL_ACTION_EVENT: 'scroll',
    SECURITY_FAQS: "preguntas frecuentes",
    SECURITY_PAGE_NAME: "seguridad y claves",
    SECURITY_PERSONALIZATION: "personaliza tu seguridad",
    SEE_DETAILS_ACCOUNT_ACTION_EVENT: "ver detalles de cuenta",
    SEE_DETAILS_CARD_ACTION_EVENT: "ver detalles de tarjeta",
    SEE_DETAILS_EVENT_TAG: "clickLink",
    SELECTION_ACTION_EVENT: 'seleccion',
    SEND_MAIL_INFORMATION: "enviar informacion por mail",
    SEND_KEYS_SIGNATURE_EVENT_TAG: 'claves/firma/envio',
    SEND_KEYS_SIGNATURE_CATEGORY_ERROR: 'otp/error/claves/firma/envio',
    SERVICE_DENIED: "denegacion servicio",
    SERVICE_ERROR: "error servicio",
    SET_ID: 'setID',
    SIGNATURE_PATTERN: "clave de firma",
    SIMULATOR_PENSION_PLAN_BENEFITS: "pension plan benefits simulator",
    TIMEOUT_MODAL_LOGOUT: 'cerrar sesion',
    TIMEOUT_MODAL_MAINTAIN_SESSION: 'mantener sesion abierta',
    TIMEOUT_MODAL: 'modal timeout',
    TRANSFERS_WARNING_MODAL: 'modal estafas',
    TRANSFERS_WARNING_MODAL_BACK: 'volver',
    TRANSFERS_WARNING_MODAL_ACCEPT: 'acepto',
    TRANSFERS_WARNING_MODAL_REJECT: 'noAcepto',
    TRANSFERS_WARNING_MODAL_CONFIRM: 'confirmar',
    TPPS_MANAGEMENT: "gestionar tpps",
    UNCHECKED_ACTION_EVENT: 'unchecked',
    UPDATE_MAIL: "actualizar mail",
    UPDATE_EMAIL: "actualizar email",
    UPLOAD_DOCUMENTATION: "documentos/subida de documentacion",
    BLOCKING_CARDS:'tarjetas-bloqueo definitivo',
    ROBO:'robo',
    PERDIDA:'perdida',
    BIZUM_NEW_CUSTOMER: 'bizumNewCustomer',
    SEND_TERMS_AND_CONDITIONS_BUTTON: 'sendTermsAndConditionsButton',
    SEND_INFORMATION_BY_MAIL_BUTTON: 'sendInformationByMailButton',
    VIEW_MESSAGE: 'viewMessage',
    FAILED_TO_SEND_MAIL_MESSAGE: 'failedToSendMailMessage',
    FAILED_TO_SEND_MAIL: 'failedToSendMail'
};
