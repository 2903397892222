/**
 * PROC_ADD_CO_HOLDER:
 *  inicia y cancela "components/mortgageRequest/mortgageRequestCoHolder/mortgageRequestCoHolder".
 *  falla y guarda en "actions/mortgageRequest/mortgageRequestHolder"
 *
 * PROC_CHANGE_HOLDER_DATA:
 *  inicia y cancela "components/mortgageRequest/mortgageRequestHolderInformation/mortgageRequestHolderInformation".
 *  falla y guarda en "actions/mortgageRequest/mortgageRequestHolder"
 *
 * PROC_CHANGE_DATE_LIMIT_FIRM:
 *  inicia y cancela "components/mortgageRequest/mortgageRequestTargetDateBar/mortgageRequestTargetDateBar"
 *  falla y guarda en "actions/mortgageRequest/mortgageRequestTargetDate"
 *
 * PROC_CHANGE_MORTGAGE_DATA, PROC_DELETE_MORTGAGE_DATA, PROC_RECALCULATE_MORTGAGE_QUOTE:
 *  inicia y/o cancela "components/mortgageRequest/mortgageRequestMortgageEditForm/mortgageRequestMortgageEditForm"
 *  falla y guarda en "actions/mortgageRequest/mortgageRequestTargetDate"
 *
 * PROC_ADD_ESTATES_DATA:
 *  inicia y cancela "routes/mortgageRequestView/mortgageRequestEstatesView/mortgageRequestEstatesView"
 *  falla y guarda en "actions/mortgageRequest/mortgageRequestEstates"
 *
 * PROC_ADD_SIMPLE_NOTE:
 *  inicia y cancela "components/mortgageRequest/mortgageRequestSimpleNote/mortgageRequestSimpleNote"
 *  falla y guarda en "actions/mortgageRequest/mortgageRequestSimpleNote"
 *
 *  PROC_ADD_PROPERTY_VALUE:
 *  inicia y cancela "components/mortgageRequest/mortgagePropertyValue/mortgagePropertyValue"
 *  falla y guarda en "actions/mortgageRequest/mortgageRequestPropertyValue"
 *
 *  PROC_ADD_PROPERTY_CERT:
 *  inicia y cancela "components/mortgageRequest/mortgagePropertyValue/mortgagePropertyValue"
 *  falla y guarda en "actions/mortgageRequest/mortgageRequestPropertyValue"
 *
 *  PROC_CHANGE_WORK_DATA:
 *  inicia y cancela "components/mortgageRequest/mortgageRequestWorkData/mortgageRequestWorkData"
 *  falla y guarda en "actions/mortgageRequest/mortgageRequestWorkData"
 *
 */

const processes = {
    PROC_ADD_CO_HOLDER: 'proc-agregar-co-titular',
    PROC_CHANGE_HOLDER_DATA: 'proc-modificar-datos-titular',
    PROC_CHANGE_TIME_LIMIT_FIRM: 'proc-modificar-fecha-limite-firma',
    PROC_CHANGE_MORTGAGE_DATA: 'proc-modifificar-datos-hipoteca',
    PROC_DELETE_MORTGAGE_DATA: 'proc-eliminar-datos-hipoteca',
    PROC_RECALCULATE_MORTGAGE_QUOTE: 'proc-recalcular-cuota-hipoteca',
    PROC_ADD_ESTATES_DATA: 'proc-agregar-cantidad-inmuebles',
    PROC_ADD_SIMPLE_NOTE: 'proc-agregar-nota-simple',
    PROC_ADD_PROPERTY_VALUE: 'proc-agregar-tasacion',
    PROC_ADD_PROPERTY_CERT: 'proc-agregar-certificado-tasacion',
    PROC_CHANGE_WORK_DATA: 'proc-modificar-informacion-laboral',
    PROC_CHANGE_ECONOMIC_DATA: 'proc-modificar-informacion-economica'
}

module.exports = processes;
