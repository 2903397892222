const MICCODE_CONVERSION =
[
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"APXL","OPERATING MIC":"APXL","NAME-INSTITUTION DESCRIPTION":"SYDNEY STOCK EXCHANGE LIMITED","ACRONYM":"SSX","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"AWBX","OPERATING MIC":"AWBX","NAME-INSTITUTION DESCRIPTION":"AUSTRALIAN WHEAT BOARD","ACRONYM":"AWB","CITY":"MELBOURNE"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"AWEX","OPERATING MIC":"AWEX","NAME-INSTITUTION DESCRIPTION":"AUSTRALIAN WOOL EXCHANGE","ACRONYM":"AWEX","CITY":"LANE COVE"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"BLEV","OPERATING MIC":"BLEV","NAME-INSTITUTION DESCRIPTION":"BLOCK EVENT","ACRONYM":"","CITY":"MELBOURNE"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CGMA","OPERATING MIC":"CGMA","NAME-INSTITUTION DESCRIPTION":"CITI MATCH AUSTRALIA","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CHIA","OPERATING MIC":"CHIA","NAME-INSTITUTION DESCRIPTION":"CHI-X AUSTRALIA","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CXAC","OPERATING MIC":"CHIA","NAME-INSTITUTION DESCRIPTION":"CHI-X MARKET AUSTRALIA - LIMIT VENUE","ACRONYM":"CXA","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CXAV","OPERATING MIC":"CHIA","NAME-INSTITUTION DESCRIPTION":"CHI-X VWAP","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CXAM","OPERATING MIC":"CHIA","NAME-INSTITUTION DESCRIPTION":"CHI-X MOC","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CXAP","OPERATING MIC":"CHIA","NAME-INSTITUTION DESCRIPTION":"CHI-X AUSTRALIA MID-POINT VENUE","ACRONYM":"CXA","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CXAN","OPERATING MIC":"CHIA","NAME-INSTITUTION DESCRIPTION":"CHI-X AUSTRALIA PRIMARY PEG (NEARPOINT) VENUE","ACRONYM":"CXA","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CXAF","OPERATING MIC":"CHIA","NAME-INSTITUTION DESCRIPTION":"CHI-X AUSTRALIA MARKET PEG (FARPOINT) VENUE","ACRONYM":"CXA","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CXAW","OPERATING MIC":"CHIA","NAME-INSTITUTION DESCRIPTION":"CHI-X AUSTRALIA - WARRANTS","ACRONYM":"CXA","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CXAR","OPERATING MIC":"CHIA","NAME-INSTITUTION DESCRIPTION":"CHI-X AUSTRALIA - TRANSFERABLE CUSTODY RECEIPT MARKET","ACRONYM":"CHI-X","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CXAQ","OPERATING MIC":"CHIA","NAME-INSTITUTION DESCRIPTION":"CHI-X AUSTRALIA -QUOTED MANAGED FUNDS","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CLAU","OPERATING MIC":"CLAU","NAME-INSTITUTION DESCRIPTION":"CLSA AUSTRALIA - DARK","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CSAU","OPERATING MIC":"CSAU","NAME-INSTITUTION DESCRIPTION":"CREDIT SUISSE EQUITIES (AUSTRALIA) LIMITED","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"CFAU","OPERATING MIC":"CSAU","NAME-INSTITUTION DESCRIPTION":"CROSSFINDER AUSTRALIA","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"MAQX","OPERATING MIC":"MAQX","NAME-INSTITUTION DESCRIPTION":"MACQUARIE INTERNAL MARKETS (AUSTRALIA)","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"MSAL","OPERATING MIC":"MSAL","NAME-INSTITUTION DESCRIPTION":"MORGAN STANLEY AUSTRALIA SECURITIES LIMITED","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"NSXB","OPERATING MIC":"NSXB","NAME-INSTITUTION DESCRIPTION":"BENDIGO STOCK EXCHANGE LIMITED","ACRONYM":"BSX","CITY":"MELBOURNE"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"SIGA","OPERATING MIC":"SIGA","NAME-INSTITUTION DESCRIPTION":"SIGMA X AUSTRALIA","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"SIMV","OPERATING MIC":"SIMV","NAME-INSTITUTION DESCRIPTION":"SIM VENTURE SECURITIES EXCHANGE","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"XASX","OPERATING MIC":"XASX","NAME-INSTITUTION DESCRIPTION":"ASX - ALL MARKETS","ACRONYM":"ASX","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"ASXC","OPERATING MIC":"XASX","NAME-INSTITUTION DESCRIPTION":"ASX - CENTER POINT","ACRONYM":"ASX","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"ASXP","OPERATING MIC":"XASX","NAME-INSTITUTION DESCRIPTION":"ASX - PUREMATCH","ACRONYM":"ASX","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"ASXT","OPERATING MIC":"XASX","NAME-INSTITUTION DESCRIPTION":"ASX TRADEMATCH","ACRONYM":"ASX","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"ASXV","OPERATING MIC":"XASX","NAME-INSTITUTION DESCRIPTION":"ASX - VOLUMEMATCH","ACRONYM":"ASX","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"XSFE","OPERATING MIC":"XASX","NAME-INSTITUTION DESCRIPTION":"ASX - TRADE24","ACRONYM":"SFE","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"ASXB","OPERATING MIC":"XASX","NAME-INSTITUTION DESCRIPTION":"ASX BOOKBUILD","ACRONYM":"ASX","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"XFEX","OPERATING MIC":"XFEX","NAME-INSTITUTION DESCRIPTION":"FINANCIAL AND ENERGY EXCHANGE GROUP","ACRONYM":"FEX","CITY":"SYDNEY"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"XNEC","OPERATING MIC":"XNEC","NAME-INSTITUTION DESCRIPTION":"NATIONAL STOCK EXCHANGE OF AUSTRALIA LIMITED","ACRONYM":"NSXA","CITY":"NEWCASTLE"},
{"COUNTRY":"AUSTRALIA","ISO_COUNTRY_CODE":"AU","MIC":"XYIE","OPERATING MIC":"XYIE","NAME-INSTITUTION DESCRIPTION":"YIELDBROKER PTY LTD","ACRONYM":"","CITY":"SYDNEY"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"BLPX","OPERATING MIC":"BLPX","NAME-INSTITUTION DESCRIPTION":"BELGIAN POWER EXCHANGE","ACRONYM":"BLPX","CITY":"BRUSSELS"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"BMTS","OPERATING MIC":"BMTS","NAME-INSTITUTION DESCRIPTION":"MTS BELGIUM","ACRONYM":"","CITY":"BRUSSELS"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"MTSD","OPERATING MIC":"BMTS","NAME-INSTITUTION DESCRIPTION":"MTS DENMARK","ACRONYM":"","CITY":"BRUSSELS"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"MTSF","OPERATING MIC":"BMTS","NAME-INSTITUTION DESCRIPTION":"MTS FINLAND","ACRONYM":"","CITY":"BRUSSELS"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"XBRU","OPERATING MIC":"XBRU","NAME-INSTITUTION DESCRIPTION":"EURONEXT - EURONEXT BRUSSELS","ACRONYM":"","CITY":"BRUSSELS"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"ALXB","OPERATING MIC":"XBRU","NAME-INSTITUTION DESCRIPTION":"EURONEXT - ALTERNEXT BRUSSELS","ACRONYM":"","CITY":"BRUSSELS"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"ENXB","OPERATING MIC":"XBRU","NAME-INSTITUTION DESCRIPTION":"EURONEXT - EASY NEXT","ACRONYM":"","CITY":"BRUSSELS"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"MLXB","OPERATING MIC":"XBRU","NAME-INSTITUTION DESCRIPTION":"EURONEXT - MARCHE LIBRE BRUSSELS","ACRONYM":"","CITY":"BRUSSELS"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"TNLB","OPERATING MIC":"XBRU","NAME-INSTITUTION DESCRIPTION":"EURONEXT - TRADING FACILITY BRUSSELS","ACRONYM":"","CITY":"BRUSSELS"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"VPXB","OPERATING MIC":"XBRU","NAME-INSTITUTION DESCRIPTION":"EURONEXT - VENTES PUBLIQUES BRUSSELS","ACRONYM":"","CITY":"BRUSSELS"},
{"COUNTRY":"BELGIUM","ISO_COUNTRY_CODE":"BE","MIC":"XBRD","OPERATING MIC":"XBRU","NAME-INSTITUTION DESCRIPTION":"EURONEXT - EURONEXT BRUSSELS - DERIVATIVES","ACRONYM":"","CITY":"BRUSSELS"},
{"COUNTRY":"BRAZIL","ISO_COUNTRY_CODE":"BR","MIC":"BCMM","OPERATING MIC":"BCMM","NAME-INSTITUTION DESCRIPTION":"BOLSA DE CEREAIS E MERCADORIAS DE MARINGÁ","ACRONYM":"","CITY":"MARINGA"},
{"COUNTRY":"BRAZIL","ISO_COUNTRY_CODE":"BR","MIC":"BOVM","OPERATING MIC":"BOVM","NAME-INSTITUTION DESCRIPTION":"BOLSA DE VALORES MINAS-ESPÍRITO SANTO-BRASÍLIA","ACRONYM":"","CITY":"ESPIRITO SANTO"},
{"COUNTRY":"BRAZIL","ISO_COUNTRY_CODE":"BR","MIC":"BRIX","OPERATING MIC":"BRIX","NAME-INSTITUTION DESCRIPTION":"BRAZILIAN ENERGY EXCHANGE","ACRONYM":"","CITY":"SAO PAULO"},
{"COUNTRY":"BRAZIL","ISO_COUNTRY_CODE":"BR","MIC":"BVMF","OPERATING MIC":"BVMF","NAME-INSTITUTION DESCRIPTION":"BM&FBOVESPA S.A. - BOLSA DE VALORES, MERCADORIAS E FUTUROS","ACRONYM":"","CITY":"SAO PAULO"},
{"COUNTRY":"BRAZIL","ISO_COUNTRY_CODE":"BR","MIC":"CETI","OPERATING MIC":"CETI","NAME-INSTITUTION DESCRIPTION":"CETIP S.A. - MERCADOS ORGANIZADOS","ACRONYM":"","CITY":"RIO DE JANEIRO"},
{"COUNTRY":"BRAZIL","ISO_COUNTRY_CODE":"BR","MIC":"SELC","OPERATING MIC":"SELC","NAME-INSTITUTION DESCRIPTION":"SISTEMA ESPECIAL DE LIQUIDACAO E CUSTODIA DE TITULOS PUBLICOS","ACRONYM":"","CITY":"RIO DE JANEIRO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"CAND","OPERATING MIC":"CAND","NAME-INSTITUTION DESCRIPTION":"CANDEAL.CA INC","ACRONYM":"CANDEAL","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"CANX","OPERATING MIC":"CANX","NAME-INSTITUTION DESCRIPTION":"CANNEX FINANCIAL EXCHANGE LTS","ACRONYM":"CANNEX","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"CHIC","OPERATING MIC":"CHIC","NAME-INSTITUTION DESCRIPTION":"CHI-X CANADA ATS","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XCX2","OPERATING MIC":"CHIC","NAME-INSTITUTION DESCRIPTION":"CX2","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"COTC","OPERATING MIC":"COTC","NAME-INSTITUTION DESCRIPTION":"BMO CAPITAL MARKETS - CAD OTC TRADES","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"IFCA","OPERATING MIC":"IFCA","NAME-INSTITUTION DESCRIPTION":"ICE FUTURES CANADA","ACRONYM":"","CITY":"WINNIPEG"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"IVZX","OPERATING MIC":"IVZX","NAME-INSTITUTION DESCRIPTION":"INVESCO CANADA PTF TRADES","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"LICA","OPERATING MIC":"LICA","NAME-INSTITUTION DESCRIPTION":"LIQUIDNET CANADA ATS","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"MATN","OPERATING MIC":"MATN","NAME-INSTITUTION DESCRIPTION":"MATCH NOW","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"NEOE","OPERATING MIC":"NEOE","NAME-INSTITUTION DESCRIPTION":"AEQUITAS NEO EXCHANGE","ACRONYM":"NEO EXCHANGE","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"NGXC","OPERATING MIC":"NGXC","NAME-INSTITUTION DESCRIPTION":"NATURAL GAS EXCHANGE","ACRONYM":"NGX","CITY":"CALGARY"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"OMGA","OPERATING MIC":"OMGA","NAME-INSTITUTION DESCRIPTION":"OMEGA ATS","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"LYNX","OPERATING MIC":"OMGA","NAME-INSTITUTION DESCRIPTION":"LYNX ATS","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"TMXS","OPERATING MIC":"TMXS","NAME-INSTITUTION DESCRIPTION":"TMX SELECT","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XATS","OPERATING MIC":"XATS","NAME-INSTITUTION DESCRIPTION":"ALPHA EXCHANGE","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XBBK","OPERATING MIC":"XBBK","NAME-INSTITUTION DESCRIPTION":"PERIMETER FINANCIAL CORP. - BLOCKBOOK ATS","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XCNQ","OPERATING MIC":"XCNQ","NAME-INSTITUTION DESCRIPTION":"CANADIAN NATIONAL STOCK EXCHANGE","ACRONYM":"CNSX","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"PURE","OPERATING MIC":"XCNQ","NAME-INSTITUTION DESCRIPTION":"PURE TRADING","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XCXD","OPERATING MIC":"XCXD","NAME-INSTITUTION DESCRIPTION":"NASDAQ CXD","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XICX","OPERATING MIC":"XICX","NAME-INSTITUTION DESCRIPTION":"INSTINET CANADA CROSS","ACRONYM":"","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XMOC","OPERATING MIC":"XMOC","NAME-INSTITUTION DESCRIPTION":"MONTREAL CLIMATE EXCHANGE","ACRONYM":"","CITY":"MONTREAL"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XMOD","OPERATING MIC":"XMOD","NAME-INSTITUTION DESCRIPTION":"THE MONTREAL EXCHANGE / BOURSE DE MONTREAL","ACRONYM":"CDE","CITY":"MONTREAL"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XTSE","OPERATING MIC":"XTSE","NAME-INSTITUTION DESCRIPTION":"TORONTO STOCK EXCHANGE","ACRONYM":"TSX","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XTSX","OPERATING MIC":"XTSX","NAME-INSTITUTION DESCRIPTION":"TSX VENTURE EXCHANGE","ACRONYM":"TSX-V","CITY":"TORONTO"},
{"COUNTRY":"CANADA","ISO_COUNTRY_CODE":"CA","MIC":"XTNX","OPERATING MIC":"XTSX","NAME-INSTITUTION DESCRIPTION":"TSX VENTURE EXCHANGE - NEX","ACRONYM":"NEX","CITY":"VANCOUVER"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"CCFX","OPERATING MIC":"CCFX","NAME-INSTITUTION DESCRIPTION":"CHINA FINANCIAL FUTURES EXCHANGE","ACRONYM":"","CITY":"SHANGHAI"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"CSSX","OPERATING MIC":"CSSX","NAME-INSTITUTION DESCRIPTION":"CHINA STAINLESS STEEL EXCHANGE","ACRONYM":"","CITY":"WUXI"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"SGEX","OPERATING MIC":"SGEX","NAME-INSTITUTION DESCRIPTION":"SHANGHAI GOLD EXCHANGE","ACRONYM":"SGE","CITY":"SHANGHAI"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"XCFE","OPERATING MIC":"XCFE","NAME-INSTITUTION DESCRIPTION":"CHINA FOREIGN EXCHANGE TRADE SYSTEM","ACRONYM":"CFETS","CITY":"SHANGHAI"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"XDCE","OPERATING MIC":"XDCE","NAME-INSTITUTION DESCRIPTION":"DALIAN COMMODITY EXCHANGE","ACRONYM":"DCE","CITY":"DALIAN"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"XSGE","OPERATING MIC":"XSGE","NAME-INSTITUTION DESCRIPTION":"SHANGHAI FUTURES EXCHANGE","ACRONYM":"SHFE","CITY":"SHANGHAI"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"XINE","OPERATING MIC":"XSGE","NAME-INSTITUTION DESCRIPTION":"SHANGHAI INTERNATIONAL ENERGY EXCHANGE","ACRONYM":"INE","CITY":"SHANGHAI"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"XSHE","OPERATING MIC":"XSHE","NAME-INSTITUTION DESCRIPTION":"SHENZHEN STOCK EXCHANGE","ACRONYM":"","CITY":"SHENZHEN"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"XSEC","OPERATING MIC":"XSHE","NAME-INSTITUTION DESCRIPTION":"SHENZHEN STOCK EXCHANGE - SHENZHEN - HONG KONG STOCK CONNECT","ACRONYM":"","CITY":"SHENZHEN"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"XSHG","OPERATING MIC":"XSHG","NAME-INSTITUTION DESCRIPTION":"SHANGHAI STOCK EXCHANGE","ACRONYM":"SSE","CITY":"SHANGHAI"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"XSSC","OPERATING MIC":"XSHG","NAME-INSTITUTION DESCRIPTION":"SHANGHAI STOCK EXCHANGE - SHANGHAI - HONG KONG STOCK CONNECT","ACRONYM":"SSE","CITY":"SHANGHAI"},
{"COUNTRY":"CHINA","ISO_COUNTRY_CODE":"CN","MIC":"XZCE","OPERATING MIC":"XZCE","NAME-INSTITUTION DESCRIPTION":"ZHENGZHOU COMMODITY EXCHANGE","ACRONYM":"ZCE","CITY":"ZHENGZHOU"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"DASI","OPERATING MIC":"DASI","NAME-INSTITUTION DESCRIPTION":"DANSKE BANK A/S - SYSTEMATIC INTERNALISER","ACRONYM":"","CITY":"COPENHAGEN"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"DKTC","OPERATING MIC":"DKTC","NAME-INSTITUTION DESCRIPTION":"DANSK OTC","ACRONYM":"","CITY":"HORSENS"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"GXGR","OPERATING MIC":"GXGR","NAME-INSTITUTION DESCRIPTION":"GXG MARKETS A/S","ACRONYM":"DANSK AMP","CITY":"HORSENS"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"GXGF","OPERATING MIC":"GXGR","NAME-INSTITUTION DESCRIPTION":"GXG MTF FIRST QUOTE","ACRONYM":"","CITY":"HORSENS"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"GXGM","OPERATING MIC":"GXGR","NAME-INSTITUTION DESCRIPTION":"GXG MTF","ACRONYM":"","CITY":"HORSENS"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"NPGA","OPERATING MIC":"NPGA","NAME-INSTITUTION DESCRIPTION":"GASPOINT NORDIC A/S","ACRONYM":"GPN","CITY":"COPENHAGEN"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"XCSE","OPERATING MIC":"XCSE","NAME-INSTITUTION DESCRIPTION":"NASDAQ COPENHAGEN A/S","ACRONYM":"","CITY":"COPENHAGEN"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"DCSE","OPERATING MIC":"XCSE","NAME-INSTITUTION DESCRIPTION":"NASDAQ COPENHAGEN A/S - NORDIC@MID","ACRONYM":"","CITY":"COPENHAGEN"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"FNDK","OPERATING MIC":"XCSE","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH DENMARK","ACRONYM":"","CITY":"COPENHAGEN"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"DNDK","OPERATING MIC":"XCSE","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH DENMARK - NORDIC@MID","ACRONYM":"","CITY":"COPENHAGEN"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"MCSE","OPERATING MIC":"XCSE","NAME-INSTITUTION DESCRIPTION":"NASDAQ COPENHAGEN A/S – AUCTION ON DEMAND","ACRONYM":"","CITY":"COPENHAGEN"},
{"COUNTRY":"DENMARK","ISO_COUNTRY_CODE":"DK","MIC":"MNDK","OPERATING MIC":"XCSE","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH DENMARK – AUCTION ON DEMAND","ACRONYM":"","CITY":"COPENHAGEN"},
{"COUNTRY":"FINLAND","ISO_COUNTRY_CODE":"FI","MIC":"FGEX","OPERATING MIC":"FGEX","NAME-INSTITUTION DESCRIPTION":"KAASUPORSSI - FINNISH GAS EXCHANGE","ACRONYM":"","CITY":"ESPOO"},
{"COUNTRY":"FINLAND","ISO_COUNTRY_CODE":"FI","MIC":"XHEL","OPERATING MIC":"XHEL","NAME-INSTITUTION DESCRIPTION":"NASDAQ HELSINKI LTD","ACRONYM":"","CITY":"HELSINKI"},
{"COUNTRY":"FINLAND","ISO_COUNTRY_CODE":"FI","MIC":"FNFI","OPERATING MIC":"XHEL","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH FINLAND","ACRONYM":"","CITY":"HELSINKI"},
{"COUNTRY":"FINLAND","ISO_COUNTRY_CODE":"FI","MIC":"DHEL","OPERATING MIC":"XHEL","NAME-INSTITUTION DESCRIPTION":"NASDAQ HELSINKI LTD - NORDIC@MID","ACRONYM":"","CITY":"HELSINKI"},
{"COUNTRY":"FINLAND","ISO_COUNTRY_CODE":"FI","MIC":"DNFI","OPERATING MIC":"XHEL","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH FINLAND - NORDIC@MID","ACRONYM":"","CITY":"HELSINKI"},
{"COUNTRY":"FINLAND","ISO_COUNTRY_CODE":"FI","MIC":"MHEL","OPERATING MIC":"XHEL","NAME-INSTITUTION DESCRIPTION":"NASDAQ HELSINKI LTD – AUCTION ON DEMAND","ACRONYM":"","CITY":"HELSINKI"},
{"COUNTRY":"FINLAND","ISO_COUNTRY_CODE":"FI","MIC":"MNFI","OPERATING MIC":"XHEL","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH FINLAND – AUCTION ON DEMAND","ACRONYM":"","CITY":"HELSINKI"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"COAL","OPERATING MIC":"COAL","NAME-INSTITUTION DESCRIPTION":"LA COTE ALPHA","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"EPEX","OPERATING MIC":"EPEX","NAME-INSTITUTION DESCRIPTION":"EPEX SPOT SE","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"FMTS","OPERATING MIC":"FMTS","NAME-INSTITUTION DESCRIPTION":"MTS FRANCE SAS","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"GMTF","OPERATING MIC":"GMTF","NAME-INSTITUTION DESCRIPTION":"GALAXY","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"LCHC","OPERATING MIC":"LCHC","NAME-INSTITUTION DESCRIPTION":"LCH.CLEARNET","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"XAFR","OPERATING MIC":"XAFR","NAME-INSTITUTION DESCRIPTION":"ALTERNATIVA FRANCE","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"XBLN","OPERATING MIC":"XBLN","NAME-INSTITUTION DESCRIPTION":"BLUENEXT","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"XPAR","OPERATING MIC":"XPAR","NAME-INSTITUTION DESCRIPTION":"EURONEXT - EURONEXT PARIS","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"ALXP","OPERATING MIC":"XPAR","NAME-INSTITUTION DESCRIPTION":"EURONEXT - ALTERNEXT PARIS","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"MTCH","OPERATING MIC":"XPAR","NAME-INSTITUTION DESCRIPTION":"BONDMATCH","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"XMAT","OPERATING MIC":"XPAR","NAME-INSTITUTION DESCRIPTION":"EURONEXT PARIS MATIF","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"XMLI","OPERATING MIC":"XPAR","NAME-INSTITUTION DESCRIPTION":"EURONEXT - MARCHE LIBRE PARIS","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"XMON","OPERATING MIC":"XPAR","NAME-INSTITUTION DESCRIPTION":"EURONEXT PARIS MONEP","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"XSPM","OPERATING MIC":"XPAR","NAME-INSTITUTION DESCRIPTION":"EURONEXT STRUCTURED PRODUCTS MTF","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"XPOW","OPERATING MIC":"XPOW","NAME-INSTITUTION DESCRIPTION":"POWERNEXT","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"XPSF","OPERATING MIC":"XPOW","NAME-INSTITUTION DESCRIPTION":"POWERNEXT - GAS SPOT AND FUTURES","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"FRANCE","ISO_COUNTRY_CODE":"FR","MIC":"XPOT","OPERATING MIC":"XPOW","NAME-INSTITUTION DESCRIPTION":"POWERNEXT - OTF","ACRONYM":"","CITY":"PARIS"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"360T","OPERATING MIC":"360T","NAME-INSTITUTION DESCRIPTION":"360T","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"CATS","OPERATING MIC":"CATS","NAME-INSTITUTION DESCRIPTION":"CATS","ACRONYM":"CATS","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"DBOX","OPERATING MIC":"DBOX","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BANK OFF EXCHANGE TRADING","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"AUTO","OPERATING MIC":"DBOX","NAME-INSTITUTION DESCRIPTION":"AUTOBAHN FX","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"ECAG","OPERATING MIC":"ECAG","NAME-INSTITUTION DESCRIPTION":"EUREX CLEARING AG","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"FICX","OPERATING MIC":"FICX","NAME-INSTITUTION DESCRIPTION":"FINANCIAL INFORMATION CONTRIBUTORS EXCHANGE","ACRONYM":"FICONEX","CITY":"RODGAU"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"TGAT","OPERATING MIC":"TGAT","NAME-INSTITUTION DESCRIPTION":"TRADEGATE EXCHANGE","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XGAT","OPERATING MIC":"TGAT","NAME-INSTITUTION DESCRIPTION":"TRADEGATE EXCHANGE - FREIVERKEHR","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XGRM","OPERATING MIC":"TGAT","NAME-INSTITUTION DESCRIPTION":"TRADEGATE EXCHANGE - REGULIERTER MARKT","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XBER","OPERATING MIC":"XBER","NAME-INSTITUTION DESCRIPTION":"BOERSE BERLIN","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"BERA","OPERATING MIC":"XBER","NAME-INSTITUTION DESCRIPTION":"BOERSE BERLIN - REGULIERTER MARKT","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"BERB","OPERATING MIC":"XBER","NAME-INSTITUTION DESCRIPTION":"BOERSE BERLIN - FREIVERKEHR","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"BERC","OPERATING MIC":"XBER","NAME-INSTITUTION DESCRIPTION":"BOERSE BERLIN - BERLIN SECOND REGULATED MARKET","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"EQTA","OPERATING MIC":"XBER","NAME-INSTITUTION DESCRIPTION":"BOERSE BERLIN EQUIDUCT TRADING - REGULIERTER MARKT","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"EQTB","OPERATING MIC":"XBER","NAME-INSTITUTION DESCRIPTION":"BOERSE BERLIN EQUIDUCT TRADING - BERLIN SECOND REGULATED MARKET","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"EQTC","OPERATING MIC":"XBER","NAME-INSTITUTION DESCRIPTION":"BOERSE BERLIN EQUIDUCT TRADING - FREIVERKEHR","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"EQTD","OPERATING MIC":"XBER","NAME-INSTITUTION DESCRIPTION":"BOERSE BERLIN EQUIDUCT TRADING - OTC","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XEQT","OPERATING MIC":"XBER","NAME-INSTITUTION DESCRIPTION":"BOERSE BERLIN EQUIDUCT TRADING","ACRONYM":"","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"ZOBX","OPERATING MIC":"XBER","NAME-INSTITUTION DESCRIPTION":"ZOBEX","ACRONYM":"ZOBEX","CITY":"BERLIN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XDUS","OPERATING MIC":"XDUS","NAME-INSTITUTION DESCRIPTION":"BOERSE DUESSELDORF","ACRONYM":"","CITY":"DUESSELDORF"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"DUSA","OPERATING MIC":"XDUS","NAME-INSTITUTION DESCRIPTION":"BOERSE DUESSELDORF - REGULIERTER MARKT","ACRONYM":"","CITY":"DUESSELDORF"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"DUSB","OPERATING MIC":"XDUS","NAME-INSTITUTION DESCRIPTION":"BOERSE DUESSELDORF - FREIVERKEHR","ACRONYM":"","CITY":"DUESSELDORF"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"DUSC","OPERATING MIC":"XDUS","NAME-INSTITUTION DESCRIPTION":"BOERSE DUESSELDORF - QUOTRIX - REGULIERTER MARKT","ACRONYM":"","CITY":"DUESSELDORF"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"DUSD","OPERATING MIC":"XDUS","NAME-INSTITUTION DESCRIPTION":"BOERSE DUESSELDORF - QUOTRIX MTF","ACRONYM":"","CITY":"DUESSELDORF"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XQTX","OPERATING MIC":"XDUS","NAME-INSTITUTION DESCRIPTION":"BOERSE DUESSELDORF - QUOTRIX","ACRONYM":"","CITY":"DUESSELDORF"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XECB","OPERATING MIC":"XECB","NAME-INSTITUTION DESCRIPTION":"ECB EXCHANGE RATES","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XECC","OPERATING MIC":"XECC","NAME-INSTITUTION DESCRIPTION":"EUROPEAN COMMODITY CLEARING AG","ACRONYM":"ECC","CITY":"LEIPZIG"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XEEE","OPERATING MIC":"XEEE","NAME-INSTITUTION DESCRIPTION":"EUROPEAN ENERGY EXCHANGE","ACRONYM":"EEX","CITY":"LEIPZIG"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XEEO","OPERATING MIC":"XEEE","NAME-INSTITUTION DESCRIPTION":"EUROPEAN ENERGY EXCHANGE - NON-MTF MARKET","ACRONYM":"EEX","CITY":"LEIPZIG"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XEER","OPERATING MIC":"XEEE","NAME-INSTITUTION DESCRIPTION":"EUROPEAN ENERGY EXCHANGE - REGULATED MARKET","ACRONYM":"EEX","CITY":"LEIPZIG"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XETR","OPERATING MIC":"XETR","NAME-INSTITUTION DESCRIPTION":"XETRA","ACRONYM":"XETRA","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XEUB","OPERATING MIC":"XETR","NAME-INSTITUTION DESCRIPTION":"EUREX BONDS","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XETA","OPERATING MIC":"XETR","NAME-INSTITUTION DESCRIPTION":"XETRA - REGULIERTER MARKT","ACRONYM":"XETRA","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XETB","OPERATING MIC":"XETR","NAME-INSTITUTION DESCRIPTION":"XETRA - FREIVERKEHR","ACRONYM":"XETRA","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XEUP","OPERATING MIC":"XEUP","NAME-INSTITUTION DESCRIPTION":"EUREX REPO GMBH","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XEUM","OPERATING MIC":"XEUP","NAME-INSTITUTION DESCRIPTION":"EUREX REPO SECLEND MARKET","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XERE","OPERATING MIC":"XEUP","NAME-INSTITUTION DESCRIPTION":"EUREX REPO - FUNDING AND FINANCING PRODUCTS","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XERT","OPERATING MIC":"XEUP","NAME-INSTITUTION DESCRIPTION":"EUREX REPO - TRIPARTY","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XEUR","OPERATING MIC":"XEUR","NAME-INSTITUTION DESCRIPTION":"EUREX DEUTSCHLAND","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XFRA","OPERATING MIC":"XFRA","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BOERSE AG","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"FRAA","OPERATING MIC":"XFRA","NAME-INSTITUTION DESCRIPTION":"BOERSE FRANKFURT - REGULIERTER MARKT","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"FRAB","OPERATING MIC":"XFRA","NAME-INSTITUTION DESCRIPTION":"BOERSE FRANKFURT - FREIVERKEHR","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XDBC","OPERATING MIC":"XFRA","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BOERSE AG - CUSTOMIZED INDICES","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XDBV","OPERATING MIC":"XFRA","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BOERSE AG - VOLATILITY INDICES","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XDBX","OPERATING MIC":"XFRA","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BOERSE AG - INDICES","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XHAM","OPERATING MIC":"XHAM","NAME-INSTITUTION DESCRIPTION":"HANSEATISCHE WERTPAPIERBOERSE HAMBURG","ACRONYM":"","CITY":"HAMBURG"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"HAMA","OPERATING MIC":"XHAM","NAME-INSTITUTION DESCRIPTION":"BOERSE HAMBURG - REGULIERTER MARKT","ACRONYM":"","CITY":"HAMBURG"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"HAMB","OPERATING MIC":"XHAM","NAME-INSTITUTION DESCRIPTION":"BOERSE HAMBURG - FREIVERKEHR","ACRONYM":"","CITY":"HAMBURG"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"HAML","OPERATING MIC":"XHAM","NAME-INSTITUTION DESCRIPTION":"BOERSE HAMBURG - LANG AND SCHWARZ EXCHANGE","ACRONYM":"","CITY":"HAMBURG"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"HAMM","OPERATING MIC":"XHAM","NAME-INSTITUTION DESCRIPTION":"BOERSE HAMBURG - LANG AND SCHWARZ EXCHANGE - REGULIERTER MARKT","ACRONYM":"","CITY":"HAMBURG"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"HAMN","OPERATING MIC":"XHAM","NAME-INSTITUTION DESCRIPTION":"BOERSE HAMBURG - LANG AND SCHWARZ EXCHANGE - FREIVERKEHR","ACRONYM":"","CITY":"HAMBURG"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XHAN","OPERATING MIC":"XHAN","NAME-INSTITUTION DESCRIPTION":"NIEDERSAECHSISCHE BOERSE ZU HANNOVER","ACRONYM":"","CITY":"HANNOVER"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"HANA","OPERATING MIC":"XHAN","NAME-INSTITUTION DESCRIPTION":"BOERSE HANNOVER - REGULIERTER MARKT","ACRONYM":"","CITY":"HANNOVER"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"HANB","OPERATING MIC":"XHAN","NAME-INSTITUTION DESCRIPTION":"BOERSE HANNOVER - FREIVERKEHR","ACRONYM":"","CITY":"HANNOVER"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XINV","OPERATING MIC":"XINV","NAME-INSTITUTION DESCRIPTION":"INVESTRO","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XMUN","OPERATING MIC":"XMUN","NAME-INSTITUTION DESCRIPTION":"BOERSE MUENCHEN","ACRONYM":"","CITY":"MUENCHEN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"MUNA","OPERATING MIC":"XMUN","NAME-INSTITUTION DESCRIPTION":"BOERSE MUENCHEN - REGULIERTER MARKT","ACRONYM":"","CITY":"MUENCHEN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"MUNB","OPERATING MIC":"XMUN","NAME-INSTITUTION DESCRIPTION":"BOERSE MUENCHEN - FREIVERKEHR","ACRONYM":"","CITY":"MUENCHEN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"MUNC","OPERATING MIC":"XMUN","NAME-INSTITUTION DESCRIPTION":"BOERSE MUENCHEN - MARKET MAKER MUNICH - REGULIERTER MARKT","ACRONYM":"","CITY":"MUENCHEN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"MUND","OPERATING MIC":"XMUN","NAME-INSTITUTION DESCRIPTION":"BOERSE MUENCHEN - MARKET MAKER MUNICH - FREIVERKEHR MARKT","ACRONYM":"","CITY":"MUENCHEN"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XSCO","OPERATING MIC":"XSCO","NAME-INSTITUTION DESCRIPTION":"BOERSE FRANKFURT WARRANTS TECHNICAL","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XSC1","OPERATING MIC":"XSCO","NAME-INSTITUTION DESCRIPTION":"BOERSE FRANKFURT WARRANTS TECHNICAL 1","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XSC2","OPERATING MIC":"XSCO","NAME-INSTITUTION DESCRIPTION":"BOERSE FRANKFURT WARRANTS TECHNICAL 2","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XSC3","OPERATING MIC":"XSCO","NAME-INSTITUTION DESCRIPTION":"BOERSE FRANKFURT WARRANTS TECHNICAL 3","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XSTU","OPERATING MIC":"XSTU","NAME-INSTITUTION DESCRIPTION":"BOERSE STUTTGART","ACRONYM":"","CITY":"STUTTGART"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"EUWX","OPERATING MIC":"XSTU","NAME-INSTITUTION DESCRIPTION":"EUWAX","ACRONYM":"EUWAX","CITY":"STUTTGART"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"STUA","OPERATING MIC":"XSTU","NAME-INSTITUTION DESCRIPTION":"BOERSE STUTTGART - REGULIERTER MARKT","ACRONYM":"","CITY":"STUTTGART"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"STUB","OPERATING MIC":"XSTU","NAME-INSTITUTION DESCRIPTION":"BOERSE STUTTGART - FREIVERKEHR","ACRONYM":"","CITY":"STUTTGART"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XSTF","OPERATING MIC":"XSTU","NAME-INSTITUTION DESCRIPTION":"BOERSE STUTTGART - TECHNICAL PLATFORM 2","ACRONYM":"","CITY":"STUTTGART"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"STUC","OPERATING MIC":"XSTU","NAME-INSTITUTION DESCRIPTION":"BOERSE STUTTGART - REGULIERTER MARKT - TECHNICAL PLATFORM 2","ACRONYM":"","CITY":"STUTTGART"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"STUD","OPERATING MIC":"XSTU","NAME-INSTITUTION DESCRIPTION":"BOERSE STUTTGART - FREIVERKEHR - TECHNICAL PLATFORM 2","ACRONYM":"","CITY":"STUTTGART"},
{"COUNTRY":"GERMANY","ISO_COUNTRY_CODE":"DE","MIC":"XXSC","OPERATING MIC":"XXSC","NAME-INSTITUTION DESCRIPTION":"FRANKFURT CEF SC","ACRONYM":"","CITY":"FRANKFURT"},
{"COUNTRY":"GREECE","ISO_COUNTRY_CODE":"GR","MIC":"ASEX","OPERATING MIC":"ASEX","NAME-INSTITUTION DESCRIPTION":"ATHENS STOCK EXCHANGE","ACRONYM":"ASE","CITY":"ATHENS"},
{"COUNTRY":"GREECE","ISO_COUNTRY_CODE":"GR","MIC":"ENAX","OPERATING MIC":"ASEX","NAME-INSTITUTION DESCRIPTION":"ATHENS EXCHANGE ALTERNATIVE MARKET","ACRONYM":"ENAX","CITY":"ATHENS"},
{"COUNTRY":"GREECE","ISO_COUNTRY_CODE":"GR","MIC":"HOTC","OPERATING MIC":"ASEX","NAME-INSTITUTION DESCRIPTION":"HELLENIC EXCHANGE OTC MARKET","ACRONYM":"","CITY":"ATHENS"},
{"COUNTRY":"GREECE","ISO_COUNTRY_CODE":"GR","MIC":"XADE","OPERATING MIC":"ASEX","NAME-INSTITUTION DESCRIPTION":"ATHENS EXCHANGE S.A. DERIVATIVES MARKET","ACRONYM":"ATHEXD","CITY":"ATHENS"},
{"COUNTRY":"GREECE","ISO_COUNTRY_CODE":"GR","MIC":"XATH","OPERATING MIC":"ASEX","NAME-INSTITUTION DESCRIPTION":"ATHENS EXCHANGE S.A. CASH MARKET","ACRONYM":"ATHEXC","CITY":"ATHENS"},
{"COUNTRY":"GREECE","ISO_COUNTRY_CODE":"GR","MIC":"XIPO","OPERATING MIC":"ASEX","NAME-INSTITUTION DESCRIPTION":"HELEX ELECTRONIC BOOK BUILDING","ACRONYM":"EBB","CITY":"ATHENS"},
{"COUNTRY":"GREECE","ISO_COUNTRY_CODE":"GR","MIC":"HDAT","OPERATING MIC":"HDAT","NAME-INSTITUTION DESCRIPTION":"ELECTRONIC SECONDARY SECURITIES MARKET (HDAT)","ACRONYM":"HDAT","CITY":"ATHENS"},
{"COUNTRY":"GREECE","ISO_COUNTRY_CODE":"GR","MIC":"HEMO","OPERATING MIC":"HEMO","NAME-INSTITUTION DESCRIPTION":"LAGIE - OPERATOR OF THE ENERGY MARKET S.A.","ACRONYM":"LAGIE S.A.","CITY":"PIREAUS"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"CGMH","OPERATING MIC":"CGMH","NAME-INSTITUTION DESCRIPTION":"CITI MATCH","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"CLHK","OPERATING MIC":"CLHK","NAME-INSTITUTION DESCRIPTION":"CLSA HONG KONG - DARK","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"CSHK","OPERATING MIC":"CSHK","NAME-INSTITUTION DESCRIPTION":"CREDIT SUISSE SECURITIES (HONG KONG) LIMITED","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"CFHK","OPERATING MIC":"CSHK","NAME-INSTITUTION DESCRIPTION":"CROSSFINDER HONG KONG","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"DBHK","OPERATING MIC":"DBHK","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BANK HONG KONG ATS","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"EOTC","OPERATING MIC":"EOTC","NAME-INSTITUTION DESCRIPTION":"E-OTC","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"GSXH","OPERATING MIC":"GSXH","NAME-INSTITUTION DESCRIPTION":"GSX HONG KONG","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"HSXA","OPERATING MIC":"HSXA","NAME-INSTITUTION DESCRIPTION":"HSBC-X HONG KONG","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"JPMI","OPERATING MIC":"JPMI","NAME-INSTITUTION DESCRIPTION":"JP MORGAN - JPMI MARKET","ACRONYM":"JPMI","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"MAQH","OPERATING MIC":"MAQH","NAME-INSTITUTION DESCRIPTION":"MACQUARIE INTERNAL MARKETS (HONG KONG)","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"SIGH","OPERATING MIC":"SIGH","NAME-INSTITUTION DESCRIPTION":"SIGMA X HONG KONG","ACRONYM":"GSET","CITY":"HONG-KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"TOCP","OPERATING MIC":"TOCP","NAME-INSTITUTION DESCRIPTION":"TORA CROSSPOINT","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"UBSX","OPERATING MIC":"UBSX","NAME-INSTITUTION DESCRIPTION":"UBS CROSS","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"XCGS","OPERATING MIC":"XCGS","NAME-INSTITUTION DESCRIPTION":"CHINESE GOLD & SILVER EXCHANGE SOCIETY","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"XHKF","OPERATING MIC":"XHKF","NAME-INSTITUTION DESCRIPTION":"HONG KONG FUTURES EXCHANGE LTD.","ACRONYM":"HKFE","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"XHKG","OPERATING MIC":"XHKG","NAME-INSTITUTION DESCRIPTION":"HONG KONG EXCHANGES AND CLEARING LTD","ACRONYM":"HKEX","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"XGEM","OPERATING MIC":"XHKG","NAME-INSTITUTION DESCRIPTION":"HONG KONG GROWTH ENTERPRISES MARKET","ACRONYM":"HK GEM","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"SHSC","OPERATING MIC":"XHKG","NAME-INSTITUTION DESCRIPTION":"STOCK EXCHANGE OF HONG KONG LIMITED - SHANGHAI - HONG KONG STOCK CONNECT","ACRONYM":"SEHK","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"SZSC","OPERATING MIC":"XHKG","NAME-INSTITUTION DESCRIPTION":"STOCK EXCHANGE OF HONG KONG LIMITED - SHENZHEN - HONG KONG STOCK CONNECT","ACRONYM":"SEHK","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"XIHK","OPERATING MIC":"XIHK","NAME-INSTITUTION DESCRIPTION":"INSTINET PACIFIC LTD","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"HONG KONG","ISO_COUNTRY_CODE":"HK","MIC":"XPST","OPERATING MIC":"XPST","NAME-INSTITUTION DESCRIPTION":"POSIT - ASIA PACIFIC","ACRONYM":"","CITY":"HONG KONG"},
{"COUNTRY":"ICELAND","ISO_COUNTRY_CODE":"IS","MIC":"XICE","OPERATING MIC":"XICE","NAME-INSTITUTION DESCRIPTION":"NASDAQ ICELAND HF.","ACRONYM":"ICEX","CITY":"REYKJAVIK"},
{"COUNTRY":"ICELAND","ISO_COUNTRY_CODE":"IS","MIC":"DICE","OPERATING MIC":"XICE","NAME-INSTITUTION DESCRIPTION":"NASDAQ ICELAND HF. - NORDIC@MID","ACRONYM":"ICEX","CITY":"REYKJAVIK"},
{"COUNTRY":"ICELAND","ISO_COUNTRY_CODE":"IS","MIC":"FNIS","OPERATING MIC":"XICE","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH ICELAND","ACRONYM":"","CITY":"REYKJAVIK"},
{"COUNTRY":"ICELAND","ISO_COUNTRY_CODE":"IS","MIC":"DNIS","OPERATING MIC":"XICE","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH ICELAND - NORDIC@MID","ACRONYM":"","CITY":"REYKJAVIK"},
{"COUNTRY":"ICELAND","ISO_COUNTRY_CODE":"IS","MIC":"MICE","OPERATING MIC":"XICE","NAME-INSTITUTION DESCRIPTION":"NASDAQ ICELAND HF. – AUCTION ON DEMAND","ACRONYM":"","CITY":"REYKJAVIK"},
{"COUNTRY":"ICELAND","ISO_COUNTRY_CODE":"IS","MIC":"MNIS","OPERATING MIC":"XICE","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH ICELAND – AUCTION ON DEMAND","ACRONYM":"","CITY":"REYKJAVIK"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"ACEX","OPERATING MIC":"ACEX","NAME-INSTITUTION DESCRIPTION":"ACE DERIVATIVES & COMMODITY EXCHANGE LTD","ACRONYM":"","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"CDSL","OPERATING MIC":"CDSL","NAME-INSTITUTION DESCRIPTION":"CLEARCORP DEALING SYSTEMS (INDIA) LTD.","ACRONYM":"CLEARCORP","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"FXCL","OPERATING MIC":"CDSL","NAME-INSTITUTION DESCRIPTION":"CLEARCORP DEALING SYSTEMS INDIA LIMITED – FX-CLEAR","ACRONYM":"FXCLR","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"FXSW","OPERATING MIC":"CDSL","NAME-INSTITUTION DESCRIPTION":"CLEARCORP DEALING SYSTEMS INDIA LIMITED – FX-SWAP","ACRONYM":"FXSWAP","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"ASTR","OPERATING MIC":"CDSL","NAME-INSTITUTION DESCRIPTION":"CLEARCORP DEALING SYSTEMS INDIA LIMITED – ASTROID","ACRONYM":"ASTROID","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"ICXL","OPERATING MIC":"ICXL","NAME-INSTITUTION DESCRIPTION":"INDIAN COMMODITY EXCHANGE LTD.","ACRONYM":"","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"ISEX","OPERATING MIC":"ISEX","NAME-INSTITUTION DESCRIPTION":"INTER-CONNECTED STOCK EXCHANGE OF INDIA LTD","ACRONYM":"ISE","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"MCXX","OPERATING MIC":"MCXX","NAME-INSTITUTION DESCRIPTION":"MCX STOCK EXCHANGE LTD","ACRONYM":"","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"NBOT","OPERATING MIC":"NBOT","NAME-INSTITUTION DESCRIPTION":"NATIONAL BOARD OF TRADE LIMITED","ACRONYM":"","CITY":"INDORE MADHYA PRADESH"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"NMCE","OPERATING MIC":"NMCE","NAME-INSTITUTION DESCRIPTION":"NATIONAL MULTI-COMMODITY EXCHANGE OF INDIA","ACRONYM":"","CITY":"AHMEDABAD"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"OTCX","OPERATING MIC":"OTCX","NAME-INSTITUTION DESCRIPTION":"OTC EXCHANGE OF INDIA","ACRONYM":"OTCEI","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"PXIL","OPERATING MIC":"PXIL","NAME-INSTITUTION DESCRIPTION":"POWER EXCHANGE INDIA LTD.","ACRONYM":"","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"XBAN","OPERATING MIC":"XBAN","NAME-INSTITUTION DESCRIPTION":"BANGALORE STOCK EXCHANGE LTD","ACRONYM":"","CITY":"BANGALORE"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"XBOM","OPERATING MIC":"XBOM","NAME-INSTITUTION DESCRIPTION":"BSE LTD","ACRONYM":"MSE","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"BSME","OPERATING MIC":"XBOM","NAME-INSTITUTION DESCRIPTION":"BSE SME","ACRONYM":"BSE SME","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"XCAL","OPERATING MIC":"XCAL","NAME-INSTITUTION DESCRIPTION":"CALCUTTA STOCK EXCHANGE","ACRONYM":"","CITY":"CALCUTTA"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"XDES","OPERATING MIC":"XDES","NAME-INSTITUTION DESCRIPTION":"DELHI STOCK EXCHANGE","ACRONYM":"","CITY":"DELHI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"XIMC","OPERATING MIC":"XIMC","NAME-INSTITUTION DESCRIPTION":"MULTI COMMODITY EXCHANGE OF INDIA LTD.","ACRONYM":"MCX","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"XMDS","OPERATING MIC":"XMDS","NAME-INSTITUTION DESCRIPTION":"MADRAS STOCK EXCHANGE","ACRONYM":"","CITY":"MADRAS"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"XNCD","OPERATING MIC":"XNCD","NAME-INSTITUTION DESCRIPTION":"NATIONAL COMMODITY & DERIVATIVES EXCHANGE LTD","ACRONYM":"NCDEX","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"XNSE","OPERATING MIC":"XNSE","NAME-INSTITUTION DESCRIPTION":"NATIONAL STOCK EXCHANGE OF INDIA","ACRONYM":"NSE","CITY":"MUMBAI"},
{"COUNTRY":"INDIA","ISO_COUNTRY_CODE":"IN","MIC":"XUSE","OPERATING MIC":"XUSE","NAME-INSTITUTION DESCRIPTION":"UNITED STOCK EXCHANGE","ACRONYM":"","CITY":"MUMBAI"},
{"COUNTRY":"IRELAND","ISO_COUNTRY_CODE":"IE","MIC":"AREX","OPERATING MIC":"AREX","NAME-INSTITUTION DESCRIPTION":"AREX - AUTOMATED RECEIVABLES EXCHANGE","ACRONYM":"AREX","CITY":"DUBLIN"},
{"COUNTRY":"IRELAND","ISO_COUNTRY_CODE":"IE","MIC":"XPOS","OPERATING MIC":"ITGI","NAME-INSTITUTION DESCRIPTION":"POSIT","ACRONYM":"POSIT","CITY":"DUBLIN"},
{"COUNTRY":"IRELAND","ISO_COUNTRY_CODE":"IE","MIC":"XCDE","OPERATING MIC":"XCDE","NAME-INSTITUTION DESCRIPTION":"BAXTER FINANCIAL SERVICES","ACRONYM":"","CITY":"DUBLIN"},
{"COUNTRY":"IRELAND","ISO_COUNTRY_CODE":"IE","MIC":"XDUB","OPERATING MIC":"XDUB","NAME-INSTITUTION DESCRIPTION":"IRISH STOCK EXCHANGE - ALL MARKET","ACRONYM":"ISE","CITY":"DUBLIN"},
{"COUNTRY":"IRELAND","ISO_COUNTRY_CODE":"IE","MIC":"XEYE","OPERATING MIC":"XDUB","NAME-INSTITUTION DESCRIPTION":"IRISH STOCK EXCHANGE - GEM - XETRA","ACRONYM":"ISE XETRA","CITY":"DUBLIN"},
{"COUNTRY":"IRELAND","ISO_COUNTRY_CODE":"IE","MIC":"XESM","OPERATING MIC":"XDUB","NAME-INSTITUTION DESCRIPTION":"DUBLIN - IRISH STOCK EXCHANGE - ENTREPRISE SECURITIES MARKET (ESM)- ISE XETRA","ACRONYM":"ISE","CITY":"DUBLIN"},
{"COUNTRY":"IRELAND","ISO_COUNTRY_CODE":"IE","MIC":"XMSM","OPERATING MIC":"XDUB","NAME-INSTITUTION DESCRIPTION":"DUBLIN - IRISH STOCK EXCHANGE - MAIN SECURITIES MARKET (MSM)- ISE XETRA","ACRONYM":"ISE","CITY":"DUBLIN"},
{"COUNTRY":"IRELAND","ISO_COUNTRY_CODE":"IE","MIC":"XATL","OPERATING MIC":"XDUB","NAME-INSTITUTION DESCRIPTION":"ATLANTIC SECURITIES MARKET","ACRONYM":"ISE","CITY":"DUBLIN"},
{"COUNTRY":"IRELAND","ISO_COUNTRY_CODE":"IE","MIC":"XEBI","OPERATING MIC":"XEBI","NAME-INSTITUTION DESCRIPTION":"ENERGY BROKING IRELAND GAS TRADING PLATFORM","ACRONYM":"EBI","CITY":"DUBLIN"},
{"COUNTRY":"ISRAEL","ISO_COUNTRY_CODE":"IL","MIC":"XTAE","OPERATING MIC":"XTAE","NAME-INSTITUTION DESCRIPTION":"TEL AVIV STOCK EXCHANGE","ACRONYM":"TASE","CITY":"TEL AVIV"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"CGIT","OPERATING MIC":"CGIT","NAME-INSTITUTION DESCRIPTION":"CASSA DI COMPENSAZIONE E GARANZIA SPA","ACRONYM":"CC&G","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"CGQT","OPERATING MIC":"CGIT","NAME-INSTITUTION DESCRIPTION":"CASSA DI COMPENSAZIONE E GARANZIA SPA - EQUITY CCP SERVICE","ACRONYM":"CCGEQUITY","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"CGDB","OPERATING MIC":"CGIT","NAME-INSTITUTION DESCRIPTION":"CASSA DI COMPENSAZIONE E GARANZIA SPA - BONDS CCP SERVICE","ACRONYM":"CCGBONDS","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"CGEB","OPERATING MIC":"CGIT","NAME-INSTITUTION DESCRIPTION":"CASSA DI COMPENSAZIONE E GARANZIA SPA - EURO BONDS CCP SERVICE","ACRONYM":"CCGEUROBONDS","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"CGTR","OPERATING MIC":"CGIT","NAME-INSTITUTION DESCRIPTION":"CASSA DI COMPENSAZIONE E GARANZIA SPA - TRIPARTY REPO CCP SERVICE","ACRONYM":"CCGTRIPARTY","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"CGQD","OPERATING MIC":"CGIT","NAME-INSTITUTION DESCRIPTION":"CASSA DI COMPENSAZIONE E GARANZIA SPA - CCP EQUITY DERIVATIVES","ACRONYM":"CCGEQUITYDER","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"CGND","OPERATING MIC":"CGIT","NAME-INSTITUTION DESCRIPTION":"CASSA DI COMPENSAZIONE E GARANZIA SPA - CCP ENERGY DERIVATIVES","ACRONYM":"CCGENERGYDER","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"CGGD","OPERATING MIC":"CGIT","NAME-INSTITUTION DESCRIPTION":"CASSA DI COMPENSAZIONE E GARANZIA SPA - CCP AGRICULTURAL COMMODITY DERIVATIVES","ACRONYM":"CCGAGRIDER","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"CGCM","OPERATING MIC":"CGIT","NAME-INSTITUTION DESCRIPTION":"CASSA DI COMPENSAZIONE E GARANZIA SPA - COLLATERALIZED MONEY MARKET GUARANTEE SERVICE","ACRONYM":"NEWMIC","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"EMID","OPERATING MIC":"EMID","NAME-INSTITUTION DESCRIPTION":"E-MID","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"EMDR","OPERATING MIC":"EMID","NAME-INSTITUTION DESCRIPTION":"E-MID - E-MIDER MARKET","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"EMIR","OPERATING MIC":"EMID","NAME-INSTITUTION DESCRIPTION":"E-MID REPO","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"EMIB","OPERATING MIC":"EMID","NAME-INSTITUTION DESCRIPTION":"E-MID - BANCA D'ITALIA SHARES TRADING MARKET","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"ETLX","OPERATING MIC":"ETLX","NAME-INSTITUTION DESCRIPTION":"EUROTLX","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"HMTF","OPERATING MIC":"HMTF","NAME-INSTITUTION DESCRIPTION":"HI-MTF","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"HMOD","OPERATING MIC":"HMTF","NAME-INSTITUTION DESCRIPTION":"HI-MTF ORDER DRIVEN","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"HRFQ","OPERATING MIC":"HMTF","NAME-INSTITUTION DESCRIPTION":"HI-MTF RFQ","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"MTSC","OPERATING MIC":"MTSC","NAME-INSTITUTION DESCRIPTION":"MTS ITALIA","ACRONYM":"MTS ITALY","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"BOND","OPERATING MIC":"MTSC","NAME-INSTITUTION DESCRIPTION":"BONDVISION ITALIA","ACRONYM":"","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"MTSM","OPERATING MIC":"MTSC","NAME-INSTITUTION DESCRIPTION":"MTS CORPORATE MARKET","ACRONYM":"MTS ITALY","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"SSOB","OPERATING MIC":"MTSC","NAME-INSTITUTION DESCRIPTION":"BONDVISION ITALIA MTF","ACRONYM":"","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"XGME","OPERATING MIC":"XGME","NAME-INSTITUTION DESCRIPTION":"GESTORE MERCATO ELETTRICO - ITALIAN POWER EXCHANGE","ACRONYM":"IPEX/GME","CITY":"ROMA"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"XMIL","OPERATING MIC":"XMIL","NAME-INSTITUTION DESCRIPTION":"BORSA ITALIANA S.P.A.","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"MTAH","OPERATING MIC":"XMIL","NAME-INSTITUTION DESCRIPTION":"BORSA ITALIANA EQUITY MTF","ACRONYM":"BITEQMTF","CITY":"MILAN"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"ETFP","OPERATING MIC":"XMIL","NAME-INSTITUTION DESCRIPTION":"ELECTRONIC OPEN-END FUNDS AND ETC MARKET","ACRONYM":"ETFPLUS","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"MIVX","OPERATING MIC":"XMIL","NAME-INSTITUTION DESCRIPTION":"MARKET FOR INVESTMENT VEHICLES","ACRONYM":"MIV","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"MOTX","OPERATING MIC":"XMIL","NAME-INSTITUTION DESCRIPTION":"ELECTRONIC BOND MARKET","ACRONYM":"MOT","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"MTAA","OPERATING MIC":"XMIL","NAME-INSTITUTION DESCRIPTION":"ELECTRONIC SHARE MARKET","ACRONYM":"MTA","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"SEDX","OPERATING MIC":"XMIL","NAME-INSTITUTION DESCRIPTION":"SECURITISED DERIVATIVES MARKET","ACRONYM":"SEDEX","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"XAIM","OPERATING MIC":"XMIL","NAME-INSTITUTION DESCRIPTION":"AIM ITALIA - MERCATO ALTERNATIVO DEL CAPITALE","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"XDMI","OPERATING MIC":"XMIL","NAME-INSTITUTION DESCRIPTION":"ITALIAN DERIVATIVES MARKET","ACRONYM":"IDEM","CITY":"MILANO"},
{"COUNTRY":"ITALY","ISO_COUNTRY_CODE":"IT","MIC":"XMOT","OPERATING MIC":"XMIL","NAME-INSTITUTION DESCRIPTION":"EXTRAMOT","ACRONYM":"","CITY":"MILANO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"BETP","OPERATING MIC":"BETP","NAME-INSTITUTION DESCRIPTION":"BLOOMBERG TRADEBOOK JAPAN LIMITED","ACRONYM":"","CITY":"CHIYODA-KU"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"CHIJ","OPERATING MIC":"CHIJ","NAME-INSTITUTION DESCRIPTION":"CHI-X JAPAN","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"CHIV","OPERATING MIC":"CHIJ","NAME-INSTITUTION DESCRIPTION":"CHI-X JAPAN VWAP CROSSING","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"CHIS","OPERATING MIC":"CHIJ","NAME-INSTITUTION DESCRIPTION":"CHI-X JAPAN SELECT","ACRONYM":"CHI-SELECT","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"KAIX","OPERATING MIC":"CHIJ","NAME-INSTITUTION DESCRIPTION":"KAI-X","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"CITX","OPERATING MIC":"CITX","NAME-INSTITUTION DESCRIPTION":"CITI MATCH","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"CITD","OPERATING MIC":"CITX","NAME-INSTITUTION DESCRIPTION":"CITI DARK","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"CLJP","OPERATING MIC":"CLJP","NAME-INSTITUTION DESCRIPTION":"CLSA JAPAN - DARK","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"CMET","OPERATING MIC":"CMET","NAME-INSTITUTION DESCRIPTION":"CLEAR MARKETS JAPAN, INC.","ACRONYM":"CM ETP","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"CSJP","OPERATING MIC":"CSJP","NAME-INSTITUTION DESCRIPTION":"CREDIT SUISSE EQUITIES (JAPAN) LIMITED","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"CFJP","OPERATING MIC":"CSJP","NAME-INSTITUTION DESCRIPTION":"CROSSFINDER JAPAN","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"DRCT","OPERATING MIC":"DRCT","NAME-INSTITUTION DESCRIPTION":"DAIWA DRECT","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"LXJP","OPERATING MIC":"LXJP","NAME-INSTITUTION DESCRIPTION":"BARCLAYS LX JAPAN","ACRONYM":"LXJP","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"MAQJ","OPERATING MIC":"MAQJ","NAME-INSTITUTION DESCRIPTION":"MACQUARIE INTERNAL MARKETS (JAPAN)","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"MIZX","OPERATING MIC":"MIZX","NAME-INSTITUTION DESCRIPTION":"MIZUHO INTERNAL CROSSING","ACRONYM":"MIX","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"MSMS","OPERATING MIC":"MSMS","NAME-INSTITUTION DESCRIPTION":"MORGAN STANLEY MUFG SECURITIES CO., LTD","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"NMRJ","OPERATING MIC":"NMRJ","NAME-INSTITUTION DESCRIPTION":"NOMURA SECURITIES CO LTD","ACRONYM":"NSC","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"NXJP","OPERATING MIC":"NMRJ","NAME-INSTITUTION DESCRIPTION":"NX JAPAN","ACRONYM":"NXJP","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"NXVW","OPERATING MIC":"NMRJ","NAME-INSTITUTION DESCRIPTION":"NX VWAP","ACRONYM":"NXVWAP","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"NXSE","OPERATING MIC":"NMRJ","NAME-INSTITUTION DESCRIPTION":"NX SELECT JAPAN","ACRONYM":"NXSE","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"SBIJ","OPERATING MIC":"SBIJ","NAME-INSTITUTION DESCRIPTION":"SBI JAPANNEXT-J-MARKET","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XSBI","OPERATING MIC":"SBIJ","NAME-INSTITUTION DESCRIPTION":"SBI JAPANNEXT - X - MARKET","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"SBIV","OPERATING MIC":"SBIJ","NAME-INSTITUTION DESCRIPTION":"SBI JAPANNEXT - VWAP CROSSING","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"SBIU","OPERATING MIC":"SBIJ","NAME-INSTITUTION DESCRIPTION":"SBI JAPANNEXT - U - MARKET","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"SIGJ","OPERATING MIC":"SIGJ","NAME-INSTITUTION DESCRIPTION":"SIGMA X JAPAN","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XFKA","OPERATING MIC":"XFKA","NAME-INSTITUTION DESCRIPTION":"FUKUOKA STOCK EXCHANGE","ACRONYM":"","CITY":"FUKUOKA"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XIJP","OPERATING MIC":"XIJP","NAME-INSTITUTION DESCRIPTION":"INSTINET JAPAN","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"JASR","OPERATING MIC":"XIJP","NAME-INSTITUTION DESCRIPTION":"JAPANCROSSING","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XJPX","OPERATING MIC":"XJPX","NAME-INSTITUTION DESCRIPTION":"JAPAN EXCHANGE GROUP","ACRONYM":"JPX","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XJAS","OPERATING MIC":"XJPX","NAME-INSTITUTION DESCRIPTION":"TOKYO STOCK EXCHANGE JASDAQ","ACRONYM":"JASDAQ","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XOSE","OPERATING MIC":"XJPX","NAME-INSTITUTION DESCRIPTION":"OSAKA EXCHANGE","ACRONYM":"OSE","CITY":"OSAKA"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XOSJ","OPERATING MIC":"XJPX","NAME-INSTITUTION DESCRIPTION":"OSAKA EXCHANGE J-NET","ACRONYM":"J-NET","CITY":"OSAKA"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XTAM","OPERATING MIC":"XJPX","NAME-INSTITUTION DESCRIPTION":"TOKYO STOCK EXCHANGE-TOKYO PRO MARKET","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XTK1","OPERATING MIC":"XJPX","NAME-INSTITUTION DESCRIPTION":"TOKYO STOCK EXCHANGE - TOSTNET-1","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XTK2","OPERATING MIC":"XJPX","NAME-INSTITUTION DESCRIPTION":"TOKYO STOCK EXCHANGE - TOSTNET-2","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XTK3","OPERATING MIC":"XJPX","NAME-INSTITUTION DESCRIPTION":"TOKYO STOCK EXCHANGE - TOSTNET-3","ACRONYM":"","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XTKS","OPERATING MIC":"XJPX","NAME-INSTITUTION DESCRIPTION":"TOKYO STOCK EXCHANGE","ACRONYM":"TSE","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XKAC","OPERATING MIC":"XKAC","NAME-INSTITUTION DESCRIPTION":"OSAKA DOJIMA COMMODITY EXCHANGE","ACRONYM":"ODE","CITY":"OSAKA"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XNGO","OPERATING MIC":"XNGO","NAME-INSTITUTION DESCRIPTION":"NAGOYA STOCK EXCHANGE","ACRONYM":"NSE","CITY":"NAGOYA"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XNKS","OPERATING MIC":"XNKS","NAME-INSTITUTION DESCRIPTION":"CENTRAL JAPAN COMMODITIES EXCHANGE","ACRONYM":"C-COM","CITY":"NAGOYA"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XSAP","OPERATING MIC":"XSAP","NAME-INSTITUTION DESCRIPTION":"SAPPORO SECURITIES EXCHANGE","ACRONYM":"","CITY":"SAPPORO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XTFF","OPERATING MIC":"XTFF","NAME-INSTITUTION DESCRIPTION":"TOKYO FINANCIAL EXCHANGE","ACRONYM":"TFX","CITY":"TOKYO"},
{"COUNTRY":"JAPAN","ISO_COUNTRY_CODE":"JP","MIC":"XTKT","OPERATING MIC":"XTKT","NAME-INSTITUTION DESCRIPTION":"TOKYO COMMODITY EXCHANGE","ACRONYM":"TOCOM","CITY":"TOKYO"},
{"COUNTRY":"LUXEMBOURG","ISO_COUNTRY_CODE":"LU","MIC":"CCLX","OPERATING MIC":"CCLX","NAME-INSTITUTION DESCRIPTION":"FINESTI S.A.","ACRONYM":"CCLUX","CITY":"LUXEMBOURG"},
{"COUNTRY":"LUXEMBOURG","ISO_COUNTRY_CODE":"LU","MIC":"XLUX","OPERATING MIC":"XLUX","NAME-INSTITUTION DESCRIPTION":"LUXEMBOURG STOCK EXCHANGE","ACRONYM":"","CITY":"LUXEMBOURG"},
{"COUNTRY":"LUXEMBOURG","ISO_COUNTRY_CODE":"LU","MIC":"EMTF","OPERATING MIC":"XLUX","NAME-INSTITUTION DESCRIPTION":"EURO MTF","ACRONYM":"","CITY":"LUXEMBOURG"},
{"COUNTRY":"LUXEMBOURG","ISO_COUNTRY_CODE":"LU","MIC":"XVES","OPERATING MIC":"XVES","NAME-INSTITUTION DESCRIPTION":"VESTIMA","ACRONYM":"","CITY":"LUXEMBOURG"},
{"COUNTRY":"MEXICO","ISO_COUNTRY_CODE":"MX","MIC":"CGMX","OPERATING MIC":"CGMX","NAME-INSTITUTION DESCRIPTION":"CITI MEXICO RPI (RETAIL PRICE IMPROVEMENT)","ACRONYM":"","CITY":"MEXICO"},
{"COUNTRY":"MEXICO","ISO_COUNTRY_CODE":"MX","MIC":"XEMD","OPERATING MIC":"XEMD","NAME-INSTITUTION DESCRIPTION":"MERCADO MEXICANO DE DERIVADOS","ACRONYM":"MEXDER","CITY":"MEXICO"},
{"COUNTRY":"MEXICO","ISO_COUNTRY_CODE":"MX","MIC":"XMEX","OPERATING MIC":"XMEX","NAME-INSTITUTION DESCRIPTION":"BOLSA MEXICANA DE VALORES (MEXICAN STOCK EXCHANGE)","ACRONYM":"","CITY":"MEXICO"},
{"COUNTRY":"NIGERIA","ISO_COUNTRY_CODE":"NG","MIC":"NASX","OPERATING MIC":"NASX","NAME-INSTITUTION DESCRIPTION":"NASD OTC MARKET","ACRONYM":"NASDOTC","CITY":"LAGOS"},
{"COUNTRY":"NIGERIA","ISO_COUNTRY_CODE":"NG","MIC":"XNSA","OPERATING MIC":"XNSA","NAME-INSTITUTION DESCRIPTION":"THE NIGERIAN STOCK EXCHANGE","ACRONYM":"NSE","CITY":"LAGOS"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"FISH","OPERATING MIC":"FISH","NAME-INSTITUTION DESCRIPTION":"FISH POOL ASA","ACRONYM":"","CITY":"BERGEN"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"FSHX","OPERATING MIC":"FSHX","NAME-INSTITUTION DESCRIPTION":"FISHEX","ACRONYM":"","CITY":"TROMSO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"ICAS","OPERATING MIC":"ICAS","NAME-INSTITUTION DESCRIPTION":"ICAP ENERGY AS","ACRONYM":"","CITY":"BERGEN"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"NEXO","OPERATING MIC":"NEXO","NAME-INSTITUTION DESCRIPTION":"NOREXECO ASA","ACRONYM":"NOREXECO","CITY":"KONGSVINGER"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"NOPS","OPERATING MIC":"NOPS","NAME-INSTITUTION DESCRIPTION":"NORD POOL SPOT AS","ACRONYM":"","CITY":"TRONDHEIM"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"NORX","OPERATING MIC":"NORX","NAME-INSTITUTION DESCRIPTION":"NASDAQ OMX COMMODITIES","ACRONYM":"","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"NOSC","OPERATING MIC":"NOSC","NAME-INSTITUTION DESCRIPTION":"NOS CLEARING ASA","ACRONYM":"NOS","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"NOTC","OPERATING MIC":"NOTC","NAME-INSTITUTION DESCRIPTION":"NORWEGIAN OVER THE COUNTER MARKET","ACRONYM":"NOTC","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"OSLC","OPERATING MIC":"OSLC","NAME-INSTITUTION DESCRIPTION":"SIX X-CLEAR AG","ACRONYM":"","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"XIMA","OPERATING MIC":"XIMA","NAME-INSTITUTION DESCRIPTION":"INTERNATIONAL MARTIME EXCHANGE","ACRONYM":"IMAREX","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"XOSL","OPERATING MIC":"XOSL","NAME-INSTITUTION DESCRIPTION":"OSLO BORS ASA","ACRONYM":"","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"XOAM","OPERATING MIC":"XOSL","NAME-INSTITUTION DESCRIPTION":"NORDIC ALTERNATIVE BOND MARKET","ACRONYM":"ABM","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"XOAS","OPERATING MIC":"XOSL","NAME-INSTITUTION DESCRIPTION":"OSLO AXESS","ACRONYM":"","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"XOSC","OPERATING MIC":"XOSL","NAME-INSTITUTION DESCRIPTION":"OSLO CONNECT","ACRONYM":"","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"NIBR","OPERATING MIC":"XOSL","NAME-INSTITUTION DESCRIPTION":"NORWEGIAN INTER BANK OFFERED RATE","ACRONYM":"NIBOR","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"XOAD","OPERATING MIC":"XOSL","NAME-INSTITUTION DESCRIPTION":"OSLO AXESS NORTH SEA - DARK POOL","ACRONYM":"","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"XOSD","OPERATING MIC":"XOSL","NAME-INSTITUTION DESCRIPTION":"OSLO BORS NORTH SEA - DARK POOL","ACRONYM":"","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"MERD","OPERATING MIC":"XOSL","NAME-INSTITUTION DESCRIPTION":"MERKUR MARKET - DARK POOL","ACRONYM":"","CITY":"OSLO"},
{"COUNTRY":"NORWAY","ISO_COUNTRY_CODE":"NO","MIC":"MERK","OPERATING MIC":"XOSL","NAME-INSTITUTION DESCRIPTION":"MERKUR MARKET","ACRONYM":"","CITY":"OSLO"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"IENG","OPERATING MIC":"IENG","NAME-INSTITUTION DESCRIPTION":"INFOENGINE OTC","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"KDPW","OPERATING MIC":"KDPW","NAME-INSTITUTION DESCRIPTION":"KDPW_CCP","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"PTPG","OPERATING MIC":"PTPG","NAME-INSTITUTION DESCRIPTION":"POLISH TRADING POINT","ACRONYM":"PTP","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"XWAR","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/EQUITIES/MAIN MARKET","ACRONYM":"WSE","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"PLPX","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/COMMODITIES/POLISH POWER EXCHANGE/ENERGY MARKET","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"BOSP","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/BONDS/CATALYST/BONDSPOT/MTF","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"RPWC","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/BONDS/CATALYST/BONDSPOT/REGULATED MARKET","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"TBSP","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/BONDS/BONDSPOT/TREASURY BOND MARKET","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"XNCO","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/ EQUITIES/NEW CONNECT-MTF","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"WBON","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/ BONDS/CATALYST/MAIN MARKET","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"WDER","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/FINANCIAL DERIVATIVES","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"WETP","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/ ETPS","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"WGAS","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/COMMODITIES/POLISH POWER EXCHANGE/GAS","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"WIND","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/INDICES","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"WMTF","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/BONDS/CATALYST/MTF","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"WBCL","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/BONDS/CATALYST/LISTING","ACRONYM":"","CITY":"WARSAW"},
{"COUNTRY":"POLAND","ISO_COUNTRY_CODE":"PL","MIC":"PLPD","OPERATING MIC":"XWAR","NAME-INSTITUTION DESCRIPTION":"WARSAW STOCK EXCHANGE/COMMODITIES/POLISH POWER EXCHANGE/COMMODITY DERIVATIVES","ACRONYM":"WSE","CITY":"WARSAW"},
{"COUNTRY":"PORTUGAL","ISO_COUNTRY_CODE":"PT","MIC":"OMIC","OPERATING MIC":"OMIC","NAME-INSTITUTION DESCRIPTION":"THE IBERIAN ENERGY CLEARING HOUSE","ACRONYM":"OMICLEAR","CITY":"LISBOA"},
{"COUNTRY":"PORTUGAL","ISO_COUNTRY_CODE":"PT","MIC":"OPEX","OPERATING MIC":"OPEX","NAME-INSTITUTION DESCRIPTION":"PEX-PRIVATE EXCHANGE","ACRONYM":"OPEX","CITY":"LISBOA"},
{"COUNTRY":"PORTUGAL","ISO_COUNTRY_CODE":"PT","MIC":"XLIS","OPERATING MIC":"XLIS","NAME-INSTITUTION DESCRIPTION":"EURONEXT - EURONEXT LISBON","ACRONYM":"","CITY":"LISBOA"},
{"COUNTRY":"PORTUGAL","ISO_COUNTRY_CODE":"PT","MIC":"ALXL","OPERATING MIC":"XLIS","NAME-INSTITUTION DESCRIPTION":"EURONEXT - ALTERNEXT LISBON","ACRONYM":"","CITY":"LISBON"},
{"COUNTRY":"PORTUGAL","ISO_COUNTRY_CODE":"PT","MIC":"ENXL","OPERATING MIC":"XLIS","NAME-INSTITUTION DESCRIPTION":"EURONEXT - EASYNEXT LISBON","ACRONYM":"","CITY":"LISBOA"},
{"COUNTRY":"PORTUGAL","ISO_COUNTRY_CODE":"PT","MIC":"MFOX","OPERATING MIC":"XLIS","NAME-INSTITUTION DESCRIPTION":"EURONEXT - MERCADO DE FUTUROS E OPÇÕES","ACRONYM":"","CITY":"LISBOA"},
{"COUNTRY":"PORTUGAL","ISO_COUNTRY_CODE":"PT","MIC":"OMIP","OPERATING MIC":"XLIS","NAME-INSTITUTION DESCRIPTION":"OPERADOR DE MERCADO IBERICO DE ENERGIA - PORTUGAL","ACRONYM":"OMIP","CITY":"LISBOA"},
{"COUNTRY":"PORTUGAL","ISO_COUNTRY_CODE":"PT","MIC":"WQXL","OPERATING MIC":"XLIS","NAME-INSTITUTION DESCRIPTION":"EURONEXT - MARKET WITHOUT QUOTATIONS LISBON","ACRONYM":"","CITY":"LISBOA"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"MISX","OPERATING MIC":"MISX","NAME-INSTITUTION DESCRIPTION":"MOSCOW EXCHANGE","ACRONYM":"MOEX","CITY":"MOSCOW"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"RTSX","OPERATING MIC":"MISX","NAME-INSTITUTION DESCRIPTION":"MOSCOW EXCHANGE-DERIVATIVES AND CLASSICA MARKET","ACRONYM":"MOEX","CITY":"MOSCOW"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"NAMX","OPERATING MIC":"NAMX","NAME-INSTITUTION DESCRIPTION":"NATIONAL MERCANTILE EXCHANGE","ACRONYM":"NAMEX","CITY":"MOSCOW"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"RPDX","OPERATING MIC":"RPDX","NAME-INSTITUTION DESCRIPTION":"MOSCOW ENERGY EXCHANGE","ACRONYM":"MOSENEX","CITY":"MOSCOW"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"RUSX","OPERATING MIC":"RUSX","NAME-INSTITUTION DESCRIPTION":"NON-PROFIT PARTNERSHIP FOR THE DEVELOPMENT OF FINANCIAL MARKET RTS","ACRONYM":"NP RTS","CITY":"MOSCOW"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"SPIM","OPERATING MIC":"SPIM","NAME-INSTITUTION DESCRIPTION":"ST. PETERSBURG INTERNATIONAL MERCANTILE EXCHANGE","ACRONYM":"SPIMEX","CITY":"SAINT-PETERSBURG"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"XMOS","OPERATING MIC":"XMOS","NAME-INSTITUTION DESCRIPTION":"MOSCOW STOCK EXCHANGE","ACRONYM":"MSE","CITY":"MOSCOW"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"XPET","OPERATING MIC":"XPET","NAME-INSTITUTION DESCRIPTION":"SAINT PETERSBURG EXCHANGE","ACRONYM":"SPEX","CITY":"SAINT-PETERSBURG"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"XPIC","OPERATING MIC":"XPIC","NAME-INSTITUTION DESCRIPTION":"SAINT-PETERSBURG CURRENCY EXCHANGE","ACRONYM":"SPCEX","CITY":"SAINT-PETERSBURG"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"XRUS","OPERATING MIC":"XRUS","NAME-INSTITUTION DESCRIPTION":"INTERNET DIRECT-ACCESS EXCHANGE","ACRONYM":"INDX","CITY":"MOSCOW"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"XSAM","OPERATING MIC":"XSAM","NAME-INSTITUTION DESCRIPTION":"SAMARA CURRENCY INTERBANK EXCHANGE","ACRONYM":"SCIEX","CITY":"SAMARA"},
{"COUNTRY":"RUSSIA","ISO_COUNTRY_CODE":"RU","MIC":"XSIB","OPERATING MIC":"XSIB","NAME-INSTITUTION DESCRIPTION":"SIBERIAN EXCHANGE","ACRONYM":"SIMEX","CITY":"NOVOSIBIRSK"},
{"COUNTRY":"SOUTH AFRICA","ISO_COUNTRY_CODE":"ZA","MIC":"XJSE","OPERATING MIC":"XJSE","NAME-INSTITUTION DESCRIPTION":"JOHANNESBURG STOCK EXCHANGE","ACRONYM":"JSE","CITY":"JOHANNESBURG"},
{"COUNTRY":"SOUTH AFRICA","ISO_COUNTRY_CODE":"ZA","MIC":"ALTX","OPERATING MIC":"XJSE","NAME-INSTITUTION DESCRIPTION":"JSE ALTERNATE EXCHANGE","ACRONYM":"ALTX","CITY":"JOHANNESBURG"},
{"COUNTRY":"SOUTH AFRICA","ISO_COUNTRY_CODE":"ZA","MIC":"XBES","OPERATING MIC":"XJSE","NAME-INSTITUTION DESCRIPTION":"JSE CASH BOND MARKET","ACRONYM":"BESA","CITY":"JOHANNESBURG"},
{"COUNTRY":"SOUTH AFRICA","ISO_COUNTRY_CODE":"ZA","MIC":"XSAF","OPERATING MIC":"XJSE","NAME-INSTITUTION DESCRIPTION":"JSE EQUITY DERIVATIVES MARKET","ACRONYM":"SAFEX","CITY":"JOHANNESBURG"},
{"COUNTRY":"SOUTH AFRICA","ISO_COUNTRY_CODE":"ZA","MIC":"XSFA","OPERATING MIC":"XJSE","NAME-INSTITUTION DESCRIPTION":"JSE COMMODITY DERIVATIVES MARKET","ACRONYM":"SAFEX","CITY":"JOHANNESBURG"},
{"COUNTRY":"SOUTH AFRICA","ISO_COUNTRY_CODE":"ZA","MIC":"YLDX","OPERATING MIC":"XJSE","NAME-INSTITUTION DESCRIPTION":"JSE INTEREST RATE DERIVATIVES MARKET","ACRONYM":"","CITY":"JOHANNESBURG"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"GROW","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"BME GROW","ACRONYM":"SIBE","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"BMEX","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"BME - BOLSAS Y MERCADOS ESPANOLES","ACRONYM":"BME","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"MABX","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"MERCADO ALTERNATIVO BURSATIL","ACRONYM":"MAB","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"SEND","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"SEND - SISTEMA ELECTRONICO DE NEGOCIACION DE DEUDA","ACRONYM":"SEND","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XBAR","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"BOLSA DE BARCELONA","ACRONYM":"","CITY":"BARCELONA"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XBIL","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"BOLSA DE VALORES DE BILBAO","ACRONYM":"","CITY":"BILBAO"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XDRF","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"AIAF - MERCADO DE RENTA FIJA","ACRONYM":"","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XLAT","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"LATIBEX","ACRONYM":"","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XMAD","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"BOLSA DE MADRID","ACRONYM":"","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XMCE","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"MERCADO CONTINUO ESPANOL","ACRONYM":"SIBE","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XMRV","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"MEFF FINANCIAL DERIVATIVES","ACRONYM":"MEFF","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XVAL","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"BOLSA DE VALENCIA","ACRONYM":"","CITY":"VALENCIA"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"MERF","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"MERCADO ELECTRONICO DE RENTA FIJA","ACRONYM":"MERF","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"MARF","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"MERCADO ALTERNATIVO DE RENTA FIJA","ACRONYM":"MARF","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"BMCL","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"BME CLEARING S.A.","ACRONYM":"","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XMPW","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"MEFF POWER DERIVATIVES","ACRONYM":"MEPD","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"SBAR","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"BOLSA DE BARCELONA RENTA FIJA","ACRONYM":"","CITY":"BARCELONA"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"SBIL","OPERATING MIC":"BMEX","NAME-INSTITUTION DESCRIPTION":"BOLSA DE BILBAO RENTA FIJA","ACRONYM":"","CITY":"BILBAO"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"IBGH","OPERATING MIC":"IBGH","NAME-INSTITUTION DESCRIPTION":"IBERIAN GAS HUB","ACRONYM":"IBGH","CITY":"BILBAO"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"MIBG","OPERATING MIC":"MIBG","NAME-INSTITUTION DESCRIPTION":"MERCADO ORGANIZADO DEL GAS","ACRONYM":"MIBGAS","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"OMEL","OPERATING MIC":"OMEL","NAME-INSTITUTION DESCRIPTION":"OMI POLO ESPANOL S.A. (OMIE)","ACRONYM":"OMIE","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"PAVE","OPERATING MIC":"PAVE","NAME-INSTITUTION DESCRIPTION":"ALTERNATIVE PLATFORM FOR SPANISH SECURITIES","ACRONYM":"PAVE","CITY":"BARCELONA"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XDPA","OPERATING MIC":"XDPA","NAME-INSTITUTION DESCRIPTION":"CADE - MERCADO DE DEUDA PUBLICA ANOTADA","ACRONYM":"","CITY":"MADRID"},
{"COUNTRY":"SPAIN","ISO_COUNTRY_CODE":"ES","MIC":"XNAF","OPERATING MIC":"XNAF","NAME-INSTITUTION DESCRIPTION":"SISTEMA ESPANOL DE NEGOCIACION DE ACTIVOS FINANCIEROS","ACRONYM":"SENAF","CITY":"MADRID"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"CRYD","OPERATING MIC":"CRYD","NAME-INSTITUTION DESCRIPTION":"CRYEX - FX AND DIGITAL CURRENCIES","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"CRYX","OPERATING MIC":"CRYX","NAME-INSTITUTION DESCRIPTION":"CRYEX","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"SEBX","OPERATING MIC":"SEBX","NAME-INSTITUTION DESCRIPTION":"SEB - LIQUIDITY POOL","ACRONYM":"SEB","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"ENSX","OPERATING MIC":"SEBX","NAME-INSTITUTION DESCRIPTION":"SEB ENSKILDA","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"XNGM","OPERATING MIC":"XNGM","NAME-INSTITUTION DESCRIPTION":"NORDIC GROWTH MARKET","ACRONYM":"NGM","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"NMTF","OPERATING MIC":"XNGM","NAME-INSTITUTION DESCRIPTION":"NORDIC MTF","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"XNDX","OPERATING MIC":"XNGM","NAME-INSTITUTION DESCRIPTION":"NORDIC DERIVATIVES EXCHANGE","ACRONYM":"NDX","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"XNMR","OPERATING MIC":"XNGM","NAME-INSTITUTION DESCRIPTION":"NORDIC MTF REPORTING","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"XSAT","OPERATING MIC":"XSAT","NAME-INSTITUTION DESCRIPTION":"AKTIETORGET","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"XSTO","OPERATING MIC":"XSTO","NAME-INSTITUTION DESCRIPTION":"NASDAQ STOCKHOLM AB","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"FNSE","OPERATING MIC":"XSTO","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH SWEDEN","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"XOPV","OPERATING MIC":"XSTO","NAME-INSTITUTION DESCRIPTION":"OTC PUBLICATION VENUE","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"CSTO","OPERATING MIC":"XSTO","NAME-INSTITUTION DESCRIPTION":"NASDAQ CLEARING AB","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"DSTO","OPERATING MIC":"XSTO","NAME-INSTITUTION DESCRIPTION":"NASDAQ STOCKHOLM AB - NORDIC@MID","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"DNSE","OPERATING MIC":"XSTO","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH SWEDEN - NORDIC@MID","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"MSTO","OPERATING MIC":"XSTO","NAME-INSTITUTION DESCRIPTION":"NASDAQ STOCKHOLM AB – AUCTION ON DEMAND","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWEDEN","ISO_COUNTRY_CODE":"SE","MIC":"MNSE","OPERATING MIC":"XSTO","NAME-INSTITUTION DESCRIPTION":"FIRST NORTH SWEDEN – AUCTION ON DEMAND","ACRONYM":"","CITY":"STOCKHOLM"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"AIXE","OPERATING MIC":"AIXE","NAME-INSTITUTION DESCRIPTION":"AIXECUTE","ACRONYM":"","CITY":"BERNE"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"DOTS","OPERATING MIC":"DOTS","NAME-INSTITUTION DESCRIPTION":"SWISS DOTS BY CATS","ACRONYM":"DOTS","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"EUCH","OPERATING MIC":"EUCH","NAME-INSTITUTION DESCRIPTION":"EUREX ZURICH","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"EUSP","OPERATING MIC":"EUCH","NAME-INSTITUTION DESCRIPTION":"EUREX OTC SPOT MARKET","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"EURM","OPERATING MIC":"EUCH","NAME-INSTITUTION DESCRIPTION":"EUREX REPO MARKET","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"EUSC","OPERATING MIC":"EUCH","NAME-INSTITUTION DESCRIPTION":"EUREX CH SECLEND MARKET","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"S3FM","OPERATING MIC":"S3FM","NAME-INSTITUTION DESCRIPTION":"SOCIETY3 FUNDERSMART","ACRONYM":"S3FM","CITY":"LUZERN"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"STOX","OPERATING MIC":"STOX","NAME-INSTITUTION DESCRIPTION":"STOXX LIMITED","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XSCU","OPERATING MIC":"STOX","NAME-INSTITUTION DESCRIPTION":"STOXX LIMITED - CUSTOMIZED INDICES","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XSTV","OPERATING MIC":"STOX","NAME-INSTITUTION DESCRIPTION":"STOXX LIMITED - VOLATILITY INDICES","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XSTX","OPERATING MIC":"STOX","NAME-INSTITUTION DESCRIPTION":"STOXX LIMITED - INDICES","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"UBSG","OPERATING MIC":"UBSG","NAME-INSTITUTION DESCRIPTION":"UBS TRADING","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"UBSF","OPERATING MIC":"UBSG","NAME-INSTITUTION DESCRIPTION":"UBS FX","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"UBSC","OPERATING MIC":"UBSG","NAME-INSTITUTION DESCRIPTION":"UBS PIN-FX","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"VLEX","OPERATING MIC":"VLEX","NAME-INSTITUTION DESCRIPTION":"VONTOBEL LIQUIDITY EXTENDER","ACRONYM":"VLEX","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XBRN","OPERATING MIC":"XBRN","NAME-INSTITUTION DESCRIPTION":"BX SWISS AG","ACRONYM":"","CITY":"BERN"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XSWX","OPERATING MIC":"XSWX","NAME-INSTITUTION DESCRIPTION":"SIX SWISS EXCHANGE","ACRONYM":"SIX","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XQMH","OPERATING MIC":"XSWX","NAME-INSTITUTION DESCRIPTION":"SIX STRUCTURED PRODUCTS EXCHANGE","ACRONYM":"SIX","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XVTX","OPERATING MIC":"XSWX","NAME-INSTITUTION DESCRIPTION":"SIX SWISS EXCHANGE - BLUE CHIPS SEGMENT","ACRONYM":"SIX","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XBTR","OPERATING MIC":"XSWX","NAME-INSTITUTION DESCRIPTION":"SIX SWISS BILATERAL TRADING PLATFORM FOR STRUCTURED OTC PRODUCTS","ACRONYM":"SIX","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XICB","OPERATING MIC":"XSWX","NAME-INSTITUTION DESCRIPTION":"SIX CORPORATE BONDS AG","ACRONYM":"SIX","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XSWM","OPERATING MIC":"XSWX","NAME-INSTITUTION DESCRIPTION":"SIX SWISS EXCHANGE - SIX SWISS EXCHANGE AT MIDPOINT","ACRONYM":"SIX","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"XSLS","OPERATING MIC":"XSWX","NAME-INSTITUTION DESCRIPTION":"SIX SWISS EXCHANGE - SLS","ACRONYM":"SIX","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"ZKBX","OPERATING MIC":"ZKBX","NAME-INSTITUTION DESCRIPTION":"ZURCHER KANTONALBANK SECURITIES EXCHANGE","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"SWITZERLAND","ISO_COUNTRY_CODE":"CH","MIC":"KMUX","OPERATING MIC":"ZKBX","NAME-INSTITUTION DESCRIPTION":"ZURCHER KANTONALBANK - EKMU-X","ACRONYM":"","CITY":"ZURICH"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"CLMX","OPERATING MIC":"CLMX","NAME-INSTITUTION DESCRIPTION":"CLIMEX","ACRONYM":"","CITY":"UTRECHT"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"HCHC","OPERATING MIC":"HCHC","NAME-INSTITUTION DESCRIPTION":"ICE CLEAR NETHERLANDS B.V.","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"NDEX","OPERATING MIC":"NDEX","NAME-INSTITUTION DESCRIPTION":"ICE ENDEX DERIVATIVES B.V.","ACRONYM":"ICE ENDEX","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"IMEQ","OPERATING MIC":"NDEX","NAME-INSTITUTION DESCRIPTION":"ICE MARKETS EQUITY","ACRONYM":"ICE ENDEX","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"NDXS","OPERATING MIC":"NDEX","NAME-INSTITUTION DESCRIPTION":"ICE ENDEX GAS B.V.","ACRONYM":"ICE ENDEX","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"NLPX","OPERATING MIC":"NLPX","NAME-INSTITUTION DESCRIPTION":"APX POWER NL","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"TOMX","OPERATING MIC":"TOMX","NAME-INSTITUTION DESCRIPTION":"TOM MTF CASH MARKETS","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"TOMD","OPERATING MIC":"TOMX","NAME-INSTITUTION DESCRIPTION":"TOM MTF DERIVATIVES MARKET","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"XAMS","OPERATING MIC":"XAMS","NAME-INSTITUTION DESCRIPTION":"EURONEXT - EURONEXT AMSTERDAM","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"ALXA","OPERATING MIC":"XAMS","NAME-INSTITUTION DESCRIPTION":"EURONEXT - ALTERNEXT AMSTERDAM","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"TNLA","OPERATING MIC":"XAMS","NAME-INSTITUTION DESCRIPTION":"EURONEXT - TRADED BUT NOT LISTED AMSTERDAM","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"XEUC","OPERATING MIC":"XAMS","NAME-INSTITUTION DESCRIPTION":"EURONEXT COM, COMMODITIES FUTURES AND OPTIONS","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"XEUE","OPERATING MIC":"XAMS","NAME-INSTITUTION DESCRIPTION":"EURONEXT EQF, EQUITIES AND INDICES DERIVATIVES","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"XEUI","OPERATING MIC":"XAMS","NAME-INSTITUTION DESCRIPTION":"EURONEXT IRF, INTEREST RATE FUTURE AND OPTIONS","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"XEMS","OPERATING MIC":"XEMS","NAME-INSTITUTION DESCRIPTION":"EMS EXCHANGE","ACRONYM":"","CITY":"S-HERTOGENBOSCH"},
{"COUNTRY":"THE NETHERLANDS","ISO_COUNTRY_CODE":"NL","MIC":"XNXC","OPERATING MIC":"XNXC","NAME-INSTITUTION DESCRIPTION":"NXCHANGE","ACRONYM":"","CITY":"AMSTERDAM"},
{"COUNTRY":"TURKEY","ISO_COUNTRY_CODE":"TR","MIC":"EXTR","OPERATING MIC":"EXTR","NAME-INSTITUTION DESCRIPTION":"ENERGY EXCHANGE ISTANBUL","ACRONYM":"EXIST","CITY":"ISTANBUL"},
{"COUNTRY":"TURKEY","ISO_COUNTRY_CODE":"TR","MIC":"XEID","OPERATING MIC":"EXTR","NAME-INSTITUTION DESCRIPTION":"ELECTRICITY INTRA-DAY MARKET","ACRONYM":"EXIST","CITY":"ISTANBUL"},
{"COUNTRY":"TURKEY","ISO_COUNTRY_CODE":"TR","MIC":"XEDA","OPERATING MIC":"EXTR","NAME-INSTITUTION DESCRIPTION":"ELECTRICITY DAY-AHEAD MARKET","ACRONYM":"EXIST","CITY":"ISTANBUL"},
{"COUNTRY":"TURKEY","ISO_COUNTRY_CODE":"TR","MIC":"XIST","OPERATING MIC":"XIST","NAME-INSTITUTION DESCRIPTION":"BORSA ISTANBUL","ACRONYM":"","CITY":"ISTANBUL"},
{"COUNTRY":"TURKEY","ISO_COUNTRY_CODE":"TR","MIC":"XFNO","OPERATING MIC":"XIST","NAME-INSTITUTION DESCRIPTION":"BORSA ISTANBUL - FUTURES AND OPTIONS MARKET","ACRONYM":"","CITY":"ISTANBUL"},
{"COUNTRY":"TURKEY","ISO_COUNTRY_CODE":"TR","MIC":"XEQY","OPERATING MIC":"XIST","NAME-INSTITUTION DESCRIPTION":"BORSA ISTANBUL - EQUITY MARKET","ACRONYM":"","CITY":"ISTANBUL"},
{"COUNTRY":"TURKEY","ISO_COUNTRY_CODE":"TR","MIC":"XDSM","OPERATING MIC":"XIST","NAME-INSTITUTION DESCRIPTION":"BORSA ISTANBUL - DEBT SECURITIES MARKET","ACRONYM":"","CITY":"ISTANBUL"},
{"COUNTRY":"TURKEY","ISO_COUNTRY_CODE":"TR","MIC":"XPMS","OPERATING MIC":"XIST","NAME-INSTITUTION DESCRIPTION":"BORSA ISTANBUL - PRECIOUS METALS AND DIAMONDS MARKETS","ACRONYM":"","CITY":"ISTANBUL"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"AFDL","OPERATING MIC":"AFDL","NAME-INSTITUTION DESCRIPTION":"ABIDE FINANCIAL DRSP LIMITED APA","ACRONYM":"AFDLAPA","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"AQXE","OPERATING MIC":"AQXE","NAME-INSTITUTION DESCRIPTION":"AQUIS EXCHANGE","ACRONYM":"AQX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ARAX","OPERATING MIC":"ARAX","NAME-INSTITUTION DESCRIPTION":"ARAX COMMODITIES LTD","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ATLB","OPERATING MIC":"ATLB","NAME-INSTITUTION DESCRIPTION":"ATLANTIC BROKERS LTD","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"AUTX","OPERATING MIC":"AUTX","NAME-INSTITUTION DESCRIPTION":"AUTILLA","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"AUTP","OPERATING MIC":"AUTX","NAME-INSTITUTION DESCRIPTION":"AUTILLA - PRECIOUS METALS","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"AUTB","OPERATING MIC":"AUTX","NAME-INSTITUTION DESCRIPTION":"AUTILLA - BASE METALS","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BALT","OPERATING MIC":"BALT","NAME-INSTITUTION DESCRIPTION":"THE BALTIC EXCHANGE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BLTX","OPERATING MIC":"BALT","NAME-INSTITUTION DESCRIPTION":"BALTEX - FREIGHT DERIVATIVES MARKET","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BCXE","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE","ACRONYM":"BATS EUROPE","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BATE","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE -BXE ORDER BOOKS","ACRONYM":"BATS EUROPE","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CHIX","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE - CXE ORDER BOOKS","ACRONYM":"BATS EUROPE","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BATD","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE -BXE DARK ORDER BOOK","ACRONYM":"BATS DARK","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CHID","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE - CXE DARK ORDER BOOK","ACRONYM":"CXE DARK","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BATF","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE – BATS OFF-BOOK","ACRONYM":"BATS OFF-BOOK","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CHIO","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE - CXE - OFF-BOOK","ACRONYM":"CXE OFF-BOOK","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BOTC","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"OFF EXCHANGE IDENTIFIER FOR OTC TRADES REPORTED TO BATS EUROPE","ACRONYM":"BATS OFF EXCHANGE","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BATP","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE - BXE PERIODIC","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BARO","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE - REGULATED MARKET OFF BOOK","ACRONYM":"BATS REGM OFF BOOK","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BARK","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE - REGULATED MARKET DARK BOOK","ACRONYM":"BATS REGM DARK","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BART","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE - REGULATED MARKET INTEGRATED BOOK","ACRONYM":"BATS REGM LIT","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LISX","OPERATING MIC":"BCXE","NAME-INSTITUTION DESCRIPTION":"BATS EUROPE - LIS SERVICE","ACRONYM":"BATS LIS","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BGCI","OPERATING MIC":"BGCI","NAME-INSTITUTION DESCRIPTION":"BGC BROKERS LP","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BGCB","OPERATING MIC":"BGCI","NAME-INSTITUTION DESCRIPTION":"BGC BROKERS LP - TRAYPORT","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BLOX","OPERATING MIC":"BLOX","NAME-INSTITUTION DESCRIPTION":"BLOCKMATCH","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BMTF","OPERATING MIC":"BMTF","NAME-INSTITUTION DESCRIPTION":"BLOOMBERG TRADING FACILITY LIMITED","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BOAT","OPERATING MIC":"BOAT","NAME-INSTITUTION DESCRIPTION":"CINNOBER BOAT","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BOSC","OPERATING MIC":"BOSC","NAME-INSTITUTION DESCRIPTION":"BONDSCAPE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BRNX","OPERATING MIC":"BRNX","NAME-INSTITUTION DESCRIPTION":"BERNSTEIN CROSS (BERN-X)","ACRONYM":"BERN-X","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BTEE","OPERATING MIC":"BTEE","NAME-INSTITUTION DESCRIPTION":"BROKERTEC EU MTF","ACRONYM":"BEM","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EBSX","OPERATING MIC":"BTEE","NAME-INSTITUTION DESCRIPTION":"EBS MTF","ACRONYM":"EBS","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CCO2","OPERATING MIC":"CCO2","NAME-INSTITUTION DESCRIPTION":"CANTORCO2E.COM LIMITED","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CGME","OPERATING MIC":"CGME","NAME-INSTITUTION DESCRIPTION":"CITI MATCH","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CHEV","OPERATING MIC":"CHEV","NAME-INSTITUTION DESCRIPTION":"CA CHEUVREUX","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BLNK","OPERATING MIC":"CHEV","NAME-INSTITUTION DESCRIPTION":"BLINK MTF","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CMEE","OPERATING MIC":"CMEE","NAME-INSTITUTION DESCRIPTION":"CME EUROPE","ACRONYM":"CME","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CMEC","OPERATING MIC":"CMEE","NAME-INSTITUTION DESCRIPTION":"CME CLEARING EUROPE","ACRONYM":"CME","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CMED","OPERATING MIC":"CMEE","NAME-INSTITUTION DESCRIPTION":"CME EUROPE - DERIVATIVES","ACRONYM":"CME","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CMMT","OPERATING MIC":"CMMT","NAME-INSTITUTION DESCRIPTION":"CLEAR MARKETS EUROPE LIMITED","ACRONYM":"CM MTF","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CRYP","OPERATING MIC":"CRYP","NAME-INSTITUTION DESCRIPTION":"CRYPTO FACILITIES","ACRONYM":"CF","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CSEU","OPERATING MIC":"CSEU","NAME-INSTITUTION DESCRIPTION":"CREDIT SUISSE (EUROPE)","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CSCF","OPERATING MIC":"CSEU","NAME-INSTITUTION DESCRIPTION":"CREDIT SUISSE AES CROSSFINDER EUROPE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"DBIX","OPERATING MIC":"DBIX","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BANK INTERNALISATION","ACRONYM":"DB INTERNALISATION","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"DBDC","OPERATING MIC":"DBIX","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BANK - DIRECT CAPITAL ACCESS","ACRONYM":"DCA","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"DBCX","OPERATING MIC":"DBIX","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BANK - CLOSE CROSS","ACRONYM":"CLX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"DBCR","OPERATING MIC":"DBIX","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BANK - CENTRAL RISK BOOK","ACRONYM":"CENTRAL RISK BOOK","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"DBMO","OPERATING MIC":"DBIX","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BANK - MANUAL OTC","ACRONYM":"MANUAL OTC","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"DBSE","OPERATING MIC":"DBIX","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BANK - SUPERX EU","ACRONYM":"SUPERX EU","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EMBX","OPERATING MIC":"EMBX","NAME-INSTITUTION DESCRIPTION":"EMERGING MARKETS BOND EXCHANGE LIMITED","ACRONYM":"EMBX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ENCL","OPERATING MIC":"ENCL","NAME-INSTITUTION DESCRIPTION":"ENCLEAR","ACRONYM":"ENC","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EQLD","OPERATING MIC":"EQLD","NAME-INSTITUTION DESCRIPTION":"EQUILEND EUROPE LIMITED","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EXEU","OPERATING MIC":"EXEU","NAME-INSTITUTION DESCRIPTION":"EXANE BNP PARIBAS","ACRONYM":"EXEU","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EXMP","OPERATING MIC":"EXEU","NAME-INSTITUTION DESCRIPTION":"EXANE BNP PARIBAS - MID POINT","ACRONYM":"EXANE BNP PARIBAS","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EXOR","OPERATING MIC":"EXEU","NAME-INSTITUTION DESCRIPTION":"EXANE BNP PARIBAS - CHILD ORDER CROSSING","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EXVP","OPERATING MIC":"EXEU","NAME-INSTITUTION DESCRIPTION":"EXANE BNP PARIBAS - VOLUME PROFILE CROSSING","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EXBO","OPERATING MIC":"EXEU","NAME-INSTITUTION DESCRIPTION":"EXANE BNP PARIBAS - BID-OFFER CROSSING","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EXSI","OPERATING MIC":"EXEU","NAME-INSTITUTION DESCRIPTION":"EXANE BNP PARIBAS - SYSTEMATIC INTERNALISER","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EXCP","OPERATING MIC":"EXEU","NAME-INSTITUTION DESCRIPTION":"EXANE BNP PARIBAS - CLOSING PRICE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EXLP","OPERATING MIC":"EXEU","NAME-INSTITUTION DESCRIPTION":"EXANE BNP PARIBAS - LIQUIDITY PROVISION","ACRONYM":"EXLP","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EXDC","OPERATING MIC":"EXEU","NAME-INSTITUTION DESCRIPTION":"EXANE BNP PARIBAS - DIRECT CAPITAL ACCESS","ACRONYM":"EXDC","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"FAIR","OPERATING MIC":"FAIR","NAME-INSTITUTION DESCRIPTION":"CANTOR SPREADFAIR","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GEMX","OPERATING MIC":"GEMX","NAME-INSTITUTION DESCRIPTION":"GEMMA (GILT EDGED MARKET MAKERS’ASSOCIATION)","ACRONYM":"GEMMA","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GFIC","OPERATING MIC":"GFIC","NAME-INSTITUTION DESCRIPTION":"GFI CREDITMATCH","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GFIF","OPERATING MIC":"GFIC","NAME-INSTITUTION DESCRIPTION":"GFI FOREXMATCH","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GFIN","OPERATING MIC":"GFIC","NAME-INSTITUTION DESCRIPTION":"GFI ENERGYMATCH","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GFIR","OPERATING MIC":"GFIC","NAME-INSTITUTION DESCRIPTION":"GFI RATESMATCH","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GMEG","OPERATING MIC":"GMEG","NAME-INSTITUTION DESCRIPTION":"GMEX EXCHANGE","ACRONYM":"GMEX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XLDX","OPERATING MIC":"GMEG","NAME-INSTITUTION DESCRIPTION":"LONDON DERIVATIVES EXCHANGE","ACRONYM":"LDX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XGDX","OPERATING MIC":"GMEG","NAME-INSTITUTION DESCRIPTION":"GLOBAL DERIVATIVES EXCHANGE","ACRONYM":"GDX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XGSX","OPERATING MIC":"GMEG","NAME-INSTITUTION DESCRIPTION":"GLOBAL SECURITIES EXCHANGE","ACRONYM":"GSX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XGCX","OPERATING MIC":"GMEG","NAME-INSTITUTION DESCRIPTION":"GLOBAL COMMODITIES EXCHANGE","ACRONYM":"GCX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GRIF","OPERATING MIC":"GRIF","NAME-INSTITUTION DESCRIPTION":"GRIFFIN MARKETS LIMITED","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GRIO","OPERATING MIC":"GRIF","NAME-INSTITUTION DESCRIPTION":"GRIFFIN MARKETS LIMITED - OTF","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GRSE","OPERATING MIC":"GRSE","NAME-INSTITUTION DESCRIPTION":"THE GREEN STOCK EXCHANGE - ACB IMPACT MARKETS","ACRONYM":"GSE","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GSIL","OPERATING MIC":"GSIL","NAME-INSTITUTION DESCRIPTION":"GOLDMAN SACHS INTERNATIONAL","ACRONYM":"GSI","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GSSI","OPERATING MIC":"GSIL","NAME-INSTITUTION DESCRIPTION":"GOLDMAN SACHS INTERNATIONAL - SYSTEMATIC INTERNALISER","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GSBX","OPERATING MIC":"GSIL","NAME-INSTITUTION DESCRIPTION":"GOLDMAN SACHS INTERNATIONAL - SIGMA BCN","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"HSXE","OPERATING MIC":"HSXE","NAME-INSTITUTION DESCRIPTION":"HSBC-X UNITED KINGDOM","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"IBAL","OPERATING MIC":"IBAL","NAME-INSTITUTION DESCRIPTION":"ICE BENCHMARK ADMINISTRATION","ACRONYM":"IBA","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ICAP","OPERATING MIC":"ICAP","NAME-INSTITUTION DESCRIPTION":"ICAP EUROPE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ICAH","OPERATING MIC":"ICAP","NAME-INSTITUTION DESCRIPTION":"TRAYPORT","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ICEN","OPERATING MIC":"ICAP","NAME-INSTITUTION DESCRIPTION":"ICAP ENERGY","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ICSE","OPERATING MIC":"ICAP","NAME-INSTITUTION DESCRIPTION":"ICAP SECURITIES","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ICTQ","OPERATING MIC":"ICAP","NAME-INSTITUTION DESCRIPTION":"ICAP TRUEQUOTE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"WCLK","OPERATING MIC":"ICAP","NAME-INSTITUTION DESCRIPTION":"ICAP WCLK","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ISDX","OPERATING MIC":"ICAP","NAME-INSTITUTION DESCRIPTION":"ICAP SECURITIES & DERIVATIVES EXCHANGE LIMITED","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"IGDL","OPERATING MIC":"ICAP","NAME-INSTITUTION DESCRIPTION":"ICAP GLOBAL DERIVATIVES LIMITED","ACRONYM":"IGDL","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"IFEU","OPERATING MIC":"IFEU","NAME-INSTITUTION DESCRIPTION":"ICE FUTURES EUROPE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CXRT","OPERATING MIC":"IFEU","NAME-INSTITUTION DESCRIPTION":"CREDITEX BROKERAGE LLP - MTF","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"IMCO","OPERATING MIC":"IFEU","NAME-INSTITUTION DESCRIPTION":"ICE MARKETS COMMODITIES","ACRONYM":"","CITY":"ATLANTA"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"IFLO","OPERATING MIC":"IFEU","NAME-INSTITUTION DESCRIPTION":"ICE FUTURES EUROPE - EQUITY PRODUCTS DIVISION","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"IFLL","OPERATING MIC":"IFEU","NAME-INSTITUTION DESCRIPTION":"ICE FUTURES EUROPE - FINANCIAL PRODUCTS DIVISION","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"IFUT","OPERATING MIC":"IFEU","NAME-INSTITUTION DESCRIPTION":"ICE FUTURES EUROPE - EUROPEAN UTILITIES DIVISION","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"IFLX","OPERATING MIC":"IFEU","NAME-INSTITUTION DESCRIPTION":"ICE FUTURES EUROPE - AGRICULTURAL PRODUCTS DIVISION","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"IFEN","OPERATING MIC":"IFEU","NAME-INSTITUTION DESCRIPTION":"ICE FUTURES EUROPE - OIL AND REFINED PRODUCTS DIVISION","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"CXOT","OPERATING MIC":"IFEU","NAME-INSTITUTION DESCRIPTION":"CREDITEX BROKERAGE LLP - OTF","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ISWA","OPERATING MIC":"ISWA","NAME-INSTITUTION DESCRIPTION":"I-SWAP","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"JPSI","OPERATING MIC":"JPSI","NAME-INSTITUTION DESCRIPTION":"J.P. MORGAN SECURITIES PLC","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"KLEU","OPERATING MIC":"KLEU","NAME-INSTITUTION DESCRIPTION":"KNIGHT LINK EUROPE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LCUR","OPERATING MIC":"LCUR","NAME-INSTITUTION DESCRIPTION":"CURRENEX LDFX","ACRONYM":"CX LDFX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LIQU","OPERATING MIC":"LIQU","NAME-INSTITUTION DESCRIPTION":"LIQUIDNET SYSTEMS","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LIQH","OPERATING MIC":"LIQU","NAME-INSTITUTION DESCRIPTION":"LIQUIDNET H20","ACRONYM":"LQNT H20","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LIQF","OPERATING MIC":"LIQU","NAME-INSTITUTION DESCRIPTION":"LIQUIDNET EUROPE LIMITED","ACRONYM":"LIQU","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LMAX","OPERATING MIC":"LMAX","NAME-INSTITUTION DESCRIPTION":"LMAX","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LMAD","OPERATING MIC":"LMAX","NAME-INSTITUTION DESCRIPTION":"LMAX - DERIVATIVES","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LMAE","OPERATING MIC":"LMAX","NAME-INSTITUTION DESCRIPTION":"LMAX - EQUITIES","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LMAF","OPERATING MIC":"LMAX","NAME-INSTITUTION DESCRIPTION":"LMAX - FX","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LMAO","OPERATING MIC":"LMAX","NAME-INSTITUTION DESCRIPTION":"LMAX - INDICES/RATES/COMMODITIES","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LMEC","OPERATING MIC":"LMEC","NAME-INSTITUTION DESCRIPTION":"LME CLEAR","ACRONYM":"LMEC","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LOTC","OPERATING MIC":"LOTC","NAME-INSTITUTION DESCRIPTION":"OTC MARKET","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"PLDX","OPERATING MIC":"LOTC","NAME-INSTITUTION DESCRIPTION":"PLUS DERIVATIVES EXCHANGE","ACRONYM":"PLUS-DX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"LPPM","OPERATING MIC":"LPPM","NAME-INSTITUTION DESCRIPTION":"LONDON PLATINUM AND PALLADIUM MARKET","ACRONYM":"LPPM","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MAEL","OPERATING MIC":"MAEL","NAME-INSTITUTION DESCRIPTION":"MARKETAXESS EUROPE LIMITED","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MFGL","OPERATING MIC":"MFGL","NAME-INSTITUTION DESCRIPTION":"MF GLOBAL ENERGY MTF","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MLXN","OPERATING MIC":"MLXN","NAME-INSTITUTION DESCRIPTION":"BANK OF AMERICA - MERRILL LYNCH INSTINCT X - EUROPE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MLAX","OPERATING MIC":"MLXN","NAME-INSTITUTION DESCRIPTION":"BANK OF AMERICA - MERRILL LYNCH AUCTION CROSS","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MLEU","OPERATING MIC":"MLXN","NAME-INSTITUTION DESCRIPTION":"BANK OF AMERICA - MERRILL LYNCH OTC - EUROPE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MLVE","OPERATING MIC":"MLXN","NAME-INSTITUTION DESCRIPTION":"BANK OF AMERICA - MERRILL LYNCH VWAP CROSS - EUROPE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MSIP","OPERATING MIC":"MSIP","NAME-INSTITUTION DESCRIPTION":"MORGAN STANLEY AND CO. INTERNATIONAL PLC","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MYTR","OPERATING MIC":"MYTR","NAME-INSTITUTION DESCRIPTION":"MYTREASURY","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"N2EX","OPERATING MIC":"N2EX","NAME-INSTITUTION DESCRIPTION":"N2EX","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"NDCM","OPERATING MIC":"NDCM","NAME-INSTITUTION DESCRIPTION":"ICE ENDEX GAS SPOT LTD","ACRONYM":"OCM","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"NEXS","OPERATING MIC":"NEXS","NAME-INSTITUTION DESCRIPTION":"NEX SEF","ACRONYM":"NSL","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"NOFF","OPERATING MIC":"NOFF","NAME-INSTITUTION DESCRIPTION":"NOMURA OTC TRADES","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"NURO","OPERATING MIC":"NURO","NAME-INSTITUTION DESCRIPTION":"NASDAQ OMX EUROPE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XNLX","OPERATING MIC":"NURO","NAME-INSTITUTION DESCRIPTION":"NASDAQ OMX NLX","ACRONYM":"NLX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"NURD","OPERATING MIC":"NURO","NAME-INSTITUTION DESCRIPTION":"NASDAQ EUROPE (NURO) DARK","ACRONYM":"NURODARK","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"NXEU","OPERATING MIC":"NXEU","NAME-INSTITUTION DESCRIPTION":"NX","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"OTCE","OPERATING MIC":"OTCE","NAME-INSTITUTION DESCRIPTION":"OTCEX","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"PEEL","OPERATING MIC":"PEEL","NAME-INSTITUTION DESCRIPTION":"PEEL HUNT LLP UK","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XRSP","OPERATING MIC":"PEEL","NAME-INSTITUTION DESCRIPTION":"PEEL HUNT RETAIL","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XPHX","OPERATING MIC":"PEEL","NAME-INSTITUTION DESCRIPTION":"PEEL HUNT CROSSING","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"PIEU","OPERATING MIC":"PIEU","NAME-INSTITUTION DESCRIPTION":"ARITAS FINANCIAL LTD","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"PIRM","OPERATING MIC":"PIRM","NAME-INSTITUTION DESCRIPTION":"PIRUM","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"QWIX","OPERATING MIC":"QWIX","NAME-INSTITUTION DESCRIPTION":"Q-WIXX PLATFORM","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"RBCE","OPERATING MIC":"RBCE","NAME-INSTITUTION DESCRIPTION":"RBC EUROPE LIMITED","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"RBSX","OPERATING MIC":"RBSX","NAME-INSTITUTION DESCRIPTION":"RBS CROSS","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"RTSL","OPERATING MIC":"RTSL","NAME-INSTITUTION DESCRIPTION":"REUTERS TRANSACTION SERVICES LIMITED","ACRONYM":"RTSL","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"SECF","OPERATING MIC":"SECF","NAME-INSTITUTION DESCRIPTION":"SECFINEX","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"SGMX","OPERATING MIC":"SGMX","NAME-INSTITUTION DESCRIPTION":"SIGMA X MTF","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"SHAR","OPERATING MIC":"SHAR","NAME-INSTITUTION DESCRIPTION":"ASSET MATCH","ACRONYM":"","CITY":"AYLESBURY"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"SPEC","OPERATING MIC":"SPEC","NAME-INSTITUTION DESCRIPTION":"SPECTRONLIVE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"SPRZ","OPERATING MIC":"SPRZ","NAME-INSTITUTION DESCRIPTION":"SPREADZERO","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"SSEX","OPERATING MIC":"SSEX","NAME-INSTITUTION DESCRIPTION":"SOCIAL STOCK EXCHANGE","ACRONYM":"SSE","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"SWAP","OPERATING MIC":"SWAP","NAME-INSTITUTION DESCRIPTION":"SWAPSTREAM","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TFSV","OPERATING MIC":"TFSV","NAME-INSTITUTION DESCRIPTION":"VOLBROKER","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TPIE","OPERATING MIC":"TPIE","NAME-INSTITUTION DESCRIPTION":"THE PROPERTY INVESTMENT EXCHANGE","ACRONYM":"PROPEX","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TRDE","OPERATING MIC":"TRDE","NAME-INSTITUTION DESCRIPTION":"TRADITION ELECTRONIC TRADING PLATFORM","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TCDS","OPERATING MIC":"TRDE","NAME-INSTITUTION DESCRIPTION":"TRADITION CDS","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TRDX","OPERATING MIC":"TRDE","NAME-INSTITUTION DESCRIPTION":"TRAD-X","ACRONYM":"TRAD-X","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"VOLA","OPERATING MIC":"TRDE","NAME-INSTITUTION DESCRIPTION":"TRADITION - VOLATIS","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TFSG","OPERATING MIC":"TRDE","NAME-INSTITUTION DESCRIPTION":"TRADITION ENERGY","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"PARX","OPERATING MIC":"TRDE","NAME-INSTITUTION DESCRIPTION":"PARFX","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"ELIX","OPERATING MIC":"TRDE","NAME-INSTITUTION DESCRIPTION":"ELIXIUM","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EMCH","OPERATING MIC":"TRDE","NAME-INSTITUTION DESCRIPTION":"FINACOR EMATCH","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TREU","OPERATING MIC":"TREU","NAME-INSTITUTION DESCRIPTION":"TRADEWEB EUROPE LIMITED","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TRQX","OPERATING MIC":"TRQX","NAME-INSTITUTION DESCRIPTION":"TURQUOISE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TRQM","OPERATING MIC":"TRQX","NAME-INSTITUTION DESCRIPTION":"TURQUOISE DARK","ACRONYM":"TQDARK","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"UBSL","OPERATING MIC":"UBSL","NAME-INSTITUTION DESCRIPTION":"UBS EMEA EQUITIES TRADING","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"UBSE","OPERATING MIC":"UBSL","NAME-INSTITUTION DESCRIPTION":"UBS PIN (EMEA)","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"UKGD","OPERATING MIC":"UKGD","NAME-INSTITUTION DESCRIPTION":"UK GILTS MARKET","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"UKPX","OPERATING MIC":"UKPX","NAME-INSTITUTION DESCRIPTION":"APX POWER UK","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"VEGA","OPERATING MIC":"VEGA","NAME-INSTITUTION DESCRIPTION":"VEGA-CHI","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"WINS","OPERATING MIC":"WINS","NAME-INSTITUTION DESCRIPTION":"WINTERFLOOD SECURITIES LIMITED","ACRONYM":"WINS","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XALT","OPERATING MIC":"XALT","NAME-INSTITUTION DESCRIPTION":"ALTEX-ATS","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XCOR","OPERATING MIC":"XCOR","NAME-INSTITUTION DESCRIPTION":"ICMA","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XGCL","OPERATING MIC":"XGCL","NAME-INSTITUTION DESCRIPTION":"GLOBAL COAL LIMITED","ACRONYM":"GLOBALCOAL","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XLBM","OPERATING MIC":"XLBM","NAME-INSTITUTION DESCRIPTION":"LONDON BULLION MARKET","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XLCH","OPERATING MIC":"XLCH","NAME-INSTITUTION DESCRIPTION":"LCH.CLEARNET LTD","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XLDN","OPERATING MIC":"XLDN","NAME-INSTITUTION DESCRIPTION":"EURONEXT - EURONEXT LONDON","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XLME","OPERATING MIC":"XLME","NAME-INSTITUTION DESCRIPTION":"LONDON METAL EXCHANGE","ACRONYM":"LME","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XLON","OPERATING MIC":"XLON","NAME-INSTITUTION DESCRIPTION":"LONDON STOCK EXCHANGE","ACRONYM":"LSE","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XLOD","OPERATING MIC":"XLON","NAME-INSTITUTION DESCRIPTION":"LONDON STOCK EXCHANGE - DERIVATIVES MARKET","ACRONYM":"LSE","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XMTS","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS MARKETS","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"HUNG","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS HUNGARY","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"PRME","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS PRIME","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"AMTS","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS NETHERLANDS","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"EMTS","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"EUROMTS","ACRONYM":"EMTS","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"GMTS","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS GERMANY","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"IMTS","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS IRELAND","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MCZK","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS CZECH REPUBLIC","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MTSA","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS AUSTRIA","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MTSG","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS GREECE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MTSS","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS INTERDEALER SWAPS MARKET","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"RMTS","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS ISRAEL","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"SMTS","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS SPAIN","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"VMTS","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS SLOVENIA","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"BVUK","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"BONDVISION UK","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"PORT","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS PORTUGAL","ACRONYM":"PTE","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"MTSW","OPERATING MIC":"XMTS","NAME-INSTITUTION DESCRIPTION":"MTS SWAP MARKET","ACRONYM":"STF","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XSGA","OPERATING MIC":"XSGA","NAME-INSTITUTION DESCRIPTION":"ALPHA Y","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XSMP","OPERATING MIC":"XSMP","NAME-INSTITUTION DESCRIPTION":"SMARTPOOL","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XSWB","OPERATING MIC":"XSWB","NAME-INSTITUTION DESCRIPTION":"SWX SWISS BLOCK","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XTUP","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TPEQ","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC - TP EQUITYTRADE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TBEN","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC - TP ENERGY","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TBLA","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC - TP TRADEBLADE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TPCD","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC - TP CREDITDEAL","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TPFD","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC - TP FORWARDDEAL","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TPRE","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC - TP REPO","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TPSD","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC - TP SWAPDEAL","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XTPE","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC - TP ENERGYTRADE","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TPEL","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC – TULLETT PREBON (EUROPE) LIMITED","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"TPSL","OPERATING MIC":"XTUP","NAME-INSTITUTION DESCRIPTION":"TULLETT PREBON PLC – TULLETT PREBON (SECURITIES) LIMITED","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED KINGDOM","ISO_COUNTRY_CODE":"GB","MIC":"XUBS","OPERATING MIC":"XUBS","NAME-INSTITUTION DESCRIPTION":"UBS MTF","ACRONYM":"","CITY":"LONDON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"AATS","OPERATING MIC":"AATS","NAME-INSTITUTION DESCRIPTION":"ASSENT ATS","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"AQUA","OPERATING MIC":"AQUA","NAME-INSTITUTION DESCRIPTION":"AQUA EQUITIES L.P.","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ATDF","OPERATING MIC":"ATDF","NAME-INSTITUTION DESCRIPTION":"AUTOMATED TRADING DESK FINANCIAL SERVICES, LLC","ACRONYM":"ATDF","CITY":"MOUNT PLEASANT"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CORE","OPERATING MIC":"ATDF","NAME-INSTITUTION DESCRIPTION":"ATD - CITIGROUP AGENCY OPTION AND EQUITIES ROUTING ENGINE","ACRONYM":"ATDF","CITY":"MOUNT PLEASANT"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BAML","OPERATING MIC":"BAML","NAME-INSTITUTION DESCRIPTION":"BANK OF AMERICA - MERRILL LYNCH INSTINCT X ATS","ACRONYM":"MLIX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MLVX","OPERATING MIC":"BAML","NAME-INSTITUTION DESCRIPTION":"BANK OF AMERICA - MERRILL LYNCH VWAP CROSS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MLCO","OPERATING MIC":"BAML","NAME-INSTITUTION DESCRIPTION":"BANK OF AMERICA - MERRILL LYNCH OTC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BARX","OPERATING MIC":"BARX","NAME-INSTITUTION DESCRIPTION":"BARCLAYS ATS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BARD","OPERATING MIC":"BARX","NAME-INSTITUTION DESCRIPTION":"BARCLAYS FX – TRADING","ACRONYM":"BCAP LX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BARL","OPERATING MIC":"BARX","NAME-INSTITUTION DESCRIPTION":"BARCLAYS LIQUID MARKETS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BCDX","OPERATING MIC":"BARX","NAME-INSTITUTION DESCRIPTION":"BARCLAYS DIRECT EX ATS","ACRONYM":"BCDX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BATS","OPERATING MIC":"BATS","NAME-INSTITUTION DESCRIPTION":"BATS Z-EXCHANGE","ACRONYM":"BATS","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BATO","OPERATING MIC":"BATS","NAME-INSTITUTION DESCRIPTION":"BZX OPTIONS MARKET","ACRONYM":"BATO","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BATY","OPERATING MIC":"BATS","NAME-INSTITUTION DESCRIPTION":"BATS Y-EXCHANGE, INC.","ACRONYM":"BYXX","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BZXD","OPERATING MIC":"BATS","NAME-INSTITUTION DESCRIPTION":"BATS Z-EXCHANGE DARK","ACRONYM":"BZXDARK","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BYXD","OPERATING MIC":"BATS","NAME-INSTITUTION DESCRIPTION":"BATS Y-EXCHANGE DARK","ACRONYM":"BYXDARK","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BBSF","OPERATING MIC":"BBSF","NAME-INSTITUTION DESCRIPTION":"BLOOMBERG SEF LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BGCF","OPERATING MIC":"BGCF","NAME-INSTITUTION DESCRIPTION":"BGC FINANCIAL INC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BGCD","OPERATING MIC":"BGCF","NAME-INSTITUTION DESCRIPTION":"BGC DERIVATIVE MARKETS L.P.","ACRONYM":"BGCDML","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BHSF","OPERATING MIC":"BHSF","NAME-INSTITUTION DESCRIPTION":"BATS HOTSPOT SEF LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BIDS","OPERATING MIC":"BIDS","NAME-INSTITUTION DESCRIPTION":"BIDS TRADING L.P.","ACRONYM":"BIDS","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BLTD","OPERATING MIC":"BLTD","NAME-INSTITUTION DESCRIPTION":"BLOOMBERG TRADEBOOK LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BPOL","OPERATING MIC":"BLTD","NAME-INSTITUTION DESCRIPTION":"BLOOMBERG BPOOL","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BNYC","OPERATING MIC":"BNYC","NAME-INSTITUTION DESCRIPTION":"CONVERGEX","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"VTEX","OPERATING MIC":"BNYC","NAME-INSTITUTION DESCRIPTION":"VORTEX","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NYFX","OPERATING MIC":"BNYC","NAME-INSTITUTION DESCRIPTION":"MILLENNIUM","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BTEC","OPERATING MIC":"BTEC","NAME-INSTITUTION DESCRIPTION":"ICAP ELECTRONIC BROKING (US)","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ICSU","OPERATING MIC":"BTEC","NAME-INSTITUTION DESCRIPTION":"ICAP SEF (US) LLC.","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CDED","OPERATING MIC":"CDED","NAME-INSTITUTION DESCRIPTION":"CITADEL SECURITIES","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CGMI","OPERATING MIC":"CGMI","NAME-INSTITUTION DESCRIPTION":"CITIGROUP GLOBAL MARKETS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CICX","OPERATING MIC":"CGMI","NAME-INSTITUTION DESCRIPTION":"CITI CROSS","ACRONYM":"CX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"LQFI","OPERATING MIC":"CGMI","NAME-INSTITUTION DESCRIPTION":"CITI LIQUIFI","ACRONYM":"LQFI","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CBLC","OPERATING MIC":"CGMI","NAME-INSTITUTION DESCRIPTION":"CITIBLOC","ACRONYM":"CBLC","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CMSF","OPERATING MIC":"CMSF","NAME-INSTITUTION DESCRIPTION":"CLEAR MARKETS NORTH AMERICA, INC.","ACRONYM":"CM SEF","CITY":"CHARLOTTE"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CRED","OPERATING MIC":"CRED","NAME-INSTITUTION DESCRIPTION":"CREDIT SUISSE (US)","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CAES","OPERATING MIC":"CRED","NAME-INSTITUTION DESCRIPTION":"CREDIT SUISSE AES CROSSFINDER","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CSLP","OPERATING MIC":"CRED","NAME-INSTITUTION DESCRIPTION":"CREDIT SUISSE LIGHT POOL","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"DBSX","OPERATING MIC":"DBSX","NAME-INSTITUTION DESCRIPTION":"DEUTSCHE BANK SUPER X","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"DEAL","OPERATING MIC":"DEAL","NAME-INSTITUTION DESCRIPTION":"DCX (DERIVATIVES CURRENCY EXCHANGE)","ACRONYM":"DCX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"EDGE","OPERATING MIC":"EDGE","NAME-INSTITUTION DESCRIPTION":"BATS DIRECT EDGE","ACRONYM":"","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"EDDP","OPERATING MIC":"EDGE","NAME-INSTITUTION DESCRIPTION":"EDGX EXCHANGE DARK","ACRONYM":"EDGXDARK","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"EDGA","OPERATING MIC":"EDGE","NAME-INSTITUTION DESCRIPTION":"EDGA EXCHANGE","ACRONYM":"EDGA","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"EDGD","OPERATING MIC":"EDGE","NAME-INSTITUTION DESCRIPTION":"EDGA EXCHANGE DARK","ACRONYM":"EDGADARK","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"EDGX","OPERATING MIC":"EDGE","NAME-INSTITUTION DESCRIPTION":"EDGX EXCHANGE","ACRONYM":"EDGX","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"EDGO","OPERATING MIC":"EDGE","NAME-INSTITUTION DESCRIPTION":"EDGX OPTIONS MARKET","ACRONYM":"EDGO","CITY":"LENEXA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"EGMT","OPERATING MIC":"EGMT","NAME-INSTITUTION DESCRIPTION":"EG MARKET TECHNOLOGIES","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ERIS","OPERATING MIC":"ERIS","NAME-INSTITUTION DESCRIPTION":"ERIS EXCHANGE","ACRONYM":"","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"FAST","OPERATING MIC":"FAST","NAME-INSTITUTION DESCRIPTION":"FASTMATCH","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"FINR","OPERATING MIC":"FINR","NAME-INSTITUTION DESCRIPTION":"FINRA","ACRONYM":"","CITY":"WASHINGTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"FINN","OPERATING MIC":"FINR","NAME-INSTITUTION DESCRIPTION":"FINRA/NASDAQ TRF(TRADE REPORTING FACILITY)","ACRONYM":"","CITY":"WASHINGTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"FINO","OPERATING MIC":"FINR","NAME-INSTITUTION DESCRIPTION":"FINRA ORF (TRADE REPORTING FACILITY)","ACRONYM":"","CITY":"WASHINGTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"FINY","OPERATING MIC":"FINR","NAME-INSTITUTION DESCRIPTION":"FINRA/NYSE TRF (TRADE REPORTING FACILITY)","ACRONYM":"","CITY":"WASHINGTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XADF","OPERATING MIC":"FINR","NAME-INSTITUTION DESCRIPTION":"FINRA ALTERNATIVE DISPLAY FACILITY (ADF)","ACRONYM":"","CITY":"WASHINGTON/NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"OOTC","OPERATING MIC":"FINR","NAME-INSTITUTION DESCRIPTION":"OTHER OTC","ACRONYM":"","CITY":"WASHINGTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"FSEF","OPERATING MIC":"FSEF","NAME-INSTITUTION DESCRIPTION":"FTSEF LLC","ACRONYM":"","CITY":"GREAT NECK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"FXAL","OPERATING MIC":"FXAL","NAME-INSTITUTION DESCRIPTION":"FXALL","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"FXCM","OPERATING MIC":"FXCM","NAME-INSTITUTION DESCRIPTION":"FXCM","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"G1XX","OPERATING MIC":"G1XX","NAME-INSTITUTION DESCRIPTION":"G1 EXECUTION SERVICES","ACRONYM":"","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GLLC","OPERATING MIC":"GLLC","NAME-INSTITUTION DESCRIPTION":"GATE US LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GLPS","OPERATING MIC":"GLPS","NAME-INSTITUTION DESCRIPTION":"ESSEX RADEZ, LLC","ACRONYM":"GLPS","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GLPX","OPERATING MIC":"GLPX","NAME-INSTITUTION DESCRIPTION":"ACS EXECUTION SERVICES, LLC","ACRONYM":"GLPX","CITY":"RED BANK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GOVX","OPERATING MIC":"GOVX","NAME-INSTITUTION DESCRIPTION":"GOVEX","ACRONYM":"GOVEX","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GREE","OPERATING MIC":"GREE","NAME-INSTITUTION DESCRIPTION":"THE GREEN EXCHANGE","ACRONYM":"","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GSEF","OPERATING MIC":"GSEF","NAME-INSTITUTION DESCRIPTION":"GFI SWAPS EXCHANGE, LLC","ACRONYM":"GFI SEF","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GTCO","OPERATING MIC":"GTCO","NAME-INSTITUTION DESCRIPTION":"KCG AMERICAS LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GTSX","OPERATING MIC":"GTSX","NAME-INSTITUTION DESCRIPTION":"GTSX","ACRONYM":"GTSX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GTXS","OPERATING MIC":"GTXS","NAME-INSTITUTION DESCRIPTION":"GTX SEF, LLC","ACRONYM":"GTX","CITY":"BEDMINSTER"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"HEGX","OPERATING MIC":"HEGX","NAME-INSTITUTION DESCRIPTION":"NADEX","ACRONYM":"","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"HPPO","OPERATING MIC":"HPPO","NAME-INSTITUTION DESCRIPTION":"POTAMUS TRADING LLC","ACRONYM":"HPPO","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"HSFX","OPERATING MIC":"HSFX","NAME-INSTITUTION DESCRIPTION":"HOTSPOT FX","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ICEL","OPERATING MIC":"ICEL","NAME-INSTITUTION DESCRIPTION":"ISLAND ECN LTD, THE","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IEXG","OPERATING MIC":"IEXG","NAME-INSTITUTION DESCRIPTION":"INVESTORS EXCHANGE","ACRONYM":"IEX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IFUS","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"ICE FUTURES U.S.","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IEPA","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"INTERCONTINENTAL EXCHANGE","ACRONYM":"ICE","CITY":"ATLANTA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IMFX","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"ICE MARKETS FOREIGN EXCHANGE","ACRONYM":"","CITY":"ATLANTA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IMAG","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"ICE MARKETS AGRICULTURE","ACRONYM":"","CITY":"ATLANTA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IMBD","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"ICE MARKETS BONDS","ACRONYM":"","CITY":"ATLANTA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IMCR","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"ICE MARKETS CREDIT","ACRONYM":"","CITY":"ATLANTA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IMEN","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"ICE MARKETS ENERGY","ACRONYM":"","CITY":"ATLANTA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IMIR","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"ICE MARKETS RATES","ACRONYM":"","CITY":"ATLANTA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IFED","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"ICE FUTURES U.S. ENERGY DIVISION","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IMCG","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"CREDITEX LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IMCC","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"CREDITEX SECURITIES CORPORATION","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ICES","OPERATING MIC":"IFUS","NAME-INSTITUTION DESCRIPTION":"ICE SWAP TRADE LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ISDA","OPERATING MIC":"ISDA","NAME-INSTITUTION DESCRIPTION":"ISDAFIX","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ITGI","OPERATING MIC":"ITGI","NAME-INSTITUTION DESCRIPTION":"ITG - POSIT","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"JEFX","OPERATING MIC":"JEFX","NAME-INSTITUTION DESCRIPTION":"JETX","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"JPBX","OPERATING MIC":"JPBX","NAME-INSTITUTION DESCRIPTION":"JPBX","ACRONYM":"JPBX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"JPMX","OPERATING MIC":"JPMX","NAME-INSTITUTION DESCRIPTION":"JPMX","ACRONYM":"JPMX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"JSES","OPERATING MIC":"JSES","NAME-INSTITUTION DESCRIPTION":"JANE STREET EXECUTION SERVICES LLC","ACRONYM":"JX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"JSJX","OPERATING MIC":"JSJX","NAME-INSTITUTION DESCRIPTION":"JANE STREET JX","ACRONYM":"JX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"KNIG","OPERATING MIC":"KNIG","NAME-INSTITUTION DESCRIPTION":"KNIGHT","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"KNCM","OPERATING MIC":"KNIG","NAME-INSTITUTION DESCRIPTION":"KNIGHT CAPITAL MARKETS LLC","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"KNEM","OPERATING MIC":"KNIG","NAME-INSTITUTION DESCRIPTION":"KNIGHT EQUITY MARKETS LP","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"KNLI","OPERATING MIC":"KNIG","NAME-INSTITUTION DESCRIPTION":"KNIGHT LINK","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"KNMX","OPERATING MIC":"KNIG","NAME-INSTITUTION DESCRIPTION":"KNIGHT MATCH ATS","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ACKF","OPERATING MIC":"KNIG","NAME-INSTITUTION DESCRIPTION":"KCG ACKNOWLEDGE FI","ACRONYM":"ACKFI","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"LASF","OPERATING MIC":"LASF","NAME-INSTITUTION DESCRIPTION":"LATAM SEF","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"LEVL","OPERATING MIC":"LEVL","NAME-INSTITUTION DESCRIPTION":"LEVEL ATS","ACRONYM":"LEVEL","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"LIUS","OPERATING MIC":"LIUS","NAME-INSTITUTION DESCRIPTION":"LIQUIDNET, INC.","ACRONYM":"LQNT","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"LIUH","OPERATING MIC":"LIUS","NAME-INSTITUTION DESCRIPTION":"LIQUIDNET, INC. H2O ATS","ACRONYM":"LQNA","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"LIFI","OPERATING MIC":"LIUS","NAME-INSTITUTION DESCRIPTION":"LIQUIDNET, INC. FIXED INCOME ATS","ACRONYM":"LQNF","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"LQED","OPERATING MIC":"LQED","NAME-INSTITUTION DESCRIPTION":"LIQUIDITYEDGE","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"LTAA","OPERATING MIC":"LTAA","NAME-INSTITUTION DESCRIPTION":"LUMINEX TRADING & ANALYTICS LLC","ACRONYM":"LTAA","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"LMNX","OPERATING MIC":"LTAA","NAME-INSTITUTION DESCRIPTION":"LUMINEX TRADING & ANALYTICS LLC - ATS","ACRONYM":"LMNX","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MIHI","OPERATING MIC":"MIHI","NAME-INSTITUTION DESCRIPTION":"MIAMI INTERNATIONAL HOLDINGS, INC.","ACRONYM":"MIHI","CITY":"PRINCETON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XMIO","OPERATING MIC":"MIHI","NAME-INSTITUTION DESCRIPTION":"MIAMI INTERNATIONAL SECURITIES EXCHANGE","ACRONYM":"XMIO","CITY":"PRINCETON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MPRL","OPERATING MIC":"MIHI","NAME-INSTITUTION DESCRIPTION":"MIAX PEARL, LLC","ACRONYM":"MPRL","CITY":"PRINCETON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MSCO","OPERATING MIC":"MSCO","NAME-INSTITUTION DESCRIPTION":"MORGAN STANLEY AND CO. LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MSPL","OPERATING MIC":"MSCO","NAME-INSTITUTION DESCRIPTION":"MS POOL","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MSRP","OPERATING MIC":"MSCO","NAME-INSTITUTION DESCRIPTION":"MS RETAIL POOL","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MSTX","OPERATING MIC":"MSCO","NAME-INSTITUTION DESCRIPTION":"MS TRAJECTORY CROSS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MSLP","OPERATING MIC":"MSCO","NAME-INSTITUTION DESCRIPTION":"MORGAN STANLEY AUTOMATED LIQUIDITY PROVISION","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MTUS","OPERATING MIC":"MTUS","NAME-INSTITUTION DESCRIPTION":"MTS US","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BVUS","OPERATING MIC":"MTUS","NAME-INSTITUTION DESCRIPTION":"BONDVISION US","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MTSB","OPERATING MIC":"MTUS","NAME-INSTITUTION DESCRIPTION":"MTS BONDS.COM","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MTXX","OPERATING MIC":"MTXX","NAME-INSTITUTION DESCRIPTION":"MARKETAXESS CORPORATION","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MTXM","OPERATING MIC":"MTXX","NAME-INSTITUTION DESCRIPTION":"MARKETAXESS CORPORATION MID-X TRADING SYSTEM","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MTXC","OPERATING MIC":"MTXX","NAME-INSTITUTION DESCRIPTION":"MARKETAXESS CORPORATION SINGLE-NAME CDS CENTRAL LIMIT ORDER","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MTXS","OPERATING MIC":"MTXX","NAME-INSTITUTION DESCRIPTION":"MARKETAXESS SEF CORPORATION","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MTXA","OPERATING MIC":"MTXX","NAME-INSTITUTION DESCRIPTION":"MARKETAXESS CANADA COMPANY","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NBLX","OPERATING MIC":"NBLX","NAME-INSTITUTION DESCRIPTION":"NOBLE EXCHANGE","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NFSC","OPERATING MIC":"NFSC","NAME-INSTITUTION DESCRIPTION":"NATIONAL FINANCIAL SERVICES, LLC","ACRONYM":"","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NFSA","OPERATING MIC":"NFSC","NAME-INSTITUTION DESCRIPTION":"FIDELITY CROSSSTREAM","ACRONYM":"","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NFSD","OPERATING MIC":"NFSC","NAME-INSTITUTION DESCRIPTION":"FIDELITY DARK","ACRONYM":"","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XSTM","OPERATING MIC":"NFSC","NAME-INSTITUTION DESCRIPTION":"FIDELITY CROSSSTREAM ATS","ACRONYM":"","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NMRA","OPERATING MIC":"NMRA","NAME-INSTITUTION DESCRIPTION":"NOMURA SECURITIES INTERNATIONAL","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NODX","OPERATING MIC":"NODX","NAME-INSTITUTION DESCRIPTION":"NODAL EXCHANGE","ACRONYM":"","CITY":"VIENNA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NXUS","OPERATING MIC":"NXUS","NAME-INSTITUTION DESCRIPTION":"NX ATS - CROSSING PLATFORM","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NYPC","OPERATING MIC":"NYPC","NAME-INSTITUTION DESCRIPTION":"NEW YORK PORTFOLIO CLEARING","ACRONYM":"NYPC","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"OLLC","OPERATING MIC":"OLLC","NAME-INSTITUTION DESCRIPTION":"OTCEX LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"OPRA","OPERATING MIC":"OPRA","NAME-INSTITUTION DESCRIPTION":"OPTIONS PRICE REPORTING AUTHORITY","ACRONYM":"OPRA","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"OTCM","OPERATING MIC":"OTCM","NAME-INSTITUTION DESCRIPTION":"OTC MARKETS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"OTCB","OPERATING MIC":"OTCM","NAME-INSTITUTION DESCRIPTION":"OTCQB MARKETPLACE","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"OTCQ","OPERATING MIC":"OTCM","NAME-INSTITUTION DESCRIPTION":"OTCQX MARKETPLACE","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"PINC","OPERATING MIC":"OTCM","NAME-INSTITUTION DESCRIPTION":"OTC PINK CURRENT","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"PINI","OPERATING MIC":"OTCM","NAME-INSTITUTION DESCRIPTION":"OTC PINK NO INFORMATION","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"PINL","OPERATING MIC":"OTCM","NAME-INSTITUTION DESCRIPTION":"OTC PINK LIMITED","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"PINX","OPERATING MIC":"OTCM","NAME-INSTITUTION DESCRIPTION":"OTC PINK MARKETPLACE","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"PSGM","OPERATING MIC":"OTCM","NAME-INSTITUTION DESCRIPTION":"OTC GREY MARKET","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CAVE","OPERATING MIC":"OTCM","NAME-INSTITUTION DESCRIPTION":"CAVEAT EMPTOR","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"PDQX","OPERATING MIC":"PDQX","NAME-INSTITUTION DESCRIPTION":"CODA MARKETS","ACRONYM":"PDQX","CITY":"GLENVIEW"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"PDQD","OPERATING MIC":"PDQX","NAME-INSTITUTION DESCRIPTION":"CODA MARKETS ATS DARK","ACRONYM":"","CITY":"ILLINOIS"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"PIPE","OPERATING MIC":"PIPE","NAME-INSTITUTION DESCRIPTION":"ARITAS SECURITIES LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"PRSE","OPERATING MIC":"PRSE","NAME-INSTITUTION DESCRIPTION":"PRAGMA ATS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"PULX","OPERATING MIC":"PULX","NAME-INSTITUTION DESCRIPTION":"BLOCKCROSS ATS","ACRONYM":"","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"RICX","OPERATING MIC":"RICX","NAME-INSTITUTION DESCRIPTION":"RIVERCROSS","ACRONYM":"","CITY":"NARBERTH"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"RICD","OPERATING MIC":"RICX","NAME-INSTITUTION DESCRIPTION":"RIVERCROSS DARK","ACRONYM":"RIVERX","CITY":"NARBERTH"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"SCXS","OPERATING MIC":"SCXS","NAME-INSTITUTION DESCRIPTION":"SEED SEF LLC","ACRONYM":"SEED SEF","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"SGMA","OPERATING MIC":"SGMA","NAME-INSTITUTION DESCRIPTION":"GOLDMAN SACH MTF","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"SGMT","OPERATING MIC":"SGMT","NAME-INSTITUTION DESCRIPTION":"SIGMA X2","ACRONYM":"SGMT","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"SHAW","OPERATING MIC":"SHAW","NAME-INSTITUTION DESCRIPTION":"D.E. SHAW","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"SHAD","OPERATING MIC":"SHAW","NAME-INSTITUTION DESCRIPTION":"D.E. SHAW DARK","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"SOHO","OPERATING MIC":"SOHO","NAME-INSTITUTION DESCRIPTION":"TWO SIGMA SECURITIES, LLC","ACRONYM":"SOHO","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"SSTX","OPERATING MIC":"SSTX","NAME-INSTITUTION DESCRIPTION":"E-EXCHANGE","ACRONYM":"EEXCHANGE","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TERA","OPERATING MIC":"TERA","NAME-INSTITUTION DESCRIPTION":"TERAEXCHANGE","ACRONYM":"","CITY":"NEW JERSEY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TFSU","OPERATING MIC":"TFSU","NAME-INSTITUTION DESCRIPTION":"TFS GREEN UNITED STATES GREEN MARKETS","ACRONYM":"TFS","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"THRE","OPERATING MIC":"THRE","NAME-INSTITUTION DESCRIPTION":"THOMSON REUTERS (SEF) LLC","ACRONYM":"TRSEF","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TMID","OPERATING MIC":"TMID","NAME-INSTITUTION DESCRIPTION":"TRUMID ATS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TPSE","OPERATING MIC":"TPSE","NAME-INSTITUTION DESCRIPTION":"TP SEF, INC.","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TRCK","OPERATING MIC":"TRCK","NAME-INSTITUTION DESCRIPTION":"TRACK ECN","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TRUX","OPERATING MIC":"TRUX","NAME-INSTITUTION DESCRIPTION":"TRUEEX LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TRU1","OPERATING MIC":"TRUX","NAME-INSTITUTION DESCRIPTION":"TRUEEX LLC - DESIGNATED CONTRACT MARKET (DMC)","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TRU2","OPERATING MIC":"TRUX","NAME-INSTITUTION DESCRIPTION":"TRUEEX LLC - SEF (SWAP EXECUTION FACILITY)","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TRWB","OPERATING MIC":"TRWB","NAME-INSTITUTION DESCRIPTION":"TRADEWEB LLC","ACRONYM":"","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BNDD","OPERATING MIC":"TRWB","NAME-INSTITUTION DESCRIPTION":"TRADEWEB DIRECT LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TWSF","OPERATING MIC":"TRWB","NAME-INSTITUTION DESCRIPTION":"TW SEF LLC","ACRONYM":"TWSEF","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"DWSF","OPERATING MIC":"TRWB","NAME-INSTITUTION DESCRIPTION":"DW SEF LLC","ACRONYM":"DWSEF","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TSAD","OPERATING MIC":"TSAD","NAME-INSTITUTION DESCRIPTION":"TRADITION SECURITIES AND DERIVATIVES INC.","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TSBX","OPERATING MIC":"TSBX","NAME-INSTITUTION DESCRIPTION":"TRIPLESHOT","ACRONYM":"","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"TSEF","OPERATING MIC":"TSEF","NAME-INSTITUTION DESCRIPTION":"TRADITION SEF","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"UBSA","OPERATING MIC":"UBSA","NAME-INSTITUTION DESCRIPTION":"UBS ATS","ACRONYM":"UBS ATS","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"UBSP","OPERATING MIC":"UBSA","NAME-INSTITUTION DESCRIPTION":"UBS PIN (UBS PRICE IMPROVEMENT NETWORK)","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"VFCM","OPERATING MIC":"VFCM","NAME-INSTITUTION DESCRIPTION":"VIRTU FINANCIAL CAPITAL MARKETS LLC","ACRONYM":"VFCM","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"VIRT","OPERATING MIC":"VIRT","NAME-INSTITUTION DESCRIPTION":"VIRTU FINANCIAL BD","ACRONYM":"VEQ","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"WEED","OPERATING MIC":"WEED","NAME-INSTITUTION DESCRIPTION":"WEEDEN AND CO MARKETS","ACRONYM":"","CITY":"GREENWICH"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XWEE","OPERATING MIC":"WEED","NAME-INSTITUTION DESCRIPTION":"WEEDEN ATS","ACRONYM":"","CITY":"GREENWICH"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"WELX","OPERATING MIC":"WELX","NAME-INSTITUTION DESCRIPTION":"WELLS FARGO LIQUIDITY CROSS ATS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"WSAG","OPERATING MIC":"WSAG","NAME-INSTITUTION DESCRIPTION":"WALL STREET ACCESS","ACRONYM":"WSAG","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XAQS","OPERATING MIC":"XAQS","NAME-INSTITUTION DESCRIPTION":"AUTOMATED EQUITY FINANCE MARKETS","ACRONYM":"AQS","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XBOX","OPERATING MIC":"XBOX","NAME-INSTITUTION DESCRIPTION":"BOSTON OPTIONS EXCHANGE","ACRONYM":"BOX","CITY":"BOSTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XCBO","OPERATING MIC":"XCBO","NAME-INSTITUTION DESCRIPTION":"CHICAGO BOARD OPTIONS EXCHANGE","ACRONYM":"CBOE","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"C2OX","OPERATING MIC":"XCBO","NAME-INSTITUTION DESCRIPTION":"C2 OPTIONS EXCHANGE INC.","ACRONYM":"","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CBSX","OPERATING MIC":"XCBO","NAME-INSTITUTION DESCRIPTION":"CBOE STOCK EXCHANGE","ACRONYM":"CBSX","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XCBF","OPERATING MIC":"XCBO","NAME-INSTITUTION DESCRIPTION":"CBOE FUTURES EXCHANGE","ACRONYM":"CFE","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XCBT","OPERATING MIC":"XCBT","NAME-INSTITUTION DESCRIPTION":"CHICAGO BOARD OF TRADE","ACRONYM":"CBOT","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"FCBT","OPERATING MIC":"XCBT","NAME-INSTITUTION DESCRIPTION":"CHICAGO BOARD OF TRADE (FLOOR)","ACRONYM":"CBOT (FLOOR)","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XKBT","OPERATING MIC":"XCBT","NAME-INSTITUTION DESCRIPTION":"KANSAS CITY BOARD OF TRADE","ACRONYM":"KCBT","CITY":"KANSAS CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XCFF","OPERATING MIC":"XCFF","NAME-INSTITUTION DESCRIPTION":"CANTOR FINANCIAL FUTURES EXCHANGE","ACRONYM":"CANTOR","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XCHI","OPERATING MIC":"XCHI","NAME-INSTITUTION DESCRIPTION":"CHICAGO STOCK EXCHANGE, INC","ACRONYM":"CHX","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XCIS","OPERATING MIC":"XCIS","NAME-INSTITUTION DESCRIPTION":"NATIONAL STOCK EXCHANGE, INC.","ACRONYM":"NSX","CITY":"JERSEY CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XCME","OPERATING MIC":"XCME","NAME-INSTITUTION DESCRIPTION":"CHICAGO MERCANTILE EXCHANGE","ACRONYM":"CME","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"FCME","OPERATING MIC":"XCME","NAME-INSTITUTION DESCRIPTION":"CHICAGO MERCANTILE EXCHANGE (FLOOR)","ACRONYM":"CME (FLOOR)","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GLBX","OPERATING MIC":"XCME","NAME-INSTITUTION DESCRIPTION":"CME GLOBEX","ACRONYM":"","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XIMM","OPERATING MIC":"XCME","NAME-INSTITUTION DESCRIPTION":"INTERNATIONAL MONETARY MARKET","ACRONYM":"","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XIOM","OPERATING MIC":"XCME","NAME-INSTITUTION DESCRIPTION":"INDEX AND OPTIONS MARKET","ACRONYM":"IOM","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CMES","OPERATING MIC":"XCME","NAME-INSTITUTION DESCRIPTION":"CME SWAPS MARKETS (CME)","ACRONYM":"","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CBTS","OPERATING MIC":"XCME","NAME-INSTITUTION DESCRIPTION":"CME SWAPS MARKETS (CBOT)","ACRONYM":"","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"CECS","OPERATING MIC":"XCME","NAME-INSTITUTION DESCRIPTION":"CME SWAPS MARKETS (COMEX)","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NYMS","OPERATING MIC":"XCME","NAME-INSTITUTION DESCRIPTION":"CME SWAPS MARKETS (NYMEX)","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XCUR","OPERATING MIC":"XCUR","NAME-INSTITUTION DESCRIPTION":"CURRENEX","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XELX","OPERATING MIC":"XELX","NAME-INSTITUTION DESCRIPTION":"ELX","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XFCI","OPERATING MIC":"XFCI","NAME-INSTITUTION DESCRIPTION":"FINANCIALCONTENT INDEXES","ACRONYM":"","CITY":"FOSTER CITY"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XGMX","OPERATING MIC":"XGMX","NAME-INSTITUTION DESCRIPTION":"GLOBALCLEAR MERCANTILE EXCHANGE","ACRONYM":"GMX","CITY":"GUAYNABO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XINS","OPERATING MIC":"XINS","NAME-INSTITUTION DESCRIPTION":"INSTINET","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IBLX","OPERATING MIC":"XINS","NAME-INSTITUTION DESCRIPTION":"INSTINET BLX","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ICBX","OPERATING MIC":"XINS","NAME-INSTITUTION DESCRIPTION":"INSTINET CBX (US)","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ICRO","OPERATING MIC":"XINS","NAME-INSTITUTION DESCRIPTION":"INSTINET VWAP CROSS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"IIDX","OPERATING MIC":"XINS","NAME-INSTITUTION DESCRIPTION":"INSTINET IDX","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"RCBX","OPERATING MIC":"XINS","NAME-INSTITUTION DESCRIPTION":"INSTINET RETAIL CBX","ACRONYM":"RCBX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MOCX","OPERATING MIC":"XINS","NAME-INSTITUTION DESCRIPTION":"MOC CROSS","ACRONYM":"MOCX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XISX","OPERATING MIC":"XISX","NAME-INSTITUTION DESCRIPTION":"INTERNATIONAL SECURITIES EXCHANGE, LLC","ACRONYM":"ISE","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XISA","OPERATING MIC":"XISX","NAME-INSTITUTION DESCRIPTION":"INTERNATIONAL SECURITIES EXCHANGE, LLC - ALTERNATIVE MARKETS","ACRONYM":"ISE","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XISE","OPERATING MIC":"XISX","NAME-INSTITUTION DESCRIPTION":"INTERNATIONAL SECURITIES EXCHANGE, LLC - EQUITIES","ACRONYM":"ISE","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"GMNI","OPERATING MIC":"XISX","NAME-INSTITUTION DESCRIPTION":"ISE GEMINI EXCHANGE","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"MCRY","OPERATING MIC":"XISX","NAME-INSTITUTION DESCRIPTION":"ISE MERCURY, LLC","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XMER","OPERATING MIC":"XMER","NAME-INSTITUTION DESCRIPTION":"MERCHANTS' EXCHANGE","ACRONYM":"ME","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XMGE","OPERATING MIC":"XMGE","NAME-INSTITUTION DESCRIPTION":"MINNEAPOLIS GRAIN EXCHANGE","ACRONYM":"MGE","CITY":"MINNEAPOLIS"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNAS","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ - ALL MARKETS","ACRONYM":"NASDAQ","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XBXO","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ OMX BX OPTIONS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"BOSD","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ OMX BX DARK","ACRONYM":"NQBXDARK","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NASD","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NSDQ DARK","ACRONYM":"NSDQDARK","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XBRT","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"BRUT ECN","ACRONYM":"BRUT","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNCM","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ CAPITAL MARKET","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNDQ","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ OPTIONS MARKET","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNGS","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ/NGS (GLOBAL SELECT MARKET)","ACRONYM":"NGS","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNIM","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ INTERMARKET","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNMS","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ/NMS (GLOBAL MARKET)","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XPBT","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ OMX FUTURES EXCHANGE","ACRONYM":"NFX","CITY":"PHILADELPHIA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XPHL","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ OMX PHLX","ACRONYM":"PHLX","CITY":"PHILADELPHIA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XPHO","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"PHILADELPHIA OPTIONS EXCHANGE","ACRONYM":"","CITY":"PHILADELPHIA"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XPOR","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"PORTAL","ACRONYM":"","CITY":"WASHINGTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XPSX","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ OMX PSX","ACRONYM":"PSX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XBOS","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ OMX BX","ACRONYM":"BSE","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ESPD","OPERATING MIC":"XNAS","NAME-INSTITUTION DESCRIPTION":"NASDAQ OMX ESPEED","ACRONYM":"ESPEED","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNYM","OPERATING MIC":"XNYM","NAME-INSTITUTION DESCRIPTION":"NEW YORK MERCANTILE EXCHANGE","ACRONYM":"NYMEX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XCEC","OPERATING MIC":"XNYM","NAME-INSTITUTION DESCRIPTION":"COMMODITIES EXCHANGE CENTER","ACRONYM":"COMEX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNYE","OPERATING MIC":"XNYM","NAME-INSTITUTION DESCRIPTION":"NEW YORK MERCANTILE EXCHANGE - OTC MARKETS","ACRONYM":"NYMEX ECM","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNYL","OPERATING MIC":"XNYM","NAME-INSTITUTION DESCRIPTION":"NEW YORK MERCANTILE EXCHANGE - ENERGY MARKETS","ACRONYM":"NYMEX MTF LIMITED","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNYS","OPERATING MIC":"XNYS","NAME-INSTITUTION DESCRIPTION":"NEW YORK STOCK EXCHANGE, INC.","ACRONYM":"NYSE","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ALDP","OPERATING MIC":"XNYS","NAME-INSTITUTION DESCRIPTION":"NYSE ALTERNEXT DARK","ACRONYM":"AMEXDARK","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"AMXO","OPERATING MIC":"XNYS","NAME-INSTITUTION DESCRIPTION":"NYSE AMEX OPTIONS","ACRONYM":"NYSE","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ARCD","OPERATING MIC":"XNYS","NAME-INSTITUTION DESCRIPTION":"ARCA DARK","ACRONYM":"ARCADARK","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ARCO","OPERATING MIC":"XNYS","NAME-INSTITUTION DESCRIPTION":"NYSE ARCA OPTIONS","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"ARCX","OPERATING MIC":"XNYS","NAME-INSTITUTION DESCRIPTION":"NYSE ARCA","ACRONYM":"NYSE","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"NYSD","OPERATING MIC":"XNYS","NAME-INSTITUTION DESCRIPTION":"NYSE DARK","ACRONYM":"NYSEDARK","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XASE","OPERATING MIC":"XNYS","NAME-INSTITUTION DESCRIPTION":"NYSE MKT LLC","ACRONYM":"AMEX","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XNLI","OPERATING MIC":"XNYS","NAME-INSTITUTION DESCRIPTION":"NYSE LIFFE","ACRONYM":"","CITY":"NEW YORK"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XOCH","OPERATING MIC":"XOCH","NAME-INSTITUTION DESCRIPTION":"ONECHICAGO, LLC","ACRONYM":"","CITY":"CHICAGO"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XOTC","OPERATING MIC":"XOTC","NAME-INSTITUTION DESCRIPTION":"OTCBB","ACRONYM":"OTCBB","CITY":"WASHINGTON"},
{"COUNTRY":"UNITED STATES OF AMERICA","ISO_COUNTRY_CODE":"US","MIC":"XSEF","OPERATING MIC":"XSEF","NAME-INSTITUTION DESCRIPTION":"SWAPEX, LLC","ACRONYM":"SWAPEX","CITY":"NEW YORK"},
{"COUNTRY":"ZZ","ISO_COUNTRY_CODE":"ZZ","MIC":"XOFF","OPERATING MIC":"XOFF","NAME-INSTITUTION DESCRIPTION":"OFF-EXCHANGE TRANSACTIONS - LISTED INSTRUMENTS","ACRONYM":"XOFF","CITY":"ZZ"},
{"COUNTRY":"ZZ","ISO_COUNTRY_CODE":"ZZ","MIC":"XXXX","OPERATING MIC":"XXXX","NAME-INSTITUTION DESCRIPTION":"NO MARKET (E.G. UNLISTED)","ACRONYM":"","CITY":""}];

module.exports = MICCODE_CONVERSION;
