require('./OKCustomSelect.scss');

// @ vendor
const React = require('react');
const PropTypes = require('prop-types');
const Select = require('react-select').default;
const classnames = require('classnames');
const { formatText, hasTranslation } = require('core/i18n').i18n;

// @ components
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');

class OKCustomSelect extends React.Component {
    render() {
        const { errorState, errorMessage, label, disabled, openUp, tooltipMsg, subLabelElement, optionalElement,
            tooltipOffSet } = this.props;
        const labelClass = this.props.showLabel ? 'ok-select__label' : 'ok-select__label-invisible';
        let labelElement, finalLabel, elementMessage, tooltipElement, optionalElementContent, appliedTooltipOffSet, subLabelElementContent;

        if (errorState) {
            elementMessage = (
                <div className="ok-select__error-msg">
                    {errorMessage}
                </div>
            );
        }

        if (label) {
            if (hasTranslation(label)) {
                finalLabel = formatText(label);
            } else {
                finalLabel = label;
            }

            if (tooltipMsg) {
                appliedTooltipOffSet = !!tooltipOffSet ? tooltipOffSet : [0, -15];

                tooltipElement = (
                    <OKTooltip
                        content={tooltipMsg}
                        offset={appliedTooltipOffSet}
                    />
                );
            }

            labelElement = (
                <label className={labelClass}>
                    {finalLabel}
                </label>
            );
        }

        if (optionalElement) {
            optionalElementContent = (
                <span className="ok-select__optional-element">
                    {optionalElement}
                </span>
            );
        }

        if (subLabelElement) {
            subLabelElementContent = (
                <div className="ok-select__sub-label-element">
                    {subLabelElement}
                </div>
            );
        }

        const classNames = classnames('ok-select', {
            'ok-select--error': errorState,
            'ok-select--open-up': openUp
        });

        return (
            <div className={classNames}>
                {labelElement}
                {tooltipElement}
                {optionalElementContent}
                {subLabelElementContent}
                <Select
                    {...this.props}
                    clearable={false}
                    arrowRenderer={() => (<i className="ok-select__arrow icon-dropdown-abrir" />)}
                    disabled={disabled}
                />
                {elementMessage}
            </div>
        );
    }
}

OKCustomSelect.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    errorState: PropTypes.bool,
    errorMessage: PropTypes.string,
    noResultsText: PropTypes.string,
    showLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    openUp: PropTypes.bool,
    openOnFocus: PropTypes.bool,
    tooltipMsg: PropTypes.any,
    optionalElement: PropTypes.object,
    subLabelElement: PropTypes.object,
    tooltipOffSet: PropTypes.array
};

OKCustomSelect.defaultProps = {
    clearable: false,
    placeholder: formatText('common-selectPlaceholder'),
    noResultsText: formatText('common-selectNoResults'),
    showLabel: true,
    disabled: false,
    openUp: false,
    subLabelElement: null
};

module.exports = OKCustomSelect;
