const PROVINCES = {
    '15': 'A Coruña',
    '01': 'Alava',
    '02': 'Albacete',
    '03': 'Alicante',
    '04': 'Almería',
    '33': 'Asturias',
    '05': 'Ávila',
    '06': 'Badajoz',
    '07': 'Baleares',
    '08': 'Barcelona',
    '09': 'Burgos',
    '10': 'Caceres',
    '11': 'Cádiz',
    '39': 'Cantabria',
    '12': 'Castellón',
    '51': 'Ceuta',
    '13': 'Ciudad Real',
    '14': 'Córdoba',
    '16': 'Cuenca',
    '17': 'Girona',
    '18': 'Granada',
    '19': 'Guadalajara',
    '20': 'Guipuzcoa',
    '21': 'Huelva',
    '22': 'Huesca',
    '23': 'Jaén',
    '26': 'La Rioja',
    '35': 'Las Palmas',
    '24': 'León',
    '25': 'Lérida',
    '27': 'Lugo',
    '28': 'Madrid',
    '29': 'Malaga',
    '52': 'Melilla',
    '30': 'Murcia',
    '31': 'Navarra',
    '32': 'Ourense',
    '34': 'Palencia',
    '36': 'Pontevedra',
    '37': 'Salamanca',
    '38': 'Santa Cruz De Tenerife',
    '40': 'Segovia',
    '41': 'Sevilla',
    '42': 'Soria',
    '43': 'Tarragona',
    '44': 'Teruel',
    '45': 'Toledo',
    '46': 'Valencia',
    '47': 'Valladolid',
    '48': 'Vizcaya',
    '49': 'Zamora',
    '50': 'Zaragoza'
};
module.exports = PROVINCES;
