require('./wizardProgressIndicator.scss');

const PropTypes = require('prop-types');

// @ vendors
const React = require('react');
const classnames = require('classnames');

class WizardProgressIndicator extends React.Component {
    constructor(props) {
        super(props);

        this.buildProgressIndicator = this.buildProgressIndicator.bind(this);
    }

    buildProgressIndicator() {
        const { immSteps, visibleStep, stepsAmount } = this.props;
        const width = (100 / stepsAmount) + '%';

        return immSteps.map( (step, index) => {
            const itemIndex = index + 1;
            const active = visibleStep === itemIndex;
            // (andres): We need to make sure that a stemp don't look as 'valid'
            // if it's greater than the current step.
            // For example, if the user completes the step one and does to step 2,
            // we have to show step 1 as valid and complete. But, if the user
            // returns to step 1, even though the step may still be valid, visually
            // we have to show the progress indicator as if it's not. By making sure
            // that we don't show any step past the current step as complete, we're good.
            const valid = itemIndex < visibleStep && !!step.get('valid');
            const progressClassName = classnames(
                'wizard-progress-indicator__progress',
                {
                    'wizard-progress-indicator__progress--active' : active,
                    'wizard-progress-indicator__progress--valid' : valid
                }
            );
            const progressItemClassName = classnames(
                'wizard-progress-indicator__item',
                {
                    'wizard-progress-indicator__item--active' : active,
                    'wizard-progress-indicator__item--valid' : valid
                }
            );

            return (
                <div key={index} className={progressClassName} style={{width: width}}>
                    <span className={progressItemClassName}>
                        {itemIndex}
                    </span>
                </div>
            )

        });
    }

    render() {
        const progressIndicator = this.buildProgressIndicator();

        return (
            <header className="wizard-progress-indicator">
                {progressIndicator}
            </header>
        )
    }
}

WizardProgressIndicator.propTypes = {
    visibleStep: PropTypes.number.isRequired,
    stepsAmount: PropTypes.number.isRequired,
    immSteps: PropTypes.object.isRequired
};

module.exports = WizardProgressIndicator;
