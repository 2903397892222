// @ vendor
const React = require('react');
const { withRouter } = require('react-router-dom');
const PropTypes = require('prop-types');
// @ commons
const { OKButton: OKCommonButton } = require('commonsComponents/OKButton');

class OKButtonClass extends React.Component {
    render() {
        return (
            <OKCommonButton
                routerPush={this.props.history.push}
                routerReplace={this.props.history.replace}
                {...this.props}
            />
        );
    }
}

OKButtonClass.propTypes = {
    history: PropTypes.object.isRequired,
}

const OKButton = withRouter(OKButtonClass);
module.exports = { OKButton };
