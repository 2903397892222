require('./OKTableRowDetails.scss');

const PropTypes = require('prop-types');

// @ vendors
const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const classnames = require('classnames');
// @ helpers
const { formatText, hasTranslation } = require('core/i18n').i18n;
const isArray = require('lodash/lang/isArray');
// @ constants
const MAX_COLUMNS = 12;

class OKTableRowDetails extends React.Component {

    constructor(props) {
        super(props);

        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    render () {
        const { title, children, columns, isMobile } = this.props;
        const bootstrapColumn = MAX_COLUMNS/columns;
        const listClassNames = classnames({
            'ok-table-row-details__list': !isMobile,
        }, 'list-unstyled');

        let columnElements = [];
        let titleContent;
        let finalTitle;

        if (!!title){
            if (hasTranslation(title)){
                finalTitle = formatText(title);
            } else {
                finalTitle = title;
            }
            titleContent = (<h3 className="ok-table-row-details__title">{finalTitle}</h3>);
        }

        if (isArray(children)){
            let count = 0;
            children.forEach((child) => {

                if(!!child) {
                    const offset = count % columns;
                    if (!columnElements[offset]) {
                        columnElements[offset] = [];
                    }
                    columnElements[offset].push(child);
                    count++;
                }
            });
        } else {
            columnElements[0] = [children];
        }

        columnElements = columnElements.map((column, index) => {
            const className = `col-xs-${bootstrapColumn}`;
            const key = `col-${index}`;

            return (
                <div className={className} key={key}>
                    <ul className="list-unstyled">
                        {column}
                    </ul>
                </div>
            )
        });

        return (
            <div className="ok-table-row-details">
                {titleContent}
                <div className={listClassNames}>
                    <div className="row no-outside-gutter">
                        {columnElements}
                    </div>
                </div>
            </div>
        );
    }
}

OKTableRowDetails.propTypes = {
    isMobile: PropTypes.bool,
    title: PropTypes.string,
    columns: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ])
};

OKTableRowDetails.defaultProps = {
    isMobile: false,
    title: '',
    columns: 1,
    children: []
};

module.exports = OKTableRowDetails;
