const TYPES = {
    DEPOSIT: 'H',
    EXPENSE: 'D',
    ALL: '000'
}

const CONCEPTS = [
    {
        key: TYPES.ALL,
        label: 'movementTypes-conceptAll',
        type: TYPES.ALL
    },
    {
        key: '035',
        label: 'movementTypes-conceptDeposit',
        type: TYPES.DEPOSIT
    },
    {
        key: '036',
        label: 'movementTypes-conceptExpense',
        type: TYPES.EXPENSE
    },
    {
        key: '043',
        label: 'movementTypes-conceptDepositCash',
        type: TYPES.DEPOSIT
    },
    {
        key: '044',
        label: 'movementTypes-conceptExpenseCash',
        type: TYPES.EXPENSE
    },
    {
        key: '071',
        label: 'movementTypes-conceptWireReceived',
        type: TYPES.DEPOSIT
    },
    {
        key: '072',
        label: 'movementTypes-conceptWireEmitted',
        type: TYPES.EXPENSE
    },
    {
        key: '074',
        label: 'movementTypes-conceptDocumentCharge',
        type: TYPES.EXPENSE
    },
    {
        key: '174',
        label: 'movementTypes-conceptReceiptsCharge',
        type: TYPES.EXPENSE
    }
]

const CONCEPTS_PT = [
    {
        key: '071',
        label: 'movementTypes-conceptWireReceived',
        type: TYPES.DEPOSIT
    },
    {
        key: '072',
        label: 'movementTypes-conceptWireEmitted',
        type: TYPES.EXPENSE
    },
    {
        key: '074',
        label: 'movementTypes-conceptDocumentCharge',
        type: TYPES.EXPENSE
    },
    {
        key: '174',
        label: 'movementTypes-conceptReceiptsCharge',
        type: TYPES.EXPENSE
    }
]

module.exports = {
    TYPES,
    CONCEPTS,
    CONCEPTS_PT
}
