require('./OKHeaderLanding.scss');

const PropTypes = require('prop-types');

const React = require('react');
const classNames = require('classnames');

//@commons
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');
const { OKBreadcrumb } = require('commonsComponents/OKBreadcrumb');

const { contactCenterClassName } = require('utilities/conditionals/conditionalClassname');

class OKHeaderLanding extends React.Component {
  buildTitleWithTooltip() {
    const { title } = this.props;
    const target = <span>{title}</span>;
    return (
      <OKTooltip
        content={title.toString()}
        modifier="dark-bg"
        offset={[10, 0]}
        placement="right"
        target={target}
        type="descriptive"
      />
    );
  }

  render() {
    const { children, tooltipContent, inputClassName, breadcrumbConfig } = this.props;
    const divClassName = 'row ok-header-landing ' + inputClassName;
    let { title, tutorial } = this.props;

    if (tooltipContent) {
      title = this.buildTitleWithTooltip();
    }

    const finalClassTitleSection = classNames(
      'ok-header-landing__title-section',
      'col-xs-12-cc-6',
      {
        'col-sm-5': Boolean(children) && !__CONTACT_CENTER__,
        'col-sm-12': !Boolean(children) && !__CONTACT_CENTER__,
        'hidden-xs': Boolean(children) && __CONTACT_CENTER__,
      }
    );

    const finalClassTitleClassName = contactCenterClassName(
      'col-xs-12',
      'ok-header-landing__title'
    );
    const finalClassBreadcrumbClassName = contactCenterClassName(
      'col-xs-12',
      'ok-header-landing__breadcrumb-section'
    );

    return (
      <div className={divClassName}>
        <div className={finalClassTitleSection}>
          {breadcrumbConfig && (
            <div className={finalClassBreadcrumbClassName}>
              <OKBreadcrumb mapping={breadcrumbConfig} />
            </div>
          )}
          <h1 className={finalClassTitleClassName}>
            {title}
            {tutorial}
          </h1>
        </div>
        <div className="col-xs-12 col-sm-7 ok-header-landing__buttons-section">{children}</div>
      </div>
    );
  }
}

OKHeaderLanding.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  tutorial: PropTypes.element,
  children: PropTypes.any,
  inputClassName: PropTypes.string,
  tooltipContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  breadcrumbConfig: PropTypes.array,
};

module.exports = OKHeaderLanding;
