// @vendor
const { sortByOrder } = require('lodash/collection');

// @core
const { formatText } = require('core/i18n').i18n;


const TYPEOFROADS = [
    'AA',
    'AE',
    'AW',
    'AL',
    'AD',
    'DD',
    'AG',
    'AC',
    'AP',
    'AX',
    'AR',
    'AT',
    'AU',
    'AV',
    'BX',
    'BA',
    'BD',
    'BO',
    'BL',
    'BU',
    'CL',
    'CJ',
    'CZ',
    'VJ',
    'CM',
    'CP',
    'CÑ',
    'DN',
    'IA',
    'CQ',
    'CA',
    'CR',
    'CI',
    'CS',
    'CV',
    'IB',
    'CE',
    'CH',
    'CG',
    'CO',
    'CW',
    'ON',
    'IC',
    'CD',
    'ID',
    'CU',
    'CN',
    'CT',
    'ZZ',
    'DP',
    'DS',
    'ED',
    'EN',
    'EC',
    'ES',
    'EX',
    'EM',
    'EB',
    'FC',
    'FN',
    'GL',
    'GV',
    'GR',
    'HT',
    'JR',
    'LD',
    'LG',
    'MZ',
    'MS',
    'BT',
    'MC',
    'MT',
    'ML',
    'MN',
    'OT',
    'PC',
    'PQ',
    'PA',
    'PO',
    'MA',
    'PJ',
    'PS',
    'BB',
    'PY',
    'PZ',
    'PE',
    'PU',
    'PB',
    'PI',
    'PG',
    'PD',
    'SO',
    'PP',
    'AK',
    'IG',
    'PL',
    'IF',
    'PR',
    'PN',
    'PT',
    'QT',
    'RM',
    'RB',
    'RE',
    'RS',
    'RI',
    'RC',
    'RD',
    'IJ',
    'SL',
    'SC',
    'SE',
    'SR',
    'SD',
    'TN',
    'TO',
    'TR',
    'UR',
    'VR',
    'VP',
    'VI',
    'VL'
]

const getTranslatedRoad = (code) => {
    return formatText(`typeOfRoad-typeOfRoad_${code}`);
}

const sortedTypesOfRoadsOptions= () => {
    return sortByOrder(
        TYPEOFROADS.map(key => ({
                value: key,
                label: getTranslatedRoad(key)
            })
        ),
        ['label'],
        ['asc']
    );
}

const typesOfRoadsOptions = () => {
    return TYPEOFROADS.map(key => {
        return {
            value: key,
            label: getTranslatedRoad(key)
        }
    });
}

module.exports = {
    TYPEOFROADS,
    getTranslatedRoad,
    sortedTypesOfRoadsOptions,
    typesOfRoadsOptions,
}

