require('./OKCalculationWaffer.scss');

const PropTypes = require('prop-types');
const classNames = require('classnames');

//@vendor
const React = require('react');
// @commons
const OKInformativeListItem = require('components/rebrand/OKInformativeListItem/OKInformativeListItem');

class OKCalculationWaffer extends React.Component {
    renderElements() {
        return this.props.itemsList.map((item, index) => {
            const key = 'k-calculation-waffer' + index;
            return (
                <OKInformativeListItem
                    {...item}
                    compact
                    icon={!!item.information}
                    key={key}
                />
            );
        })
    }

    render() {
        const { className } = this.props;
        const elements = this.renderElements();
        const css = classNames('ok-calculation-waffer', className);

        return (
            <section className={css}>
                <ul className="ok-calculation-waffer__list">
                    {elements}
                </ul>
                <div className="ok-calculation-waffer__outstanded-section">
                    <div className="ok-calculation-waffer__outstanded-content">
                        <div className="ok-calculation-waffer__outstanded-label">
                            {this.props.outstandedLabel}
                        </div>
                        <div className="ok-calculation-waffer__outstanded-text">
                            {this.props.outstandedText}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

OKCalculationWaffer.propTypes = {
    className: PropTypes.string,
    itemsList: PropTypes.array,
    outstandedLabel: PropTypes.string,
    outstandedText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

OKCalculationWaffer.defaultProps = {
    itemsList: []
};

module.exports = OKCalculationWaffer;
