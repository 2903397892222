module.exports = {
    REGISTER_LOGIN_FAILURE: 'REGISTER_LOGIN_FAILURE',

    USER_PHONE_SUCCESS : 'USER_PHONE_SUCCESS',
    USER_DATA_REQUEST : 'USER_DATA_REQUEST',
    USER_DATA_FAILURE : 'USER_DATA_FAILURE',
    USER_DATA_RESET : 'USER_DATA_RESET',
    USER_DATA_SET_STEP : 'USER_DATA_SET_STEP',

    CODE_OTP_SUCCESS : 'CODE_OTP_SUCCESS',
    CODE_OTP_DATA_REQUEST : 'CODE_OTP_DATA_REQUEST',
    CODE_OTP_DATA_FAILURE : 'CODE_OTP_DATA_FAILURE',
    CODE_OTP_DATA_RESET: 'CODE_OTP_DATA_RESET',
    CODE_OTP_ERROR_RESET : 'CODE_OTP_ERROR_RESET',

    CANCEL_CARD_PAY_LATER_SET_VISIBLE_STEP : 'CANCEL_CARD_PAY_LATER_SET_VISIBLE_STEP',
    CANCEL_CARD_PAY_LATER_VALIDATE_STEP : 'CANCEL_CARD_PAY_LATER_VALIDATE_STEP',
    CANCEL_CARD_PAY_LATER_SET_STEP_SUCCESS : 'CANCEL_CARD_PAY_LATER_SET_STEP_SUCCESS',
    CANCEL_CARD_PAY_LATER_INFORMATION_SUCCESS :'CANCEL_CARD_PAY_LATER_INFORMATION_SUCCESS',
    CANCEL_CARD_PAY_LATER_SET_STEP_ERROR : 'CANCEL_CARD_PAY_LATER_SET_STEP_ERROR',
    CANCEL_CARD_PAY_LATER_WILL_CANCEL : 'CANCEL_CARD_PAY_LATER_WILL_CANCEL',
    CANCEL_CARD_PAY_LATER_STEP_RESET : 'CANCEL_CARD_PAY_LATER_STEP_RESET',
    CANCEL_CARD_PAY_LATER_WILL_LEAVE : 'CANCEL_CARD_PAY_LATER_WILL_LEAVE',
    CANCEL_CARD_PAY_LATER_INFORMATION_REQUEST : 'CANCEL_CARD_PAY_LATER_INFORMATION_REQUEST',

    CARDS_CONTROL_HISTORY_QUERY_FAILURE: 'CARDS_CONTROL_HISTORY_QUERY_FAILURE',
    CARDS_CONTROL_HISTORY_QUERY_REQUEST: 'CARDS_CONTROL_HISTORY_QUERY_REQUEST',
    CARDS_CONTROL_HISTORY_QUERY_SUCCESS: 'CARDS_CONTROL_HISTORY_QUERY_SUCCESS',
    CARDS_CONTROL_HISTORY_RESET: 'CARDS_CONTROL_HISTORY_RESET',
    CARDS_CONTROL_HISTORY_SET_DATE_FROM: 'CARDS_CONTROL_HISTORY_SET_DATE_FROM',
    CARDS_CONTROL_HISTORY_SET_DATE_TO: 'CARDS_CONTROL_HISTORY_SET_DATE_TO',

    CARD_HISTORY_REQUEST_IN_PROGRESS: 'CARD_HISTORY_REQUEST_IN_PROGRESS',
    CARD_HISTORY_REQUEST_SUCCESS: 'CARD_HISTORY_REQUEST_SUCCESS',
    CARD_HISTORY_REQUEST_FAILURE: 'CARD_HISTORY_REQUEST_FAILURE',
    CARD_HISTORY_SET_VALUE: 'CARD_HISTORY_SET_VALUE',

    RECOVERY_KEY_SUCCESS: 'RECOVERY_KEY_SUCCESS',
    RECOVERY_KEYS_DATA_REQUEST : 'RECOVERY_KEYS_DATA_REQUEST',
    RECOVERY_KEYS_DATA_FAILURE : 'RECOVERY_KEYS_DATA_FAILURE',
    RECOVERY_KEYS_DATA_RESET : 'RECOVERY_KEYS_DATA_RESET',
    RECOVERY_KEYS_DATA_ERROR : 'RECOVERY_KEYS_DATA_ERROR',
    RECOVERY_KEYS_SET_VISIBLE_STEP: 'RECOVERY_KEYS_SET_VISIBLE_STEP',

    EARLY_IMPOSITION_CANCEL_SET_VISIBLE_STEP: 'EARLY_IMPOSITION_CANCEL_SET_VISIBLE_STEP',
    EARLY_IMPOSITION_CANCEL_VALIDATE: 'EARLY_IMPOSITION_CANCEL_VALIDATE',
    EARLY_CANCEL_IMPOSITION_SHOW_CANCEL_MODAL: 'EARLY_CANCEL_IMPOSITION_SHOW_CANCEL_MODAL',

    DEPOSIT_RENOVATION_CANCEL_SET_VISIBLE_STEP: 'DEPOSIT_RENOVATION_CANCEL_SET_VISIBLE_STEP',
    DEPOSIT_RENOVATION_CANCEL_VALIDATE: 'DEPOSIT_RENOVATION_CANCEL_VALIDATE',
    DEPOSIT_RENOVATION_SHOW_CANCEL_MODAL: 'DEPOSIT_RENOVATION_SHOW_CANCEL_MODAL',

    FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_SUCCESS: 'FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_SUCCESS',
    FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_REQUEST: 'FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_REQUEST',
    FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_FAILURE: 'FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_FAILURE',

    FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_SUCCESS: 'FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_SUCCESS',
    FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_REQUEST: 'FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_REQUEST',
    FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_FAILURE: 'FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_FAILURE',

    FETCH_DEVICES_CC_OPEN_MODAL_BLOCK: 'FETCH_DEVICES_CC_OPEN_MODAL_BLOCK',
    FETCH_DEVICES_CC_OPEN_MODAL_HIDE: 'FETCH_DEVICES_CC_OPEN_MODAL_HIDE',
    FETCH_DEVICES_CC_OPEN_MODAL_SHOW: 'FETCH_DEVICES_CC_OPEN_MODAL_SHOW',
    FETCH_DEVICES_CC_OPEN_MODAL_UNBLOCK: 'FETCH_DEVICES_CC_OPEN_MODAL_UNBLOCK',
    MODIFY_DEVICES_CC_ALIAS: 'MODIFY_DEVICES_CC_ALIAS',

    BROKER_GET_CONTRACT_STATUS_REQUEST: 'BROKER_GET_CONTRACT_STATUS_REQUEST',
    BROKER_GET_CONTRACT_STATUS_SUCCESS: 'BROKER_GET_CONTRACT_STATUS_SUCCESS',
    BROKER_GET_CONTRACT_STATUS_FAILURE: 'BROKER_GET_CONTRACT_STATUS_FAILURE',
    BROKER_GET_CONTRACT_RESET: 'BROKER_GET_CONTRACT_RESET',

    EARLY_CANCEL_IMPOSITION_SUCCESS: 'EARLY_CANCEL_IMPOSITION_SUCCESS',
    EARLY_CANCEL_IMPOSITION_REQUEST: 'EARLY_CANCEL_IMPOSITION_REQUEST',
    EARLY_CANCEL_IMPOSITION_FAILURE: 'EARLY_CANCEL_IMPOSITION_FAILURE',

    DEPOSIT_RENOVATION_TO_CANCEL_SUCCESS: 'DEPOSIT_RENOVATION_TO_CANCEL_SUCCESS',
    DEPOSIT_RENOVATION_TO_CANCEL_REQUEST: 'DEPOSIT_RENOVATION_TO_CANCEL_REQUEST',
    DEPOSIT_RENOVATION_TO_CANCEL_FAILURE: 'DEPOSIT_RENOVATION_TO_CANCEL_FAILURE',

    CARD_DATA_SUCCESS: 'CARD_DATA_SUCCESS',
    CARD_DATA_REQUEST: 'CARD_DATA_REQUEST',
    CARD_DATA_FAILURE: 'CARD_DATA_FAILURE',

    RENEW_IMPOSITION_INTEREST_REQUEST: 'RENEW_IMPOSITION_INTEREST_REQUEST',
    RENEW_IMPOSITION_INTEREST_FAILURE: 'RENEW_IMPOSITION_INTEREST_FAILURE',
    RENEW_IMPOSITION_INTEREST_SUCCESS: 'RENEW_IMPOSITION_INTEREST_SUCCESS',
    RENEW_IMPOSITION_INTEREST_RESET: 'RENEW_IMPOSITION_INTEREST_RESET',
    RENEW_IMPOSITION_INTEREST_SET_VISIBLE_STEP: 'RENEW_IMPOSITION_INTEREST_SET_VISIBLE_STEP',
    RENEW_IMPOSITION_INTEREST_VALIDATE: 'RENEW_IMPOSITION_INTEREST_VALIDATE',
    RENEW_IMPOSITION_INTEREST_SHOW_CANCEL_MODAL: 'RENEW_IMPOSITION_INTEREST_SHOW_CANCEL_MODAL',

    LOG_AS_CLIENT_SUCCESS: 'LOG_AS_CLIENT_SUCCESS',
    LOG_AS_CLIENT_REQUEST: 'LOG_AS_CLIENT_REQUEST',
    LOG_AS_CLIENT_FAILURE: 'LOG_AS_CLIENT_FAILURE',
    LOG_AS_CLIENT_PASSWORD_ERROR: 'LOG_AS_CLIENT_PASSWORD_ERROR',
    LOG_AS_CLIENT_PASSWORD_RESET: 'LOG_AS_CLIENT_PASSWORD_RESET',

    SEND_N1_PASSWORD_SET_STEP_SUCCESS:'SEND_N1_PASSWORD_SET_STEP_SUCCESS',
    SEND_N1_PASSWORD_SET_STEP_ERROR:'SEND_N1_PASSWORD_SET_STEP_ERROR',

    SEND_N2_PASSWORD_SET_STEP_SUCCESS:'SEND_N2_PASSWORD_SET_STEP_SUCCESS',
    SEND_N2_PASSWORD_SET_STEP_ERROR:'SEND_N2_PASSWORD_SET_STEP_ERROR',
    RESET_SEND_N2_PASSWORD_STEP1: 'RESET_SEND_N2_PASSWORD_STEP1',

    CHANGE_RECEIPT_ACCOUNT_RESET: 'CHANGE_RECEIPT_ACCOUNT_RESET',

    ACCOUNT_BENEFITS_DATA_SUCCESS: 'ACCOUNT_BENEFITS_DATA_SUCCESS',
    ACCOUNT_BENEFITS_DATA_REQUEST: 'ACCOUNT_BENEFITS_DATA_REQUEST',
    ACCOUNT_BENEFITS_DATA_FAILURE: 'ACCOUNT_BENEFITS_DATA_FAILURE',

    ACCOUNT_WARNING_MESSAGE_REQUEST_IN_PROGRESS: 'ACCOUNT_WARNING_MESSAGE_REQUEST_IN_PROGRESS',
    ACCOUNT_WARNING_MESSAGE_REQUEST_SUCCESS: 'ACCOUNT_WARNING_MESSAGE_REQUEST_SUCCESS',
    ACCOUNT_WARNING_MESSAGE_REQUEST_FAILURE: 'ACCOUNT_WARNING_MESSAGE_REQUEST_FAILURE',

    BIZUM_CANCEL_QUERY_CONTRACT_START: 'BIZUM_CANCEL_QUERY_CONTRACT_START',
    BIZUM_CANCEL_QUERY_CONTRACT_SUCCESS: 'BIZUM_CANCEL_QUERY_CONTRACT_SUCCESS',
    BIZUM_CANCEL_QUERY_CONTRACT_FAILURE: 'BIZUM_CANCEL_QUERY_CONTRACT_FAILURE',
    BIZUM_CANCEL_VALIDATE_STEP: 'BIZUM_CANCEL_VALIDATE_STEP',
    BIZUM_CANCEL_SET_VISIBLE_STEP: 'BIZUM_CANCEL_SET_VISIBLE_STEP',
    BIZUM_CANCEL_SAVE_START: 'BIZUM_CANCEL_SAVE_START',
    BIZUM_CANCEL_SAVE_SUCCESS: 'BIZUM_CANCEL_SAVE_SUCCESS',
    BIZUM_CANCEL_SAVE_FAILURE: 'BIZUM_CANCEL_SAVE_FAILURE',
    BIZUM_CANCEL_INVALID_SECURITY_DATA_ERROR: 'BIZUM_CANCEL_INVALID_SECURITY_DATA_ERROR',
    BIZUM_CANCEL_CLEAR_REQUEST: 'BIZUM_CANCEL_CLEAR_REQUEST',

    BIZUM_REGISTER_SET_VISIBLE_STEP : 'BIZUM_REGISTER__VALIDATE_STEP',
    BIZUM_REGISTER__VALIDATE_STEP: 'BIZUM_REGISTER__VALIDATE_STEP',
    BIZUM_REGISTER__SET_ACCOUNT: 'BIZUM_REGISTER__SET_ACCOUNT',
    BIZUM_REGISTER__SET_TERMS : 'BIZUM_REGISTER__SET_TERMS',
    BIZUM_REGISTER__VALIDATE_UI_STEP : 'BIZUM_REGISTER__VALIDATE_UI_STEP',
    BIZUM_REGISTER__SET_VISIBLE_STEP : 'BIZUM_REGISTER__SET_VISIBLE_STEP',
    BIZUM_REGISTER_TERMS_CONDITIONS_SUCCESS : 'BIZUM_REGISTER_TERMS_CONDITIONS_SUCCESS',
    BIZUM_REGISTER_TERMS_CONDITIONS_FAILURE : 'BIZUM_REGISTER_TERMS_CONDITIONS_FAILURE',
    BIZUM_REGISTER_TERMS_CONDITIONS_REQUEST : 'BIZUM_REGISTER_TERMS_CONDITIONS_REQUEST',
    BIZUM_REGISTER_TERMS_CONDITIONS_VALIDATE_UI_STEP : 'BIZUM_REGISTER_TERMS_CONDITIONS_VALIDATE_UI_STEP',
    BIZUM_REGISTER__SET_TERMS_CONDITIONS : 'BIZUM_REGISTER__SET_TERMS_CONDITIONS',
    BIZUM_AUTHORIZE_SAVE_START: 'BIZUM_AUTHORIZE_SAVE_START',
    BIZUM_AUTHORIZE_SAVE_SUCCESS: 'BIZUM_AUTHORIZE_SAVE_SUCCESS',
    BIZUM_REGISTER_WITH_PORTABILITY: 'BIZUM_REGISTER_WITH_PORTABILITY',
    BIZUM_REGISTER_WITH_PORTABILITY_RESEND: 'BIZUM_REGISTER_WITH_PORTABILITY_RESEND',
    BIZUM_AUTHORIZE_SAVE_FAILURE: 'BIZUM_AUTHORIZE_SAVE_FAILURE',
    BIZUM_AUTHORIZE_INVALID_SECURITY_DATA_ERROR: 'BIZUM_AUTHORIZE_INVALID_SECURITY_DATA_ERROR',
    BIZUM_AUTHORIZE_VALID_SECURITY_DATA: 'BIZUM_AUTHORIZE_VALID_SECURITY_DATA',
    BIZUM_REGISTER_SET_ERROR: 'BIZUM_REGISTER_SET_ERROR',
    BIZUM_AUTHORIZE_SAVE_RESET : 'BIZUM_AUTHORIZE_SAVE_RESET',

    BIZUM_CONFIRMATION_RESEND_FAILURE: 'BIZUM_CONFIRMATION_RESEND_FAILURE',
    BIZUM_CONFIRMATION_RESEND_REQUEST: 'BIZUM_CONFIRMATION_RESEND_REQUEST',
    BIZUM_CONFIRMATION_RESEND_SUCCESS: 'BIZUM_CONFIRMATION_RESEND_SUCCESS',
    BIZUM_CONFIRMATION_RESET: 'BIZUM_CONFIRMATION_RESET',
    BIZUM_CONFIRMATION_VALIDATE_ERROR: 'BIZUM_CONFIRMATION_VALIDATE_ERROR',
    BIZUM_CONFIRMATION_VALIDATE_FAILURE: 'BIZUM_CONFIRMATION_VALIDATE_FAILURE',
    BIZUM_CONFIRMATION_VALIDATE_REQUEST: 'BIZUM_CONFIRMATION_VALIDATE_REQUEST',
    BIZUM_CONFIRMATION_VALIDATE_SUCCESS: 'BIZUM_CONFIRMATION_VALIDATE_SUCCESS',
    BIZUM_CONFIRMATION_SET_CODE: 'BIZUM_CONFIRMATION_SET_CODE',
    BIZUM_CONFIRMATION_SET_SCREEN: 'BIZUM_CONFIRMATION_SET_SCREEN',
    BIZUM_CONFIRMATION_SHOW_MODAL: 'BIZUM_CONFIRMATION_SHOW_MODAL',

    CHANGE_RECEIPT_ACCOUNT_REQUEST: 'CHANGE_RECEIPT_ACCOUNT_REQUEST',
    CHANGE_RECEIPT_ACCOUNT_SUCCESS: 'CHANGE_RECEIPT_ACCOUNT_SUCCESS',
    CHANGE_RECEIPT_ACCOUNT_FAILURE: 'CHANGE_RECEIPT_ACCOUNT_FAILURE',
    CHANGE_RECEIPT_ACCOUNT_SET_VISIBLE_STEP: 'CHANGE_RECEIPT_ACCOUNT_SET_VISIBLE_STEP',
    CHANGE_RECEIPT_ACCOUNT_VALIDATE_STEP: 'CHANGE_RECEIPT_ACCOUNT_VALIDATE_STEP',
    CHANGE_RECEIPT_ACCOUNT_VALIDATE_UI_STEP: 'CHANGE_RECEIPT_ACCOUNT_VALIDATE_UI_STEP',
    CHANGE_RECEIPT_ACCOUNT_VALIDATION_SUCCESS: 'CHANGE_RECEIPT_ACCOUNT_VALIDATION_SUCCESS',
    CHANGE_RECEIPT_ACCOUNT_VALIDATION_FAILURE: 'CHANGE_RECEIPT_ACCOUNT_VALIDATION_FAILURE',
    CHANGE_RECEIPT_ACCOUNT_SET_DESTINATION_ACCOUNT_ID: 'CHANGE_RECEIPT_ACCOUNT_SET_DESTINATION_ACCOUNT_ID',
    CHANGE_RECEIPT_ACCOUNT_SET_CURRENT_ACCOUNT_ID: 'CHANGE_RECEIPT_ACCOUNT_SET_CURRENT_ACCOUNT_ID',
    CHANGE_RECEIPT_ACCOUNT_SET_RECEIPT: 'CHANGE_RECEIPT_ACCOUNT_SET_RECEIPT',
    CHANGE_RECEIPT_ACCOUNT_WILL_CANCEL: 'CHANGE_RECEIPT_ACCOUNT_WILL_CANCEL',
    CHANGE_RECEIPT_ACCOUNT_SIGNATURE_SUCCESS: 'CHANGE_RECEIPT_ACCOUNT_SIGNATURE_SUCCESS',
    CHANGE_RECEIPT_ACCOUNT_SHOW_CANCEL_MODAL: 'CHANGE_RECEIPT_ACCOUNT_SHOW_CANCEL_MODAL',

    CANCEL_CREDIT_CARD_RENEWAL_DETAILS_REQUEST: 'CANCEL_CREDIT_CARD_RENEWAL_DETAILS_REQUEST',
    CANCEL_CREDIT_CARD_RENEWAL_DETAILS_SUCCESS: 'CANCEL_CREDIT_CARD_RENEWAL_DETAILS_SUCCESS',
    CANCEL_CREDIT_CARD_RENEWAL_DETAILS_FAILURE: 'CANCEL_CREDIT_CARD_RENEWAL_DETAILS_FAILURE',
    CANCEL_CREDIT_CARD_RENEWAL_VALIDATE_STEP: 'CANCEL_CREDIT_CARD_RENEWAL_VALIDATE_STEP',
    CANCEL_CREDIT_CARD_RENEWAL_WILL_CANCEL: 'CANCEL_CREDIT_CARD_RENEWAL_WILL_CANCEL',
    CANCEL_CREDIT_CARD_RENEWAL_SET_VISIBLE_STEP: 'CANCEL_CREDIT_CARD_RENEWAL_SET_VISIBLE_STEP',
    CANCEL_CREDIT_CARD_RENEWAL_RESET: 'CANCEL_CREDIT_CARD_RENEWAL_RESET',
    CANCEL_CREDIT_CARD_RENEWAL_REQUEST: 'CANCEL_CREDIT_CARD_RENEWAL_REQUEST',
    CANCEL_CREDIT_CARD_RENEWAL_SUCCESS: 'CANCEL_CREDIT_CARD_RENEWAL_SUCCESS',
    CANCEL_CREDIT_CARD_RENEWAL_FAILURE: 'CANCEL_CREDIT_CARD_RENEWAL_FAILURE',

    CUSTOMER_FINDER_CLEAR_REQUEST: 'CUSTOMER_FINDER_CLEAR_REQUEST',
    CUSTOMER_FINDER_SEARCH_CLIENT_FETCHING: 'CUSTOMER_FINDER_SEARCH_CLIENT_FETCHING',
    CUSTOMER_FINDER_SEARCH_CLIENT_UPDATE_COUNTRY: 'CUSTOMER_FINDER_SEARCH_CLIENT_UPDATE_COUNTRY',
    CUSTOMER_FINDER_SEARCH_CLIENT_SUCCESS: 'CUSTOMER_FINDER_SEARCH_CLIENT_SUCCESS',
    CUSTOMER_FINDER_SEARCH_CLIENT_FAILURE: 'CUSTOMER_FINDER_SEARCH_CLIENT_FAILURE',
    CUSTOMER_FINDER_SEARCH_CLEAN_ERROR_MESSAGE : 'CUSTOMER_FINDER_SEARCH_CLEAN_ERROR_MESSAGE',
    CUSTOMER_FINDER_SEARCH_EMPTY_DOCUMENT: 'CUSTOMER_FINDER_SEARCH_EMPTY_DOCUMENT',
    CUSTOMER_FINDER_SEARCH_UNRECOVERABLE_ERROR: 'CUSTOMER_FINDER_SEARCH_UNRECOVERABLE_ERROR',
    CUSTOMER_FINDER_SAVE_INTERACTION_ID: 'CUSTOMER_FINDER_SAVE_INTERACTION_ID',
    CUSTOMER_FINDER_SET_INTERACTION_ID_ERROR_MESSAGE: 'CUSTOMER_FINDER_SET_INTERACTION_ID_ERROR_MESSAGE',
    CUSTOMER_FINDER_VALIDATE_WITH_API_START: 'CUSTOMER_FINDER_VALIDATE_WITH_API_START',
    CUSTOMER_FINDER_VALIDATE_WITH_API_SUCCESS: 'CUSTOMER_FINDER_VALIDATE_WITH_API_SUCCESS',
    CUSTOMER_FINDER_SET_PASSWORD_ERROR: 'CUSTOMER_FINDER_SET_PASSWORD_ERROR',
    CUSTOMER_FINDER_AUTHENTICATION_REQUEST_START: 'CUSTOMER_FINDER_AUTHENTICATION_REQUEST_START',
    CUSTOMER_FINDER_AUTHENTICATION_REQUEST_SUCCESSFUL: 'CUSTOMER_FINDER_AUTHENTICATION_REQUEST_SUCCESSFUL',
    CUSTOMER_FINDER_AUTHENTICATION_REQUEST_ERROR: 'CUSTOMER_FINDER_AUTHENTICATION_REQUEST_ERROR',
    CUSTOMER_FINDER_TRACE_BIND_CLIENT_INTERACTION_REQUEST_START: 'CUSTOMER_FINDER_TRACE_BIND_CLIENT_INTERACTION_REQUEST_START',
    CUSTOMER_FINDER_TRACE_BIND_CLIENT_INTERACTION_REQUEST_SUCCESSFUL: 'CUSTOMER_FINDER_TRACE_BIND_CLIENT_INTERACTION_REQUEST_SUCCESSFUL',
    CUSTOMER_FINDER_TRACE_BIND_CLIENT_INTERACTION_REQUEST_ERROR: 'CUSTOMER_FINDER_TRACE_BIND_CLIENT_INTERACTION_REQUEST_ERROR',
    CUSTOMER_FINDER_TRACE_SECURITY_LEVEL_SET: 'CUSTOMER_FINDER_TRACE_SECURITY_LEVEL_SET',
    CUSTOMER_FINDER_TRACE_GENERATE_REPRESENTATION_TOKEN_REQUEST_START: 'CUSTOMER_FINDER_TRACE_GENERATE_REPRESENTATION_TOKEN_REQUEST_START',
    CUSTOMER_FINDER_TRACE_GENERATE_REPRESENTATION_TOKEN_REQUEST_SUCCESSFUL: 'CUSTOMER_FINDER_TRACE_GENERATE_REPRESENTATION_TOKEN_REQUEST_SUCCESSFUL',
    CUSTOMER_FINDER_TRACE_GENERATE_REPRESENTATION_TOKEN_REQUEST_ERROR: 'CUSTOMER_FINDER_TRACE_GENERATE_REPRESENTATION_TOKEN_REQUEST_ERROR',
    CUSTOMER_FINDER_TRACE_RAISE_TO_N1_REQUEST_START: 'CUSTOMER_FINDER_TRACE_RAISE_TO_N1_REQUEST_START',
    CUSTOMER_FINDER_TRACE_RAISE_TO_N1_REQUEST_SUCCESSFUL: 'CUSTOMER_FINDER_TRACE_RAISE_TO_N1_REQUEST_SUCCESSFUL',
    CUSTOMER_FINDER_TRACE_RAISE_TO_N1_REQUEST_ERROR: 'CUSTOMER_FINDER_TRACE_RAISE_TO_N1_REQUEST_ERROR',
    CUSTOMER_FINDER_AUTHENTICATION_TOKEN_CREDENTIAL: 'CUSTOMER_FINDER_AUTHENTICATION_TOKEN_CREDENTIAL',

    AGENT_AUTHENTICATION_REQUEST: 'AGENT_AUTHENTICATION_REQUEST',
    AGENT_AUTHENTICATION_SUCCESS: 'AGENT_AUTHENTICATION_SUCCESS',
    AGENT_AUTHENTICATION_FAILURE: 'AGENT_AUTHENTICATION_FAILURE',
    AGENT_OFFICE_VALIDATION: 'AGENT_OFFICE_VALIDATION',

    PRE_CONTRACTUAL_MAIL_SENT_SUCCESS:'PRE_CONTRACTUAL_MAIL_SENT_SUCCESS',
    PRE_CONTRACTUAL_MAIL_SENT_FAILURE:'PRE_CONTRACTUAL_MAIL_SENT_FAILURE',
    PRE_CONTRACTUAL_MAIL_SENT_REQUEST:'PRE_CONTRACTUAL_MAIL_SENT_REQUEST',
    PRE_CONTRACTUAL_SET_LEGAL_TEXT_READ: 'PRE_CONTRACTUAL_SET_LEGAL_TEXT_READ',

    HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_REQUEST: 'HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_REQUEST',
    HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_SUCCESS: 'HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_SUCCESS',
    HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_FAILURE: 'HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_FAILURE',

    PRE_CONTRACTUAL_MAIL_RESET:'PRE_CONTRACTUAL_MAIL_RESET',
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_START: 'CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_START',
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_SUCCESS: 'CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_SUCCESS',
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_FAILURE: 'CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_FAILURE',
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SET_AMOUNT: 'CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SET_AMOUNT',
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_VALIDATE_STEP: 'CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_VALIDATE_STEP',
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SET_VISIBLE_STEP: 'CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SET_VISIBLE_STEP',
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SAVE_START: 'CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SAVE_START',
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SAVE_SUCCESS: 'CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SAVE_SUCCESS',
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SAVE_FAILURE: 'CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SAVE_FAILURE',
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_CLEAR: 'CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_CLEAR',

    PASSWORD_POSITIONS_CONFIG_REQUEST: 'PASSWORD_POSITIONS_CONFIG_REQUEST',
    PASSWORD_POSITIONS_CONFIG_REQUEST_SUCCESS: 'PASSWORD_POSITIONS_CONFIG_REQUEST_SUCCESS',
    PASSWORD_POSITIONS_CONFIG_REQUEST_FAILURE: 'PASSWORD_POSITIONS_CONFIG_REQUEST_FAILURE',
    PASSWORD_POSITIONS_RESET: 'PASSWORD_POSITIONS_RESET',

    GAP_ACCESS_SET_OPERATIVE: 'GAP_ACCESS_SET_OPERATIVE',
    GAP_ACCESS_SET_VISIBLE_STEP: 'GAP_ACCESS_SET_VISIBLE_STEP',
    GAP_VALIDATE_STEP: 'GAP_VALIDATE_STEP',
    GAP_RESET: 'GAP_RESET',
    GAP_SECURITY_DATA_ERROR: 'GAP_SECURITY_DATA_ERROR',

    RAISE_TO_N2_REQUEST: 'RAISE_TO_N2_REQUEST',
    RAISE_TO_N2_SUCCESS: 'RAISE_TO_N2_SUCCESS',
    RAISE_TO_N2_FAILURE: 'RAISE_TO_N2_FAILURE',
    RAISE_TO_N2_RESET: 'RAISE_TO_N2_RESET',

    CARD_REQUEST_ADDITIONAL_FETCH_DATA_START: 'CARD_REQUEST_ADDITIONAL_FETCH_DATA_START',
    CARD_REQUEST_ADDITIONAL_FETCH_DATA_SUCCESS: 'CARD_REQUEST_ADDITIONAL_FETCH_DATA_SUCCESS',
    CARD_REQUEST_ADDITIONAL_FETCH_DATA_ERROR: 'CARD_REQUEST_ADDITIONAL_FETCH_DATA_ERROR',
    CARD_REQUEST_ADDITIONAL_SELECT_INTERVENER: 'CARD_REQUEST_ADDITIONAL_SELECT_INTERVENER',
    CARD_REQUEST_ADDITIONAL_VALIDATE_START: 'CARD_REQUEST_ADDITIONAL_VALIDATE_START',
    CARD_REQUEST_ADDITIONAL_VALIDATE_SUCCESS: 'CARD_REQUEST_ADDITIONAL_VALIDATE_SUCCESS',
    CARD_REQUEST_ADDITIONAL_VALIDATE_ERROR: 'CARD_REQUEST_ADDITIONAL_VALIDATE_ERROR',
    CARD_REQUEST_ADDITIONAL_VALIDATE_STEP: 'CARD_REQUEST_ADDITIONAL_VALIDATE_STEP',
    CARD_REQUEST_ADDITIONAL_SET_VISIBLE_STEP: 'CARD_REQUEST_ADDITIONAL_SET_VISIBLE_STEP',
    CARD_REQUEST_ADDITIONAL_CONFIRMATION_START: 'CARD_REQUEST_ADDITIONAL_CONFIRMATION_START',
    CARD_REQUEST_ADDITIONAL_CONFIRMATION_SUCCESS: 'CARD_REQUEST_ADDITIONAL_CONFIRMATION_SUCCESS',
    CARD_REQUEST_ADDITIONAL_CONFIRMATION_ERROR: 'CARD_REQUEST_ADDITIONAL_CONFIRMATION_ERROR',
    CARD_REQUEST_ADDITIONAL_CLEAR: 'CARD_REQUEST_ADDITIONAL_CLEAR',
    CARD_REQUEST_ADDITIONAL_CLEAR_VALIDATION: 'CARD_REQUEST_ADDITIONAL_CLEAR_VALIDATION',

    ADD_DEPOSIT_IMPOSITION_RESET: 'ADD_DEPOSIT_IMPOSITION_RESET',
    ADD_DEPOSIT_IMPOSITION_SET_VISIBLE_STEP: 'ADD_DEPOSIT_IMPOSITION_SET_VISIBLE_STEP',
    ADD_DEPOSIT_IMPOSITION_VALIDATE_STEP: 'ADD_DEPOSIT_IMPOSITION_VALIDATE_STEP',
    ADD_DEPOSIT_IMPOSITION_WILL_CANCEL: 'ADD_DEPOSIT_IMPOSITION_WILL_CANCEL',
    ADD_DEPOSIT_IMPOSITION_SET_CURRENT_VALUE: 'ADD_DEPOSIT_IMPOSITION_SET_CURRENT_VALUE',
    ADD_DEPOSIT_IMPOSITION_REQUEST: 'ADD_DEPOSIT_IMPOSITION_REQUEST',
    ADD_DEPOSIT_IMPOSITION_SUCCESS: 'ADD_DEPOSIT_IMPOSITION_SUCCESS',
    ADD_DEPOSIT_IMPOSITION_FAILURE: 'ADD_DEPOSIT_IMPOSITION_FAILURE',
    ADD_DEPOSIT_IMPOSITION_UPDATE_RENEWAL_PREFERENCE: 'ADD_DEPOSIT_IMPOSITION_UPDATE_RENEWAL_PREFERENCE',

    DEPOSIT_DATA_IMPOSITION_REQUEST: 'DEPOSIT_DATA_IMPOSITION_REQUEST',
    DEPOSIT_DATA_IMPOSITION_SUCCESS: 'DEPOSIT_DATA_IMPOSITION_SUCCESS',
    DEPOSIT_DATA_IMPOSITION_FAILURE: 'DEPOSIT_DATA_IMPOSITION_FAILURE',

    DEPOSIT_VALIDATION_REQUEST: 'DEPOSIT_VALIDATION_REQUEST',
    DEPOSIT_VALIDATION_SUCCESS: 'DEPOSIT_VALIDATION_SUCCESS',
    DEPOSIT_VALIDATION_ERROR: 'DEPOSIT_VALIDATION_ERROR',

    DEPOSIT_CHANGE_ACCOUNT_SET_OPERATIVE: 'DEPOSIT_CHANGE_ACCOUNT_SET_OPERATIVE',
    DEPOSIT_CHANGE_ACCOUNT_SET_VISIBLE_STEP: 'DEPOSIT_CHANGE_ACCOUNT_SET_VISIBLE_STEP',
    DEPOSIT_CHANGE_ACCOUNT_VALIDATE_STEP: 'DEPOSIT_CHANGE_ACCOUNT_VALIDATE_STEP',
    DEPOSIT_CHANGE_ACCOUNT_RESET: 'DEPOSIT_CHANGE_ACCOUNT_RESET',
    DEPOSIT_CHANGE_ACCOUNT_HANDLE_ERROR: 'DEPOSIT_CHANGE_ACCOUNT_HANDLE_ERROR',
    DEPOSIT_CHANGE_ACCOUNT_SET_ASOCIATED_ACCOUNT_ID: 'DEPOSIT_CHANGE_ACCOUNT_SET_ASOCIATED_ACCOUNT_ID',
    DEPOSIT_CHANGE_ACCOUNT_SHOW_CANCEL_MODAL: 'DEPOSIT_CHANGE_ACCOUNT_SHOW_CANCEL_MODAL',
    DEPOSIT_CHANGE_ACCOUNT_SET_DEPOSIT_ID: 'DEPOSIT_CHANGE_ACCOUNT_SET_DEPOSIT_ID',
    FETCH_DEPOSIT_ASOCIATED_ACCOUNT_SUCCESS: 'FETCH_DEPOSIT_ASOCIATED_ACCOUNT_SUCCESS',
    FETCH_DEPOSIT_ASOCIATED_ACCOUNT_FAILURE: 'FETCH_DEPOSIT_ASOCIATED_ACCOUNT_FAILURE',
    FETCH_DEPOSIT_ASOCIATED_ACCOUNT_REQUEST: 'FETCH_DEPOSIT_ASOCIATED_ACCOUNT_REQUEST',
    FETCH_DEPOSIT_MODIFY_ASOCIATED_ACCOUNT_SUCCESS: 'FETCH_DEPOSIT_MODIFY_ASOCIATED_ACCOUNT_SUCCESS',
    FETCH_DEPOSIT_MODIFY_ASOCIATED_ACCOUNT_FAILURE: 'FETCH_DEPOSIT_MODIFY_ASOCIATED_ACCOUNT_FAILURE',

    CARD_PIN_RESET_SET_VISIBLE_STEP: 'CARD_PIN_RESET_SET_VISIBLE_STEP',
    CARD_PIN_RESET_VALIDATE_STEP: 'CARD_PIN_RESET_VALIDATE_STEP',
    CARD_PIN_RESET_RESET_WIZARD: 'CARD_PIN_RESET_RESET_WIZARD',
    CARD_PIN_RESET_SUCCESS: 'CARD_PIN_RESET_SUCCESS',
    CARD_PIN_RESET_ERROR: 'CARD_PIN_RESET_ERROR',

    BROKER_LEGAL_INFORMATION_CONF_EXTERNAL_FUND: 'BROKER_LEGAL_INFORMATION_CONF_EXTERNAL_FUND',
    BROKER_LEGAL_INFORMATION_CONF_EXTERNAL_FUND_RESET: 'BROKER_LEGAL_INFORMATION_CONF_EXTERNAL_FUND_RESET',

    BROKER_WIZARD_INTERNAL_TRANSFER_LEGAL_YES_NO_OPTION_CHANGE: 'BROKER_WIZARD_INTERNAL_TRANSFER_LEGAL_YES_NO_OPTION_CHANGE',
    BROKER_WIZARD_INTERNAL_TRANSFER_LEGAL_INFORMATION_RESET: 'BROKER_WIZARD_INTERNAL_TRANSFER_LEGAL_INFORMATION_RESET',

    FUND_LEGAL_CONFIRMATION_MORNINGSTAR_SECURITY_DETAILS_REQUEST_START: 'FUND_LEGAL_CONFIRMATION_MORNINGSTAR_SECURITY_DETAILS_REQUEST_START',
    FUND_LEGAL_CONFIRMATION_MORNINGSTAR_SECURITY_DETAILS_REQUEST_SUCCESS: 'FUND_LEGAL_CONFIRMATION_MORNINGSTAR_SECURITY_DETAILS_REQUEST_SUCCESS',
    FUND_LEGAL_CONFIRMATION_MORNINGSTAR_SECURITY_DETAILS_REQUEST_ERROR: 'FUND_LEGAL_CONFIRMATION_MORNINGSTAR_SECURITY_DETAILS_REQUEST_ERROR',
    FUND_LEGAL_CONFIRMATION_RESET: 'FUND_LEGAL_CONFIRMATION_RESET',

    MIFID_DOCUMENTS_SEND_MAIL_REQUEST: 'MIFID_DOCUMENTS_SEND_MAIL_REQUEST',
    MIFID_DOCUMENTS_SEND_MAIL_REQUEST_SUCCESS: 'MIFID_DOCUMENTS_SEND_MAIL_REQUEST_SUCCESS',
    MIFID_DOCUMENTS_SEND_MAIL_REQUEST_ERROR: 'MIFID_DOCUMENTS_SEND_MAIL_REQUEST_ERROR',
    MIFID_DOCUMENTS_SEND_MAIL_RESET: 'MIFID_DOCUMENTS_SEND_MAIL_RESET',

    DOCUMENTATION_MIFID_COST_BREAKDOWN_FETCHING: 'DOCUMENTATION_MIFID_COST_BREAKDOWN_FETCHING',
    DOCUMENTATION_MIFID_COST_BREAKDOWN_SUCCESS: 'DOCUMENTATION_MIFID_COST_BREAKDOWN_SUCCESS',

    CREDIT_WARRANTIES_REQUEST: 'CREDIT_WARRANTIES_REQUEST',
    CREDIT_WARRANTIES_SUCCESS: 'CREDIT_WARRANTIES_SUCCESS',
    CREDIT_WARRANTIES_FAILURE: 'CREDIT_WARRANTIES_FAILURE',

    TRANSFER_SET_WEEK_DAY: 'TRANSFER_SET_WEEK_DAY',
    TRANSFER_RESET_WEEK_DAY: 'TRANSFER_RESET_WEEK_DAY',

    TRANSFER_CUSTOM_REPEAT_ADD_MONTH_DAY: 'TRANSFER_CUSTOM_REPEAT_ADD_MONTH_DAY',
    TRANSFER_CUSTOM_REPEAT_REMOVE_MONTH_DAY: 'TRANSFER_CUSTOM_REPEAT_REMOVE_MONTH_DAY',
    TRANSFER_CUSTOM_REPEAT_SET_MONTH_DAY: 'TRANSFER_CUSTOM_REPEAT_SET_MONTH_DAY',
    TRANSFER_SET_PERIODICITY_RANGE_QUANTITY: 'TRANSFER_SET_PERIODICITY_RANGE_QUANTITY',
    TRANSFER_SET_PERIODICITY_RANGE_DESCRIPTION: 'TRANSFER_SET_PERIODICITY_RANGE_DESCRIPTION',
    TRANSFER_SET_PERIODICITY_RANGE_SPECIFICATION: 'TRANSFER_SET_PERIODICITY_RANGE_SPECIFICATION',

    TRANSFER_SET_TRANSFER_TYPE: 'TRANSFER_SET_TRANSFER_TYPE',

    PENSION_PLAN_LEGAL_CONFIRMATION_MORNINGSTAR_INFORMATION_REQUEST_START: 'PENSION_PLAN_LEGAL_CONFIRMATION_MORNINGSTAR_INFORMATION_REQUEST_START',
    PENSION_PLAN_LEGAL_CONFIRMATION_MORNINGSTAR_INFORMATION_REQUEST_SUCCESS: 'PENSION_PLAN_LEGAL_CONFIRMATION_MORNINGSTAR_INFORMATION_REQUEST_SUCCESS',
    PENSION_PLAN_LEGAL_CONFIRMATION_MORNINGSTAR_INFORMATION_REQUEST_ERROR: 'PENSION_PLAN_LEGAL_CONFIRMATION_MORNINGSTAR_INFORMATION_REQUEST_ERROR',
    PENSION_PLAN_LEGAL_CONFIRMATION_RESET: 'PENSION_PLAN_LEGAL_CONFIRMATION_RESET',

    CANCEL_PENSION_PLAN_PENDING_ORDER_REQUEST: 'CANCEL_PENSION_PLAN_PENDING_ORDER_REQUEST',
    CANCEL_PENSION_PLAN_PENDING_ORDER_SUCCESS: 'CANCEL_PENSION_PLAN_PENDING_ORDER_SUCCESS',
    CANCEL_PENSION_PLAN_PENDING_ORDER_FAILURE: 'CANCEL_PENSION_PLAN_PENDING_ORDER_FAILURE',
    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_RESET: 'BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_RESET',
    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_SET_VISIBLE_STEP: 'BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_SET_VISIBLE_STEP',
    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_SHOW_CANCEL_MODAL: 'BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_SHOW_CANCEL_MODAL',
    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_REQUEST: 'BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_REQUEST',
    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_SUCCESS: 'BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_SUCCESS',
    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_FAILURE: 'BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_FAILURE',

    ACCOUNTS_PRECAUTIONARY_WARNINGS_REQUEST: 'ACCOUNTS_PRECAUTIONARY_WARNINGS_REQUEST',
    ACCOUNTS_PRECAUTIONARY_WARNINGS_SUCCESS: 'ACCOUNTS_PRECAUTIONARY_WARNINGS_SUCCESS',
    ACCOUNTS_PRECAUTIONARY_WARNINGS_FAILURE: 'ACCOUNTS_PRECAUTIONARY_WARNINGS_FAILURE',
    ACCOUNTS_PRECAUTIONARY_WARNINGS_REQUEST_NEXT_PAGE: 'ACCOUNTS_PRECAUTIONARY_WARNINGS_REQUEST_NEXT_PAGE',
    ACCOUNTS_PRECAUTIONARY_WARNINGS_SUCCESS_NEXT_PAGE: 'ACCOUNTS_PRECAUTIONARY_WARNINGS_SUCCESS_NEXT_PAGE',
    ACCOUNTS_PRECAUTIONARY_WARNINGS_FAILURE_NEXT_PAGE: 'ACCOUNTS_PRECAUTIONARY_WARNINGS_FAILURE_NEXT_PAGE',

    UPDATE_CONTACT_TIME_VALIDATE_UI_STEP : 'UPDATE_CONTACT_TIME_VALIDATE_UI_STEP',
    UPDATE_CONTACT_TIME_SET_VISIBLE_STEP : 'UPDATE_CONTACT_TIME_SET_VISIBLE_STEP',
    UPDATE_CONTACT_TIME_IN_PROGRESS: 'UPDATE_CONTACT_TIME_IN_PROGRESS',
    UPDATE_CONTACT_TIME_SUCCESS: 'UPDATE_CONTACT_TIME_SUCCESS',
    UPDATE_CONTACT_TIME_ERROR: 'UPDATE_CONTACT_TIME_ERROR',
    UPDATE_CONTACT_TIME_RESET: 'UPDATE_CONTACT_TIME_RESET',
    UPDATE_CONTACT_TIME_FROM_TIME: 'UPDATE_CONTACT_TIME_FROM_TIME',
    UPDATE_CONTACT_TIME_TO_TIME: 'UPDATE_CONTACT_TIME_TO_TIME',

    PRINTER_OPEN_PREVIEW: 'PRINTER_OPEN_PREVIEW',
    DOCUMENTS_PRINTED_AND_SIGNED_RADIO_CHANGED: 'DOCUMENTS_PRINTED_AND_SIGNED_RADIO_CHANGED',
    PRINTED_INDICATORS_RESET: 'PRINTED_INDICATORS_RESET',
    SHOW_PRINT_REQUIRED: 'SHOW_PRINT_REQUIRED',
    SHOW_PRINTED_AND_SIGNED_STATUS_REQUIRED: 'SHOW_PRINTED_AND_SIGNED_STATUS_REQUIRED',
    PRINTER_CLEAN_ERROR_MESSAGE: 'PRINTER_CLEAN_ERROR_MESSAGE',
    PRINTED_AND_SIGNED_SUCCESS: 'PRINTED_AND_SIGNED_SUCCESS',

    BROKER_MOVEMENT_CANCELATION_OPEN_MODAL: 'BROKER_MOVEMENT_CANCELATION_OPEN_MODAL',

    CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_REQUEST: 'CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_REQUEST',
    CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_SUCCESS: 'CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_SUCCESS',
    CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_FAILURE: 'CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_FAILURE',
    MODIFY_COMMERCE_AND_ATM_LIMITS_RESET: 'MODIFY_COMMERCE_AND_ATM_LIMITS_RESET',
    MODIFY_COMMERCE_AND_ATM_LIMITS_SET_VISIBLE_STEP: 'MODIFY_COMMERCE_AND_ATM_LIMITS_SET_VISIBLE_STEP',
    MODIFY_COMMERCE_AND_ATM_LIMITS_VALIDATE: 'MODIFY_COMMERCE_AND_ATM_LIMITS_VALIDATE',
    MODIFY_COMMERCE_AND_ATM_LIMITS_SHOW_CANCEL_MODAL: 'MODIFY_COMMERCE_AND_ATM_LIMITS_SHOW_CANCEL_MODAL',
    MODIFY_COMMERCE_AND_ATM_LIMITS_HIDE_WARNING_MODAL: 'MODIFY_COMMERCE_AND_ATM_LIMITS_HIDE_WARNING_MODAL',
    LIMITS_DATA_REQUEST: 'LIMITS_DATA_REQUEST',
    LIMITS_DATA_SUCCESS: 'LIMITS_DATA_SUCCESS',
    LIMITS_DATA_FAILURE: 'LIMITS_DATA_FAILURE',
    SET_MODIFY_AMOUNT: 'SET_MODIFY_AMOUNT',
    SET_LIMITS_RANGE: 'SET_LIMITS_RANGE',

    MODIFY_NGO_LIMITS_INFO_SUCCESS: 'MODIFY_NGO_LIMITS_INFO_SUCCESS',
    MODIFY_NGO_LIMITS_INFO_FAILURE: 'MODIFY_NGO_LIMITS_INFO_FAILURE',

    SECURITY_KIT_CHANGE_START: 'SECURITY_KIT_CHANGE_START',

    CONFIRM_MODIFY_MAIN_PHONE_REQUEST: 'CONFIRM_MODIFY_MAIN_PHONE_REQUEST',
    CONFIRM_MODIFY_MAIN_PHONE_SUCCESS: 'CONFIRM_MODIFY_MAIN_PHONE_SUCCESS',
    CONFIRM_MODIFY_MAIN_PHONE_FAILURE: 'CONFIRM_MODIFY_MAIN_PHONE_FAILURE',
    MODIFY_MAIN_PHONE_RESET: 'MODIFY_MAIN_PHONE_RESET',
    MODIFY_MAIN_PHONE_SET_VISIBLE_STEP: 'MODIFY_MAIN_PHONE_SET_VISIBLE_STEP',
    MODIFY_MAIN_PHONE_VALIDATE: 'MODIFY_MAIN_PHONE_VALIDATE',
    MODIFY_MAIN_PHONE_SHOW_CANCEL_MODAL: 'MODIFY_MAIN_PHONE_SHOW_CANCEL_MODAL',

    CONTACT_PHONE_DATA_REQUEST: 'CONTACT_PHONE_DATA_REQUEST',
    CONTACT_PHONE_DATA_SUCCESS: 'CONTACT_PHONE_DATA_SUCCESS',
    CONTACT_PHONE_DATA_FAILURE: 'CONTACT_PHONE_DATA_FAILURE',

    SET_MAIN_PHONE: 'SET_MAIN_PHONE',

    CONFIRM_SEND_EXTRACTS_VIA_MAIL_REQUEST: 'CONFIRM_SEND_EXTRACTS_VIA_MAIL_REQUEST',
    CONFIRM_SEND_EXTRACTS_VIA_MAIL_SUCCESS: 'CONFIRM_SEND_EXTRACTS_VIA_MAIL_SUCCESS',
    CONFIRM_SEND_EXTRACTS_VIA_MAIL_FAILURE: 'CONFIRM_SEND_EXTRACTS_VIA_MAIL_FAILURE',

    SEND_EXTRACTS_VIA_MAIL_RESET: 'SEND_EXTRACTS_VIA_MAIL_RESET',
    SEND_EXTRACTS_VIA_MAIL_SET_VISIBLE_STEP: 'SEND_EXTRACTS_VIA_MAIL_SET_VISIBLE_STEP',
    SEND_EXTRACTS_VIA_MAIL_VALIDATE: 'SEND_EXTRACTS_VIA_MAIL_VALIDATE',
    SEND_EXTRACTS_VIA_MAIL_SHOW_CANCEL_MODAL: 'SEND_EXTRACTS_VIA_MAIL_SHOW_CANCEL_MODAL',

    SET_SELECTED_DOCUMENT: "SET_SELECTED_DOCUMENT",

    WRAPUP_DISASTER_SEND_REQUEST: 'WRAPUP_DISASTER_SEND_REQUEST',
    WRAPUP_DISASTER_SEND_SUCCESS: 'WRAPUP_DISASTER_SEND_SUCCESS',
    WRAPUP_DISASTER_SEND_FAILURE: 'WRAPUP_DISASTER_SEND_FAILURE',
    WRAPUP_DISASTER_SEND_RESET: 'WRAPUP_DISASTER_SEND_RESET',

    DISASTER_RECOVERY_RENEW_AGENT_TOKEN_TIMER_START: 'DISASTER_RECOVERY_RENEW_AGENT_TOKEN_TIMER_START',
    DISASTER_RECOVERY_RENEW_AGENT_TOKEN_TIMER_STOP: 'DISASTER_RECOVERY_RENEW_AGENT_TOKEN_TIMER_STOP',
    DISASTER_RECOVERY_RENEW_AGENT_TOKEN_REQUEST: 'DISASTER_RECOVERY_RENEW_AGENT_TOKEN_REQUEST',
    DISASTER_RECOVERY_RENEW_AGENT_TOKEN_SUCCESS: 'DISASTER_RECOVERY_RENEW_AGENT_TOKEN_SUCCESS',
    DISASTER_RECOVERY_RENEW_AGENT_TOKEN_FAILURE: 'DISASTER_RECOVERY_RENEW_AGENT_TOKEN_FAILURE',
}
