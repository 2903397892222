module.exports = {
    AMAZON_CONSENT_MODAL_OPENMARKETS_POLICY_PDF: 'https://www.openbank.es/assets/static/pdf/OpenMarkets/Politica_de_privacidad_ODM.pdf',
    AMAZON_CONSENT_MODAL_POLICY_LINK: 'https://www.openbank.es/politica',
    AMAZON_CONSENTS_STATUS: {
        ACCEPTED: 'ACCEPTED',
        PENDING: 'PENDING',
        NOT_ACCEPTED: 'NOT_ACCEPTED'
    },
    AMAZON_DEFAULT_PAGE_NUMBER: 0,
    AMAZON_DEFAULT_PAGE_SIZE: 15,
    AMAZON_GIFT_CARD_IMAGE_LOCATION_ID: 'amazon_gift_card',
    AMAZON_GIFT_CARD_LOCATION_ID: 'amazon_gift_card_promotion',
    AMAZON_GIFT_CARD_MAX_AMOUNT: 5000,
    AMAZON_GIFT_CARD_MAX_FRACTION_LENGTH: 2,
    AMAZON_GIFT_CARD_MAX_INTEGER_LENGTH: 4,
    AMAZON_GIFT_CARD_MIN_AMOUNT: 1,
    AMAZON_TERMS_AND_CONDITIONS_PDF_FILE_NAME: 'Bases_cheque_amazon.pdf',
    CANJEAR_CODIGO_LINK: "https://www.amazon.es/canjear-cheque-regalo",
    CODIGOS_TEXT: "codigos",
    PROMOCIONES_TEXT: "promociones",
    SELECTED_RADIO_COUPON: 'couponCode',
    SELECTED_RADIO_PROMOTIONS: 'promotions',
}
