const codes = {
    ANDALUCIA: '01',
    ARAGON: '02',
    ASTURIAS: '03',
    BALEARS: '04',
    CANARIAS: '05',
    CANTABRIA: '06',
    CASTILLA_LEON: '07',
    CASTILLA_LA_MANCHA: '08',
    CATALUNA: '09',
    OMUNITAT_VALENCIANA: '10',
    EXTREMADURA: '11',
    GALICIA: '12',
    MADRID: '13',
    MURCIA: '14',
    NAVARRA: '15',
    PAIS_VASCO: '16',
    RIOJA: '17',
    CEUTA: '18',
    MELILLA: '19'
};

const names = {
    ANDALUCIA: 'Andalucía',
    ARAGON: 'Aragón',
    ASTURIAS: 'Asturias, Principado de',
    BALEARS: 'Balears, Illes',
    CANARIAS: 'Canarias',
    CANTABRIA: 'Cantabria',
    CASTILLA_LEON: 'Castilla y León',
    CASTILLA_LA_MANCHA: 'Castilla - La Mancha',
    CATALUNA: 'Cataluña',
    OMUNITAT_VALENCIANA: 'Comunitat Valenciana',
    EXTREMADURA: 'Extremadura',
    GALICIA: 'Galicia',
    MADRID: 'Madrid, Comunidad de',
    MURCIA: 'Murcia, Región de',
    NAVARRA: 'Navarra, Comunidad Foral de',
    PAIS_VASCO: 'País Vasco',
    RIOJA: 'Rioja, La',
    CEUTA: 'Ceuta',
    MELILLA: 'Melilla'
};

const constants = {
    '01': 'ANDALUCIA',
    '02': 'ARAGON',
    '03': 'ASTURIAS',
    '04': 'BALEARS',
    '05': 'CANARIAS',
    '06': 'CANTABRIA',
    '07': 'CASTILLA_LEON',
    '08': 'CASTILLA_LA_MANCHA',
    '09': 'CATALUNA',
    '10': 'OMUNITAT_VALENCIANA',
    '11': 'EXTREMADURA',
    '12': 'GALICIA',
    '13': 'MADRID',
    '14': 'MURCIA',
    '15': 'NAVARRA',
    '16': 'PAIS_VASCO',
    '17': 'RIOJA',
    '18': 'CEUTA',
    '19': 'MELILLA'
};

module.exports = {
    codes,
    names,
    constants
}
