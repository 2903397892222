const BROKER_STOCKS_TYPE_FOOTBALL = 'footballStocks';
const BROKER_STOCKS_TYPE_SOCIAL_MEDIA = 'socialMediaStocks';
const BROKER_STOCKS_DEFAULT_TAB = 0;
const BROKER_STOCKS_PRICE_DECIMALS = 3;
const BROKER_STOCKS_SEARCH_PAGE_NUM = '1';
const BROKER_STOCKS_SEARCH_PAGE_SIZE = '10';
const BROKER_STOCKS_DETAIL_VARIANT_TWO_LINES = 'Two';
const BROKER_STOCKS_DETAIL_VARIANT_THREE_LINES = 'Three';
const BROKER_STOCKS_LIST_PAGE_SIZE = 5;
const BROKER_FOOTBALL_STOCK_QUERY_TYPE = 'OB_Futbol';
const BROKER_SOCIAL_MEDIA_STOCK_QUERY_TYPE = 'OB_Tech';

module.exports = {
    BROKER_STOCKS_TYPE_FOOTBALL,
    BROKER_STOCKS_TYPE_SOCIAL_MEDIA,
    BROKER_STOCKS_DEFAULT_TAB,
    BROKER_STOCKS_PRICE_DECIMALS,
    BROKER_STOCKS_SEARCH_PAGE_NUM,
    BROKER_STOCKS_SEARCH_PAGE_SIZE,
    BROKER_STOCKS_DETAIL_VARIANT_TWO_LINES,
    BROKER_STOCKS_DETAIL_VARIANT_THREE_LINES,
    BROKER_STOCKS_LIST_PAGE_SIZE,
    BROKER_FOOTBALL_STOCK_QUERY_TYPE,
    BROKER_SOCIAL_MEDIA_STOCK_QUERY_TYPE
};
