require('./productBanner.scss');
//@constants
const { PRODUCT_BANNER_PACK_PREMIUM, PRODUCT_BANNER_PACK_DIAMOND, TYPE_ETFS, TYPE_STOCKS } = require('constants/index');
const {
    DIAMOND_PACK_PRODUCT_ALIAS,
    PREMIUM_PACK_PRODUCT_ALIAS
} = require('constants/hiringProductInformation');
const { DENIAL_ACCOUNT_HIRING_BLOCKED_ACCOUNT } = require('constants/flagBasedFeatures');

//@vendor
const React = require('react');
const PropTypes = require('prop-types');
const { connect } = require('react-redux');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const { withRouter } = require('react-router-dom');
const { contains } = require('lodash');
//@commons
const OKTag = require('components/commons/OKTag/OKTag');
const { CollectiveTag } = require('components/commons/collectiveTag/collectiveTag');
const { OKButton } = require('components/commons/OKButton/OKButton');
const { HTMLSafeInjector } = require('commonsComponents/htmlSafeInjector');
const { OKIconHighlight } = require('commonsComponents/OKIconHighlight');
const { OKLink } = require('commonsComponents/OKLink');
const { TagNew } = require('components/commons/tagNew/tagNew');
const WebappRender = require('components/conditionalRender/webappRender');
// @ actions
const hiringProductBannersActions = require('actions/hiringProductBanners');
//@core
const { formatText, FormattedText, hasTranslation } = require('core/i18n').i18n;
const classNames = require('classnames');
// @ helper
const { recordLink } = require('utilities/tagging');
const { hasPublicUrl } = require('utilities/commercialAdsHelper');
const { taggingEventHandled } = require('utilities/taggingEventHandled')
// @hocs
import withFeatureToggling from 'hocs/withFeatureToggling/withFeatureToggling';

class ProductBanner extends React.Component {
    constructor(props) {
        super(props);

        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
        this.collectivesProductList = [
            DIAMOND_PACK_PRODUCT_ALIAS,
            PREMIUM_PACK_PRODUCT_ALIAS,
            PRODUCT_BANNER_PACK_DIAMOND,
            PRODUCT_BANNER_PACK_PREMIUM,
            TYPE_ETFS,
            TYPE_STOCKS
        ];

    }

    handleButtonClick(url, linkText) {
        const {
            history,
            onButtonClickHandle,
            processType,
            eventName,
            eventTag,
            immFlagBasedFeatures,
            immHiringProductBanners,
            isAccountHiringFlow,
            showAccountBlockedErrorModal
        } = this.props;

        let eventNameText = eventName ? eventName : linkText;
        const accountBlockedSuccessStatus = isAccountHiringFlow && immFlagBasedFeatures.get(DENIAL_ACCOUNT_HIRING_BLOCKED_ACCOUNT)
            ? immHiringProductBanners.get('isAccountBlockedValidationSuccess')
            : true;
        const accountBlockedErrorStatus = immHiringProductBanners.get('isAccountBlockedValidationError');
        const accountBlockedFetchingStatus = immHiringProductBanners.get('isAccountBlockedValidationFetching');

        if (!!processType) {
            recordLink(processType, eventNameText, eventTag);
        }

        if (accountBlockedSuccessStatus) {
            if (onButtonClickHandle) {
                onButtonClickHandle();
            } else {
                if (hasPublicUrl(url)) {
                    window.location.href = url;
                } else {
                    history.push(url);
                }
            }
        } else if (!accountBlockedSuccessStatus && accountBlockedErrorStatus && !accountBlockedFetchingStatus) {
            showAccountBlockedErrorModal(true);
        }
    }

    handleLinkClick(index) {
        if (index !== 'undefined' && this.props.pageName) {
            const eventTypes = (index) => ['ESGFunds', 'Indexed&ThematicFunds', 'BuildYourPortfolio'][index]
            taggingEventHandled(this.props.pageName, 'comercialCard', eventTypes(index))
        }
    }

    buildFootersLink() {
        const {
            complementaryLinkModifier,
            complementaryLinkText
        } = this.props;
        const columns = Math.ceil(12 / this.props.advantages.length);

        const links = this.props.advantages.map((advantage, index) => {
            if (advantage.link) {
                const linkText = advantage.linkText
                    ? (<FormattedText value={advantage.linkText} />)
                    : (<FormattedText value={complementaryLinkText} />);

                return (
                    <div className={`col-sm-${columns}`} key={index}>
                        <OKLink
                            extraClass="product-banner__link"
                            modifier={complementaryLinkModifier}
                            to={formatText(advantage.link)}
                            target="_blank"
                            onClick={() => this.handleLinkClick(index)}
                        >
                            {linkText}
                        </OKLink>
                    </div>
                )
            } else {
                return (
                    <div className={`col-sm-${columns}`} key={index}></div>
                )
            }
        })
        return (
            <div className="product-banner__advantage-link">
                {links}
            </div>
        )
    }

    buildAdvantages() {
        const {
            originCountry,
            textsAlreadyTranslated
        } = this.props;
        const columns = Math.ceil(12 / this.props.advantages.length);
        const columnsClass = classNames(
            'product-banner__advantage',
            'col-sm-' + columns
        );
        return this.props.advantages.map((advantage, index) => {
            const advantageIconByCountry =   advantage.iconByCountry  && advantage.iconByCountry[originCountry.toLowerCase()];
            const advantageIcon = advantageIconByCountry ||  advantage.icon;
            const icon = hasTranslation(advantageIcon) ? formatText(advantageIcon) :advantageIcon;
            let iconAdvantageElement;
            if (advantage.iconType === "SVG") {
                iconAdvantageElement =  <img className="ok-icon-highlight ok-icon-highlight--medium" src={icon}/>
            } else if (advantage.iconType === "large-svg"){
                iconAdvantageElement =  (
                    <div className="ok-icon-highlight ok-icon-highlight--medium ok-icon-highlight--wealth product-banner__advantage-icon">
                        <img className="" src={icon}/>
                    </div>
                )
            } else {
                iconAdvantageElement = (<OKIconHighlight icon={icon} product={this.props.productModifier} />);
            }
            const key = 'advantages' + icon + index;
            const titleClass = classNames({
                'product-banner__advantage-title': !!advantage.description,
                'product-banner__advantage-text': !advantage.description,
            });
            let textAdvantageElement, descriptionAdvantage, descriptionAdvantageElement;
            if (textsAlreadyTranslated) {
                textAdvantageElement = advantage.text;
            } else if (hasTranslation(advantage.text)) {
                textAdvantageElement = <FormattedText value={advantage.text} withCountrySuffix />
            } else {
                textAdvantageElement = <HTMLSafeInjector message={advantage.text} />
            }

            if (advantage.description) {
                if (hasTranslation(advantage.description)) {
                    descriptionAdvantageElement = <FormattedText value={advantage.description} withCountrySuffix />
                } else {
                    descriptionAdvantageElement = <HTMLSafeInjector message={advantage.description} />
                }
                descriptionAdvantage = (
                    <div className="product-banner__advantage-description">
                        {descriptionAdvantageElement}
                    </div>
                );
            }

            return (
                <div key={key} className={`${columnsClass} ${advantage.link && 'product-banner__advantage--has-link'}`}>
                    <div className="product-banner__advantage-column-icon">
                        { iconAdvantageElement }
                    </div>
                    <div className="product-banner__advantage-column-text">
                        <div className={titleClass}>
                            {textAdvantageElement}
                        </div>
                        {descriptionAdvantage}
                    </div>
                </div>
            );
        });
    }

    buildLeftSidebar() {
        const {
            sidebarImgCardUrl,
            sidebarExtraImgCardUrl,
            sidebarImgAlt,
            sidebarImgUrl,
            sidebarType,
        } = this.props;
        let leftSidebar;

        if (sidebarType === 'card-art') {
            const imgAlt = hasTranslation(sidebarImgAlt) ? formatText(sidebarImgAlt) : '';
            const classNameCardImage = classNames('product-banner__card-image', {
                'product-banner__card-image--extra-front': !!sidebarExtraImgCardUrl,
                'product-banner__card-image--single': !sidebarExtraImgCardUrl,
            });
            let extraImage;
            if (!!sidebarExtraImgCardUrl) {
                extraImage = (
                    <img
                        alt={imgAlt}
                        className="product-banner__card-image product-banner__card-image--extra-last"
                        src={sidebarExtraImgCardUrl}
                    />
                );
            }
            leftSidebar = (
                <div>
                    <img
                        alt={imgAlt}
                        className={classNameCardImage}
                        src={sidebarImgCardUrl}
                    />
                    {extraImage}
                </div>
            );
        }

        if (!!sidebarImgUrl) {
            leftSidebar = (
                <div className="product-banner__left-sidebar" style={{ backgroundImage: `url(${sidebarImgUrl})` }}>
                    {leftSidebar}
                </div>
            );
        } else {
            leftSidebar = (
                <div className="product-banner__left-sidebar">
                    {leftSidebar}
                </div>
            );
        }

        return leftSidebar;
    }

    buildContent() {
        const { children, customType } = this.props;
        let content;

        switch (customType) {
            case 'retail-loan':
                content = (
                    <div className="product-banner__retail-loan">{children}</div>
                );
                break;
            case 'deposits-informative':
                content = (
                    <div className="product-banner__deposits-informative">{children}</div>
                );
                break;
            default:
                const advantages = this.buildAdvantages();
                const links = this.buildFootersLink();
                content = (
                    <div className="product-banner__advantages row">
                        {advantages}
                        {links}
                    </div>
                );
        }

        return content;
    }

    buildBottomButtonRow() {
        const {
            hasButton,
            link,
            linkModifier,
            linkText,
        } = this.props;
        const formatedLinkText = formatText(linkText);

        let hireButton;

        if (hasButton) {
            hireButton = (
                <OKButton
                    modifier={linkModifier}
                    onClick={() => this.handleButtonClick(link, formatedLinkText)}
                >
                    {formatedLinkText}
                </OKButton>
            );
        }

        return hireButton;
    }

    handleComplementarrylinkClick() {
        const { onFooterLinkClick, pageName, complementaryLinkText } = this.props;
        if (!!onFooterLinkClick) {
            onFooterLinkClick();
        }
        if (pageName) {
            taggingEventHandled(this.props.pageName, 'comercialCardsLink', formatText(complementaryLinkText))
        }
    }

    buildComplementaryLinkButton() {
        const {
            complementaryLink,
            complementaryLinkModifier,
            complementaryLinkText,
            hasComplementaryLink,
        } = this.props;

        return hasComplementaryLink
            ? (
                <WebappRender>
                    <OKLink
                        onClick={() => this.handleComplementarrylinkClick()}
                        extraClass="product-banner__primary-button-link"
                        modifier={complementaryLinkModifier}
                        to={complementaryLink ? complementaryLink : undefined}
                        target={complementaryLink ? "_blank" : ""}
                    >
                        <FormattedText value={complementaryLinkText} />
                    </OKLink>
                </WebappRender>
            )
            : null
    }

    buildComplementaryLeftLink(){
        const {
            secondaryLinkURL,
            hasSecondaryLink,
            secondaryLinkLabel,
        } = this.props;

        return hasSecondaryLink
            ? (
                <OKLink
                    onClick={() => this.props.pageName && taggingEventHandled(this.props.pageName, 'comercialCardsLink', formatText(secondaryLinkLabel))}
                    extraClass="product-banner__secondary-button-link"
                    modifier="emphasize"
                    to={secondaryLinkURL}
                    target="_blank"
                >
                    <FormattedText value={secondaryLinkLabel} />
                </OKLink>
            )
            : null
    }

    buildBadge() {
        const { collective } = this.props;

        return collective ? (
            <CollectiveTag
                extraClass="product-banner__badge"
                productAlias={collective.alias}
            />
        ) : null;
    }

    render() {
        const {
            collectiveTagData,
            id,
            isCollectiveUser,
            hasSidebarImg,
            subtitle,
            title,
        } = this.props;
        const leftSidebar = this.buildLeftSidebar();
        const content = this.buildContent();
        const hireButton = this.buildBottomButtonRow();
        const complementaryLinkButton = this.buildComplementaryLinkButton();
        const buildComplementaryLeftLink = this.buildComplementaryLeftLink();
        const collectiveName = this.props.collectiveName || formatText('colectivos-businessTag');

        let titleElement;
        let subtitleElement;
        let businessTagElement;

        const productBannerContainerClass = classNames('product-banner__container', {
            'product-banner__side-image-hidden': !hasSidebarImg
        });

        if (hasTranslation(title)) {
            titleElement = <FormattedText value={title} withCountrySuffix />;
        } else {
            titleElement = <HTMLSafeInjector message={title} />;
        }

        if (hasTranslation(subtitle)) {
            subtitleElement = <FormattedText value={subtitle} withCountrySuffix />;
        } else {
            subtitleElement = <HTMLSafeInjector message={subtitle} />;
        }

        if (isCollectiveUser && contains(this.collectivesProductList, id)) {
            if (collectiveTagData && collectiveTagData.collective_title) {
                businessTagElement = (
                    <div className="product-banner__title-tag">
                        <OKTag
                            content={collectiveTagData.collective_title}
                            type="collective"
                            backgroundColor={collectiveTagData.collective_background_color}
                            textColor={collectiveTagData.collective_text_color}
                            context={"pill"}
                        />
                    </div>
                )
            } else {
                businessTagElement = (
                    <div className="product-banner__title-tag"><OKTag content={collectiveName} type="identification" /></div>
                )
            }
        } else {
            businessTagElement = this.buildBadge();
        }

        return (
            <div className="product-banner__card">
                {leftSidebar}
                <div className={productBannerContainerClass}>
                    <div className="product-banner__header">
                        <div className="product-banner__title-container">
                            <div className="product-banner__title">
                                <div className="product-banner__title--text">{titleElement}</div>
                                {businessTagElement}
                                <TagNew id={id} context="layered" />
                            </div>
                            <div className="product-banner__subtitle">
                                {subtitleElement}
                            </div>
                        </div>
                        <div className="product-banner__hire-button">
                            {hireButton}
                        </div>
                    </div>

                    {content}
                    <div className="product-banner__complementary-link">
                        {buildComplementaryLeftLink}
                        {complementaryLinkButton}
                    </div>
                </div>
            </div>
        );
    }
}

ProductBanner.propTypes = {
    textsAlreadyTranslated: PropTypes.bool,
    advantages: PropTypes.array,
    children: PropTypes.object,
    collective: PropTypes.object,
    collectiveTagData: PropTypes.object,
    complementaryLink: PropTypes.string,
    complementaryLinkModifier: PropTypes.string,
    complementaryLinkText: PropTypes.string,
    customType: PropTypes.oneOf(['retail-loan', 'deposits-informative']),
    eventName: PropTypes.string,
    eventTag: PropTypes.string,
    title: PropTypes.string.isRequired,
    tagFree: PropTypes.bool,
    subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    link: PropTypes.string.isRequired,
    linkModifier: PropTypes.string,// Use the same modifiers as okButton
    linkText: PropTypes.string,
    hasButton: PropTypes.bool,
    hasComplementaryLink: PropTypes.bool,
    hasSecondaryLink: PropTypes.bool,
    secondaryLinkLabel: PropTypes.string,
    secondaryLinkURL: PropTypes.string,
    hasSidebarImg: PropTypes.bool,
    id: PropTypes.string,
    collectiveName: PropTypes.string,
    isAccountHiringFlow: PropTypes.bool,
    isCollectiveUser: PropTypes.bool,
    history: PropTypes.object.isRequired,
    immFlagBasedFeatures: PropTypes.object,
    immHiringProductBanners: PropTypes.object,
    onButtonClickHandle: PropTypes.func,
    onFooterLinkClick: PropTypes.func,
    processType: PropTypes.string,
    productModifier: PropTypes.oneOf([
        'banking',
        'wealth'
    ]),
    sidebarExtraImgCardUrl: PropTypes.string,
    sidebarImgAlt: PropTypes.string,
    sidebarImgCardUrl: PropTypes.string,
    sidebarImgUrl: PropTypes.string,
    sidebarType: PropTypes.oneOf([
        'card-art',
        'image'
    ]),
    originCountry: PropTypes.string,
    pageName: PropTypes.string,
    showAccountBlockedErrorModal: PropTypes.func
};

ProductBanner.defaultProps = {
    complementaryLink: '',
    complementaryLinkModifier: 'complementary',
    complementaryLinkText: 'common-moreInformation',
    secondaryLinkLabel: null,
    secondaryLinkURL: null,
    collectiveTagData: {},
    hasButton: true,
    hasComplementaryLink: false,
    hasSecondaryLink: false,
    hasSidebarImg: true,
    id: '',
    isAccountHiringFlow: false,
    link: '/myprofile',
    linkModifier: 'primary',
    linkText: 'common-moreInformation',
    processType: null,
    sidebarType: 'image',
    subtitle: '',
    tagFree: false,
    textsAlreadyTranslated: false,
    isCollectiveUser: false,
    pageName:'',
    onFooterLinkClick: null,
};
module.exports = connect(
    state => ({
        originCountry: state.profile.get('originCountry'),
        immFlagBasedFeatures: state.flagBasedFeatures,
        immHiringProductBanners: state.hiringProductBanners,
    }), {
        showAccountBlockedErrorModal: hiringProductBannersActions.showAccountBlockedErrorModal
    }
)(withFeatureToggling(withRouter(ProductBanner)));
