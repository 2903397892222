require('./dateFilter.scss');

const PropTypes = require('prop-types');

const React = require('react');
const { FormattedText, formatText, getLanguage } = require('core/i18n').i18n;
const moment = require('moment');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const { OKDatePicker } = require('commonsComponents/OKDatePicker');
const { RadioButton } = require('commonsComponents/radioButton');
const { RadioButtonGroup } = require('commonsComponents/radioButtonGroup');
const DateHelper = require('utilities/dateHelper');

//@constants
const {
    DATE_FILTER_ANNUAL,
    DATE_FILTER_BIANNUAL,
    DATE_FILTER_CUSTOM,
    DATE_FILTER_FORTNIGHTLY,
    DATE_FILTER_THREE_MONTHS,
    DATE_FILTER_MONTHLY,
    DATE_FILTER_NONE,
    DATE_FILTER_TWO_YEARS
} = require('constants/index');
const { DATE_PICKER_CUSTOM_PLACEHOLDER, DATE_PICKER_CUSTOM_LOCALE, DATE_PICKER_CUSTOM_DATE_FORMAT, DATE_PICKER_LOWERCASE_MONTH_COUNTRIES } = require('constants/commonsComponents');

class DateFilter extends React.Component {
    constructor(props) {
        super(props);
        const { immFilter } = this.props;

        this.state = {
            selected: immFilter.get('selected'),
            fromDate: immFilter.get('from'),
            toDate: immFilter.get('to')
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.setToDate = this.setToDate.bind(this);
        this.setFromDate = this.setFromDate.bind(this);
        this.buildRadioButtons = this.buildRadioButtons.bind(this);
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    setFromDate(date) {
        const newDate = moment(date).isValid() ? date : this.state.fromDate;
        this.setState({
            fromDate: newDate
        });
        this.props.onChangeMin(newDate);
    }

    setToDate(date) {
        this.setState(previousState => {
            const newDate = moment(date).isValid() ? date : this.state.toDate;
            if (previousState.fromDate.isAfter(newDate)) {
                this.props.onChangeMin(newDate);
                this.props.onChangeMax(newDate);

                return {
                    fromDate: newDate,
                    toDate: newDate
                };
            } else {
                this.props.onChangeMax(newDate);

                return {
                    toDate: newDate
                };
            }
        });
    }

    buildBetweenDates() {
        const { selected, fromDate, toDate } = this.state;
        const { minDate } = this.props;
        const maxDate = moment().startOf('day');

        const convertToMomentLocale = date => moment.isMoment(date)
            && date.locale
            && date.locale(getLanguage() || 'es');

        const selectedFromDate = fromDate;
        const selectedToDate = toDate;
        convertToMomentLocale(selectedFromDate);
        convertToMomentLocale(selectedToDate);

        let dateRangeSection;
        const errors = {
            messageStandardErrorDate: formatText('dateFilter-dateFilter_standardErrorDate'),
            messageLessThanMinDateError: formatText('dateFilter-dateFilter_lessThanMinDateError'),
            messageMoreThanMinDateError: formatText('dateFilter-dateFilter_moreThanMinDateError')
        }

        if (selected === DATE_FILTER_CUSTOM) {
            const isLowerCaseCountry = DATE_PICKER_LOWERCASE_MONTH_COUNTRIES.includes(getLanguage().toUpperCase());
            dateRangeSection = (
                <div className="row date-filter__custom-ranges">
                    <div className="col-xs-6">
                        <div className="date-filter__label-to">
                            <FormattedText value="filterDate-fromRange" />
                        </div>
                        <OKDatePicker
                            callback={this.setFromDate}
                            minDate={minDate}
                            maxDate={toDate}
                            selectedDate={selectedFromDate}
                            dateLower={isLowerCaseCountry}
                            placeholder={formatText(DATE_PICKER_CUSTOM_PLACEHOLDER)}
                            locale={formatText(DATE_PICKER_CUSTOM_LOCALE)}
                            dateFormat={formatText(DATE_PICKER_CUSTOM_DATE_FORMAT)}
                            defaultPlaceholder={formatText('datePickerCustom-defaultPlaceholder')}
                            defaultPlaceholderDay={formatText('datePickerCustom-defaultPlaceholderDay')}
                            defaultPlaceholderMonth={formatText('datePickerCustom-defaultPlaceholderMonth')}
                            defaultPlaceholderYear={formatText('datePickerCustom-defaultPlaceholderYear')}
                            {...errors}
                        />
                    </div>
                    <div className="col-xs-6">
                        <div className="date-filter__label-from">
                            <FormattedText value="filterDate-toRange" />
                        </div>
                        <OKDatePicker
                            callback={this.setToDate}
                            minDate={fromDate}
                            maxDate={maxDate}
                            selectedDate={selectedToDate}
                            dateLower={isLowerCaseCountry}
                            placeholder={formatText(DATE_PICKER_CUSTOM_PLACEHOLDER)}
                            locale={formatText(DATE_PICKER_CUSTOM_LOCALE)}
                            dateFormat={formatText(DATE_PICKER_CUSTOM_DATE_FORMAT)}
                            innerSmallContainer
                            defaultPlaceholder={formatText('datePickerCustom-defaultPlaceholder')}
                            defaultPlaceholderDay={formatText('datePickerCustom-defaultPlaceholderDay')}
                            defaultPlaceholderMonth={formatText('datePickerCustom-defaultPlaceholderMonth')}
                            defaultPlaceholderYear={formatText('datePickerCustom-defaultPlaceholderYear')}
                            {...errors}
                        />
                    </div>
                </div>
            );
        }

        return dateRangeSection;
    }

    buildRadioButtons() {
        const {
            showFortnightly,
            showMonthly,
            showBiannual,
            showAnnual,
            showTwoYears,
            showThreeMonths
        } = this.props;
        const options = [DATE_FILTER_NONE];

        if (showFortnightly) {
            options.push(DATE_FILTER_FORTNIGHTLY);
        }

        if (showMonthly) {
            options.push(DATE_FILTER_MONTHLY);
        }

        if (showThreeMonths) {
            options.push(DATE_FILTER_THREE_MONTHS);
        }

        if (showBiannual) {
            options.push(DATE_FILTER_BIANNUAL);
        }

        if (showAnnual) {
            options.push(DATE_FILTER_ANNUAL);
        }

        if (showTwoYears) {
            options.push(DATE_FILTER_TWO_YEARS);
        }

        options.push(DATE_FILTER_CUSTOM);

        return options.map((filter, index) => {
            let labelPrimary = formatText(`filterDate-label${filter}`);
            let labelSecondary = '';
            let customLabel;

            if (filter !== DATE_FILTER_CUSTOM && filter !== DATE_FILTER_NONE) {
                const dates = this.estimateDates(filter);
                const isLowerCaseCountry = DATE_PICKER_LOWERCASE_MONTH_COUNTRIES.includes(getLanguage().toUpperCase());
                const formatDate = (date, lowercase) => {
                    const newDate = DateHelper.formatDate(date, formatText('filterDateAhead-rangeFormat'))
                    return lowercase ? newDate.toLowerCase() : newDate;
                };
                labelPrimary = `${labelPrimary} - `;

                labelSecondary = formatText('filterDate-range',
                    [
                        formatDate(dates[0], isLowerCaseCountry),
                        formatDate(dates[1], isLowerCaseCountry)
                    ]
                );
            }

            customLabel = (
                <div className="date-filter__radio-label">
                    <span className="date-filter__radio-label-primary">
                        {labelPrimary}
                    </span>
                    <span className="date-filter__radio-label-secondary">
                        {labelSecondary}
                    </span>
                </div>
            );

            return (
                <RadioButton
                    key={index}
                    className="date-filter__radio-option"
                    label={customLabel}
                    value={filter}
                />
            );
        });
    }

    estimateDates(id) {
        const today = moment().startOf('day');
        const startDate = moment().startOf('day');

        switch (id) {
            case DATE_FILTER_FORTNIGHTLY:
                startDate.subtract('2', 'weeks');
                break;
            case DATE_FILTER_MONTHLY:
                startDate.subtract('1', 'months');
                break;
            case DATE_FILTER_THREE_MONTHS:
                startDate.subtract('3', 'months');
                break;
            case DATE_FILTER_BIANNUAL:
                startDate.subtract('6', 'months');
                break;
            case DATE_FILTER_ANNUAL:
                startDate.subtract('1', 'years');
                break;
            case DATE_FILTER_TWO_YEARS:
                startDate.subtract('2', 'years');
                break;
        }
        return [startDate, today];
    }

    handleFilterChange(value) {
        const selected = value;
        let fromDate = '';
        let toDate = '';

        if (selected !== DATE_FILTER_NONE) {
            const dates = this.estimateDates(selected);
            fromDate = dates[1];
            toDate = dates[0];
        }

        this.setState({
            selected,
            fromDate,
            toDate
        });

        this.props.onChangeSelected(selected);
        this.props.onChangeMax(fromDate);
        this.props.onChangeMin(toDate);
    }

    render() {
        const {dateLabelTitle} = this.props;
        const radioButtonsSection = this.buildRadioButtons();
        const dateRangeSection = this.buildBetweenDates();

        return (
            <div className={this.props.last ? 'date-filter__last' : 'date-filter'}>
                <h3 className="date-filter__title">
                    {dateLabelTitle ? dateLabelTitle: <FormattedText value="filterDate-title" />}
                </h3>
                <RadioButtonGroup
                    name="date-filter"
                    idPrefix="date-filter-radio"
                    selectedValue={this.state.selected}
                    onChange={this.handleFilterChange}
                    className="date-filter__options"
                >
                    {radioButtonsSection}
                </RadioButtonGroup>
                {dateRangeSection}
            </div>
        );
    }
}

DateFilter.propTypes = {
    immFilter: PropTypes.object.isRequired,
    minDate: PropTypes.object,
    onChangeMax: PropTypes.func,
    onChangeMin: PropTypes.func,
    onChangeSelected: PropTypes.func,
    showYearDropdown: PropTypes.bool,
    showAnnual: PropTypes.bool,
    showBiannual: PropTypes.bool,
    showFortnightly: PropTypes.bool,
    showMonthly: PropTypes.bool,
    showTwoYears: PropTypes.bool,
    showThreeMonths: PropTypes.bool,
    last: PropTypes.bool,
    dateLabelTitle: PropTypes.string
};

DateFilter.defaultProps = {
    minDate: moment('2005-01-01').startOf('day'),
    onChangeSelected: () => { },
    showYearDropdown: true,
    showAnnual: true,
    showBiannual: true,
    showFortnightly: true,
    showThreeMonths: false,
    showMonthly: true,
    showTwoYears: false,
    last: false,
    dateLabelTitle: null
};

module.exports = DateFilter;
