const PropTypes = require('prop-types');

// @vendor
const React = require('react');

// @commons
const OKModal = require('components/commons/OKModal/OKModal');

class ModalAbort extends React.Component {
    render() {
        const {
            isOpen,
            onAccept,
            onCancel,
            textKey,
            titleKey,
        } = this.props;

        return (
            <OKModal
                description={textKey}
                descriptionAlignment="center"
                footerType="action"
                isOpen={isOpen}
                onCloseCallback={onCancel}
                onPrimaryButtonClick={onAccept}
                onSecondaryButtonClick={onCancel}
                primaryButtonText="wizard-redirectConfirmationAcceptButton"
                secondaryButtonText="wizard-redirectConfirmationCancelButton"
                title={titleKey}
                titleAlignment="center"
            />
        );
    }
}

ModalAbort.propTypes = {
    titleKey: PropTypes.string,
    textKey: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func,
    onAccept: PropTypes.func
};

ModalAbort.defaultProps = {
    titleKey: 'wizard-redirectConfirmationTitle',
    textKey: 'wizard-redirectConfirmationMessage'
};

module.exports = ModalAbort;
