require('./OKTableRowDetailsItem.scss');

const PropTypes = require('prop-types');
const { connect } = require('react-redux');
const React = require('react');
const classnames = require('classnames');
const { formatText, hasTranslation } = require('core/i18n').i18n;


const OKTableRowDetailsItem = props => {
    const { className, immScreen, title, value } = props;
    const isMobile = immScreen.get('isMobile');
    const finalTitle = (hasTranslation(title)) ? formatText(title) : title;
    const listClasses = classnames('ok-table-row-details-item', className);
    const valueContentClasses = classnames({ 'ok-table-row-details-item__value-mobile': isMobile, 'ok-table-row-details-item__value': !isMobile });
    const titleClass = (isMobile) ? 'ok-table-row-details-item__title-mobile' : 'ok-table-row-details-item__title';

    let valueContent;
    if (!!value) {
        const finalValueText = (typeof value === 'string' && hasTranslation(value)) ? formatText(value) : value;
        valueContent = <span className={valueContentClasses}>{finalValueText}</span>;
    }

    return (
        <li className={listClasses}>
            <span className={titleClass}>{finalTitle}</span>{`${isMobile ? '' : ': '}`}
            {valueContent}
        </li>
    );
};

OKTableRowDetailsItem.propTypes = {
    className: PropTypes.string,
    immScreen: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired, // This can be text or translation key
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired,
};

OKTableRowDetailsItem.defaultProps = {
    className: '',
    title: '',
    value: ''
};

export default connect(
    state => ({
        immScreen: state.screen,
    }),
    null
)(OKTableRowDetailsItem);