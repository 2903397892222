require('./signaturePatternSMSInput.scss');

const PropTypes = require('prop-types');

// @ vendor
const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const classnames = require('classnames');

// @ commons
const {FormattedText, formatText} = require('core/i18n').i18n;
// (Andres): Production is sending a OTP value that has a different format than the one
// we get from preprod. So, temporarely we will remove the UI validation of the SMS code
// and and don't set a max length
// const { SMS_CODE_MAX_LENGTH } = require('constants/index');

// @ components
const { InputSafe } = require('commonsComponents/inputSafe');
const { OKMessage } = require('commonsComponents/OKMessage');

// @ utilities
const { getStringFromVOandTrustedDevice } = require('utilities/signaturePatternHelper');

// @ constants
const { OTP_VITUAL_OFFICE_CHECK } = require('constants/flagBasedFeatures');
const { EXPIRED_FLOW, BLOCKED_OTP, EXCLUDED_PUSH_OPERATION_CODES } = require('constants/signaturePattern');

class SignaturePatternSMSInput extends React.Component {

    constructor(props) {
        super(props);
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
        this.handleInputBoxClick = this.handleInputBoxClick.bind(this);
    }

    buildTitle() {
        const { displayTitle } = this.props;
        if (displayTitle) {
            return(
                <div className="signature-pattern-sms-input__title" htmlFor="informationCode">
                    <FormattedText value="cardPinRequest-confirmationCodeTitle"/>
                </div>
            );
        }
        return null;
    }
    handleInputBoxClick() {
        const { handleInputClick } =  this.props;
        !!handleInputClick && handleInputClick()
    }

    render() {
        let submitEl;
        const {
            confirmationCodeSubtitle,
            handleOnChangeSMS,
            immSignaturePattern,
            immSignaturePatternVirtualOffices,
            immFlagBasedFeatures,
            disabled,
            operationType,
        } = this.props;
        const isFetchingSignaturePassCodeSuccess = immSignaturePattern.get('isFetchingSignaturePassCodeSuccess');
        const isFetchingSMSCodeSuccess = immSignaturePattern.get('isFetchingSMSCodeSuccess');
        const isSMSValid = immSignaturePattern.get('isSMSValid');
        const inputValueSMS = immSignaturePattern.get('inputValueSMS');
        const showWarningSMS = immSignaturePattern.get('showWarningSMS');
        const SMSError = immSignaturePattern.get('isFetchingSMSCodeError');
        const SignaturePatternRestartNotification = immSignaturePattern.get('reasonForRestart');
        const subTitleClassName = classnames('signature-pattern-sms-input__subtitle', { 'signature-pattern-sms-input__subtitle--hidden': !isFetchingSignaturePassCodeSuccess });
        const codeConfirmationClassName = classnames('signature-pattern-sms-input', { 'signature-pattern-sms-input--disabled': !isFetchingSignaturePassCodeSuccess });
        const showTitle = this.buildTitle();
        let virtualOfficeFlag = null;
        let isVirtualOfficeSuccessAndValid = null;
        if (!__CONTACT_CENTER__) {
            virtualOfficeFlag = immFlagBasedFeatures.get(OTP_VITUAL_OFFICE_CHECK);
            isVirtualOfficeSuccessAndValid = virtualOfficeFlag && immSignaturePatternVirtualOffices.get('virtualOfficeRequestSuccess') && immSignaturePatternVirtualOffices.get('isVirtualOfficesValid');
        }
        let subtitle;

        if (__CONTACT_CENTER__) {
            subtitle = 'signaturePattern-confirmationCodeSubtitle';
        } else {
            subtitle = EXCLUDED_PUSH_OPERATION_CODES.includes(operationType) ?
                confirmationCodeSubtitle :
                getStringFromVOandTrustedDevice(
                immFlagBasedFeatures.get(OTP_VITUAL_OFFICE_CHECK),
                immSignaturePatternVirtualOffices,
                {
                    push: 'signaturePattern-confirmationCodeSubtitleTrusted',
                    generic: 'signaturePattern-confirmationCodeSubtitleGeneric',
                    sms: confirmationCodeSubtitle
                }
            )
        }

        let inputErrorMessage;
        if (!isSMSValid) {
            // SMS format is not valid. This validation is not active right now
            inputErrorMessage = formatText('signaturePattern-invalidSMS');
        } else if (!!SignaturePatternRestartNotification) {
                switch (SignaturePatternRestartNotification){
                    case EXPIRED_FLOW:
                        inputErrorMessage = formatText('signaturePattern-expiredFlow');
                        break;
                    case BLOCKED_OTP:
                        inputErrorMessage = formatText('signaturePattern-blockedOTP');
                        break;
                }
        } else if (!!SMSError) {
            inputErrorMessage = formatText('signaturePattern-SMSRejected');
        }

        if (inputValueSMS !== '') {
            if (!immSignaturePattern.get('isFetchingSMSCode') && isFetchingSMSCodeSuccess) {
                submitEl = (
                    <span className="signature-pattern-sms-input__icon-container">
                        <i className="signature-pattern-sms-input__icon icon icon-check"/>
                    </span>
                );
            } else if (immSignaturePattern.get('codeConfirmationFailure')) {
                submitEl = (<FormattedText value="common-error" />);
            }
        }

        let trustedWarningMessage;
        if (!__CONTACT_CENTER__ &&
            !EXCLUDED_PUSH_OPERATION_CODES.includes(operationType) &&
            immSignaturePatternVirtualOffices.get('isDeviceTrusted') &&
            (!virtualOfficeFlag || isVirtualOfficeSuccessAndValid)) {
            trustedWarningMessage = (
                <div className="signature-pattern-sms-input__trusted-warning">
                    <OKMessage type="informative">
                        <FormattedText value="signaturePattern-trustedDeviceWarning"/>
                    </OKMessage>
                </div>
            )
        }
        return (
            <div className={codeConfirmationClassName}>
                {showTitle}                
                <div className={subTitleClassName} htmlFor="informationCode">
                    <FormattedText value={subtitle} />
                </div>
                <div>
                    <div className="signature-pattern-sms-input__input-container" onClick={this.handleInputBoxClick}>
                        <InputSafe
                            disabled={!isFetchingSignaturePassCodeSuccess || isFetchingSMSCodeSuccess || disabled}
                            id="informationCode"
                            type="text"
                            value={inputValueSMS}
                            onChange={handleOnChangeSMS}
                            errorState={((!isSMSValid || !!SMSError) && showWarningSMS) || !!SignaturePatternRestartNotification}
                            errorMessage={inputErrorMessage}
                        />
                    </div>
                    {submitEl}
                </div>
                {trustedWarningMessage}
            </div>
        );
    }
}

SignaturePatternSMSInput.propTypes = {
    confirmationCodeSubtitle: PropTypes.string,
    displayTitle: PropTypes.bool,
    handleOnChangeSMS: PropTypes.func.isRequired,
    handleInputClick: PropTypes.func,
    immFlagBasedFeatures: PropTypes.object,
    immSignaturePatternVirtualOffices: PropTypes.object,
    immSignaturePattern: PropTypes.object,
    operationType: PropTypes.number,
    disabled: PropTypes.bool,
};

SignaturePatternSMSInput.defaultProps = {
    confirmationCodeSubtitle: 'cardPinRequest-confirmationCodeSubtitle',
    disabled: false,
    displayTitle: true
};

module.exports = SignaturePatternSMSInput;