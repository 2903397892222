module.exports = {
    // STANDARDS ON
    R42_CARD_1_ON_STANDARD: '0000001',
    R42_CARD_2_ON_STANDARD: '0000002',
    R42_CARD_3_ON_STANDARD: '0000003',
    R42_CARD_4_ON_STANDARD: '0000004',
    R42_CARD_5_ON_STANDARD: '0000005',
    R42_CARD_6_ON_STANDARD: '0000016',
    // STANDARDS OFF
    R42_CARD_1_OFF_STANDARD: '0000006',
    R42_CARD_2_OFF_STANDARD: '0000007',
    R42_CARD_3_OFF_STANDARD: '0000008',
    R42_CARD_4_OFF_STANDARD: '0000009',
    R42_CARD_5_OFF_STANDARD: '0000010',
    R42_CARD_6_OFF_STANDARD: '0000017',
    // STANDARDS VIP
    R42_CARD_1_VIP_STANDARD: '0000011',
    R42_CARD_2_VIP_STANDARD: '0000012',
    R42_CARD_3_VIP_STANDARD: '0000013',
    R42_CARD_4_VIP_STANDARD: '0000014',
    R42_CARD_5_VIP_STANDARD: '0000015',
    R42_CARD_6_VIP_STANDARD: '0000018',
};