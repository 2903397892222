// @vendors
const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const { formatText } = require('core/i18n').i18n;

const { OKDatePicker } = require('commonsComponents/OKDatePicker');

// @constants
const {
    YEAR,
    YEAR_MONTH_DAY,
    STANDARD_ERROR,
    LESS_ERROR,
    MORE_ERROR
} = require('constants/datePickerCustom');

class DatePickerCustom extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            selectedDate,
            minDate,
            maxDate,
            label,
            errorMessage,
            excludeDates,
            disabled,
            placeholder,
            locale,
            dateFormat,
            open,
            startDate,
            children,
            callback,
            callbackError,
            triangleInput,
            innerSmallContainer,
            messageLessThanMinDateError,
            messageMoreThanMinDateError,
            offsetTop,
            newSelectedDate,
            forceErrorMessageText,
            forceErrorMessageShow,
            doTaggingClickDateInput,
        } = this.props;

        return (
            <OKDatePicker
                open={open}
                children={children}
                locale={locale}
                maxDate={maxDate}
                minDate={minDate}
                startDate={startDate}
                selectedDate={selectedDate}
                newSelectedDate={newSelectedDate}
                excludeDates={excludeDates}
                dateFormat={dateFormat}
                disabled={disabled}
                offsetTop={offsetTop}
                onChange={this.handleChange}
                placeholder={placeholder}
                readOnly
                label={label}
                callback={callback}
                callbackError={callbackError}
                messageStandardErrorDate={errorMessage|| formatText(STANDARD_ERROR)}
                messageLessThanMinDateError={messageLessThanMinDateError|| formatText(LESS_ERROR)}
                messageMoreThanMinDateError={messageMoreThanMinDateError || formatText(MORE_ERROR)}
                innerSmallContainer={innerSmallContainer}
                triangleInput={triangleInput}
                defaultPlaceholder={formatText('datePickerCustom-defaultPlaceholder')}
                defaultPlaceholderDay={formatText('datePickerCustom-defaultPlaceholderDay')}
                defaultPlaceholderMonth={formatText('datePickerCustom-defaultPlaceholderMonth')}
                defaultPlaceholderYear={formatText('datePickerCustom-defaultPlaceholderYear')}
                forceErrorMessageText={forceErrorMessageText}
                forceErrorMessageShow={forceErrorMessageShow}
                doTaggingClickDateInput={doTaggingClickDateInput}
            />
        );
    }
}

DatePickerCustom.propTypes = {
    errorMessage: PropTypes.string,
    open: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    maxDate: PropTypes.object.isRequired,
    minDate: PropTypes.object.isRequired,
    startDate: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    children: PropTypes.object,
    dateFormat: PropTypes.string.isRequired,
    selectedDate: PropTypes.object,
    callback: PropTypes.func.isRequired,
    callbackError: PropTypes.func.isRequired,
    triangleInput: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    label: PropTypes.string,
    innerSmallContainer: PropTypes.bool.isRequired,
    messageStandardErrorDate: PropTypes.string,
    messageLessThanMinDateError: PropTypes.string,
    messageMoreThanMinDateError: PropTypes.string,
    offsetTop: PropTypes.number,
    excludeDates: PropTypes.array,
    newSelectedDate: PropTypes.array,
    forceErrorMessageText: PropTypes.string,
    forceErrorMessageShow: PropTypes.bool,
    doTaggingClickDateInput: PropTypes.func,
};

DatePickerCustom.defaultProps = {
    open: false,
    minDate: moment(),
    startDate: moment(),
    maxDate: moment().add(10, YEAR),
    locale: 'es',
    placeholder: '',
    dateFormat: YEAR_MONTH_DAY,
    selectedDate: null,
    callback: () => {},
    callbackError: () => {},
    triangleInput: true,
    label: null,
    disabled: false,
    innerSmallContainer: false,
    offsetTop: 0, //calendar standar height
    excludeDates: [],
    forceErrorMessageText: '',
    forceErrorMessageShow: false,
};

module.exports = DatePickerCustom;
