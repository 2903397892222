require('./stocksContractDropdownValue.scss');

const PropTypes = require('prop-types');

// @vendors
const React = require('react');

// @helpers
const { formatText, getLanguage } = require('core/i18n').i18n;
const { formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');
//@constants
const { COLON } = require('constants/index');

// @components
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');

class StocksContractDropdownValue extends React.Component {
    render() {
        const {walletAccountType, walletBalance, walletNumber} = this.props.value;
        const walletNumberEllipsis = '...' + walletNumber.substr(-4);
        const balanceLabel = `${formatText('broker-stocksContractDropdownContractBalance')}${COLON}`;
        const plainAmountObject = getPlainAmountObject(walletBalance);

        return (
            <div className="stocks-contract-dropdown__value" onMouseDown={this.handleMouseDown}>
                <span className="stocks-contract-dropdown__value--highlighted">{walletAccountType}</span>
                <span className="stocks-contract-dropdown__value--margin stocks-contract-dropdown__value--light">{walletNumberEllipsis}</span>

                <span className="pull-right">
                    <span className="stocks-contract-dropdown__right-text-title">{balanceLabel}</span>
                    <span className="stocks-contract-dropdown__value--highlighted">
                        <OneLineSymbolAmount
                            amountObject={plainAmountObject}
                            language={getLanguage()}
                            amountObjectFormatted={formatAmountObject(plainAmountObject, true)}
                        />
                    </span>
                </span>
            </div>
        );
    }
}

StocksContractDropdownValue.propTypes = {
    value: PropTypes.object
};

module.exports = StocksContractDropdownValue;