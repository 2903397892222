const DATE_FORMAT_TYPES = {
    es: {
        DATETIME_FORMAT_WITH_SLASHES: 'DD/MM/YYYY HH:mm',
        DATE_FORMAT_WITH_DASHES: 'DD-MM-YYYY',
    },
    en: {
        DATETIME_FORMAT_WITH_SLASHES: 'MM/DD/YYYY HH:mm',
        DATE_FORMAT_WITH_DASHES: 'MM-DD-YYYY',
    },
    de: {
        DATETIME_FORMAT_WITH_SLASHES: 'DD/MM/YYYY HH:mm',
        DATE_FORMAT_WITH_DASHES: 'DD-MM-YYYY',
    },
    pt: {
        DATETIME_FORMAT_WITH_SLASHES: 'DD/MM/YYYY HH:mm',
        DATE_FORMAT_WITH_DASHES: 'DD-MM-YYYY',
    },
    fr: {
        DATETIME_FORMAT_WITH_SLASHES: 'DD/MM/YYYY HH:mm',
        DATE_FORMAT_WITH_DASHES: 'DD-MM-YYYY',
    },
    nl: {
        DATETIME_FORMAT_WITH_SLASHES: 'DD/MM/YYYY HH:mm',
        DATE_FORMAT_WITH_DASHES: 'DD-MM-YYYY',
    }
};

module.exports = DATE_FORMAT_TYPES;
