require('./OKInformativeListItem.scss');

const PropTypes = require('prop-types');

const React = require('react');
const classNames = require('classnames');
const { FormattedText, hasTranslation } = require('core/i18n').i18n;
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');

class OKInformativeListItem extends React.Component {

    renderArrayOfTitles(titleArray) {
        return titleArray.map((item, index) => {
            const isFirstItem = index === 0;
            const applyCompactStyle = index !== titleArray.length - 1 || this.props.compact; // Don't apply compact style unless it is the last title.
            const titleText = this.renderTitleText(item);
            return this.renderTitleContainer(titleText, applyCompactStyle, isFirstItem, index);
        });
    }

    renderBodyContent (bodyText, bodyWordBreak) {
        const bodyTextClasses = classNames('ok-informative-list-item__body-text', {
            'ok-informative-list-item__body-text--nowrap': bodyWordBreak
        });

        let bodyContent;
        if (bodyText) {
            let finalBodyText;
            if (typeof bodyText === 'string' && hasTranslation(bodyText)) {
                finalBodyText = <FormattedText value={bodyText} />;
            } else {
                finalBodyText = bodyText;
            }
            bodyContent = (<p className={bodyTextClasses}>{finalBodyText}</p>);
        }

        return bodyContent;
    }

    renderExtraContent(children, bodyText, onlyChildren) {
        let extraContent;
        if (children) {
            const extraContentClasses = classNames({
                'ok-informative-list-item__body-text': onlyChildren,
                'ok-informative-list-item__extra-content': !!bodyText && !onlyChildren
            });
            extraContent = (
                <div className={extraContentClasses}>
                    {children}
                </div>
            );
        }

        return extraContent;
    }

    renderIconContent() {
        const {
            icon,
            information,
            onTooltipClick,
            tooltipAlternativeIcon,
            tooltipModifier,
            tooltipOffset,
            tooltipPlacement,
            tooltipType,
            tooltipSize,
        } = this.props;

        let iconContent = null;
        if (!!icon) {
            iconContent = (
                <OKTooltip
                    alternativeIcon={tooltipAlternativeIcon}
                    content={information}
                    size={tooltipSize}
                    modifier={tooltipModifier}
                    offset={tooltipOffset}
                    placement={tooltipPlacement}
                    type={tooltipType}
                    onTooltipClick={onTooltipClick}
                />
            );
        }

        return iconContent;
    }

    renderTitle() {
        const title = this.props.title;
        let content;

        if (Array.isArray(title)) {
            content = this.renderArrayOfTitles(title);
        } else {
            content = this.renderTitleText(title);
            content = this.renderTitleContainer(content, this.props.compact, true);
        }

        return content;
    }

    renderTitleContainer(title, compact, showIcon, key) {
        const {
            deleteButton,
            editButton,
            boldTitle,
        } = this.props;

        let iconContent;

        if (showIcon) { // prevents repetition of the icon on the titles.
            iconContent = this.renderIconContent();
        }

        const titleClasses = classNames('ok-informative-list-item__title', {
            'ok-informative-list-item__title--uppercase': this.props.isUppercaseTitle,
            'ok-informative-list-item__title--dark': this.props.isDarkTitle,
            'ok-informative-list-item__title--compact': compact,
            'ok-informative-list-item__title--bold' : boldTitle
        });

        return (
            <p key={key} className={titleClasses}>
                {title}
                {iconContent}
                {editButton}
                {deleteButton}
            </p>
        );
    }

    renderTitleText(title) {
        const { additionalLabel } = this.props;
        let finalTitle;
        if (hasTranslation(title)) {
            finalTitle = <FormattedText value={title} />;
        } else {
            finalTitle = title;
        }

        return (
            <React.Fragment>
                {finalTitle} {additionalLabel}
            </React.Fragment>
        );
    }

    render() {
        const {
            bodyText,
            bodyWordBreak,
            children,
            className,
            onlyChildren
        } = this.props;

        const listClasses = classNames('ok-informative-list-item', className);

        const titleElement = this.renderTitle();
        const bodyContent = this.renderBodyContent(bodyText, bodyWordBreak);
        const extraContent = this.renderExtraContent(children, bodyText, onlyChildren);

        return (
            <li className={listClasses}>
                {titleElement}
                {bodyContent}
                {extraContent}
            </li>
        );
    }
}

OKInformativeListItem.propTypes = {
    title: PropTypes.oneOfType([PropTypes.array, PropTypes.string]), // This can be text, a translation key or an array of them
    bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // This can be text or translation key
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    className: PropTypes.string,
    boldTitle: PropTypes.bool,
    deleteButton: PropTypes.object,
    additionalLabel: PropTypes.string,
    editButton: PropTypes.object,
    isDarkTitle: PropTypes.bool,
    isUppercaseTitle: PropTypes.bool,
    icon: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string // For retrocompatibility string is kept, but booleans should be used from now on
    ]),
    tooltipAlternativeIcon: PropTypes.bool,
    information: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    tooltipSize: PropTypes.oneOf(['large', 'medium']),
    tooltipModifier: PropTypes.string,
    tooltipOffset: PropTypes.array,
    tooltipPlacement: PropTypes.string,
    tooltipType: PropTypes.string,
    compact: PropTypes.bool,
    bodyWordBreak: PropTypes.bool,
    onlyChildren: PropTypes.bool,
    onTooltipClick: PropTypes.func,
};

OKInformativeListItem.defaultProps = {
    title: '',
    bodyText: '',
    className: '',
    additionalLabel: '',
    boldTitle: false,
    deleteButton: null,
    editButton: null,
    isDarkTitle: false,
    isUppercaseTitle: false,
    tooltipSize: 'medium',
    tooltipPlacement: 'right',
    iconCustomClass: '',
    tooltipOffset: [0, 0],
    compact: false,
    bodyWordBreak: true,
    onlyChildren: false
};

module.exports = OKInformativeListItem;
