const { getLanguage } = require('core/i18n').i18n;

let localLang = getLanguage();

const image1 = require(`assets/images/tutorials/${ localLang }/general1/slide1.svg`);
const image2 = require(`assets/images/tutorials/${ localLang }/general1/slide2.svg`);
const image3 = require(`assets/images/tutorials/${ localLang }/general1/slide3.svg`);

const images = [image1, image2, image3];

module.exports = images;
