require('./crossSellingProduct.scss');

// @ vendors
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

class CrossSellingProduct extends React.Component {
    render() {
        const { description, modifier, onClick , title } = this.props;
        const className = classNames('cross-selling', `cross-selling--${modifier}`);

        return (
            <div className={className} onClick={onClick}>
                <div className="cross-selling__content">
                    <h4 className="cross-selling__title">
                        {title}
                    </h4>
                    <p className="cross-selling__description">
                        {description}
                    </p>
                </div>
            </div>
        )
    }
}

CrossSellingProduct.propTypes = {
    description: PropTypes.string,
    modifier: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string
};

module.exports = CrossSellingProduct;
