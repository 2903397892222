const PropTypes = require('prop-types');
// @vendors
const React = require('react');

// @helpers
const { formatText, getLanguage } = require('core/i18n').i18n;
const { getLastNChars } = require('utilities/stringHelper');
const { formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');

// @components
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');

const { HYPHEN } = require('constants/index');

class StocksContractDropdownValue extends React.Component {
    render() {
        const {walletAccountType, walletNumber, walletBalance} = this.props.value;
        let walletBalanceLegend = (
            <span className="stocks-contract-dropdown__value--right"> {HYPHEN} </span>
        );

        if (walletBalance.amount || walletBalance.amount === 0) {
            const plainAmountObject = getPlainAmountObject(walletBalance);

            walletBalanceLegend = (
                <span className="stocks-contract-dropdown__value--right">
                    <span>{formatText('broker-stocksBuyStep1AssociatedAccountBalance')}</span>
                    <span className="stocks-contract-dropdown__value-price stocks-contract-dropdown__value--highlighted">
                        <OneLineSymbolAmount
                            amountObject={plainAmountObject}
                            language={getLanguage()}
                            amountObjectFormatted={formatAmountObject(plainAmountObject, true)}
                        />
                    </span>
                </span>
            );
        }

        return (
            <div className="stocks-contract-dropdown__value" onMouseDown={this.handleMouseDown}>
                <span className="stocks-contract-dropdown__value-label stocks-contract-dropdown__value--highlighted">
                    {walletAccountType}
                </span>
                <span className="stocks-contract-dropdown__value--medium">{getLastNChars(walletNumber)}</span>

                {walletBalanceLegend}
            </div>
        );
    }
}

StocksContractDropdownValue.propTypes = {
    value: PropTypes.object
};

module.exports = StocksContractDropdownValue;