/**
 * This is a carousel created using the following tool:
 *
 * https://github.com/akiran/react-slick
 *
 * Its properties are:
 *
 * * settings: the properties of the carousel.
 * * blocks: the blocks that the carousel is going to manage.
 * * leftButtonClass: styles for the container and the inner div.
 * * rightButtonClass: styles for the container and the inner div.
 * * leftIcon: in case of having an icon defined, we need to set <i /> styles.
 * * rightIcon: in case of having an icon defined, we need to set <i /> styles.
 *
 * The two considerations we have here are:
 *
 * * The current tab cannot be greater than the last block of full tabs to show
 *   according to the tabsPerClick variable.
 * * The right button has to be disable in that case.
 */

/* eslint-disable react/jsx-handler-names */

require('./OKCarousel.scss');

const PropTypes = require('prop-types');

// @vendors
const React = require('react');
const {
    Component
} = require('react');
const Carousel = require('react-slick').default;
const classNames = require('classnames');
// @ constants
const {ADOBE_NOT_INTERESTED_STATUS_CODE} = require('constants/adobe');
class OKCarousel extends Component {

    constructor(props) {
        super(props);
        const { blocks, settings } = props;

        let enableLeftButton = false;
        let enableRightButton = true;

        let rightLimit = blocks.length - settings.slidesToShow;
        if (settings.initialSlide >= rightLimit) {
            enableRightButton = false;
        }

        if (settings.initialSlide && settings.initialSlide > 0) {
            enableLeftButton = true;
        }

        this.state = {
            enableLeftButton,
            enableRightButton,
            rightLimit,
            currentSlide: settings.initialSlide
        };

        this.goToSlide = this.goToSlide.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleAfterChange = this.handleAfterChange.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    componentDidMount() {
        if (!!this.props) {
            this.goToSlide();
        }
    }

    componentDidUpdate(prevProps) {
        const { blocks, settings } = this.props;
        const prevBlocks =  prevProps.blocks;
        const blocksUpdate = prevBlocks.length !== blocks.length;

        if (!!this.props && (this.props.settings.initialSlide !== prevProps.settings.initialSlide)) {
            this.goToSlide();
        }

        if (blocksUpdate && blocks.length > 0) {
             let rightLimit = blocks.length - settings.slidesToShow;
             if (settings.initialSlide >= rightLimit) {
                 this.updateState({
                     enableRightButton: false,
                     rightLimit: rightLimit
                 });
             } else {
                 this.updateState({
                     enableRightButton: true,
                     rightLimit: rightLimit
                 });
             }
        }
     }

    componentWillUnmount(){
        (this.intervalId) && clearInterval(this.intervalId);
    }

    updateState(newState) {
        this.setState(newState);
    }

    goToSlide() {
        const {customInitial, numberSlides, settings} =this.props;
        const {initialSlide, slidesToShow} = settings;
        if (customInitial && !!settings) {
            if (initialSlide < slidesToShow) {
                this.slider.slickGoTo(0);
            } else if (initialSlide + 1 > (numberSlides - slidesToShow)) {
                this.slider.slickGoTo(numberSlides);
            }
            else {
                this.slider.slickGoTo(initialSlide);
            }
        }
    }

    handleNext() {
        const { settings } = this.props;
        let tabsLeft = this.state.rightLimit - this.state.currentSlide || 0;

        if (tabsLeft < settings.slidesToShow) {
            this.slider.slickGoTo(this.state.currentSlide + tabsLeft);
        } else {
            this.slider.slickNext();
        }
    }

    handlePrevious() {
        this.slider.slickPrev();
    }

    handleAfterChange(event) {

        if(this.props.cycleCarousel){
            //send not-interested(9) status to adobe
            const {ads, updateCommercialAdsStatus} = this.props;
            let immAd = ads[event];
            if(immAd.getIn(['offerSpaceName'])){
                updateCommercialAdsStatus(immAd, ADOBE_NOT_INTERESTED_STATUS_CODE)
            }
        }
        if (event > 0) {
            this.setState({
                enableLeftButton: true
            });
        } else {
            this.setState({
                enableLeftButton: false
            });
        }

        if (event >= this.state.rightLimit) {
            this.setState({
                enableRightButton: false
            });
        } else {
            this.setState({
                enableRightButton: true
            });
        }

        this.setState({
            currentSlide: event
        });
    }

    getLeftButton() {
        const { enableLeftButton } = this.state;
        const { leftIcon, leftButtonClass } = this.props;

        let leftButton = (
            <div onClick={this.handlePrevious}>
                { leftIcon && <i className={`icon ${ leftIcon }`} />}
            </div>
        );

        if (!enableLeftButton) {
            leftButton = null;
        }

        return <div className={leftButtonClass}>{leftButton}</div>;
    }

    getRightButton() {

        const { enableRightButton } = this.state;
        const { rightIcon, rightButtonClass } = this.props;

        let rightButton = (
            <div onClick={this.handleNext}>
                { rightIcon && <i className={`icon ${ rightIcon }`} />}
            </div>
        );

        if (!enableRightButton) {
            rightButton = null;
        }

        return <div className={rightButtonClass}>{rightButton}</div>;
    }

    render() {

        const leftButton = this.getLeftButton();
        const rightButton = this.getRightButton();
        const { customClass, settings, blocks } = this.props;

        const finalClass = classNames('ok-carousel', customClass);

        return (
            <div className={finalClass}>
                {leftButton}
                <Carousel
                    ref={slider => this.slider = slider}
                    afterChange={this.handleAfterChange}
                    {...settings}>
                    {blocks}
                </Carousel>
                {rightButton}
            </div>
        );
    }
}

OKCarousel.propTypes = {
    customClass: PropTypes.string,
    settings: PropTypes.object.isRequired,
    blocks: PropTypes.array.isRequired,
    ads:PropTypes.array,
    leftButtonClass: PropTypes.string,
    rightButtonClass: PropTypes.string,
    leftIcon: PropTypes.string,
    rightIcon: PropTypes.string,
    cycleCarousel: PropTypes.bool,
    cycleTime: PropTypes.number,
    customInitial: PropTypes.bool,
    numberSlides: PropTypes.number,
    updateCommercialAdsStatus: PropTypes.func.isRequired
};

OKCarousel.defaultProps = {
    cycleCarousel: false,
    cycleTime: 1000
};

module.exports = OKCarousel;
