require('./brokerEditField.scss');

const PropTypes = require('prop-types');
const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const get = require('lodash/object/get');
const { getTextWidth } = require('commonsUtilities/textWidth');
const { getFirstNChars } = require('utilities/stringHelper');
const { KEYBOARD_ESC, KEYBOARD_RETURN } = require('constants/index');
const { InputSafe } = require('commonsComponents/inputSafe');

class BrokerEditField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fieldValue: this.props.initialValue
        };

        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleOnKey = this.handleOnKey.bind(this);
        this.setNode = this.setNode.bind(this);
    }

    componentDidMount() {
        let inputLength = this.state.fieldValue.length;
        /* place caret at end of input */
        this.nodeReference.setSelectionRange(inputLength, inputLength);
        this.setWidth();
    }

    componentDidUpdate() {
        this.setWidth();
    }

    componentWillUnmount() {
        this.canvasElem = null;
    }

    setNode(ref) {
        this.nodeReference = get(ref, 'el');
        this.canvasElem = document.createElement('canvas');
    }

    setWidth() {
        const { allowMaxLength, font, placeholder, widthOffset } = this.props;
        const { fieldValue } = this.state;
        const inputString = placeholder && fieldValue === '' ? placeholder : fieldValue;
        const stringLength = allowMaxLength ? inputString : getFirstNChars(inputString, 20);
        const textWidth = getTextWidth(stringLength, font, this.canvasElem);
        this.nodeReference.style.width = widthOffset + textWidth + 'px';
    }

    handleBlur(evt) {
        const { initialValue } = this.props;
        const currentValue = get(evt, 'target.value', '').trim();
        const result = {
            hasChanged: currentValue !== initialValue,
            value: currentValue,
        };

        this.props.blurCallback(result);
    }

    handleChange(evt) {
        this.setState({
            fieldValue: evt.target.value
        })
    }

    handleClick(evt) {
        evt.stopPropagation();
    }

    handleOnKey(evt) {
        switch (evt.keyCode) {
            case KEYBOARD_ESC:
                this.props.blurCallback({
                    hasChanged: false
                });
                break;
            case KEYBOARD_RETURN:
                this.handleBlur(evt);
                break;
        }
    }

    render() {
        const value = this.state.fieldValue;
        const inputStyle = this.props.allowMaxLength && value.length > 20 ? {maxWidth: '310px'} : null;
        return (
            <InputSafe
                overrideUI={true}
                ref={this.setNode}
                className="input-broker-edit-field"
                type="text"
                autoFocus="true"
                value={value}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onClick={this.handleClick}
                onMouseDown={this.handleClick}
                onKeyUp={this.handleOnKey}
                autoComplete="off"
                maxLength={this.props.limit}
                placeholder={this.props.placeholder}
                style={inputStyle}
            />)
    }
}

BrokerEditField.propTypes = {
    allowMaxLength: PropTypes.bool,
    initialValue: PropTypes.string,
    blurCallback: PropTypes.func,
    font: PropTypes.string,
    placeholder: PropTypes.string,
    widthOffset: PropTypes.number,
    limit: PropTypes.number
};

BrokerEditField.defaultProps = {
    allowMaxLength: false,
    widthOffset: 30,
    font: '18px PrimaryFontBold',
    placeholder: ''
};

module.exports = BrokerEditField;
