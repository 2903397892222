require('./nextButtonWrapper.scss');
// @ vendor
const React = require('react');
const classNames = require('classnames');
// @ core
const { formatText } = require('core/i18n').i18n;
// @components
const { OKButton } = require('components/commons/OKButton/OKButton');
const PropTypes = require('prop-types');

class NextButtonWrapper extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dependenciesAreResolved: false
        }

        this.handleChildrenOnResolvedDependencies = this.handleChildrenOnResolvedDependencies.bind(this);
    }

    handleChildrenOnResolvedDependencies(resolved) {
        this.setState({
            dependenciesAreResolved: resolved
        });
    }

    isAbleToContinue(){
        const { canContinue: externalDependenciesAreResolved } = this.props;

        return externalDependenciesAreResolved;
    }

    buildContinueButton() {
        const { onContinue, buttonContainerClassName } = this.props;
        const displayWizardButtonText = formatText("commons-continue");
        const buttonContainerClassNames = classNames('next-button-wrapper__next-button-container', buttonContainerClassName);

        return (
            <div className={buttonContainerClassNames}>
                <div className="next-button-wrapper__next-button">
                    <OKButton
                        onClick={onContinue}
                        disabled={!this.isAbleToContinue()}
                        modifier="secondary"
                    >
                        {displayWizardButtonText}
                    </OKButton>
                </div>
            </div>
        );
    }

    buildChildren() {
        const { children } = this.props;
        return React.cloneElement(children, Object.assign({}, ...children.props, {
            onResolvedDependencies:this.handleChildrenOnResolvedDependencies
        }));
    }

    render() {
        const { dependenciesAreResolved } = this.state;
        const { childrenHaveDependencies } = this.props;
        const showContinueButton = !childrenHaveDependencies || (childrenHaveDependencies && dependenciesAreResolved);

        return (
            <div className="next-button-wrapper">
                {this.buildChildren()}
                {showContinueButton && this.buildContinueButton()}
            </div>
        );
    }
}

NextButtonWrapper.propTypes = {
    buttonContainerClassName: PropTypes.string,
    canContinue: PropTypes.bool,
    children: PropTypes.node.isRequired,
    childrenHaveDependencies: PropTypes.bool,
    onContinue: PropTypes.func.isRequired,
};

NextButtonWrapper.defaultProps = {
    childrenHaveDependencies: true
}

module.exports = NextButtonWrapper;
