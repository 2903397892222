require('./wizardStep.scss');

const PropTypes = require('prop-types');

// @ vendors
const React = require('react');
const throttle = require('lodash/function/throttle');
const classnames = require('classnames');
// @ helpers
const { formatText } = require('core/i18n').i18n;
const { toFirstUppercase } = require('utilities/stringHelper');
// @ components
const { OKButton } = require('components/commons/OKButton/OKButton');
const PrinterPreviewManagerButton = require('components/contactCenter/printer/printerPreviewManagerButton');
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');
const SignaturePatternButton = require('components/signaturePattern/signaturePatternButton/signaturePatternButton');
const OKModal = require('components/commons/OKModal/OKModal');
const WebappRender = require('components/conditionalRender/webappRender')
const ContactCenterRender = require('components/conditionalRender/contactCenterRender');
// @ utils
import { clickTaleStart, clickTaleEnd } from 'utilities/clickTale';

class WizardStep extends React.Component {
    constructor(props) {
        super(props);

        this.getFinishButton = this.getFinishButton.bind(this);
        this.getNextButton = this.getNextButton.bind(this);
        this.handlePrevClick = this.handlePrevClick.bind(this);
        this.handleOnCloseModal = this.handleOnCloseModal.bind(this);
        this.handleNextClickActions = this.handleNextClickActions.bind(this);

        // Call click event only once during the period of one second. This prevents to accidentally trigger multiple calls of the same operation.
        this.handleFinishClick = throttle(this.handleFinishClick.bind(this), 1000, { trailing: false });

        this.state = {
            showModal: false
        };
    }

    componentDidMount() {
        clickTaleStart();
    }

    componentWillUnmount() {
        clickTaleEnd();
    }

    getCancelButton() {
        const { cancelButtonText, disableCancel } = this.props;
        const buttonText = cancelButtonText ? cancelButtonText : formatText('buttonsActions-cancel');
        const finalButtonText = toFirstUppercase(buttonText);

        return (
            <div className="wizard-step__first-button">
                <OKButton
                    modifier={disableCancel ? "ghost" : "link-ghost"}
                    onClick={this.props.actions.handleCancelClick}
                    disabled={disableCancel}
                >
                    {finalButtonText}
                </OKButton>
            </div>
        );
    }

    getFinishButton() {
        const {
            isLoading,
            addLoadingBehaviour,
            finalButtonModifier,
            finalButtonDisabled,
            signaturePatternCode,
            ccDefaultBehaviour,
            officeSignature
        } = this.props;
        const buttonText = formatText(this.props.immFinalButton);
        let CurrentButton = addLoadingBehaviour ? SignaturePatternButton : OKButton;

        if(officeSignature){
            return <PrinterPreviewManagerButton 
                    {...this.props} 
                    officeSignature
                    rightIcon={this.props.finalButtonIcon}
                    onClick={this.handleFinishClick}
                    loading={isLoading || this.props.isFetching}
                    modifier={finalButtonModifier}
                    disabled={finalButtonDisabled}>{buttonText}
                    </PrinterPreviewManagerButton>   
        }

        if (__CONTACT_CENTER__ && !signaturePatternCode && !ccDefaultBehaviour) {
            CurrentButton = PrinterPreviewManagerButton; // This Button manages PrinterPreviewManager enabled in ContractConfirmationStep
        }
            
        return (
            <div className="wizard-step__fourth-button">
                <CurrentButton
                    rightIcon={this.props.finalButtonIcon}
                    onClick={this.handleFinishClick}
                    loading={isLoading || this.props.isFetching}
                    modifier={finalButtonModifier}
                    disabled={finalButtonDisabled}
                >
                    {buttonText}
                </CurrentButton>
            </div>
        );
    }

    getGoBackButton() {
        const backText = toFirstUppercase(formatText('buttonsActions-goBack'));
        return (
            <div className="wizard-step__first-button">
                <OKButton
                    modifier="link-ghost"
                    onClick={this.props.actions.handleGoBackClick}
                >
                    {backText}
                </OKButton>
            </div>
        );
    }

    getLeftCustomButton() {
        const leftCustomButton = this.props.leftCustomButton;

        return (
            <div className="wizard-step__first-button">
                {leftCustomButton}
            </div>
        );
    }

    handleNextClickActions() {
        const { onNextClick } = this.props;

        !!onNextClick && onNextClick();
        this.props.actions.handleNextClick();
    }

    getNextButton() {
        const {
            addLoadingBehaviour,
            isLoading,
            nextButtonDisabled,
            tooltipNextButtonContent,
            tooltipNextButton,
            nextButtonCustomText,
            rightIconModifier,
            nextBtnModifier
        } = this.props;
        const CurrentButton = addLoadingBehaviour ? SignaturePatternButton : OKButton;
        const rightIcon = rightIconModifier ? "icon-siguiente" : "";

        let buttonText;
        let button;
        if (!!nextButtonCustomText) {
            buttonText = nextButtonCustomText;
        } else {
            buttonText = toFirstUppercase(formatText('buttonsActions-next'));
        }

        if (tooltipNextButton && nextButtonDisabled) {
            const tooltipTarget = (
                <div className="wizard-step__tooltip">
                    <CurrentButton
                        modifier="secondary"
                        rightIcon={rightIcon}
                        onClick={this.props.actions.handleNextClick}
                        loading={isLoading}
                        disabled={nextButtonDisabled}
                    >
                        {buttonText}
                    </CurrentButton>
                </div>
            );
            button = (
                <OKTooltip
                    content={tooltipNextButtonContent}
                    offset={[0, -15]}
                    target={tooltipTarget}
                />
            );
        } else {
            button = (
                <CurrentButton
                    modifier={nextBtnModifier}
                    rightIcon="icon-siguiente"
                    onClick={this.handleNextClickActions}
                    loading={isLoading}
                    disabled={nextButtonDisabled}
                >
                    {buttonText}
                </CurrentButton>
            )
        }

        return (
            <div className="wizard-step__third-button">
                {button}
            </div>
        );
    }

    getPreviousButton() {
        const buttonText = toFirstUppercase(formatText('buttonsActions-previous'));
        const { onBackClick, disablePrevious, actions: { handlePrevClick } } = this.props;
        let onClick = handlePrevClick;

        if (onBackClick !== false) {
            onClick = () => {
                this.setState({
                    showModal: true
                });
            };
        }

        return (
            <div className="wizard-step__second-button">
                <OKButton modifier="ghost" leftIcon="icon-anterior" onClick={onClick} disabled={disablePrevious} >
                    {buttonText}
                </OKButton>
            </div>
        );
    }

    buildButtonsSection() {
        const cancelButton = this.props.cancelButton ? this.getCancelButton() : null;
        const goBackButton = this.props.goBackButton ? this.getGoBackButton() : null;
        // A button with custom behaviour and styles that is placed on the bottom-left corner of the step.
        const leftCustomButton = this.props.leftCustomButton ? this.getLeftCustomButton() : null;
        let previousButton;
        let nextButton;
        let finishButton;
        if (this.props.omitPreviousButtonWP) {
            switch (this.props.position) {
                case 'first':
                    nextButton = this.getNextButton();
                    break;
                case 'middle':
                    if (!this.props.omitPreviousButton) {
                        previousButton = this.getPreviousButton();
                    }
                    nextButton = this.getNextButton();
                    break;
                case 'last':
                    if (!__CONTACT_CENTER__ || !this.props.omitPreviousButton) {
                        previousButton = this.getPreviousButton();
                    }
                    finishButton = this.props.overrides.FinalButton ? this.props.overrides.FinalButton : this.getFinishButton();
                    break;
                default:
                    nextButton = this.getNextButton();
                    break;
            }
        } else {
            switch (this.props.position) {
                case 'first':
                    nextButton = this.getNextButton();
                    break;
                case 'middle':
                    if (!__CONTACT_CENTER__ || !this.props.omitPreviousButton) {
                        previousButton = this.getPreviousButton();
                    }
                    nextButton = this.getNextButton();
                    break;
                case 'last':
                    if (!__CONTACT_CENTER__ || !this.props.omitPreviousButton) {
                        previousButton = this.getPreviousButton();
                    }
                    finishButton = this.getFinishButton();
                    break;
                default:
                    nextButton = this.getNextButton();
                    break;
            }
        }


        return (
            <footer className="wizard-step__buttons">
                {goBackButton}
                {leftCustomButton}
                {cancelButton}
                {previousButton}
                {nextButton}
                {finishButton}
            </footer>
        );
    }

    handleFinishClick() {
        this.props.actions.handleFinishClick();
    }

    handleOnCloseModal() {
        this.setState({
            showModal: false
        });
    }

    handlePrevClick() {
        const { confirmBackClick, actions: { handlePrevClick } } = this.props;
        this.setState({
            showModal: false
        });
        confirmBackClick && confirmBackClick();
        handlePrevClick();
    }

    modalCustom() {
        const { confirmTitle, confirmDescription } = this.props;

        let component = null;
        if (confirmTitle) {
            component = (
                <OKModal
                    description={confirmDescription}
                    footerType="navigation"
                    isOpen={this.state.showModal}
                    onCloseCallback={this.handleOnCloseModal}
                    onPrimaryButtonClick={this.handlePrevClick}
                    onSecondaryButtonClick={this.handleOnCloseModal}
                    primaryButtonText="contractsView-checkingAccountStep4BackCloseButton"
                    secondaryButtonText="contractsView-checkingAccountStep4BackStayHereButton"
                    title={confirmTitle}
                />
            );
        }

        return component;
    }

    render() {
        const { children, isShowOnly, stepNo, isVerticalView } = this.props
        let myChildren = children;
        const wizardStepsClass = classnames(
            'wizard-step',
            { 'wizard-step--vertical': isVerticalView && __CONTACT_CENTER__ }
        )
        const stepIndicator = (isVerticalView) ? (
            <span className="wizard-step--vertical__progress-indicator">
                {stepNo}
            </span>
        ) : null;
        if (isShowOnly) {
            myChildren = React.Children.map(children, child => React.cloneElement(child, { isShowOnly: true }));
        }
        const buttonsSection = this.buildButtonsSection();
        return (
            <article className="wizard-step">
                <section className={wizardStepsClass}>
                    <WebappRender>
                        {children}
                    </WebappRender>
                    <ContactCenterRender>
                        {stepIndicator}
                        {myChildren}
                    </ContactCenterRender>
                </section>
                {this.modalCustom()}
                {buttonsSection}
            </article>
        );
    }
}

WizardStep.propTypes = {
    actions: PropTypes.object,
    addLoadingBehaviour: PropTypes.bool,
    cancelButton: PropTypes.bool,
    leftCustomButton: PropTypes.object,
    goBackButton: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    confirmBackClick: PropTypes.func.isRequired,
    confirmDescription: PropTypes.string,
    confirmTitle: PropTypes.string,
    finalButtonIcon: PropTypes.string,
    finalButtonDisabled: PropTypes.bool,
    immFinalButton: PropTypes.string,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    nextButtonDisabled: PropTypes.bool,
    nextButtonCustomText: PropTypes.object,
    onBackClick: PropTypes.bool,
    onNextClick: PropTypes.func,
    overrides: {
        Finalbutton: PropTypes.element,
    },
    position: PropTypes.oneOf(['first', 'middle', 'last']),
    cancelButtonText: PropTypes.string,
    isShowOnly: PropTypes.bool,
    stepNo: PropTypes.number,
    finalButtonModifier: PropTypes.string,
    tooltipNextButton: PropTypes.bool,
    tooltipNextButtonContent: PropTypes.string,
    isVerticalView: PropTypes.bool,
    omitPreviousButton: PropTypes.bool,
    rightIconModifier: PropTypes.bool,
    signaturePatternCode: PropTypes.number,
    nextBtnModifier: PropTypes.string,
    ccDefaultBehaviour: PropTypes.bool,
    disablePrevious:PropTypes.bool,
    disableCancel:PropTypes.bool,
    officeSignature: PropTypes.bool
};

WizardStep.defaultProps = {
    addLoadingBehaviour: false,
    confirmBackClick: function () { },
    finalButtonIcon: '',
    isLoading: false,
    nextButtonDisabled: false,
    finalButtonDisabled: false,
    onBackClick: false,
    onNextClick: null,
    overrides: {
        FinalButton: undefined
    },
    isShowOnly: false,
    isVerticalView: false,
    omitPreviousButton: false,
    nextBtnModifier: 'secondary',
    cancelButtonText: '',
    tooltipNextButton: false,
    tooltipNextButtonContent: '',
    rightIconModifier: true,
    ccDefaultBehaviour: false,
    disablePrevious:false,
    disableCancel:false,
    officeSignature: false
};

module.exports = WizardStep;