require('./preContractualMailInfo.scss');

// @vendor
const React = require('react');
const PropTypes = require('prop-types');

// @core
const { formatText } = require('core/i18n').i18n;

// @commons
const { OKButton } = require('components/commons/OKButton/OKButton');
const { HTMLSafeInjector } = require('commonsComponents/htmlSafeInjector');
const { withRouter } = require('react-router-dom');

// @Constants
import { SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM, SAVINGS_WITHOUT_CONDITION_WITH_TERM } from 'constants/eAccountLoan'
const ACCOUNT_KEY_SUFFIX = {
    'combined-deposit': '_combinedDeposit',
    'deposit-eighteen-months-retention': '_depositEighteenMonthsRetention',
    'deposit-twentyfour-months-retention': '_depositTwentyFourMonthsRetention',
}
const {
    OVERDRAFT_LOAN_MAILTYPE, EASY_LOAN_MAILTYPE
} = require('constants/index');

class PreContractualMailInfo extends React.Component {
    constructor(props) {
        super(props);
        this.handleSendPreContractualInfo = this.handleSendPreContractualInfo.bind(this);
        this.handleClientEmailRefresh = this.handleClientEmailRefresh.bind(this);
    }

    handleClientEmailRefresh() {
        const { requestProfile, tagRefreshMailEvent, history } = this.props;
        requestProfile(history);
        if (!!tagRefreshMailEvent) {
            tagRefreshMailEvent()
        }
    }

    handleSendPreContractualInfo() {
        const { sendEmail, sendMailParams, tagSendEmailEvent, sendSimpleEmail, contractObj, mainFeatureFlag, getContractDraftDocument, mainFlag } = this.props;
        if (!!mainFeatureFlag && !!contractObj && mainFlag === 'OVERDRAFT_PROTECTION') {
            sendSimpleEmail(OVERDRAFT_LOAN_MAILTYPE.PRECONTRACTUAL, contractObj.get('fileINEDocumentId'))
            getContractDraftDocument(true)
        }
        else if (!!mainFeatureFlag && !!contractObj && mainFlag === 'EASY_LOAN') {
            sendSimpleEmail(EASY_LOAN_MAILTYPE.PRECONTRACTUAL, contractObj.get('fileEasyLoanIneDocumentId'))
            getContractDraftDocument(EASY_LOAN_MAILTYPE.CONTRACTUAL);
        }
        else if (mainFlag === 'EASY_LOAN_TYPEC' && !!contractObj) {
            sendSimpleEmail(EASY_LOAN_MAILTYPE.PRECONTRACTUAL, contractObj.get('fileEasyLoanTypecIneDocumentId'))
        }
        else {
            sendEmail(sendMailParams);
        }
        if (!!tagSendEmailEvent) {
            tagSendEmailEvent();
        }
    }

    buildLegalTextArea() {
        const { showLegalTextAreaAsWarning, legalTextAreaLabel, eAccountType } = this.props;
        const contractName = this.props.contractName;
        const accountKey = ACCOUNT_KEY_SUFFIX[contractName] ? ACCOUNT_KEY_SUFFIX[contractName] : '';
        let relevantInfo = legalTextAreaLabel || formatText('legalConfirmation-readRelevantInformation' + accountKey);
        relevantInfo = showLegalTextAreaAsWarning ? formatText('legalConfirmation-readRelevantInfoWarning') : relevantInfo;
        const readRelevantInformation = (<HTMLSafeInjector message={relevantInfo} />);
        const mandatoryReading = (<HTMLSafeInjector message={formatText('legalConfirmation-mandatoryReading' + accountKey)} />);
        const indicativeOfRisk = (<HTMLSafeInjector message={formatText('legalConfirmation-indicativeOfRisk' + accountKey)} />);
        const depositsGuaranteeFound = (<HTMLSafeInjector message={formatText('legalConfirmation-depositsGuaranteeFound' + accountKey)} />);
        const liquidityAlert = <HTMLSafeInjector message={formatText('legalConfirmation-liquidityAlert' + accountKey)} />;
        let content = null;

        switch (contractName) {
            case 'open-young':
            case 'open-debit':
            case 'mini-card':
            case 'diamond-pack':
            case 'premium-pack':
            case 'visa-open':
            case 'pre-granted-credit':
            case 'via-t':
            case 'solidario':
            case 'at-home-abroad':
            case 'virtual-credit':
            case 'revolving':
            case 'virtual-only-debit':
            case 'maestro':
            case 'metallic':
                content = (
                    <div>
                        {readRelevantInformation}
                    </div>
                );
                break;
            case 'e-account-loan':
                let eAccountLegalPoeKey = ''

                if (eAccountType === SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM) {
                    eAccountLegalPoeKey = 'cc_legalConfirmation-readWithoutBonusWithoutTerm'
                }

                if (eAccountType === SAVINGS_WITHOUT_CONDITION_WITH_TERM) {
                    eAccountLegalPoeKey = 'cc_legalConfirmation-readWithoutBonusWithTerm'
                }

                content = (
                    <div>
                        <HTMLSafeInjector message={formatText(eAccountLegalPoeKey)} />
                    </div>
                );
            case 'junior-account':
            case 'checking-account':
            case 'payroll-account':
            case 'upgrade-to-payroll-account':
                content = (
                    <div>
                        {readRelevantInformation}
                        {mandatoryReading}
                        <ul>
                            <li>{indicativeOfRisk}</li>
                            <li>{depositsGuaranteeFound}</li>
                        </ul>
                    </div>
                );
                break;
            case 'deposit-thirteen-months':
            case 'deposit-thirteen-months-due-dates':
            case 'deposit-thirteen-months-excepciones':
            case 'deposit-thirteen-months-leak-retention':
            case 'deposit-thirteen-months-linked-maturities':
            case 'deposit-thirteen-months-renovacion-excepciones':
            case 'deposit-eighteen-months-retention':
            case 'deposit-eighteen-months':
            case 'combined-deposit':
            case 'deposit-twentyfour-months-retention':
                content = (
                    <div>
                        {readRelevantInformation}
                        {mandatoryReading}
                        <ul>
                            <li>{indicativeOfRisk}</li>
                            <li>{depositsGuaranteeFound}</li>
                            <li>{liquidityAlert}</li>
                        </ul>
                    </div>
                );
                break;
        }

        return content;
    }

    buildSendPreContractualButton(hasBeenSent, isFetching) {
        let message;
        const { preContractualMail, email, disableSendButton, loadingSendButton, contractObj } = this.props;
        const textPreContactualMailButton = formatText("preContractualMailInfo-textPreContactualMailButton");
        const overdraftEmailError = !!contractObj && contractObj.get('overdraftEmailError');
        const overdraftEmailLoading = !!contractObj && contractObj.get('overdraftEmailLoading');
        const isOverdraftEmailSent = !!contractObj && contractObj.get('isOverdraftEmailSent');
        const loading = isFetching || overdraftEmailLoading;
        const mailError = preContractualMail.get('error');
         if ((!!contractObj && contractObj.get('mailAPIflag')) || disableSendButton || !(hasBeenSent || isOverdraftEmailSent)) {
            message = ''
        } else if (mailError || (typeof overdraftEmailError === 'boolean' && overdraftEmailError && !overdraftEmailLoading)) {
            message = formatText('preContractualMailInfo-errorMailSend');
        } else if (hasBeenSent || (isOverdraftEmailSent && !overdraftEmailError && !overdraftEmailLoading)) {
            message = formatText('preContractualMailInfo-successMailSend');
        }

        return (
            <div className="pre-contractual-mail__button">
                <OKButton
                    modifier="primary"
                    onClick={this.handleSendPreContractualInfo}
                    loading={loading || loadingSendButton}
                    disabled={!email || disableSendButton}
                >
                    {textPreContactualMailButton}
                </OKButton>
                <p className="pre-contractual-mail__button-error">{!disableSendButton && message}</p>
            </div>
        );
    }

    buildLegalTextBlock() {
        const { showLegalText, showLegalTextAreaAsWarning, biggerLegalTexts } = this.props;
        const textVal = formatText("preContractualMailInfo-textLabel");
        const textAreaContent = this.buildLegalTextArea();
        const textboxClassName = biggerLegalTexts ? "pre-contractual-mail__textbox-large" : "pre-contractual-mail__textbox";
        return showLegalText ? (
            <div>
                <div className="pre-contractual-mail__text-value">
                    {textVal}
                </div>
                <div className="pre-contractual-mail__textarea">
                    {showLegalTextAreaAsWarning ?
                        <div className="pre-contractual-mail__warning-box">
                            <i className="icon-warning"></i>
                            <div className="pre-contractual-mail__legal-text">
                                {textAreaContent}
                            </div>
                        </div> :
                        <div className={textboxClassName}>
                            {textAreaContent}
                        </div>
                    }
                </div>
            </div>
        ) : null;
    }

    buildLegalInfoText() {
        const { legalInfoText } = this.props;
        const information = formatText('preContractualMailInfo-information');
        return legalInfoText ? (
            <div className="pre-contractual-mail__information">
                <i className="icon-informacion"></i>
                {information}
            </div>
        ) : null;

    }


    render() {
        const {
            email,
            preContractualMail,
            profileError,
            showMailSendButton,
            ccFlag,
            mainFeatureFlag,
            mainFlag,
            contractObj,
            hasBeenSentEmail
        } = this.props;
        let hasBeenSent

        if (hasBeenSentEmail) {
            hasBeenSent = hasBeenSentEmail
        } else {
            hasBeenSent = mainFlag === 'EASY_LOAN_TYPEC' || (mainFlag === 'EASY_LOAN' && mainFeatureFlag) ? !!contractObj && contractObj.get('isEasyLoanEmailSent') : preContractualMail.get('mailSent');
        }
        const isFetching = preContractualMail.get('isFetching');
        const sendPreContractualButton = this.buildSendPreContractualButton(hasBeenSent, isFetching);
        const emailTitle = formatText('preContractualMailInfo-emailTitle');
        const errorMailRefresh = profileError ? formatText('preContractualMailInfo-errorMailRefresh') : '';
        const legalTextBlock = this.buildLegalTextBlock();
        const legalInfoBlock = this.buildLegalInfoText();

        const emailholder = email ? email : formatText('preContractualMailInfo-emailPlaceholder')

        return (
            <div className="pre-contractual-mail">
                {!!ccFlag &&
                    <React.Fragment>
                        <div className="pre-contractual-mail__email-container">
                            <div className="pre-contractual-mail__email">
                                <p className="pre-contractual-mail__title-email">{emailTitle}</p>
                                <span className="pre-contractual-mail__email-text">
                                    {emailholder}
                                </span>
                                <OKButton
                                    modifier="tasks-transparent"
                                    size="medium"
                                    onClick={this.handleClientEmailRefresh}
                                >
                                    <i className="icon-refrescar"></i>
                                </OKButton>
                                <p className="pre-contractual-mail__refresh-error">{errorMailRefresh}</p>
                            </div>
                            <p className="pre-contractual-mail__error-mail-refresh">{''}</p>
                        </div>
                        <div className="pre-contractual-mail__button-container">
                            {showMailSendButton && sendPreContractualButton}
                        </div>
                        {!mainFeatureFlag && legalInfoBlock}
                    </React.Fragment>
                }
                {legalTextBlock}
            </div>
        );
    }
}

PreContractualMailInfo.propTypes = {
    disableSendButton: PropTypes.bool,
    email: PropTypes.string.isRequired,
    sendEmail: PropTypes.func.isRequired,
    sendMailParams: PropTypes.object.isRequired,
    requestProfile: PropTypes.func.isRequired,
    preContractualMail: PropTypes.object.isRequired,
    profileError: PropTypes.string,
    contractName: PropTypes.string,
    legalTextAreaLabel: PropTypes.string,
    loadingSendButton: PropTypes.bool,
    showLegalText: PropTypes.bool.isRequired,
    legalInfoText: PropTypes.bool.isRequired,
    showLegalTextAreaAsWarning: PropTypes.bool,
    tagSendEmailEvent: PropTypes.func,
    tagRefreshMailEvent: PropTypes.func,
    showMailSendButton: PropTypes.bool,
    biggerLegalTexts: PropTypes.bool,
    history: PropTypes.object.isRequired,
    ccFlag: PropTypes.bool,
    mainFeatureFlag: PropTypes.bool,
    mainFlag: PropTypes.string,
    sendSimpleEmail: PropTypes.func,
    contractObj: PropTypes.object,
    getContractDraftDocument: PropTypes.func,
};

PreContractualMailInfo.defaultProps = {
    contractObj: null,
    disableSendButton: false,
    showLegalText: true,
    legalTextArea: null,
    legalInfoText: true,
    legalTextAreaLabel: '',
    loadingSendButton: false,
    showLegalTextAreaAsWarning: false,
    showMailSendButton: true,
    biggerLegalTexts: false,
    ccFlag: true,
    mainFlag: false,
}

module.exports = withRouter(PreContractualMailInfo);
