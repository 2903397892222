// @ vendors
const moment = require('moment');
const business = require('moment-business');

const getExtraTime = ( creationDate, targetDate ) => {
    const plazo_total = business.weekDays( moment(creationDate), moment(targetDate) ) + 1; //+1 porque se incluye el día inicial
    return plazo_total - 10;
}

//Fecha objetivo => Fecha objetivo - 5 dias laborales - rounddown(tiempo_extra/3)
const getTargetDateLivingPlace = ( creationDate, targetDate ) => {
    const workDays = 5;
    const extraTime = getExtraTime( creationDate, targetDate );
    return business.subtractWeekDays( moment(targetDate), workDays + 1 + Math.floor(extraTime/3) ); //+1 porque se incluye el día actual
}

//Fecha objetivo => Fecha objetivo - 9 dias laborales - 2 * rounddown(tiempo_extra/3)
const getTargetDateHolders = ( creationDate, targetDate ) => {
    const workDays = 9;
    const extraTime = getExtraTime( creationDate, targetDate );
    return business.subtractWeekDays( moment(targetDate), workDays + 1 + 2 * Math.floor(extraTime/3) ); //+1 porque se incluye el día actual
}

module.exports = {
    getExtraTime,
    getTargetDateLivingPlace,
    getTargetDateHolders
}
