const ACCOUNT_PRECAUTIONARY_WARNINGS = {
    REPOS_LIST_INDICATOR: 1,
    NEXT_PAGE_INDICATOR: 6,
    GROUP: 1,
    NOTICE_CIRCUIT_CODE: 1,
    ACTIVE_CODE: 'A',
    NO_END_LIST: 'N',
    END_LIST: 'S'
}

module.exports = ACCOUNT_PRECAUTIONARY_WARNINGS;
