const { formatText } = require('core/i18n').i18n;


const EMPLOYMENT_SITUATION = {
    TRABAJADOR_CUENTA_AJENA: 'TRABAJADOR_CUENTA_AJENA',
    FUNCIONARIO_MUFACE: 'FUNCIONARIO_MUFACE',
    FUNCIONARIO_SEG_SOCIAL: 'FUNCIONARIO_SEG_SOCIAL',
    AUTONOMO: 'AUTONOMO',
    PARADO: 'PARADO',
    PENSIONISTA: 'PENSIONISTA',
    RENTISTA_OTROS: 'RENTISTA_OTROS'
};

const EMPTY_SELECTOR_FIELD = 'UNKNOWN'

const LEVEL_STUDIES = {
    SIN_ESTUDIOS: 'SIN_ESTUDIOS',
    PRIMARIOS: 'PRIMARIOS',
    BACHILLER: 'BACHILLER',
    UNIVERSITARIOS_MEDIOS: 'UNIVERSITARIOS_MEDIOS',
    TITULADO_SUPERIOR: 'TITULADO_SUPERIOR'
};

const WORK_CONTRACT = {
    FIJO: 'FIJO',
    TEMPORAL: 'TEMPORAL',
    PRACTICAS: 'PRACTICAS',
    FUNCIONARIO_INTERINO: 'FUNCIONARIO_INTERINO'
};

const HOUSING_OWNERSHIP_TYPE = {
    ALQUILER: 'ALQUILER',
    FAMILIAR: 'FAMILIAR',
    PROPIEDAD_HIPOTECADA: 'PROPIEDAD_HIPOTECADA',
    PROPIEDAD_SIN_CARGAS: 'PROPIEDAD_SIN_CARGAS',
    OTROS: 'OTROS',
    UNKNOWN: 'UNKNOWN'
};

const ESTATES_TYPE = {
    VIVIENDA: 'VIVIENDA',
    GARAJE: 'GARAJE',
    TRASTERO: 'TRASTERO'
};

const DOCUMENTS_STATUS = {
    NONE: 'NONE',
    EXENTO: 'EXENTO',
    PEND_APORTAR: 'PEND_APORTAR',
    PEND_VAL: 'PEND_VAL',
    VERIFICADO: 'VERIFICADO',
    NO_VALIDO: 'NO_VALIDO',
    BORRADOR: 'BORRADOR',
    PROBLEMA_NS: 'PROBLEMA_NS',
    PEND_RESP_OCR: 'PEND_RESP_OCR'
};

const COMMONS = {
    INCIDENCIAS_PHONE: '+34 900 223 242',
    PRESTAMOS_PHONE: '+34 900 100 349',
    TASACION_PHONE: '+34 900 100 349',
    MORTGAGE_CONFIRMATION_PHONE: '+34 900 100 349',
    MORTGAGE_DENIAL_PHONE: '+34 900 100 349',
    MORTGAGE_ESTATES_HAS_NS_PHONE: '+34 900 100 349',
    MORTGAGE_HOLDER_INCIDENCIAS_PHONE: '+34 90 022 32 42'
};

const OTHER_LOANS_TYPES = {
    MORTGAGE: 'MORTGAGE',
    PERSONAL_LOAN: 'PERSONAL_LOAN'
}
const OTHER_LOANS_FILE_TYPES = {
    MORTGAGE: 'RECIB_HIPO',
    PERSONAL_LOAN: 'RECIB_PREST'
};

const getWorkInfo = () => {
    return {
        nivelEstudios: [
            { label: formatText('mortgageRequestWorkData-selectSinEstudios'), value: LEVEL_STUDIES.SIN_ESTUDIOS },
            { label: formatText('mortgageRequestWorkData-selectPrimarios'), value: LEVEL_STUDIES.PRIMARIOS },
            { label: formatText('mortgageRequestWorkData-selectBachiller'), value: LEVEL_STUDIES.BACHILLER },
            { label: formatText('mortgageRequestWorkData-selectUniversitariosMedios'), value: LEVEL_STUDIES.UNIVERSITARIOS_MEDIOS },
            { label: formatText('mortgageRequestWorkData-selectTituladoSuperior'), value: LEVEL_STUDIES.TITULADO_SUPERIOR }
        ],
        situacionLaboral: [
            { label: formatText('mortgageRequestWorkData-selectTrabajadorCuentaAjena'), value: EMPLOYMENT_SITUATION.TRABAJADOR_CUENTA_AJENA },
            { label: formatText('mortgageRequestWorkData-selectAutonomo'), value: EMPLOYMENT_SITUATION.AUTONOMO },
            { label: formatText('mortgageRequestWorkData-selectFuncionarioSeguridadSocial'), value: EMPLOYMENT_SITUATION.FUNCIONARIO_SEG_SOCIAL },
            { label: formatText('mortgageRequestWorkData-selectSinFuncionarioMuface'), value: EMPLOYMENT_SITUATION.FUNCIONARIO_MUFACE },
            { label: formatText('mortgageRequestWorkData-selectPensionista'), value: EMPLOYMENT_SITUATION.PENSIONISTA },
            { label: formatText('mortgageRequestWorkData-selectParado'), value: EMPLOYMENT_SITUATION.PARADO },
            { label: formatText('mortgageRequestWorkData-selectRentistaOtros'), value: EMPLOYMENT_SITUATION.RENTISTA_OTROS }
        ],
        contratoTrabajo: [
            { label: formatText('mortgageRequestWorkData-selectFijo'), value: WORK_CONTRACT.FIJO },
            { label: formatText('mortgageRequestWorkData-selectTemporal'), value: WORK_CONTRACT.TEMPORAL },
            { label: formatText('mortgageRequestWorkData-selectPracticas'), value: WORK_CONTRACT.PRACTICAS }
        ]
    }
};

const getCivilStatusOptions = () => {
    return [
        {
            label: `${formatText(
                'mortgageRequestHolderInformationCivilStatusOptions-marriedCommunityProperty'
            )}`,
            value: 'CASADO_BIENES_GANANCIALES'
        },
        {
            label: `${formatText(
                'mortgageRequestHolderInformationCivilStatusOptions-marriedParticipation'
            )}`,
            value: 'CASADO_REGIMEN_PARTICIPACION'
        },
        {
            label: `${formatText(
                'mortgageRequestHolderInformationCivilStatusOptions-marriedSeparationProperty'
            )}`,
            value: 'CASADO_EN_SEPARACION_DE_BIENES'
        },
        {
            label: `${formatText(
                'mortgageRequestHolderInformationCivilStatusOptions-divorced'
            )}`,
            value: 'DIVORCIADO'
        },
        {
            label: `${formatText(
                'mortgageRequestHolderInformationCivilStatusOptions-coupleOfFact'
            )}`,
            value: 'PAREJA_DE_HECHO'
        },
        {
            label: `${formatText(
                'mortgageRequestHolderInformationCivilStatusOptions-separated'
            )}`,
            value: 'SEPARADO'
        },
        {
            label: `${formatText('mortgageRequestHolderInformationCivilStatusOptions-single')}`,
            value: 'SOLTERO'
        },
        {
            label: `${formatText(
                'mortgageRequestHolderInformationCivilStatusOptions-widower'
            )}`,
            value: 'VIUDO'
        }
    ]
};

const getIdNumberOptions = () => {
    return [
        {
            label: `${formatText('mortgageRequestHolderInformationIdNumberOptions-DNI')}`,
            value: 'N'
        },
        {
            label: `${formatText('mortgageRequestHolderInformationIdNumberOptions-passport')}`,
            value: 'P'
        },
        {
            label: `${formatText(
                'mortgageRequestHolderInformationIdNumberOptions-residenceCard'
            )}`,
            value: 'C'
        },
        {
            label: `${formatText('mortgageRequestHolderInformationIdNumberOptions-birthdate')}`,
            value: 'FECHA_NACIMIENTO'
        },
        {
            label: `${formatText(
                'mortgageRequestHolderInformationIdNumberOptions-notSpecified'
            )}`,
            value: 'I'
        },
        {
            label: `${formatText('mortgageRequestHolderInformationIdNumberOptions-CFI')}`,
            value: 'S'
        },
        {
            label: `${formatText('mortgageRequestHolderInformationIdNumberOptions-notFound')}`,
            value: 'NOT_FOUND'
        }
    ]
};

module.exports = {
    EMPLOYMENT_SITUATION,
    EMPTY_SELECTOR_FIELD,
    LEVEL_STUDIES,
    WORK_CONTRACT,
    HOUSING_OWNERSHIP_TYPE,
    ESTATES_TYPE,
    DOCUMENTS_STATUS,
    COMMONS,
    OTHER_LOANS_TYPES,
    OTHER_LOANS_FILE_TYPES,
    getWorkInfo,
    getCivilStatusOptions,
    getIdNumberOptions
};
