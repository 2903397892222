import "./accountsTransferRedirector.scss";

import React from 'react';

import { OKButton } from "components/commons/OKButton/OKButton";


function AccountsTransferRedirector({ handleClick, title, message, buttonText }) {
    return (
        <section className="accounts-transfer-redirector">
            <h4 className="accounts-transfer-redirector__title">{title}</h4>
            <p className="accounts-transfer-redirector__message">{message}</p>
            <div className="accounts-transfer-redirector__button">
                <OKButton
                    onClick={handleClick}
                    modifier="secondary"
                >
                    {buttonText}
                </OKButton>
            </div>
        </section>
    );
}

export default AccountsTransferRedirector;