const PropTypes = require('prop-types');
// @vendors
const React = require('react');

// @helpers
const { formatText } = require('core/i18n').i18n;
const { getLastNChars } = require('utilities/stringHelper');

// @constants
const { TYPE_ETFS } = require('constants/index');

class StocksContractDropdownOption extends React.Component {
    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    buildOption() {
        const { option, className } = this.props;
        const {
            walletAccountType,
            walletTitular,
            walletNumber,
            titlesQuantity,
            productType
        } = option;
        const optionClassName = `stocks-contract-dropdown__option ${className}`;
        let titlesLabel;

        if (productType === TYPE_ETFS) {
            titlesLabel = formatText('broker-stocksContractDropdownAvailableTitles');
        } else {
            titlesLabel = formatText('broker-stocksContractDropdownAvailableStocksTitles');
        }

        return (
            <div
                className={optionClassName}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
            >
                <span className="stocks-contract-dropdown__value-label stocks-contract-dropdown__option--highlighted">
                    {walletAccountType} {walletTitular}
                </span>
                <span className="stocks-contract-dropdown__value--medium">
                    {getLastNChars(walletNumber)}
                </span>

                <span className="stocks-contract-dropdown__option--right">
                    <span>
                        {titlesLabel}
                    </span>
                    <span className="stocks-contract-dropdown__option--highlighted">
                        {titlesQuantity}
                    </span>
                </span>
            </div>
        );
    }

    handleMouseDown(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    }

    handleMouseMove(event) {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    }

    render() {
        let result;

        result = this.buildOption();

        return result;
    }
}

StocksContractDropdownOption.propTypes = {
    className: PropTypes.string,
    option: PropTypes.object.isRequired,
    isFocused: PropTypes.bool,
    onSelect: PropTypes.func,
    onFocus: PropTypes.func
};

module.exports = StocksContractDropdownOption;