const PROFILE_EDIT_FORMS = {
    ADDRESS: 'ADDRESS',
    EMAIL: 'EMAIL',
    FISCAL_DATA: 'FISCAL_DATA',
    PHONE: 'PHONE',
    NEWADDRESS: 'NEWADDRESS',
    PHONE_CONTACT_TIME: 'PHONE_CONTACT_TIME',
    EMAIL_CONTACT_TIME: 'EMAIL_CONTACT_TIME',
    SHIPPING_ADDRESS: 'SHIPPING_ADDRESS',
};

const PROFILE_EDIT_CONFIG = {
    ADDRESS: {
        confirmStep: {
            highlighted: true,
            titleTranslation: 'addressTitle',
            title: 'profile-addressData_title',
        },
        editFormStep: {
            showContactInfoMessage: true,
            titleTranslation: 'addressTitle',
            warningSubtitleTranslation: 'addressSubtitle'
        },
        successStep: {
            warningMessage: false
        }
    },
    NEWADDRESS: {
        confirmStep: {
            highlighted: true,
            titleTranslation: 'addressTitle'
        },
        editFormStep: {
            showContactInfoMessage: true,
            titleTranslation: 'addressTitle',
            warningSubtitleTranslation: 'addressSubtitle'
        },
        successStep: {
            warningMessage: false
        }
    },
    EMAIL: {
        confirmStep: {
            highlighted: false,
            titleTranslation: 'emailTitle',
            title: 'profile-contactData_title'
        },
        editFormStep: {
            showContactInfoMessage: true,
            titleTranslation: 'emailTitle',
            subtitleTranslation: 'subtitleTranslationEmail',
            highlighted: false,
            highlightedModificationsMessage: 'profile-infoMessageEmail',
            warningSubtitleTranslation: 'emailSubtitle'
        },
        successStep: {
            warningMessage: true
        }
    },
    FISCAL_DATA: {
        confirmStep: {
            highlighted: true,
            titleTranslation: 'fiscalDataTitle',
            title: 'profile-taxInformation_title',
            subtitleTranslation: 'subtitleTranslationFiscalData'
        },
        editFormStep: {
            showContactInfoMessage: false,
            titleTranslation: 'fiscalDataTitle'
        },
        successStep: {
            warningMessage: false
        }
    },
    PHONE: {
        confirmStep: {
            highlighted: false,
            titleTranslation: 'phoneTitle',
            title: 'profile-contactData_title'
        },
        editFormStep: {
            showContactInfoMessage: true,
            titleTranslation: 'phoneTitle',
            subtitleTranslation: 'subtitleTranslationPhone',
            highlighted: false,
            highlightedModificationsMessage: 'profile-infoMessagePhone',
            warningSubtitleTranslation: 'phoneSubtitle'
        },
        successStep: {
            warningMessage: true
        }
    },
    //Is managed in external wizard so it dosen´t need any configuration
    SHIPPING_ADDRESS: {},
};

module.exports = {
    PROFILE_EDIT_CONFIG,
    PROFILE_EDIT_FORMS
};
