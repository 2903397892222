require('./biocatchExceptionModal.scss');

// @vendors
const React = require('react');
const { withRouter } = require('react-router-dom');
const PropTypes = require('prop-types');

// @core
const { FormattedText } = require('core/i18n').i18n;

// @components
const OKModal = require('components/commons/OKModal/OKModal');

// @ assets
const technicalErrorIcon = require('assets/images/penguin-technical-error-bug.svg');

const BiocatchExceptionModal = ({ history }) => {

    const content = (
        <React.Fragment>
            <div className="biocatch-exception-modal__primary-text">
                <FormattedText value="biocatch-exceptionModalTitle" />
            </div>
            <div className="biocatch-exception-modal__divider"></div>
            <div className="biocatch-exception-modal__icon-img">
                <img src={technicalErrorIcon} className="biocatch-exception-modal__icon-img--icon" />
            </div>
            <p className="biocatch-exception-modal__secondary-text">
                <FormattedText value="biocatch-exceptionModalDescription" />
            </p>
        </React.Fragment>
    );

    const navigateToGlobalPosition = () => {
        history.push('/myprofile/global')
    }

    return (
        <div className="biocatch-exception-modal">
            <OKModal
                content={content}
                contentOverflow="auto"
                customCloseButton={<div/>}
                footerType="navigation"
                isOpen={true}
                onPrimaryButtonClick={navigateToGlobalPosition}
                primaryButtonText="biocatch-exceptionModalButtonText"
                overlayClassName="biocatch-exception-modal-wrapper"
                type="filter"
                shouldCloseOnOverlayClick={false}
            />
        </div>
    );
}

BiocatchExceptionModal.propTypes = {
  history: PropTypes.object.isRequired,
};

module.exports = withRouter(BiocatchExceptionModal);
