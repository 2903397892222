const GLOBAL_POSITION_BOXES = {
    ACCOUNT: 'sec_PG_Accounts',
    SAVINGS: 'sec_PG_Savings',
    ECARD: 'sec_PG_Ecars',
    INVESTMENT: 'sec_PG_Investments',
    CREDIT_CARD: 'sec_PG_CreditCards',
    CREDIT: 'sec_PG_Credits',
    MORTGAGE: 'sec_PG_Mortgages',
    LOAN: 'sec_PG_Loans',
    LOANS_LANDING: 'sec_LM_Loans',
    CREDIT_LANDING: 'sec_LM_Credits',
    MORTGAGE_LANDING: 'sec_LM_Mortgages'
}
module.exports = GLOBAL_POSITION_BOXES;
