require('./phoneInput.scss');

const PropTypes = require('prop-types');

//@vendor
const React = require('react');

//@components
const { InputSafe } = require('commonsComponents/inputSafe');

//@constants
const { 
    CALL_ME_BACK_BEHAVIOUR,
    PHONE_INPUT_PREFIX_REGEX,
    PHONE_INPUT_VALUE_REGEX,
    PREFIX_PHONE_INPUT,
    NUMBER_PHONE_INPUT,
    PREFIX_PHONE_SYMBOL 
} = require('constants/phoneInput');

const { PHONE_NUMBER_MAX_LENGTH } = require('constants/index');

// @helpers
const { formattedPhoneOnly } = require('utilities/personalDataHelper');

class PhoneInput extends React.Component {
    constructor(props) {
        super(props);

        this.handlePhoneNumberBlur = this.handlePhoneNumberBlur.bind(this);
        this.handlePhonePrefixBlur = this.handlePhonePrefixBlur.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handlePhonePrefixChange = this.handlePhonePrefixChange.bind(this);
        this.handlePhoneNumberFocus = this.handlePhoneNumberFocus.bind(this);
        this.handlePhonePrefixFocus = this.handlePhonePrefixFocus.bind(this);

        this.digest = this.digest.bind(this);
        this.store = this.store.bind(this);
        this.maskPhoneNumberValue = this.maskPhoneNumberValue.bind(this);
        this.handlePaste = this.handlePaste.bind(this);

        const { phonePrefix, phoneNumber } = this.props;
        const value = phoneNumber;
        const prefixValue = phonePrefix;
        const emptyDefault = '';
        const hasValue = value || value === 0;
        const hasPrefix = prefixValue || prefixValue === 0;

        this.state = {
            phoneNumber: hasValue ? `${value}` : `${emptyDefault}`,
            phonePrefix: hasPrefix ? `${prefixValue}` : `${emptyDefault}`,
            isPhoneNumberFocused: false,
            isPhonePrefixFocused: false,
            isPhoneNumberEmpty: !hasValue,
            isPhonePrefixEmpty: !hasPrefix,
            isInputDirty: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.isInputDirty){
            if (nextProps.phoneNumber !== this.state.phoneNumber) {
                this.digest(nextProps.phoneNumber, NUMBER_PHONE_INPUT);
            }

            if (nextProps.phonePrefix !== this.state.phonePrefix) {
                this.digest(nextProps.phonePrefix, PREFIX_PHONE_INPUT);
            }    
        }
    }

    buildErrors() {
        const { numberError, prefixError, numberErrorMsg, prefixErrorMsg } = this.props;
        let telCodeError;
        let telError;
        let result;

        if (prefixError) {
            telCodeError = (
                <div className="ok-input__message">
                    {prefixErrorMsg}
                </div>
            );
        }

        if (numberError) {
            telError = (
                <div className="ok-input__message">
                    {numberErrorMsg}
                </div>
            );
        }

        if (telCodeError || telError) {
            result = (
                <div className="broker-investment-specialist-modal__error-summary ok-input--error">
                    {telCodeError}
                    {telError}
                </div>
            );
        }

        return result;
    }

    digest(raw, inputType) {
        let newState;
        if (inputType === NUMBER_PHONE_INPUT) {
            if (!raw && raw !== 0) {
                newState = { phoneNumber: '', isPhoneNumberEmpty: true };
            } else {
                newState = { phoneNumber: `${raw}`, isPhoneNumberEmpty: false };
            }
        } else {
            if (!raw && raw !== 0) {
                newState = { phonePrefix: '', isPhonePrefixEmpty: true };
            } else {
                newState = { phonePrefix: `${raw}`, isPhonePrefixEmpty: false };
            }
        }

        this.setState(newState);
    }

    handlePaste(evt) {
        if (this.props.disablePaste) {
            evt.preventDefault();
        }
    }

    handlePhoneNumberBlur(evt) {
        this.setState({ isPhoneNumberFocused: false });
        this.store(evt, NUMBER_PHONE_INPUT);
    }

    handlePhoneNumberChange(evt) {
        if (this.valid(evt.target.value, NUMBER_PHONE_INPUT)) {
            this.digest(evt.target.value, NUMBER_PHONE_INPUT);
        }
    }

    handlePhoneNumberFocus() {
        let newState = { 
            isPhoneNumberFocused: true,
            isInputDirty: true
         };
        
        if ( this.state.phoneNumber.length === 0 || this.shouldEmptyPhoneField() ) {
            newState.phoneNumber = '';
            newState.isPhoneNumberEmpty = true;
        }

        this.setState(newState);
    }

    handlePhonePrefixBlur(evt) {
        let newState = { isPhonePrefixFocused: false }
        const { behaviour } = this.props;
        if ( this.state.phonePrefix !== behaviour.profileCountryCode && this.shouldEmptyPhoneField()){
            newState.phoneNumber = '';
            newState.isPhoneNumberEmpty = true;
        }
        this.setState(newState);
        this.store(evt, PREFIX_PHONE_INPUT);
    }

    handlePhonePrefixChange(evt) {
        if (this.valid(evt.target.value, PREFIX_PHONE_INPUT)) {
            this.digest(evt.target.value, PREFIX_PHONE_INPUT);
        }
    }

    handlePhonePrefixFocus() {
        let newState = { 
            isPhonePrefixFocused: true,
            isInputDirty: true
         };

        if (this.state.phonePrefix.length === 0) {
            newState.phonePrefix = '';
            newState.isPhonePrefixEmpty = true;
        }

        this.setState(newState);
    }

    maskPhoneNumberValue() {
        const { phoneNumber, isPhoneNumberFocused, isPhoneNumberEmpty } = this.state;

        if (isPhoneNumberEmpty && !isPhoneNumberFocused) {
            return '';
        }

        if (isPhoneNumberFocused) {
            return phoneNumber.replace(/\s+/g, '');
        } else {
            return phoneNumber.length === PHONE_NUMBER_MAX_LENGTH ? formattedPhoneOnly(phoneNumber) : phoneNumber;
        }
    }

    shouldEmptyPhoneField(){
        const { behaviour } = this.props;
        return behaviour && 
            behaviour.value === CALL_ME_BACK_BEHAVIOUR && 
            this.state.phoneNumber == behaviour.profileOfuscatedPhone;
    }

    store(evt, inputType) {
        let value = '';
        if (inputType === NUMBER_PHONE_INPUT) {
            value = this.state.phoneNumber;
            this.props.numberCallback(value, evt);
        } else {
            if (!this.state.isPhonePrefixEmpty && this.state.phonePrefix !== 0) {
                value = PREFIX_PHONE_SYMBOL + this.state.phonePrefix.replace(/^\+?0*/, '');
            }
            this.props.prefixCallback(value, evt);
        }

        this.digest(value, inputType);
    }

    valid(value, inputType) {
        if (inputType === NUMBER_PHONE_INPUT) {
            return PHONE_INPUT_VALUE_REGEX.test(value);
        } else {
            return PHONE_INPUT_PREFIX_REGEX.test(value);
        }
    }

    render() {
        const { id, name, disabled } = this.props;
        const phoneNumberValue = this.maskPhoneNumberValue();
        const phonePrefixValue = this.state.phonePrefix;

        return (
            <div>
                <div className="phone-input__prefix">
                    <InputSafe
                        id={id + PREFIX_PHONE_INPUT}
                        name={name}
                        type="text"
                        value={phonePrefixValue}
                        onChange={this.handlePhonePrefixChange}
                        onBlur={this.handlePhonePrefixBlur}
                        onFocus={this.handlePhonePrefixFocus}
                        onPaste={this.handlePaste}
                        disabled={disabled}
                    />
                </div>
                <div className="phone-input__number">
                    <InputSafe
                        id={id + NUMBER_PHONE_INPUT}
                        name={name}
                        type="text"
                        value={phoneNumberValue}
                        onChange={this.handlePhoneNumberChange}
                        onBlur={this.handlePhoneNumberBlur}
                        onFocus={this.handlePhoneNumberFocus}
                        onPaste={this.handlePaste}
                        disabled={disabled}
                    />
                </div>
                <div>
                    {this.buildErrors()}
                </div>
            </div>
        );
    }
}

PhoneInput.propTypes = {
    phonePrefix: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    phoneNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    prefixCallback: PropTypes.func,
    numberCallback: PropTypes.func,
    behaviour: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    numberError: PropTypes.bool,
    prefixError: PropTypes.bool,
    numberErrorMsg: PropTypes.string,
    prefixErrorMsg: PropTypes.string,
    disablePaste: PropTypes.bool
};

module.exports = PhoneInput;