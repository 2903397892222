require('./globalPositionLoans.scss');

const PropTypes = require('prop-types');

// @ vendors
const React = require('react');
const { connect } = require('react-redux');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const { withRouter } = require('react-router-dom');
const classnames = require('classnames');
const includes = require('lodash/collection/includes');
const get = require('lodash/object/get');
const some = require('lodash/collection/some');

// @ helpers
const { recordLinkV2 } = require("utilities/tagging");
const { formatText } = require('core/i18n').i18n;

// @actions
const { setWaferIdRequest } = require('actions/myGlobalPosition');

//constants
const GLOBAL_POSITION_BOXES = require('constants/globalPositionBoxes');

// @ components
const GlobalPositionFinancialCard = require('components/globalPosition/globalPositionFinancialCard/globalPositionFinancialCard');

class GlobalPositionLoans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxVisibleItems : this.props.isOnPG || this.props.redirectionFlag !== GLOBAL_POSITION_BOXES.LOAN ? 5: this.getLoans().length, 
        }
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
        this.getTotal = this.getTotal.bind(this);
        this.getLoans = this.getLoans.bind(this);
        this.handleOnWaferLoansClick = this.handleOnWaferLoansClick.bind(this);
        this.handleOnSeeMoreClick = this.handleOnSeeMoreClick.bind(this);
    }

    getLoansCount() {
        const { immLoans } = this.props;
        let immLoansByOrder = immLoans.get('loansByOrder');
        const zeroAmountLoans = [];
        immLoansByOrder = immLoansByOrder.filter((loanId) => {
                const isValid = immLoans.getIn(['byId', loanId, 'filters', 'valid']);
                immLoans.getIn(['byId', loanId, 'balance', 'amount']) === 0 && zeroAmountLoans.push(immLoans.getIn(['byId', loanId]));
               return isValid;
            })
             
        const loansCount = immLoansByOrder.toJS().length - zeroAmountLoans.length;
        return loansCount;
    }

    getLoans() {
        const { immLoans, isOnPG, immProfile } = this.props;
        let immLoansByOrder = immLoans.get('loansByOrder');
        const isAresUser = immProfile && immProfile.get('isAresUser');

        if (isOnPG) {
            immLoansByOrder = immLoansByOrder.filter((loanId) => {
                const isValid = immLoans.getIn(['byId', loanId, 'filters', 'valid']);
                const balanceIsZero = immLoans.getIn(['byId', loanId, 'balance', 'amount']) === 0;

                return isValid && !balanceIsZero;
            })
        }
        else {
            immLoansByOrder = immLoansByOrder.sort((firstLoan, secondLoan) => {
                return immLoans.getIn(['byId', firstLoan, 'balance', 'amount']) > immLoans.getIn(['byId', secondLoan,'balance', 'amount']) ? 1 : -1;
             })
             
        }


        return immLoansByOrder.map(loanId => {
            const immLoan = immLoans.getIn(['byId', loanId]);
            const loanDeatilsLinkPrefix = isAresUser ? 'ares-': '';
            const loanPrefix = immLoan.get('isPersonal') ? loanDeatilsLinkPrefix : `${loanDeatilsLinkPrefix}${immLoan.get('type')}-`;
            const link = `/myprofile/loans-mortgages/${loanPrefix}loans/${immLoan.get('loanId')}`;
            const handleOnClickTitle = () => {
                const type = immLoan.get('product');
                recordLinkV2({
                    categoriaEvento: window.utag_last_name,
                    accionEvento: `prestamo${type}`
                });

            }
            return {
                amount: immLoan.getIn(['balance', 'amount']),
                currency: immLoan.getIn(['balance', 'currency']),
                link,
                handleOnClickTitle,
                productType: {
                    type: immLoan.get('product'),
                    subType: immLoan.get('productSubType'),
                    standard: immLoan.get('productStandard')
                },
                title: immLoan.get('alias')
            };
        }).toJS();
    }

    getTotal() {
        const { immLoans, isOnPG } = this.props;

        let loansBalance;
        if (isOnPG) {
            loansBalance = immLoans.get('loansBalanceForPG');
        } else {
            loansBalance = immLoans.get('loansBalance');
        }

        return loansBalance.map(balance => {
            return {
                currency: balance.get('currency'),
                amount: balance.get('amount'),
                legend: formatText('myGlobalPosition-savingsTotalLoans')
            }
        }).toArray();
    }

    handleOnWaferLoansClick(evt) {
        const { history, isOnPG } = this.props;
        const loans = this.getLoans();
        const url = get(loans, '0.link', '');
        const classListWithoutRedirect = [
            'glb-position-showmore',
            'ok-collapsing-toggler'
        ];

        if (!some(evt.target.parentElement.classList, (el) => includes(classListWithoutRedirect, el))) {
            if (isOnPG) {
                history.push('/myprofile/loans-mortgages');
            } else {
                history.push(url);
            }
        }
    }

    handleOnSeeMoreClick() {
        const { history, isOnPG , id, setWaferIdRequest 
        } = this.props;
        const loans = this.getLoans();
        if (isOnPG) {
                
                setWaferIdRequest(id);
                history.push('/myprofile/loans-mortgages');
            }
        else {
                setWaferIdRequest('');
                this.setState({maxVisibleItems: loans.length})
            }
    }

    render() {
        const { isOnPG, expanded, easyLoanInfo, id, easyLoanFFStatus, immProfile } = this.props;
        const { maxVisibleItems } = this.state;
        const loans = this.getLoans();
        const total = this.getTotal();
        const loansCount = this.getLoansCount();
        const isAresUser = immProfile && immProfile.get('isAresUser');



        const isClickable = isOnPG ? !expanded : expanded && loans.length === 1;
        const globalPositionLoansClasses = classnames('global-position-loans', {
            'global-position-loans--is-clickable': isClickable
        });

        return (
            <section className={globalPositionLoansClasses} onClick={isClickable ? this.handleOnWaferLoansClick : undefined} >
                <GlobalPositionFinancialCard
                    amount={loansCount}
                    cards={loans}
                    currencyDetails={total}
                    easyLoanFFStatus={easyLoanFFStatus}
                    expanded={expanded}
                    icon="rebrand-loans"
                    id={id}
                    maxVisibleItems={maxVisibleItems}
                    showMoreLink="/myprofile/loans-mortgages"
                    showMoreText={formatText('myGlobalPosition-loansShowMore')}
                    title={formatText('myGlobalPosition-loans')}
                    extraItem={easyLoanInfo}
                    titleLink="/myprofile/loans-mortgages"
                    isAresUser={isAresUser}
                    handleOnSeeMoreClick={this.handleOnSeeMoreClick}
                />
            </section>
        );
    }
}

GlobalPositionLoans.propTypes = {
    id: PropTypes.string,
    easyLoanInfo: PropTypes.object,
    expanded: PropTypes.bool,
    history: PropTypes.object.isRequired,
    immLoans: PropTypes.object,
    isOnPG: PropTypes.bool,
    easyLoanFFStatus: PropTypes.bool.isRequired,
    immProfile: PropTypes.object,
   redirectionFlag: PropTypes.string,
   setWaferIdRequest: PropTypes.func
};

GlobalPositionLoans.defaultProps = {
    isOnPG: true
};

export default connect(
    (state) => ({
        redirectionFlag : state.globalPosition.get('redirectionFlag'),
    }),
    {
        setWaferIdRequest
    }
)(withRouter(GlobalPositionLoans));
