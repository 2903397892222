const contractsValidations = {
    VALIDATE_DATE: 'validateDate',
    VALIDATE_DOCUMENT_TYPE: 'validateDocumentType',
    VALIDATE_DOCUMENT_TYPE_NOT_EMPTY: 'validateDocumentTypeNotEmpty',
    VALIDATE_DOCUMENT_COUNTRY: 'validateDocumentCountry',
    VALIDATE_DOCUMENT_NUMBER: 'validateDocumentNumber',
    VALIDATE_EMAIL: 'validateEmail',
    VALIDATE_EXISTING_OYACCOUNT_DOCUMENT_NUMBER: 'validateExistingOYAccountDocumentNumber',
    VALIDATE_EXISTING_OYCARD_DOCUMENT_NUMBER: 'validateExistingOYCardDocumentNumber',
    VALIDATE_IS_REQUIRED_FIELD: 'validateIsRequiredField',
    VALIDATE_METHOD_OF_PAYMENT: 'validateMethodOfPayment',
    VALIDATE_PHONE_NUMBER: 'validatePhoneNumber',
    VALIDATE_POSTAL_CODE: 'validatePostalCode',
    VALIDATE_POSTAL_CODE_PROVINCE: 'validatePostalCodeProvince',
    VALIDATE_POSTAL_CODE_PROVINCE_CHECKING_PAYROLL: 'validatePostalCodeProvinceCheckingPayroll',
    VALIDATE_COMPANY_NAME_OR_DESCRIPTION: "validateDescriptionsOrCompany",
};

const contractsValidationsErrorMessages = {
    ERROR_MESSAGE_EMAIL: 'contractsView-emailError',
    ERROR_MESSAGE_EXISTING_OYACCOUNT_DOCUMENT_NUMBER: 'contractsView-existingOYAccountDocumentNumberError',
    ERROR_MESSAGE_EXISTING_OYCARD_DOCUMENT_NUMBER: 'contractsView-existingOYCardDocumentNumberError',
    ERROR_MESSAGE_IBAN_NOT_VALID: 'contractsView-ibanNotValidError',
    ERROR_MESSAGE_IBAN_REQUIRED: 'contractsView-ibanRequiredError',
    ERROR_MESSAGE_IS_REQUIRED_FIELD: 'contractsView-requiredFieldError',
    ERROR_MESSAGE_PHONE_NUMBER: 'contractsView-phoneNumberError',
    ERROR_MESSAGE_PHONE_NUMBER_INCOMPLETE: 'contractsView-incompleteNumber',
    ERROR_MESSAGE_PHONE_NUMBER_CHECKING_PAYROLL: 'contractsView-phoneNumberErrorCheckingPayroll',
    ERROR_MESSAGE_POSTAL_CODE: 'contractsView-postalCodeError',
    ERROR_MESSAGE_POSTAL_CODE_PROVINCE: 'contractsView-postalCodeProvinceError',
    ERROR_MESSAGE_METHOD_OF_PAYMENT: 'contractsView-requiredMethodOfPaymentError',
    ERROR_MESSAGE_VIA_T_METHOD_OF_PAYMENT: 'contractsView-viaTRequiredMethodOfPaymentError',
    ERROR_MESSAGE_METHOD_OF_PAYMENT_FEE_AMOUNT: 'contractsView-methodOfPaymentFeeAmountError',
    ERROR_MESSAGE_ADDRESS_OTHERS: 'contractsView-addressOthersError',
    ERROR_MESSAGE_REQUIRED_DATE: 'contractsView-requiredDateError',
    ERROR_MESSAGE_REQUIRED_DATE_CHECKING_PAYROLL: 'contractsView-requiredDateErrorCheckingPayroll',
    ERROR_MESSAGE_REQUIRED_DOCUMENT_NUMBER: 'contractsView-requiredDocumentNumberError',
    ERROR_MESSAGE_REQUIRED_DROPDOWN_CHECKING_PAYROLL: 'contractsView-requiredDropdownErrorCheckingPayroll',
    ERROR_MESSAGE_REQUIRED_RADIO_BUTTON_CHECKING_PAYROLL: 'contractsView-requiredRadioButtonErrorCheckingPayroll',
    ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS: 'contractsView-dropdownAccountsError',
    ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_EIGHTEEN: 'contractsView-dropdownAccountsDepositEighteenMonthsError',
    ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_NM: 'contractsView-dropdownAccountsDepositNMError',
    ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_WNM: 'contractsView-dropdownAccountsDepositWNMError',
    ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_EIGHTEEN_RETENTION: 'contractsView-dropdownAccountsDepositEighteenMonthsRetentionError',
    ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_TWENTYFOUR_RETENTION: 'contractsView-dropdownAccountsDepositTwentyFourMonthsRetentionError',
    ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_THIRTEEN: 'contractsView-dropdownAccountsDepositThirteenMonthsError',
    ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_JUNIOR_ACCOUNT: 'contractsView-dropdownAccountsJuniorAccountError',
    ERROR_MESSAGE_REQUIRED_EMAIL: 'contractsView-requiredEmailError',
    ERROR_MESSAGE_INVALID_CAPITAL_DESCRIPTION: "contractsView-capitalNotValidDescription",
    ERROR_MESSAGE_INVALID_INCOME_DESCRIPTION: "contractsView-incomeNotValidDescription",
    ERROR_MESSAGE_INVALID_COMPANY_NAME: "contractsView-notValidcompanyName"
};

module.exports = {
    contractsValidations: contractsValidations,
    contractsValidationsErrorMessages: contractsValidationsErrorMessages
}
