module.exports = {
    AT_HOME_ABROAD_GDPR_ID: 'purchaseAtHomeAbroad',
    AMAZON_GIFT_CARD_GDPR_ID: 'amazonGifts',
    BROKERS_STOCKS_CONTRACT_GDPR_PID: 'stocksContract',
    CHECKING_ACCOUNT_GDPR_PID: 'purchaseCheckingAccount',
    CHECKING_ACCOUNT_2_GDPR_PID: 'purchaseCheckingAccount2',
    DEPOSIT_EIGHTEEN_MONTS_GDPR_PID: 'purchaseDepositEighteenMonths',
    DEPOSIT_EIGHTEEN_MONTHS_RETENTION_GDPR_PID: 'purchaseEighteenMonthsRetentionDeposit',
    DEPOSIT_NM_GDPR_PID: 'purchaseDepositWithNewBalance',
    DEPOSIT_RENEWAL_GDPR_PID: 'purchaseDepositRenewal',
    DEPOSIT_WNM_GDPR_PID: 'purchaseDepositWithoutNewBalance',
    COMBINED_DEPOSIT_GDPR_PID: 'purchaseCombinedDeposit',
    DEPOSIT_THIRTEEN_MONTS_GDPR_PID: 'purchaseDepositThirteenMonths',
    DEPOSIT_TWENTYFOUR_MONTHS_RETENTION_GDPR_PID: 'purchaseTwentyFourMonthsRetentionDeposit',
    DIAMOND_PACK_GDPR_PID: 'purchaseDiamondPack',
    E_ACCOUNT_LOAN_GDPR_PID: 'purchaseEAccountLoan',
    E_CARD_GDPR_PID: 'purchaseECard',
    EXTERNAL_FUNDS_TRANSFER_GDPR_PID: 'externalTransferFund',
    HIRE_INITIAL_FUNDS_GDPR_PID: 'buyInvestmentFunds',
    HIRE_PENSION_PLAN_GDPR_PID: 'buyPensionPlan',
    MGM_GDPR_PID: 'referralsForm',
    MINI_CARD_GDPR_PID: 'purchaseMiniCard',
    NERUDA_GDPR_PID: 'purchasevisaopenviajera',
    OPEN_DEBIT_GDPR_PID: 'purchaseOpenDebit',
    METALLIC_GDPR_PID: 'purchaseMetallic',
    MAESTRO_GDPR_PID: 'purchaseMaestro',
    PAYROLL_ACCOUNT_GDPR_PID: 'purchasePayrollAccount',
    PAYROLL_ACCOUNT_2_GDPR_PID: 'purchasePayrollAccount2',
    PRE_GRANTED_CREDIT_PID: 'purchasePreGrantedCredit',
    PREMIUM_PACK_GDPR_PID: 'purchasePremiumPack',
    REVOLVING_GDPR_ID: 'purchaseRevolving',
    VANITY_GDPR_PID: 'purchasevisaopenvf',
    HORMIGUERO_GDPR_PID: 'purchasevisaHormiguero',
    VIA_T_GDPR_PID: 'purchaseViaT',
    VIRTUAL_CREDIT_PID: 'purchaseVirtualCredit',
    VIRTUAL_ONLY_DEBIT_CARD_GDPR_PID: 'purchaseVirtualDebit',
    VISA_OPEN_GDPR_PID: 'purchaseVisaOpen'
};
