require('./globalPositionCredits.scss');

const PropTypes = require('prop-types');

// @vendors
const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');

// @helpers
const { formatText } = require('core/i18n').i18n;
const { getLastNChars } = require('utilities/stringHelper');
// @components
const GlobalPositionFinancialCard = require('components/globalPosition/globalPositionFinancialCard/globalPositionFinancialCard');
// @constants
const { EURO_TEXT, LOANS_MORTGAGES_PREGRANTED_CREDIT } = require('constants/index');

class GlobalPositionCredits extends React.Component {
    constructor(props) {
        super(props);
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    buildCurrencyDetails(immCredits) {

        let availableAmount = 0;
        let disposedAmount = 0;
        let currency = EURO_TEXT;

        if (!immCredits.get('byOrder').isEmpty()) {
            currency = immCredits.getIn(['byId', 0, 'available', 'currency']);
        }


        immCredits.get('byId').forEach(credit => {
            disposedAmount = credit.getIn(['disposed', 'amount']) + disposedAmount;
            availableAmount = credit.getIn(['available', 'amount']) + availableAmount;
        });

        return ([
            {
                currency,
                amount: availableAmount,
                legend: formatText('globalPositionCredit-availableLabel')
            },
            {
                currency,
                amount: disposedAmount,
                legend: formatText('globalPositionCredit-disposedLabel')
            }
        ]);
    }


    processCredits(credits) {
        let processedCredits = [];

        credits.map(credit => {
            const type = credit.get('type');
            const iban = credit.get('iban');
            const accountId = credit.get('accountId');
            const title = type === LOANS_MORTGAGES_PREGRANTED_CREDIT ? formatText('globalPositionCredit-pregrantedCredit') : formatText('globalPositionCredit-investmentCredit');
            const alias = credit.get('alias');
            const formattedIban = getLastNChars(iban, 4, '.', 4);

            const accountLabel = `${alias} ${formattedIban}`;
            processedCredits.push({
                title,
                amount: credit.getIn(['disposed', 'amount']),
                currency: credit.getIn(['disposed', 'currency']),
                secondLine: accountLabel,
                subtitleAmount: credit.getIn(['available', 'amount']),
                subtitleCurrency: credit.getIn(['available', 'currency']),
                link: `/myprofile/loans-mortgages/credit/${type}-credit/${accountId}`
            });
        });

        return processedCredits;
    }

    render() {
        const { immCredits, expanded } = this.props;
        const creditsById = immCredits.get('byId');
        const credits = this.processCredits(creditsById);
        const currencyDetails = this.buildCurrencyDetails(immCredits);
        let extraLink;

        if (immCredits.get('anyAccountWithoutPregrantedCredit')) {
            extraLink = {
                url: '/myprofile/loans-mortgages/pre-granted-credit',
                text: formatText('myGlobalPosition-otherCreditsLink')
            };
        }

        return (
            <section className={'global-position-credits'}>
                <GlobalPositionFinancialCard
                    id={this.props.id}
                    icon="rebrand-credits"
                    amount={credits.length}
                    cards={credits}
                    currencyDetails={currencyDetails}
                    showMoreText={formatText('myGlobalPosition-creditCardShowMore')}
                    showMoreLink="/myprofile/"
                    title={formatText('myGlobalPosition-credits')}
                    expanded={expanded}
                    extraLink={extraLink}
                />
            </section>
        );
    }
}

GlobalPositionCredits.propTypes = {
    expanded: PropTypes.bool,
    id: PropTypes.string,
    immCredits: PropTypes.object
};

module.exports = GlobalPositionCredits;
