// @vendors
const React = require('react');
const { connect } = require('react-redux');
const PropTypes = require('prop-types');
const Immutable = require('immutable');
const merge = require('lodash/object/merge');

// @core
const { getStore } = require('core/storeHelper');

// @commons
const i18nCommons = require('commonsUtilities/i18n').i18n;
const { FormattedText } = require('commonsComponents/formattedText');

// @constants
const { PRODUCTION_ENVIRONMENT } = require('constants/index');
// const { DEFAULT_TRANSLATION_TEXT } = require('commonsConstants/i18n');
const { ES_LANG } = require('constants/contactCenter/language');
const isProduction = window.__ENV__ === PRODUCTION_ENVIRONMENT;

// Update store if it does not exist and check if its valid
let store = null;
const validStore = () => {
    if (!store) store = getStore();
    return !!(typeof store !== 'undefined' && store);
}

// Gets the selected language
const getLanguage = (defaultLanguage = ES_LANG) => i18nCommons.getLanguage(__CONTACT_CENTER__, defaultLanguage);

// NOTE: This should be removed when traslations is erased from package
// Get initial reference to strings from node_modules, to avoid empty strings in the first initiallization
const getInitialStrings = () => {
    const countries = ['es', 'de', 'nl', 'pt']
    const translations = countries
        .map(c => {
            const ccStrings = !__CONTACT_CENTER__ ? {} : require(`stringsOpenbankCc/${c}.json`);
            return {
                [c]: merge(require(`stringsOpenbank/${c}.json`), ccStrings)
            }
        })
        .reduce((lv, cv) => merge(lv, cv), {})
    
    return Immutable.fromJS({translations});
}
const initialStrings = getInitialStrings();

// NOTE: This should be removed when traslations is erased from package
// If the translation call has not yet been fetched we consume the strings from node_modules
const getStrings = () => {
    if (validStore()) {
        return store.getState().translations;
    } else {
        return initialStrings;
    }
};

const isDefaultLanguage = () => i18nCommons.isDefaultLanguage();

// Checks if a string code has a translation (including cc translations)
const hasTranslation = stringCode => {
    // NOTE: Commented until traslations is removed from package
    // return validStore() ? i18nCommons.hasTranslation(__CONTACT_CENTER__, store.getState().translations, stringCode) : false;
    return i18nCommons.hasTranslation(__CONTACT_CENTER__, getStrings(), stringCode);
};

// Returns the translation of the string code (including cc translations)
const formatText = (stringCode, injectedStrings, useLanguage, originCountry=undefined) => {
    // NOTE: Commented until traslations is removed from package
    // return validStore() ? i18nCommons.formatText(__CONTACT_CENTER__, store.getState().translations, isProduction, stringCode, injectedStrings, useLanguage, originCountry) : DEFAULT_TRANSLATION_TEXT;
    return i18nCommons.formatText(__CONTACT_CENTER__, getStrings(), isProduction, stringCode, injectedStrings, useLanguage, originCountry);
};

const getFinalTitle = (titleKey, titleStrings = []) => {
    if (!!titleKey) {
        if (typeof(titleKey) === 'string') {
            if (hasTranslation(titleKey)) {
                return <FormattedTextConnected value={titleKey} injectedStrings={titleStrings} />;
            }
        }
        return titleKey
    }
    return null;
};

const formatTextApi = (stringCode, stringValue, injectedStrings, useLanguage, originCountry=undefined) => {
    // NOTE: Commented until traslations is removed from package
    // return validStore() ? i18nCommons.formatTextApi(__CONTACT_CENTER__, store.getState().translations, isProduction, stringCode, stringValue, injectedStrings, useLanguage, originCountry) : DEFAULT_TRANSLATION_TEXT;
    return i18nCommons.formatTextApi(__CONTACT_CENTER__, getStrings(), isProduction, stringCode, stringValue, injectedStrings, useLanguage, originCountry);
};

const formatTextWithDefaultMessage = (stringCode, injectedStrings, useLanguage, defaultMessageCode, defaultInjectedStrings, originCountry=undefined) => {
    // NOTE: Commented until traslations is removed from package
    // return validStore() ? i18nCommons.formatTextWithDefaultMessage(__CONTACT_CENTER__, store.getState().translations, isProduction, stringCode, injectedStrings, useLanguage, defaultMessageCode, defaultInjectedStrings, originCountry) : DEFAULT_TRANSLATION_TEXT;
    return i18nCommons.formatTextWithDefaultMessage(__CONTACT_CENTER__, getStrings(), isProduction, stringCode, injectedStrings, useLanguage, defaultMessageCode, defaultInjectedStrings, originCountry);
};

const getFormattedLanguage = (language) => i18nCommons.getFormattedLanguage(__CONTACT_CENTER__, language);

const extractNamedVariables = (text) => {
    const regex = /{{\s*(.*?)\s*}}/g;
    let match;
    const matches = [];
    const translation = formatText(text);
    while ((match = regex.exec(translation)) !== null) {
        matches.push(match[1]);
    }
    return matches;
}

const getNamedVariablesValues = (namedVariables, objectWithValues) => {
    const variablesValues = namedVariables.reduce((acc, variable) => {
        acc[variable] = objectWithValues[variable];
        return acc;
    }
    , {});
    return variablesValues;
}

class FormattedTextWithNamedVariablesCommons extends React.Component {
    render() {
        const { translations, namedVariables } = this.props;
        const formattedVariables = Immutable.fromJS({
            parameters: namedVariables,
        });
        return (
            <FormattedText
                {...this.props}
                immVariables={formattedVariables}
                translations={translations}
                isProduction={isProduction}
                isContactCenter={__CONTACT_CENTER__}
            />
        );
    }
}

class FormattedTextCommons extends React.Component {
    render() {
        const { translations } = this.props;

        return (
            <FormattedText
                {...this.props}
                translations={translations}
                isProduction={isProduction}
                isContactCenter={__CONTACT_CENTER__}
            />
        );
    }
}

FormattedTextCommons.propTypes = {
    immVariables: PropTypes.any,
    originCountry: PropTypes.string,
    translations: PropTypes.object,
    className: PropTypes.string,
    defaultInjectedStrings: PropTypes.array,
    defaultMessageCode: PropTypes.string,
    format: PropTypes.string,
    id: PropTypes.string,
    injectedStrings: PropTypes.array,
    value: PropTypes.string,
    noWrapper: PropTypes.bool,
    apiValue: PropTypes.string,
    withCountrySuffix: PropTypes.bool
};

const FormattedTextWithNamedVariablesConnected = connect(
    state => ({
        immVariables: state.globalVariables.get('variables'),
        originCountry: state.profile.get('originCountry'),
        translations: state.translations,
    })
)(FormattedTextWithNamedVariablesCommons);

const FormattedTextConnected = connect(
    state => ({
        immVariables: state.globalVariables.get('variables'),
        originCountry: state.profile.get('originCountry'),
        translations: state.translations,
    })
)(FormattedTextCommons);

module.exports.i18n = {
    extractNamedVariables,
    getLanguage,
    getInitialStrings,
    isDefaultLanguage,
    hasTranslation,
    formatText,
    getFinalTitle,
    formatTextApi,
    formatTextWithDefaultMessage,
    getFormattedLanguage,
    getNamedVariablesValues,
    FormattedText: FormattedTextConnected,
    FormattedTextWithNamedVariables: FormattedTextWithNamedVariablesConnected,
};
