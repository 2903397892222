const { formatText } = require('../core/i18n').i18n;

const MORTGAGE_TYPE = {
    FIJO: 'FIJO',
    MIXTO: 'MIXTO',
    VARIABLE: 'VARIABLE'
};

const MORTGAGE_PRODUCT_NUMBER = {
    FIJO: 1,
    MIXTO: 2,
    VARIABLE: 3
};

const MORTGAGE_SUBPRODUCT_TYPE_CODE = {
    FIJO: '801',
    MIXTO: '802',
    VARIABLE: '803'
};

const UNKNOWN = "UNKNOWN";

const getMortgageType = (mortgageType) => {
    switch (mortgageType) {
        case MORTGAGE_TYPE.FIJO:
        case MORTGAGE_TYPE.MIXTO:
        case MORTGAGE_TYPE.VARIABLE:
            return mortgageType;
        default:
            return UNKNOWN;
    }
}

const getMortgageTypeText = (mortgageType) => {
    let mortgageTypeText;
    switch (mortgageType) {
        case MORTGAGE_TYPE.FIJO:
        case MORTGAGE_TYPE.MIXTO:
        case MORTGAGE_TYPE.VARIABLE:
            mortgageTypeText = formatText('mortgageRequestPanel-mortgageType_' + mortgageType);
            break;
        default:
            mortgageTypeText = '';
    }
    return mortgageTypeText;
}

const getMortgageTypeProductSubType = productSubType => {
    return Object.keys(MORTGAGE_SUBPRODUCT_TYPE_CODE).find(key => MORTGAGE_SUBPRODUCT_TYPE_CODE[key] === productSubType);
}

module.exports = {
    MORTGAGE_TYPE,
    MORTGAGE_PRODUCT_NUMBER,
    getMortgageType,
    getMortgageTypeText,
    MORTGAGE_SUBPRODUCT_TYPE_CODE,
    getMortgageTypeProductSubType
}
