const CHAT_CONVERSATION_STATUS = {
    ACTIVE: 'ACTIVE',
    CONNECTING: 'CONNECTING',
    DISCONNECTED: 'DISCONNECTED',
    ENDTIME: '22:00:00',
    ERROR: 'ERROR',
    INACTIVE: 'INACTIVE',
    URL_VALIDATOR_REGEX: /[0-9]/g,
    STARTTIME: '08:00:00',
    SPAINTIMEZONE: 'Europe/Madrid',
    HISTORY_TRANSCRIPT_POSITION: 1,
    POLLING_TIME: 300,
    ERROR_MIN_RANGE: 400,
    ERROR_MAX_RANGE: 500,
    MAX_MESSAGE_LENGTH: 5000,
    MESSAGE_REFRESH_INTERVAL: 1000,
    CONTENT_TYPE: 'string'
};

const CHAT_WINDOW_STATUS = {
    COLLAPSED: 'COLLAPSED',
    EXPANDED: 'EXPANDED'
};

const CHAT_MESSAGE_STATUS = {
    ERROR: 'ERROR',
    SENDING: 'SENDING',
    SENT: 'SENT'
};

const CHAT_MESSAGE_TYPES = {
    MESSAGE: 'MESSAGE',
    IDLE_ALERT: 'IDLEALERT',
    PARTICIPANTJOINED: 'PARTICIPANTJOINED'
};

const CHAT_SENDER_TYPE = {
    AGENT: 'AGENT',
    CLIENT: 'CLIENT',
    EXTERNAL: 'EXTERNAL',
    MESSAGE_TYPE: 'welcome',
    TYPE: 'message'
};

const CHAT_POSITIONS = {
    ALONE: 'ALONE',
    WITH_BACK_TO_TOP: 'WITH_BACK_TO_TOP',
    WITH_FOOTER: 'WITH_FOOTER',
    WITH_FOOTER_AND_BACK_TO_TOP: 'WITH_FOOTER_AND_BACK_TO_TOP',
    WITH_FOOTER_SMALL_VIEWPORT: 'WITH_FOOTER_SMALL_VIEWPORT',
}

module.exports = {
    CHAT_CONVERSATION_STATUS,
    CHAT_MESSAGE_STATUS,
    CHAT_MESSAGE_TYPES,
    CHAT_POSITIONS,
    CHAT_SENDER_TYPE,
    CHAT_WINDOW_STATUS
};
