require('./customMessageBox.scss');

// @ vendors
const React = require('react');
const PropTypes = require('prop-types');

// @ utilities
const classNames = require('classnames');
const { traceFlowEvent } = require('utilities/contactCenter/wrapUp/wrapUpCore')
const { OPERATION_LOG_TYPE_FINISH, OPERATION_LOG_DONE, OPERATION_LOG_FAIL } = require('utilities/contactCenter/wrapUp/constants')

// @ commons
const { OKButton } = require('components/commons/OKButton/OKButton');


class CustomMessageBox extends React.Component {

    constructor(props) {
        super(props);
        this.handleGoBack = this.handleGoBack.bind(this);
    }

    componentDidMount() {
        const { icon, bypassWrapUpNotification } = this.props

        if (__CONTACT_CENTER__ && bypassWrapUpNotification ) return

        if (icon === 'icon-success') {
            traceFlowEvent.notify(OPERATION_LOG_TYPE_FINISH, OPERATION_LOG_DONE)
        } else if (icon === 'icon-warning') {
            traceFlowEvent.notify(OPERATION_LOG_TYPE_FINISH, OPERATION_LOG_FAIL)
        }
    }

    handleGoBack() {
        const { goBackHandler } = this.props;
        goBackHandler();
    }

    buildButton(textLink){
        return (
            <div className="custom-message-box__buttons">
                <div className="custom-message-box__right-button">
                    <OKButton modifier="link-complementary" size="small" onClick={this.handleGoBack}>
                        <span className="custom-message-box__link">{textLink}</span>
                    </OKButton>
                </div>
            </div>
        )
    }

    render() {
        const { icon ,title, subtitle, extraContent, textLink, goBackHandler} = this.props;
        const iconClass = 'custom-message-box__' + icon;
        const classDivider = classNames({'custom-message-box__divider': (textLink && goBackHandler)});
        const classExtraContent = classNames({
            'custom-message-box__extra-content': !!extraContent
        });
        const classBody = classNames(
            'custom-message-box__body',
            {
                'custom-message-box__body--no-extra-content': !extraContent
            }
        );

        let button = (textLink && goBackHandler) ? this.buildButton(textLink) : null;

        return (
            <div className="custom-message-box">
                <div className={classBody}>
                    <div className="custom-message-box__content-message">
                        <div className="custom-message-box__content-icon">
                            <span className="custom-message-box__span-icon"><i className={iconClass}/></span>
                        </div>
                        <div className="custom-message-box__content-text">
                            <p className="custom-message-box__title">{title}</p>
                            <p className="custom-message-box__subtitle">{subtitle}</p>
                        </div>
                    </div>
                    <div className={classExtraContent}>
                        {extraContent}
                    </div>
                </div>
                <div className={classDivider}></div>
                <div className="custom-message-box__footer">
                    {button}
                </div>
            </div>
        );
    }
}

CustomMessageBox.propTypes = {
    icon: PropTypes.oneOf(['icon-success', 'icon-warning']),
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    extraContent: PropTypes.object,
    textLink: PropTypes.string,
    goBackHandler: PropTypes.func,
    bypassWrapUpNotification: PropTypes.bool,
};

CustomMessageBox.defaultPropTypes = {
    bypassWrapUpNotification: false
}

module.exports = CustomMessageBox;
