import { Component } from 'react';
import PropTypes from 'prop-types';

class WebappRender extends Component {
    shouldComponentUpdate() {
        return !__CONTACT_CENTER__;
    }

    render() {
        const { children } = this.props;
        return !__CONTACT_CENTER__ && children ? children : null;
    }
}

WebappRender.propTypes = {
    children: PropTypes.node.isRequired
};

export default WebappRender;
