const PropTypes = require('prop-types');
const React= require('react');
const d3 = require('d3');
const isEqual = require('lodash/lang/isEqual');
const reduce = require('lodash/collection/reduce');
const classnames = require('classnames');
const {BROKER_ACTIVE_CLASS_MAXCHARACTERS} = require('constants/index');


function blackBox(props) {

    const {dataset, width, height, donutWidth, namespace, valueFormatFunction} = props;

    return function(me) {

        let g = me.select('g')
                   .attr('transform', `translate(${width / 2}, ${height / 2})`);

        const radius = Math.min(width, height)/2;

        const pie = d3.layout.pie().value(function(d){ return d; }).sort(null);

        const arc = d3.svg.arc()
                    .outerRadius(radius - donutWidth)
                    .innerRadius(radius);

        let dataRange = 0;

        const dataKeys = dataset.toJS().map((item) => {
            return item.key
        })

        const dataVals = dataset.toJS().map((item) => {
            dataRange = dataRange + item.value;
            return item.value;
        });

        const dataLabels = dataset.toJS().map((item) => {
            return item.label;
        });

        const tooltip = me.select(`.${namespace}__donut-tooltip`);

        const arcTween = function(a) {
            let i;
            if (a.value === this._current.value) {
                this._current = {data: "0", value: 0, startAngle: 0, endAngle: 0.001, padAngle: 0};
            }
            i = d3.interpolate(this._current, a);
            this._current = i(0);
            return function(t) {
                return arc(i(t));
            };
        };

        const tooltipBox = tooltip.node().getBoundingClientRect();

        tooltip.style('left', ((parseInt(width, 10) - tooltipBox.width) /2) + 'px')
               .style('top', ((parseInt(height, 10) - tooltipBox.height) /2) + 'px');

        const d3Render = (donutData) => {

            g.datum(donutData).selectAll('path')
                .data(pie)
            .enter().append('path')
                .each(function(d){ this._current = d; })
                .attr('class', function (d, i) {
                    return `${namespace}__donut-path ${namespace}__donut-path-color-${dataKeys[i]}`
                })
                .on('mouseover', function(d, i) {
                    d3.select(this)
                        .attr('opacity', 0.7);

                    const tooltipLabelValue = valueFormatFunction ? valueFormatFunction(d.data) : d.data;
                    const truncate = dataLabels[i].length > BROKER_ACTIVE_CLASS_MAXCHARACTERS ? `${dataLabels[i].substring(0,11)}...`:dataLabels[i];
                    tooltip.html(`<div><div> ${truncate} </div> ${tooltipLabelValue} %</div>`)
                        .transition()
                        .duration(200)
                        .style('opacity', 0.9);
                })
                .on('mouseleave', function() {
                    d3.select(this)
                        .attr('opacity', 1);

                    tooltip.transition()
                        .duration(500)
                        .style('opacity', 0);
                })
                .attr('d', arc);

            g.datum(donutData).selectAll('path')
                .data(pie)
                .transition().duration(1500).attrTween('d', arcTween);

            g.datum(donutData).selectAll('path')
                .data(pie).exit().remove();
        }

        setTimeout(function () {d3Render(dataVals); }, 100);
    }
}

class DonutChart extends React.Component {

    constructor(props) {
        super(props);
        this.setNode = this.setNode.bind(this);
    }

    componentDidMount() {
        d3.select(this.nodeReference)
            .call(blackBox(this.props));
    }

    componentDidUpdate(old) {
        let difference = reduce(this.props, function(result, value, key) {
            return isEqual(value, old[key]) ? result : result.concat(key);
        }, []);

        if (difference.length === 0) { return; }

        d3.select(this.nodeReference)
            .call(blackBox(this.props));
    }

    setNode(ref) {
        this.nodeReference = ref;
    }

    render() {
        const {width, height, totalInvestmentAmount} = this.props;
        const donutClass = classnames(`${this.props.namespace}__donut-chart-component`, {
            [`${this.props.namespace}__donut-chart-component--empty`]: !totalInvestmentAmount || totalInvestmentAmount <= 0
        });
        const tooltipClass = `${this.props.namespace}__donut-tooltip`;
        const centralCircleClass = `${this.props.namespace}__central-circle`;
        const circleRadius = (width - this.props.donutWidth * 2) / 2;
        const circleStyles = {
            'top': `${this.props.donutWidth}px`,
            'left': `${this.props.donutWidth}px`,
            'width': `${circleRadius * 2}px`,
            'height': `${circleRadius * 2}px`,
            'borderRadius': `${circleRadius * 2}px`
        }
        return (
            <div ref={this.setNode} className={donutClass}>
                <svg width={width}
                    height={height}
                    shapeRendering="geometricPrecision"
                >
                    <g></g>
                </svg>
                <div
                    style={circleStyles}
                    className={centralCircleClass}>
                </div>
                <div className={tooltipClass}></div>
            </div>
        );
    }
}

DonutChart.propTypes = {
    dataset: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    donutWidth: PropTypes.string.isRequired,
    namespace: PropTypes.string.isRequired,
    totalInvestmentAmount: PropTypes.number,
    valueFormatFunction: PropTypes.func
};

module.exports = DonutChart;