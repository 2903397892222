require('./confirmationScript.scss');

const React = require('react');
const PropTypes = require('prop-types');
const { connect } = require('react-redux');
const classNames = require('classnames');

const { formatText, FormattedText } = require('core/i18n').i18n;
const { getFullName } = require('utilities/personalDataHelper');

const CONFIRMATION_SCRIPT_GREETING = 0,
        CONFIRMATION_SCRIPT_GREETING_WITH_SUMMARY = 1,
        CONFIRMATION_SCRIPT_AGREEMENT =2,
        CONFIRMATION_SCRIPT_NO_OP_NAME = 3;
class ConfirmationScript extends React.Component {
    buildContent(){
        // 0 If the confirmation component does not have a summary data, you have to use this and the second type at the end
        // 1 First type that use name, operative and a message
        // 2 Second type use only name

        const { immProfile, operative, confirmationType, extraClass, isOverDraft } = this.props;
        let confirmation;
        const keyName = '<strong>' + getFullName(immProfile, false) + '</strong>';
        const keyOperative = '<strong>' + operative + '</strong>';

        const confirmationMsg = formatText('confirmationScript-confirmationMsg');
        const greetings = (
            <FormattedText
                value="confirmationScript-greetings"
                injectedStrings={[keyName]}
            />
        );
        let operativeTxt = 
        !!isOverDraft ? (
            <FormattedText
                value="confirmationScript-overdraftOperativeTxt"
            />
        ):(
            <FormattedText
                value="confirmationScript-operativeTxt"
                injectedStrings={[keyOperative]}
            />
        );
        if (confirmationType === CONFIRMATION_SCRIPT_NO_OP_NAME) {
            operativeTxt = operative;
        }
        const promptMsg = (
            <FormattedText
                value="confirmationScript-promptMsg"
                injectedStrings={[keyName]}
            />
        );

        const onlyNameMsg = (
            <FormattedText
                value="confirmationScript-confirmationScript_onlyNameMessage"
                injectedStrings={[keyName]}
            />
        )

        const componentClass = classNames(
            'confirmation-script',
            {
                [extraClass]: !!extraClass,
            }
        );

        switch (confirmationType) {
            case CONFIRMATION_SCRIPT_GREETING:
                confirmation = (
                    <div>
                        <p>{greetings}</p>
                        <p>{operativeTxt}</p>
                    </div>
                );
                break;
            case CONFIRMATION_SCRIPT_GREETING_WITH_SUMMARY:
                confirmation = (
                    <div>
                        <p>{greetings}</p>
                        <p>{operativeTxt}</p>
                        <p><strong className="no-print-out">{confirmationMsg}</strong></p>
                    </div>
                );
                break;
            case CONFIRMATION_SCRIPT_AGREEMENT:
                confirmation = (
                    <p>{promptMsg}</p>
                );
                break;
            case CONFIRMATION_SCRIPT_NO_OP_NAME:
                confirmation = (
                    <React.Fragment>
                        <p>{onlyNameMsg}</p>
                        <p>{operativeTxt}</p>
                    </React.Fragment>
                );
                break;
            default:
                confirmation = (
                    <div>
                        <p>{greetings}</p>
                        <p>{operativeTxt}</p>
                        <p><strong>{confirmationMsg}</strong></p>
                    </div>
                );
                break;
        }

        return (
            <div className={componentClass}>
                {confirmation}
            </div>
        );
    }
    render() {
        return __CONTACT_CENTER__ ? this.buildContent() : null;
    }
}

ConfirmationScript.defaultProps = {
    isOverDraft: false
}

ConfirmationScript.propTypes = {
    immProfile: PropTypes.object.isRequired,
    operative: PropTypes.string,
    confirmationType: PropTypes.number.isRequired,
    extraClass: PropTypes.string,
    isOverDraft: PropTypes.bool
}
const connectedConfirmationScript = connect(
    state => ({
        immProfile: state.profile,
    })
)(ConfirmationScript);

module.exports={
    CONFIRMATION_SCRIPT_GREETING,
    CONFIRMATION_SCRIPT_GREETING_WITH_SUMMARY,
    CONFIRMATION_SCRIPT_AGREEMENT,
    ConfirmationScript: connectedConfirmationScript,
}


