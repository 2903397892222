require('./dropdownAccounts.scss');

const PropTypes = require('prop-types');

// @ vendors
const React = require('react');
// @ commons
const { HYPHEN } = require ('constants/index');
const { getLastNChars } = require('utilities/stringHelper');
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');
//@core
const { getLanguage } = require('core/i18n').i18n;
// @ helpers
const { formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');

class AccountItemSelected extends React.Component {

    render () {
        const {value} = this.props;
        const account = value
        const id = `${account.id}_Selected`;
        const accountIban = getLastNChars(account.iban);
        const plainAmountObject = getPlainAmountObject(account.balance);

        return (
            <div id={id} className="Select-value dropdown-accounts__account">
                <div className="Select-value-label">
                    <div className="dropdown-accounts__account-description">
                        <span className="dropdown-accounts__account-description-alias">{account.alias}</span>
                        <span className="dropdown-accounts__account-description-iban" > {accountIban}</span>
                        <span className="dropdown-accounts__account-description-separator"> {HYPHEN} </span>
                        <span className="dropdown-accounts__account-description-type">{account.type} </span>
                        <OneLineSymbolAmount
                            amountObject={plainAmountObject}
                            className="dropdown-accounts__account-description-amount"
                            language={getLanguage()}
                            amountObjectFormatted={formatAmountObject(plainAmountObject, true)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

AccountItemSelected.propTypes = {
    value: PropTypes.object
};

module.exports = AccountItemSelected;