require('./stocksContractDropdownValue.scss');

const PropTypes = require('prop-types');

// @vendors
const React = require('react');

// @helpers
const { formatText, getLanguage } = require('core/i18n').i18n;
const { formatAmountObject } = require('utilities/currencyHelper');
//@constants
const { COLON, BROKER_FUND_NEW_CONTRACT_OPTION } = require('constants/index');

// @components
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');

class StocksContractDropdownFundSubscriptionValue extends React.Component {
    render() {
        const { contractNumber, totalValue, totalValueCurrency, value } = this.props.value;
        const balanceLabel = `${formatText('broker-stocksContractDropdownContractBalance')}${COLON}`;
        const plainAmountObject = {
            amount: totalValue,
            currency: totalValueCurrency
        };
        if (value === BROKER_FUND_NEW_CONTRACT_OPTION) {
            return (
                <div className="stocks-contract-dropdown__option">
                    <span className="stocks-contract-dropdown__option--highlighted">
                        {totalValue}
                    </span>
                </div>
            );
        }
        return (
            <div className="stocks-contract-dropdown__value" onMouseDown={this.handleMouseDown}>
                <span className="stocks-contract-dropdown__value--margin stocks-contract-dropdown__value--light">
                    {`${formatText('brokerHireInvestmentStep1-fundContractDropdown_contractNumberOptionLabel')} `}
                </span>
                <span className="stocks-contract-dropdown__value--highlighted">{contractNumber}</span>

                <span className="pull-right">
                    <span className="stocks-contract-dropdown__right-text-title">{balanceLabel}</span>
                    <span className="stocks-contract-dropdown__value--highlighted">
                        <OneLineSymbolAmount
                            amountObject={plainAmountObject}
                            language={getLanguage()}
                            amountObjectFormatted={formatAmountObject(plainAmountObject, true)}
                        />
                    </span>
                </span>
            </div>
        );
    }
}

StocksContractDropdownFundSubscriptionValue.propTypes = {
    value: PropTypes.object
};

module.exports = StocksContractDropdownFundSubscriptionValue;