require('./card.scss');

const PropTypes = require('prop-types');

// @ vendor
const React = require('react');
const { formatText } = require('core/i18n').i18n;
const classNames = require('classnames');
// @ helper
const { getCommercialDataFromPath, getCardIdAd, immAdIsNotEmpty } = require('utilities/commercialAdsHelper');
// @ constants
const {
    CARD_ECARD,
    DATE_FORMAT_SHORT_MONTH_SHORT_YEAR,
    CARD_PLACEHOLDER,
    CARD_SIZE_BIG,
    CARD_SIZE_MEDIUM
} = require('constants/index');
// @ utilities
const DateHelper = require('utilities/dateHelper');
const { getLastNChars } = require('utilities/stringHelper');

class Card extends React.Component {

    constructor(props) {
        super(props);

        const  { size, immCard } = this.props;
        const isPrepaid = immCard.get('type') === CARD_ECARD;

        //(nicolas.olmos): OpenYoung cards has to be styled as credit/debit cards.
        this.state = {
            isMinicard: immCard.get('isMinicard'),
            isEcard: isPrepaid && !immCard.get('isOpenYoung'),
            isMediumSize: size === CARD_SIZE_MEDIUM,
            isBigSize: size === CARD_SIZE_BIG
        };
    }

    getFormattedPAN() {
        const lastNumbersCard = getLastNChars(this.props.immCard.get('number')).substr(3);
        let formattedPAN = [];
        let i = 0;

        for (i; i < 3; i++) {
            formattedPAN.push(<span key={i}>{CARD_PLACEHOLDER}</span>);
        }
        formattedPAN.push(<span key={i}>{lastNumbersCard}</span>);

        return formattedPAN;
    }

    buildCardBeneficiaryName() {
        const { immCard } = this.props;
        const { isEcard, isMediumSize } = this.state;
        const cardBeneficiaryName = immCard.get('beneficiaryNameOnCard').replace(/\*/g, ' ');

        const className = classNames({
            'card__beneficiary-name': !isEcard && !isMediumSize,
            'card__beneficiary-name--medium': !isEcard && isMediumSize,
            'card__beneficiary-name--ecard': isEcard,
            'card__beneficiary-name--ecard-medium': isEcard && isMediumSize
        });

        return (
            <div className={className}>
                {cardBeneficiaryName}
            </div>
        );
    }

    buildCardExpirationDate() {
        const { immCard } = this.props;
        const { isEcard, isMediumSize } = this.state;
        const expirationDate = DateHelper.formatDate(immCard.get('expirationDate'), DATE_FORMAT_SHORT_MONTH_SHORT_YEAR);
        const expirationDateLabel = isEcard ? expirationDate : formatText('card-expirationDate')  + expirationDate;

        const className = classNames({
            'card__expiration-date': !isEcard && !isMediumSize,
            'card__expiration-date--medium': !isEcard && isMediumSize,
            'card__expiration-date--ecard': isEcard,
            'card__expiration-date--ecard-medium': isEcard && isMediumSize
        });

        return (
            <div className={className}>
                {expirationDateLabel}
            </div>
        );
    }

    buildCardInfo() {
        const { immCard, immCommercialAds, showOnlyNumber } = this.props;
        const { isMinicard } = this.state;
        let commercialData = getCommercialDataFromPath('cards', 'cards');
        commercialData = getCardIdAd(immCard, commercialData);
        const immAd = immCommercialAds.getIn(['byId', commercialData.id]);
        let cardExpirationDate;
        let cardBeneficiaryName;
        let dataToRender;

        if (immAdIsNotEmpty(immAd)) {
            const cardNumber = this.buildCardNumber();

            if (!isMinicard && !showOnlyNumber) {
                cardExpirationDate = this.buildCardExpirationDate();
                cardBeneficiaryName = this.buildCardBeneficiaryName();
            }

            dataToRender = (
                <div>
                    {cardNumber}
                    {cardExpirationDate}
                    {cardBeneficiaryName}
                </div>
            );
        } else if (showOnlyNumber) {
            // When the card img is not available from CMS. Only for the flow of the cards landing.
            const hasCardImg = false;

            dataToRender = (
                <div>
                    {this.buildCardNumber(hasCardImg)}
                </div>
            );
        }

        return dataToRender;
    }

    buildCardNumber(hasCardImg = true) {
        const { immCard, isSmallSize, showOnlyNumber } = this.props;
        const { isMinicard, isEcard, isBigSize, isMediumSize } = this.state;
        const isVanityCard = immCard.get('isVanityCard');
        let cardNumber;

        if (isMinicard) {
            cardNumber = getLastNChars(immCard.get('number')).substr(3);
        } else {
            cardNumber = this.getFormattedPAN();
        }

        const className = classNames({
            'card__card-number': !isEcard && !isMinicard && !isMediumSize && !isSmallSize,
            'card__card-number--big': !isEcard && !isMinicard && !isMediumSize && isBigSize,
            'card__card-number--medium': !isEcard && !isMinicard && isMediumSize && !isBigSize,
            'card__card-number--small': !isBigSize && (!isEcard && !isMinicard && isSmallSize || !hasCardImg),
            'card__card-number--hide': showOnlyNumber && !isSmallSize && !isBigSize,
            'card__card-number--show': showOnlyNumber && isSmallSize || isBigSize,
            'card__card-number--only-number': showOnlyNumber && !isVanityCard,
            'card__card-number--vanity': showOnlyNumber && isVanityCard,
            'card__card-number--ecard': isEcard && hasCardImg,
            'card__card-number--ecard-medium': isEcard && isMediumSize,
            'card__card-number--ecard-small': isEcard && isSmallSize && hasCardImg,
            'card__card-number--minicard': isMinicard && hasCardImg,
            'card__card-number--minicard-medium': isMinicard && isMediumSize,
            'card__card-number--minicard-small': isMinicard && isSmallSize && hasCardImg
        });

        return (
            <div className={className}>
                {cardNumber}
            </div>
        );

    }

    render() {
        const cardInfo = this.buildCardInfo();

        return (
            <div className="card">
                {cardInfo}
            </div>
        );
    }
}

Card.propTypes = {
    immCard: PropTypes.object.isRequired,
    immCommercialAds: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool,
    showOnlyNumber: PropTypes.bool,
    size: PropTypes.string
};

Card.DefaultProps = {
    isSmallSize: false,
    showOnlyNumber: false
};

module.exports = Card;