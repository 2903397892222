const apiURLBuilder = require('core/apiURLBuilder');
const { PRODUCTION_ENVIRONMENT, OCULTO_ENVIRONMENT } = require('constants/index');

const FAQ_URL = apiURLBuilder.getPublicSiteUrl() + '/ayuda/claves-seguridad';
const SEDE_ELECTRONICA_AGENCIA_TRIBUTARIA_SPANISH = 'http://www.agenciatributaria.es/';
const SEDE_ELECTRONICA_AGENCIA_TRIBUTARIA_ENGLISH = 'http://www.agenciatributaria.es/AEAT.internet/en_gb/Inicio.shtml';
const SEDE_ELECTRONICA_SEGURIDAD_SOCIAL_SPANISH =
    'https://sede.seg-social.gob.es/wps/portal/sede/sede/Ciudadanos/CiudadanoDetalle/!ut/p/z1/rVRdk5owFP0ruw88Ym7CV-gbOg6rlbWuZRVenABB02pgIatrf32BdWanH2pnLC8ZLjfnHM7JDYrREsWS7cWaKVFItm3eo9heGdg2sQt44sNoCF4YfA5dY2b4I4wWXQOceTxA8eX9zyhGcSpVqTYoqnnGV2khFZciK2oN2oIGqXjNWMZkWxEyL6odr--OdymvlMhFyrpWApjaZotWpiJDUYYTyizD0XNsWLrppKmeOA7XXct1cpuw3MTJSf0ZeeBeVb9o-a4gdA2XDLpIQmw0RvF6WyTvaXgyMegaxRXPecWr3mvVlDdKlZ800OBwOPTKolJs26s7s1TFJFd1_aUralCe1kFnMs-KSgOlwUiKVBR_w98UtULLX3BR1LjmfCgG8JsfCfswfwaCwTfQYi_4AYWySapRPf8IxeU4yy0z0S3WhkKoqVOeEp2kHEiWU8uyGHqA3xl8PLNahikdPc0wUOdGhnd4a0oBj4FMwA4oeO5sHk4esQGOeSP8-Fqijc-kCgZB43PJ1EZvDzVanjnaaAn47CcUiW8vL7HXzFCb6FuT1S1DdJ7pj0T6Jnh4-tUmD0MM0wuW_dMgXkzEB3wj_PjaIP9vI0-30ZxLVO7CHTWO-vcnejS2-90gocHQsNa7VVDzhR6N9z_6j3qcmN79_U8nBUGx/dz/d5/L0lHSkovSUNqQUFOeEFBRVEhLzROVkUvZXM!/';
const SEDE_ELECTRONICA_SEGURIDAD_SOCIAL_ENGLISH =
    'https://sede.seg-social.gob.es/wps/portal/sede/sede/Ciudadanos/CiudadanoDetalle/!ut/p/z1/rVRdk5owFP0ruw88Ym4IgdA3dBxWK2tdyyq8OAGCplVgIatrf32BdWanH2pnLC8ZLjfnHM7JDYrQEkU538s1V7LI-bZ5DyNrRbBlYgfwxIPRENzA_xw4ZEa8EUaLrgHOPC6g6PL-ZxShKMlVqTYorEUqVkmRK5HLtKg1aAsaJPI15SnP24rMs6LaifrueJeISslMJrxrNQAzy2zRykSmKExxzDgltp5hQnXTThI9tm2hO9SxM8vgmYnjk_oz8sC5qn7R8l1B6BouGXSRxLDQGEXrbRG_p-HmMWFrFFUiE5Woeq9VU94oVX7SQIPD4dAri0rxba_uzFIVz4Wq6y9dUYPytA46k0VaVBooDUa5TGTxN_xNUSu0_AUXhY1r9odiAK_5kaAP82cwMHgELfZSHFCQN0k1qucfoTgCpxk1Y53yNhSDmToTiaEbiQAjzRillKMH-J3BwzPaMkzZ6GmGgdk3MrzD0ykDPAZjApbPwHVm82DyiAnY5o3w42uJNj4blT_wG59LrjZ6e6jR8szRRkvAZz-hUH57eYncZobaRN-arG4ZovNMfyTSN8HF06-W8TDEML1g2T8N4sVEPMA3wo-vDfL_NvJ0G81FjcpdsGPkqH9_Ykey3e8GMfOHhK53K78WCz0c73_0H_UoNt37-5-Xhiwz/dz/d5/L0lHSkovSUNqQUFOeEFBRVEhLzROVkUvZW4!/';
const PLACEHOLDER_MANAGER = '//placehold.it/50x50';
const LISTA_DOCUMENTOS_FIRMA = 'https://www.openbank.es/assets/hipotecario/Hipotecario-listaDocumentosFirma.pdf';
const PDF_TASACION = 'https://s3-eu-west-1.amazonaws.com/pubprobkt001/assets/hipotecario/OPENBANK_Tasadoras%20Homologadas.pdf';
const ADD_EMAIL_PRIVACY = 'https://s3-eu-west-1.amazonaws.com/pubprobkt001/assets/hipotecario/Hipotecario-clausulaAceptacionLargo.pdf';

//PRE URL
let MORTGAGE_SIMULATOR_TOTAL_COSTS = 'https://openbanksimuladores.afi.es/MortgageSimulatorTotalCosts.aspx?valorVivienda=VALOR_VIVIENDA&ubicacionVivienda=UBICACION_VIVIENDA&tipoVivienda=TIPO_VIVIENDA&anios=ANIOS&montoHipoteca=MONTO_HIPOTECA&viviendaNueva=VIVIENDA_NUEVA&mostrarInforme=MOSTRAR_INFORME&MortType=MORT_TYPE';
let MORTGAGE_APPRAISAL_AFI = 'https://openbanksimuladores.afi.es/MortgageSimulatorIntranet.aspx';
/* REMOVE-IN-PROD:START */
if (window.__ENV__ !== PRODUCTION_ENVIRONMENT && window.__ENV__ !== OCULTO_ENVIRONMENT) {
    MORTGAGE_SIMULATOR_TOTAL_COSTS = 'https://openbanksimuladores-pre.afi.es/MortgageSimulatorTotalCosts.aspx?valorVivienda=VALOR_VIVIENDA&ubicacionVivienda=UBICACION_VIVIENDA&tipoVivienda=TIPO_VIVIENDA&anios=ANIOS&montoHipoteca=MONTO_HIPOTECA&viviendaNueva=VIVIENDA_NUEVA&mostrarInforme=MOSTRAR_INFORME&MortType=MORT_TYPE';
    MORTGAGE_APPRAISAL_AFI = 'https://openbanksimuladores-pre.afi.es/MortgageSimulatorIntranet.aspx';
}
/* REMOVE-IN-PROD:END */

const MORTGAGE_DOCUMENTS_IDEP = 'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431476387372&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview';
const MORTGAGE_DOCUMENTS_HIPOTECA_FIJA_DIPREC = 'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431476387078&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview';
const MORTGAGE_DOCUMENTS_HIPOTECA_FIJA_FIPRE = 'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431476387463&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview';
const MORTGAGE_DOCUMENTS_HIPOTECA_MIXTA_DIPREC = 'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431476387106&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview';
const MORTGAGE_DOCUMENTS_HIPOTECA_MIXTA_FIPRE = 'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431476387487&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview';
const MORTGAGE_DOCUMENTS_HIPOTECA_VARIABLE_DIPREC = 'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431476387604&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview';
const MORTGAGE_DOCUMENTS_HIPOTECA_VARIABLE_FIPRE = 'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431476387528&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview';
const MORTGAGE_APPRAISAL_LIST = 'https://s3-eu-west-1.amazonaws.com/pubprobkt001/assets/hipotecario/OPENBANK_Tasadoras%20Homologadas.pdf';
const MORTGAGE_PANEL = '/myprofile/mortgage/panel';
const MORTGAGE_SIMPLE_NOTE_ISSUE = '/myprofile/mortgage/simple-note-issue';
const MORTGAGE_SIMPLE_NOTE_RESULT = '/myprofile/mortgage/simple-note-result';
const DATA_PROTECTION = 'https://www.openbank.es/politica';
module.exports = {
    ADD_EMAIL_PRIVACY,
    FAQ_URL,
    SEDE_ELECTRONICA_AGENCIA_TRIBUTARIA_SPANISH,
    SEDE_ELECTRONICA_AGENCIA_TRIBUTARIA_ENGLISH,
    SEDE_ELECTRONICA_SEGURIDAD_SOCIAL_SPANISH,
    SEDE_ELECTRONICA_SEGURIDAD_SOCIAL_ENGLISH,
    PDF_TASACION,
    PLACEHOLDER_MANAGER,
    LISTA_DOCUMENTOS_FIRMA,
    MORTGAGE_SIMULATOR_TOTAL_COSTS,
    MORTGAGE_DOCUMENTS_IDEP,
    MORTGAGE_DOCUMENTS_HIPOTECA_FIJA_DIPREC,
    MORTGAGE_DOCUMENTS_HIPOTECA_FIJA_FIPRE,
    MORTGAGE_DOCUMENTS_HIPOTECA_MIXTA_DIPREC,
    MORTGAGE_DOCUMENTS_HIPOTECA_MIXTA_FIPRE,
    MORTGAGE_DOCUMENTS_HIPOTECA_VARIABLE_DIPREC,
    MORTGAGE_DOCUMENTS_HIPOTECA_VARIABLE_FIPRE,
    MORTGAGE_APPRAISAL_LIST,
    MORTGAGE_PANEL,
    MORTGAGE_SIMPLE_NOTE_ISSUE,
    MORTGAGE_SIMPLE_NOTE_RESULT,
    DATA_PROTECTION,
    MORTGAGE_APPRAISAL_AFI
};
