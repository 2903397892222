const PropTypes = require('prop-types');
// @vendors
const React = require('react');

// @helpers
const { formatText, getLanguage } = require('core/i18n').i18n;
const { getLastNChars } = require('utilities/stringHelper');
const { formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');

// @components
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');

class StocksContractDropdownFuturesValue extends React.Component {
    render() {
        const {nombretitular, iban, balance} = this.props.value;
        const ibanLastChars = getLastNChars(iban);
        const plainAmountObject = getPlainAmountObject(balance);

        return (
            <div className="stocks-contract-dropdown__value" onMouseDown={this.handleMouseDown}>
                <span className="stocks-contract-dropdown__value-label stocks-contract-dropdown__value--highlighted">
                    {nombretitular}
                </span>
                <span className="stocks-contract-dropdown__value--medium">
                    {ibanLastChars}
                </span>

                <span className="stocks-contract-dropdown__value--right">
                    <span>
                        {formatText('broker-stocksContractDropdownDerivativesTransferBalance')}
                    </span>
                    <span className="stocks-contract-dropdown__value-price stocks-contract-dropdown__value--highlighted">
                        <OneLineSymbolAmount
                            amountObject={plainAmountObject}
                            language={getLanguage()}
                            amountObjectFormatted={formatAmountObject(plainAmountObject, true)} />
                    </span>
                </span>
            </div>
        );
    }
}

StocksContractDropdownFuturesValue.propTypes = {
    value: PropTypes.object
};

module.exports = StocksContractDropdownFuturesValue;