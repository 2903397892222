const SECURITY_LEVEL_UPGRADE_PATH = '/keys/login-as-client';
const SECURITY_SEND_KEY_PATH = '/keys/send';
const SECURITY_SEND_KEY_N1_MOBILE = '/keys/recovery';
const SECURITY_SEND_KEY_N1_POST = '/keys/send-n1-post';
const SECURITY_SEND_KEY_N2_POST = '/keys/send-n2-post';
const UNKNOWN_SECURITY_LEVEL_PATH = '/unknown-security-level';
const SESSION_ERROR_LEVEL = '/session';
const CONTACT_CENTER_LANDING_SCREEN_PATH = '/contactCenterLandingPage';
const DISASTER_RECOVERY_CUSTOMER_FINDER   = '/disasterRecovery/customerFinder';
const DISASTER_RECOVERY_RECOVERY_KEY   = '/disasterRecovery/keys-recovery';
const AGENT_LOGIN_DISASTER = '/disasterRecovery/agentLogin';
const PRINTER_PREVIEW = '/printerPreview';
const LOGIN_PATH = '/login';

module.exports = {
    SECURITY_LEVEL_UPGRADE_PATH,
    UNKNOWN_SECURITY_LEVEL_PATH,
    SESSION_ERROR_LEVEL,
    AGENT_LOGIN_DISASTER,
    DISASTER_RECOVERY_CUSTOMER_FINDER,
    SECURITY_LEVEL_WHITELIST: [
        SECURITY_LEVEL_UPGRADE_PATH,
        UNKNOWN_SECURITY_LEVEL_PATH,
        SESSION_ERROR_LEVEL,
        SECURITY_SEND_KEY_PATH,
        SECURITY_SEND_KEY_N1_MOBILE,
        SECURITY_SEND_KEY_N1_POST,
        SECURITY_SEND_KEY_N2_POST,
        CONTACT_CENTER_LANDING_SCREEN_PATH,
        PRINTER_PREVIEW,
    ],
    AUTHENTICATION_WHITELIST: [
        LOGIN_PATH,
        SESSION_ERROR_LEVEL,
        UNKNOWN_SECURITY_LEVEL_PATH,
        SECURITY_LEVEL_UPGRADE_PATH,
        SECURITY_SEND_KEY_N1_MOBILE,
        AGENT_LOGIN_DISASTER,
        PRINTER_PREVIEW,
    ],
    SKIP_CLIENT_LOGIN_WHITELIST: [
        SECURITY_SEND_KEY_PATH,
        SECURITY_SEND_KEY_N1_POST,
        SECURITY_SEND_KEY_N2_POST,
        PRINTER_PREVIEW,
    ],
    MENU_DISASTER_WHITELIST: [
        LOGIN_PATH,
        DISASTER_RECOVERY_CUSTOMER_FINDER,
        SECURITY_SEND_KEY_N1_MOBILE,
        DISASTER_RECOVERY_RECOVERY_KEY,
        PRINTER_PREVIEW,
    ]
};
