const allowedURLs = [
    '/myprofile/loans-mortgages',
    '/myprofile/loans-mortgages/aresusers',
    '/myprofile/accounts',
    '/myprofile/edit',
    '/myprofile/keys',
    '/myprofile/documentation',
    '/myprofile/purchase/checking-account',
    '/myprofile/purchase/payroll-account'
];

const loanURLs = [
    '/aresusers',
    '/preapproved-loans',
    '/preapproved-loans',
    '/loans',
    '/credit'
];

const forbiddenURLs = [
    '/myprofile/keys/tpps',
];

const allowedSettingsDropdownCC = [
    '/keys/send-n2-post',
    '/myprofile/devices',
    '/myprofile/documentation',
    '/myprofile/edit',
    '/myprofile/keys/securitykit',
];

const LOANS_ARES_URL = '/myprofile/loans-mortgages/aresusers';
const LOANS_ARES_LANDING_URL = '/myprofile/hire/ares-landing';
const GLOBAL_POSITION_URL = '/myprofile/global';

module.exports = {
    allowedSettingsDropdownCC,
    allowedURLs,
    loanURLs,
    forbiddenURLs,
    LOANS_ARES_URL,
    LOANS_ARES_LANDING_URL,
    GLOBAL_POSITION_URL
};
