require('./mortgageRequestManagerInfo.scss');

const PropTypes = require('prop-types');

// @ vendors
const React = require('react');
const { formatText } = require('core/i18n').i18n;
const { connect } = require('react-redux');
const classnames = require('classnames');

// @ components
const { LoadingSection } = require('commonsComponents/loadingSection');

// @ actions
const getManagerActions = require('actions/mortgageRequest/mortgageRequestManager');

// @ helpers
const { toCamelCase } = require('utilities/stringHelper');

class MortgageRequestManagerInfo extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { getManagerInfo, managerInfo } = this.props;

        const managerName = managerInfo.get('name');
        const managerIsLoading = managerInfo.get('isFetching');

        if (!managerName && !managerIsLoading) {
            getManagerInfo();
        }
    }

    render() {
        const { managerInfo, managerTitle, compact } = this.props;

        if (!managerInfo.get('name') || managerInfo.get('isFetching')) {
            return <LoadingSection />;
        }

        const managerInfoTitle = managerTitle ? (
            <div className="mortgage-request-manager-info__box-title">
                {managerTitle}
            </div>
        ) : '';

        const dataWrapperClass = classnames('mortgage-request-manager-info__data-wrapper', {
            'mortgage-request-manager-info__data-wrapper--compact': compact
        });

        const managerPhoto = managerInfo.get('foto');
        const managerFullName = toCamelCase(`${managerInfo.get('name')} ${managerInfo.get('lastName')}`);
        const managerEmail = managerInfo.get('email');
        const managerSchedule = managerInfo.get('horario');
        const managerPhone = formatText('mortgageRequestManager-phonePrefix', [managerInfo.get('phone')]);

        return (
            <div className="mortgage-request-manager-info">
                <div className="row">
                    <div className="col-xs-12">
                        {managerInfoTitle}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-2">
                        <div className="mortgage-request-manager-info__img-wrapper">
                            <div className="mortgage-request-manager-info__pic-slider">
                                <img
                                    className="mortgage-request-manager-info__img img-responsive"
                                    src={managerPhoto}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-10">
                        <div className={dataWrapperClass}>
                            <span className="mortgage-request-manager-info__data-line">
                                <strong className="mortgage-request-manager-info--full-name">{managerFullName}</strong>
                            </span>
                            <span className="mortgage-request-manager-info__data-line mortgage-request-manager-info__email-text">
                                <strong>{managerEmail}</strong>
                            </span>
                            <span className="mortgage-request-manager-info__data-line">
                                {managerSchedule}
                            </span>
                            <span className="mortgage-request-manager-info__data-line">
                                {formatText('mortgageRequestManager-phone')}
                                <strong>{managerPhone}</strong>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
       );
    }
}

MortgageRequestManagerInfo.propTypes = {
    getManagerInfo: PropTypes.func,
    managerInfo: PropTypes.object,
    managerTitle: PropTypes.string,
    compact: PropTypes.bool
};

MortgageRequestManagerInfo.defaultProps = {
    managerTitle: '',
    compact: true
};

module.exports.constructor = MortgageRequestManagerInfo;
module.exports.MortgageRequestManagerInfo = connect(
    state => ({
        managerInfo: state.mortgageRequestManagerInfo
    }), {
        getManagerInfo: getManagerActions.getManagerInformation
})(MortgageRequestManagerInfo);
