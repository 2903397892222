const allowedURLs = [
    '/disasterRecovery/agentLogin',
    '/disasterRecovery/customerFinder',
    '/disasterRecovery/keys-recovery',
    '/keys/login-as-client',
    '/keys/send',
    '/keys/send-n1-post',
    '/keys/send-n2-post',
    '/myprofile/accounts',
    '/myprofile/cards',
    '/myprofile/databank',
    '/myprofile/databank/setup',
    '/myprofile/databank/login',
    '/myprofile/databank/intro',
    '/myprofile/databank/secrets',
    '/myprofile/databank/secret/create',
    '/myprofile/databank/changeMasterPassword',
    '/myprofile/databank/deleteVault',
    '/myprofile/devices',
    '/myprofile/devices/forgotten',
    '/myprofile/documentation',
    '/myprofile/edit',
    '/myprofile/edit/shipping-address/add',
    '/myprofile/fiscal',
    '/myprofile/global/entities/accounts',
    '/myprofile/global/entities/add',
    '/myprofile/global/entities/credentials/Token',
    '/myprofile/global/entities/manage',
    '/myprofile/global/expenses-by-category/',
    '/myprofile/global/entities/expenses-by-category/',
    '/myprofile/hire/card/nrd-card/',
    '/myprofile/hire/card/vanity-fair',
    '/myprofile/hire/card/vanity-fair/',
    '/myprofile/hire/card/eh-card',
    '/myprofile/hire/card/eh-card/',
    '/myprofile/keys',
    '/myprofile/keys/password',
    '/myprofile/keys/securitykit',
    '/myprofile/keys/signature',
    '/myprofile/okdiscounts',
    '/myprofile/purchase/payroll-account',
    '/myprofile/purchase/checking-account',
    '/myprofile/consent',
    '/myprofile/rights',
    '/myprofile/rights/access',
    '/myprofile/rights/portability',
];

const cardsURLs = [
    '/activate',
    '/benefits/r42',
    '/block',
    '/cancel-renewal',
    '/cancelled',
    '/config',
    '/deferred-purchases',
    '/details',
    '/mobile-recharge',
    '/movements',
    '/payment-method/change',
    '/payment-purchase-deferral',
    '/pin-reset',
    '/request/pin',
    '/request/cvv',
    '/request-additional',
    '/replace',
    '/rewards',
    '/secure-payment',
    '/send-pin',
    '/statement',
    '/statements',
];

const forbiddenURLs = [
    '/myprofile/keys/tpps',
];

const allowedSettingsDropdownCC = [
    '/keys/send-n2-post',
    '/myprofile/devices',
    '/myprofile/documentation',
    '/myprofile/edit',
    '/myprofile/keys/securitykit',
];

module.exports = {
    allowedSettingsDropdownCC,
    allowedURLs,
    cardsURLs,
    forbiddenURLs,
};
