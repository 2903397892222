// @vendors
const React = require('react');
const PropTypes = require('prop-types');

// @components
const { Tutorial } = require('./tutorial');
const FeatureTogglingRender = require('components/conditionalRender/featureTogglingRender');

// @utilities
const { formatText } = require('core/i18n').i18n;

// @ hoc
const withContactCenterValidation = require('utilities/withContactCenterValidation');

// @constants
const { TUTORIAL } = require('constants/flagBasedFeatures');

class TutorialLauncher extends React.Component {
    render() {
        const {
            closeCallBack,
            onOpenHandler,
            subtitle,
            title,
            tooltip,
            type,
            shouldOpenByDefault
        } = this.props;
        const images = require(`./types/${type}/index`);
        const titleText = title || formatText(`tutorials-type${type}Title`);
        const subtitleText = subtitle || formatText(`tutorials-type${type}Subtitle`);
        const tooltipText = tooltip || formatText(`tutorials-type${type}Tooltip`);

        return (
            <FeatureTogglingRender notAllowedProductKey={TUTORIAL} >
                <Tutorial
                    id={type}
                    images={images}
                    subtitle={subtitleText}
                    title={titleText}
                    tooltip={tooltipText}
                    notCenterPadding={this.props.notCenterPadding}
                    shouldOpenByDefault={shouldOpenByDefault}
                    closeCallBack={closeCallBack}
                    onOpenHandler={onOpenHandler}
                 />
            </FeatureTogglingRender>
        );
    }
}

TutorialLauncher.defaultProps = {
    subtitle: '',
    title: '',
    tooltip: '',
    notCenterPadding: false
}

TutorialLauncher.propTypes = {
    onOpenHandler: PropTypes.func,
    closeCallBack: PropTypes.func,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    tooltip: PropTypes.string,
    type: PropTypes.number.isRequired,
    notCenterPadding: PropTypes.bool,
    shouldOpenByDefault: PropTypes.bool,
};

module.exports = withContactCenterValidation(TutorialLauncher);
