require('./brokerInvestmentSpecialist.scss');
require('slick-carousel/slick/slick.scss');
require('slick-carousel/slick/slick-theme.scss');

const PropTypes = require('prop-types');

// @vendors
const React = require('react');
const { connect } = require('react-redux');
const Slider = require('react-slick').default;

// @actions
const specialistsActions = require('actions/investmentSpecialists');

// @components
const { ContentBox } = require('commonsComponents/contentBox');
const { LoadingSection } = require('commonsComponents/loadingSection');
//const { InputSafe } = require('commonsComponents/inputSafe');
//const OKSelect = require('components/commons/OKSelect/OKSelect');
//const { OKButton } = require('components/commons/OKButton/OKButton');
const OKModal = require('components/commons/OKModal/OKModal');
const BrokerInvestmentSpecialistModal = require('components/broker/brokerInvestmentSpecialistModal/brokerInvestmentSpecialistModal');

// @helpers
const { formatText, FormattedText } = require('core/i18n').i18n;
const { sendTextToClipBoard } = require('commonsUtilities/clipBoardHelper');
const WebappRender = require('components/conditionalRender/webappRender');

//@commons
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');

// @constants
const {
    CALL_TIME_OPTIONS_STATUS_OK,
} = require('constants/index');

const {
    CALLMEBACK_GENESYS,
    CALLMEBACK
} = require('constants/flagBasedFeatures');

class BrokerInvestmentSpecialist extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showErrorMessagesModal: false,
            showContactForm: false,
            telephone: '',
            telephoneCode: ''
        };

        this.handleContactModalToggle = this.handleContactModalToggle.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleRequestCall = this.handleRequestCall.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
        this.buildInputForm = this.buildInputForm.bind(this);
        this.buildFormMessage = this.buildFormMessage.bind(this);
        this.buildContractsView = this.buildContractsView.bind(this);
        this.buildGeneralView = this.buildGeneralView.bind(this);
        this.buildErrorScreen = this.buildErrorScreen.bind(this);
    }

    componentDidMount() {
        this.props.onReset();
        this.props.getSpecialists();
    }

    componentWillUnmount() {
        this.props.onReset();
    }

    buildContactInfo = () => {
        // Email custom tooltip data.
        const { immVariables } = this.props
        const email = (
            <FormattedText
                value="brokerInvestmentSpecialist-brokerInvestmentSpecialistEmail"
            />
        )

        const emailCopy = immVariables ? immVariables.getIn(['email', 'inversiones']) : ''

        const target = (
            <p className="broker-investment-specialist__contact-options__e-mail">
                {email}
            </p>
        );
        const content = (
            <span>
                {this.state.copied || emailCopy}
                <button
                    className="icon__tooltip icon-copiar"
                    onClick={() => sendTextToClipBoard(emailCopy)}
                />
            </span>
        );

        return (
            <div className="broker-investment-specialist__contact-info">
                <div className="broker-investment-specialist__contact-options">
                    <FormattedText
                        className="broker-investment-specialist__contact-options__phone-number"
                        value="brokerInvestmentSpecialist-brokerInvestmentSpecialistPhone"
                    />
                    <OKTooltip
                        target={target}
                        content={content}
                        offset={[0,-5]}
                    />
                </div>
            </div>
        );
    }

    buildContractsView(investmentSpecialist, profilePics) {
        const {
            immInvestmentSpecialists,
            immProfile,
            infoTestBlock,
            onTelCodeChange,
            onTelChange,
            onCallTimeChange,
            onCommentChange,
            onRequestBrokerCallTimeOptions,
            onSetProfilePhone
        } = this.props;
        const footerMessage = this.buildFormMessage();

        const contentModal = (
            <BrokerInvestmentSpecialistModal
                immInvestmentSpecialists={immInvestmentSpecialists}
                immProfile={immProfile}
                onTelCodeChange={onTelCodeChange}
                onTelChange={onTelChange}
                onCallTimeChange={onCallTimeChange}
                onCommentChange={onCommentChange}
                onCloseClick={this.handleContactModalToggle}
                onRequestCall={this.handleRequestCall}
                onRequestBrokerCallTimeOptions={onRequestBrokerCallTimeOptions}
                onSetProfilePhone={onSetProfilePhone}
                showErrorMessages={this.state.showErrorMessagesModal}
            />
        );

        let infoBlock;
        let callTimeZoneBlock;

        if (!!infoTestBlock) {
            infoBlock = (
                <div className="broker-investment-specialist__extra-info">
                    {infoTestBlock}
                </div>
            );
        } else {
            infoBlock = this.buildExtraInfo('brokerInvestmentSpecialist-brokerInvestmentSpecialistContactOurSpecialistMessage');
            callTimeZoneBlock = (
                <FormattedText
                    className="broker-investment-specialist__call-time-zone"
                    value="callmeback-callTimeZone"
                />
            );
        }

        return (
            <ContentBox
                id="brokerInvestmentSpecialist"
                title={investmentSpecialist}
                smallTitle={true}
                customClassName="broker-investment-specialist"
                modifier="complementary"
                product="wealth"
                footerContent={footerMessage}
            >
                <OKModal
                    content={contentModal}
                    footerType="navigation"
                    isOpen={immInvestmentSpecialists.get('showModal')}
                    onCloseCallback={this.handleContactModalToggle}
                    onPrimaryButtonClick={this.handleRequestCall}
                    onSecondaryButtonClick={this.handleContactModalToggle}
                    primaryButtonText="brokerInvestmentSpecialistModal-request"
                    primaryButtonLoading={immInvestmentSpecialists.get('requestingCall')}
                    secondaryButtonText="brokerInvestmentSpecialistModal-cancel"
                />
                <div>
                    {infoBlock}
                    {callTimeZoneBlock}
                    <div className="broker-investment-specialist__contact-info">
                        <div className="broker-investment-specialist__img-wrapper">
                            <div className="broker-investment-specialist__pic-slider">
                                <div>
                                    {profilePics}
                                </div>
                            </div>
                        </div>
                        {this.buildContactInfo()}
                    </div>
                    {this.buildInputForm()}
                </div>
            </ContentBox>
        );
    }

    buildErrorScreen() {
        return (
            <ContentBox
                id="brokerInvestmentSpecialistSuccess"
                smallTitle={true}
                customClassName="broker-investment-specialist"
            >
                <p className="broker-investment-specialist__icon-failure">
                    <i className="icon-exito" />
                </p>
                <p className="broker-investment-specialist__icon-failure-label">
                    {formatText(
                        'brokerInvestmentSpecialist-brokerInvestmentSpecialistFailureLabel'
                    )}
                </p>
                <p className="broker-investment-specialist__failure-message">
                    {formatText(
                        'brokerInvestmentSpecialist-brokerInvestmentSpecialistFailureMessage'
                    )}
                </p>
            </ContentBox>
        );
    }

    buildExtraInfo(messageKey) {
        return (
            <div className="broker-investment-specialist__extra-info">
                {formatText(messageKey)}
            </div>
        );
    }

    buildFormMessage() {
        const { immFlagBasedFeatures } = this.props;
        const callMeBackEntrance = formatText('callmeback-entrance');
        const callMeBackHere = formatText('callmeback-here');
        let callMeBackContent = null;

        if (immFlagBasedFeatures.get(CALLMEBACK)) {
            callMeBackContent = (
                <div className="broker-investment-specialist__entrance">
                    <span className="broker-investment-specialist__entrance-text">
                        {callMeBackEntrance}{' '}
                    </span>
                    <span
                        className="broker-investment-specialist__entrance-here"
                        onClick={this.handleOnClick}
                    >
                        {callMeBackHere}
                    </span>
                </div>
            );
        }

        return callMeBackContent;
    }

    buildGeneralView(investmentSpecialist, profilePics) {
        return (
            <ContentBox
                id="brokerInvestmentSpecialist"
                title={investmentSpecialist}
                smallTitle={true}
                customClassName="broker-investment-specialist"
            >
                <div className="broker-investment-specialist__contact-info">
                    <div className="broker-investment-specialist__img-wrapper">
                        <div className="broker-investment-specialist__pic-slider">
                            <div className="broker-investment-specialist__sliding-wrapper">
                                {profilePics}
                            </div>
                        </div>
                    </div>
                    {this.buildContactInfo()}
                </div>
                {this.buildExtraInfo(
                    'brokerInvestmentSpecialist-brokerInvestmentSpecialistContactOurSpecialistMessage'
                )}
            </ContentBox>
        );
    }

    buildInputForm() {
        /*const telephoneCodes = [
            {
                value: 34,
                label: '+34'
            },
            {
                value: 35,
                label: '+35'
            }
        ];//@hardcoded
        let contactForm;
        if(this.state.showContactForm) {
            contactForm = (
                <div>
                    <div className="broker-investment-specialist__contact-info">
                        <label>{formatText('brokerInvestmentSpecialist-brokerInvestmentSpecialistContactTelephone')}</label>
                        <div >
                            <div className="broker-investment-specialist__telephone-code">
                                <OKSelect onChange={this.handleOnChange} value={this.state.telephoneCode} options={telephoneCodes}/>
                            </div>
                            <div className="broker-investment-specialist__telephone-input">
                                <InputSafe type="text" onChange={this.handleOnChangeInput} value={this.state.telephone}/>
                            </div>
                        </div>
                    </div>
                    <div className="broker-investment-specialist__contact-info">
                        <div className="broker-investment-specialist__will-call-buttons">
                            <div className="broker-investment-specialist__cancel-button"><OKButton modifier="link-complementary" onClick={this.handleOnClick}>{formatText('brokerInvestmentSpecialist-brokerInvestmentSpecialistCancelButton')}</OKButton></div>
                            <OKButton onClick={this.handleRequestCall}>{formatText('brokerInvestmentSpecialist-brokerInvestmentSpecialistRequestCallButton')}</OKButton>
                        </div>
                    </div>
                </div>)
        } else {
            contactForm = '';
        }
        return contactForm;*/
    }

    buildProfilePics() {
        const { immInvestmentSpecialists } = this.props;
        const specialistPics = immInvestmentSpecialists.getIn(['office', 'specialists']);
        let dataToRender = null;

        if (specialistPics && !specialistPics.isEmpty()) {
            dataToRender = specialistPics.map((specialistPic, index) => {
                return (
                    <img
                        key={index}
                        className="broker-investment-specialist__img"
                        alt="investment specialist"
                        src={specialistPic.get('imageUrl')}
                    />
                );
            });
        }

        const settings = {
            arrows: false,
            infinite: true,
            autoplay: true,
            draggable: false,
            dots: false
        };

        const response = (
            <Slider {...settings}>
                {dataToRender}
            </Slider>
        )
        return response;
    }

    buildSuccessScreen() {
        return (
            <ContentBox
                id="brokerInvestmentSpecialistSuccess"
                smallTitle={true}
                customClassName="broker-investment-specialist"
            >
                <p className="callmeback__icon-success">
                    <i className="wizard-success-step__success-icon" />
                </p>
                <p className="broker-investment-specialist__icon-success-label">
                    {formatText(
                        'brokerInvestmentSpecialist-brokerInvestmentSpecialistSuccessLabel'
                    )}
                </p>
                <p className="broker-investment-specialist__success-message">
                    {formatText(
                        'brokerInvestmentSpecialist-brokerInvestmentSpecialistSuccessMessage'
                    )}
                </p>
            </ContentBox>
        );
    }

    handleContactModalToggle() {
        this.setState({
            showContactForm: !this.state.showContactForm
        });
        this.props.onToggleModal();
    }

    handleOnChange(event) {
        this.setState({ telephoneCode: event.value });
    }

    handleOnChangeInput(event) {
        this.setState({ telephone: event.target.value });
    }

    handleOnClick() {
        this.setState({ showContactForm: !this.state.showContactForm });
        this.props.onToggleModal();
    }

    handleRequestCall() {
        const { immInvestmentSpecialists, immFlagBasedFeatures } = this.props;
        const isCallMeBackGenesysEnabled = immFlagBasedFeatures.get(CALLMEBACK_GENESYS);
        this.props.onRequestCall(isCallMeBackGenesysEnabled);

        if (immInvestmentSpecialists.get('valid')) {
            this.handleContactModalToggle();
        } else {
            this.setState({
                showErrorMessagesModal: true
            });
        }
    }

    render() {
        const { title, titleKey, immInvestmentSpecialists /*, context*/ } = this.props;
        const investmentSpecialist = !!title ? title : formatText(titleKey);
        const profilePics = this.buildProfilePics();
        let content = null;

        if (immInvestmentSpecialists.get('isFetching')) {
            content = (
                <ContentBox customClassName="broker-investment-specialist">
                    <LoadingSection />
                </ContentBox>
            );
        } else {
            /*let design;
            switch(context) {
                case 'contracts':
                    design = this.state.showSuccessScreen ? this.buildSuccessScreen() : this.buildContractsView(investmentSpecialist, profilePics, email);
                    break;
                case 'general':
                    design = this.buildGeneralView(investmentSpecialist, profilePics, email);
                    break;
            }
            return design;*/
            const requestSent = immInvestmentSpecialists.get('requestCallSent');
            const requestingCall = immInvestmentSpecialists.get('requestingCall');
            const successRequestCall = immInvestmentSpecialists.get('successRequestCall');
            const status = immInvestmentSpecialists.get('status') === CALL_TIME_OPTIONS_STATUS_OK;

            content = this.buildContractsView(investmentSpecialist, profilePics);

            if (!requestingCall) {
                if (status && requestSent && successRequestCall) {
                    content = this.buildSuccessScreen();
                } else if (requestSent && !successRequestCall) {
                    content = this.buildErrorScreen();
                }
            }
        }

        return (
            <WebappRender>
                {content}
            </WebappRender>
        )
    }
}

BrokerInvestmentSpecialist.propTypes = {
    immFlagBasedFeatures: PropTypes.object,
    immInvestmentSpecialists: PropTypes.object,
    immProfile: PropTypes.object,
    immVariables: PropTypes.object.isRequired,
    infoTestBlock: PropTypes.element,
    onToggleModal: PropTypes.func.isRequired,
    onTelCodeChange: PropTypes.func.isRequired,
    onTelChange: PropTypes.func.isRequired,
    onCallTimeChange: PropTypes.func.isRequired,
    onCommentChange: PropTypes.func.isRequired,
    onRequestCall: PropTypes.func.isRequired,
    onRequestBrokerCallTimeOptions: PropTypes.func.isRequired,
    getSpecialists: PropTypes.func,
    title: PropTypes.string,
    titleKey: PropTypes.string,
    context: PropTypes.string,
    requestCallFn: PropTypes.func,
    onSetProfilePhone: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
};

BrokerInvestmentSpecialist.defaultProps = {
    titleKey: 'broker-investmentSpecialist',
    context: 'general'
};

module.exports.BrokerInvestmentSpecialist = connect(
    state => ({
        immFlagBasedFeatures: state.flagBasedFeatures,
        immInvestmentSpecialists: state.brokerInvestmentSpecialists,
        immProfile: state.profile,
        immVariables: state.globalVariables.getIn(['variables', 'parameters']),
    }),
    {
        getSpecialists: specialistsActions.getSpecialists,
        onCallTimeChange: specialistsActions.changeCallTime,
        onCommentChange: specialistsActions.changeComment,
        onRequestBrokerCallTimeOptions: specialistsActions.requestBrokerCallTimeOptions,
        onRequestCall: specialistsActions.requestCall,
        onTelChange: specialistsActions.changeTel,
        onTelCodeChange: specialistsActions.changeTelCode,
        onToggleModal: specialistsActions.toggleModal,
        onSetProfilePhone: specialistsActions.setProfilePhone,
        onReset: specialistsActions.reset
    }
)(BrokerInvestmentSpecialist);
