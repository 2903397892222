require('./signaturePasscode.scss');

// @ vendor
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const PureRenderMixin = require('react-addons-pure-render-mixin');

// @ utilities
const {FormattedText} = require('core/i18n').i18n;
const { contactCenterClassName } = require('utilities/conditionals/conditionalClassname');

// @ components
const SignaturePasscodeInput = require('./signaturePasscodeInput');

// @ constants
const { SIGNATURE_KEY_ALL_DIGITS } = require('constants/index');


class SignaturePasscode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            followingPosition: 0,
            firstInputFocus: props.firstInputFocus
        };

        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
    }

    getFollowingInputPosition(valueEnteredByTheUser, positionValueEnteredByTheUser) {
        return valueEnteredByTheUser ? positionValueEnteredByTheUser + 1 : positionValueEnteredByTheUser - 1;
    }

    handleInputChange(value, position) {
        this.props.onKeyDown(value, position);
        this.setState({
            followingPosition: this.getFollowingInputPosition(value, position),
            firstInputFocus: true
        });
    }

    handleInputKeyDown(value, position) {
        this.props.onKeyDown(value, position);
        this.setState({
            followingPosition: this.getFollowingInputPosition(value, position)
        });
    }

    handleOnEnter(position) {
        const { onEnter } = this.props;
        onEnter && onEnter(position);
    }

    render() {
        const {
            immRequiredDigits,
            immDigitsValues,
            disabled,
            keySize,
            numbersOnly,
            className,
            error,
            clearOnError,
            initialValue,
            displayErrorStyles
        } = this.props;
        const { followingPosition, firstInputFocus } = this.state;
        const signaturePasscodeClass = contactCenterClassName(className, 'signature-passcode pull-left');

        let signaturePasscodeContainerClass;
        let signaturePasscodeAttempts;

        let inputs = [];
        let numbers= [];

        if (immRequiredDigits) {
            let requiredIndex = 0;
            let position = immRequiredDigits.get(requiredIndex);

            for (let index = 0; index < keySize; index++) {
                const numberId = 'lbl_SignaturePasscode_InputPosition_' + index;

                if (index === position) {

                    const hasFocus =
                        firstInputFocus &&
                        requiredIndex === followingPosition;

                    inputs.push(
                        <SignaturePasscodeInput
                            key={index}
                            initialValue={initialValue? initialValue.get(index) : null}
                            index={requiredIndex}
                            disabled={disabled}
                            focus={hasFocus}
                            value={immDigitsValues.get(index)}
                            onChange={this.handleInputChange}
                            onKeyDown={this.handleInputKeyDown}
                            onEnter={this.handleOnEnter}
                            isNumeric={numbersOnly}
                            error={error}
                            clearOnError={clearOnError}
                            displayErrorStyles={displayErrorStyles}
                        />
                    );
                    position = immRequiredDigits.get(++requiredIndex); //This must be done after pushing in inputs
                } else {
                    const id = 'lbl_SignaturePasscode_Hidden_' + index;
                    inputs.push(
                        <div key={index} className="signature-passcode__box signature-passcode__box--empty">
                            <span id={id}>*</span>
                        </div>
                    );
                }

                numbers.push(
                    <div key={index} className="signature-passcode__box">
                        <span id={numberId}>{index + 1}</span>
                    </div>
                );
            }
        }

        signaturePasscodeContainerClass = classNames({
            'signature-passcode__container': true,
            'signature-passcode__container--error': this.props.error
        });

        // The attempts limit has to be on a config file.
        if (this.props.attempt) {
            signaturePasscodeAttempts = (
                <div className="signature-passcode__attempts">
                    <FormattedText id="lbl_SignaturePasscode_Attempt" value={'signaturePasscode-attempt'}/>
                    <span id="txt_SignaturePasscode_Attempt">{this.props.attempt}/3</span>
                </div>
            );
        }

        return (
            <div className={signaturePasscodeClass}>
                <div className={signaturePasscodeContainerClass}>
                    <div className="signature-passcode__container--left">
                        <div className="signature-passcode__boxes">
                            {inputs}
                        </div>
                        <div>{numbers}</div>
                    </div>
                </div>
                {signaturePasscodeAttempts}
            </div>
        );
    }
}

SignaturePasscode.propTypes = {
    attempt: PropTypes.string,
    immRequiredDigits: PropTypes.object.isRequired,
    immDigitsValues: PropTypes.object.isRequired,
    title: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    error: PropTypes.bool,
    firstInputFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    keySize: PropTypes.number,
    numbersOnly: PropTypes.bool,
    className: PropTypes.string,
    onEnter: PropTypes.func,
    clearOnError: PropTypes.bool,
    initialValue: PropTypes.array,
    displayErrorStyles: PropTypes.bool
};

SignaturePasscode.defaultProps = {
    attempt: "",
    title: "",
    onChange: undefined,
    onKeyDown: undefined,
    error: false,
    firstInputFocus: true,
    disabled: false,
    keySize: SIGNATURE_KEY_ALL_DIGITS,
    numbersOnly: false,
    clearOnError: false,
    displayErrorStyles: false
};

module.exports = SignaturePasscode;