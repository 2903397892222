const PropTypes = require('prop-types');
// @ vendors
const React = require('react');
const { connect } = require('react-redux');
const throttle = require('lodash/function/throttle');

// @ commons
const { OKButton } = require('components/commons/OKButton/OKButton');

class SignaturePatternButton extends React.Component {
    constructor(props) {
        super(props);
        // Call click event only once during the period of one second. This prevents to accidentally trigger multiple calls of the same operation.
        this.handleClick = throttle(this.handleClick.bind(this), 1000, { trailing: false });
    }

    handleClick() {
        this.props.onClick();
    }

    render() {
        const { children, loading, modifier, rightIcon, immSignaturePattern, disabled } = this.props;
        const signaturePatternLoading = (
            immSignaturePattern.get('isFetchingConfig') ||
            immSignaturePattern.get('isFetchingSMSCode') ||
            immSignaturePattern.get('isFetchingSignaturePassCode') ||
            immSignaturePattern.get('callbackCalled')
        );
        const signaturePassCodeSuccess = immSignaturePattern.get('isFetchingSignaturePassCodeSuccess');
        const withValidateButton = immSignaturePattern.get('withValidateButton');
        const _disabled = disabled || (withValidateButton && !signaturePassCodeSuccess);
        let _loading;
        if (!withValidateButton) {
            _loading = loading || signaturePatternLoading
        } else {
            if (signaturePassCodeSuccess) {
                _loading = loading || signaturePatternLoading
            } else {
                _loading = false
            }
        }

        return (
            <OKButton
                disabled={_disabled}
                onClick={this.handleClick}
                rightIcon={rightIcon}
                modifier={modifier}
                loading={_loading}
            >
                {children}
            </OKButton>
        );
    }
}

SignaturePatternButton.propTypes = {
    immSignaturePattern: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    modifier: PropTypes.string,
    loading: PropTypes.bool,
    rightIcon: PropTypes.string,
    children: PropTypes.any,
    disabled: PropTypes.bool
}

module.exports = connect(state => ({
    immSignaturePattern: state.signaturePattern
}))(SignaturePatternButton);
