// @vendors
const React = require('react');
const classNames = require('classnames');
const PropTypes = require('prop-types');

// @helpers
const { formatText, FormattedText, getLanguage } = require('core/i18n').i18n;
const { formatAmountObject } = require('utilities/currencyHelper');
//@constants
const { COLON, BROKER_FUND_NEW_CONTRACT_OPTION } = require('constants/index');

// @components
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');

class StocksContractDropdownFundSubscriptionOption extends React.Component {
    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    buildOption() {
        const { option, className, grouped } = this.props;
        let optionElement;
        if (grouped && option.disabled) {
            optionElement = (
                <div className="stocks-contract-dropdown__option--subtitle">
                    <FormattedText value={option.label} />
                </div>
            );
        } else {
            if(option.value === BROKER_FUND_NEW_CONTRACT_OPTION){
                const optionClassName = classNames('stocks-contract-dropdown__option', className, {
                    'stocks-contract-dropdown__option--grouped': grouped
                });
                optionElement = (
                    <div
                        className={optionClassName}
                        onMouseDown={this.handleMouseDown}
                        onMouseMove={this.handleMouseMove}
                    >
                        <span className="stocks-contract-dropdown__option--highlighted-principal">
                            {option.totalValue}
                        </span>
                    </div>
                );
            } else {
                const { contractNumber, totalValue, totalValueCurrency } = option;
                const optionClassName = classNames('stocks-contract-dropdown__option', className, {
                    'stocks-contract-dropdown__option--grouped': grouped
                });
                const contractNumberLabel = formatText('brokerHireInvestmentStep1-fundContractDropdown_contractNumberOptionLabel');
                const balanceLabel = `${formatText(
                    'broker-stocksContractDropdownContractBalance'
                )}${COLON} `;
                const plainAmountObject = {
                    amount: totalValue,
                    currency: totalValueCurrency
                };
                optionElement = (
                    <div
                        className={optionClassName}
                        onMouseDown={this.handleMouseDown}
                        onMouseMove={this.handleMouseMove}
                    >
                        <span className="stocks-contract-dropdown__value--light">
                            {contractNumberLabel}
                        </span>
                        <span className="stocks-contract-dropdown__option--highlighted">
                            {contractNumber}
                        </span>

                        <span className="pull-right">
                            <span>{balanceLabel}</span>
                            <span className="stocks-contract-dropdown__option--highlighted">
                                <OneLineSymbolAmount
                                    amountObject={plainAmountObject}
                                    className="stocks-contract-dropdown__option-balance"
                                    language={getLanguage()}
                                    amountObjectFormatted={formatAmountObject(plainAmountObject, true)}
                                />
                            </span>
                        </span>
                    </div>
                );
            }
        }

        return optionElement;
    }

    handleMouseDown(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    }

    handleMouseMove(event) {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    }

    render() {
        let result;
        result = this.buildOption();

        return result;
    }
}

StocksContractDropdownFundSubscriptionOption.propTypes = {
    className: PropTypes.string,
    option: PropTypes.object.isRequired,
    isFocused: PropTypes.bool,
    grouped: PropTypes.bool,
    onSelect: PropTypes.func,
    onFocus: PropTypes.func
};

module.exports = StocksContractDropdownFundSubscriptionOption;