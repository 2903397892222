module.exports = {
    DNI_REGEX: /^(\d{8})([A-Z])$/,
    CIF_REGEX: /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/,
    ALPHANUMERIC_REGEX: /^(\d+[a-z]|[a-z]+\d)([a-z]|\d)*$/i,
    URL_WITH_PROTOCOL_REGEX: /^[a-zA-Z]{4,5}\:\/{2}[^\/]+((?:\/(?!\/)[^\/]*)+)/,
    URL_WITHOUT_PROTOCOL_REGEX: /^[^\/]*((?:\/(?!\/)[^\/]*)+)/,
    SMS_CODE_PORTABILITY_REGEX: /^\d{4,6}$/,
    CONTACT_CENTER_EXCLUDED_ROUTES: [
        /^\/myprofile\/keys$/,
        /^\/myprofile\/keys\/signature$/,
        /^\/myprofile\/keys\/password$/,
        /^\/myprofile\/cards\/[^\/]+\/request\/cvv$/,
        /^\/myprofile\/cards\/[^\/]+\/request\/pin$/,
        /^\/myprofile\/cards\/[^\/]+\/mobile-recharge$/,
        /^\/myprofile\/cards\/[^\/]+\/download-pdf$/,
        /^\/myprofile\/cards\/[^\/]+\/get-virtual-debit-pdf$/,
        /^\/myprofile\/hire\/card\/e-card$/,
        /^\/myprofile\/loans-mortgages\/loans\/[^\/]+\/unpaid-fees$/,
        /^\/myprofile\/global\/entities$/,
        /^\/myprofile\/global\/entities\/add$/,
        /^\/myprofile\/global\/entities\/credentials\/[^\/]+$/,
        /^\/myprofile\/global\/entities\/accounts\/[^\/]+$/,
        /^\/myprofile\/global\/entities\/cards\/[^\/]+$/,
        /^\/myprofile\/global\/entities\/loans-mortgages\/[^\/]+$/,
        /^\/myprofile\/global\/entities\/manage\/[^\/]+$/,
        /^\/myprofile\/global\/entities\/accounts$/,
        /^\/myprofile\/global\/entities\/cards$/,
        /^\/myprofile\/global\/entities\/loans-mortgages$/,
        /^\/myprofile\/global\/entities\/deposits$/,
        /^\/myprofile\/global\/entities\/investments$/
    ],
    CONTACT_CENTER_ONLY_ROUTES: [
        /^\/myprofile\/purchase\/deposit-thirteen-months-due-dates$/,
        /^\/myprofile\/purchase\/deposit-thirteen-months-leak-retention$/,
        /^\/myprofile\/purchase\/deposit-thirteen-months-linked-maturities$/
    ]
}
