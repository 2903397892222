// @ vendors
const React = require('react');
const { OKButton } = require('components/commons/OKButton/OKButton');

const PropTypes = require('prop-types');

// @ helpers
const { formatText } = require('core/i18n').i18n;
const { toFirstUppercase } = require('utilities/stringHelper');

const CustomPrevArrow = props => {
    props = Object.assign({}, props);
    /*
        The following check was derived by run-time analysis of the code and its effects
    */
    if (props.className.includes("slick-disabled")) {
        return null;

    } else {
        props.className = "tutorial-overlay__prev-button"; // must override the provided values
        return (
            <div {...props}>
                <OKButton modifier="ghost" inverse={true} leftIcon="icon-anterior" id={"prevTutorialButtonId"}>
                    {toFirstUppercase(formatText('buttonsActions-previous'))}
                </OKButton>
            </div>
        );
    }
};

CustomPrevArrow.propTypes = {
    className: PropTypes.array,
};

module.exports = CustomPrevArrow;
