// vendors
import { combineReducers } from "redux";
// adapter
import { ReducersDuplicated } from "../adapter/dynamicLoad/dynamicLoad.error";
// utils
import { findArrayIntersection } from "../utilities/arrayHelper";

let store = null;
let reducers = null;
let webAppRerducersHistory = [];

const initStoreHelper = (storeParam, reducersParam) => {
    if (!store) {
        store = storeParam;
        reducers = reducersParam;
        webAppRerducersHistory = Object.keys(reducersParam);
    }
};

const checkReducersDuplicated = (
    webAppRerducersHistory,
    microfrontReducers
) => {
    
    const duplicatedReducers = findArrayIntersection(
        webAppRerducersHistory,
        microfrontReducers
    );

    
    if (duplicatedReducers.length) {
        throw new ReducersDuplicated(duplicatedReducers);
    }
};

const combineChildReducers = (parentReducers, childConfigs, commonReducers) => {
    const childReducers = Object.keys(childConfigs).map(
        (k) => childConfigs[k].reducers
    );

    const combinedReducerObjects = [
        ...childReducers,
        parentReducers,
        commonReducers,
    ];
    return combinedReducerObjects.reduce((acc, curr) => ({ ...acc, ...curr }));
};

const replaceReducers = (key, noStaticReducers, commonReducers = {}) => {
    const wrapper = {};
    wrapper[key] = { reducers: noStaticReducers };
    checkReducersDuplicated(webAppRerducersHistory, [
        ...Object.keys(noStaticReducers),
        ...Object.keys(commonReducers),
    ]);

    const combinedReducers = combineChildReducers(
        reducers,
        wrapper,
        commonReducers
    );
    reducers = combinedReducers;
    store.replaceReducer(combineReducers(combinedReducers));
};

const applyInitialization = (key, initialization) => {
    initialization({
        dispatch: store.dispatch,
        getState: store.getState,
    });
};

const getStore = () => store;

const getDispatch = () => store.dispatch;

export { 
    getStore, 
    getDispatch, 
    initStoreHelper, 
    replaceReducers, 
    applyInitialization 
};