const { formatText } = require('core/i18n').i18n;

const purposeTranslationsSubkey = {
    PURPOSE_BUY_VEHICLE: 'purposeBuyVehicle',
    PURPOSE_FIX_HOUSE: 'purposeFixHouse',
    PURPOSE_HOME_ELEMENTS: 'purposeHomeElements',
    PURPOSE_STUDIES: 'purposeStudies',
    PURPOSE_HEALTH: 'purposeHealth',
    PURPOSE_TRAVEL: 'purposeTravel',
    PURPOSE_OTHER: 'purposeOther'
};

const getPurposeTranslation = function(constant) {
    const translationSubkey = purposeTranslationsSubkey[constant];
    const wholeTransKey = 'contractEasyLoanStepOne-' + translationSubkey;

    return formatText(wholeTransKey);
}

module.exports = {
    getPurposeTranslation
};
