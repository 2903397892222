
const APPRAISER_VISIT_OPTIONS = {
    ATTEND: 'ATTEND',
    NOT_ATTEND: 'NOT_ATTEND',
    SELECT_DATE: 'SELECT_DATE',
    NOT_SELECT_DATE: 'NOT_SELECT_DATE'
};

const SCHEDULE_OPTIONS = {
    INDIFFERENT: 'INDIFFERENT',
    MORNING: 'MORNING',
    LATE: 'LATE'
};

const ACCOUNTS_CODES = {
    CURRENT_ACCOUNT_CODE: '300',
}
module.exports = {
    APPRAISER_VISIT_OPTIONS,
    SCHEDULE_OPTIONS,
    ACCOUNTS_CODES
};
