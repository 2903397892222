import { PureComponent } from 'react';
import withFeatureToggling from 'hocs/withFeatureToggling/withFeatureToggling';
import PropTypes from 'prop-types';

const PRODUCT_KEY_FILTER_SOME = 'some';
const PRODUCT_KEY_FILTER_EVERY = 'every';

class FeatureTogglingRender extends PureComponent {

    render() {
        const { children, flagBasedFeatures, notAllowedProductKey, productKeyFilter } = this.props;
        const keyValue = [
            PRODUCT_KEY_FILTER_SOME,
            PRODUCT_KEY_FILTER_EVERY, 
        ];

        if (typeof notAllowedProductKey === 'string' || notAllowedProductKey instanceof String) {
            return !!flagBasedFeatures.get(notAllowedProductKey) && !!children && children;
        } else if (Array.isArray(notAllowedProductKey)) {
            if (keyValue.some(key => productKeyFilter === key)) {
                return notAllowedProductKey[productKeyFilter](flag => !!flagBasedFeatures.get(flag)) && !!children && children;
            } else {
                throw new Error("If 'productKeyFilter' is an array, you should specify 'every' or 'some' to set the type of filter.");
            }
        }
        return null;
    }
}

FeatureTogglingRender.propTypes = {
    children: PropTypes.node,
    flagBasedFeatures: PropTypes.object.isRequired,
    notAllowedProductKey: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.string,
            ]).isRequired,
    productKeyFilter: PropTypes.string
};

export default withFeatureToggling(FeatureTogglingRender);
