import { Component } from 'react';
import PropTypes from 'prop-types';

class ContactCenterRender extends Component {
    shouldComponentUpdate() {
        return __CONTACT_CENTER__;
    }

    render() {
        const { children } = this.props;
        return __CONTACT_CENTER__ && children ? children : null;
    }
}

ContactCenterRender.propTypes = {
    children: PropTypes.node
};

export default ContactCenterRender;
