require('./OKInformativeList.scss');

const PropTypes = require('prop-types');

// @ vendors
const React = require('react');
const {
    Children
} = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
//const classNames = require('classnames');
// @ helpers
const { formatText, hasTranslation } = require('core/i18n').i18n;
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');
// @ constants
const MAX_COLUMNS = 12;

class OKInformativeList extends React.Component {

    constructor(props) {
        super(props);

        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    renderIconContent (information, tooltipPlacement, tooltipOffset) {
        const { icon } = this.props;
        let iconContent;
        if (!!icon) {
            iconContent = (
                <OKTooltip
                    alternativeIcon={true}
                    content={information}
                    offset={tooltipOffset}
                    placement={tooltipPlacement}
                />
            );
        }

        return iconContent;
    }

    renderTitleContainer(title, titleClasses, showIcon, key) {
        let iconContent;

        if (showIcon) { // prevents repetition of the icon on the titles.
            const {
                information,
                tooltipPlacement,
                tooltipOffset,
            } = this.props;

            iconContent = this.renderIconContent(
                information,
                tooltipPlacement,
                tooltipOffset,
            );
        }

        return (
            <h3 key={key} className={titleClasses}>
                {title}
                {iconContent}
            </h3>
        );
    }

    render () {
        const {title, titleButton, children, columns, icon} = this.props;
        const bootstrapColumn = MAX_COLUMNS/columns;
        let columnElements = [];
        let titleContent;
        let finalTitle;

        if (!!title){
            if (hasTranslation(title)){
                finalTitle = formatText(title);
            } else {
                finalTitle = title;
            }
            if (!icon){
                titleContent = (<h3 className="ok-informative-list__title">{finalTitle} {titleButton ? titleButton : null}</h3>);
            } else {
                titleContent = this.renderTitleContainer(finalTitle, "ok-informative-list__title", true);
            }
        }

        Children.toArray(children)
        .filter((child) => (!!child)) //Remove null or undefined elements
        .forEach((child, index) => {
            if (child){
                const offset = index % columns;

                if (!columnElements[offset]) {
                    columnElements[offset] = [];
                }

                columnElements[offset].push(child);
            }

        });

        columnElements = columnElements.map((column, index) => {
            const className = `col-xs-${bootstrapColumn}`;
            const key = `col-${index}`;

            return (
                <div className={className} key={key}>
                    <ul className="ok-informative-list__unstyled-list">
                        {column}
                    </ul>
                </div>
            )
        });

        return (
            <div className="ok-informative-list">
                {titleContent}
                <div className="ok-informative-list__list list-unstyled row">
                    {columnElements}
                </div>
            </div>
        );
    }
}

OKInformativeList.propTypes = {
    title: PropTypes.string,
    titleButton: PropTypes.element,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    columns: PropTypes.oneOf([1, 2, 3, 4]),
    icon: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string // For retrocompatibility string is kept, but booleans should be used from now on
    ]),
    tooltipPlacement: PropTypes.string,
    information: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    tooltipOffset: PropTypes.array,
};

OKInformativeList.defaultProps = {
    title: '',
    columns: 1
};

module.exports = OKInformativeList;