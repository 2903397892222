require('./brokerInvestmentSpecialistModal.scss');
require('./brokerInvestmentSpecialistModal.override.scss');

const PropTypes = require('prop-types');

// @vendors
const React = require('react');

// @components
const OKSelect = require('components/commons/OKSelect/OKSelect');
const { OKTextArea } = require('commonsComponents/OKTextArea');
const PhoneInput = require('components/commons/phoneInput/phoneInput');

// @helpers
const { formatText, FormattedText } = require('core/i18n').i18n;
const PersonalDataHelper = require('utilities/personalDataHelper');
const { toCamelCase } = require('utilities/stringHelper');

// @constants
const {
    BROKER_CALLMEBACK_MAX_COMMENT_CHARS,
    CALL_TIME_OPTIONS_RIGHT_AWAY_ES,
    CALL_TIME_OPTIONS_STATUS_OK
} = require('constants/index');
const RIGHT_AWAY = toCamelCase(CALL_TIME_OPTIONS_RIGHT_AWAY_ES);
const { CALL_ME_BACK_BEHAVIOUR } = require('constants/phoneInput');

class BrokerInvestmentSpecialistModal extends React.Component {
    constructor(props) {
        super(props);

        this.onTelCodeChange = this.onTelCodeChange.bind(this);
        this.onTelChange = this.onTelChange.bind(this);
        this.handleCallTimeChange = this.handleCallTimeChange.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
    }

    componentDidMount() {
        const { immProfile, onTelChange, onRequestBrokerCallTimeOptions, onSetProfilePhone, onTelCodeChange } = this.props;
        const profilePhone = PersonalDataHelper.getPhoneOnly(immProfile);
        const profilePhoneCode = PersonalDataHelper.getPhoneCodeOnly(immProfile);

        onSetProfilePhone(profilePhone);
        onTelCodeChange(profilePhoneCode);
        onTelChange(profilePhone);
        onRequestBrokerCallTimeOptions();
    }

    createOptions = () => {
        const { immInvestmentSpecialists } = this.props;
        const callTimeOptions = immInvestmentSpecialists.get('callTimeOptions');
        return callTimeOptions.map(option => {
            if (option.labelKey) {
                return {
                    ...option,
                    label: <FormattedText value={option.labelKey} />
                }
            }
            return option;
        })
    }

    onTelChange(value) {
        this.props.onTelChange(value);
    }

    onTelCodeChange(value) {
        this.props.onTelCodeChange(value);
    }

    handleCallTimeChange(option) {
        this.props.onCallTimeChange(option.label);
    }

    handleCommentChange(event) {
        this.props.onCommentChange(event.target.value);
    }

    render() {
        const { immInvestmentSpecialists, immProfile, showErrorMessages } = this.props;
        const clientName = immProfile.get('nombreCliente', '');
        const telCode = immInvestmentSpecialists.get('telcode');
        const telephone = immInvestmentSpecialists.get('phone');
        const callTime = immInvestmentSpecialists.get('callTime');
        const comment = immInvestmentSpecialists.get('message');
        const invalidTelCode = showErrorMessages && !immInvestmentSpecialists.get('validTelCode');
        const invalidTel = showErrorMessages && !immInvestmentSpecialists.get('validTel');
        const status = immInvestmentSpecialists.get('status');
        const invalidCallTime = showErrorMessages &&
            (callTime === RIGHT_AWAY && status !== CALL_TIME_OPTIONS_STATUS_OK);

        const behaviour = {
            value : CALL_ME_BACK_BEHAVIOUR,
            profileOfuscatedPhone: immInvestmentSpecialists.get('profilePhone'),
            profileCountryCode: PersonalDataHelper.getPhoneCodeOnly(immProfile)
        };

        return (
            <React.Fragment>
                <h4 className="broker-investment-specialist-modal__title">
                    <FormattedText
                        value="brokerInvestmentSpecialistModal-header"
                        injectedStrings={[clientName.trim()]}
                    />
                </h4>
                <h4 className="broker-investment-specialist-modal__title">
                    <FormattedText value="brokerInvestmentSpecialistModal-subtitle" />
                </h4>

                <div className="broker-investment-specialist-modal__field">
                    <label className="ok-select__label">
                        <FormattedText value="brokerInvestmentSpecialistModal-contactTel" />
                    </label>
                    <PhoneInput
                        id="brokerCallMeBackPhone"
                        phonePrefix={telCode}
                        phoneNumber={telephone}
                        prefixCallback={this.onTelCodeChange}
                        numberCallback={this.onTelChange}
                        numberError={invalidTel}
                        prefixError={invalidTelCode}
                        numberErrorMsg={formatText('brokerInvestmentSpecialistModal-invalidTel')}
                        prefixErrorMsg={formatText('brokerInvestmentSpecialistModal-invalidTelCode')}
                        behaviour={behaviour}
                    />
                </div>

                <div className="broker-investment-specialist-modal__field">
                    <OKSelect
                        className="broker-investment-specialist-modal__options"
                        label={formatText('brokerInvestmentSpecialistModal-whatTime')}
                        onChange={this.handleCallTimeChange}
                        value={callTime}
                        options={this.createOptions()}
                        searchable={false}
                        errorState={invalidCallTime}
                        errorMessage={formatText(
                            'brokerInvestmentSpecialistModal-callTimeTimezoneOutOfRange'
                        )}
                    />
                </div>

                <div className="broker-investment-specialist-modal__field">
                    <OKTextArea
                        label={formatText('brokerInvestmentSpecialistModal-reason')}
                        value={comment}
                        onChange={this.handleCommentChange}
                        maxLength={BROKER_CALLMEBACK_MAX_COMMENT_CHARS}
                    />
                </div>
            </React.Fragment>
        );
    }
}

BrokerInvestmentSpecialistModal.propTypes = {
    immInvestmentSpecialists: PropTypes.object.isRequired,
    immProfile: PropTypes.object.isRequired,
    onCallTimeChange: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onCommentChange: PropTypes.func.isRequired,
    onRequestBrokerCallTimeOptions: PropTypes.func.isRequired,
    onRequestCall: PropTypes.func.isRequired,
    onTelChange: PropTypes.func.isRequired,
    onTelCodeChange: PropTypes.func.isRequired,
    onSetProfilePhone: PropTypes.func.isRequired,
    showErrorMessages: PropTypes.bool.isRequired,
};

module.exports = BrokerInvestmentSpecialistModal;