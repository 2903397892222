// @ vendor
const React = require('react');
const PropTypes = require('prop-types');

// @utilities
const { formatText, hasTranslation } = require('core/i18n').i18n;

// @commons
const OKCommonTag = require('commonsComponents/OKTag');

class OKTag extends React.Component {

    render() {
        let { content } = this.props;
        let tagProps = Object.assign({}, this.props);

        if (typeof content === 'string' && hasTranslation(content)) {
            content = formatText(content) ;
            delete tagProps.content;
        }

        return (
            <OKCommonTag
                {...tagProps}
                content={content}
            />
        );
    }
}
OKTag.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]).isRequired
};

module.exports = OKTag;
