module.exports = {
    CC_PERIODICITY_DAILY: 'DAILY',
    CC_PERIODICITY_WEEKLY: 'WEEKLY',
    CC_PERIODICITY_CUSTOM: 'CUSTOM',
    CC_PERIODICITY_RANGE_DESCRIPTION_MONTH: 'MONTH',
    CC_PERIODICITY_RANGE_SPECIFICATION_DAY: 'DAY_SPECIFIED',
    CC_PERIODICITY_RANGE_SPECIFICATION_DAYS: 'DAYS_SPECIFIED',
    CC_FIXED_DATES: 'FIXED_DATES',

    SELECTED_WEEK_DAY_DEFAULT: 0,

    CC_TRANSFER_TYPE_ORDINARY: 'O',
    CC_TRANSFER_TYPE_PAYROLL: 'P',

    CC_TRANSFER_TYPE_CODE_ORDINARY: '10',
    CC_TRANSFER_TYPE_CODE_ORDINARY_EMISION_SEPA_INTERNATIONAL: '13',
    CC_TRANSFER_TYPE_CODE_PAYROLL_NATIONAL: '23',
    CC_TRANSFER_TYPE_CODE_PAYROLL_SEPA_INTERNATIONAL: '25',
};
