require('./OKErrorBoundary.scss');

// @vendors
const PropTypes = require('prop-types');
import React, { Component, Fragment } from 'react';
const { FormattedText, formatText } = require('core/i18n').i18n;

// components
const WizardError = require('components/commons/wizardError/wizardError');

// @helpers
const { isALowerEnvironment } = require('utilities/environmentHelper');
const { getPersonTypeAndCodeHashed } = require('utilities/hash');

export class OKErrorBoundary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            info: null
        };
    }

    componentDidCatch(error, info) {
        const personTypeAndCodeHashed = getPersonTypeAndCodeHashed();
        if (window.ADRUM) {
            const eventError = new window.ADRUM.events.Error()
            if(error && error.stack && typeof error.stack.toString === 'function'){
                eventError.msg(`${error.stack.toString()} | clientId:${personTypeAndCodeHashed}`)
            } else {
                eventError.msg(`clientId:${personTypeAndCodeHashed}`)
            }
            window.ADRUM.report(eventError)
        }

        this.setState({
            error,
            info
        })
    }

    buildMessage = () => {
        const { error, info } = this.state;

        let message = '';
        let detail = '';

        if (isALowerEnvironment()) {
            message = `Error: ${error.message}`;
            detail = `Detail: ${info && info.componentStack}`;
        } else {
            message = formatText('errorBoundary-title');
        }

        let errorDescription;
        if (detail) {
            errorDescription = <pre>{detail}</pre>;
        } else {
            errorDescription = (
                <p>
                    <FormattedText
                        value="errorBoundary-message"
                    />
                </p>
            )
        }

        return (
            <div className="ok-error-boundary__container">
                <WizardError
                    errorMessage={message}
                >
                    {errorDescription}
                </WizardError>
            </div>
        )
    }

    render() {
        const { children, behaviour } = this.props;
        let content;

        if (behaviour === 'log'){
            content = children;
        } else if (this.state.error){
            content = this.buildMessage();
        } else {
            content = children;
        }


        return (
            <Fragment>
                {content}
            </Fragment>
        );
    }
}

OKErrorBoundary.defaultProps = {
    behaviour: "log_and_show_info"
}

OKErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    behaviour: PropTypes.string
};
