const { formatText } = require('core/i18n').i18n;
const { sortByOrder } = require('lodash/collection');
const { EURO_TEXT, EURO_SYMBOL } = require('constants/index');

// Se acordo mostrar el codigo para las monedas extranjeras
// en vez del simbolo. Dejo el simbolo en el comment.
const CURRENCIES = {
    [EURO_TEXT]: EURO_SYMBOL, // EURO
    USD: 'USD', // U$S AMERICAN DOLLAR
    AUD: 'AUD', // A$ AUSTRALIAN DOLLAR
    CAD: 'CAD', // C$ CANADIAN DOLLAR
    NZD: 'NZD', // NZ$ NEW ZEALAND DOLLAR
    GBP: 'GBP', // £ POUND STERLING
    CHF: 'CHF', // Fr SWISS FRANC
    SEK: 'SEK', // S-kr SWEDISH KRONA
    NOK: 'NOK', // N-kr NORWEGIAN KRONE
    DKK: 'DKK', // D-kr DANISH KRONE
    JPY: 'JPY' // ¥ JAPANESE YEN
};

const CURRENCIES_SYMBOLS = {
    [EURO_TEXT]: EURO_SYMBOL,
    USD: '$',
    AUD: '$',
    CAD: '$',
    NZD: '$',
    GBP: '£',
    CHF: 'Fr',
    SEK: 'kr',
    NOK: 'kr',
    DKK: 'kr',
    JPY: '¥'
}

const currenciesOptions = function() {
    const countriesUnsorted = Object.keys(CURRENCIES).map( key => {
        return {
            value: key,
            label: formatText('currency-' + key)
        }
    });

    return sortByOrder(countriesUnsorted, ['label'], ['asc']);
}

module.exports = {
    CURRENCIES,
    CURRENCIES_SYMBOLS,
    currenciesOptions
};
