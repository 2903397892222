require('./globalPositionFinancialCardBodyItem.scss');
require('./globalPositionFinancialCardBodyItem.override.scss');

const PropTypes = require('prop-types');
const React = require('react');
const { Link } = require('react-router-dom');
const Immutable = require('immutable');
const classnames = require('classnames');
// @ components
const WarningMessage = require('components/contactCenter/warningMessage/warningMessage')
const ContactCenterRender = require('components/conditionalRender/contactCenterRender')
//@commons
const { OKLink } = require('commonsComponents/OKLink');
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');
const { CollectiveTag } = require('components/commons/collectiveTag/collectiveTag');
//@core
const { getLanguage, formatText, FormattedText } = require('core/i18n').i18n;
// @ helpers
const { formatAmount, formatCurrency, formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');
const { conditionalClassName } = require('utilities/conditionals/conditionalClassname');

const GlobalPositionFinancialCardBodyItem = (props) => {
    const {
        amount,
        currency,
        easyLoanFFStatus,
        isExtraItem,
        keepSign,
        isWealth,
        hasWarnings,
        linkButton,
        linkClick,
        insurance,
        warningCode,
        warningLink
    } = props;

    const accountHasWarning = hasWarnings === 'S' && warningCode !== '00';
    const amountObject = Immutable.fromJS({
        amount: keepSign ? amount : Math.abs(amount),
        currency
    });
    const plainAmountObject = getPlainAmountObject(amountObject);
    const titleClass = classnames('glb-position-financial-card-item__title', {
        'glb-position-financial-card-item__title--wealth': isWealth,
        'glb-position-financial-card-item__title--extra': isExtraItem
    });
    const amountClass = classnames('glb-position-financial-card-item__amount', {
        'glb-position-financial-card-item__amount--wealth': isWealth
    })

    const globalBlockClass = 'glb-position-financial-card-item';
    const hasWarningClasses = classnames(globalBlockClass, {
        [`${globalBlockClass}--has-warnings`]: accountHasWarning
    });
    const hasWarningClassesClassName = conditionalClassName(globalBlockClass, hasWarningClasses)

    let warningAlert = null
    if (__CONTACT_CENTER__ && accountHasWarning) {
        warningAlert = (
            <span className="col-sm-1 glb-position-financial-card-item__warning">
                <WarningMessage
                    isLink
                    text={""}
                    to={warningLink}
                    visible
                    typeOfBackground="light"
                />
            </span>
        );
    }

    const warningComponent = warningAlert && (
        <OKTooltip
            target={warningAlert}
            content={formatText("globalPosition-accountSeeWarning")}
            placement="top"
        />
    )

    let titleComponent;
    const title = (
        <Link
            onClick={props.handleOnClickTitle}
            to={props.link}
            className={titleClass}
        >
            {props.title}
        </Link>
    );

    if (props.tooltipText) {
        titleComponent = (
            <span>
                <OKTooltip
                    target={title}
                    content={props.tooltipText}
                    placement="right"
                    offset={[10, 0]}
                />
            </span>
        );
    } else if (isExtraItem) {
        titleComponent = <span className={titleClass}>{props.title}</span>;
    } else {
        titleComponent = title;
    }

    const amountContainer = linkButton ? (
        <OKLink
            modifier="emphasize"
            extraClass="glb-position-financial-card-item__easy-loan-link"
            onClick={linkClick}
            to={props.link}>
            {linkButton}
        </OKLink>
    ) :
        (
            <span className={amountClass}>
                <OneLineSymbolAmount
                    amountObject={plainAmountObject}
                    language={getLanguage()}
                    amountObjectFormatted={formatAmountObject(plainAmountObject, true)} />
            </span>
        );

    const buildSubtitleInfo = () => {
        let content = null;

        if (!props.noSubtitle) {
            content = (
                <div className="glb-position-financial-card-item__col">
                    <span className="glb-position-financial-card-item__subtitle">
                        <span className="glb-position-financial-card-item__subtitle-label">
                            {props.subtitleLabel}
                        </span>
                        <span className="glb-position-financial-card-item__subtitle-amount">
                            {formatAmount(props.subtitleAmount)}
                        </span>
                        <span className="glb-position-financial-card-item__subtitle-currency">
                            {formatCurrency(props.subtitleCurrency)}
                        </span>
                    </span>
                </div>
            );
        }
        return content;
    };

    const getInsuranceInfo = insurance => {
        let content = null;
        if (insurance) {
            content = (
                <div className="row">
                    <div className="col-xs-12 col-sm-12">
                        <div className="glb-position-financial-card-item__insurance-content">
                            {`${insurance.description} ${insurance.title}`}
                            <Link to={insurance.link} className="glb-position-financial-card-item__insurance-link">
                                <FormattedText
                                    className="glb-position-financial-card-item__insurance-link-text"
                                    value="insuranceLanding-mortgageLinkInsurance"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            );
        }
        return content;
    };

    const buildSecondLine = () => {
        if (!!props.secondLine) {
            return (
                <div className="glb-position-financial-card-item__content-title">
                    <span className="glb-position-financial-card-item__second-line">
                        {props.secondLine}
                    </span>
                </div>
            );
        }
    }

    return (
        (easyLoanFFStatus) ?
        <div className={hasWarningClassesClassName}>
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <div className="glb-position-financial-card-item__content-title">
                        {titleComponent}
                        <span className="glb-position-financial-card-item__title--small">
                            {props.titleSmall}
                        </span>
                        <CollectiveTag
                            productType={props.productType}
                        />
                    </div>
                    {buildSecondLine()}
                </div>
                <div className="col-xs-12 col-sm-6">
                    <div className="glb-position-financial-card-item__amount-object">
                        <ContactCenterRender>
                            {warningComponent}
                        </ContactCenterRender>
                        {buildSubtitleInfo()}
                        <div className="glb-position-financial-card-item__col">
                            {amountContainer}
                        </div>
                    </div>
                </div>
            </div>
            {getInsuranceInfo(insurance)}
        </div>
        : (
            <div/>
        )
    )
}

GlobalPositionFinancialCardBodyItem.propTypes = {
    amount: PropTypes.number,
    currency: PropTypes.string,
    easyLoanFFStatus: PropTypes.bool,
    handleOnClickTitle: PropTypes.func,
    isWealth: PropTypes.bool,
    link: PropTypes.string,
    linkButton: PropTypes.string,
    linkClick: PropTypes.func,
    insurance: PropTypes.object,
    warningLink: PropTypes.string,
    noSubtitle: PropTypes.bool,
    productType: PropTypes.object,
    subtitleLabel: PropTypes.string,
    subtitleAmount: PropTypes.number,
    subtitleCurrency: PropTypes.string,
    secondLine: PropTypes.string,
    title: PropTypes.string,
    titleSmall: PropTypes.string,
    tooltipText: PropTypes.string,
    hasWarnings: PropTypes.string,
    warningCode: PropTypes.string,
    keepSign: PropTypes.bool,
    isExtraItem: PropTypes.bool
};

GlobalPositionFinancialCardBodyItem.defaultProps = {
    easyLoanFFStatus: true,
    hasWarnings: 'N',
    warningCode: '00',
    isWealth: false,
    isExtraItem: false,
    noSubtitle: false
}

module.exports = GlobalPositionFinancialCardBodyItem;
