const PropTypes = require('prop-types');
// @ vendors
const React = require('react');
const { OKButton } = require('components/commons/OKButton/OKButton');

// @ helpers
const { formatText } = require('core/i18n').i18n;
const { toFirstUppercase } = require('utilities/stringHelper');

const CustomNextArrow = props => {
    props = Object.assign({}, props);

    /*
        The following check was derived by run-time analysis of the code and its effects
    */
    if (props.className.includes("slick-disabled")) {
        const handleClick = props.onCloselink;
        props.className = "tutorial-overlay__next-button"; // must override the provided values
        return (
            <div {...props}>
                <OKButton
                    onClick={handleClick}
                    modifier="primary"
                    id={"nextTutorialButtonId"}>
                    {toFirstUppercase(formatText('buttonsActions-finish'))}
                </OKButton>
            </div>
        );
    } else {
        props.className = "tutorial-overlay__next-button"; // must override the provided values
        return (
            <div {...props}>
                <OKButton modifier="primary" rightIcon="icon-siguiente" id={"nextTutorialButtonId"}>
                    {toFirstUppercase(formatText('buttonsActions-next'))}
                </OKButton>
            </div>
        );
    }
};

CustomNextArrow.propTypes = {
    closeLink: PropTypes.func,
    className: PropTypes.string,
    onCloselink: PropTypes.func
};

module.exports = CustomNextArrow;
