module.exports = {
    // STANDARDS ON NERUDA
    NERUDA_CARD_1_ON_STANDARD: '0000001',
    NERUDA_CARD_2_ON_STANDARD: '0000002',
    NERUDA_CARD_3_ON_STANDARD: '0000003',
    NERUDA_CARD_4_ON_STANDARD: '0000004',
    NERUDA_CARD_5_ON_STANDARD: '0000005',
    NERUDA_CARD_6_ON_STANDARD: '0000006',
    NERUDA_CARD_7_ON_STANDARD: '0000013',
    // STANDARDS OFF NERUDA
    NERUDA_CARD_1_OFF_STANDARD: '0000007',
    NERUDA_CARD_2_OFF_STANDARD: '0000008',
    NERUDA_CARD_3_OFF_STANDARD: '0000009',
    NERUDA_CARD_4_OFF_STANDARD: '0000010',
    NERUDA_CARD_5_OFF_STANDARD: '0000011',
    NERUDA_CARD_6_OFF_STANDARD: '0000012',
    NERUDA_CARD_7_OFF_STANDARD: '0000014',
    // STANDARDS OFF VANITY
    VANITY_CARD_1_OFF_STANDARD: '0000001',
    // STANDARDS ON VANITY
    VANITY_CARD_1_ON_STANDARD: '0000002',
    // STANDARDS OFF HORMIGUERO
    HORMIGUERO_CARD_1_OFF_STANDARD:'0000001',
    // STANDARDS ON HORMIGUERO
    HORMIGUERO_CARD_1_ON_STANDARD: '0000002',
};