require('./globalPositionMortgages.scss');

const PropTypes = require('prop-types');

// @ vendors
const React = require('react');
const { connect } = require('react-redux');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const { withRouter } = require('react-router-dom');
const classnames = require('classnames');

// @ helpers
const { formatText } = require('core/i18n').i18n;
const { getPublicSiteUrl } = require('core/apiURLBuilder');
const includes = require('lodash/collection/includes');
const some = require('lodash/collection/some');
// @ components
const GlobalPositionFinancialCard = require('components/globalPosition/globalPositionFinancialCard/globalPositionFinancialCard');

// @actions
const { setWaferIdRequest } = require('actions/myGlobalPosition');

//constants
const GLOBAL_POSITION_BOXES = require('constants/globalPositionBoxes');

class GlobalPositionMortgages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxVisibleItems : this.props.isOnPG || this.props.redirectionFlag !== GLOBAL_POSITION_BOXES.MORTGAGE  ? 5 : this.getMortgages().length, 
        };
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
        this.handleOnWaferMortgagesClick = this.handleOnWaferMortgagesClick.bind(this);
        this.handleOnSeeMoreClick = this.handleOnSeeMoreClick.bind(this);
    }

    getInsuranceMortgage(immMortgage) {
        const { immMortgageAssociatedInsurances } = this.props;
        const contractNumber = immMortgage.get('newContractNumber');
        const mortgageAssociatedInsurances = immMortgageAssociatedInsurances.get('mortgageAssociatedInsurances');
        let insurance = null;

        if (mortgageAssociatedInsurances && contractNumber) {
            const currentInsurance = mortgageAssociatedInsurances.find(mortgageAssociatedInsurance =>
                mortgageAssociatedInsurance.get('mortgageContractNumber') === contractNumber)

            insurance = currentInsurance ? {
                description: formatText('insuranceLanding-descriptionMortgage'),
                link: '/myprofile/insurances/home/' + currentInsurance.get('policyCode'),
                title: formatText('insuranceLanding-homeInsurance')
            } : null;
        }

        return insurance;
    }

    getMortgages() {
        const { immLoans, isOnPG, immMortgageAssociatedInsurances } = this.props;

        let immMortgagesByOrder = immLoans.get('mortgagesByOrder');

        if (isOnPG) {
            immMortgagesByOrder = immMortgagesByOrder.filter(mortgageId => {
                const isValid = immLoans.getIn(['byId', mortgageId, 'filters', 'valid']);
                const balanceIsZero = immLoans.getIn(['byId', mortgageId, 'balance', 'amount']) === 0;

                return isValid && !balanceIsZero;
            })
        } else {
            immMortgagesByOrder = immMortgagesByOrder.sort((firstMortgage, secondMortgage) => {
                return immLoans.getIn(['byId', firstMortgage, 'balance', 'amount']) > immLoans.getIn(['byId', secondMortgage,'balance', 'amount']) ? 1 : -1;
             })
             
        }

        const mortgages = immMortgagesByOrder.map(mortgageId => {
            const immMortgage = immLoans.getIn(['byId', mortgageId]);
            const insurance = immMortgageAssociatedInsurances ? this.getInsuranceMortgage(immMortgage) : null;

            return {
                amount: immMortgage.getIn(['balance', 'amount']),
                currency: immMortgage.getIn(['balance', 'currency']),
                link: `/myprofile/loans-mortgages/loans/${immMortgage.get('loanId')}`,
                product: immMortgage.get('newProduct'),
                productType: {
                    type: immMortgage.get('product'),
                    subType: immMortgage.get('productSubType'),
                    standard: immMortgage.get('productStandard')
                },
                insurance,
                title: immMortgage.get('alias')
            };
        })

        return mortgages.toJS();
    }

    getTotal() {
        const { immLoans, isOnPG } = this.props;

        let mortgagesBalance;
        if (isOnPG) {
            mortgagesBalance = immLoans.get('mortgagesBalanceForPG');
        } else {
            mortgagesBalance = immLoans.get('mortgagesBalance');
        }

        return mortgagesBalance.map(balance => {
            return {
                currency: balance.get('currency'),
                amount: balance.get('amount'),
                legend: formatText('myGlobalPosition-savingsTotal')
            }
        }).toArray();
    }

    handleOnWaferMortgagesClick(evt) {
        const { history, isOnPG } = this.props;
        const mortages = this.getMortgages();
        const url = mortages[0].link;
        const classListWithoutRedirect = [
            'glb-position-showmore',
            'ok-collapsing-toggler'
        ];

        if (!some(evt.target.parentElement.classList, (el) => includes(classListWithoutRedirect, el))) {
            if (isOnPG) {
                history.push('/myprofile/loans-mortgages');
            } else {
                history.push(url);
            }
        }
    }

    handleOnSeeMoreClick() {
        const { history, isOnPG , id, setWaferIdRequest 
        } = this.props;
        const mortages = this.getMortgages();
        if (isOnPG) {
            setWaferIdRequest(id);
            history.push('/myprofile/loans-mortgages');
            }
            else {
            setWaferIdRequest('');
            this.setState({maxVisibleItems: mortages.length})
            }
    }
    
    hasOpenMortgages(mortgages) {
        return mortgages.find(mortgage => {
            return mortgage.product === '103' // Hipoteca Open
        });
    }

    render() {
        const { isOnPG, expanded } = this.props;
        const { maxVisibleItems } = this.state;
        const mortgages = this.getMortgages();
        const total = this.getTotal();
        let extraLink;

        if (!this.hasOpenMortgages(mortgages) && !__CONTACT_CENTER__) {
            extraLink = {
                url: `${getPublicSiteUrl()}/hipoteca-open`,
                text: formatText('myGlobalPosition-otherMortgagesLink')
            };
        }

        const isClickable = isOnPG ? !expanded : expanded && mortgages.length === 1;

        const globalPositionMortgagesClasses = classnames('global-position-mortgages', {
            'global-position-mortgages--is-clickable': isClickable
        })

        return (
            <section className={globalPositionMortgagesClasses} onClick={isClickable && this.handleOnWaferMortgagesClick}>
                <GlobalPositionFinancialCard
                    id={this.props.id}
                    icon="rebrand-mortgages"
                    amount={mortgages.length}
                    maxVisibleItems={maxVisibleItems}
                    cards={mortgages}
                    currencyDetails={total}
                    showMoreText={formatText('myGlobalPosition-creditCardShowMore')}
                    showMoreLink="/myprofile/loans-mortgages"
                    title={formatText('myGlobalPosition-mortgages')}
                    expanded={this.props.expanded}
                    extraLink={extraLink}
                    titleLink="/myprofile/loans-mortgages"
                    handleOnSeeMoreClick={this.handleOnSeeMoreClick}
                />
            </section>
        );
    }
}

GlobalPositionMortgages.propTypes = {
    id: PropTypes.string,
    expanded: PropTypes.bool,
    history: PropTypes.object.isRequired,
    immLoans: PropTypes.object.isRequired,
    immMortgageAssociatedInsurances: PropTypes.object,
    isOnPG: PropTypes.bool,
    redirectionFlag:PropTypes.string,
    setWaferIdRequest: PropTypes.func
};

GlobalPositionMortgages.defaultProps = {
    isOnPG: true
};

export default connect(
    (state) => ({
        redirectionFlag : state.globalPosition.get('redirectionFlag'),
    }),
    {
        setWaferIdRequest
    }
)(withRouter(GlobalPositionMortgages));
