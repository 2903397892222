require('./globalPositionFinancialCardHeader.scss');

// @vendor
const React = require('react');
const classnames = require('classnames');
const PropTypes = require('prop-types');

// @core
const { formatText } = require('core/i18n').i18n;

// @utils
const { conditionalClassName } = require('utilities/conditionals/conditionalClassname');
const { webappClassName } = require('utilities/conditionals/conditionalClassname');

// @commons
const { OKLink } = require('commonsComponents/OKLink');


// @components
const GlobalPositionFinancialCardHeaderCol = require('./globalPositionFinancialCardHeaderCol/globalPositionFinancialCardHeaderCol');
const WarningMessage = require('components/contactCenter/warningMessage/warningMessage');
const WebappRender = require('components/conditionalRender/webappRender');
const ContactCenterRender = require('components/conditionalRender/contactCenterRender');
const FormattedTextWithComponents = require('components/broker/brokerFormattedTextWithComponents/brokerFormattedTextWithComponents');

// @constants
const { PREPAID_CARD } = require('constants/index');

const GlobalPositionFinancialCardHeader = props => {
    const {
        icon,
        title,
        subtitle,
        currencyDetails,
        amount,
        tooltipContent,
        titleLink,
        keepSign,
        expanded,
        isWealth,
        hasWarnings,
        cardType
    } = props;

    const headerColumns = currencyDetails.map((colContent, index) => {
        const headerColKey = `header-row-key-${index}`;

        return (
            <GlobalPositionFinancialCardHeaderCol
                key={headerColKey}
                currency={colContent.currency}
                legend={colContent.legend}
                amount={colContent.amount}
                tooltipContent={tooltipContent}
                keepSign={keepSign}
                isWealth={isWealth}
            />
        )
    });

    const isNotCurrentSection = titleLink !== window.location.pathname;
    const titleClasses = classnames('glb-position-financial-card-header__title-text', {
        'glb-position-financial-card-header__title-text--is-link': isNotCurrentSection,
        'glb-position-financial-card-header__title-text--is-expanded': expanded
    });

    let amountRowEl;
    let titleComponent = (
        <span className={titleClasses}>
            {title}
        </span>
    );

    let warningComponent = null;
    if (__CONTACT_CENTER__ && hasWarnings) {
        warningComponent = (
            <div className="glb-position-financial-card-header__warning row">
                <WarningMessage
                    text={formatText("globalPosition-accountsWarning")}
                    visible={hasWarnings}
                    typeOfBackground="light"
                />
            </div>
        )
    }

    if (amount) {
        amountRowEl = (
            <span className="glb-position-financial-card-header__title-amount-items">
                ({amount})
            </span>
        );
    }

    if (titleLink && isNotCurrentSection) {
        const linkClasses = webappClassName({'glb-position-financial-card-header__title-link--is-expanded': expanded}, 'glb-position-financial-card-header__title-link');
        titleComponent = (
            <span className={linkClasses}>
                <OKLink to={titleLink} modifier="inherit-styles">
                    {title}
                </OKLink>
            </span>
        );
    }

    let disclaimerWealth = '';
    if (isWealth || cardType) {
        const link = isWealth ? (
            <OKLink modifier="emphasize" to="/myprofile/broker/general">
                {formatText("myGlobalPosition-wealthDisclaimerLink")}
            </OKLink>
        ) : '';
        
        const value = isWealth ? "myGlobalPosition-wealthDisclaimer" : cardType === PREPAID_CARD ?
        "myGlobalPosition-cardDisclaimerText":"";
        disclaimerWealth = (
            <FormattedTextWithComponents
                value={value}
                injectedComponents={[link]}
            />
        )
    }

    const classNameHeaderTitle = `glb-position-financial-card-header__title-icon icon ${icon}`;

    const leftColsClassName = conditionalClassName('col-xs-12 col-sm-6', 'glb-position-financial-card-header__title-wrap col-xs-7')
    const rightColsClassName = conditionalClassName('col-xs-12 col-sm-6', 'glb-position-financial-card-header__currency-detail-wrap col-xs-5')

    return (
        <div className="container-fluid">
            <div className="glb-position-financial-card-header row">
                <div className={leftColsClassName}>
                    <div className="glb-position-financial-card-header__title-container">
                        <i className={classNameHeaderTitle}></i>
                        {titleComponent}
                        {amountRowEl}
                        <WebappRender>
                            <span
                                className="glb-position-financial-card-header__title-disclaimer"
                                id="txt-FinancialHeader-subtitle"
                            >
                                {subtitle}
                            </span>
                        </WebappRender>
                    </div>
                </div>
                <div className={rightColsClassName}>
                    <ul className="glb-position-financial-card-header__cols">
                        {headerColumns}
                    </ul>
                </div>
            </div>
            <ContactCenterRender>{warningComponent}</ContactCenterRender>
            <div className="glb-position-financial-card-header__wealth-disclaimer row">
                <div className="col-xs-12">{disclaimerWealth}</div>
            </div>
        </div>
    );
};

GlobalPositionFinancialCardHeader.propTypes = {
    amount: PropTypes.number,
    currencyDetails: PropTypes.array,
    icon: PropTypes.string,
    isWealth: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    tooltipContent: PropTypes.string,
    titleLink: PropTypes.string,
    keepSign: PropTypes.bool,
    hasWarnings: PropTypes.bool,
    expanded: PropTypes.bool,
    cardType: PropTypes.string,
};

GlobalPositionFinancialCardHeader.defaultProps = {
    hasWarnings: false,
    isWealth: false
};

module.exports = GlobalPositionFinancialCardHeader;