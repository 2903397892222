const {
    ACCOUNT_INTERVENTION_OWNER,
    ACCOUNT_INTERVENTION_AUTHORIZED,
    ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE,
    ACCOUNT_INTERVENTION_ADMINISTRATOR,
    ACCOUNT_INTERVENTION_REPRESENTATIVE,
    ACCOUNT_INTERVENTION_OWNER_CODE,
    ACCOUNT_INTERVENTION_AUTHORIZED_CODE,
    ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE,
    ACCOUNT_INTERVENTION_ADMINISTRATOR_CODE,
    ACCOUNT_INTERVENTION_REPRESENTATIVE_CODE
} = require('constants/index.js');

const INTERVENER_DESCRIPTIONS = {
    '01': 'TITULAR',
    '07': 'APODERADO',
    '08': 'AUTORIZADO',
    '09': 'REPRESENTANTE LEGAL',
    '25': 'ADMINISTRADOR /REP LEG JURID'
};

const INTERVENER_CODES_BY_DESCRIPTION = {
    [ACCOUNT_INTERVENTION_OWNER]: ACCOUNT_INTERVENTION_OWNER_CODE,
    [ACCOUNT_INTERVENTION_AUTHORIZED]: ACCOUNT_INTERVENTION_AUTHORIZED_CODE,
    [ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE]: ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE,
    [ACCOUNT_INTERVENTION_ADMINISTRATOR]: ACCOUNT_INTERVENTION_ADMINISTRATOR_CODE,
    [ACCOUNT_INTERVENTION_REPRESENTATIVE]: ACCOUNT_INTERVENTION_REPRESENTATIVE_CODE
};

const INTERVENER_CODES = {
    'administrator': '25',
    'authorized': '08',
    'empowered': '07',
    'holder': '01',
    'legalRepresentative': '09'
};


module.exports = {
    INTERVENER_CODES,
    INTERVENER_CODES_BY_DESCRIPTION,
    INTERVENER_DESCRIPTIONS
};
