require('./tutorial.scss');
require('slick-carousel/slick/slick.scss');
require('slick-carousel/slick/slick-theme.scss');

// @vendors
const React = require('react');
const { connect } = require('react-redux');
const PropTypes = require('prop-types');
const Slider = require("react-slick").default;

// @components
const CustomPrevArrow = require('./customPrevArrow');
const CustomNextArrow = require('./customNextArrow');
const openTutorialImg = require('assets/images/icon-tutorial.svg');

// @commons
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');

// @utilities
const {
    recordProcess,
    PROC_START,
    PROC_END,
} = require('utilities/tagging');
const storage = require('utilities/storage');

// @actions
const tutorialActions = require('actions/tutorial');

class Tutorial extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this);
    }

    componentDidMount() {
        const {
            immFlagBasedFeatures,
            id,
            tutorialShouldOpen,
            shouldOpenByDefault
        } = this.props;

        if (immFlagBasedFeatures.get('tutor-database-enabled') && shouldOpenByDefault) {
            tutorialShouldOpen(id);
        }
    }

    handleClick(event) {
        const { tutorialToggleOpen, onOpenHandler } = this.props;
        event.preventDefault();
        tutorialToggleOpen(true);
        onOpenHandler();
        recordProcess('tutorial', PROC_START);
    }

    handleCloseClick(event) {
        const {
            closeCallBack,
            id,
            immFlagBasedFeatures,
            immTutorial,
            requestSetTutorialSeen,
            tutorialToggleOpen,
        } = this.props;
        event.preventDefault();
        closeCallBack();
        tutorialToggleOpen(false);
        recordProcess('tutorial', PROC_END);
        const tutorialName = `tg-tutorial-${id}`;

        if (immFlagBasedFeatures.get('tutor-database-enabled')) {
            const immTutorialDetails = immTutorial.get('tutorialList').find(immTutorialDetails => (
                immTutorialDetails.get('tutorial') === tutorialName
            ));
            if (!!immTutorialDetails && !!immTutorialDetails.get('tutorialId')) {
                requestSetTutorialSeen([immTutorialDetails.get('tutorialId')]);
            }
        } else {
            storage.localStorage.set(tutorialName, 1);
        }
    }

    renderOpenButton() {
        const fireButton = <img id="tutorialopen" src={openTutorialImg} />;
        return (
            <div className="tutorial-open hidden-xs hidden-sm" onClick={this.handleClick} >
                <OKTooltip
                    content={this.props.tooltip.toString()}
                    modifier="dark-bg"
                    offset={[0, -15]}
                    target={fireButton}
                    type="descriptive"
                />
            </div>
        );
    }

    renderSlider() {
        const {
            images,
            subtitle,
            title,
        } = this.props;
        const manyImages = images.length > 1;
        const myNext = <CustomNextArrow onCloselink={this.handleCloseClick} />;
        const myPrev = <CustomPrevArrow />;

        let settings = {
            nextArrow: myNext,
            prevArrow: myPrev,
            accessibility: manyImages,
            arrows: true,
            autoplay: false,
            centerMode: true,
            dots: manyImages,
            infinite: false,
            lazyLoad: false,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        if (this.props.notCenterPadding) {
            settings.centerPadding = 0;
        }

        // Use an extra <div> according to issue at
        // https://github.com/akiran/react-slick/issues/261

        const imgItems = images.map((image, i) => (
            <div key={i} className="tutorial-overlay__content-img-tutorial">
                <img className="tutorial-overlay__image" src={image} />
            </div>
        ));

        return (
            <div className="tutorial-overlay">
                <div>
                    <div className="tutorial-overlay__top-space" />
                    <div className="tutorial-overlay__title">{title}</div>
                    <div className="tutorial-overlay__subtitle">{subtitle}</div>
                    <div className="tutorial-overlay__slider">
                        <Slider {...settings}>
                            {imgItems}
                        </Slider>
                    </div>
                    <i className="tutorial-overlay__close icon-cerrar" onClick={this.handleCloseClick} />
                </div>
            </div>
        );
    }

    render() {
        const { immTutorial } = this.props;
        let content;

        if (immTutorial.get('isOpen')) {
            content = this.renderSlider();
        } else {
            content = this.renderOpenButton();
        }

        return content;
    }
}

Tutorial.defaultProps = {
    onOpenHandler: () => {},
    notCenterPadding: false,
    shouldOpenByDefault: true,
    closeCallBack: () => {}
}

Tutorial.propTypes = {
    onOpenHandler: PropTypes.func,
    closeCallBack: PropTypes.func,
    immFlagBasedFeatures: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    immTutorial: PropTypes.object.isRequired,
    requestSetTutorialSeen: PropTypes.func.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    tutorialShouldOpen: PropTypes.func.isRequired,
    tutorialToggleOpen: PropTypes.func.isRequired,
    notCenterPadding: PropTypes.bool,
    shouldOpenByDefault: PropTypes.bool
};

module.exports.constructor = Tutorial;
module.exports.Tutorial = connect(
    state => ({
        immTutorial: state.tutorial,
        immFlagBasedFeatures: state.flagBasedFeatures,
    }), {
        requestSetTutorialSeen: tutorialActions.requestSetTutorialSeen,
        tutorialShouldOpen: tutorialActions.tutorialShouldOpen,
        tutorialToggleOpen: tutorialActions.tutorialToggleOpen,
    })(Tutorial);
