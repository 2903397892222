const SEPA_COUNTRIES = [
    'AD', //ANDORRA
    'AT', //AUSTRIA
    'BE', //BELGICA
    'BG', //BULGARIA
    'CH', //SUIZA
    'CY', //CHIPRE
    'CZ', //CROACIA
    'DE', //ALEMANIA
    'DK', //DINAMARCA
    'EE', //ESTONIA
    'ES', //ESPAÑA
    'FI', //FINLANDIA
    'FR', //FRANCIA
    'GB', //REINO UNIDO
    'GI', //GIBRALTAR
    'GR', //GRECIA
    'HR', //REP.CHECA
    'HU', //HUNGRIA
    'IE', //IRLANDA
    'IS', //ISLANDIA
    'IT', //ITALIA
    'JE', //JERSEY
    'LI', //LIECHTENSTEIN
    'LT', //LITUANIA
    'LU', //LUXEMBURGO
    'LV', //LETONIA
    'MC', //MONACO
    'MT', //MALTA
    'NL', //PAISES BAJOS
    'NO', //NORUEGA
    'PL', //POLONIA
    'PT', //PORTUGAL
    'RO', //RUMANIA
    'SE', //SUECIA
    'SI', //ESLOVENIA
    'SK', //ESLOVAQUIA
    'SM', //SAN MARINO
    'VA', //CIUDAD DEL VATICANO
];

module.exports = SEPA_COUNTRIES;
