const welcomeAccountData = {
    productName: 'Welcome account',
    templateCode : "TCD_01"
};

const EMAILCONSTANTS = {
    '300800' : {
        productName : 'Cuenta Corriente Open',
        templateCode : "TCO_01"
    },
    '300805' : {
        productName : 'Cuenta Nómina Open',
        templateCode : "TCO_01"
    },
    '301006' : {
        productName : 'Cuenta de ahorro Openbank',
        templateCode : "TCD_01"
    },
    '301101' : {
        productName : 'Cuenta de ahorro OpenYoung',
        templateCode : "COY_01"
    },
    '506002' : {
        productName : 'Tarjeta de débito OpenDebit',
        templateCode : "TC_02"
    },
    '500801' : {
        productName : 'Tarjeta de crédito OpenCredit',
        templateCode : "TC_01"
    },
    '500803' : {
        productName : 'Virtual Only Credit',
        templateCode : "TC_03"
    },
    '506010' : {
        productName : 'Tarjeta prepago OpenYoung',
        templateCode : "TOY_01"
    },
    '506003' : {
        productName : 'Tarjeta prepago Ecard',
        templateCode : "TC_01"
    },
    '506005': {
        productName: 'Tarjeta de débito At Home Abroad',
        templateCode: 'TC_02'
    },
    '506007' : {
        productName : 'Tarjeta de débito minicard Debit Card',
        templateCode : "TC_02"
    },
    '501002' : {
        productName : 'Pack de tarjetas débito y crédito Diamond',
        templateCode : "TPDC_01"
    },
    '501003' : {
        productName : 'Pack de tarjetas débito y crédito Premium',
        templateCode : "TPDC_01"
    },
    '532001' : {
        productName : 'VIA-T',
        templateCode : "TVT_01"
    },
    '302883' : {
        productName : 'Depósito Open 13 meses',
        templateCode : "TCD_01"
    },
    '302884' : {
        productName : 'Depósito Open 13 meses',
        templateCode : "TCD_01"
    },
    '302895' : {
        productName : 'Depósito Open 12 meses',
        templateCode : "TCD_01"
    },
    '302890' : {
        templateCode : "TCD_01"
    },
    '302892' : {
        templateCode : "TCD_01"
    },
    '302893' : {
        templateCode : "TCD_01"
    },
    '302894' : {
        templateCode : "TCD_01"
    },
    '302898' : {
        templateCode : "TCD_01"
    },
    '302899' : {
        templateCode : "TCD_01"
    },
    '3028970000004' : {
        templateCode : "TCD_01"
    },
    '3028950000014' : {
        templateCode : "TCD_01"
    },
    '3028900000007' : {
        templateCode : "TCD_01"
    },
    '302901' : {
        templateCode : "TCD_01"
    },
    '302904' : {
        templateCode : "TCD_01"
    },
    '302900' : {
        templateCode : "TCD_01"
    },
    '302903' : {
        templateCode : "TCD_01"
    },
    '302902' : {
        templateCode : "TCD_01"
    },
    '302905' : {
        templateCode : "TCD_01"
    },
    '3028900000002' : {
        productName : 'Depósito Open 18 meses retenciones',
        templateCode : "TCD_02"
    },
    '302891' : {
        productName : 'Depósito retención 24 meses',
        templateCode : "TCD_02"
    },
    '302868' : {
        productName : 'Depósito Open 13 meses',
        templateCode : "TCD_02"
    },
    '302889' : {
        productName : 'Depósito Combinado',
        templateCode : "TCD_02"
    },
    '302897' : {
        productName : 'Depósito retención 6 meses',
        templateCode : "TCD_02"
    },
    '3028950000005' : {
        productName : 'Depósito 12 meses',
        templateCode : "TCD_02"
    },
    '999999' : {
        productName : 'Crédito de protección al descubierto',
        templateCode : "TPD_01"
    },
    '30500': {
        productName : 'Plan de Pensiones',
        templateCode : "TPP_01"
    },
    '30500CONTRIBUTION': {
        productName : 'Aportación Plan de Pensiones',
        templateCode : "TPP_02"
    },
    '30600': {
        productName : 'Aportación Plan de Pensiones',
        templateCode : "TPP_02"
    },
    '30601': {
        productName : 'Traspaso de Planes',
        templateCode : "TPP_03"
    },
    '506011': {
        productName : 'Tarjeta solidaria',
        templateCode : "TC_02"
    },
    '500804': {
        productName : 'Next Credit',
        templateCode : "TC_03"
    },
    '500805': {
        productName : 'Next Credit',
        templateCode : "TC_03"
    },
    '500806': {
        productName : 'Next Credit',
        templateCode : "TC_03"
    },
    '500807': {
        productName : 'Next Credit',
        templateCode : "TC_03"
    },
    '506012': {
        productName : 'Tarjeta de Débito Virtual Debit',
        templateCode : "TC_02"
    },
    'Solidarios': {
        productName : 'Extracto solidario',
        templateCode : "TSSS_01"
    },
    '0107': {
        productName : 'Extracto visa',
        templateCode : "TSCS_01"
    },
    '0008': {
        productName : 'Documento de domiciliaciones',
        templateCode : "TSAR_01"
    },
    '0006': {
        productName : 'Extracto unificado',
        templateCode : "TSIS_01"
    },
    '301007': welcomeAccountData, // ES
    '301014': welcomeAccountData, // DE
    '301017': welcomeAccountData, // NL
    '301019': welcomeAccountData, // PT
    '301010': {
        productName: 'Welcome payroll account',
        templateCode : "TCD_01"
    },
    '506020': { // DE
        productName: 'Я42 Metal Card',
        templateCode : "TC_04"
    },
    '506021': { // NL
        productName: 'Я42 Metal Card',
        templateCode : "TC_04"
    },
    '506022': { // PT
        productName: 'Cartão de Débito Я42 Metal',
        templateCode : "TC_04"
    },
}

module.exports = {
    EMAILCONSTANTS
}
