require('./LastDaysLabel.scss');

// @ vendor
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

class LastDaysLabel extends React.Component {

	render() {
        const {
            color,
            enabled,
            iconColor,
            iconEnabled,
            iconFont,
            isBannerC,
            size,
            text,
            textColor,
        } = this.props;
        let content = null;

        if (enabled) {
            let icon;
            let tagStyle = {};
            let tagTextStyle = {
                color: textColor,
            };

            if (!!color) {
                tagStyle.borderColor = color;
                tagStyle.color = color;
                tagTextStyle.backgroundColor = color;
                tagTextStyle.borderColor = color;
            }

            if (iconEnabled) {
                let iconStyle = {};

                if (!!iconColor) {
                    iconStyle.color = iconColor;
                }
                icon = <i className={`last-days-label__icon last-days-label__icon--${size} ${iconFont}`} style={iconStyle} />;
            }

            const contentClasses = classNames('last-days-label', {
                'last-days-label--banner-c': isBannerC,
            });

            content = (
                <div className={contentClasses}>
                    <div className={`last-days-label__tag last-days-label__tag--${size}`} style={tagStyle}>
                        <div
                            className={`last-days-label__tag-content last-days-label__tag-content--${size}`}
                            style={tagTextStyle}
                        >   
                            {icon}
                            <span className={`last-days-label__tag-text last-days-label__tag-text--${size}`}>
                                {text}
                            </span>
                        </div>
                    </div>
                </div>
            );
        }

        return content;
    }
}

LastDaysLabel.propTypes = {
    color: PropTypes.string,
    enabled: PropTypes.bool,
    iconColor: PropTypes.string,
    iconEnabled: PropTypes.bool,
    iconFont: PropTypes.string,
    isBannerC: PropTypes.bool,
    size: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
};

LastDaysLabel.defaultProps = {
    enabled: false,
    iconColor: '',
    iconEnabled: false,
    iconFont: '',
};

module.exports = LastDaysLabel ;
