require('./wizardSuccess.scss');
const PropTypes = require('prop-types');
// @ vendors
const React = require('react');
const { withRouter } = require('react-router-dom');
const classnames = require('classnames');
const PureRenderMixin = require('react-addons-pure-render-mixin');
// @ commons
const { OKButton } = require('components/commons/OKButton/OKButton');
const OKTag = require('components/commons/OKTag/OKTag');
const { OKBoxTitle } = require('commonsComponents/OKBoxTitle');
// @ constants
const { recordProcess, PROC_END } = require('utilities/tagging');
const {
  OPERATION_LOG_TYPE_FINISH,
  OPERATION_LOG_DONE,
} = require('utilities/contactCenter/wrapUp/constants');
const { LINK_CLICK_ACTION_EVENT } = require('constants/tagging');
// @ utils
import { clickTaleStart, clickTaleEnd } from 'utilities/clickTale';
const { traceFlowEvent } = require('utilities/contactCenter/wrapUp/wrapUpCore');
const { recordLink } = require('utilities/tagging');
const { isString } = require('utilities/stringHelper');


class WizardSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    this.handleMainButtonClick = this.handleMainButtonClick.bind(this);
    this.handleSecondaryButtonClick = this.handleSecondaryButtonClick.bind(this);
  }

  componentDidMount() {
    const { notifyWrapUp, onComponentDidMountCallback, eventTag, isSecondVersion } = this.props;
    let processCustomData = {};

    if (!!this.props.processDetail && eventTag) {
      processCustomData.eventTag = this.props.processDetail;
    } else if (!!this.props.processDetail) {
      processCustomData.processDetail = this.props.processDetail;
    }

    if (!!this.props.productName) {
      processCustomData.productName = this.props.productName;
    }

    processCustomData = { ...processCustomData, ...this.props.tealium };

    clickTaleStart();
    recordProcess(this.props.processType, PROC_END, processCustomData, isSecondVersion);

    if (__CONTACT_CENTER__ && notifyWrapUp) {
      traceFlowEvent.notify(OPERATION_LOG_TYPE_FINISH, OPERATION_LOG_DONE);
    }
    onComponentDidMountCallback();
  }

  componentWillUnmount() {
    clickTaleEnd();
  }

  handleMainButtonClick() {
    const {
      history,
      onMainButtonClick,
      mainButton: { url, label },
      processDetail,
    } = this.props;

    recordLink(processDetail + ' exito' || '', LINK_CLICK_ACTION_EVENT,isString(label) && label.toLowerCase());

    return onMainButtonClick ? onMainButtonClick() : url ? history.push(url) : history.goBack();
  }

  handleSecondaryButtonClick() {
    const {
      history,
      onSecondaryButtonClick,
      secondaryButton: { url, replaceUrl },
    } = this.props;
    if (onSecondaryButtonClick) {
      return onSecondaryButtonClick();
    }

    const shouldReplace = !!replaceUrl;

    if (url) {
      shouldReplace ? history.replace(url) : history.push(url);
    } else {
      history.goBack();
    }
  }

  render() {
    const {
      iconModifier,
      successMessage,
      successSubMessage,
      successTagLabelSubMessage,
      customClassName,
      children,
      mainButton,
      secondaryButton,
      successMessageChildren,
      secondaryButtonStyle,
      fullWidthWizardStep,
    } = this.props;
    const classNames = classnames('wizard-success-step', customClassName);
    const iconClassNames = classnames('wizard-success-step__success-icon', {
      [`wizard-success-step__success-icon--${iconModifier}`]: !!iconModifier,
      'wizard-success-step-full-width': __CONTACT_CENTER__ && fullWidthWizardStep,
    });

    let myMainButton;
    let mySecondaryButton;
    let myButtons;
    let successTag;
    let subMessage;

    if (mainButton.label) {
      let isPrimaryButtonLoading = false;
      
      if (mainButton.loading) {
        isPrimaryButtonLoading = mainButton.loading;
      }

      myMainButton = (
        <div className="wizard-success-step__main-button">
          <OKButton
            id="lnk_wizardSuccessStep_mainButton"
            modifier={mainButton.modifier ? mainButton.modifier : 'link-complementary'}
            onClick={this.handleMainButtonClick}
            loading={isPrimaryButtonLoading}
          >
            {mainButton.label}
          </OKButton>
        </div>
      );
    }

    if (secondaryButton.label) {
      let secondaryButtonModifier = __CONTACT_CENTER__ ? 'secondary' : 'ghost';
      let isSecondaryButtonLoading = false;

      if (secondaryButtonStyle) {
        secondaryButtonModifier = secondaryButtonStyle;
      }

      if (secondaryButton.loading) {
        isSecondaryButtonLoading = secondaryButton.loading;
      }

      mySecondaryButton = (
        <div className="wizard-success-step__secondary-button">
          <OKButton
            id="lnk_wizardSuccessStep_secondaryButton"
            modifier={secondaryButtonModifier}
            onClick={this.handleSecondaryButtonClick}
            loading={isSecondaryButtonLoading}
          >
            {secondaryButton.label}
          </OKButton>
        </div>
      );
    }

    if (myMainButton || mySecondaryButton) {
      myButtons = (
        <div className="wizard-success-step__footer">
          {!__CONTACT_CENTER__ && <div className="wizard-success-step__separator" />}
          <div className="wizard-success-step__foot-buttons">
            {myMainButton} {mySecondaryButton}
          </div>
        </div>
      );
    }

    if (!!successTagLabelSubMessage) {
      successTag = (
        <div className="wizard-success-step__success-tag-label-message">
          <OKTag
            content={successTagLabelSubMessage}
            type="success"
            emphasis={true}
            context="arrow"
          />
        </div>
      );
    }

    if (successSubMessage || successTag) {
      subMessage = (
        <div className="wizard-success-step__success-sub-message">
          {successSubMessage}
          {successTag}
        </div>
      );
    }

    return (
      <div className={classNames}>
        <div className="wizard-success-step__highlight">
          <i className={iconClassNames} />
          <div className="wizard-success-step__messages">
            <OKBoxTitle value={successMessage}>{successMessageChildren}</OKBoxTitle>
            {subMessage}
          </div>
        </div>
        <div className="wizard-success-step__content">{children}</div>
        {myButtons}
      </div>
    );
  }
}

WizardSuccess.defaultProps = {
  eventTag: true,
  iconModifier: '',
  mainButton: {},
  secondaryButton: {},
  customClassName: '',
  processDetail: null,
  processType: null,
  productName: null,
  successMessageChildren: null,
  secondaryButtonStyle: '',
  fullWidthWizardStep: false,
  notifyWrapUp: true,
  onComponentDidMountCallback: () => {},
  tealium: {},
  isSecondVersion: false,
};

WizardSuccess.propTypes = {
  eventTag: PropTypes.bool,
  iconModifier: PropTypes.string,
  successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  successSubMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  successTagLabelSubMessage: PropTypes.string,
  customClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mainButton: PropTypes.object,
  processDetail: PropTypes.string,
  processType: PropTypes.string,
  productName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondaryButton: PropTypes.object,
  secondaryButtonStyle: PropTypes.string,
  successMessageChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onMainButtonClick: PropTypes.func,
  fullWidthWizardStep: PropTypes.bool,
  onSecondaryButtonClick: PropTypes.func,
  notifyWrapUp: PropTypes.bool,
  onComponentDidMountCallback: PropTypes.func,
  history: PropTypes.object.isRequired,
  tealium: PropTypes.object,
  isSecondVersion: PropTypes.bool,
};

module.exports = withRouter(WizardSuccess);
