const { NO_ERROR } = require('constants/index');

const ERRORS = {
    'F0_0002&&&NO HAY CONTRATOS VISIBLES DE LA PERSONA': {
        'default': NO_ERROR
    },
    'NINGUN REGISTRO CUMPLE LA CONDICION DE BUSQUEDA': {
        'cardsInactive': NO_ERROR,
        'emitedTransfers': NO_ERROR,
        'receivedTransfers': NO_ERROR,
        'accountCheques': NO_ERROR,
        'accountChequebook': NO_ERROR,
        'accountDebits': NO_ERROR,
        'accountTransactions': NO_ERROR,
        'mortgageRequestUnpaidFeesDetail': NO_ERROR
    },
    'NO HAY MAS FILAS QUE CUMPLAN LA CONDICION DE BUSQUEDA': {
        'receivedTransfers': NO_ERROR,
        'emitedTransfers': NO_ERROR,
        'default': NO_ERROR
    },
    'XX9511&&&NINGUN REGISTRO CUMPLE LA CONDICION DE BUSQUEDA': {
        'cardsInactive': NO_ERROR,
        'default': NO_ERROR
    },
    'EL IMPORTE FINAL DEL TRAMO DE BUSQUEDA NO ES VALIDO O NO ESTA INFORMADO': {
        'emitedTransfers': NO_ERROR
    },
    'ERROR TECNICO. CONTACTE CON SU BANCO .': {
        'emitedTransfers': NO_ERROR
    },
    'LA TARJETA NO TIENE LIMITES ASOCIADOS': {
        'cardInformation': NO_ERROR
    },
    'INFORMACION NO DISPONIBLE': {
        'loansAndMortgagesMovements': NO_ERROR
    },
    'TIPO DE PRESTAMO NO PERMITIDO PARA ESTA OPERATIVA': {
        'loanSettlements': NO_ERROR
    }
};

module.exports = ERRORS;
