module.exports = {
    DOCUMENT_PENDING_REVISION: 'RVP',
    DOCUMENT_REQUIRED_NOT_SENT: 'RNE', // Other values could be RE, NR
    DOCUMENT_TYPE_DNI: 'DNI',
    DOCUMENT_TYPE_DNI_VALUES: ['N', 'DA', 'DN', 'CC'],
    DOCUMENT_TYPE_NIE_NOT_UE: 'NIE_NOT_UE',
    DOCUMENT_TYPE_NIE_UE: 'NIE_UE',
    DOCUMENT_TYPE_NIE_VALUES: 'C',
    DOCUMENT_TYPE_PASSPORT: 'PASSPORT',
    DOCUMENT_TYPE_PASSPORT_VALUES: ['P', 'PA', 'PN', 'PP', 'I'],
    MAX_SIZE_FILE: 3584,
    VALID_TYPES_FILE: ['jpg','jpeg','png'],
    VALID_TYPES_FILE_ERROR_MESSAGE: ['jpg', 'jpeg', 'png'],
}
