const { getLanguage } = require('core/i18n').i18n;

let localLang = getLanguage();

const image1 = require(`assets/images/tutorials/${ localLang }/general2/slide1.svg`);
const image2 = require(`assets/images/tutorials/${ localLang }/general2/slide2.svg`);

const images = [image1, image2];

module.exports = images;
