const PropTypes = require('prop-types');
// @vendors
const React = require('react');

// @helpers
const { formatText } = require('core/i18n').i18n;
const { toCamelCase } = require('utilities/stringHelper');

const FormattedTextWithComponents = props => {
    const { value, language, format, id, injectedComponents, className } = props;
    const translation = formatText(value, [], language);
    let translationFormatted;

        // Duplicated from FormattedText
        switch (format) {
            case 'uppercase':
                translationFormatted = translation.toUpperCase();
                break;
            case 'camelCase':
                translationFormatted = toCamelCase(translation);
                break;
            case 'firstUppercase':
                translationFormatted =
                    translation.charAt(0).toUpperCase() + translation.substr(1).toLowerCase();
                break;
            case 'lowercase':
                translationFormatted = translation.toLowerCase();
                break;
            default:
                translationFormatted = translation;
                break;
        }

    const splitMessage = translationFormatted.split('%s');
    const replacementsCount = (translationFormatted.match(/%s/g) || []).length;
    const lastMessage = splitMessage.length - 1;
    const result = splitMessage.map((message, index) => {
        const conditionalReplacement = (
            __CONTACT_CENTER__
            ? index < replacementsCount && injectedComponents[index]
            : injectedComponents[index]
        )
        const replacement =
            conditionalReplacement ?
            injectedComponents[index] :
            lastMessage !== index ? '%s' : '';

        return (
            <span key={index}>
                {message}
                {replacement}
            </span>
        );
    });

    return (
        <span className={className} id={id}>{result}</span>
    );
};

FormattedTextWithComponents.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    injectedComponents: PropTypes.array,
    value: PropTypes.string,
    format: PropTypes.string,
    language: PropTypes.string
};

FormattedTextWithComponents.defaultProps = {
    injectedComponents: []
};

module.exports = FormattedTextWithComponents;
