const isEmpty = require('lodash/lang/isEmpty');
const forOwn = require('lodash/object/forOwn');
let config = require('./environmentConfig');
const { filterPotentiallyXSS } = require('commonsUtilities/securityHelper');
const { getLanguage, formatText } = require('core/i18n').i18n;

/**
 * Replaces the parameters in the url for its values
 *
 * @example
 * buildUrl('http://localhost/users/{id}', {id: 123}); => 'http://localhost/users/123';
 */

const getPublicSiteUrl = config.getPublicUrl;

function buildUrl(url, params) {
    if (!params || isEmpty(params)) {
        return url;
    }

    let urlWithParams = url;

    forOwn(params, (paramValue, paramName) => {
        urlWithParams = urlWithParams.replace(`{${paramName}}`, paramValue);
    });

    return urlWithParams;
}

/**
 * It adds a trailing slash if there isn't one
 */
function normalizeHost(host) {
    if (host.substr(-1) !== '/') {
        return host + '/';
    }
    return host;
}

/**
 * It removes the slash in the front of the endpoint if there is one
 */
function normalizeEndpoint(endpoint) {
    if (endpoint.substr(0, 1) === '/') {
        return endpoint.substr(1);
    }
    return endpoint;
}

function isMocked(endpointName) {
    return (
        config.services.mockAllEndpoints || config.services.mockedEndpoints.includes(endpointName)
    );
}

function setCustomConfig(_config) {
    config = _config;
}

function getHost(host) {
    return config.services.hosts[host];
}

function getOriginSourceByHost() {
    const abbreviationsByHost = {
        "DEV": "dev_",
        "QA": "qa_",
        "STAGING": "stg_",
        "OCULTO": "ocu_",
        'PRODUCCION': ''
    }

    const abbreviatedHost = abbreviationsByHost[window.__ENV__]
    const baseOriginSource = 'frontapi_priv-docs'
    return `${abbreviatedHost}${baseOriginSource}`
}

function getHostForEndpoint(endpointName, forcePrivateApiWhenCC = false) {
    if (isMocked(endpointName)) {
        return config.services.hosts['MOCK'];
    } else if (config.services.redirectedEndpoints[endpointName]) {
        // Check if endpoint is being redirected
        const redirectedHost = config.services.redirectedEndpoints[endpointName];
        return config.services.hosts[redirectedHost];
    } else if (config.services.localHostsByEndpoint[endpointName]) {
        return config.services.localHostsByEndpoint[endpointName]
    } else {
        //We allow to set an URL as baseHost, not just one of the local hosts
        if (__CONTACT_CENTER__ && forcePrivateApiWhenCC) {
            return config.baseHostPrivada;
        }
        return config.services.hosts[config.baseHost] || config.baseHost;
    }
}

const getPublicSitePath = (pathName, params) => {
    if (params && !params.lang) {
        params.lang = getLanguage();
    }
    const host = getPublicSiteUrl();
    const endpoint = config.publicPaths[pathName];

    const url = normalizeHost(host) + normalizeEndpoint(endpoint);

    return buildUrl(url, params);
};

const getPublicSitePathByLanguaje = (pathNamePOEKey, params) => {
    if (params && !params.lang) {
        params.lang = getLanguage();
    }
    const host = getPublicSiteUrl();
    const endpoint = formatText(pathNamePOEKey);

    const url = normalizeHost(host) + normalizeEndpoint(endpoint);

    return buildUrl(url, params);
};

/**
 * @param {string} endpointName Name of the endpoint
 * @param {object} params Object with the data needed to create the api url
 * @example { paramName: paramValue }
 */
function getURL(endpointName, params, privateApiWhenCC = false) {
    const endpoint = config.services.endpoints[endpointName];

    if (!endpoint || isEmpty(endpoint)) {
        throw new Error(`The endpoint ${endpointName} has not been found in the configuration`);
    }

    const host = getHostForEndpoint(endpointName, privateApiWhenCC);

    const url = normalizeHost(host) + normalizeEndpoint(endpoint);

    const urlWithParams = filterPotentiallyXSS(buildUrl(url, params));

    if (urlWithParams.match(/{\w+}/g) && urlWithParams.match(/{\w+}/g).length > 0) {
        // if we still have a {} in the url, it means that some of the params hasn't
        // been sent with values. The url was not successfully created.
        // throw new EndpointNotCreated(url, params, endpoint);

        var missingParams = urlWithParams.match(/{\w+}/g).join(' ,');
        throw new Error(
            `The api url "${url}" could not be created since there are paramters missing: ${missingParams}`
        );
    }

    return urlWithParams;
}

module.exports = {
    getURL,
    isMocked,
    setCustomConfig,
    getHost,
    getPublicSiteUrl,
    getPublicSitePath,
    getPublicSitePathByLanguaje,
    getOriginSourceByHost
};
