const iconInsuranceAdvantage1 = require('assets/icons/icon-insurance-advantage1.svg');
const iconInsuranceAdvantage2 = require('assets/icons/icon-insurance-advantage2.svg');
const iconInsuranceAdvantage3 = require('assets/icons/icon-insurance-advantage3.svg');
const iconInsuranceAdvantage4 = require('assets/icons/icon-insurance-advantage4.svg');

const INSURANCES_PRODUCT_BANNERS = {
    insuranceHome: {
        id: 'insurance_home',
        title: 'bannerInsurances-title',
        subtitle: 'bannerInsurances-subtitle',
        sidebarImgUrl: require('../../assets/images/hogar-image-card.jpg'),
        advantages: [
            {
                text: 'bannerInsurances-advantage01',
                icon: 'icon-estrategia'
            },
            {
                text: 'bannerInsurances-advantage02',
                icon: 'icon-asistencia'
            },
            {
                text: 'bannerInsurances-advantage03',
                icon: 'icon-cerrajero'
            },
            {
                text: 'bannerInsurances-advantage04',
                icon: 'icon-manitas'
            }
        ],
        link: 'bannerInsurances-insuranceHomeLink', //myprofile/insurances/home/request
        promotionPosition: '1',
    },
    roboMovil: {
        id: 'robo_movil',
        title: 'bannerInsurances-bannerInsurances_roboMovilTitle',
        subtitle: 'bannerInsurances-bannerInsurances_roboMovilSubtitle',
        sidebarImgUrl: require('../../assets/images/robo-movil-card.jpg'),
        advantages: [
            {
                text: 'bannerInsurances-bannerInsurances_roboMovilAdvantage01',
                icon: 'icon-estrategia',
            },
            {
                text: 'bannerInsurances-bannerInsurances_roboMovilAdvantage02',
                icon: 'icon-movil',
            },
            {
                text: 'bannerInsurances-bannerInsurances_roboMovilAdvantage03',
                icon: 'icon-global',
            },
            {
                text: 'bannerInsurances-bannerInsurances_roboMovilAdvantage04',
                icon: 'icon-calcular',
            }
        ],
        link: 'bannerInsurances-bannerInsurances_roboMovilLink',
        promotionPosition: '2',
    },
    insuranceLife: {
        id: 'insurance_life',
        title: 'bannerInsurances-insuranceLifeTitle',
        subtitle: 'bannerInsurances-insuranceLifeSubtitle',
        sidebarImgUrl: require('../../assets/images/WEB_oblea_seguro_vida.jpg'),
        advantages: [
            {
                text: 'bannerInsurances-insuranceLifeAdvantage01',
                icon: iconInsuranceAdvantage1,
                iconType: 'SVG',
            },
            {
                text: 'bannerInsurances-insuranceLifeAdvantage02',
                icon: iconInsuranceAdvantage2,
                iconType: 'SVG',
            },
            {
                text: 'bannerInsurances-insuranceLifeAdvantage03',
                icon: iconInsuranceAdvantage3,
                iconType: 'SVG',
            },
            {
                text: 'bannerInsurances-insuranceLifeAdvantage04',
                icon: iconInsuranceAdvantage4,
                iconType: 'SVG',
            }
        ],
        link: 'bannerInsurances-insuranceLifeLink',
        promotionPosition: '3',
    },
};

module.exports = INSURANCES_PRODUCT_BANNERS;
