// @ vendor
const React = require('react');
const PropTypes = require('prop-types');

// @commons
const OKSelectCommon = require('commonsComponents/OKSelect');

// @utilities
const { FormattedText, formatText, hasTranslation } = require('core/i18n').i18n;

class OKSelect extends React.Component  {

    checkTranslation(translationKey, getObject = false) {
        let translation = translationKey;

        if (typeof translationKey === 'string' && hasTranslation(translationKey)) {
            if (getObject) {
                translation = <FormattedText value={translationKey} />;
            } else {
                translation = formatText(translationKey);
            }
        }

        return translation;
    }

    render() {
        let {
            label,
            errorMessage,
            noResultsText,
            tooltipContent,
            tooltipTitle,
            optionalElement,
            placeholder,
        } = this.props;
        let okSelectProps = Object.assign({}, this.props);

        okSelectProps.label = this.checkTranslation(label);
        okSelectProps.errorMessage = this.checkTranslation(errorMessage);
        okSelectProps.noResultsText = this.checkTranslation(noResultsText);
        okSelectProps.tooltipContent = this.checkTranslation(tooltipContent, true);
        okSelectProps.tooltipTitle = this.checkTranslation(tooltipTitle);
        okSelectProps.optionalElement = this.checkTranslation(optionalElement, true);
        okSelectProps.placeholder = this.checkTranslation(placeholder);

        return (
            <OKSelectCommon
                {...okSelectProps}
            />
        );
    }
}

OKSelect.propTypes = {
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    noResultsText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onOpen: PropTypes.func,
    tooltipContent: PropTypes.any,
    tooltipTitle: PropTypes.string,
    optionalElement: PropTypes.object,
    placeholder: PropTypes.string,
};

OKSelect.defaultProps = {
    noResultsText: "common-selectNoResults",
    onChange: () => {},
    placeholder: "common-selectPlaceholder",
};

module.exports = OKSelect;