require('./mortgagesCollectiveTagTooltip.scss');

// @ vendors
const PropTypes = require('prop-types');
const React = require('react');

//@core
const { FormattedText } = require('core/i18n').i18n;

// @ helpers
const { formatPercentage } = require('utilities/currencyHelper');
const MortgageRequestCurrencyHelp = require('components/mortgageRequest/mortgageRequestCurrencyHelp/mortgageRequestCurrencyHelp');

// @constants
const { EURO_TEXT } = require('constants/index');
const { MORTGAGE_TYPE: { FIJO, MIXTO, VARIABLE } } = require('constants/mortgageRequestType');

const buildFixedContent = (monthlyFee, tin, tae) => {
    return (
        <React.Fragment>
            <li>
                <FormattedText
                    className="mortages-collective-tag-tooltip__text--bold"
                    value={"collectives-collectives_mortgageInfoMonthlyFees"}
                    injectedStrings={[monthlyFee]}
                />
            </li>
            <li>
                <FormattedText value={"collectives-collectives_mortgageInfoTin"} />
                <FormattedText
                    className="mortages-collective-tag-tooltip__text--bold"
                    value={"collectives-collectives_mortgageInfoTinValue"}
                    injectedStrings={[tin]}
                />
            </li>
            <li>
                <FormattedText value={"collectives-collectives_mortgageInfoTae"} />
                <FormattedText
                    className="mortages-collective-tag-tooltip__text--bold"
                    value={"collectives-collectives_mortgageInfoTaeValue"}
                    injectedStrings={[tae]}
                />
            </li>
        </React.Fragment>
    );
}

const buildMixOrVaribleContent = (monthlyFee, tin, tae) => {
    return (
        <React.Fragment>
            <li>
                <FormattedText
                    className="mortages-collective-tag-tooltip__text--bold"
                    value={"collectives-collectives_mortgageInfoMonthlyFees"}
                    injectedStrings={[monthlyFee]}
                />
            </li>
            <li>
                <FormattedText value={"collectives-collectives_mortgageInfoTin"} />
                <FormattedText
                    className="mortages-collective-tag-tooltip__text--bold"
                    value={"collectives-collectives_mortgageInfoTinValue"}
                    injectedStrings={[tin]}
                />
            </li>
            <li>
                <FormattedText value={"collectives-collectives_mortgageInfoTaeVariable"} />
                <FormattedText
                    className="mortages-collective-tag-tooltip__text--bold"
                    value={"collectives-collectives_mortgageInfoTaeVariableValue"}
                    injectedStrings={[tae]}
                />
            </li>
        </React.Fragment>
    )
}

const buildDefaultContent = (euribor, tae) => {
    return (
        <React.Fragment>
            <li>
                <FormattedText
                    className="mortages-collective-tag-tooltip__text"
                    value={"collectives-collectives_mortgageInfoEuribor"}
                />
                <FormattedText
                    className="mortages-collective-tag-tooltip__text--bold"
                    value={"collectives-collectives_mortgageInfoEuriborValue"}
                    injectedStrings={[euribor]}
                />
            </li>
            <li>
                <FormattedText
                    className="mortages-collective-tag-tooltip__text"
                    value={"collectives-collectives_mortgageInfoTaeVariableDefault"}
                />
                <FormattedText
                    className="mortages-collective-tag-tooltip__text--bold"
                    value={"collectives-collectives_mortgageInfoTaeValue"}
                    injectedStrings={[tae]}
                />
            </li>
        </React.Fragment>
    );
}

const MortgagesCollectiveTagTooltip = props => {
    const { mortgageType, taxes: { euribor, tin, tae }, monthlyFee } = props;
    const monthlyFeeParams = {
        currency: monthlyFee ? monthlyFee : 0,
        symbol: EURO_TEXT
    };
    const formattedMonthlyFee = MortgageRequestCurrencyHelp(monthlyFeeParams);
    const tinValue = !!tin && typeof tin === 'object' ? tin.variable : tin;
    const formattedTin = !!tinValue ? formatPercentage(tinValue.replace('%', '')) : '';
    const formattedTae = !!tae ? formatPercentage(tae.replace('%', '')) : '';

    let content;

    switch (mortgageType) {
        case FIJO:
            content = buildFixedContent(formattedMonthlyFee, formattedTin, formattedTae);
            break;
        case MIXTO:
            content = buildMixOrVaribleContent(formattedMonthlyFee, formattedTin, formattedTae);
            break;
        case VARIABLE:
            content = buildMixOrVaribleContent(formattedMonthlyFee, formattedTin, formattedTae);
            break;
        default: // no mortgae type selected yet
            content = buildDefaultContent(euribor, formattedTae);
    }

    return (
        <ul className="mortages-collective-tag-tooltip">
            {content}
        </ul>
    );

}

MortgagesCollectiveTagTooltip.propTypes = {
    mortgageType: PropTypes.string,
    taxes: PropTypes.object,
    monthlyFee: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

module.exports = MortgagesCollectiveTagTooltip;
