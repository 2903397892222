require('./productBannerHeader.scss');
const PropTypes = require('prop-types');
//@vendor
const React = require('react');
const get = require('lodash/object/get');
const classNames = require('classnames');

//@commons
const { OKButton } = require('components/commons/OKButton/OKButton');
const ModalAbort = require('components/commons/modalAbort/modalAbort');

//@core
const { FormattedText, hasTranslation } = require('core/i18n').i18n;

//@utilities
const { recordLinkV2 } = require('utilities/tagging');

class ProductBannerHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };

        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.handleAcceptModal = this.handleAcceptModal.bind(this);
        this.handleCancelModal = this.handleCancelModal.bind(this);
    }

    componentDidMount() {
        const { analyticsTagView } = this.props;
        analyticsTagView && recordLinkV2(analyticsTagView);
    }

    buildButton() {
        const { button } = this.props;
        let content = null;

        if (button) {
            const rawText = button.text || '';
            const text = this.buildTranslate(rawText);
            const link = button.link || '';
            const modifier = button.modifier || 'secondary';
            let actionableElement;

            if (button.isOKButton && button.callback) {
                const handleButtonClick = button.callback;

                actionableElement = (
                    <OKButton modifier={modifier} onClick={handleButtonClick}>
                        {text}
                    </OKButton>
                );
            } else {
                actionableElement = (
                    <OKButton modifier={modifier} to={link}>
                        {text}
                    </OKButton>
                );
            }

            content = (
                <div className="product-banner-header__button">
                    {actionableElement}
                </div>
            );
        }

        return content;
    }

    buildCustomButton() {
        const { isOpen } = this.state;
        const { customButton } = this.props;
        const text = this.buildTranslate(get(customButton, 'text'));
        const hasConfirmation = get(customButton, 'confirmation');
        const confirmationMessage = get(customButton, 'confirmationMessage');
        const modifier = get(customButton, 'modifier') || 'secondary';
        const handleAnalyticsTag = get(customButton, 'handleAnalyticsTag');
        const handleClickWithModal = () => {
            if (handleAnalyticsTag) {
                handleAnalyticsTag();
            }
            this.handleToggleModal();
        };
        const onClick = hasConfirmation ? handleClickWithModal : get(customButton, 'onAccept');

        const modalAbort = hasConfirmation ? (
            <ModalAbort
                isOpen={isOpen}
                onAccept={this.handleAcceptModal}
                onCancel={this.handleCancelModal}
            >
                {confirmationMessage}
            </ModalAbort>
        ) : null;

        return customButton ? (
            <div className="product-banner-header__custom-container">
                <OKButton modifier={modifier}
                    onClick={onClick}>
                    {text}
                </OKButton>
                {modalAbort}
            </div>
        ) : null;
    }

    buildImage() {
        const { image } = this.props;

        let content = null;

        if (image) {
            const iconClass = classNames('product-banner-header__icon', 'icon', image);
            content = (
                <i className={iconClass}></i>
            );
        }

        return content;
    }

    buildMessages() {
        const { messages = [] } = this.props;

        return messages.map((message, index) => {
            const messageClass = classNames(
                'product-banner-header__message',
                message.className
            );

            const text = this.buildTranslate(message.text);

            return (
                <div key={'productBannerHeaderMessage-' + index} className={messageClass}>
                    {text}
                </div>
            )
        });
    }

    buildSubtitle() {
        const { subtitle } = this.props;
        const text = this.buildTranslate(subtitle);

        return subtitle ? (
            <div className="product-banner-header__subtitle">
                {text}
            </div>
        ) : null;
    }

    buildTitle() {
        const { title } = this.props;
        const icon = get(title, 'icon');

        const text = this.buildTranslate(get(title, 'text'));

        const iconContainer = icon ? (
            <i className={'icon ' + icon} />
        ) : null;

        return title ? (
            <div className="product-banner-header__title">
                {iconContainer}
                {text}
            </div>
        ) : null;
    }

    buildTranslate(rawText) {
        let text = rawText;

        if (hasTranslation(rawText)) {
            text = <FormattedText value={rawText} noWrapper />;
        }

        return text;
    }

    handleAcceptModal() {
        const { customButton } = this.props;
        const onAccept = get(customButton, 'onAccept');

        if (onAccept) {
            onAccept();
        }

        this.handleToggleModal();
    }

    handleCancelModal() {
        const { customButton } = this.props;
        const onCancel = get(customButton, 'onCancel');

        if (onCancel) {
            onCancel();
        }

        this.handleToggleModal();
    }

    handleToggleModal() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        const { badge } = this.props;
        const title = this.buildTitle();
        const image = this.buildImage();
        const subtitle = this.buildSubtitle();
        const messages = this.buildMessages();
        const button = this.buildButton();
        const customButton = this.buildCustomButton();

        return (
            <div className="product-banner-header__card">
                <div className="product-banner-header__container">
                    {badge}
                    {title}
                    <div className="product-banner-header__body">
                        {image}
                        {subtitle}
                        {messages}
                        {button}
                        {customButton}
                    </div>
                </div>
            </div>
        );
    }
}

ProductBannerHeader.propTypes = {
    badge: PropTypes.object,
    title: PropTypes.object,
    image: PropTypes.string,
    subtitle: PropTypes.string,
    messages: PropTypes.array,
    button: PropTypes.object,
    customButton: PropTypes.object,
    analyticsTagView: PropTypes.object
};

module.exports = ProductBannerHeader;
