// @vendors
const React = require('react');
const PropTypes = require('prop-types');

// @helpers
const { formatText, getLanguage } = require('core/i18n').i18n;
const { getLastNChars } = require('utilities/stringHelper');
const { formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');

// @components
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');

class StocksContractDropdownValue extends React.Component {
    render() {
        const {walletAccountType, walletNumber, walletBalance} = this.props.value;
        const plainAmountObject = getPlainAmountObject(walletBalance);

        return (
            <div className="stocks-contract-dropdown__value" onMouseDown={this.handleMouseDown}>
                <span className="stocks-contract-dropdown__value--highlighted">{walletAccountType}</span>
                <span className="stocks-contract-dropdown__value--medium">{getLastNChars(walletNumber)}</span>

                <span className="stocks-contract-dropdown__value--right">
                    <span className="stocks-contract-dropdown__right-text-title">
                        {formatText('broker-stocksContractDropdownAssociatedAccountTransferValuesBalance')}
                    </span>
                    <span className="stocks-contract-dropdown__value--highlighted">
                        <OneLineSymbolAmount
                            amountObject={plainAmountObject}
                            language={getLanguage()}
                            amountObjectFormatted={formatAmountObject(plainAmountObject, true)}
                        />
                    </span>
                </span>
            </div>
        );
    }
}

StocksContractDropdownValue.propTypes = {
    value: PropTypes.object
};

module.exports = StocksContractDropdownValue;
