// @core
const { formatText } = require('core/i18n').i18n;

const PERIODIC_LOADS_MIN_AMOUNT_DEFAULT = 0;
const PERIODIC_LOADS_MAX_AMOUNT_DEFAULT = 9999999.99;
const PERIODIC_PERIODICITY_DEFAULT = 'all';
const PERIODICITY = {
    'all': 'cardPeriodicLoads-all',
    'Weekly': 'cardPeriodicLoads-weekly',
    'Monthly': 'cardPeriodicLoads-monthly',
    'Quarterly': 'cardPeriodicLoads-quarterly',
    'Half-Yearly': 'cardPeriodicLoads-halfYearly'
};

const getTypesOfPeriodicityOptions = () => {
    return Object.keys(PERIODICITY).map(key => {
        return {
            value: key,
            label: formatText(PERIODICITY[key]),
        }
    });
};


module.exports = {
    PERIODIC_LOADS_MIN_AMOUNT_DEFAULT,
    PERIODIC_LOADS_MAX_AMOUNT_DEFAULT,
    PERIODIC_PERIODICITY_DEFAULT,
    PERIODICITY,
    getTypesOfPeriodicityOptions
}
