require('./promotionBanner.scss');

// @vendors
const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const classNames = require('classnames');
const PropTypes = require('prop-types');

// @core
const { FormattedText, formatText } = require('core/i18n').i18n;

// @components
const { OKButton } = require('components/commons/OKButton/OKButton');
const OKCarousel = require('components/commons/OKCarousel/OKCarousel');
const LastDaysLabel = require('components/commons/LastDaysLabel/LastDaysLabel');
const LastDaysDate = require('components/commons/LastDaysDate/LastDaysDate');

// @commons
const { OKButtonCMS } = require('commonsComponents/OKButtonCMS');
const { HTMLSafeInjector } = require('commonsComponents/htmlSafeInjector');

// @utilities
const {
    doTaggingForCommercialAdsClick,
    formatRedirectUrl,
    immAdIsNotEmpty,
} = require('utilities/commercialAdsHelper');
const { decodeHTMLEntities } = require('utilities/stringHelper');

// @constants
const { COMMERCIAL_ADS_EMPTY_BANNER_URI } = require('constants/index');
const { ADOBE_INTERESTED_STATUS_CODE } = require('constants/adobe');
const PRIVATE_HOLLOW_COMMERCIAL_INTERACTION = 'interaccion_hueco_comercial_privada';
const { LAST_DAYS_CMS_TAG, ADOBE } = require('constants/flagBasedFeatures');

class PromotionBanner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imageStatus: {}
        };

        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    getBannerBodyBlock(id) {
        const { immCommercialAds } = this.props;
        const immAd = immCommercialAds.getIn(['byId', id]);
        return immAd.getIn(['body', 'value']);
    }

    getBannerClassByType(commercialDataType, stateClass) {
        return classNames(stateClass, {
            [`${stateClass}--banner-a`]: commercialDataType === 'banner-a',
            [`${stateClass}--banner-b`]: commercialDataType === 'banner-b',
            [`${stateClass}--banner-c`]: commercialDataType === 'banner-c',
            [`${stateClass}--banner-d`]: commercialDataType === 'banner-d',

        });
    }

    getEmptyBanner(commercialDataType, commercialDataLabel) {
        const { productAssociation } = this.props;
        const emptyStateClass = 'promotion-banner__empty-banner';
        const emptyCommercialAdsClass = this.getBannerClassByType(
            commercialDataType,
            emptyStateClass
        );
        const emptySecondaryTextBlock = this.getEmptyBannerSecondaryTextBlock(
            commercialDataType,
            commercialDataLabel
        );
        const emptyPrimaryText = `promotionBanner-empty${commercialDataLabel}PrimaryText`;
        const linkButtonType = 'promotion-banner__link-button';
        const linkButtonClassType = this.getBannerClassByType(commercialDataType, linkButtonType);
        const buttonLabel = `promotionBanner-empty${commercialDataLabel}ButtonLabel`;
        const bannerUri = formatText(`promotionBanner-empty${commercialDataLabel}URI`);
        const title = formatText(emptyPrimaryText);
        const targetBlank = false;

        const handleButtonRedirect = () => {
            this.handleRedirectButtonClick(bannerUri, targetBlank);
        };
        let buttonText = <FormattedText value={buttonLabel} />;

        return (
            <div
                id={productAssociation}
                className={emptyCommercialAdsClass}
            >
                <p className="promotion-banner__empty-banner--primary-text">
                    <FormattedText value={emptyPrimaryText} />
                </p>
                {emptySecondaryTextBlock}
                <div className={linkButtonClassType} title={title}>
                    <OKButton modifier="primary" size="small" onClick={handleButtonRedirect}>
                        {buttonText}
                    </OKButton>
                </div>
            </div>
        );
    }

    getEmptyBannerSecondaryTextBlock(commercialDataType, commercialDataLabel) {
        const emptySecondaryText = `promotionBanner-empty${commercialDataLabel}SecondaryText`;
        const secondaryTextClass = 'promotion-banner__empty-banner--secondary-text';

        const emptySecondaryTextClass = classNames(secondaryTextClass, {
            [`${secondaryTextClass}-medium`]: commercialDataType === 'banner-a',
            [`${secondaryTextClass}-short`]: commercialDataType === 'banner-b',
            [`${secondaryTextClass}-large`]: commercialDataType === 'banner-c',
            [`${secondaryTextClass}-medium`]: commercialDataType === 'banner-d',
        });

        return (
            <p className={emptySecondaryTextClass}>
                <FormattedText value={emptySecondaryText} />
            </p>
        );
    }

    buildBodyBlock(immAd, commercialDataId, commercialDataType) {
        const {
            immFlagBasedFeatures,
            productAssociation,
        } = this.props;
        const dataGtnscomponentName = productAssociation.replace(/-/g, ' ');
        const altValue = immAd.get('fieldAltText');
        const urlValue = immAd.getIn(['fieldImageLarge', 'url']);
        const bannerBodyBlock = this.getBannerBodyBlock(commercialDataId);
        const figureType = 'promotion-banner__figure-banners';
        const bannerClassType = this.getBannerClassByType(commercialDataType, figureType);
        const immLastDays = immAd.get('fieldLastDays');
        const immValidPeriod = immAd.get('fieldValidPeriod');
        const title = immAd.get('title');
        const isBannerC = commercialDataType === 'banner-c';
        let lastDaysDate;
        let lastDaysLabel;
        let lastDaysDateTop;
        let lastDaysDateMiddle;
        let lastDaysDateBottom;
        let titleHtml;
        let innerBody;
        let labelNotBannerC;

        if (!!title) {
            titleHtml = (
                <HTMLSafeInjector
                    message={title}
                />
            );
        }

        if (immFlagBasedFeatures.get(LAST_DAYS_CMS_TAG)) {
            if (!!immLastDays) {
                lastDaysLabel = (
                    <LastDaysLabel
                        color={immLastDays.get('color')}
                        date={immLastDays.getIn(['date', 'value'])}
                        dateEnd={immLastDays.getIn(['date', 'end_value'])}
                        enabled={immLastDays.get('enable')}
                        iconColor={immLastDays.getIn(['icon', 'color'])}
                        iconEnabled={immLastDays.getIn(['icon', 'enable'])}
                        iconFont={immLastDays.getIn(['icon', 'font_icon'])}
                        isBannerC={isBannerC}
                        size={immLastDays.get('size')}
                        text={immLastDays.get('text')}
                        textColor={immLastDays.get('text_color')}
                    />
                );
            }

            if (!!immValidPeriod) {
                lastDaysDate = (
                    <LastDaysDate
                        date={immValidPeriod.getIn(['date', 'value'])}
                        dateEnd={immValidPeriod.getIn(['date', 'end_value'])}
                        enabled={immValidPeriod.get('enable')}
                        iconEnabled={immValidPeriod.get('font_icon_enable')}
                        iconFont={immValidPeriod.get('font_icon')}
                        size={immValidPeriod.get('size')}
                        text={immValidPeriod.get('text')}
                    />
                );

                if (immValidPeriod.get('position') === 'top') {
                    lastDaysDateTop = lastDaysDate;
                } else if (immValidPeriod.get('position') === 'middle') {
                    lastDaysDateMiddle = lastDaysDate;
                } else if (immValidPeriod.get('position') === 'bottom') {
                    lastDaysDateBottom = lastDaysDate;
                }
            }
        }

        if (isBannerC) {
            innerBody = (
                <div className={bannerClassType}>
                    {lastDaysLabel}
                    {lastDaysDate}
                    {titleHtml}
                    <HTMLSafeInjector message={bannerBodyBlock} />
                </div>
            );
        } else {
            labelNotBannerC = lastDaysLabel;
            innerBody = (
                <div className={bannerClassType}>
                    {lastDaysDateTop}
                    {titleHtml}
                    {lastDaysDateMiddle}
                    <HTMLSafeInjector message={bannerBodyBlock} />
                    {lastDaysDateBottom}
                </div>
            );
        }

        let adId = immAd.getIn(['adId'])
        let imageStatus = this.state.imageStatus[adId]
        const innerBodyContent = imageStatus ? innerBody : null;

        return (
            <div
                id={productAssociation}
                data-gtns-component-name={dataGtnscomponentName}
                className="promotion-banner__figure-container public-cms-styles"
            >
                {labelNotBannerC}
                <img
                    className="promotion-banner__image"
                    alt={altValue}
                    src={urlValue}
                    onLoad={(event) => this.handleImageLoaded(event, immAd)}
                />
                {innerBodyContent}
                {this.buildButtonBlockContent(immAd, imageStatus)}
            </div>
        );
    }

    buildButtonBlockContent(immAd, imageStatus) {
        const { commercialData, immFlagBasedFeatures } = this.props;
        const commercialDataType = commercialData.type;
        let bannerUri = COMMERCIAL_ADS_EMPTY_BANNER_URI;
        let bannerUriTargetBlank;
        let buttonBlockContent = null;

        const buttonText = immAd.getIn(['fieldLink', 'title']);
        bannerUri = immAd.getIn(['fieldLink', 'uri']);
        bannerUriTargetBlank = immAd.getIn(['fieldLink', 'targetBlank']);
        bannerUri = bannerUriTargetBlank ? formatRedirectUrl(bannerUri) : bannerUri;
        const title = decodeHTMLEntities(immAd.get('title'));
        const buttonAlignment = immAd.getIn(['ctaDetails', 'aligment']);
        let linkButtonClassName = 'promotion-banner__link-button-right';

        if (buttonAlignment === 'center') {
            linkButtonClassName = 'promotion-banner__link-button-center';
        } else if (buttonAlignment === 'left') {
            linkButtonClassName = 'promotion-banner__link-button-left';
        }
        const linkButtonClassType = this.getBannerClassByType(
            commercialDataType,
            linkButtonClassName
        );
        const handleButtonRedirect = () => {
            doTaggingForCommercialAdsClick(immAd, immFlagBasedFeatures.get(ADOBE));
            this.handleRedirectButtonClick(bannerUri, bannerUriTargetBlank)
        };
        buttonBlockContent = (
            <div className={linkButtonClassType} title={title}>
                <OKButtonCMS
                    color={immAd.getIn(['fieldLink', 'color'])}
                    icon={immAd.getIn(['fieldLink', 'icon'])}
                    iconAlignment={immAd.getIn(['fieldLink', 'iconAlignment'])}
                    iconColor={immAd.getIn(['fieldLink', 'iconColor'])}
                    iconGhost={immAd.getIn(['fieldLink', 'iconGhost'])}
                    iconIsEnabled={immAd.getIn(['fieldLink', 'iconIsEnabled'])}
                    isOnlyIcon={immAd.getIn(['fieldLink', 'isOnlyIcon'])}
                    inverseGhost={immAd.getIn(['fieldLink', 'inverseGhost'])}
                    onClick={handleButtonRedirect}
                    size="small"
                    text={buttonText}
                />
            </div>
        );
        buttonBlockContent = imageStatus ? buttonBlockContent : null;
        return buttonBlockContent;
    }

    buildCarouselAds(ads, commercialDataType) {
        const {updateCommercialAdsStatus} = this.props;
        const blocks = ads.map((ad) => {
            let commercialDataId = ad.getIn(['adId'])
            return this.buildBodyBlock(ad, commercialDataId, commercialDataType);
        })
        const settings = {
            className: 'adobe-ads__carousel',
            dots: true
        };

        return (
            <div className="promotion-banner__adobe-ads">
                <OKCarousel
                    settings={settings}
                    blocks={blocks}
                    ads={ads}
                    cycleCarousel={true}
                    cycleTime={5000}
                    updateCommercialAdsStatus={updateCommercialAdsStatus}
                />
            </div>
        );
    }

    handleImageLoaded(evt, immAd) {
        evt.preventDefault();
        let currentState = Object.assign({}, this.state.imageStatus);
        currentState[immAd.getIn(['adId'])] = true;
        this.setState({ imageStatus: currentState });
    }

    handleRedirectButtonClick(uri, targetBlank = true) {
        const {
            commercialData,
            immCommercialAds,
            updateCommercialAdsStatus,
        } = this.props;
        const commercialDataId = commercialData.id;

        if (targetBlank) {
            window.open(uri, '_blank');
        } else {
            window.location.href = uri;
        }

        const immAd = immCommercialAds.getIn(['byId', commercialDataId]);

        // We need to run this AFTER any update in service is needed
        const callback = () => {
            if (targetBlank) {
                window.open(uri, '_blank');
            } else {
                window.location.href = uri;
            }
        }

        updateCommercialAdsStatus(immAd, ADOBE_INTERESTED_STATUS_CODE, callback)
    }

    render() {
        const { commercialData, immCommercialAds } = this.props;
        const commercialDataId = commercialData.id;
        const commercialDataType = commercialData.type;
        const commercialDataLabel = commercialData.label;
        const immAd = immCommercialAds.getIn(['byId', commercialDataId]);
        const promotionType = 'promotion-banner';
        const promotionClassType = this.getBannerClassByType(commercialDataType, promotionType);
        let bodyBlock = null;
        let immAds = immCommercialAds.getIn(['byId'])

        if (!immAd){
            return <div />
        }

        if (immAdIsNotEmpty(immAd)) {
            const offerSpaceName = immAd.get('offerSpaceName');
            let carouselAds;

            if (offerSpaceName){
                carouselAds = immAds.filter((currentImmAd) => currentImmAd.getIn(['offerSpaceName']) === offerSpaceName);
            }

            if (carouselAds && carouselAds.length > 1) {
                carouselAds = carouselAds.sort((ad1, ad2) => { return ad1.get('rank') - ad2.get('rank') });
                bodyBlock = this.buildCarouselAds(carouselAds, commercialDataType);
            } else {
                bodyBlock = this.buildBodyBlock(immAd, commercialDataId, commercialDataType);
            }
        } else if (immAd.get('contentNotFound')) {
            bodyBlock = this.getEmptyBanner(commercialDataType, commercialDataLabel);
        } else {
            return <div />
        }

        return (
            <div
                className={promotionClassType}
                data-gtns-component-name={PRIVATE_HOLLOW_COMMERCIAL_INTERACTION}
            >
                {bodyBlock}
            </div>
        );
    }
}
PromotionBanner.propTypes = {
    commercialData: PropTypes.object.isRequired,
    immCommercialAds: PropTypes.object.isRequired,
    immFlagBasedFeatures: PropTypes.object.isRequired,
    productAssociation: PropTypes.string.isRequired,
    updateCommercialAdsStatus: PropTypes.func.isRequired
};

module.exports = PromotionBanner;
