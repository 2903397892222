// @ vendor
const React = require('react');
const PropTypes = require('prop-types');
const { Component } = require('react');

// @utilities
const { FormattedText, hasTranslation } = require('core/i18n').i18n;

// @commons
const OKCommonPillSelectors = require('commonsComponents/OKPillSelectors');

class OKPillSelectors extends Component {

    render() {
        let { items } = this.props;
        let pillSelectorsProps = Object.assign({}, this.props);

        items.forEach(item => {
            if (typeof item.text === 'string' && hasTranslation(item.text)) {
                item.text = <FormattedText value={item.text} />;
            }
        });
        delete pillSelectorsProps.items;

        return (
            <OKCommonPillSelectors
                {...pillSelectorsProps}
                items={items}
            />
        );
    }
}
OKPillSelectors.propTypes = {
    items: PropTypes.array.isRequired,
};

module.exports = OKPillSelectors;