// @vendor
const merge = require('lodash/object/merge');
const isArray = require('lodash/lang/isArray');

// @constants
const {
    OCU_CC_PRODUCTION_DOMAIN,
    PROD_CC_PRODUCTION_DOMAIN,
    LOCAL_ENVIRONMENT,
    OCULTO_ENVIRONMENT,
    PRODUCTION_DOMAIN,
    PRODUCTION_ENVIRONMENT,
    URL_REGEX,
} = require('constants/index');

let environmentConfig = require('../../environment_config/base.json');
let mbEnvironmentConfig = require('../../environment_config/modelbankGlobalVariables.json');


// This is a helper for mergeWith, to merge array items instead of overwrite the ones in the same position
const customizer = (objValue, srcValue) => {if (isArray(objValue)) {return objValue.concat(srcValue); } }
const getEnvAndCountry = () => {
    const parsedUrl = URL_REGEX.exec(location.origin)
    return {
        env: parsedUrl && parsedUrl[1],
        country: parsedUrl && parsedUrl[2]
    }
}

if (window.__ENV__ === LOCAL_ENVIRONMENT){
    const localEnvironmentConfig = require('../../environment_config/local.json');
    environmentConfig = merge(environmentConfig, localEnvironmentConfig, customizer);
}

if (__CONTACT_CENTER__) {
    const contactCenterEnvironmentConfig = require('../../environment_config/contact_center.json');
    environmentConfig = merge(environmentConfig, contactCenterEnvironmentConfig, customizer);
}

// We check for environment variables. We use them on servers, not on localhost
const baseHost = window.__BASE_HOST__ || environmentConfig.baseHost;
const baseHostPrivada = window.__BASE_HOST_PRIVADA__ || environmentConfig.baseHostPrivada || baseHost;
const mockAllEndpoints = JSON.parse(window.__MOCK_ALL_ENDPOINTS__ || environmentConfig.mockAllEndpoints || false);

const { env, country } = getEnvAndCountry();

const getPublicUrl = (withLanguage = true) => {
    const { getLanguage } = require('core/i18n').i18n;

    const language = getLanguage();
    let publicUrl =   window.__PUBLIC_URL__
    if (withLanguage && language && country && language.toUpperCase() !== country.toUpperCase()) {
        publicUrl = `${publicUrl}/${language}`;
    }
    return publicUrl
}


let mockedEndpoints;
if (window.__MOCKED_ENDPOINTS__){
    mockedEndpoints = JSON.parse(window.__MOCKED_ENDPOINTS__).concat(environmentConfig.mockedEndpoints);
} else {
    mockedEndpoints = environmentConfig.mockedEndpoints;
}

let redirectedEndpoints;
if (window.__REDIRECTED_ENDPOINTS__){
    redirectedEndpoints = merge(environmentConfig.redirectedEndpoints, JSON.parse(__REDIRECTED_ENDPOINTS__));
} else {
    redirectedEndpoints = environmentConfig.redirectedEndpoints;
}

let cookiesDomain;
if (__CONTACT_CENTER__ || (!country && !env)) {
    if (window.__ENV__ === OCULTO_ENVIRONMENT) {
        cookiesDomain = __CONTACT_CENTER__ ? OCU_CC_PRODUCTION_DOMAIN : PRODUCTION_DOMAIN;
    }
    else if (window.__ENV__ === PRODUCTION_ENVIRONMENT ) {
        cookiesDomain = __CONTACT_CENTER__ ? PROD_CC_PRODUCTION_DOMAIN : PRODUCTION_DOMAIN;
    }
    else {
        cookiesDomain = 'cc.openbank.es'
    }
} else {
     cookiesDomain = (window.__ENV__ === PRODUCTION_ENVIRONMENT) ? `openbank.${country}` : `${env}.openbank.${country}`;
}

const localHostsByEndpoint = environmentConfig.localHostsByEndpoint || {};

const hosts = {
    ...environmentConfig.hosts,
    "DEV": "https://api.dev.openbank.es",
    "QA": "https://api.qa.openbank.es",
    "STAGING": "https://api.stg.openbank.es",
    "OCULTO": "https://api.ocu.openbank.es",
    "MOCK": "https://openb.mockable.io",
    "CMS_DEV": "http://api-qa-campaigns-engine01.aws.ok-cloud.net:8978",
    "LOCAL": "http://127.0.0.1:13000"
};

const contactCenterHhosts = {
    ...environmentConfig.hosts,
    "DEV": "https://apiccqaalb001.aws.ok-cloud.net",
    "QA": "https://apiccqaalb001.aws.ok-cloud.net",
    "STAGING": "https://apiccstgalb001.aws.ok-cloud.net",
    "MOCK": "http://localhost:3001/rest/v1"
};

let config = {
    baseHost,
    baseHostPrivada,
    getPublicUrl,
    cookiesDomain,
    publicPaths: environmentConfig.publicPaths,
    GDI:environmentConfig.GDI[window.__ENV__],
    services: {
        mockedEndpoints,
        mockAllEndpoints,
        localHostsByEndpoint,
        redirectedEndpoints,
        hosts: __CONTACT_CENTER__ ? contactCenterHhosts : hosts,
        endpoints: environmentConfig.endpoints,
    }
};

for (const [key, value] of Object.entries(mbEnvironmentConfig.globalVariables)) {
    const client = country === 'nl' ? 'klanten' : 'clientes';
    window[key] = typeof value === "string"
        ? value
            .replace("${env}", env || "stg")
            .replace("${country}", country || "es")
            .replace("${client}", client)
        : value;
}

module.exports = config;
