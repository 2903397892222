require ('./avatar.scss');

const PropTypes = require('prop-types');

const React = require('react');

const { FormattedText } = require('core/i18n').i18n;

const { downloadAsFile, base64ToBlob } = require('utilities/APIRequestHelper');

const defaultMaleAvatar = require('assets/images/avatar-male-default.svg');
const defaultFemaleAvatar = require('assets/images/avatar-female-default.svg');

class Avatar extends React.Component {

    constructor(props) {
        super(props);

        this.handleDownloadAvatar = this.handleDownloadAvatar.bind(this);
    }

    downloadAvatar() {
        const { imgUrl } = this.props;

        downloadAsFile(base64ToBlob(imgUrl), null, 'avatar.jpg');
    }

    handleDownloadAvatar() {

        if (this.props.imgType === "view-mode") {
            this.downloadAvatar();
        }
    }

    render() {
        const { id, imgDescription, imgType, imgUrl } = this.props;
        let response = (
            <div className="avatar__image-empty" />
        );

        if (!!imgUrl) {
            const imgClass = `img-responsive center-block img-circle avatar__image avatar__image--${imgType}`;
            const image = (
                <img
                    className={imgClass}
                    src={imgUrl}
                    alt={imgDescription}
                    crossOrigin="Anonymous"
                    id={id}
                />
            );

            if (imgType !== "view-mode" || imgUrl === defaultMaleAvatar || imgUrl === defaultFemaleAvatar) {
                response = image;
            } else {
                response = (
                    <div className="avatar__image-download">
                        <div className="avatar__image-download-preview" onClick={this.handleDownloadAvatar}>
                            <div className="avatar__image-download-background" />
                            <div className="avatar__image-download-icon">
                                <i className="icon icon-descargar avatar__icon-download" />
                                <br />
                                <FormattedText value="avatarPicker-downloadImage"/>
                            </div>
                        </div>
                        {image}
                    </div>
                );
            }
        }
        return response;
    }
}

Avatar.propTypes = {
    imgDescription: PropTypes.string.isRequired,
    imgType: PropTypes.string.isRequired,
    imgUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
};

module.exports = Avatar;