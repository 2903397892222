const PropTypes = require('prop-types');
// @vendors
const React = require('react');

// @helpers
const { formatText, getLanguage } = require('core/i18n').i18n;
const { getLastNChars } = require('utilities/stringHelper');
const { formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');

// @components
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');

// @constants
const { BROKER_TRANSFER_FUNDS_ALL } = require('constants/index');

class RAHireManagementPlanStep1DropdownValue extends React.Component {
    render() {
        const {value, name, newContractNumber, interventionType, accountHolder, balance} = this.props.value;
        const plainAmountObject = getPlainAmountObject(balance);
        const balanceLabel = formatText('raHireManagementPlanStep1-raHireManagementPlanStep1_fundsTransferDropdownBalanceLabel');
        let content;
        if (value === BROKER_TRANSFER_FUNDS_ALL) {
            content = (
                <div className="stocks-contract-dropdown__selected-value" onMouseDown={this.handleMouseDown}>
                    <span className="stocks-contract-dropdown__value-label stocks-contract-dropdown__option--highlighted">
                        {name}
                    </span>
                </div>
            );
        } else {
            content = (
                <div className="stocks-contract-dropdown__selected-value" onMouseDown={this.handleMouseDown}>
                    <span className="stocks-contract-dropdown__value-label stocks-contract-dropdown__option--highlighted">
                        {name}
                    </span>
                    <span className="stocks-contract-dropdown__value--medium">
                        {getLastNChars(newContractNumber)}
                    </span>

                    <span className="stocks-contract-dropdown__option--right">
                        <span className="stocks-contract-dropdown__value-label">{interventionType}</span>
                        <span className="stocks-contract-dropdown__value-label">{accountHolder}</span>
                        <span className="stocks-contract-dropdown__value-label">{balanceLabel}</span>
                        <span className="stocks-contract-dropdown__value-price stocks-contract-dropdown__option--highlighted">
                            <OneLineSymbolAmount
                                amountObject={plainAmountObject}
                                className="stocks-contract-dropdown__option-balance"
                                language={getLanguage()}
                                amountObjectFormatted={formatAmountObject(plainAmountObject, true)}
                            />
                        </span>
                    </span>
                </div>
            );
        }
        return content;

    }
}

RAHireManagementPlanStep1DropdownValue.propTypes = {
    value: PropTypes.object
};

module.exports = RAHireManagementPlanStep1DropdownValue;
