const PropTypes = require('prop-types');
// @vendors
const React = require('react');

// @helpers
const { formatText, getLanguage } = require('core/i18n').i18n;
const { getLastNChars } = require('utilities/stringHelper');
const { formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');

// @components
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');

class StocksContractDropdownDerivativesTransferOption extends React.Component {
    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    handleMouseDown (event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    }

    handleMouseMove (event) {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    }

    render() {
        const {option, className} = this.props;
        const {walletAccountType, walletNumber, walletBalance} = option;
        const optionClassName = `stocks-contract-dropdown__option ${className}`;
        const plainAmountObject = getPlainAmountObject(walletBalance);
        let showInactiveIcon = null;

        if (option.isBlocked) {
            option.disabled = true;
            showInactiveIcon = <span className="stocks-contract-dropdown__account-description-inactive-icon"><i className="icon-candado"></i></span>
        }

        return (
            <div className={optionClassName} onMouseDown={this.handleMouseDown} onMouseMove={this.handleMouseMove}>
                {showInactiveIcon}
                <span className="stocks-contract-dropdown__value-label stocks-contract-dropdown__option--highlighted">
                    {walletAccountType}
                </span>
                <span className="stocks-contract-dropdown__value--medium">
                    {getLastNChars(walletNumber)}
                </span>

                <span className="stocks-contract-dropdown__option--right">
                    <span>{formatText('broker-stocksContractDropdownDerivativesTransferBalance')}</span>
                    <span className="stocks-contract-dropdown__value-price stocks-contract-dropdown__option--highlighted">
                        <OneLineSymbolAmount
                            amountObject={plainAmountObject}
                            className="stocks-contract-dropdown__option-balance"
                            language={getLanguage()}
                            amountObjectFormatted={formatAmountObject(plainAmountObject, true)}
                        />
                    </span>
                </span>
            </div>
        );
    }
}

StocksContractDropdownDerivativesTransferOption.propTypes = {
    className: PropTypes.string,
    option: PropTypes.object.isRequired,
    isFocused: PropTypes.bool,
    onSelect: PropTypes.func,
    onFocus: PropTypes.func
};

module.exports = StocksContractDropdownDerivativesTransferOption;