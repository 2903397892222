require('./dateFilter.scss');

const PropTypes = require('prop-types');

const React = require('react');
const { FormattedText, formatText } = require('core/i18n').i18n;
const moment = require('moment');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const DatePickerCustom = require('components/datePickerCustom/datePickerCustom');
const { RadioButton } = require('commonsComponents/radioButton');
const { RadioButtonGroup } = require('commonsComponents/radioButtonGroup');
const DateHelper = require('utilities/dateHelper');

//@constants
const {
    DATE_FILTER_ANNUAL,
    DATE_FILTER_BIANNUAL,
    DATE_FILTER_CUSTOM,
    DATE_FILTER_FORTNIGHTLY,
    DATE_FILTER_MONTHLY,
    DATE_FILTER_NONE,
    DATE_FILTER_TWO_YEARS
} = require('constants/index');
const { DATE_PICKER_CUSTOM_PLACEHOLDER, DATE_PICKER_CUSTOM_LOCALE, DATE_PICKER_CUSTOM_DATE_FORMAT } = require('constants/commonsComponents');

class DateFilterAhead extends React.Component {

    constructor(props) {
        super(props);
        const { immFilter } = this.props;

        this.state = {
            selected: immFilter.get('selected'),
            fromDate: immFilter.get('from'),
            toDate: immFilter.get('to')
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.setToDate = this.setToDate.bind(this);
        this.setFromDate = this.setFromDate.bind(this);
        this.buildRadioButtons = this.buildRadioButtons.bind(this);
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    }

    setFromDate(date) {
        this.setState((previousState) => {
            if (previousState.toDate.isBefore(date)) {
                this.props.onChangeMin(date);
                this.props.onChangeMax(date);

                return {
                    fromDate: date,
                    toDate: date
                }
            } else {
                this.props.onChangeMin(date);

                return {
                    fromDate: date
                }
            }
        });
    }

    setToDate(date) {
        this.setState({
            toDate: date
        });
        this.props.onChangeMax(date);
    }

    buildBetweenDates() {
        const { handleTaggingFromDate, handleTaggingToDate, minDate, maxDate } = this.props;
        const { selected, fromDate, toDate } = this.state;
        let dateRangeSection;

        if (selected === DATE_FILTER_CUSTOM) {
            dateRangeSection = (
                <div className="row date-filter__custom-ranges">
                    <div className="col-xs-6">
                        <div className="date-filter__label-to">
                            <FormattedText value="filterDateAhead-fromRange" />
                        </div>
                        <DatePickerCustom
                            callback={this.setFromDate}
                            doTaggingClickDateInput={handleTaggingFromDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            selectedDate={fromDate}
                            newSelectedDate={fromDate}
                            placeholder={formatText(DATE_PICKER_CUSTOM_PLACEHOLDER)}
                            locale={formatText(DATE_PICKER_CUSTOM_LOCALE)}
                            dateFormat={formatText(DATE_PICKER_CUSTOM_DATE_FORMAT)}
                        />
                    </div>
                    <div className="col-xs-6">
                        <div className="date-filter__label-from">
                            <FormattedText value="filterDateAhead-toRange" />
                        </div>
                        <DatePickerCustom
                            callback={this.setToDate}
                            doTaggingClickDateInput={handleTaggingToDate}
                            minDate={fromDate}
                            maxDate={maxDate}
                            selectedDate={toDate}
                            newSelectedDate={toDate}
                            placeholder={formatText(DATE_PICKER_CUSTOM_PLACEHOLDER)}
                            locale={formatText(DATE_PICKER_CUSTOM_LOCALE)}
                            dateFormat={formatText(DATE_PICKER_CUSTOM_DATE_FORMAT)}
                            innerSmallContainer
                        />
                    </div>
                </div>
            )
        }

        return dateRangeSection;
    }

    buildRadioButtons() {
        const { showFortnightly, showMonthly, showBiannual, showAnnual, showTwoYears } = this.props;
        const options = [DATE_FILTER_NONE];

        if (showFortnightly) {
            options.push(DATE_FILTER_FORTNIGHTLY);
        }

        if (showMonthly) {
            options.push(DATE_FILTER_MONTHLY);
        }

        if (showBiannual) {
            options.push(DATE_FILTER_BIANNUAL);
        }

        if (showAnnual) {
            options.push(DATE_FILTER_ANNUAL);
        }

        if (showTwoYears) {
            options.push(DATE_FILTER_TWO_YEARS);
        }

        options.push(DATE_FILTER_CUSTOM);

        return options.map((filter, index) => {
            let labelPrimary = formatText(`filterDateAhead-label${filter}`);
            let labelSecondary = '';
            let customLabel;

            if (filter !== DATE_FILTER_CUSTOM && filter !== DATE_FILTER_NONE) {
                const dates = this.estimateDates(filter);
                labelPrimary = `${labelPrimary} - `;

                labelSecondary = formatText('filterDateAhead-range',
                    [
                        DateHelper.formatDate(dates[0], formatText('filterDateAhead-rangeFormat')),
                        DateHelper.formatDate(dates[1], formatText('filterDateAhead-rangeFormat'))
                    ]
                );
            }

            customLabel = (
                <div className="date-filter__radio-label">
                    <span className="date-filter__radio-label-primary">{labelPrimary}</span>
                    <span className="date-filter__radio-label-secondary">{labelSecondary}</span>
                </div>
            );

            return (
                <RadioButton
                    key={index}
                    className="date-filter__radio-option"
                    label={customLabel}
                    value={filter}
                />
            );
        })
    }

    estimateDates(id) {
        const today = moment().startOf('day');
        const endDate = moment().startOf('day');

        switch (id) {
            case DATE_FILTER_FORTNIGHTLY:
                endDate.add('2', 'weeks');
                break;
            case DATE_FILTER_MONTHLY:
                endDate.add('1', 'months');
                break;
            case DATE_FILTER_BIANNUAL:
                endDate.add('6', 'months');
                break;
            case DATE_FILTER_ANNUAL:
                endDate.add('1', 'years');
                break;
            case DATE_FILTER_TWO_YEARS:
                endDate.add('2', 'years');
                break;
        }
        return [today, endDate];
    }

    handleFilterChange(value) {
        const selected = value;
        let fromDate = '';
        let toDate = '';

        if (selected !== DATE_FILTER_NONE) {
            const dates = this.estimateDates(selected);
            fromDate = dates[1];
            toDate = dates[0];
        }

        this.setState({
            selected,
            fromDate,
            toDate
        });

        this.props.onChangeSelected(selected);
        this.props.onChangeMax(fromDate);
        this.props.onChangeMin(toDate);
    }

    render() {
        const radioButtonsSection = this.buildRadioButtons();
        const dateRangeSection = this.buildBetweenDates();

        return (
            <div className="date-filter__last">
                <h3 className="date-filter__title">
                    <FormattedText value="filterDateAhead-title" format="uppercase" />
                </h3>
                <RadioButtonGroup
                    name="date-filter"
                    idPrefix="date-filter-radio"
                    selectedValue={this.state.selected}
                    onChange={this.handleFilterChange}
                    className="date-filter__options"
                >
                    {radioButtonsSection}
                </RadioButtonGroup>
                {dateRangeSection}
            </div>
        );
    }
}

DateFilterAhead.propTypes = {
    immFilter: PropTypes.object.isRequired,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    handleTaggingFromDate: PropTypes.func,
    handleTaggingToDate: PropTypes.func,
    onChangeMax: PropTypes.func,
    onChangeMin: PropTypes.func,
    onChangeSelected: PropTypes.func,
    showYearDropdown: PropTypes.bool,
    showAnnual: PropTypes.bool,
    showBiannual: PropTypes.bool,
    showFortnightly: PropTypes.bool,
    showMonthly: PropTypes.bool,
    showTwoYears: PropTypes.bool
};

DateFilterAhead.defaultProps = {
    minDate: moment('2005-01-01').startOf('day'),
    maxDate: moment().startOf('day').add('50', 'years'),
    showYearDropdown: true,
    showAnnual: true,
    showBiannual: true,
    showFortnightly: true,
    showMonthly: true,
    showTwoYears: false
};

module.exports = DateFilterAhead;
