const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

require('./blockInfo.scss');

class BlockInfo extends React.Component {
    render() {
        const { children, extraClass, hasCheckbox } = this.props;
        const blockInfoGeneral = hasCheckbox ? 'block-info-checkbox' : 'block-info';
        const blockInfoClasses = classNames(blockInfoGeneral, extraClass);
        return (
            <div className={blockInfoClasses}>
                <i className={`${blockInfoGeneral}__icon-assistant`} />
                <div className={`${blockInfoGeneral}__content`}>
                    {children}
                </div>
            </div>
        );
    }
}

BlockInfo.propTypes = {
    children: PropTypes.node.isRequired,
    extraClass: PropTypes.string,
    hasCheckbox: PropTypes.bool
};

BlockInfo.defaultProps = {
    hasCheckbox: false
}

module.exports = BlockInfo;
