const PropTypes = require('prop-types');
// @vendors
const React = require('react');

// @helpers
const { formatText } = require('core/i18n').i18n;
const { getLastNChars } = require('utilities/stringHelper');
const { formatAmount } = require('utilities/currencyHelper');

// @constants
const { TYPE_ETFS } = require('constants/index');

class StocksContractDropdownValue extends React.Component {
    render() {
        const {
            walletAccountType,
            walletTitular,
            walletNumber,
            titlesQuantity,
            productType
        } = this.props.value;

        let titlesLabel;

        if (productType === TYPE_ETFS) {
            titlesLabel = formatText('broker-stocksContractDropdownAvailableTitles');
        } else {
            titlesLabel = formatText('broker-stocksContractDropdownAvailableStocksTitles');
        }

        return (
            <div className="stocks-contract-dropdown__value" onMouseDown={this.handleMouseDown}>
                <span className="stocks-contract-dropdown__value-label stocks-contract-dropdown__value--highlighted">
                    {walletAccountType} {walletTitular}
                </span>
                <span className="stocks-contract-dropdown__value--medium">
                    {getLastNChars(walletNumber)}
                </span>

                <span className="stocks-contract-dropdown__value--right">
                    <span>{titlesLabel}</span>
                    <span className="stocks-contract-dropdown__value-price stocks-contract-dropdown__value--highlighted">
                        {formatAmount(titlesQuantity, undefined, 0)}
                    </span>
                </span>
            </div>
        );
    }
}

StocksContractDropdownValue.propTypes = {
    value: PropTypes.object
};

module.exports = StocksContractDropdownValue;