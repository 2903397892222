const { 
    BLOCK_TRUSTED_DEVICE_SECURITY_CODE,
    BROKER_ADD_SUBSCRIPTION_INVESTMENT_FUND_ID,
    BROKER_ETF_BUY_ID,
    BROKER_HIRE_INVESTMENT_FUND_ID,
    BROKER_HIRE_PENSION_PLAN_ID,
    BROKER_INTERNAL_TRANSFER_FUND_ID,
    BROKER_PLAN_CANCEL_CONTRIBUTION_ID,
    BROKER_PLAN_CONTRIBUTION_ID,
    BROKER_PLAN_PERIODIC_CONTRIBUTION_EDIT_ID,
    BROKER_PLAN_PERIODIC_CONTRIBUTION_ID,
    BROKER_RIGHTS_BUY_ID,
    BROKER_STOCK_CONTRACT_ID,
    BROKER_STOCKS_ADVANCED_BUY_ID,
    BROKER_TRANSFER_EXTERNAL_ID,
    BROKER_TRANSFER_PENSION_PLAN_INTERNAL,
    BROKER_TRANSFER_VALUES_PARTIAL_ID,
    BROKER_TRANSFER_VALUES_TOTAL_ID,
    EXTERNAL_TRANSFER_FUNDS,
    PASS_MONEY_ID,
    REMOVE_SCHEDULED_TRANSFER_ID,
    TRANSFER_SEPA_NATIONAL_PERIODICITY_ID
} = require('constants/operationTypes');
const {
    BROKER_CASH_CHARGE_SIGNATURE_PATTERN_TYPE,
    BROKER_CONVENIENCE_MIFID_TEST_SIGNATURE_OPERATION_TYPE,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_SIGNATURE_PATTERN_TYPE,
    BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_SIGNATURE_PATTERN_OPERATION_TYPE,
    BROKER_SUBSCRIPTION_SIGNATURE_PATTERN_OPERATION_TYPE
} = require('constants/index');
const RA_HIRE_MANAGEMENT_PLAN_OPERATION_TYPE = 156;
const RA_REIMBURSEMENT_VIEW = 159;

module.exports = {
    INCORRECT_SMS: "Firma por OTP no válida",
    EXPIRED_FLOW: "Token no válido",
    BLOCKED_OTP: "Firma por OTP revocada",
    EXCLUDED_PUSH_OPERATION_CODES: [BLOCK_TRUSTED_DEVICE_SECURITY_CODE],
    WEALTH_OPERATIONAL_CODES: [
        BROKER_ADD_SUBSCRIPTION_INVESTMENT_FUND_ID,
        BROKER_CASH_CHARGE_SIGNATURE_PATTERN_TYPE,
        BROKER_CONVENIENCE_MIFID_TEST_SIGNATURE_OPERATION_TYPE,
        BROKER_ETF_BUY_ID,
        BROKER_HIRE_DERIVATIVES_ACCOUNT_SIGNATURE_PATTERN_TYPE,
        BROKER_HIRE_INVESTMENT_FUND_ID,
        BROKER_HIRE_PENSION_PLAN_ID,
        BROKER_INTERNAL_TRANSFER_FUND_ID,
        BROKER_PLAN_CANCEL_CONTRIBUTION_ID,
        BROKER_PLAN_CONTRIBUTION_ID,
        BROKER_PLAN_PERIODIC_CONTRIBUTION_EDIT_ID,
        BROKER_PLAN_PERIODIC_CONTRIBUTION_ID,
        BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_SIGNATURE_PATTERN_OPERATION_TYPE,
        BROKER_RIGHTS_BUY_ID,
        BROKER_STOCK_CONTRACT_ID,
        BROKER_STOCKS_ADVANCED_BUY_ID,
        BROKER_SUBSCRIPTION_SIGNATURE_PATTERN_OPERATION_TYPE,
        BROKER_TRANSFER_EXTERNAL_ID,
        BROKER_TRANSFER_PENSION_PLAN_INTERNAL,
        BROKER_TRANSFER_VALUES_PARTIAL_ID,
        BROKER_TRANSFER_VALUES_TOTAL_ID,
        EXTERNAL_TRANSFER_FUNDS,
        PASS_MONEY_ID,
        RA_HIRE_MANAGEMENT_PLAN_OPERATION_TYPE,
        RA_REIMBURSEMENT_VIEW,
        REMOVE_SCHEDULED_TRANSFER_ID,
        TRANSFER_SEPA_NATIONAL_PERIODICITY_ID,
    ]
};
