const PropTypes = require('prop-types');
// @vendors
const React = require('react');

// @helpers
const { formatText, getLanguage } = require('core/i18n').i18n;
const { getLastNChars } = require('utilities/stringHelper');
const { formatAmountObject, getPlainAmountObject } = require('utilities/currencyHelper');

// @components
const { OneLineSymbolAmount } = require('commonsComponents/oneLineSymbolAmount');

// @constants
const { BROKER_TRANSFER_FUNDS_ALL } = require('constants/index');

class stocksContractDropdownFundsTransferOption extends React.Component {
    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    handleMouseDown (event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    }

    handleMouseMove (event) {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    }

    render() {
        const {option, className} = this.props;
        const {value, name, newContractNumber, interventionType, accountHolder, balance} = option;
        const optionClassName = `col-md-12 stocks-contract-dropdown__option ${className}`;
        const plainAmountObject = getPlainAmountObject(balance);
        const balanceLabel = formatText('raHireManagementPlanStep1-raHireManagementPlanStep1_fundsTransferDropdownBalanceLabel');
        let content;
        if (value === BROKER_TRANSFER_FUNDS_ALL) {
            content = (
                <div className={optionClassName} onMouseDown={this.handleMouseDown} onMouseMove={this.handleMouseMove}>
                    <span className="stocks-contract-dropdown__value-label stocks-contract-dropdown__option--highlighted">
                        {name}
                    </span>
                </div>
            );
        } else {
            content = (
                <div className={optionClassName} onMouseDown={this.handleMouseDown} onMouseMove={this.handleMouseMove}>
                    <span className="stocks-contract-dropdown__value-label stocks-contract-dropdown__option--highlighted">
                        {name}
                    </span>
                    <span className="stocks-contract-dropdown__value--medium">
                        {getLastNChars(newContractNumber)}
                    </span>

                    <span className="stocks-contract-dropdown__option--right">
                        <span className="stocks-contract-dropdown__value-label">{interventionType}</span>
                        <span className="stocks-contract-dropdown__value-label">{accountHolder}</span>
                        <span className="stocks-contract-dropdown__value-label">{balanceLabel}</span>
                        <span className="stocks-contract-dropdown__value-price stocks-contract-dropdown__option--highlighted">
                            <OneLineSymbolAmount
                                amountObject={plainAmountObject}
                                className="stocks-contract-dropdown__option-balance"
                                language={getLanguage()}
                                amountObjectFormatted={formatAmountObject(plainAmountObject, true)}
                            />
                        </span>
                    </span>
                </div>
            );
        }
        return content;
    }
}

stocksContractDropdownFundsTransferOption.propTypes = {
    className: PropTypes.string,
    option: PropTypes.object.isRequired,
    isFocused: PropTypes.bool,
    onSelect: PropTypes.func,
    onFocus: PropTypes.func
};

module.exports = stocksContractDropdownFundsTransferOption;
