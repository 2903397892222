//@vendor
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const { connect } = require('react-redux');
//@commons
const OKTag = require('components/commons/OKTag/OKTag');
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');
// @utilities
const { toFirstUppercase, joinStringsWithSeparator } = require('utilities/stringHelper');
const { getCollectiveByParams } = require('utilities/collectivesHelper');
const { formatText } = require('core/i18n').i18n;
// @actions
const collectiveActions = require('actions/collectives');

class CollectiveTag extends React.Component {
    componentDidMount() {
        this.setActiveCollectiveInfo();
    }

    componentDidUpdate(prevProps) {
        const oldLoaded = prevProps.immCollectives.get('dataLoaded') && prevProps.immCollectives.get('infoLoaded');
        const newLoaded = this.props.immCollectives.get('dataLoaded') && this.props.immCollectives.get('infoLoaded');

        if (oldLoaded !== newLoaded) {
            this.setActiveCollectiveInfo();
        }
    }

    getCollectiveID() {
        const { immCollectives, productAlias, productType } = this.props;
        const immCollective = immCollectives.get('collective');

        let immProduct = null;

        if (immCollective.get('isCollective')) {
            const immProductsList = immCollective.get('productsList');
            immProduct = getCollectiveByParams(productAlias, productType, immProductsList);
        }

        return !!immProduct ? immCollective.get('collectiveID') : '';
    }

    getCollectiveById(collectiveID) {
        const { immCollectives } = this.props;
        const immCollectivesList = immCollectives.get('collectivesList');

        return immCollectivesList.find(immCollective => {
            return immCollective.get('targetAudience').find(targetAudience => targetAudience === collectiveID);
        });
    }

    setActiveCollectiveInfo() {
        const { immCollectives, productAlias, productType, setCollectiveInfo, setCurrentProductCollective } = this.props;
        const immCollectivesInfo = immCollectives.get('collectivesInfo');
        const immActive = getCollectiveByParams(productAlias, productType, immCollectivesInfo);
        const collectiveID = this.getCollectiveID();
        const immCollectiveInfo = !!collectiveID && this.getCollectiveById(collectiveID);

        if (!immActive && immCollectiveInfo) {
            setCollectiveInfo({ ...productType, alias: productAlias }, immCollectiveInfo);

            if (setCurrentProductCollective) {
                setCurrentProductCollective({ title: immCollectiveInfo.get('title') });
            }
        }
    }

    buildTooltipContent(collectiveTitle, tooltipContent) {
        const formattedCollectiveTitle = joinStringsWithSeparator(' ', collectiveTitle.split(' ').map(word => {
            return toFirstUppercase(word);
        }));
        const title = formatText('collectiveTag-collectiveTag_tooltipTitle', [formattedCollectiveTitle]);

        return (
            <OKTooltip
                content={tooltipContent}
                title={title}
                offset={[0, -10]}
                type="explicative"
            />
        );
    }

    buildTagContent(immCollectiveInfo) {
        const { tooltipContent, extraClass } = this.props;
        const collectiveTitle = immCollectiveInfo.get('title');
        const backgroundColor = immCollectiveInfo.get('backgroundColor');
        const textColor = immCollectiveInfo.get('textColor');
        const tooltip = !!tooltipContent && this.buildTooltipContent(collectiveTitle, tooltipContent);
        const className = classNames(
            'ok-collective-tag',
            extraClass
        );

        return (
            <div className={className}>
                <OKTag
                    content={collectiveTitle}
                    type="collective"
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    context={"pill"}
                />
                {tooltip}
            </div>
        )
    }

    render() {
        const { immCollectives, productAlias, productType } = this.props;
        const immCollectivesInfo = immCollectives.get('collectivesInfo');
        const immActive = getCollectiveByParams(productAlias, productType, immCollectivesInfo);

        return !!immActive ? this.buildTagContent(immActive) : null;
    }

}

CollectiveTag.propTypes = {
    extraClass: PropTypes.string,
    immCollectives: PropTypes.object.isRequired,
    productAlias: PropTypes.string,
    productType: PropTypes.object,
    setCollectiveInfo: PropTypes.func.isRequired,
    setCurrentProductCollective: PropTypes.func,
    tooltipContent: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ])
}

module.exports.constructor = CollectiveTag;
module.exports.CollectiveTag = connect(
    state => ({
        immCollectives: state.collectives
    }), {
        setCollectiveInfo: collectiveActions.setCollectiveInfo
    }
)(CollectiveTag)
