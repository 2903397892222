const tarjetasIcon = require('assets/icons/tarjetas.svg');
const cajeroIcon = require('assets/icons/cajero.svg');
const depositosIcon = require('assets/icons/depositos.svg');
const derivadosotcIcon = require('assets/icons/derivados-otc.svg');
const transparenciaIcon = require('assets/icons/transparencia.svg');
const viajesIcon = require('assets/icons/viajes.svg');
const auricularIcon = require('assets/icons/auricular.svg');

const cardBenefits = {
  premium: {
    benefits: [
      {
        icon: 'icon-seguro-accidentes',
        text: 'cardBenefits-premiumBenefitsFirst',
      },
      {
        icon: 'icon-compras-seguras',
        text: 'cardBenefits-premiumBenefitsSecond',
      },
      {
        icon: 'icon-flexibilidad',
        text: 'cardBenefits-premiumBenefitsThird',
      },
      {
        icon: 'icon-cajero',
        text: 'cardBenefits-premiumBenefitsFourth',
      },
    ],
    collectivosBenefits: [
      {
        icon: 'icon-seguro-accidentes',
        text: 'cardBenefits-premiumBenefitsFirst',
      },
      {
        icon: 'icon-especialista',
        text: 'colectivosCardBenefits-premiumBenefitsSecond',
      },
      {
        icon: 'icon-cajero',
        text: 'cardBenefits-premiumBenefitsFourth',
      },
      {
        icon: 'icon-flexibilidad',
        text: 'cardBenefits-premiumBenefitsThird',
      },
    ],
    telephones: [
      {
        title: 'cardBenefits-premiumTelephonesTitleFirst',
        text: 'cardBenefits-premiumTelephonesTextFirst',
      },
      {
        title: 'cardBenefits-premiumTelephonesTitleSecond',
        text: 'cardBenefits-premiumTelephonesTextSecond',
        link: {
          text: 'cardBenefits-premiumTelephonesLinkSecond',
          url: 'https://www.openbank.es/assets/static/pdf/Productos/Otros/seguro_corto.pdf',
        },
      },
      {
        title: 'cardBenefits-premiumTelephonesTitleThird',
        text: 'cardBenefits-premiumTelephonesTextThird',
        link: {
          text: 'cardBenefits-premiumTelephonesLinkThird',
          url: 'https://www.openbank.es/assets/static/pdf/Productos/Otros/seguro_largo.pdf',
        },
      },
      {
        icon: '',
        title: 'cardBenefits-premiumTelephonesTitleFourth',
        text: 'cardBenefits-premiumTelephonesTextFourth',
        link: {
          text: 'cardBenefits-premiumTelephonesLinkFourth',
          url:  'https://www.openbank.es/assets/static/pdf/Productos/501_002_3_Pack_Diamond/Poliza_Diamond.pdf',
        },
      },
    ],
  },
  diamond: {
    benefits: [
      {
        icon: 'icon-seguro-accidentes',
        text: 'cardBenefits-diamondBenefitsFirst',
      },
      {
        icon: 'icon-compras-seguras',
        text: 'cardBenefits-diamondBenefitsSecond',
      },
      {
        icon: 'icon-flexibilidad',
        text: 'cardBenefits-diamondBenefitsThird',
      },
      {
        icon: 'icon-llamar',
        text: 'cardBenefits-diamondBenefitsFourth',
      },
      {
        icon: 'icon-refrescar',
        text: 'cardBenefits-diamondBenefitsFifth',
      },
      {
        icon: 'icon-cajero',
        text: 'cardBenefits-diamondBenefitsSixth',
      },
    ],
    collectivosBenefits: [
      {
        icon: 'icon-seguro-accidentes',
        text: 'cardBenefits-premiumBenefitsFirst',
      },
      {
        icon: 'icon-especialista',
        text: 'colectivosCardBenefits-premiumBenefitsSecond',
      },
      {
        icon: 'icon-cajero',
        text: 'cardBenefits-premiumBenefitsFourth',
      },
      {
        icon: 'icon-flexibilidad',
        text: 'cardBenefits-premiumBenefitsThird',
      },
    ],
    telephones: [
      {
        icon: '',
        title: 'cardBenefits-diamondTelephonesTitleFirst',
        text: 'cardBenefits-diamondTelephonesTextFirst',
      },
      {
        icon: '',
        title: 'cardBenefits-diamondTelephonesTitleSecond',
        text: 'cardBenefits-diamondTelephonesTextSecond',
      },
      {
        icon: '',
        title: 'cardBenefits-diamondTelephonesTitleThird',
        text: 'cardBenefits-diamondTelephonesTextThird',
        link: {
          text: 'cardBenefits-diamondTelephonesLinkThird',
          url: 'https://www.openbank.es/assets/static/pdf/Productos/Otros/seguro_corto.pdf',
        },
      },
      {
        icon: '',
        title: 'cardBenefits-diamondTelephonesTitleFourth',
        text: 'cardBenefits-diamondTelephonesTextFourth',
        link: {
          text: 'cardBenefits-diamondTelephonesLinkFourth',
          url: 'https://www.openbank.es/assets/static/pdf/Productos/Otros/seguro_largo.pdf',
        },
      },
      {
        icon: '',
        title: 'cardBenefits-diamondTelephonesTitleFifth',
        text: 'cardBenefits-diamondTelephonesTextFifth',
        link: {
          text: 'cardBenefits-diamondTelephonesLinkFifth',
          url:
            'https://www.openbank.es/assets/static/pdf/Productos/501_002_3_Pack_Diamond/Poliza_Diamond.pdf',
        },
      },
    ],
  },
  r42: {
    benefits: [
      {
        icon: 'icon-seguro-accidentes',
        text: 'cardBenefits-cardBenefits_r42BenefitsFirst',
      },
      {
        icon: 'icon-compras-seguras',
        text: 'cardBenefits-cardBenefits_r42BenefitsSecond',
      },
      {
        icon: 'icon-flexibilidad',
        text: 'cardBenefits-cardBenefits_r42BenefitsThird',
      },
      {
        icon: 'icon-llamar',
        text: 'cardBenefits-cardBenefits_r42BenefitsFourth',
      },
      {
        icon: 'icon-refrescar',
        text: 'cardBenefits-cardBenefits_r42BenefitsFifth',
      },
      {
        icon: 'icon-cajero',
        text: 'cardBenefits-cardBenefits_r42BenefitsSixth',
      },
    ],
    collectivosBenefits: [],
    telephones: [
      {
        icon: '',
        title: 'cardBenefits-cardBenefits_r42TelephonesTitleFirst',
        text: 'cardBenefits-cardBenefits_r42TelephonesTextFirst',
      },
      {
        icon: '',
        title: 'cardBenefits-cardBenefits_r42TelephonesTitleSecond',
        text: 'cardBenefits-cardBenefits_r42TelephonesTextSecond',
      },
      {
        icon: '',
        title: 'cardBenefits-cardBenefits_r42TelephonesTitleThird',
        text: 'cardBenefits-cardBenefits_r42TelephonesTextThird',
        link: {
          text: 'cardBenefits-cardBenefits_r42TelephonesLinkThird',
          urlTranslationKey: 'cardBenefits-cardBenefits_r42TelephonesLinkUrlThird',
        },
      },
      {
        icon: '',
        title: 'cardBenefits-cardBenefits_r42TelephonesTitleFourth',
        text: 'cardBenefits-cardBenefits_r42TelephonesTextFourth',
        link: {
          text: 'cardBenefits-cardBenefits_r42TelephonesLinkFourth',
          urlTranslationKey: 'cardBenefits-cardBenefits_r42TelephonesLinkUrlFourth',
        },
      },
      {
        icon: '',
        title: 'cardBenefits-cardBenefits_r42TelephonesTitleFifth',
        text: 'cardBenefits-cardBenefits_r42TelephonesTextFifth',
        link: {
          text: 'cardBenefits-cardBenefits_r42TelephonesLinkFifth',
          urlTranslationKey: 'cardBenefits-cardBenefits_r42TelephonesLinkUrlFifth',
        },
      },
    ],
  },
  r42Master: {
    benefits: [
      {
        icon: tarjetasIcon,
        text: 'cardBenefits-r42MasterBenefitsFirst',
      },
      {
        icon: depositosIcon,
        text: 'cardBenefits-r42MasterBenefitsSecond',
      },
      {
        icon: derivadosotcIcon,
        text: 'cardBenefits-r42MasterBenefitsThird',
      },
      {
        icon: cajeroIcon,
        text: 'cardBenefits-r42MasterBenefitsFourth',
      },
      {
        icon: viajesIcon,
        text: 'cardBenefits-r42MasterBenefitsFifth',
        innerLinks: {
          '013': {
            //mastercard DE
            link1:
              'https://www.openbank.de/assets/static/de/pdf/Products/Insurance_conditions_DE.pdf',
            link2:
              'https://www.openbank.de/assets/static/de/pdf/Products/Insurance_debit_card_DE.pdf',
          },
          '014': {
            //mastercard NL
            link1:
              'https://www.openbank.nl/assets/static/nl/pdf/Products/R42_verzekeringspolis.pdf',
            link2:
              'https://www.openbank.nl/assets/static/nl/pdf/Products/R42_verzekeringscertificaat.pdf',
          },
          '015': {
            //mastercard PT
            link1:
              'https://www.openbank.pt/assets/static/pt/Products/Coberturas_Seguro_Zurich_R42-PT.pdf',
            link2: 'https://www.openbank.pt/assets/static/pt/pdf/Products/R42_Insurance_PT.pdf',
          },
        },
      },
      {
        icon: transparenciaIcon,
        text: 'cardBenefits-r42MasterBenefitsSixth',
      },
    ],
    collectivosBenefits: [],
    telephones: [
      {
        icon: '',
        title: 'cardBenefits-cardBenefits_r42MasterTelephonesTitleFirst',
        text: 'cardBenefits-cardBenefits_r42MasterTelephonesTextFirst',
      },
    ],
  },
  r42BetaalPas: {
    benefits: [
      {
        icon: tarjetasIcon,
        text: 'cardBenefits-r42BetaalPasBenefitsFirst',
      },
      {
        icon: depositosIcon,
        text: 'cardBenefits-r42BetaalPasBenefitsSecond',
      },
      {
        icon: derivadosotcIcon,
        text: 'cardBenefits-r42BetaalPasBenefitsThird',
      },
      {
        icon: cajeroIcon,
        text: 'cardBenefits-r42BetaalPasBenefitsFourth',
      },
      {
        icon: viajesIcon,
        text: 'cardBenefits-r42BetaalPasBenefitsFifth',
        innerLinks: {
          '019': {
            //betaalspas NL
            link1:
              'https://www.openbank.nl/assets/static/nl/pdf/Products/R42_verzekeringspolis.pdf',
            link2:
              'https://www.openbank.nl/assets/static/nl/pdf/Products/R42_verzekeringscertificaat.pdf',
          },
        },
      },
      {
        icon: transparenciaIcon,
        text: 'cardBenefits-r42BetaalPasBenefitsSixth',
      },
    ],
    collectivosBenefits: [],
    telephones: [
      {
        icon: '',
        title: 'cardBenefits-cardBenefits_r42BetaalPasTelephonesTitleFirst',
        text: 'cardBenefits-cardBenefits_r42BetaalPasTelephonesTextFirst',
      },
    ],
  },
  r42Metalic: {
    benefits: [
      {
        icon: auricularIcon,
        text: 'cardBenefits-r42MetalicBenefitsFirst',
        innerLinks: {
          all: {
            link1: 'r42MetalicModalOpen',
          },
        },
      },
      {
        icon: cajeroIcon,
        text: 'cardBenefits-r42MetalicBenefitsSecond',
      },
      {
        icon: viajesIcon,
        text: 'cardBenefits-r42MetalicBenefitsThird',
        innerLinks: {
          '020': {
            //metalcard DE
            link1:
              'https://www.openbank.de/assets/static/de/pdf/Products/Insurance_conditions_DE.pdf',
            link2:
              'https://www.openbank.de/assets/static/de/pdf/Products/Insurance_debit_card_DE.pdf',
          },
          '021': {
            //metalcard NL
            link1:
              'https://www.openbank.nl/assets/static/nl/pdf/Products/R42_verzekeringspolis.pdf',
            link2:
              'https://www.openbank.nl/assets/static/nl/pdf/Products/R42_verzekeringscertificaat.pdf',
          },
          '022': {
            //metalcard PT
            link1:
              'https://www.openbank.pt/assets/static/pt/Products/Coberturas_Seguro_Zurich_R42-PT.pdf',
            link2: 'https://www.openbank.pt/assets/static/pt/pdf/Products/R42_Insurance_PT.pdf',
          },
        },
      },
      {
        icon: derivadosotcIcon,
        text: 'cardBenefits-r42MetalicBenefitsFourth',
      },
      {
        icon: tarjetasIcon,
        text: 'cardBenefits-r42MetalicBenefitsFifth',
      },
      {
        icon: transparenciaIcon,
        text: 'cardBenefits-r42MetalicBenefitsSixth',
      },
    ],
    collectivosBenefits: [],
    telephones: [
      {
        icon: '',
        title: 'cardBenefits-cardBenefits_r42MetalicTelephonesTitleFirst',
        text: 'cardBenefits-cardBenefits_r42MetalicTelephonesTextFirst',
      },
      {
        icon: '',
        title: 'cardBenefits-cardBenefits_r42MetalicTelephonesTitleSecond',
        text: 'cardBenefits-cardBenefits_r42MetalicTelephonesTextSecond',
      },
    ],
  },
};

const cardPolicies = {
  500: {
    '001': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1368446685951&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '002': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1368446685951&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '800': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1368446685951&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '801': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CBROKEROB&cid=1431476475454&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '802': {
      link: 'https://www.openbank.es/assets/static/pdf/Productos/zurich_corta.pdf',
    },
    '804': {
      link: 'https://www.openbank.es/assets/static/pdf/Productos/Otros/seguro_corto.pdf',
    },
    '805': {
      link: 'https://www.openbank.es/assets/static/pdf/Productos/zurich_corta.pdf',
    },
  },
  501: {
    '001': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1368446686015&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '003': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1368446686015&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
  },
  506: {
    '002': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CBROKEROB&cid=1431476317597&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '003': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CBROKEROB&cid=1431476475690&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '004': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CBROKEROB&cid=1431476317597&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '007': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CBROKEROB&cid=1431476475643&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '010': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CBROKEROB&cid=1431476475837&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '011': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431484373971&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank/DocumentoGS/DocumentoGSPreview',
    },
  },
  521: {
    '001': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1368446685951&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
    '800': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1368446685951&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
  },
  522: {
    '001': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1368446686015&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    },
  },
  532: {
    '001': {
      link:
        'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431476292474&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank/DocumentoGS/DocumentoGSPreview',
    },
  },
};

module.exports = {
  cardBenefits,
  cardPolicies,
};
