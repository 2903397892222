require('./globalPositionCollapsable.scss');

const PropTypes = require('prop-types');

const React = require('react');
const { connect } = require('react-redux');
const {
    Children,
    cloneElement
} = require('react');
const find = require('lodash/collection/find');
const slice = require('lodash/array/slice');
const { Accordion } = require('commonsComponents/accordion');
const landingActions = require('actions/landings');
// @utils
const { recordLinkV2 } = require("utilities/tagging");
// @analytics
const { catalogue, analyticsId } = require( '../analytics/globalPositionAnalytics' );

class GlobalPositionCollapsable extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(isExpanded, id) {
        recordLinkV2(catalogue[analyticsId.CLICK_TOGGLE](isExpanded));
        this.props.boxToggle(isExpanded, id, 'pg');
    }

    buildBody(AccordionBody, bodyItems) {
        const { extraItem } = this.props;

        if (extraItem) {
            bodyItems.push(extraItem);
        }

        return cloneElement(AccordionBody, {}, bodyItems);
    }

    render() {
        const { children,
            hasExcludedItems,
            id,
            isExpanded,
            maxVisibleItems } = this.props;

        let bodyItems;
        const AccordionHeader = find(children, (child) => child.props.name === 'AccordionHeader');
        let AccordionBody = find(children, (child) => child.props.name === 'AccordionBody');

        if (Children.count(AccordionBody.props.children) > maxVisibleItems || hasExcludedItems) {
            bodyItems = slice(Children.toArray(AccordionBody.props.children), 0, maxVisibleItems);
        }
        else {
            bodyItems = Children.toArray(AccordionBody.props.children);
        }

        AccordionBody = this.buildBody(AccordionBody, bodyItems);

        return (
            <Accordion
                customClassName="glb-position-collapsable"
                isExpanded={isExpanded}
                onClick={this.handleClick}
                id={id}
            >
                {AccordionHeader}
                {AccordionBody}
            </Accordion>
        )
    }
}

GlobalPositionCollapsable.propTypes = {
    boxToggle: PropTypes.func,
    children: PropTypes.any,
    extraItem: PropTypes.object,
    hasExcludedItems: PropTypes.bool,
    id: PropTypes.string,
    isExpanded: PropTypes.bool,
    maxVisibleItems: PropTypes.number
};

GlobalPositionCollapsable.defaultProps = {
    hasExcludedItems: false,
    isExpanded: false,
    maxVisibleItems: 4
};

module.exports.constructor = GlobalPositionCollapsable;
module.exports.GlobalPositionCollapsable = connect(null,
    {
        boxToggle: landingActions.boxToggle
    }
)(GlobalPositionCollapsable);
