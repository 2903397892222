require('./globalPositionShowMoreItems.scss');
const PropTypes = require('prop-types');
const React = require('react');

const { OKLink } = require('commonsComponents/OKLink');

const ShowMoreItems = (props) =>
    (<div className="glb-position-showmore">
        <OKLink modifier="widget" inverse={true} onClick={props.handleOnSeeMoreClick} to={props.showMoreLink} children={props.showMoreText} />
    </div>);

ShowMoreItems.propTypes = {
    showMoreText: PropTypes.string,
    showMoreLink: PropTypes.string.isRequired,
    handleOnSeeMoreClick: PropTypes.func,
};

module.exports = ShowMoreItems;