const { formatText } = require('core/i18n').i18n;

const FEE_CALCULATE = {
    MAX_AGE_PLUS_TERM_ALLOWED: 75,
    MULTIPLIER_MORTGAGE_PRICE_FIRST_HOUSING: 0.8,
    MULTIPLIER_MORTGAGE_PRICE_SECOND_HOUSING: 0.7,
    MIN_TERM_ALLOWED_HOUSING: 5,
    MAX_TERM_ALLOWED_FIRST_HOUSING: 30,
    MAX_TERM_ALLOWED_SECOND_HOUSING: 25,
    MAX_IMPORT_VALUE_ALLOWED: 3000000,
    MIN_IMPORT_VALUE_ALLOWED: 30000,
    MAX_DTI: 0.4,
    SMI: 707.70
};

const MORTGAGE_TYPE = {
    FIJO: 'openFija',
    MIXTO: 'openMixta',
    VARIABLE: 'openVariable'
};

const MORTGAGE_TYPE_TEXT = {
    FIJA: 'Fija',
    MIXTA: 'Mixta',
    VARIABLE: 'Variable',
    FIJO: 'Fija',
    MIXTO: 'Mixta'
}

const HOUSING_STATUS = {
    NUEVA: 'NUEVA',
    SEGUNDA_MANO: 'SEGUNDA_MANO'
};

const AUTONOMOUS_COMMUNITY = {
    ANDALUCIA: 'ANDALUCIA',
    ARAGON: 'ARAGON',
    ASTURIAS: 'ASTURIAS',
    BALEARS: 'BALEARS',
    CANARIAS: 'CANARIAS',
    CANTABRIA: 'CANTABRIA',
    CASTILLA_LA_MANCHA: 'CASTILLA_LA_MANCHA',
    CASTILLA_LEON: 'CASTILLA_LEON',
    CATALUNA: 'CATALUNA',
    EXTREMADURA: 'EXTREMADURA',
    RIOJA: 'RIOJA',
    MADRID: 'MADRID',
    MURCIA: 'MURCIA',
    NAVARRA: 'NAVARRA',
    PAIS_VASCO: 'PAIS_VASCO',
    COMUNITAT_VALENCIANA: 'COMUNITAT_VALENCIANA',
    CEUTA: 'CEUTA',
    MELILLA: 'MELILLA',
    GALICIA: 'GALICIA'
};

const HOME_USE = {
    PRIMERA_VIVIENDA: 'PRIMERA_VIVIENDA',
    SEGUNDA_VIVIENDA: 'SEGUNDA_VIVIENDA'
};

const PRODUCT_TYPE = {
    FIJO: 'FIJO',
    MIXTO: 'MIXTO',
    VARIABLE: 'VARIABLE'
};

const GENERAL_DATA = {
    CURRENCY: 'EUR',
    PRODUCT_NUMBER: '103',
    ORGANIZATION: '0073',
    OPERATION: 'A',
    PAST_DATE: {
        YEAR: 1900,
        MONTH: 0,
        DAY: 1
    },
    FUTURE_DATE: {
        YEAR: 9999,
        MONTH: 0,
        DAY: 1
    }
};



const getMortgageInfo = () => {
    return {
        estadoVivienda: [
            {
                label: formatText('mortgageRequestMortgageEdit-formTipoViviendaOption1'),
                value: HOUSING_STATUS.NUEVA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formTipoViviendaOption2'),
                value: HOUSING_STATUS.SEGUNDA_MANO
            }
        ],
        comunidadAutonoma: [
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption1'),
                value: AUTONOMOUS_COMMUNITY.ANDALUCIA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption2'),
                value: AUTONOMOUS_COMMUNITY.ARAGON
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption3'),
                value: AUTONOMOUS_COMMUNITY.ASTURIAS
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption4'),
                value: AUTONOMOUS_COMMUNITY.BALEARS
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption5'),
                value: AUTONOMOUS_COMMUNITY.CANARIAS
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption6'),
                value: AUTONOMOUS_COMMUNITY.CANTABRIA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption7'),
                value: AUTONOMOUS_COMMUNITY.CASTILLA_LA_MANCHA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption8'),
                value: AUTONOMOUS_COMMUNITY.CASTILLA_LEON
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption9'),
                value: AUTONOMOUS_COMMUNITY.CATALUNA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption10'),
                value: AUTONOMOUS_COMMUNITY.EXTREMADURA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption11'),
                value: AUTONOMOUS_COMMUNITY.RIOJA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption12'),
                value: AUTONOMOUS_COMMUNITY.MADRID
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption13'),
                value: AUTONOMOUS_COMMUNITY.MURCIA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption14'),
                value: AUTONOMOUS_COMMUNITY.NAVARRA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption15'),
                value: AUTONOMOUS_COMMUNITY.PAIS_VASCO
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption16'),
                value: AUTONOMOUS_COMMUNITY.COMUNITAT_VALENCIANA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption17'),
                value: AUTONOMOUS_COMMUNITY.CEUTA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption18'),
                value: AUTONOMOUS_COMMUNITY.MELILLA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formComunidadAutonomaOption19'),
                value: AUTONOMOUS_COMMUNITY.GALICIA
            }
        ],
        usoVivienda: [
            {
                label: formatText('mortgageRequestMortgageEdit-formUsoViviendaOption1'),
                value: HOME_USE.PRIMERA_VIVIENDA
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formUsoViviendaOption2'),
                value: HOME_USE.SEGUNDA_VIVIENDA
            }
        ],
        tipoProducto: [
            {
                label: formatText('mortgageRequestMortgageEdit-formTipoHipotecaOption1'),
                value: PRODUCT_TYPE.FIJO
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formTipoHipotecaOption2'),
                value: PRODUCT_TYPE.MIXTO
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formTipoHipotecaOption3'),
                value: PRODUCT_TYPE.VARIABLE
            }
        ],
        tipoProductoNoMixto: [
            {
                label: formatText('mortgageRequestMortgageEdit-formTipoHipotecaOption1'),
                value: PRODUCT_TYPE.FIJO
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formTipoHipotecaOption3'),
                value: PRODUCT_TYPE.VARIABLE
            }
        ],
        incluyeSeguroHogar: [
            {
                label: formatText('mortgageRequestMortgageEdit-formSeguroHogarOption1'),
                value: true
            },
            {
                label: formatText('mortgageRequestMortgageEdit-formSeguroHogarOption2'),
                value: false
            }
        ]
    }
}

const MORTGAGE_GROUP_CODE = 'AAAB';

module.exports = {
    HOUSING_STATUS,
    AUTONOMOUS_COMMUNITY,
    FEE_CALCULATE,
    HOME_USE,
    MORTGAGE_TYPE,
    GENERAL_DATA,
    MORTGAGE_TYPE_TEXT,
    MORTGAGE_GROUP_CODE,
    getMortgageInfo
};
