// @ vendor
const React = require('react');
const PropTypes = require('prop-types');

// @ commons
const OKTooltip = require('components/commons/OKTooltip/OKTooltip');
const OKModalCommons = require('commonsComponents/OKModal');

// @utilities
const { FormattedText, formatText, hasTranslation } = require('core/i18n').i18n;
import { recordLinkV2 } from 'utilities/tagging';

class OKModal extends React.Component {

    componentDidMount() {
        const { analyticsDataView } = this.props;
        analyticsDataView && recordLinkV2(analyticsDataView);
    }

    addTooltip(target, tooltipText) {
        let element = target;

        if (!!tooltipText) {
            const tooltipTarget = <span>{target}</span>;
            element = (
                <OKTooltip
                    content={tooltipText}
                    offset={[15, 0]}
                    placement="right"
                    target={tooltipTarget}
                />
            );
        }

        return element;
    }

    checkTranslation(translationKey, getObject = false) {
        let translation = translationKey;

        if (typeof translationKey === 'string' && hasTranslation(translationKey)) {
            if (getObject) {
                translation = <FormattedText value={translationKey} />;
            } else {
                translation = formatText(translationKey);
            }
        }

        return translation;
    }

    render() {
        let {
            content,
            description,
            primaryButtonText,
            secondaryButtonText,
            tertiaryButtonText,
            title,
            titleTooltip
        } = this.props;
        let okModalProps = Object.assign({}, this.props);

        okModalProps.content = this.checkTranslation(content, true);
        okModalProps.title = this.addTooltip(this.checkTranslation(title, true), titleTooltip);
        okModalProps.description = this.checkTranslation(description, true);
        okModalProps.primaryButtonText = this.checkTranslation(primaryButtonText);
        okModalProps.secondaryButtonText = this.checkTranslation(secondaryButtonText);
        okModalProps.tertiaryButtonText = this.checkTranslation(tertiaryButtonText);

        return (
            <OKModalCommons
                {...okModalProps}
            />
        );
    }
}

OKModal.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    description: PropTypes.string,
    getSize: PropTypes.func,
    primaryButtonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    tertiaryButtonText: PropTypes.string,
    title: PropTypes.string,
    titleTooltip: PropTypes.string,
    overlayClassName: PropTypes.string,
    analyticsDataView: PropTypes.object,
};

module.exports = OKModal;
