// @vendor
const React = require('react');
const { isEqual } = require('lodash/lang');
const { includes, filter } = require('lodash/collection');
const PropTypes = require('prop-types');
import classNames from 'classnames';

// @core
const { formatText, FormattedText } = require('core/i18n').i18n;

// @components
import FeatureTogglingRender from 'components/conditionalRender/featureTogglingRender';

// @helpers
const PersonalDataHelper = require('utilities/personalDataHelper');

// @hocs
import withFeatureToggling from 'hocs/withFeatureToggling/withFeatureToggling';

// @constants
const {
    ES_POSTAL_CODE_MAX_LENGTH,
    PT_POSTAL_CODE_MAX_LENGTH,
    DE_POSTAL_CODE_MAX_LENGTH,
    NL_POSTAL_CODE_MAX_LENGTH, } = require("constants/index");

// @commons
const { InputSafe } = require('commonsComponents/inputSafe');
const OKSelect = require('components/commons/OKSelect/OKSelect');
const POSTAL_CODE_REGEX = require("commonsConstants/postalCodes");

// @constants
const NUMBER_STREET_ES_MAX_LENGTH = 3
const NUMBER_STREET_NO_ES_MAX_LENGTH = 3
const REST_OF_ADDRESS_ES_MAX_LENGTH = 40
const REST_OF_ADDRESS_NO_ES_MAX_LENGTH = 16
const STREET_NAME_ES_MAX_LENGTH = 24
const STREET_NAME_NO_ES_MAX_LENGTH = 32
const { COUNTRIES_CODES: { ES } } = require('constants/countries');
const { NON_NUMERIC_REGEXP } = require('constants/index');
const { countryOptions } = require('constants/countries');
const { typesOfRoadsOptions } = require('constants/typeOfRoads');
const PROVINCES = require('constants/provinces');
const PROVINCES_OPTIONS = Object.getOwnPropertyNames(PROVINCES).map(property => ({
    value: property,
    label: PROVINCES[property]
}));
const PROVINCES_OPTIONS_ORDERED = PROVINCES_OPTIONS.sort((a, b) => a.label.localeCompare(b.label));
const { PROVINCE, TYPE_OF_ROAD } = require('constants/flagBasedFeatures');


class EditAddressForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleValidateAddressField = this.handleValidateAddressField.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.buildErrorSection = this.buildErrorSection.bind(this);
        this.buildHeadSection = this.buildHeadSection.bind(this);
    }

    normalizeData = (originCountry, name, value) => {
        return (originCountry == ES) ? this.normalizeDataEs(name, value) : value;
    }

    normalizeDataEs = (name, value) => {
        switch (name) {
            case 'postalCode':
            case 'numberStreet':
                return value.replace(NON_NUMERIC_REGEXP, '').trim();
            default:
                return value;
        }
    }

    handleInputChange(event) {
        const { retailLoanCall, handleEditValuePersonalData, immProfileFields } = this.props;
        const originCountry = immProfileFields.get('originCountry');
        const { name } = event.target;
        let { value } = event.target;
        value = this.normalizeData(originCountry, name, value);

        if (retailLoanCall) {
            handleEditValuePersonalData(name, value);
        } else {
            this.props.actionChangeValue({
                [name]: value,
                [name + 'Blur']: false
            });
        }
    }

    handleSelectChange(event, prop) {
        const { retailLoanCall, handleEditValuePersonalData } = this.props;
        const value = event.value || '';
        if (retailLoanCall) {
            handleEditValuePersonalData(prop, value);
        } else {
            this.props.actionChangeValue({
                [prop]: value,
                [prop + 'Blur']: false
            });
        }
    }

    handleValidateAddressField(event) {
        const { target: { value } } = event;
        if (PersonalDataHelper.isValidRoadNameInput(value)) {
            this.handleInputChange(event);
        }
    }

    buildErrorSection(validateError) {
        if (validateError) {
            return (
                <div className="edit-form-step__content-error-addres-form">
                    <div>
                        <i className="icon edit-form-step__icon-error icon-error" />
                    </div>
                    <div className="edit-form-step__content-subtitle-addres-form">
                        <FormattedText value="profile-errorHeader" />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    buildHeadSection(validateError) {
        const { retailLoanCall, immProfileFields, propEdit } = this.props;
        const birthCountry = formatText('profile-taxInformation_birthCountry');
        const selectNotFound = formatText('profile-selectNotFound');
        const selectPlaceholder = formatText('profile-selectPlaceholder');
        const errorSection = this.buildErrorSection(validateError);
        if (retailLoanCall) {
            const titleModal = isEqual(propEdit, 'deliveryAddress') ? "contractRetailLoanStepTwo-titleDataBilling" : "contractRetailLoanStepTwo-deliveryAddress";
            return (
                <div>
                    <div className="row edit-form-step__title-modal-personal-data">
                        <FormattedText value={titleModal} />
                    </div>
                    {errorSection}
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-xs-12 edit-form-step__input">
                        <OKSelect
                            clearable={false}
                            label={birthCountry}
                            matchPos="start"
                            matchProp="label"
                            noResultsText={selectNotFound}
                            options={countryOptions()}
                            placeholder={selectPlaceholder}
                            value={immProfileFields.get('originCountry')}
                            disabled={true}
                        />
                    </div>
                </div>
            );
        }
    }

    // TODO: Refactor when address suggest added.
    validateStreetName = (originCountry) => {
        return originCountry == ES ? STREET_NAME_ES_MAX_LENGTH : STREET_NAME_NO_ES_MAX_LENGTH;
    }

    validateNumberStreet = (originCountry) => {
        return originCountry == ES ? NUMBER_STREET_ES_MAX_LENGTH : NUMBER_STREET_NO_ES_MAX_LENGTH;
    }

    validateRestOfAddress = (originCountry) => {
        return originCountry == ES ? REST_OF_ADDRESS_ES_MAX_LENGTH : REST_OF_ADDRESS_NO_ES_MAX_LENGTH;
    }

    getPostalCodeMaxLength = (originCountry) => {
        switch (originCountry) {
            case "ES":
                return ES_POSTAL_CODE_MAX_LENGTH;
            case "PT":
                return PT_POSTAL_CODE_MAX_LENGTH;
            case "DE":
                return DE_POSTAL_CODE_MAX_LENGTH;
            case "NL":
                return NL_POSTAL_CODE_MAX_LENGTH;
            default:
                return ES_POSTAL_CODE_MAX_LENGTH;
        }
    }

    render() {
        const { retailLoanCall, flagBasedFeatures, immProfileEditor, immProfileFields, personalData, callEventSubmit, provincesExlude } = this.props;
        const addressError = formatText('profile-incompleteAddress');
        const addressName = formatText('profile-addressName');
        const addressNumber = formatText('profile-addressNumber');
        const addressNumberError = formatText('profile-incompleteAddressNumber');
        const addressType = formatText('profile-addressType');
        const city = formatText('profile-city');
        const dropdownError = formatText('profile-incompleteDropdown');
        const localityError = formatText('profile-incompleteCity');
        const postalCodeText = formatText('profile-postCode');
        const postalCodeEmpty = formatText('profile-incompletePostalCode');
        const postalCodeMismatch = formatText('profile-mismatchedPostalCode');
        const provinceText = formatText('profile-province');
        const restOfAddressText = formatText('profile-restOfAddress');
        const selectNotFound = formatText('profile-selectNotFound');
        const selectPlaceholder = formatText('profile-selectPlaceholder');
        const originCountry = immProfileFields.get("originCountry");
        const immStep = retailLoanCall
            ? personalData
            : immProfileEditor.getIn(["steps", 0]);
        const validationPostalCodeRegex = POSTAL_CODE_REGEX[originCountry].test(
            immStep.get("postalCode")
        );
        const validateCallSubmitRetail = retailLoanCall && callEventSubmit;
        const localityErrorState = (validateCallSubmitRetail || immStep.get('localityBlur')) && immStep.get('locality') === '';
        const numberStreetErrorState = (validateCallSubmitRetail || immStep.get('numberStreetBlur')) && immStep.get('numberStreet') === '';
        const wrongPostalCode = (validateCallSubmitRetail || immStep.get("postalCodeBlur")) && !immStep.get("postalCode").startsWith(immStep.get("province"));
        const postalCodeErrorState = wrongPostalCode || !validationPostalCodeRegex;
        const mismatchOrEmptyPostalCode = (validationPostalCodeRegex ? postalCodeMismatch : postalCodeEmpty);
        const postalCodeErrorMessage = (validateCallSubmitRetail || postalCodeErrorState) && mismatchOrEmptyPostalCode;
        const provinceErrorState = (validateCallSubmitRetail || immStep.get('provinceBlur')) && immStep.get('province') === '';
        const streetNameErrorState = (validateCallSubmitRetail || immStep.get('streetNameBlur')) && immStep.get('streetName') === '';
        const typeOfRoadsErrorState = (validateCallSubmitRetail || immStep.get('typeOfRoadsBlur')) && immStep.get('typeOfRoads') === '';
        const validateError = localityErrorState || numberStreetErrorState || postalCodeErrorState ||
            provinceErrorState || streetNameErrorState || typeOfRoadsErrorState ? true : false;
        const headSection = this.buildHeadSection(validateError);
        const FINAL_PROVINCES_OPTIONS = isEqual(provincesExlude, undefined) ? PROVINCES_OPTIONS_ORDERED :
            filter(PROVINCES_OPTIONS_ORDERED, item => !includes(provincesExlude, item.label));
        const typeOfRoadIsVisible = flagBasedFeatures.get(TYPE_OF_ROAD);
        const provinceIsVisible = flagBasedFeatures.get(PROVINCE);
        const streetNameClassName = classNames('edit-form-step__input', {
            'col-xs-6': typeOfRoadIsVisible,
            'col-xs-12': !typeOfRoadIsVisible
        });
        const noProvinceNameClassName = classNames('edit-form-step__input', {
            'col-xs-4': provinceIsVisible,
            'col-xs-6': !provinceIsVisible
        });

        const typeOfRoadsOptions = typesOfRoadsOptions();

        return (
            <div>
                {headSection}
                <div className="row">
                    <FeatureTogglingRender notAllowedProductKey={TYPE_OF_ROAD}>
                        <div className="col-xs-6 edit-form-step__input">
                            <OKSelect
                                clearable={false}
                                label={addressType}
                                matchPos="start"
                                matchProp="label"
                                noResultsText={selectNotFound}
                                onChange={event =>
                                    this.handleSelectChange(
                                        event,
                                        "typeOfRoads"
                                    )
                                }
                                options={typeOfRoadsOptions}
                                placeholder={selectPlaceholder}
                                value={immStep.get("typeOfRoads")}
                                errorState={typeOfRoadsErrorState}
                                errorMessage={dropdownError}
                            />
                        </div>
                    </FeatureTogglingRender>
                    <div className={streetNameClassName}>
                        <InputSafe
                            label={addressName}
                            maxLength={this.validateStreetName(originCountry)}
                            name="streetName"
                            onBlur={this.handleBlur}
                            onChange={this.handleValidateAddressField}
                            type="text"
                            value={immStep.get("streetName")}
                            errorState={streetNameErrorState}
                            errorMessage={addressError}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-6 edit-form-step__input">
                        <InputSafe
                            label={addressNumber}
                            maxLength={this.validateNumberStreet(originCountry)}
                            name="numberStreet"
                            onBlur={this.handleBlur}
                            onChange={this.handleInputChange}
                            type="text"
                            value={immStep.get("numberStreet")}
                            errorState={numberStreetErrorState}
                            errorMessage={addressNumberError}
                        />
                    </div>
                    <div className="col-xs-6 edit-form-step__input">
                        <InputSafe
                            label={restOfAddressText}
                            maxLength={this.validateRestOfAddress(
                                originCountry
                            )}
                            name="restOfAddress"
                            onBlur={this.handleBlur}
                            onChange={this.handleInputChange}
                            type="text"
                            value={immStep.get("restOfAddress")}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className={noProvinceNameClassName}>
                        <InputSafe
                            label={postalCodeText}
                            maxLength={this.getPostalCodeMaxLength(
                                originCountry
                            )}
                            name="postalCode"
                            onBlur={this.handleBlur}
                            onChange={this.handleInputChange}
                            type="text"
                            value={immStep.get("postalCode")}
                            errorState={postalCodeErrorState}
                            errorMessage={postalCodeErrorMessage}
                            placeholder={formatText("profile-postCode", [], originCountry.toLowerCase(), originCountry)}
                        />
                    </div>
                    <div className={noProvinceNameClassName}>
                        <InputSafe
                            label={city}
                            maxLength={50}
                            name="locality"
                            onBlur={this.handleBlur}
                            onChange={this.handleValidateAddressField}
                            type="text"
                            value={immStep.get("locality")}
                            errorState={localityErrorState}
                            errorMessage={localityError}
                        />
                    </div>
                    <FeatureTogglingRender notAllowedProductKey={PROVINCE}>
                        <div className="col-xs-4 edit-form-step__input">
                            <OKSelect
                                clearable={false}
                                label={provinceText}
                                matchPos="start"
                                matchProp="label"
                                noResultsText={selectNotFound}
                                onChange={event =>
                                    this.handleSelectChange(event, "province")
                                }
                                options={FINAL_PROVINCES_OPTIONS}
                                placeholder={selectPlaceholder}
                                value={immStep.get("province")}
                                errorState={provinceErrorState}
                                errorMessage={dropdownError}
                            />
                        </div>
                    </FeatureTogglingRender>
                </div>
            </div>
        );
    }
}
EditAddressForm.propTypes = {
    actionChangeValue: PropTypes.func,
    flagBasedFeatures: PropTypes.object.isRequired,
    immProfileFields: PropTypes.object,
    immProfileEditor: PropTypes.object,
    personalData: PropTypes.object,
    retailLoanCall: PropTypes.bool,
    callEventSubmit: PropTypes.bool,
    handleEditValuePersonalData: PropTypes.func,
    propEdit: PropTypes.string,
    provincesExlude: PropTypes.object
}

module.exports = withFeatureToggling(EditAddressForm);
