require('./tagNew.scss');

const PropTypes = require('prop-types');
// @ vendors
const React = require('react');
const { connect } = require('react-redux');
const OKTag = require('components/commons/OKTag/OKTag');

class TagNew extends React.Component {
    render() {
        const { id, immGlobalVariables, context } = this.props;
        let content = (<span/>);

        if (immGlobalVariables.hasIn(['variables','parameters','newLabel',id])) {
            const immNewTag = immGlobalVariables.getIn(['variables','parameters','newLabel',id]);

            content = (
                <OKTag type="new" context={context} content={immNewTag.get('text')}/>
                );
        }

        return content;
    }
}

TagNew.propTypes = {
    context: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    immGlobalVariables: PropTypes.object.isRequired
};

module.exports.constructor = TagNew;
module.exports.TagNew = connect(
    state => ({
        immGlobalVariables: state.globalVariables
    })
)(TagNew);
