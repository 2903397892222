require('./stocksContractDropdown.scss');

const PropTypes = require('prop-types');

// @vendor
const React = require('react');
const classNames = require('classnames');

// @helpers
const { formatText, FormattedText } = require('core/i18n').i18n;
const AccountOption = require('./stocksContractDropdownAccountOption');
const AccountTransferValuesOption = require('./stocksContractDropdownAccountTransferValuesOption');
const AccountTransferValuesValue = require('./stocksContractDropdownAccountTransferValuesValue');
const AccountExternalTransferValue = require('./stocksContractDropdownAccountTransferExternalValue');
const AccountExternalTransferOption = require('./stocksContractDropdownAccountTransferExternalOption');
const ContractOption = require('./stocksContractDropdownContractOption');
const ContractGroupedOption = require('./stocksContractDropdownContractGroupedOption');
const TitleOption = require('./stocksContractDropdownTitleOption');
const AccountValue = require('./stocksContractDropdownAccountValue');
const ContractValue = require('./stocksContractDropdownContractValue');
const TitleValue = require('./stocksContractDropdownTitleValue');
const DerivativesTransferOption = require('./stocksContractDropdownDerivativesTransferOption');
const DerivativesTransferValue = require('./stocksContractDropdownDerivativesTransferValue');
const FundsTransferOption = require('./stocksContractDropdownFundsTransferOption');
const FundsTransferValue = require('./stocksContractDropdownFundsTransferValue');
const FuturesOption = require('./stocksContractDropdownFuturesOption');
const FuturesValue = require('./stocksContractDropdownFuturesValue');
const FundsSubscriptionOption = require('./stocksContractDropdownFundSubscriptionOption');
const FundsSubscriptionValue = require('./stocksContractDropdownFundSubscriptionValue');

// @constants
const {
    BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_MODIFIER,
    BROKER_STOCKS_CONTRACT_DROPDOWN_TITLES_MODIFIER,
    BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_TRANSFER_VALUES_MODIFIER,
    BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_EXTERNAL_TRANSFER_MODIFIER,
    BROKER_STOCKS_CONTRACT_DROPDOWN_ASSOCIATED_ACCOUNT_MODIFIER,
    BROKER_STOCKS_CONTRACT_DROPDOWN_CONTRACTS_MODIFIER,
    BROKER_STOCKS_CONTRACT_DROPDOWN_GROUPED_CONTRACTS_MODIFIER,
    BROKER_STOCKS_CONTRACT_DROPDOWN_DERIVATIVES_TRANSFER_MODIFIER,
    BROKER_STOCKS_CONTRACT_DROPDOWN_FUNDS_TRANSFER_MODIFIER,
    BROKER_STOCKS_CONTRACT_DROPDOWN_FUTURES_DERIVATIVES_MODIFIER,
    BROKER_STOCKS_CONTRACT_DROPDOWN_FUND_SUBSCRIPTION_MODIFIER
} = require('constants/index');
const { getLastNChars } = require('utilities/stringHelper');
// @utilities
const { getBlockedAccountsFlags } = require('utilities/blockedAccountsHelper');
// @commons
const { OKMessage } = require('commonsComponents/OKMessage');
const OKSelect = require('components/commons/OKSelect/OKSelect');
const { LoadingSection } = require('commonsComponents/loadingSection');

class StocksContractDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.buildResultsMenuScrollTop = this.buildResultsMenuScrollTop.bind(this);
    }

    buildAdditionalInfoTextBlock() {
        const { options, selectedValue, modifier } = this.props;
        let result = <div />;

        if (selectedValue >= 0 && options[selectedValue]) {
            const selectedOption = options[selectedValue];
            if (selectedOption
                && modifier === BROKER_STOCKS_CONTRACT_DROPDOWN_ASSOCIATED_ACCOUNT_MODIFIER
                    || modifier === BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_EXTERNAL_TRANSFER_MODIFIER) {
                result = (
                    <FormattedText
                        value="broker-stocksContractDropdownAdditionalInfoTextAssociatedAccount"
                        injectedStrings={[selectedOption.firstOwner, selectedOption.firstOwnerType]}
                        className="stocks-contract-dropdown__additional-info-text"
                    />
                );
            } else if (modifier === BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_TRANSFER_VALUES_MODIFIER) {
                const associatedAccountLastChars = getLastNChars(selectedOption.associatedAccount);
                const selectedTitularType = formatText(`interventionTypes-${selectedOption.firstTitularType}`);
                result = (
                    <FormattedText
                        value="broker-stocksContractDropdownAdditionalInfoTextTransferValues"
                        injectedStrings={[selectedOption.associatedAccountAlias, associatedAccountLastChars, selectedOption.firstTitular, selectedTitularType]}
                        className="stocks-contract-dropdown__additional-info-text"
                    />
                );
            } else if (modifier === BROKER_STOCKS_CONTRACT_DROPDOWN_FUTURES_DERIVATIVES_MODIFIER) {
                const ibanLastChars = getLastNChars(selectedOption.iban);
                result = (
                    <FormattedText
                        value="broker-stocksContractDropdownAdditionalInfoTextFuturesValues"
                        injectedStrings={[
                            selectedOption.descripcion,
                            ibanLastChars,
                            selectedOption.nombretitular
                        ]}
                        className="stocks-contract-dropdown__additional-info-text"
                    />
                );
             } else {
                const optionExists = !!selectedOption;
                if (optionExists) {
                    const selectedTitularType = formatText(`interventionTypes-${selectedOption.firstTitularType}`);
                    result = (
                        <FormattedText
                            value="broker-stocksContractDropdownAdditionalInfoText"
                            injectedStrings={[
                                selectedOption.firstTitular,
                                selectedTitularType,
                                selectedOption.associatedAccountAlias,
                                getLastNChars(selectedOption.associatedAccount)
                            ]}
                            className="stocks-contract-dropdown__additional-info-text"
                        />
                    );
                }
            }
        }
        if (modifier === BROKER_STOCKS_CONTRACT_DROPDOWN_TITLES_MODIFIER && selectedValue >= 0) {
            const selectedOption = options.find(option => option.value === selectedValue);
            const optionExists = !!selectedOption;
            if (optionExists) {
                const selectedTitularType = formatText(`interventionTypes-${selectedOption.firstTitularType}`);
                result = (
                    <FormattedText
                        value="broker-stocksContractDropdownAdditionalInfoText"
                        injectedStrings={[
                            selectedOption.firstTitular,
                            selectedTitularType,
                            selectedOption.associatedAccountAlias,
                            getLastNChars(selectedOption.associatedAccount)
                        ]}
                        className="stocks-contract-dropdown__additional-info-text"
                    />
                );
            }
        }

        return result;
    }

    /**
     * Duplicated from react-select defaultMenuRenderer.js to build
     * the menu with scroll starting at the very top
     * @param {Object} props
     */
    buildResultsMenuScrollTop(props) {
        let Option = props.optionComponent;

        return props.options.map((option, i) => {
            let isSelected = props.valueArray && props.valueArray.indexOf(option) > -1;
            let isFocused = false;
            let optionClass = classNames(props.optionClassName, {
                'Select-option': true,
                'is-selected': isSelected,
                'is-focused': isFocused,
                'is-disabled': option.disabled,
            });

            const key = `option-${i}-${option[props.valueKey]}`;
            const ref = r => { props.onOptionRef(r, isFocused); };
            return (
                <Option
                    className={optionClass}
                    instancePrefix={props.instancePrefix}
                    isDisabled={option.disabled}
                    isFocused={isFocused}
                    isSelected={isSelected}
                    key={key}
                    onFocus={props.onFocus}
                    onSelect={props.onSelect}
                    option={option}
                    optionIndex={i}
                    ref={ref}
                >
                    {props.optionRenderer(option, i)}
                </Option>
            );
        });
    }

    handleSelectChange(option) {
        if (!option.disabled) {
            this.props.onStockContractChange(option.value);
        }
    }

    buildDisclaimer(accountId) {
        const {
            immAccounts,
            disclaimer
        } = this.props;
        const blockedAccountsIds = immAccounts.get('blockedAccountsIds');
        const blockedAccountFlags = getBlockedAccountsFlags(accountId, blockedAccountsIds);
        let content = null;
        if (blockedAccountFlags.atLeastOneBlocked) {
            const messageType = blockedAccountFlags.allBlocked ? 'warning' : 'important';
            const disclaimerValue = disclaimer[messageType];
            content = (
                <div className="dropdown-accounts__disclaimer">
                    <OKMessage
                        type={messageType}
                    >
                        <FormattedText
                            value={disclaimerValue}
                        />
                    </OKMessage>
                </div>
            )
        }

        return content;
    }

    render() {

        const {
            options,
            selectedValue,
            placeholder,
            immAccounts,
            modifier,
            label,
            loading,
            showLabel,
            showAdditionalInfo,
            errorState,
            errorMessage,
            disabled,
            startResultsScrollAtTop
        } = this.props;
        const additionalInfoTextBlock = showAdditionalInfo && this.buildAdditionalInfoTextBlock();
        const translatedLabel = formatText(label);
        let optionComponent, valueComponent, menuRenderer, disclaimer;
        let modifiedAccounts = options;
        let wrapperClassName = 'stocks-contract-dropdown';
        if (immAccounts) {
            const immAccountsById = immAccounts.get('byId');
            const blockedAccountsIds = immAccounts.get('blockedAccountsIds');
            if (modifier === BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_MODIFIER || modifier === BROKER_STOCKS_CONTRACT_DROPDOWN_DERIVATIVES_TRANSFER_MODIFIER){
                let accountsId = [];
                modifiedAccounts = modifiedAccounts.map(option => {
                    const immAccount = immAccountsById.toList().find(account => {
                            return account.getIn(["ibanComplex","codbban"]) === option.associatedAccount
                    })
                    if (immAccount && !accountsId.includes(immAccount.get('accountId'))) {
                        accountsId.push(immAccount.get('accountId'))
                    }

                    return { ...option, ...(
                        immAccount ? { isBlocked: blockedAccountsIds.contains(immAccount.get('accountId'))} : []
                    )};
                });
                disclaimer = this.buildDisclaimer(accountsId);
            }
        }

        if (startResultsScrollAtTop) {
            menuRenderer = this.buildResultsMenuScrollTop;
        }

        switch (modifier) {
            case BROKER_STOCKS_CONTRACT_DROPDOWN_DERIVATIVES_TRANSFER_MODIFIER:
                optionComponent = DerivativesTransferOption;
                valueComponent = DerivativesTransferValue;
                break;
            case BROKER_STOCKS_CONTRACT_DROPDOWN_FUNDS_TRANSFER_MODIFIER:
                optionComponent = FundsTransferOption;
                valueComponent = FundsTransferValue;
                break;
            case BROKER_STOCKS_CONTRACT_DROPDOWN_TITLES_MODIFIER:
                optionComponent = TitleOption;
                valueComponent = TitleValue;
                break;
            case BROKER_STOCKS_CONTRACT_DROPDOWN_CONTRACTS_MODIFIER:
                optionComponent = ContractOption;
                valueComponent = ContractValue;
                break;
            case BROKER_STOCKS_CONTRACT_DROPDOWN_GROUPED_CONTRACTS_MODIFIER:
                optionComponent = ContractGroupedOption;
                valueComponent = ContractValue;
                break;
            case BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_TRANSFER_VALUES_MODIFIER:
                optionComponent = AccountTransferValuesOption;
                valueComponent = AccountTransferValuesValue;
                break;
            case BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_EXTERNAL_TRANSFER_MODIFIER:
                optionComponent = AccountExternalTransferOption;
                valueComponent = AccountExternalTransferValue;
                break;
            case BROKER_STOCKS_CONTRACT_DROPDOWN_FUTURES_DERIVATIVES_MODIFIER:
                 optionComponent = FuturesOption;
                 valueComponent = FuturesValue;
                 break;
            case BROKER_STOCKS_CONTRACT_DROPDOWN_FUND_SUBSCRIPTION_MODIFIER:
                optionComponent = FundsSubscriptionOption;
                valueComponent = FundsSubscriptionValue;
                wrapperClassName = 'fund-subscription stocks-contract-dropdown';
                break
            default:
                optionComponent = AccountOption;
                valueComponent = AccountValue;
        }
        const content = loading ? <LoadingSection /> : (
            <OKSelect
                label={translatedLabel}
                showLabel={showLabel}
                onChange={this.handleSelectChange}
                optionComponent={optionComponent}
                valueComponent={valueComponent}
                options={modifiedAccounts}
                value={selectedValue}
                autosize={false}
                placeholder={placeholder}
                errorState={errorState}
                errorMessage={errorMessage}
                disabled={disabled}
                menuRenderer={menuRenderer}
            />
        )

        return (
            <div className={wrapperClassName}>
                {content}
                {disclaimer}
                {additionalInfoTextBlock}
            </div>
        );
    }
}

StocksContractDropdown.propTypes = {
    label: PropTypes.string,
    disclaimer: PropTypes.shape({
        important: PropTypes.string,
        warning: PropTypes.string,
    }),
    immAccounts: PropTypes.object,
    immBrokerStocksContract: PropTypes.object,
    loading: PropTypes.bool,
    showAdditionalInfo: PropTypes.bool,
    showLabel: PropTypes.bool,
    onStockContractChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.object.isRequired,
    modifier: PropTypes.oneOf([
        BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_MODIFIER,
        BROKER_STOCKS_CONTRACT_DROPDOWN_TITLES_MODIFIER,
        BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_TRANSFER_VALUES_MODIFIER,
        BROKER_STOCKS_CONTRACT_DROPDOWN_CONTRACTS_MODIFIER,
        BROKER_STOCKS_CONTRACT_DROPDOWN_GROUPED_CONTRACTS_MODIFIER,
        BROKER_STOCKS_CONTRACT_DROPDOWN_ASSOCIATED_ACCOUNT_MODIFIER,
        BROKER_STOCKS_CONTRACT_DROPDOWN_DERIVATIVES_TRANSFER_MODIFIER,
        BROKER_STOCKS_CONTRACT_DROPDOWN_FUND_SUBSCRIPTION_MODIFIER
    ]),
    errorState: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    startResultsScrollAtTop: PropTypes.bool,
};

StocksContractDropdown.defaultProps = {
    disclaimer: {
        important: 'dropdownAccounts-dropdownAccounts_disclaimerError',
        warning: 'dropdownAccounts-dropdownAccounts_disclaimerWarning'
    },
    label: 'broker-stocksContractDropdownLabel',
    loading: false,
    operationType: undefined,
    showAdditionalInfo: true,
    showLabel: true,
    errorState: false,
    disabled: false
};

module.exports = StocksContractDropdown;
