module.exports = {
    ADOBE_WEB_ENV: 'obk_OERcpL',
    ADOBE_OFFER_CODE_GLOBAL_POSITION_TAB: 'obk_B21',
    ADOBE_OFFER_CODE_ACCOUNTS_TAB: 'obk_B31',
    ADOBE_OFFER_CODE_CARDS_TAB: 'obk_B51',
    ADOBE_NOT_INTERESTED_STATUS_CODE: '9',
    ADOBE_INTERESTED_STATUS_CODE: '2',
    ADOBE_REJECTED_STATUS_CODE: '5',
    ADOBE_PRESENTED_STATUS_CODE: '1',
    ADOBE_WEB_SYSTEM_CODE: '50',
};
