require('./globalPositionFinancialCard.scss');

// @ vendors
const PropTypes = require('prop-types');
const React = require('react');
const { withRouter } = require('react-router-dom');
const classnames = require('classnames');
const map = require('lodash/collection/map');
const includes = require('lodash/collection/includes');
const some = require('lodash/collection/some');
const uniqueId = require('lodash/utility/uniqueId');
const get = require('lodash/object/get');

// @ commons
const { AccordionHeader } = require('commonsComponents/accordionHeader');
const { AccordionBody } = require('commonsComponents/accordionBody');
const { EmptyState } = require('commonsComponents/emptyState');

// @ components
const GlobalPositionFinancialCardBodyItem = require('./globalPositionFinancialCardBodyItem/globalPositionFinancialCardBodyItem');
const GlobalPositionFinancialCardHeader = require('./globalPositionFinancialCardHeader/globalPositionFinancialCardHeader');
const { GlobalPositionCollapsable } = require('components/globalPosition/globalPositionCollapsable/globalPositionCollapsable');
const GlobalPositionShowMoreItems = require('components/globalPosition/globalPositionCollapsable/globalPositionShowMoreItems/globalPositionShowMoreItems');

// @ constants
const { OPENBANK_PRODUCT_BANKING, OPENBANK_PRODUCT_WEALTH } = require('constants/index');

class GlobalPositionFinancialCard extends React.Component {
    constructor(props) {
        super(props);
        this.handleBoxClick = this.handleBoxClick.bind(this);
    }

    handleBoxClick(evt) {
        const linkToCardsView = '/myprofile/cards';
        const classListWithoutRedirect = [
            'ok-collapsing-toggler'
        ];
        if (this.props.isOnPG) {
            if (!some(evt.target.parentElement.classList, (el) => includes(classListWithoutRedirect, el))) {
                this.props.history.push(linkToCardsView);
            }
        }
    }

    render() {
        const {
            amount,
            cards,
            currencyDetails,
            emptyStateMessage,
            expanded,
            easyLoanFFStatus,
            hasExcludedItems,
            icon,
            id,
            maxVisibleItems,
            showMoreLink,
            showMoreText,
            extraItem,
            extraLink,
            subtitle,
            title,
            allItemsExcluded,
            titleLink,
            keepSign,
            headerTooltip,
            isOnPG,
            productModifier,
            alternativeContentCC,
            isAresUser,
            cardType,
            handleOnSeeMoreClick
        } = this.props;

        let extraItemEl;

        let cardsEl = map(cards, (card, index) => {
            const hasSubtitle = !!card.subtitleLabel || !!card.subtitleAmount || !!card.subtitleCurrency;
            return (
                <GlobalPositionFinancialCardBodyItem
                    key={`glb-position-financial-card-box-item-${index}`}
                    {...card}
                    warningLink={__CONTACT_CENTER__ ? card.warningLink : undefined}
                    keepSign={keepSign}
                    hasWarnings={__CONTACT_CENTER__ ? card.hasWarnings : undefined}
                    warningCode={__CONTACT_CENTER__ ? card.warningCode : undefined}
                    isWealth={productModifier === OPENBANK_PRODUCT_WEALTH}
                    noSubtitle={!hasSubtitle}
                />
            );
        });

        const content = __CONTACT_CENTER__ && alternativeContentCC ? React.createElement(alternativeContentCC) : cardsEl;

        // TODO: Build the extra item with GlobalPositionFinancialCardBodyItem
        if (extraItem) {
            extraItemEl = (
                <GlobalPositionFinancialCardBodyItem
                    key={`glb-position-financial-card-box-item-extra`}
                    title={extraItem.title}
                    amount={get(extraItem, 'installment.amount', 0)}
                    currency={extraItem.currency}
                    link={extraItem.link}
                    linkClick={extraItem.onClick}
                    linkButton={extraItem.button}
                    easyLoanFFStatus={easyLoanFFStatus}
                    noSubtitle
                    isExtraItem
                />
            );
        }

        /**
         * (andres): We need to specify de name attr for AccordionHeader and AccordionBody
         * The parent component will try to get the child component by the type property
         * and for some reason, when the code is minimized and uglified, the type property
         * comes empty, so everything breaks. By sending manually the name attr, we can use
         * that value to get the child component safely, so DON'T remove it, and keep
         * this in mind if you need to implement something similar in the future.
         */

        const boxClasses = classnames('glb-position-financial-section', {
            'glb-position-financial-section--is-expanded': expanded
        });

        const contentClasses = classnames('glb-position-financial-section__content', {
            'glb-position-financial-section__content--is-expanded': expanded,
            'glb-position-financial-section__content--wealth': !expanded && productModifier === OPENBANK_PRODUCT_WEALTH,
            'glb-position-financial-section__content--is-expanded-wealth': expanded && productModifier === OPENBANK_PRODUCT_WEALTH,
            'glb-position-financial-section__content--is-clickable': !expanded && isOnPG
        });

        if (allItemsExcluded) {
            return (
                <section className="glb-position-financial-section__empty-state">
                    <div className="glb-position-financial-section__content">
                        <EmptyState primaryText={emptyStateMessage} iconType="financiation-banking" withMargin={false} />
                    </div>
                </section>
            );
        }
        let link;

        if (productModifier !== OPENBANK_PRODUCT_WEALTH) {
            if (extraLink) {
                link = (
                    <GlobalPositionShowMoreItems
                        key={uniqueId('global-position-collapsable-extra-link')}
                        showMoreLink={extraLink.url}
                        showMoreText={extraLink.text}
                        handleOnSeeMoreClick={handleOnSeeMoreClick}
                    />
                );
            }

            if ((cardsEl.length > maxVisibleItems || hasExcludedItems) && !extraLink) {
                link = (
                    <GlobalPositionShowMoreItems
                        key={uniqueId('show-more-items-link-')}
                        showMoreLink={showMoreLink}
                        showMoreText={showMoreText}
                        handleOnSeeMoreClick={handleOnSeeMoreClick}
                    />
                );
            }
        }

        const accountsHasWarnings = __CONTACT_CENTER__ ? cards.filter((card) => {
            return card.hasWarnings === 'S' && card.warningCode !== '00';
        }).length > 0 : undefined

        return (
            <div className={boxClasses}>
                <div className={contentClasses} onClick={!expanded ? this.handleBoxClick : undefined}>
                    <GlobalPositionCollapsable
                        hasExcludedItems={hasExcludedItems}
                        id={id}
                        isExpanded={expanded}
                        maxVisibleItems={maxVisibleItems}
                        extraItem={extraItemEl}
                    >
                        <AccordionHeader name="AccordionHeader">
                            <GlobalPositionFinancialCardHeader
                                amount={amount}
                                currencyDetails={currencyDetails}
                                icon={icon}
                                subtitle={subtitle}
                                title={title}
                                tooltipContent={headerTooltip}
                                titleLink={titleLink}
                                keepSign={keepSign}
                                expanded={expanded}
                                hasWarnings={accountsHasWarnings}
                                cardType={cardType}
                                isWealth={productModifier === OPENBANK_PRODUCT_WEALTH}
                            />
                        </AccordionHeader>
                        <AccordionBody name="AccordionBody">
                            {content}
                        </AccordionBody>
                    </GlobalPositionCollapsable>
                </div>
                {(expanded && link) ? <div className="glb-position-financial-section__footer">
                    {link}
                </div> : null}
                {isAresUser ? <div className="glb-position-showmore"></div> : null}
            </div>
        );
    }
}

GlobalPositionFinancialCard.propTypes = {
    allItemsExcluded: PropTypes.bool,
    alternativeContentCC: PropTypes.func,
    amount: PropTypes.number,
    cards: PropTypes.array,
    currencyDetails: PropTypes.array,
    emptyStateMessage: PropTypes.string,
    expanded: PropTypes.bool,
    extraItem: PropTypes.object,
    extraLink: PropTypes.object,
    easyLoanFFStatus: PropTypes.bool,
    hasExcludedItems: PropTypes.bool,
    headerTooltip: PropTypes.string,
    history: PropTypes.object.isRequired,
    icon: PropTypes.string,
    id: PropTypes.string,
    isOnPG: PropTypes.bool,
    keepSign: PropTypes.bool,
    maxVisibleItems: PropTypes.number,
    productModifier: PropTypes.oneOf([
        OPENBANK_PRODUCT_BANKING,
        OPENBANK_PRODUCT_WEALTH
    ]),
    showMoreLink: PropTypes.string,
    showMoreLinkInPG: PropTypes.bool,
    showMoreText: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    titleLink: PropTypes.string,
    isAresUser: PropTypes.bool,
    cardType:PropTypes.string,
    handleOnSeeMoreClick: PropTypes.func
};

GlobalPositionFinancialCard.defaultProps = {
    allItemsExcluded: false,
    alternativeContentCC: null,
    emptyStateMessage: '',
    expanded: false,
    easyLoanFFStatus: true,
    hasExcludedItems: false,
    isOnPG: false,
    keepSign: false,
    maxVisibleItems: 5,
    productModifier: OPENBANK_PRODUCT_BANKING,
    showMoreLinkInPG: true,
    showMoreText: 'Show more',
    titleLink: '',
    isAresUser: false
};
module.exports = withRouter(GlobalPositionFinancialCard);
