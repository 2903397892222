const TIME_OPTIONS_CALL_ME_BACK = {
    ES: [
        '9:00 - 11:00',
        '11:00 - 13:00',
        '13:00 - 15:00',
        '15:00 - 17:00',
        '17:00 - 20:00'
    ],
    PT: [
        '9:00 - 11:00',
        '11:00 - 13:00',
        '13:00 - 15:00',
        '15:00 - 17:00',
        '17:00 - 20:00'
    ],
    DE: [
        '9:00 - 11:00',
        '11:00 - 13:00',
        '13:00 - 15:00',
        '15:00 - 17:00',
        '17:00 - 20:00'
    ],
    NL: [
        '9:00 - 11:00',
        '11:00 - 13:00',
        '13:00 - 15:00',
        '15:00 - 17:00',
        '17:00 - 20:00',
        '20:00 - 22:00'
    ]
};

module.exports = {
    TIME_OPTIONS_CALL_ME_BACK
}