module.exports = {
    DEBIT_4B_MC_MUNDO_123_SUBTYPE: '004',
    DEBIT_AT_HOME_ABROAD_SUBTYPE: '005',
    DEBIT_DIAMOND_SUBTYPE: '008',
    DEBIT_PREMIUM_SUBTYPE: '009',
    DEBIT_SOLIDARIA_SUBTYPE: '011',
    DEBIT_GERMANY_SUBTYPE: '013',
    OPEN_DEBIT_SUBTYPE: '002',
    MINICARD_SUBTYPE: '007',
    DEBIT_SOLIDARIA_STANDARD: '0000001',
};
